import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Essay } from './Essay';
import { Answer } from './Answer';

type AnswerType = 'single' | 'multiple' | 'essay';

interface AnswerTypeProps {
  name: AnswerType;
}

export const AnswerType: React.FC<AnswerTypeProps> = ({ name }) => {
  const { watch, setValue } = useFormContext();

  const type: AnswerType = watch(name);

  if (!type) return null;

  return type === 'essay' ? <Essay watch={watch} setValue={setValue} /> : <Answer type={type} />;
};
