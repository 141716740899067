import Lottie from 'react-lottie';
import spinner from 'assets/lotties/spinner.json';
import { useEffect, useState } from 'react';

const Attachment = ({ attachment, maxHeight, maxWidth = undefined }) => {
  const [loaded, setLoaded] = useState(false);
  const [prevUrl, setPrevUrl] = useState(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: spinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    if (prevUrl && prevUrl !== attachment?.url) {
      setLoaded(false);
    }
    setPrevUrl(attachment?.url);
  }, [attachment, prevUrl]);

  const handleImageLoaded = () => setLoaded(true);

  return (
    <div
      className="flex w-full items-center justify-center"
      style={{
        maxWidth: `${maxWidth}px`,
        maxHeight: `${maxHeight || attachment.height / (attachment.width / maxWidth)}px`
      }}>
      {!loaded && <Lottie options={defaultOptions} height={'100%'} width={'100%'} />}
      <img
        style={{
          maxWidth: `${maxWidth}px`,
          maxHeight: `${maxHeight || attachment.height / (attachment.width / maxWidth)}px`
        }}
        className={`${!loaded ? 'hidden' : 'inline'} object-contain`}
        src={attachment.url}
        alt="question attachment"
        onLoad={handleImageLoaded}
      />
    </div>
  );
};

export default Attachment;
