import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface NavBarProps {
  className?: string;
  children: any;
}

export const Navbar: FC<NavBarProps> = ({ className, children }) => {
  return (
    <header
      className={twMerge(
        'sticky top-0 z-10 w-full bg-white px-7.5 flex items-center gap-6 shadow-[inset_0_-1px_0_0_#C9C9C9]',
        className
      )}>
      {children}
    </header>
  );
};
