const shuffle = array => {
  return array.reduce((acc, item, idx) => {
    const randIdx = Math.floor(Math.random() * (idx + 1));

    acc[idx] = acc[randIdx];
    acc[randIdx] = item;

    return acc;
  }, []);
};

export default shuffle;
