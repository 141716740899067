import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Typography, Tab } from 'ui-kit';

import { QuestionSections } from '../QuestionSections';
import { SettingsAdditionalForm } from 'components/Forms/SettingsAdditionalForm';
import SettingsTestForm from 'components/Forms/SettingsTestForm';
import {
  saveSummary as saveSum,
  saveSettings as saveSett,
  saveWebsiteSettings as saveWebsite,
  getAreasOfUseRequest,
  getSuitableForRequest,
  getSectionsTagsRequest
} from 'store/tests/actions';
import {
  getAnswerChoisesRequest,
  getTestCategoriesRequest
} from 'store/configuration/actions/subscriptions';
import Loader from 'components/Loader';
import { SettingsTab } from '../SettingsTab/SettingsTab';
import { WebsiteTab } from '../WebsiteTab/WebsiteTab';

export const TestEditor = () => {
  const dispatch = useDispatch();

  const {
    name,
    tagline,
    difficulty,
    category,
    language,
    suitableFor,
    areasOfUse,
    questionsCount,
    includeToHirenestScore,
    whyMeasure,
    summary,
    outcomes,
    displaySectionAs,
    instructions,
    result,
    sections,
    icon,
    cover,
    website
  } = useSelector(store => store.tests.currentTest);

  const { testCategories, loading } = useSelector(store => store.subscriptions);
  const { loading: load, currentTest } = useSelector(store => store.tests);

  useEffect(() => {
    dispatch(getTestCategoriesRequest());
    dispatch(getAnswerChoisesRequest());
    dispatch(getAreasOfUseRequest());
    dispatch(getSuitableForRequest());
    dispatch(getSectionsTagsRequest());
  }, []);

  const saveSummary = d => {
    dispatch(saveSum(d));
  };

  const saveSettings = d => {
    dispatch(saveSett(d));
  };

  const saveWebsiteSettings = d => {
    dispatch(saveWebsite(d));
  };

  return (
    <Tab.Group as="div" className="pt-6">
      {(loading || load) && <Loader />}
      <Tab.List>
        <Tab
          create
          className="flex items-center border border-b-0 border-gray-300 p-3 transition-all hover:rounded-t-xl hover:border-gray-400">
          <Typography variant="regular-default" title="Summary" />
        </Tab>
        <Tab
          create
          className="flex items-center gap-1 border border-b-0 border-gray-300 p-3 transition-all hover:rounded-t-xl hover:border-gray-400">
          <Typography variant="regular-default" title="Questions" />
          <Typography
            title={questionsCount}
            variant="small-default"
            className="flex h-6 items-center justify-center rounded-md bg-gray-300 px-2"
          />
        </Tab>
        <Tab
          create
          className="flex items-center border border-b-0 border-gray-300 p-3 transition-all hover:rounded-t-xl hover:border-gray-400">
          <Typography variant="regular-default" title="Results" />
        </Tab>
        <Tab
          create
          className="flex items-center border border-b-0 border-gray-300 p-3 transition-all hover:rounded-t-xl hover:border-gray-400">
          <Typography variant="regular-default" title="Settings" />
        </Tab>
        <Tab
          create
          className="flex items-center border border-b-0 border-gray-300 p-3 transition-all hover:rounded-t-xl hover:border-gray-400">
          <Typography variant="regular-default" title="Website" />
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel className="flex flex-1 flex-col rounded-r-xl rounded-b-xl border border-t-0 border-gray-300 bg-white p-5 shadow">
          <SettingsTestForm
            defaultValues={{
              name,
              tagline,
              summary,
              outcomes,
              category: category?._id || category,
              difficulty,
              language,
              areasOfUse,
              suitableFor,
              instructions,
              whyMeasure,
              displaySectionAs,
              icon,
              cover
            }}
            onSubmit={saveSummary}
            testCategories={testCategories}
          />
        </Tab.Panel>
        <Tab.Panel className="flex flex-1 flex-col space-y-4 rounded-r-xl rounded-b-xl border border-t-0 border-gray-300 bg-white p-5 shadow">
          <QuestionSections />
        </Tab.Panel>
        <Tab.Panel className="flex flex-1 flex-col rounded-r-xl rounded-b-xl border border-t-0 border-gray-300 bg-white p-5 shadow">
          <SettingsAdditionalForm
            sections={sections}
            includeToHirenestScore={includeToHirenestScore}
            defaultValues={result}
            onSubmit={saveSettings}
            saveSummary={saveSummary}
          />
        </Tab.Panel>
        <Tab.Panel className="flex flex-1 flex-col rounded-r-xl rounded-b-xl border border-t-0 border-gray-300 bg-white p-5 shadow">
          <SettingsTab
            defaultValues={{
              recommendedScore: currentTest?.recommendedScore,
              estimatedDurationPercent: currentTest?.estimatedDurationPercent,
              evaluation: currentTest?.evaluation || [
                'random',
                'random',
                'random',
                'random',
                'random'
              ]
            }}
            timeLimit={currentTest?.timeLimit ?? 0}
            onSubmit={saveSummary}
            sections={sections}
          />
        </Tab.Panel>
        <Tab.Panel className="flex flex-1 flex-col rounded-r-xl rounded-b-xl border border-t-0 border-gray-300 bg-white p-5 shadow">
          <WebsiteTab
            defaultValues={{
              ...website
            }}
            onSubmit={saveWebsiteSettings}
          />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
};
