export const ChipList = ({
  name,
  style,
  className
}: {
  name: string,
  style: any,
  className: string
}) => (
  <div
    style={style}
    className={`max-w-fit rounded border border-[#C9CCCF] bg-[#F6F6F7] px-[12px] py-[1px] ${className}`}>
    {name}
  </div>
);
