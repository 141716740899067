import Lottie from 'react-lottie';
import { useEffect, useRef } from 'react';
import { Button, Modal, Typography } from 'ui-kit';
import check from 'assets/lotties/check.json';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { useDispatch } from 'react-redux';
import { getCompanyRequest } from 'store/auth/actions';
import { useUserRole } from 'hooks';

const SuccessModal = ({ isModalOpen, setModalOpen, data }) => {
  const dispatch = useDispatch();
  const lottieRef = useRef();
  const { role } = useUserRole();

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: check,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    role === 'employer' && dispatch(getCompanyRequest());
  }, [isModalOpen]);

  useEffect(() => {
    if (!lottieRef.current) {
      return;
    }
    lottieRef.current.setSpeed(0.5);
  }, [lottieRef]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => setModalOpen(false)}
      className="m-auto w-[350px] max-w-[350px] p-6">
      <Modal.Header className="border-none px-6">
        <Button
          icon={faXmark}
          iconSize={20}
          className="ml-auto"
          iconColor="#5C5F62"
          onClick={() => setModalOpen(false)}
        />
      </Modal.Header>
      <Modal.Body className="px-6">
        <div className="flex flex-col items-center justify-center text-center">
          <div className="mb-[24px]">
            <Lottie
              options={defaultOptions}
              height={'150px'}
              width={'150px'}
              ref={lottieRef}
              speed={0.4}
            />
          </div>
          <div className="mb-[12px]">{data?.text}</div>
          <div className="flex flex-col items-center gap-[6px]">
            <Typography variant="heading-h3" title="Purchase successful!" />
            <Typography variant="regular-default" title="Enjoy Hirenest Pro!" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
