import { adminConstants } from '../actions';

const initialState = {
  loading: false,
  dashboard: {},
  demoAssessments: '',
  employers: [],
  testsFamilies: [],
  currentEmployer: {},
  currentCompany: {},
  authors: [],
  currentAuthor: {},
  authorTests: [],
  error: null,
  total: null,
  page: null
};

export function admin(state = initialState, action) {
  switch (action.type) {
    // RESET ERRORS - NO BACKEND
    case adminConstants.RESET_ERRORS:
      return {
        ...state,
        error: null
      };

    // REQUEST ACTIONS
    case adminConstants.SET_DEMO_ASSESSMENTS_ID_REQUEST:
    case adminConstants.GET_DEMO_ASSESSMENTS_ID_REQUEST:
    case adminConstants.GET_ALL_TESTS_FAMILIES_REQUEST:
    case adminConstants.GET_SINGLE_EMPLOYER_REQUEST:
    case adminConstants.GET_SINGLE_COMPANY_REQUEST:
    case adminConstants.GET_ALL_EMPLOYERS_REQUEST:
    case adminConstants.GET_ALL_AUTHORS_REQUEST:
    case adminConstants.GET_AUTHOR_REQUEST:
    case adminConstants.GET_TEST_BY_AUTHOR_REQUEST:
    case adminConstants.NOTE_EMPLOYER_REQUEST:
    case adminConstants.DELETE_NOTE_EMPLOYER_REQUEST:
    case adminConstants.GET_DASHBOARD_STAT_REQUEST:
    case adminConstants.GENERATE_APPLICATIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    // SUCCESS ACTIONS
    case adminConstants.GET_DASHBOARD_STAT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        dashboard: action.payload
      };
    case adminConstants.SET_DEMO_ASSESSMENTS_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        demoAssessments: action.payload.map(obj => `${obj._id},${obj.demoDaysLeft}`).join('<br />')
      };
    case adminConstants.GET_DEMO_ASSESSMENTS_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        demoAssessments: action.payload.map(obj => `${obj._id},${obj.demoDaysLeft}`).join('<br />')
      };
    case adminConstants.GET_ALL_TESTS_FAMILIES_SUCCESS:
      return {
        ...state,
        loading: false,
        testsFamilies: action.payload.tests,
        total: action.payload.total,
        error: null
      };
    case adminConstants.GET_SINGLE_EMPLOYER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentEmployer: action.payload
      };
    case adminConstants.GET_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentCompany: action.payload
      };
    case adminConstants.GET_ALL_EMPLOYERS_SUCCESS:
      return {
        ...state,
        employers: action.payload.employers,
        total: action.payload.total,
        page: action.payload.page,
        loading: false,
        error: null
      };
    case adminConstants.GET_ALL_AUTHORS_SUCCESS:
      return {
        ...state,
        authors: action.payload.authors,
        loading: false,
        error: null,
        total: action.payload.total,
        page: action.payload.page
      };
    case adminConstants.GET_AUTHOR_SUCCESS:
      return {
        ...state,
        currentAuthor: action.payload,
        loading: false,
        error: null
      };
    case adminConstants.GET_TEST_BY_AUTHOR_SUCCESS:
      return {
        ...state,
        authorTests: action.payload.tests,
        page: action.payload.page,
        total: action.payload.total,
        loading: false,
        error: null
      };
    case adminConstants.NOTE_EMPLOYER_SUCCESS:
      return {
        ...state,
        currentEmployer: action.payload,
        loading: false,
        error: null
      };
    case adminConstants.DELETE_NOTE_EMPLOYER_SUCCESS:
      return {
        ...state,
        currentEmployer: action.payload,
        loading: false,
        error: null
      };
    case adminConstants.CLEAR_COMPANY_EMPLOYER_DATA:
      return {
        ...state,
        currentCompany: {},
        currentEmployer: {}
      };
    case adminConstants.GENERATE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };

    // FAIL ACTIONS
    case adminConstants.GET_DEMO_ASSESSMENTS_ID_FAILED:
    case adminConstants.SET_DEMO_ASSESSMENTS_ID_FAILED:
    case adminConstants.GET_DASHBOARD_STAT_FAILURE:
    case adminConstants.INVITE_AUTHORS_FAILED:
    case adminConstants.GET_ALL_TESTS_FAMILIES_FAILED:
    case adminConstants.GET_SINGLE_EMPLOYER_FAILURE:
    case adminConstants.GET_SINGLE_COMPANY_FAILURE:
    case adminConstants.GET_ALL_EMPLOYERS_FAILURE:
    case adminConstants.GET_ALL_AUTHORS_FAILED:
    case adminConstants.GET_AUTHOR_FAILED:
    case adminConstants.GET_TEST_BY_AUTHOR_FAILED:
    case adminConstants.NOTE_EMPLOYER_FAILED:
    case adminConstants.DELETE_NOTE_EMPLOYER_FAILED:
    case adminConstants.INVITE_EMPLOYER_FAILED:
    case adminConstants.GENERATE_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
