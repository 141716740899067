export const getWithSubcategories = (data, modified = false) =>
  data
    .map(({ name, subcategories, _id, ...props }) => {
      return modified
        ? [
            { label: name, value: _id, parent: true },
            subcategories?.map(sub => ({ label: sub?.name, value: sub?._id }))
          ].flat()
        : [
            { ...props, name, _id, parent: true },
            subcategories?.map(sub => {
              return { ...sub, parentCategory: name };
            })
          ].flat();
    })
    .flat();
