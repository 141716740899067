import { configurationsConstants } from 'store/configuration/actions/subscriptions';
const initialSubscriptionsState = {
  subscriptions: [],
  importData: {
    status: ''
  },
  lastImportData: {},
  occupations: [],
  occupation: {},
  resources: [],
  resource: {
    all: 0,
    mapped: 0,
    notMapped: 0
  },
  admins: [],
  currentAdmin: {},
  currentSubscription: {},
  testCategories: [],
  answerChoises: [],
  idOpen: '',
  clientSecret: '',
  loading: false
};

export const subscriptions = (state = initialSubscriptionsState, action) => {
  switch (action.type) {
    // RESET ERRORS - NO BACKEND
    case configurationsConstants.RESET_ERRORS:
      return {
        ...state,
        error: null
      };

    // REQUEST ACTIONS
    case configurationsConstants.GET_CURRENT_SUBSCRIPTION_REQUEST:
    case configurationsConstants.CREATE_SUBSCRIPTION_REQUEST:
    case configurationsConstants.PUBLISH_SUBSCRIPTION_REQUEST:
    case configurationsConstants.ARCHIVE_SUBSCRIPTION_REQUEST:
    case configurationsConstants.EDIT_SUBSCRIPTION_REQUEST:
    case configurationsConstants.STRIPE_CONNECT_REQUEST:
    case configurationsConstants.CREATE_TEST_CATEGORY_REQUEST:
    case configurationsConstants.EDIT_TEST_CATEGORY_REQUEST:
    case configurationsConstants.DELETE_TEST_CATEGORY_REQUEST:
    case configurationsConstants.GET_ANSWER_CHOISES_REQUEST:
    case configurationsConstants.CREATE_ANSWER_CHOISE_REQUEST:
    case configurationsConstants.EDIT_ANSWER_CHOISE_REQUEST:
    case configurationsConstants.DELETE_ANSWER_CHOISE_REQUEST:
    case configurationsConstants.GET_ALL_ADMINS_REQUEST:
    case configurationsConstants.INVITE_ADMIN_REQUEST:
    case configurationsConstants.GET_ADMIN_REQUEST:
    case configurationsConstants.GET_ALL_OCCUPATIONS_REQUEST:
    case configurationsConstants.GET_SINGLE_OCCUPATION_REQUEST:
    case configurationsConstants.GET_ALL_OCCUPATIONS_RESOURCES_REQUEST:
    case configurationsConstants.GET_SINGLE_OCCUPATION_RESOURCE_REQUEST:
    case configurationsConstants.GET_IMPORT_STATUS_REQUEST:
    case configurationsConstants.GET_LAST_IMPORT_STATUS_REQUEST:
    case configurationsConstants.START_IMPORT_REQUEST:
    case configurationsConstants.RESOURCE_MAP_TEST_REQUEST:
    case configurationsConstants.RESOURCE_UNMAP_TEST_REQUEST:
    case configurationsConstants.GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };

    case configurationsConstants.GET_TEST_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        testCategories: []
      };
    case configurationsConstants.ADD_OPEN_ID:
      return {
        ...state,
        idOpen: action.payload
      };
    case configurationsConstants.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
        loading: false
      };
    case configurationsConstants.GET_CURRENT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        currentSubscription: action.payload
      };
    case configurationsConstants.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: [...state.subscriptions, action.payload],
        idOpen: action.payload._id,
        loading: false
      };
    case configurationsConstants.PUBLISH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: state.subscriptions.map(item =>
          item._id === action.payload._id ? action.payload : item
        ),
        idOpen: action.payload._id,
        loading: false
      };
    case configurationsConstants.ARCHIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptions: state.subscriptions.map(item =>
          item._id === action.payload._id ? action.payload : item
        ),
        idOpen: action.payload._id,
        loading: false
      };
    case configurationsConstants.EDIT_SUBSCRIPTION_SUCCESS: {
      const updated = state.subscriptions.map(item =>
        item._id === action.payload._id ? action.payload : item
      );
      return {
        ...state,
        subscriptions: updated,
        idOpen: action.payload._id,
        loading: false
      };
    }
    case configurationsConstants.STRIPE_CONNECT_SUCCESS:
      return {
        ...state,
        clientSecret: action.payload,
        loading: false
      };
    case configurationsConstants.CLEAR_SECRET:
      return {
        ...state,
        clientSecret: ''
      };
    case configurationsConstants.CLEAR_OCCUPATION:
      return {
        ...state,
        occupation: {}
      };
    case configurationsConstants.GET_TEST_CATEGORIES_SUCCESS:
      return {
        ...state,
        testCategories: action.payload.map(item => ({
          ...item,
          collapsed: true,
          isCollapsable: item.subcategories.length !== 0
        })),
        loading: false
      };
    case configurationsConstants.CREATE_TEST_CATEGORY_SUCCESS:
      return {
        ...state,
        testCategories: action.payload,
        loading: false
      };
    case configurationsConstants.EDIT_TEST_CATEGORY_SUCCESS:
      return {
        ...state,
        testCategories: action.payload,
        loading: false
      };
    case configurationsConstants.DELETE_TEST_CATEGORY_SUCCESS:
      return {
        ...state,
        testCategories: action.payload,
        loading: false
      };
    case configurationsConstants.GET_ANSWER_CHOISES_SUCCESS:
      return {
        ...state,
        answerChoises: action.payload,
        loading: false
      };
    case configurationsConstants.CREATE_ANSWER_CHOISE_SUCCESS:
      return {
        ...state,
        answerChoises: action.payload,
        loading: false
      };
    case configurationsConstants.EDIT_ANSWER_CHOISE_SUCCESS:
      return {
        ...state,
        answerChoises: action.payload,
        loading: false
      };
    case configurationsConstants.DELETE_ANSWER_CHOISE_SUCCESS:
      return {
        ...state,
        answerChoises: action.payload,
        loading: false
      };
    case configurationsConstants.GET_ALL_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload,
        loading: false
      };
    case configurationsConstants.INVITE_ADMIN_SUCCESS: {
      const isExist = state.admins.find(({ email }) => email === action.payload.email);
      return {
        ...state,
        admins: isExist ? state.admins : [...state.admins, action.payload],
        loading: false
      };
    }
    case configurationsConstants.GET_ADMIN_SUCCESS:
      return {
        ...state,
        currentAdmin: action.payload,
        loading: false
      };
    case configurationsConstants.GET_ALL_OCCUPATIONS_SUCCESS:
      return {
        ...state,
        occupations: action.payload,
        loading: false
      };
    case configurationsConstants.GET_SINGLE_OCCUPATION_SUCCESS:
      return {
        ...state,
        occupation: action.payload,
        loading: false
      };
    case configurationsConstants.GET_ALL_OCCUPATIONS_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: action.payload,
        loading: false
      };
    case configurationsConstants.GET_SINGLE_OCCUPATION_RESOURCE_SUCCESS:
      return {
        ...state,
        resource: action.payload,
        loading: false
      };
    case configurationsConstants.GET_IMPORT_STATUS_SUCCESS:
      return {
        ...state,
        importData: action.payload,
        loading: false
      };
    case configurationsConstants.GET_LAST_IMPORT_STATUS_SUCCESS:
      return {
        ...state,
        lastImportData: action.payload,
        loading: false
      };
    case configurationsConstants.START_IMPORT_SUCCESS:
      return {
        ...state,
        importData: { status: 'In Progress' },
        loading: false
      };
    case configurationsConstants.RESOURCE_MAP_TEST_SUCCESS: {
      if (state.resource.items[0].items) {
        return {
          ...state,
          resource: {
            ...state.resource,
            mapped: state.resource.mapped + 1,
            notMapped: state.resource.notMapped - 1,
            items: state.resource.items.map(item =>
              item.title !== action.payload.title
                ? item
                : {
                    ...item,
                    items: item.items.map(item =>
                      item._id === action.payload._id ? action.payload : item
                    )
                  }
            )
          },
          loading: false
        };
      }
      return {
        ...state,
        resource: {
          ...state.resource,
          mapped: state.resource.mapped + 1,
          notMapped: state.resource.notMapped - 1,
          items: state.resource.items.map(item =>
            item._id === action.payload._id ? action.payload : item
          )
        },
        loading: false
      };
    }
    case configurationsConstants.RESOURCE_UNMAP_TEST_SUCCESS: {
      if (state.resource.items[0].items) {
        return {
          ...state,
          resource: {
            ...state.resource,
            mapped: state.resource.mapped - 1,
            notMapped: state.resource.notMapped + 1,
            items: state.resource.items.map(item =>
              item.title !== action.payload.title
                ? item
                : {
                    ...item,
                    items: item.items.map(item =>
                      item._id === action.payload._id ? action.payload : item
                    )
                  }
            )
          },
          loading: false
        };
      }
      return {
        ...state,
        resource: {
          ...state.resource,
          mapped: state.resource.mapped - 1,
          notMapped: state.resource.notMapped + 1,
          items: state.resource.items.map(item =>
            item._id === action.payload._id ? action.payload : item
          )
        },
        loading: false
      };
    }

    case configurationsConstants.SHOW_HIDE_CATEGORY: {
      const { id } = action.payload;
      const activeCategory = state.testCategories.find(item => !item.collapsed);
      if (activeCategory && activeCategory._id === id) return { ...state };

      return {
        ...state,
        testCategories: state.testCategories.map(item => ({
          ...item,
          collapsed: item._id === id ? !item.collapsed : true
        }))
      };
    }

    // FAIL ACTIONS
    case configurationsConstants.GET_CURRENT_SUBSCRIPTION_FAILURE:
    case configurationsConstants.CREATE_SUBSCRIPTION_FAILURE:
    case configurationsConstants.PUBLISH_SUBSCRIPTION_FAILURE:
    case configurationsConstants.ARCHIVE_SUBSCRIPTION_FAILURE:
    case configurationsConstants.EDIT_SUBSCRIPTION_FAILURE:
    case configurationsConstants.GET_TEST_CATEGORIES_FAILURE:
    case configurationsConstants.CREATE_TEST_CATEGORY_FAILURE:
    case configurationsConstants.EDIT_TEST_CATEGORY_FAILURE:
    case configurationsConstants.DELETE_TEST_CATEGORY_FAILURE:
    case configurationsConstants.GET_ANSWER_CHOISES_FAILURE:
    case configurationsConstants.CREATE_ANSWER_CHOISE_FAILURE:
    case configurationsConstants.EDIT_ANSWER_CHOISE_FAILURE:
    case configurationsConstants.DELETE_ANSWER_CHOISE_FAILURE:
    case configurationsConstants.GET_ALL_ADMINS_FAILURE:
    case configurationsConstants.INVITE_ADMIN_FAILURE:
    case configurationsConstants.GET_ADMIN_FAILURE:
    case configurationsConstants.GET_ALL_OCCUPATIONS_FAILURE:
    case configurationsConstants.GET_SINGLE_OCCUPATION_FAILURE:
    case configurationsConstants.STRIPE_CONNECT_FAILURE:
    case configurationsConstants.GET_SUBSCRIPTIONS_FAILURE:
    case configurationsConstants.GET_ALL_OCCUPATIONS_RESOURCES_FAILURE:
    case configurationsConstants.GET_SINGLE_OCCUPATION_RESOURCE_FAILURE:
    case configurationsConstants.GET_IMPORT_STATUS_FAILURE:
    case configurationsConstants.GET_LAST_IMPORT_STATUS_FAILURE:
    case configurationsConstants.START_IMPORT_FAILURE:
    case configurationsConstants.EDIT_ADMIN_FAILURE:
    case configurationsConstants.DOWNGRADE_FREE_FAILURE:
    case configurationsConstants.RESOURCE_MAP_TEST_FAILURE:
    case configurationsConstants.RESOURCE_UNMAP_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
