import { Button, Typography } from 'ui-kit';
import * as routes from 'routes/routes';
import copyToClipboard from 'utils/copyToClipboard';

import { callToast } from 'utils/helpers';

import { statusColor } from 'components/ApplicationSection';

export const SingleApplicationHeader = ({ application, embedded, handleCloseApplication }) => {
  const copyLink = () => {
    // copyToClipboard(process.env.REACT_APP_HOST_URL + location.pathname);
    copyToClipboard(
      `${process.env.REACT_APP_HOST_URL}${routes.ASSESSMENTS}/${application.assessment.assessmentId}${routes.APPLICATIONS}/${application._id}`
    );
    callToast('success', 'Link was copy to clipboard');
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[12px]">
        {embedded && (
          <div
            onClick={() => {
              handleCloseApplication && handleCloseApplication();
            }}
            className="cursor-pointer p-[14px]">
            <i className="fa-regular fa-chevron-left text-xs text-[16px] text-[#12A182]" />
          </div>
        )}
        <div className="flex items-center">
          {!application?.visible && application?.candidate?.[0]?.firstName && (
            <div key={'candidate-hidden-application'}>
              <i
                key={'candidate-hidden-application-icon'}
                data-tooltip-id="premium-tooltip-bottom"
                data-tooltip-place="bottom"
                className="fa-solid fa-gem relative top-[-3px] mr-3.5 cursor-pointer text-[20px] text-[#2C6ECB]"
              />
            </div>
          )}
          {application?.candidate?.[0]?.firstName ? (
            <Typography variant="heading-h2" className="flex">
              <p className="font-semibold">
                {application?.candidate?.[0]?.firstName} {application?.candidate?.[0]?.lastName}
              </p>
              <p className="font-medium">'s {application?.assessment?.name} Application</p>
            </Typography>
          ) : (
            'Application'
          )}
        </div>
        <div
          className={`flex items-center justify-center rounded px-[6px] py-[3px] text-xs font-bold uppercase shadow-sm ${
            statusColor?.[application?.status]?.bg
          } ${statusColor?.[application?.status]?.text}`}>
          {statusColor?.[application?.status]?.title}
        </div>
      </div>
      <Button onClick={copyLink} className="h-max" variant="secondary" title="Share" />
    </div>
  );
};
