import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import Button from 'ui-kit/Button';
import Typography from 'ui-kit/Typography';
import { getCurrentTestRequest } from 'store/tests/actions';

import { secondsToHms } from 'utils/helpers';

export const Admin = props => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const dispatch = useDispatch();
  const { _id, name, questionsCount, timeLimit, type: typeSelect } = props?.data || {};

  const previewTestHandler = () => {
    dispatch(getCurrentTestRequest(_id));
    setIsOpenModal(true);
  };

  return (
    <>
      <li
        id={_id}
        style={{ wordBreak: 'break-word' }}
        className={`relative flex min-w-[345px] flex-col justify-between break-words rounded-md bg-white  transition-all`}>
        <ul className="space-y-4 rounded-md border border-gray-300 p-4">
          <li className="space-y-1">
            <Typography variant="heading-h3" className="truncate" title={name || 'In draft'} />
            <Typography
              variant="regular-emphasis"
              className="truncate text-gray-400"
              title={typeSelect?.label || typeSelect || 'Not selected'}
            />
          </li>
          <li className="flex flex-wrap justify-between gap-3">
            <div>
              <Typography variant="small-default" title="Questions" />
              <Typography variant="regular-emphasis" className="text-gray-500" title={questionsCount} />
            </div>
            <div>
              <Typography variant="small-default" title="Time Limit" />
              <Typography
                variant="regular-emphasis"
                className="text-gray-500"
                title={secondsToHms(timeLimit) || '-'}
              />
            </div>
          </li>
          <li className="flex justify-between">
            <Button variant="primary-outline" onClick={previewTestHandler} title="Preview" />
            <Button
              onClick={() => props.handleSubmit(_id)}
              title="Pair"
              className="inline-flex h-10 w-20 items-center justify-center rounded-xl border border-transparent bg-green-400 py-2  px-3 text-base font-semibold text-white shadow-sm transition-all hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-0 disabled:bg-gray-400"
            />
          </li>
        </ul>
      </li>
      <ViewTestModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
};
