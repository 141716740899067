import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'ui-kit';
import * as routes from 'routes/routes';

import { resetTopPerformers, getTopPerformersRequest } from 'store/candidates/actions';
import ApplicationTable from './ApplicationsTable';

export const TopPerformers = ({ auth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { topPerformers, moreTopPerformers } = useSelector(store => store.candidates);

  const [scoreSort, setScoreSort] = useState(true);
  const skip = topPerformers.length;
  const perPage = topPerformers.length ? 5 : 3;

  useEffect(() => {
    if (auth) {
      return;
    }
    dispatch(getTopPerformersRequest({ perPage, skip, sortBy: 'score' }));
    return () => dispatch(resetTopPerformers());
  }, [auth]);

  const loadMoreApplications = () =>
    dispatch(getTopPerformersRequest({ perPage, skip, sortBy: 'score' }));
  const navigateToAssessment = id => navigate(routes.ASSESSMENTS + '/' + id);
  const navigateToApplication = id => {
    navigate(`${routes.CANDIDATES}?sortBy=score&application=${id}`);
  };

  const applications = useMemo(
    () =>
      topPerformers.sort((a, b) =>
        scoreSort ? b.hirenestScore - a.hirenestScore : a.hirenestScore - b.hirenestScore
      ),
    [scoreSort, topPerformers]
  );

  return (
    <div>
      <div className="mb-3 flex justify-between">
        <Typography title="Top Performers" variant="heading-h4" />
        <Button
          onClick={() => navigate(routes.CANDIDATES + '?sortBy=score')}
          variant="link"
          size="small"
          title="All Candidates"
        />
      </div>

      <ApplicationTable
        applications={applications}
        sortOrder={scoreSort}
        handleSort={() => setScoreSort(!scoreSort)}
        navigateToApplication={navigateToApplication}
        navigateToAssessment={navigateToAssessment}
      />

      {moreTopPerformers && (
        <div className="mt-3 flex justify-center">
          <Button
            onClick={loadMoreApplications}
            variant="link"
            size="small"
            title="More Applications"
          />
        </div>
      )}
    </div>
  );
};
