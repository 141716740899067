import Breadcrumbs from 'components/Breadcrumbs';
import * as React from 'react';
import { Typography } from 'ui-kit';

export const Attachments = props => (
  <div className="px-6 py-9">
    <Breadcrumbs />
    <Typography title="Attachments" variant="subheader" />
  </div>
);
