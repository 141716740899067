import { Navigate, useLocation } from 'react-router-dom';
import * as routes from '../routes';
import { localStorageService } from 'helpers/localStorageService';

const PrivateRoute = ({ children }) => {
  const isAuth = localStorageService.getToken();
  const location = useLocation();

  if (!isAuth) {
    return <Navigate to={routes.AUTH} state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
