export const truncate = (str, maxLength = 100) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    let truncated = str.substring(0, maxLength);
    let lastSpace = truncated.lastIndexOf(' ');
    if (lastSpace === -1) {
      return truncated.substring(0, maxLength - 3) + '...';
    } else if (lastSpace <= maxLength / 2) {
      return truncated.substring(0, lastSpace) + '...';
    } else {
      return truncated.substring(0, lastSpace) + '...';
    }
  }
};
