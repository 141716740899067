import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import AuthorRoutes from './AuthorRoutes';
import UnauthorizedRoutes from './UnauthorizedRoutes';
import ProfileRoutes from './ProfileRoutes';
import AssessmentsRoutes from './AssessmentsRoutes';
import CandidatesRoutes from './CandidatesRoutes';
import AdministratorRoutes from './AdministratorRoutes';
import CommonRoutes from './CommonRoutes';

import { getToken } from 'utils/helpers';
import { useUserRole } from 'hooks';
import { getCompanyRequest, getProfileRequest } from 'store/auth/actions';
import { useLocation } from 'react-router-dom';
import * as routes from './routes';
import { CANDIDATE_CERTIFICATE_OLD } from './routes';

export const AppRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { role } = useUserRole();
  const { login, user } = useSelector(store => store.auth);

  useEffect(() => {
    let token = getToken();
    if (token === 'undefined') {
      token = undefined;
    }
    if (token && !user?._id && role) {
      dispatch(getProfileRequest());
      role === 'employer' && dispatch(getCompanyRequest());
    }
  }, [login, user?._id, role]);

  if (
    location.pathname === routes.CANDIDATE_INVITE ||
    location.pathname === routes.CANDIDATE_CERTIFICATE ||
    location.pathname === CANDIDATE_CERTIFICATE_OLD
  ) {
    window.location.href = `${process.env.REACT_APP_ASSESSMENT_URL}${location.pathname}${
      location.search ? location.search : ''
    }${location.hash ? location.hash : ''}`;
    return;
  }

  return (
    <>
      <UnauthorizedRoutes />
      <CommonRoutes />
      <AssessmentsRoutes />
      <CandidatesRoutes />
      <AdministratorRoutes />
      <AuthorRoutes />
      <ProfileRoutes />
    </>
  );
};
