import { deviceConstants } from 'store/auth/actions/devices';
const initialDevicesState = {
  mic: null,
  camera: null,
  errors: null
};

export function deviceReducer(state = initialDevicesState, action) {
  switch (action.type) {
    case deviceConstants.SET_DEFAULT_CAMERA: {
      return {
        ...state,
        camera: action.payload,
        errors: null
      };
    }
    case deviceConstants.SET_DEFAULT_MIC: {
      return {
        ...state,
        mic: action.payload,
        errors: null
      };
    }
    case deviceConstants.SET_ERRORS_DEVICE: {
      return {
        ...state,
        errors: action.payload
      };
    }
    default:
      return state;
  }
}
