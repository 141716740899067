import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'ui-kit';
import copyToClipboard from 'utils/copyToClipboard';
import { callToast } from 'utils/helpers';
import * as routes from 'routes/routes';
import { useSniperLink } from 'hooks/useSniperLink';

import {
  getPublishedAssessmetsRequest,
  inviteCandidateRequest,
  clearAssessments
} from 'store/assessment/actions';
import { resendEmailRequest } from 'store/auth/actions';
import { AssessmentCard } from './AssessmentCard';
import { InviteCandidatesModal } from './InviteCandidatesModal';
import { localStorageService } from 'helpers/localStorageService';

export const PublishedAssessments = ({ auth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpened, setModalOpened] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);
  const { sniperLink } = useSniperLink();

  const { publishedAssessments } = useSelector(store => store.assessments);
  const { user } = useSelector(store => store.auth);

  useEffect(() => {
    const isEmployerFirstTimeVerified = localStorageService.getIsFirstVerified();
    if (isEmployerFirstTimeVerified && publishedAssessments.length !== 0) {
      // first assessment in published always will be either published by employer or demo one
      const assessment = publishedAssessments[0];
      localStorage.removeItem('isFirstVerified');
      if (!assessment.demo.isDemo) return navigate(routes.ASSESSMENTS + `/${assessment._id}`);
    }
  }, [publishedAssessments]);

  useEffect(() => {
    if (auth) return;

    dispatch(clearAssessments());
    dispatch(getPublishedAssessmetsRequest({ perPage: 5 }));
    return () => dispatch(clearAssessments());
  }, [auth]);

  const assessments = useMemo(() => publishedAssessments.slice(0, 5), [publishedAssessments]);
  const selectedAssessment = useMemo(() => {
    if (selectedAssessmentId) return assessments.find(item => item._id === selectedAssessmentId);
    return null;
  }, [selectedAssessmentId]);

  const handleOpenInviteCandidates = id => {
    setSelectedAssessmentId(id);
    setModalOpened(true);
  };
  const closeModal = () => {
    setSelectedAssessmentId(null);
    setModalOpened(false);
  };
  const onSendInvitation = (data, control) => {
    dispatch(inviteCandidateRequest({ data: { ...data }, id: selectedAssessment._id }));
    callToast('success', 'Success - candidate was invited');
    control.reset();
    closeModal();
  };
  const copyLink = () => {
    const text = `${process.env.REACT_APP_ASSESSMENT_URL}${routes.CANDIDATE_INVITE}#${selectedAssessment.hash}`;
    copyToClipboard(text);
    callToast('success', 'Success - link was copy to clipboard');
  };
  const resendVerificationEmail = () => {
    dispatch(resendEmailRequest({ userId: localStorageService.getUserId() }));
  };

  return (
    <div className="mb-[36px]">
      <Typography title="Published Assessments" variant="heading-h4" className="mb-6" />
      <div>
        {assessments.map((assessment, i) => (
          <AssessmentCard
            assessment={assessment}
            handleOpenInviteCandidates={handleOpenInviteCandidates}
            key={`dashboard-${assessment._id}-${i}`}
          />
        ))}
      </div>

      <div
        style={{
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238C9196FF' stroke-width='2' stroke-dasharray='10%2c 14' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e")`
        }}
        onClick={() => navigate(routes.CREATE_ASSESSMENT)}
        className="group flex h-[94px] w-full cursor-pointer flex-col items-center justify-center gap-[2px] rounded-md bg-[#FFFFFF] py-[24px] text-center shadow-small hover:bg-[#FCFCFC]">
        <Typography variant="heading-h4" className="text-[#007D6F] group-hover:text-[#12A182]">
          + Create new assessment
        </Typography>
        <Typography variant="heading-h4" className="text-[#082623]">
          using our AI-powered templates
        </Typography>
      </div>

      {isModalOpened && (
        <InviteCandidatesModal
          assessment={selectedAssessment}
          isModalOpened={isModalOpened}
          onSendInvitation={onSendInvitation}
          closeModal={closeModal}
          copyLink={copyLink}
          verified={!!user?.verifiedAt}
          resendVerificationEmail={resendVerificationEmail}
          sniperLink={sniperLink}
        />
      )}
    </div>
  );
};
