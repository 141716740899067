import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDemoAssessmentsIdRequest,
  setDemoAssessmentsIdRequest,
  generateApplicationsRequest
} from 'store/admin/actions';
import { Button, Form, Textarea, Typography, Input } from 'ui-kit';

export const Assessments = () => {
  const dispatch = useDispatch();

  const { loading, demoAssessments } = useSelector(store => store.admin);

  useEffect(() => {
    dispatch(getDemoAssessmentsIdRequest());
  }, []);

  return (
    <div className="w-full px-6 py-9">
      <Breadcrumbs />
      {loading && <Loader />}
      <Typography title="Assessments" variant="heading-h1" />
      <div className="mt-6 flex w-full">
        <div className="flex w-full flex-col">
          <Typography variant="regular-default" title="Demo Assessments" />
          <Form
            onSubmit={({ demoAssessments }) => {
              const dataArray = demoAssessments.split('<br />').map(line => {
                const [id, daysLeft] = line.split(',');
                return { _id: id, demoDaysLeft: parseInt(daysLeft) };
              });
              dispatch(
                setDemoAssessmentsIdRequest({
                  assessments: demoAssessments?.length > 0 ? dataArray : []
                })
              );
            }}
            defaultValues={{ demoAssessments: demoAssessments || '' }}>
            <Textarea height="min-h-[66px]" className="w-[480px]" name="demoAssessments" />
            <Button className="mt-[18px] max-w-max" type="submit" variant="primary" title="Save" />
          </Form>

          <div className="my-[24px] h-[1px] w-full border border-[#C9CCCF]" />

          <Typography
            variant="regular-default"
            className="text-[22px]"
            title="Demo Applications Generator"
          />
          <Form
            defaultValues={{
              invited: 2,
              inProgress: 2,
              completed: {
                high: 1,
                medium: 2,
                low: 2
              }
            }}
            onSubmit={options => {
              const { assessmentId, ...data } = options;
              dispatch(generateApplicationsRequest({ assessmentId, data }));
            }}>
            <div className="flex flex-col gap-[12px]">
              <div>
                <Typography variant="regular-default" title="Assessment ID" />
                <Textarea height="min-h-[66px]" className="w-[480px]" name="assessmentId" />
              </div>

              <div>
                <Typography variant="regular-default" title="Invited" />
                <Input name="invited" className="w-[480px]" />
              </div>

              <div>
                <Typography variant="regular-default" title="In progress" />
                <Input name="inProgress" className="w-[480px]" />
              </div>

              <div>
                <Typography variant="regular-default" title="Completed High" />
                <Input name="completed.high" className="w-[480px]" />
              </div>

              <div>
                <Typography variant="regular-default" title="Completed Medium" />
                <Input name="completed.medium" className="w-[480px]" />
              </div>

              <div>
                <Typography variant="regular-default" title="Completed Low" />
                <Input name="completed.low" className="w-[480px]" />
              </div>

              <Button
                className="mt-[18px] max-w-max"
                type="submit"
                variant="primary"
                title="Generate"
              />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
