import { Editor, EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export const HtmlTextReplacer = ({ html, ...props }) => {
  if (!html) {
    return <p></p>;
  }

  const myBlockStyleFn = contentBlock => {
    const type = contentBlock.getType();
    if (type === 'blockquote') {
      return 'custom-blockquote';
    } else if (type === 'code') {
      return 'public-DraftStyleDefault-code-block';
    } else if (type === 'unstyled') {
      return 'custom-paragraph';
    }
    return '';
  };

  // Convert HTML to ContentState using htmlToDraft
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const editorState = EditorState.createWithContent(contentState);

  return (
    <div style={props?.style} className={`${props?.className}`}>
      <Editor blockStyleFn={myBlockStyleFn} editorState={editorState} readOnly={true} />
      <style>
        {`
          .custom-paragraph {
            margin-top: 6px;
            margin-bottom: 6px;
          }
        `}
      </style>
    </div>
  );
};
