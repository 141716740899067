import { VerticalTextBlock } from 'components/VerticalTextBlock/VerticalTextBlock';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { addOpenId } from 'store/configuration/actions/subscriptions';
import { Typography } from 'ui-kit';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import { badgetStatus } from 'utils/helpers';

export interface Subscription {
  _id: string;
  name: string;
  pricing: string;
  description: string;
  status: string;
  activeSubscriptions: number;
  totalSubscriptions: number;
  applicationsLimit: number;
  recommendedUpgrade: string;
  duration: string;
  icon?: string;
  badge?: string;
  benefits?: any;
  cost: number;
  priceMonth: any;
  addons?: any;
  priceYear: any;
  createdAt: any;
  updatedAt: any;
  publicationLimit: number;
  publicationLifetime: number;
  meta: {
    createdOn: string,
    updatedOn: string
  };
}

interface SubscriptionsCardProps extends Subscription {
  setViewModalOpen: (isActive: boolean) => void;
}

export const SubscriptionsCard: React.FC<SubscriptionsCardProps> = ({
  name,
  _id,
  pricing,
  description,
  status,
  activeSubscriptions,
  totalSubscriptions,
  priceMonth,
  priceYear,
  publicationLimit,
  publicationLifetime,
  createdAt,
  updatedAt,
  recommendedUpgrade,
  applicationsLimit,
  duration,
  setViewModalOpen
}) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        setViewModalOpen(true);
        dispatch(addOpenId(_id));
      }}
      className="relative flex w-full cursor-pointer gap-6 rounded-md border border-gray-300 bg-white px-[18px] py-[24px] shadow-small">
      <div className="flex w-[55%] flex-col gap-[8px]">
        <div className="flex gap-3">
          <div
            className={`${badgetStatus(
              status
            )} flex items-center justify-center rounded px-[6px] py-[1px] text-xs font-bold uppercase shadow-sm`}>
            {status === 'active' ? 'published' : status}
          </div>
          <div className="flex items-center justify-center rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] font-semibold text-[#05443D] shadow-sm">
            {pricing.charAt(0).toUpperCase() + pricing.slice(1)}
          </div>
        </div>
        <Typography variant="heading-h4" title={name} />
        <TextAreaView title={description} />
      </div>
      <div className="flex gap-3">
        {pricing === 'free' ? (
          <>
            <VerticalTextBlock
              mainText="Assessment Publication"
              secondText={publicationLimit}
              mainColor="text-[#6D7175]"
              variantSecondText="regular-default"
            />
            <VerticalTextBlock
              mainText="Assessment Lifetime"
              secondText={publicationLifetime}
              mainColor="text-[#6D7175]"
              variantSecondText="regular-default"
            />
            <VerticalTextBlock
              mainText="View Applications"
              secondText={applicationsLimit || '-'}
              mainColor="text-[#6D7175]"
              variantSecondText="regular-default"
            />
          </>
        ) : (
          <>
            <div className="w-[110px]"></div>
            <VerticalTextBlock
              mainText="Monthly Cost"
              secondText={`$${priceMonth?.price}`}
              mainColor="text-[#6D7175]"
              variantSecondText="regular-default"
            />
            <VerticalTextBlock
              mainText="Annual Cost"
              secondText={`$${priceYear?.price}`}
              mainColor="text-[#6D7175]"
              variantSecondText="regular-default"
            />
          </>
        )}
        <VerticalTextBlock
          mainText="Active Subscriptions"
          secondText={activeSubscriptions}
          mainColor="text-[#6D7175]"
          secondColor="text-[#007D6F] font-semibold text-base"
          variantSecondText="regular-default"
        />
      </div>
    </div>
  );
};
