import { faXmark } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { Button, Modal, Typography } from 'ui-kit';

interface ConfirmationModalProps {
  isConfirmModalOpen: boolean;
  setConfirmModalOpen: (value: boolean) => void;
  headerText: string;
  bodyText: string;
  buttonText: string;
  danger?: string;
  buttonAction: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isConfirmModalOpen,
  setConfirmModalOpen,
  headerText,
  bodyText,
  buttonText,
  danger,
  buttonAction
}) => {
  return (
    <Modal isOpen={isConfirmModalOpen} className="m-auto w-full min-w-[600px] max-w-max">
      <Modal.Header className="border-none px-6">
        <Typography variant="heading-h2" title={headerText} />
        <Button
          icon={faXmark}
          iconSize={20}
          iconColor="#5C5F62"
          onClick={() => setConfirmModalOpen(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex items-center border-none px-6">
        <div className="flex gap-1">Are you sure want to {bodyText}</div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end gap-4 border-none">
        <div className="flex justify-end gap-4">
          <Button variant="secondary" title="Cancel" onClick={() => setConfirmModalOpen(false)} />
          <Button
            variant={danger ? 'danger' : 'primary'}
            title={buttonText}
            onClick={() => {
              buttonAction();
              setConfirmModalOpen(false);
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
