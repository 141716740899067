import React, { useEffect, useRef } from 'react';

import { useController } from 'react-hook-form';

import { Typography } from 'ui-kit';

import cn from 'utils/classNames';

import './textarea.css';

interface TextareaProps {
  name: string;
  onChangeValue?: (_value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  shouldUnregister?: boolean;
  maxLength?: any;
  height?: string;
  classNameLabel?: string;
  showInputLength?: boolean;
  autoFocus?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
  name,
  placeholder,
  className,
  shouldUnregister,
  maxLength,
  height,
  disabled,
  classNameLabel,
  showInputLength = true,
  autoFocus,
  ...props
}) => {
  const { field, fieldState } = useController({ name, shouldUnregister, defaultValue: '' });
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const { onChange, value, onBlur } = field;

  const { error } = fieldState;

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [value]);

  return (
    <div
      style={{ wordBreak: 'break-word' }}
      className={cn(height || 'min-h-[156px]', 'flex relative flex-col rounded', className)}>
      <label
        className={cn(
          error ? ' border-orange-500' : ' border-[#C9CCCF]',
          'm-0 rounded border',
          'h-full flex flex-1 bg-white',
          classNameLabel
        )}>
        <textarea
          data-hj-allow
          ref={textareaRef}
          placeholder={placeholder}
          maxLength={maxLength}
          autoFocus={autoFocus}
          autoComplete="off"
          value={value?.replace(/<br\s*[\/]?>/gi, '\n')}
          disabled={disabled}
          onBlur={onBlur}
          onChange={e => onChange(e.target.value?.replace(/(?:\r\n|\r|\n)/g, '<br />'))}
          style={{ wordBreak: 'break-word' }}
          {...props}
          className={cn(
            error ? 'placeholder:text-orange-500' : 'placeholder:text-gray-400',
            'rounded-lg break-words px-4 py-2 border-none w-full flex flex-1',
            `border-none outline-none resize-none`
          )}
        />
      </label>
      {maxLength && showInputLength && (
        <Typography
          variant="regular-default"
          title={`${value.replace(/<br\s*[\/]?>/gi, '\n').length} / ${maxLength}`}
          className="self-end text-[#6D7175]"
        />
      )}
    </div>
  );
};

export default Textarea;
