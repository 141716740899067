import * as React from 'react';
import { Typography } from 'ui-kit';
import { variants } from 'ui-kit/Typography/variants';

interface VerticalTextBlockProps {
    mainText: string;
    secondText: string | number;
    mainColor: string;
    secondColor?: string;
    className?: string;
    variantSecondText?: keyof typeof variants;
}

export const VerticalTextBlock: React.FC<VerticalTextBlockProps> = ({
  mainText,
  secondText,
  mainColor,
  secondColor,
  className,
  variantSecondText
}) => {
  return (
    <div className={`flex w-[110px] flex-col gap-1.5 px-3 ${className}`}>
      <Typography className={`font-normal ${mainColor}`} variant="small-default">
        {mainText}
      </Typography>
      <Typography className={`${secondColor}`} variant={variantSecondText || 'heading-h6'}>
        {secondText || '-'}
      </Typography>
    </div>
  );
};