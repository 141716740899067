import { RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useState } from 'react';
import { TextEditorSelect } from 'components/TextEditorSelect/TextEditorSelect';

const TextEditorToolbar = ({ editorState, setEditorState, className }) => {
  const [styleSelected, setStyleSelected] = useState({
    label: 'Regular',
    style: 'unstyled',
    method: 'block'
  });
  const textStyle = [
    { label: 'Regular', style: 'unstyled', method: 'block' },
    { label: 'Heading 1', style: 'header-one', method: 'block' },
    { label: 'Heading 2', style: 'header-two', method: 'block' },
    { label: 'Heading 3', style: 'header-three', method: 'block' },
    { label: 'Heading 4', style: 'header-four', method: 'block' },
    { label: 'Heading 5', style: 'header-five', method: 'block' },
    { label: 'Heading 6', style: 'header-six', method: 'block' },
    { label: 'Quote', style: 'blockquote', method: 'block' },
    { label: 'Code', style: 'code', method: 'block' }
  ];
  const tools = [
    {
      label: 'bold',
      style: 'BOLD',
      icon: 'fa-regular fa-bold',
      method: 'inline'
    },
    {
      label: 'italic',
      style: 'ITALIC',
      icon: 'fa-regular fa-italic',
      method: 'inline'
    },
    {
      label: 'underline',
      style: 'UNDERLINE',
      icon: 'fa-regular fa-underline',
      method: 'inline'
    },
    {
      label: 'line'
    },
    {
      label: 'Unordered-List',
      style: 'unordered-list-item',
      method: 'block',
      icon: 'fa-regular fa-list-ul'
    },
    {
      label: 'Ordered-List',
      style: 'ordered-list-item',
      method: 'block',
      icon: 'fa-regular fa-list-ol'
    }
  ];

  const applyStyle = (style, method) => {
    method === 'block'
      ? setEditorState(RichUtils.toggleBlockType(editorState, style))
      : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const isActive = (style, method) => {
    if (method === 'block') {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  return (
    <div
      className={`flex w-full items-center gap-3 rounded-t border-x border-t border-[#C9CCCF] px-[12px] py-[6px] ${className}`}>
      <TextEditorSelect
        onClick={value => {
          const selectedStyle = textStyle.find(item => item.label === value.label);
          setStyleSelected(selectedStyle);
          applyStyle(selectedStyle.style, selectedStyle.method);
        }}
        title={styleSelected.label}
        options={textStyle}
      />
      <div className="h-[16px] w-[1px] bg-[#BABEC3]" />
      {tools.map((item, idx) =>
        item.label === 'line' ? (
          <div className="h-[16px] w-[1px] bg-[#BABEC3]" />
        ) : (
          <button
            style={{
              background: isActive(item.style, item.method) && '#E2F1EA',
              color: isActive(item.style, item.method) && '#016B69'
            }}
            key={`${item.label}-${idx}`}
            className="rounded p-[4px] hover:bg-[#F6F6F7]"
            title={item.label}
            onClick={e => {
              e.preventDefault();
              applyStyle(item.style, item.method);
            }}
            onMouseDown={e => e.preventDefault()}>
            {item.icon ? <i className={`${item.icon} text-base text-[#5C5F62]`}></i> : item.label}
          </button>
        )
      )}
    </div>
  );
};

export default TextEditorToolbar;
