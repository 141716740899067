import { useCallback, useEffect, useRef } from 'react';

export const CurvedArrow = ({ color, lineWidth, arrowSize }) => {
  const canvasRef = useRef(null);
  const height = document.getElementById('testsInfo').clientHeight - 20;
  const width = document.getElementById('testsInfo').clientWidth;

  const drawArrow = useCallback(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    context.strokeStyle = color;
    context.lineWidth = lineWidth;
    context.lineCap = 'round';
    context.lineJoin = 'round';

    const arrowHead = () => {
      context.beginPath();
      context.moveTo(width - 40, height);
      context.lineTo(width - arrowSize - 15, height - arrowSize + 5);
      context.lineTo(width - arrowSize - 25, height + arrowSize + 5);
      context.closePath();
      context.fillStyle = color;
      context.fill();
    };

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.beginPath();
    context.moveTo(width / 1.85, 575);
    context.bezierCurveTo(width / 2, height, width, height / 1.5, width - 30, height);
    context.stroke();
    arrowHead();
  }, [height, width, color, lineWidth, arrowSize]);

  useEffect(() => {
    drawArrow();
  }, [drawArrow]);

  useEffect(() => {
    const handleResize = () => {
      drawArrow();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [drawArrow]);

  return (
    <canvas
      ref={canvasRef}
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
      width={window.innerWidth}
      height={height + 20}
    />
  );
};
