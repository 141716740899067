import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import 'react-tooltip/dist/react-tooltip.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', process.env.REACT_APP_HOST_URL]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENV
});

process.env.REACT_APP_ENV === 'production' && TagManager.initialize({ gtmId: 'G-81VCK7D4M9' });

const elementId = document.getElementById('root');
const root = createRoot(elementId);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
