export function formatScaledResult(scaledResult) {
  if (typeof scaledResult === 'string' && scaledResult.endsWith('%')) {
    const numericValue = parseFloat(scaledResult);
    const roundedValue = numericValue.toFixed(0);
    return roundedValue + '%';
  } else if (!isNaN(parseFloat(scaledResult))) {
    const numericValue = parseFloat(scaledResult);
    const roundedValue = numericValue.toFixed(0);
    return roundedValue;
  } else {
    return scaledResult;
  }
}
