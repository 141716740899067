import React from 'react';

import { Essay } from './Essay';
import { Multi } from './Multi';
import { Single } from './Single';

const questionType = {
  single: props => <Single {...props} />,
  multiple: props => <Multi {...props} />,
  essay: props => <Essay {...props} />
};

export const QuestionType = ({ type, ...props }) => {
  const Component = questionType[type];

  return <Component {...props} />;
};
