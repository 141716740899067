import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';

export const Essay = ({ attachment, essayPlaceholder, essayLimit, text, answers }) => {
  return (
    <div className="flex w-full flex-1">
      <div className="mx-[18px] flex flex-1 flex-col gap-[8px] border-r border-[#D2D5D8]">
        <TextAreaView className="mb-3 break-words" title={text} variant="regular-default" />
        <div className="overflow-hidden">
          {attachment?.url && (
            <img
              className="m-0 block h-36 max-w-[250px] object-contain"
              src={attachment.url}
              alt="question attachment"
            />
          )}
        </div>
      </div>
      <div className="min-h-[60px] flex-1 rounded-lg border border-gray-300 p-2">
        <HtmlTextReplacer
          className="break-words"
          style={{ wordBreak: 'break-word' }}
          html={answers[0].wasAnswered ? answers[0].wasAnswered[0] : essayPlaceholder}
        />
      </div>
    </div>
  );
};
