import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalSlide, Form, Input, Icon, Typography } from 'ui-kit';
import copyToClipboard from 'utils/copyToClipboard';
import {
  faMagnifyingGlass,
  faChevronRight,
  faChevronDown,
  faArrowUpRightFromSquare
} from '@fortawesome/pro-light-svg-icons';
import { faqRequest } from 'store/auth/actions';
import { callToast } from 'utils/helpers';

const FAQModal = ({ isOpen, setOpen, auth }) => {
  const dispatch = useDispatch();
  const { faqs } = useSelector(store => store.auth);
  const [faqsState, setFaqsState] = useState([]);

  useEffect(() => {
    if (auth) {
      return;
    }
    dispatch(faqRequest());
  }, [auth]);

  useEffect(() => {
    setFaqsState(
      faqs.map(item => ({
        ...item,
        posts: item.posts.map(post => ({ ...post, hidden: false })),
        opened: false,
        hidden: false
      }))
    );
  }, [faqs]);

  const openCloseFaqCategory = index => {
    faqsState[index].opened = !faqsState[index].opened;
    setFaqsState([...faqsState]);
  };

  const copyHandle = useCallback(
    post => () => {
      copyToClipboard(`https://blog.hirenest.com/${post.slug}`);
      callToast('success', 'Success - link was copy to clipboard');
    },
    []
  );

  const handleSearch = text => {
    if (!text || text === '') {
      setFaqsState(
        faqsState.map(faq => ({
          ...faq,
          posts: faq.posts.map(post => ({ ...post, hidden: false })),
          hidden: false
        }))
      );
      return;
    }

    const searched = faqsState.map(faq => ({
      ...faq,
      posts: faq.posts.map(post => ({
        ...post,
        hidden: !post.title.includes(text)
      }))
    }));

    setFaqsState(
      searched.map(faq => ({
        ...faq,
        hidden: faq.posts.filter(post => post.hidden).length === faq.posts.length
      }))
    );
  };

  return (
    <ModalSlide
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      disableScroll={true}
      animationType="rightToLeft"
      className="m-auto w-[360px]"
      customWidth={360}
      title="Frequently Asked Questions">
      <Modal.Body className="flex h-full flex-col gap-4 p-6">
        <Form className="w-full">
          {() => (
            <Input
              leftComponent={() => (
                <Icon icon={faMagnifyingGlass} size={16} color="#8C9196" className="ml-3" />
              )}
              onChangeValue={e => handleSearch(e)}
              className="flex-3"
              inputClassName="h-[40px] px-[12px] py-[8px]"
              name="search"
              placeholder="Search question..."
            />
          )}
        </Form>
        <div className="flex grow flex-col gap-[18px]">
          {faqsState.map((faq, i) => (
            <div key={`faq_${i}`}>
              {!faq.hidden && (
                <>
                  <div
                    className="flex cursor-pointer items-center"
                    onClick={() => openCloseFaqCategory(i)}>
                    <Icon
                      className="mr-[12px] w-[16px]"
                      size={16}
                      icon={faq.opened ? faChevronDown : faChevronRight}
                    />
                    <div className="text-[18px] text-[#082623]">{faq.name}</div>
                  </div>

                  {faq.opened && (
                    <div className="mt-[12px] ml-[28px] flex flex-col gap-[8px]">
                      {faq.posts.map((post, j) => (
                        <div key={`faq_${i}_${j}`}>
                          {!post.hidden && (
                            <>
                              <a
                                href={`https://blog.hirenest.com/${post.slug}`}
                                target="_blank"
                                className="text-[#007D6F]"
                                rel="noreferrer">
                                {post.title}
                              </a>
                              <Icon
                                icon={faArrowUpRightFromSquare}
                                onClick={copyHandle(post)}
                                size={12}
                                className="ml-[7px] cursor-pointer text-[#5C5F62]"
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
        <div className="rounded-md bg-[#007D6E] p-[20px]">
          <Typography
            variant="regular-default"
            className="text-[18px] font-medium text-[#FFFFFF]"
            title="Still have questions?"
          />
          <Typography
            variant="regular-default"
            className="mt-[8px] mb-[16px] text-[16px] font-normal text-[#FFFFFF]"
            title="Can’t find the answer you’re looking for? Please chat to our team."
          />
          <a
            href="mailto:support@hirenest.com"
            className="block w-full rounded-md bg-[#FB6232] px-[24px] py-[8px] text-center text-[18px] font-semibold text-[#FFFFFF]">
            Get in Touch
          </a>
        </div>
      </Modal.Body>
    </ModalSlide>
  );
};

export default FAQModal;
