import Breadcrumbs from 'components/Breadcrumbs';
import { InviteAdminModal } from 'components/Forms/InviteAdminModalForm';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllAdminsRequest } from 'store/configuration/actions/subscriptions';
import { Button, Typography } from 'ui-kit';
import { badgetStatus, getFullName, getModifyData } from 'utils/helpers';
import * as routes from 'routes/routes';

const AdminCard = ({ firstName, lastName, email, status, lastActivityAt, onClick }) => (
  <div
    onClick={onClick}
    className="group relative flex min-h-[97px] w-full cursor-pointer items-center justify-between rounded-md border border-[#C9CCCF] bg-[#FFFFFF] p-[16px] transition-all hover:border-green-400">
    <span
      className={`absolute top-0 left-4 py-0.5 px-3 text-center capitalize ${badgetStatus(
        status
      )} rounded-b-xl `}>
      <Typography variant="small-default" title={status} />
    </span>
    <div className="flex gap-[30px]">
      <Typography
        variant="heading-h5"
        title={getFullName({ firstName, lastName })}
        className="w-[200px] break-words group-hover:text-green-400"
      />
      <Typography variant="heading-h6" className="text-[#6D7175]" title={email} />
    </div>
    <div className="mt-4 flex flex-col gap-[4px] pr-[28px]">
      <Typography variant="small-default" className="text-[#6D7175]" title="Last Active" />
      <Typography
        variant="regular-default"
        title={lastActivityAt ? getModifyData(lastActivityAt) : 'No date'}
      />
    </div>
  </div>
);

export const Admins = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading, admins } = useSelector(store => store.subscriptions);

  useEffect(() => {
    dispatch(getAllAdminsRequest());
  }, []);
  return (
    <div className="w-full px-6 py-9">
      {loading && <Loader />}
      <Breadcrumbs />
      <div className="flex gap-[12px]">
        <Typography title="Admins" variant="subheader" />
        <Button
          onClick={() => setIsModalOpen(true)}
          title="Invite Admin"
          variant="create"
          icon="plus"
        />
      </div>
      <div className="mt-[24px] flex w-full flex-col gap-[24px]">
        {admins &&
          admins.map(({ firstName, lastName, email, status, lastActivityAt, _id }) => (
            <AdminCard
              key={_id}
              onClick={() => navigate(routes.CONFIGURATION + '/' + routes.ADMINS + `/${_id}`)}
              firstName={firstName}
              lastName={lastName}
              email={email}
              status={status}
              lastActivityAt={lastActivityAt}
            />
          ))}
      </div>
      <InviteAdminModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </div>
  );
};
