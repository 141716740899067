import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import ReactPaginate from 'react-paginate';
import { Icon, Typography } from 'ui-kit';

const Pagination = ({ page, itemsPerPage, totalItems, setPage }) => {
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const handlePageClick = ({ selected }) => setPage(selected);

  return (
    <ReactPaginate
      initialPage={Number(page)}
      breakLabel="..."
      nextLabel={
        <Typography variant="small-default">
          <Typography title="next" />
          <Icon icon={faArrowRight} size={18} />
        </Typography>
      }
      previousLabel={
        <Typography variant="small-default">
          <Icon icon={faArrowLeft} size={18} />
          <Typography title="prev" />
        </Typography>
      }
      onPageChange={handlePageClick}
      forcePage={Number(page)}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      pageClassName="flex items-center justify-center transition-all h-[46px] w-[46px] rounded-lg hover:bg-gray-400"
      pageLinkClassName="py-3 px-5"
      previousClassName="h-[46px] flex items-center transition-all rounded-xl hover:bg-gray-400"
      nextClassName="h-[46px] flex items-center transition-all rounded-xl hover:bg-gray-400"
      breakClassName="flex items-end px-1.5 h-[46px]"
      containerClassName="flex items-center gap-2 justify-center"
      activeClassName="bg-gray-300"
    />
  );
};

export default Pagination;
