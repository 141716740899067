import { LibraryCategories } from 'components/LibraryCategories/LibraryCategories';
import TimeBadget from 'components/TimeBadget';
import { useDebouceCallback } from 'hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTestsToAssessment, updateTests } from 'store/assessment/actions';
import { getTestCategoriesRequest } from 'store/configuration/actions/subscriptions';
import {
  clearTests,
  getAllTestsRequest,
  getCurrentTestRequest,
  getPublishedTestRequest,
  getTestByImportanceRequest,
  testRequestRequest
} from 'store/tests/actions';
import { Typography, Icon } from 'ui-kit';
import { getWithSubcategories } from 'utils/getWithSubcategories';
import { numberSuffixes } from 'utils/numberSuffixes';
import defaultLogo from 'assets/defaultLogoHirenest.svg';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

const perPage = 30;

const TestsTab = ({
  currentAssessment,
  setIsGenericModalOpen,
  setRemoveId,
  setConfirmationModalOpen,
  testsStateChanged,
  setWhatToAddModalOpen,
  setGenericTests,
  genericTemplates,
  setTestsStateChanged,
  previewTest
}) => {
  const dispatch = useDispatch();
  const [shouldScroll, setShouldScroll] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isFirstSearch, setIsFirstSearch] = useState(false);
  const [isNoTestComplete, setIsNoTestComplete] = useState(false);
  const [params, setParams] = useState({ page: 0, search: '', category: 'recommended' });
  const [testsDivHeight, setTestsDivHeight] = useState();
  const testsDivRef = useRef(null);

  const { loading, testCategories } = useSelector(store => store.subscriptions);
  const {
    loading: load,
    publishedTests,
    recommendedTests,
    publishedTestsTotal,
    allTests
  } = useSelector(store => store.tests);

  useEffect(() => {
    !load && setSearchLoading(false);
  }, [load]);

  const hasMore = useMemo(() => {
    return publishedTests?.length >= publishedTestsTotal ? false : true;
  }, [publishedTests, publishedTestsTotal]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 480) {
        setShouldScroll(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const debounceSubmit = useDebouceCallback(value => {
    setSearchLoading(true);
    dispatch(clearTests());
    setParams(value);
  });

  const previewTestHandler = (id, showAddButtons = false) => {
    dispatch(getCurrentTestRequest(id));
    previewTest(id, showAddButtons);
  };

  const clearSearchHandler = () => {
    setParams(prev => {
      return { ...prev, search: '' };
    });
    setIsNoTestComplete(false);
  };

  const categoryClickHandler = id => {
    dispatch(clearTests());
    setParams(prev => {
      return { ...prev, category: id, page: 0 };
    });
  };

  const browseAllTestHandler = () => {
    setIsNoTestComplete(false);
    setParams(() => {
      return { page: 0, search: '', category: 'recommended' };
    });
  };

  const requestTestHandler = data => {
    dispatch(testRequestRequest(data));
    setIsNoTestComplete(true);
  };

  const onScroll = () => {
    setParams(prev => {
      return { ...prev, page: prev.page + 1 };
    });
  };

  useEffect(() => {
    if (params?.category === 'recommended' && recommendedTests?.length > 0) {
      return;
    }
    if (params?.category !== 'recommended' && publishedTests?.length > 0) {
      return;
    }
    dispatch(
      getAllTestsRequest({
        sortBy: 'updatedAt',
        category: '',
        search: params?.search
      })
    );
  }, [publishedTests, recommendedTests]);

  useEffect(() => {
    if (params?.search === '' && params?.category === 'all') {
      setParams(prev => {
        return { ...prev, category: 'recommended' };
      });
    }
    dispatch(getTestCategoriesRequest({ search: params?.search }));
    if (params?.search?.length > 0 && shouldScroll) {
      window.scrollTo(480, 480);
      setShouldScroll(false);
    }
    if (params?.search !== '' && params?.category === 'recommended') {
      categoryClickHandler('all');
      return;
    }
    if (!isFirstSearch && params?.search !== '') {
      categoryClickHandler('all');
      setIsFirstSearch(true);
    }
  }, [params?.search]);

  useEffect(() => {
    if (params?.category === 'recommended') {
      if (!currentAssessment?.template)
        dispatch(
          getTestByImportanceRequest({
            params: { search: params?.search, perPage: -1 }
          })
        );
    } else {
      dispatch(
        getPublishedTestRequest({
          skip: params?.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          category: params?.category === 'all' ? '' : params?.category,
          search: params?.search
        })
      );
    }
  }, [params, currentAssessment?.template]);

  const categories = useMemo(() => {
    if (!testCategories) {
      return;
    }
    const data = getWithSubcategories(testCategories, false);
    return params?.search?.length > 0 ? data : data.filter(({ name }) => name !== 'All Results');
  }, [testCategories, params]);

  const tests =
    params?.category === 'recommended'
      ? currentAssessment?.template
        ? recommendedTests
        : genericTemplates
      : publishedTests;

  const currentCategory = categories?.find(({ _id }) => params?.category === _id);

  useEffect(() => {
    setTestsDivHeight(testsDivRef.current.offsetHeight);
  }, [currentAssessment?.tests]);

  const addTestComponent = (data, generic = false) => (
    <div
      className="group ml-auto flex h-full min-w-[100px] cursor-pointer items-center justify-center
    border-l-[1px] border-[#D2D5D8] bg-[#F3F8F6] hover:bg-[#ECF6F1]"
      onClick={e => {
        e.stopPropagation();
        if (generic && testsStateChanged && currentAssessment?.tests?.length !== 0) {
          setGenericTests(data);
          setIsGenericModalOpen(true);
          return;
        }
        generic ? dispatch(updateTests(data)) : dispatch(addTestsToAssessment(data));
        !generic && setTestsStateChanged(true);
      }}>
      <div className="w-full text-center">
        <div key="faPlus">
          <i className="fa-solid fa-plus h-[16px] w-[16px] text-[16px] text-[#2F6E63] group-hover:text-[#12A182]"></i>
        </div>
        <div className="text-[14px] font-semibold text-[#2F6E63] group-hover:text-[#12A182]">
          {generic ? 'Use' : 'Add'}
        </div>
      </div>
    </div>
  );

  const removeTestComponent = id => (
    <div
      className="ml-auto flex h-full min-w-[100px] cursor-pointer items-center justify-center
    rounded border-l-[1px] border-[#D2D5D8] bg-[#FFF4F4] text-[#B31F13] hover:bg-[#FFF0F0]"
      onClick={e => {
        e.stopPropagation();
        setRemoveId(id);
        setConfirmationModalOpen(true);
      }}>
      <div className="w-full text-center">
        <div key="faTrash">
          <i className="fa-light fa-trash-can h-[16px] w-[16px] text-[16px] text-[#B31F13]"></i>
        </div>
        <div className="text-[14px] font-semibold">Remove</div>
      </div>
    </div>
  );

  return (
    <>
      <div className="sticky top-[75px] z-[3] flex flex-col gap-3 border-b border-[#D2D5D8] bg-white p-[32px]">
        <Typography style={{ color: '#6D7175' }} variant="heading-h4" title="Included Tests" />
        <div ref={testsDivRef} className={`flex flex-wrap gap-6`}>
          {currentAssessment?.tests?.map(({ _id, name, timeLimit, estimatedDuration, icon }) => (
            <div
              onClick={() => previewTestHandler(_id, true)}
              className="group flex h-[64px] min-w-[220px] cursor-pointer rounded border-[0.5px] border-[#C9CCCF] bg-[#FAFBFB] shadow-small"
              key={_id}>
              <div className="flex h-full min-w-[64px] max-w-[64px] cursor-pointer items-center justify-center rounded-l bg-[#F6F6F7] group-hover:bg-[#FFF0F0]">
                <img
                  className="px-[16px] py-[10px] group-hover:hidden"
                  src={icon?.url || defaultLogo}
                />
                <div
                  onClick={e => {
                    e.stopPropagation();
                    setRemoveId(_id);
                    setConfirmationModalOpen(true);
                  }}
                  className="hidden h-full w-full items-center justify-center px-[16px] py-[10px] hover:bg-red-100 group-hover:flex"
                  key="faTrash">
                  <i className="fa-light fa-trash-can h-[16px] w-[16px] text-[16px] text-[#B31F13]"></i>
                </div>
              </div>
              <div className="flex flex-col gap-[4px] px-[8px] py-[7px]">
                <Typography variant="heading-h6" title={name} />
                <TimeBadget
                  className="w-fit font-semibold"
                  time={(estimatedDuration ?? timeLimit) || '-'}
                  icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
                />
              </div>
            </div>
          ))}
          {currentAssessment?.tests?.length <= 5 &&
            [...Array(5 - currentAssessment?.tests?.length)].map((_, index) => (
              <div
                key={index}
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='1' stroke-dasharray='14' stroke-dashoffset='13' stroke-linecap='butt'/%3e%3c/svg%3e")`
                }}
                className="flex h-[64px] flex-1 flex-col items-center justify-center rounded bg-[#FAFBFB] text-[#6D7175]">
                <div className="flex items-center justify-center">
                  {currentAssessment?.tests?.length + 1 + index}
                  <span style={{ fontSize: '8px' }} className="mb-[6px] mr-1">
                    {numberSuffixes(currentAssessment?.tests?.length + 1 + index)}
                  </span>
                  test
                </div>
                {index === 0 && currentAssessment?.tests?.length === 0 && (
                  <p
                    onClick={() => setWhatToAddModalOpen(true)}
                    className="cursor-pointer text-xs text-[#007D6F] underline">
                    What to add?
                  </p>
                )}
              </div>
            ))}
        </div>
      </div>
      <LibraryCategories
        params={params}
        onScroll={onScroll}
        recommendedTestsLength={recommendedTests?.length}
        genericTemplatesLength={genericTemplates?.length}
        searchLoading={searchLoading}
        hasMore={hasMore}
        debounceSubmit={debounceSubmit}
        categories={categories}
        testsDivHeight={testsDivHeight}
        currentCategory={currentCategory}
        tests={tests}
        currentAssessment={currentAssessment}
        previewTestHandler={previewTestHandler}
        clearSearchHandler={clearSearchHandler}
        categoryClickHandler={categoryClickHandler}
        allTests={allTests}
        isNoTestComplete={isNoTestComplete}
        browseAllTestHandler={browseAllTestHandler}
        requestTestHandler={requestTestHandler}
        addTestComponent={addTestComponent}
        removeTestComponent={removeTestComponent}
        loading={load || loading}
        type="assessment"
      />
    </>
  );
};

export default TestsTab;
