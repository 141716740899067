import { useState } from 'react';

const defaultOptions = {
  isHoverable: false,
  // at some moment hover effect will be added, so it has to be added this hover status to statuses const
  // and modified onMouseEnver method to set up proper status
  isActiveStepHoverable: false
};

const statuses = {
  not_active: {
    fillColor: '#F6F6F7',
    textColor: '#082623',
    gradient: (
      <linearGradient
        id="paint0_linear_9890_226685"
        x1="0"
        y1="0"
        x2="285.752"
        y2="136.486"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#6BB4AB" />
        <stop offset="1" stopColor="#007D6F" />
      </linearGradient>
    )
  },
  active: {
    fillColor: 'url(#paint0_linear_9856_184300)',
    textColor: '#FFFFFF',
    gradient: (
      <linearGradient
        id="paint0_linear_9856_184300"
        x1="320"
        y1="49.8334"
        x2="1.5793e-08"
        y2="49.8334"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#6792D7" />
        <stop offset="0.473958" stopColor="#76AADE" />
        <stop offset="1" stopColor="#9FD7F5" />
      </linearGradient>
    )
  },
  hover: {
    fillColor: 'url(#paint0_linear_9856_184294)',
    textColor: '#12A182',
    gradient: (
      <linearGradient
        id="paint0_linear_9856_184294"
        x1="0"
        y1="0"
        x2="282.196"
        y2="132.154"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#F2FCF4" />
        <stop offset="1" stopColor="#EBF7F1" />
      </linearGradient>
    )
  },
  passed: {
    fillColor: '#E2F1EA',
    textColor: '#2F6E63',
    gradient: (
      <linearGradient
        id="paint0_linear_9890_226685"
        x1="0"
        y1="0"
        x2="285.752"
        y2="136.486"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#6BB4AB" />
        <stop offset="1" stopColor="#007D6F" />
      </linearGradient>
    )
  },
  declined: {
    fillColor: 'url(#paint0_linear_9890_65479)',
    textColor: '#FFFFFF',
    gradient: (
      <linearGradient
        id="paint0_linear_9890_65479"
        x1="0"
        y1="0"
        x2="293.143"
        y2="145.945"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FB8484" />
        <stop offset="1" stopColor="#B13737" />
      </linearGradient>
    )
  }
};

const stepsPaths = {
  first:
    'M282.554 -2H6C1.58172 -2 -2 1.58172 -2 6V46V86C-2 90.4183 1.58172 94 6 94H282.554C286.075 94 289.183 91.6978 290.208 88.3293L302.382 48.3293C302.844 46.8108 302.844 45.1892 302.382 43.6707L290.208 3.67071C289.183 0.30218 286.075 -2 282.554 -2Z',
  middle:
    'M0 -2H-2.7464L-1.90347 0.613845L12.3371 44.7723C12.5945 45.5705 12.5945 46.4295 12.3371 47.2277L-1.90347 91.3862L-2.7464 94H0H301.554C305.075 94 308.183 91.6978 309.208 88.3293L321.382 48.3293C321.844 46.8108 321.844 45.1892 321.382 43.6707L309.208 3.67071C308.183 0.302178 305.075 -2 301.554 -2H0Z',
  last: 'M0 -2H-2.77139L-1.89811 0.630209L12.747 44.7396C13.0187 45.5579 13.0187 46.4421 12.747 47.2604L-1.89811 91.3698L-2.77139 94H0H301C305.418 94 309 90.4183 309 86V6C309 1.58173 305.418 -2 301 -2H0Z'
};

/*
steps: [
  {
    title: string;
    subtitle?: string;
    onClick?: () => void; // custom function should be called on step click
    className?: string; // customClass for main component
    hoverClass?: string; // custom hover effect for passed and further steps
    hoverActiveClass?: string // custom hover effect for currently active step
    component?: () => ReactComponent // custom renderer function or default should be used

    // by default stepper uses statuses based no activeStepIndex, but you can override status of each step if it's needed
    status?: enum<{NOT_ACTIVE = 'not_active', ACTIVE = 'active', PASSED = 'passed', DECLINED = 'declined'}>;
  }
]
wrapper?: (children) => React.Component;
activeStepIndex: number = 0;
options?: {
    isHoverable?: boolean = false; // if default hover effect should be applied on non-active steps
}
*/
export const ArrowStepperComponent = ({
  steps = [],
  wrapper,
  activeStepIndex = 0,
  options = {}
}) => {
  const settings = { ...defaultOptions, ...options };
  const [stepsState, setStepsState] = useState(steps);

  const onMouseEnter = index => {
    if (!settings.isHoverable) return;
    if (index === activeStepIndex && !settings.isActiveStepHoverable) return;

    const stepsCopy = [...stepsState];
    stepsCopy[index].prevStatus = stepsCopy[index].status;
    stepsCopy[index].status = 'hover';
    setStepsState([...stepsCopy]);
  };

  const onMouseLeave = index => {
    if (!settings.isHoverable) return;
    if (index === activeStepIndex && !settings.isActiveStepHoverable) return;

    const stepsCopy = [...stepsState];
    stepsCopy[index].status = stepsCopy[index].prevStatus ?? undefined;
    if (stepsCopy[index].status) delete stepsCopy[index].status;
    setStepsState([...stepsCopy]);
  };

  const onClick = index => {
    onMouseLeave(index);
    steps[index].onClick && steps[index].onClick({ step: steps[index], index });
  };

  return (
    <>
      {stepsState &&
        stepsState.map((step, index) => {
          const isFirst = index === 0;
          const isLast = index === steps.length - 1;

          let status = statuses[step.status] ?? statuses.not_active;
          if (!step.status && index === activeStepIndex) status = statuses.active;
          if (!step.status && index < activeStepIndex) status = statuses.passed;

          let path = stepsPaths.first;
          if (!isFirst && !isLast) path = stepsPaths.middle;
          else if (isLast) path = stepsPaths.last;

          const component = (
            <div
              onClick={() => onClick(index)}
              onMouseEnter={() => onMouseEnter(index)}
              onMouseLeave={() => onMouseLeave(index)}
              className={`relative h-full w-full flex-1 shrink-0 cursor-pointer`}>
              <div className="h-full w-full">
                <svg
                  className={`overflow-visible rounded-[0px_0px_0px_0px] ${
                    !isFirst && 'ml-[-4px]'
                  }`}
                  viewBox={`0 0 ${isFirst ? '295' : isLast ? '305' : '310'} 92`}
                  preserveAspectRatio="none"
                  width={'100%'}
                  height={'100%'}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path d={path} fill={status.fillColor} stroke="white" strokeWidth="4" />

                  <defs>{status.gradient}</defs>
                </svg>

                {step.component ? (
                  step.component
                ) : (
                  <div className="absolute top-0 left-0 m-auto flex h-full w-full flex-col items-center justify-center gap-0.5 p-2.5">
                    <div className={`relative text-[${status.textColor}]`}>{step.title}</div>
                  </div>
                )}
              </div>
            </div>
          );

          return wrapper ? wrapper(component) : component;
        })}
    </>
  );
};

/* OLD ARROW STEPPER FOR HIRING COMPONENTS */

const HiringHeader = ({
  active,
  completed,
  mainText,
  secondaryText,
  isFirst,
  isLast,
  declined,
  hired,
  embedded,
  showFullMeasure,
  onClick
}) => (
  <div
    onClick={onClick}
    className={`relative ${embedded ? 'h-12' : 'h-[92px]'} ${
      isFirst && embedded && !showFullMeasure ? 'w-[84px]' : 'flex-1'
    } ${onClick && embedded && 'cursor-pointer'} shrink-0`}>
    <div className="absolute" style={{ inset: '0' }}>
      <svg
        className={`absolute left-0 top-0 overflow-visible rounded-[0px_0px_0px_0px] ${
          !isFirst && 'ml-[-4px]'
        }`}
        height="100%"
        width="100%"
        viewBox={`0 0 ${isFirst ? (embedded ? '94' : '295') : isLast ? '305' : '310'} 92`}
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        {isFirst && (
          <path
            d="M282.554 -2H6C1.58172 -2 -2 1.58172 -2 6V46V86C-2 90.4183 1.58172 94 6 94H282.554C286.075 94 289.183 91.6978 290.208 88.3293L302.382 48.3293C302.844 46.8108 302.844 45.1892 302.382 43.6707L290.208 3.67071C289.183 0.30218 286.075 -2 282.554 -2Z"
            fill={completed ? 'url(#paint0_linear_9856_184294)' : 'url(#paint0_linear_9856_184300)'}
            stroke="white"
            strokeWidth="4"
          />
        )}
        {isLast && (
          <path
            d="M0 -2H-2.77139L-1.89811 0.630209L12.747 44.7396C13.0187 45.5579 13.0187 46.4421 12.747 47.2604L-1.89811 91.3698L-2.77139 94H0H301C305.418 94 309 90.4183 309 86V6C309 1.58173 305.418 -2 301 -2H0Z"
            fill={
              hired
                ? 'url(#paint0_linear_9890_226685)'
                : active
                ? declined
                  ? 'url(#paint0_linear_9890_65479)'
                  : 'url(#paint0_linear_9856_184300)'
                : '#F6F6F7'
            }
            stroke="white"
            strokeWidth="4"
          />
        )}
        {!isFirst && !isLast && (
          <path
            d="M0 -2H-2.7464L-1.90347 0.613845L12.3371 44.7723C12.5945 45.5705 12.5945 46.4295 12.3371 47.2277L-1.90347 91.3862L-2.7464 94H0H301.554C305.075 94 308.183 91.6978 309.208 88.3293L321.382 48.3293C321.844 46.8108 321.844 45.1892 321.382 43.6707L309.208 3.67071C308.183 0.302178 305.075 -2 301.554 -2H0Z"
            fill={
              completed
                ? 'url(#paint0_linear_9856_184294)'
                : active
                ? declined
                  ? 'url(#paint0_linear_9890_65479)'
                  : 'url(#paint0_linear_9856_184300)'
                : '#F6F6F7'
            }
            stroke="white"
            strokeWidth="4"
          />
        )}
        <defs>
          {isLast && hired && (
            <linearGradient
              id="paint0_linear_9890_226685"
              x1="0"
              y1="0"
              x2="285.752"
              y2="136.486"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#6BB4AB" />
              <stop offset="1" stopColor="#007D6F" />
            </linearGradient>
          )}
          {completed && !active && (
            <linearGradient
              id="paint0_linear_9856_184294"
              x1="0"
              y1="0"
              x2="282.196"
              y2="132.154"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#F2FCF4" />
              <stop offset="1" stopColor="#EBF7F1" />
            </linearGradient>
          )}
          {active && !completed && (
            <linearGradient
              id="paint0_linear_9856_184300"
              x1="320"
              y1="49.8334"
              x2="1.5793e-08"
              y2="49.8334"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#6792D7" />
              <stop offset="0.473958" stopColor="#76AADE" />
              <stop offset="1" stopColor="#9FD7F5" />
            </linearGradient>
          )}
          {active && declined && (
            <linearGradient
              id="paint0_linear_9890_65479"
              x1="0"
              y1="0"
              x2="293.143"
              y2="145.945"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#FB8484" />
              <stop offset="1" stopColor="#B13737" />
            </linearGradient>
          )}
        </defs>
      </svg>

      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-0.5 p-2.5">
        <div
          className={`relative ${completed && 'text-[#2F6E63]'} ${active && 'text-[#FFFFFF]'} ${
            !active && !completed && 'text-[#082623]'
          } flex items-center justify-center self-stretch text-center ${
            embedded ? 'text-h5' : 'text-h4'
          }`}>
          {mainText}
        </div>

        {!embedded && (
          <div
            className={`relative text-sm ${completed && 'text-[#2F6E63]'} ${
              active && 'text-[#FFFFFF]'
            } ${
              !active && !completed && 'text-[#082623]'
            } flex w-[200px] items-center justify-center text-center`}>
            {secondaryText}
          </div>
        )}
      </div>
    </div>
  </div>
);

export const ArrowStepper = ({ step, declined, completed, embedded, showFullMeasure, onClick }) => {
  return (
    <div className="relative flex w-full shrink-0 flex-row items-start justify-start">
      <HiringHeader
        mainText="Measure"
        onClick={onClick}
        secondaryText="Wait until the candidate passed assessment."
        completed={step > 0}
        active={step === 0}
        embedded={embedded}
        showFullMeasure={showFullMeasure}
        isFirst
      />
      <HiringHeader
        mainText="Evaluate"
        secondaryText="Evaluate results and consider other factors."
        completed={step > 1}
        declined={declined}
        embedded={embedded}
        active={step === 1}
      />
      <HiringHeader
        mainText="Interview"
        secondaryText="Interview candidates in-person, by phone, or via video."
        completed={step > 2}
        declined={declined}
        embedded={embedded}
        active={step === 2}
      />
      <HiringHeader
        mainText="Hire"
        secondaryText="Hire the best fit after negotiation."
        hired={completed}
        declined={declined}
        active={step === 3}
        embedded={embedded}
        isLast
      />
    </div>
  );
};
