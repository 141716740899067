import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { MailEdit } from 'components/Forms/MailEditForm';
import { Button, ErrorMessage, Form, Input, Typography } from 'ui-kit';

import PasswordEdit from 'components/Forms/PasswordEditForm';
import AvatarPicker from 'components/AvatarPicker';
import Loader from 'components/Loader';

import { EditCompanySchema } from './validation';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { countriesRequest, getCompanyRequest, updateCompanyRequest } from 'store/auth/actions';

const CompanyEditForm = () => {
  const dispatch = useDispatch();

  const [isOpenMailEdit, setIsOpenMailEdit] = useState(false);
  const [isOpenPasswdEdit, setIsOpenPasswdEdit] = useState(false);

  const { company, countries, loading } = useSelector(store => store.auth);

  useEffect(() => {
    dispatch(getCompanyRequest());
    dispatch(countriesRequest());
  }, []);

  const onSubmit = data => {
    const { companyName, country } = data;
    dispatch(updateCompanyRequest({ name: companyName, country }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <Form
        resolver={yupResolver(EditCompanySchema)}
        defaultValues={{ ...company, companyName: company.name }}
        mode="onChange"
        onSubmit={onSubmit}
        className="flex flex-1 flex-col rounded-md border border-gray-300 bg-white p-6">
        <div className="flex flex-col gap-5 xl:flex-row">
          <div className="flex-1 space-y-5">
            <Typography title="Company logo" variant="heading-h5" />
            <AvatarPicker type="company" image={company?.logo} />
            <div className="flex flex-1 gap-5">
              <div className="flex-1">
                <Typography title="Company name" variant="small-default" />
                <Input name="companyName" />
                <ErrorMessage name="companyName" />
              </div>
              <div className="flex-1">
                <Typography title="Country" variant="small-default" />
                <SelectCustom name="country" options={countries && countries} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 flex justify-between">
          <Button type="submit" variant="primary" title="Save changes" />
        </div>
      </Form>
      <MailEdit isOpenModal={isOpenMailEdit} setIsOpenModal={setIsOpenMailEdit} />
      <PasswordEdit isOpenModal={isOpenPasswdEdit} setIsOpenModal={setIsOpenPasswdEdit} />
    </>
  );
};

export default CompanyEditForm;
