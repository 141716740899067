import React from 'react';
import { variants } from '../Typography/variants';

import cn from '../../utils/classNames';
import { HtmlTextReplacer } from "components/HtmlTextReplacer";

export interface TextAreaViewTypes {
    variant?: keyof typeof variants;
    color?: string;
    className?: string;
    title: string;
}

const TextAreaView: React.FC<TextAreaViewTypes> = ({
  variant,
  color,
  className,
  title,
}) => {
  const { className: typographyClassName } =
    (variant && variants[variant]) || {};

  return (
      <HtmlTextReplacer
          className={cn(
              typographyClassName,
              color && `text-${color}`,
              'break-words',
              className
          )}
          html={title}
      />
  );
};

export default TextAreaView;
