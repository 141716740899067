import React from 'react';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { stringToHslColor } from 'utils/stringToHslColor';

export const FormatOptionLabel = ({ label }: { label: string }) => (
  <div
    className="flex max-w-max items-center justify-center break-all rounded border px-[12px] py-[3px]"
    style={{
      background: stringToHslColor(label, 45, 85),
      borderColor: stringToHslColor(label, 35, 70)
    }}>
    <div>{label}</div>
  </div>
);

export const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 999
  }),
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      background: isFocused || isSelected ? '#F7F7F7' : '#FFFFFF',
      overflow: 'hidden',
      color: 'black',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled ? (isSelected ? '#FFFFFF' : '#F7F7F7') : undefined
      }
    };
  },
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      backgroundColor: '#FFFFFF',
      border: '1px solid #C9CCCF'
    };
  },
  multiValueRemove: (styles: any, { data }: any) => ({
    ...styles,
    color: '#C9CCCF',
    ':hover': {
      backgroundColor: 'none',
      color: '#8C9196'
    }
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    color: '#C9CCCF',
    ':hover': {
      color: '#8C9196'
    }
  }),
  control: () => ({
    display: 'flex',
    flexShrink: 0,
    border: '1px solid #C9CCCF',
    cursor: 'pointer',
    borderRadius: '6px',
    width: '100%',
    background: '#FFFFFF',
    minHeight: '44px',
    '*': {
      boxShadow: 'none !important'
    }
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
};

const multiValue = (styles: any, { data }: any) => {
  return {
    ...styles,
    backgroundColor: stringToHslColor(data.label, 45, 85),
    border: '1px solid',
    borderColor: stringToHslColor(data.label, 35, 70)
  };
};

interface SelectCustom {
  name: string;
  disabled: boolean;
  options: any[];
  isMulti?: boolean;
  placeholder?: string;
  defaultValue?: any;
  creatable?: boolean;
  onChangeValue?: (value: any) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  maxLength?: number;
  color?: boolean;
}

export const SelectCustom: React.FC<SelectCustom> = ({
  name,
  disabled,
  options,
  isClearable = true,
  isSearchable = true,
  isMulti = false,
  placeholder,
  maxLength = 40,
  defaultValue,
  creatable,
  color,
  onChangeValue
}) => (
  <Controller
    name={name}
    render={({ field }) => {
      return !creatable ? (
        <Select
          {...field}
          value={
            typeof field.value === 'string' || typeof field.value === 'number'
              ? options.find(item => item.value === field.value)
              : field.value
          }
          styles={color ? { ...customStyles, multiValue } : customStyles}
          onChange={value => {
            typeof field.value === 'string' || typeof field.value === 'number'
              ? field.onChange(value.value)
              : field.onChange(value);
            onChangeValue && onChangeValue(value);
          }}
          placeholder={placeholder}
          formatOptionLabel={(option, { context }) => {
            if (color && context === 'menu') {
              return <FormatOptionLabel label={option.label} />;
            }

            return option.label;
          }}
          isClearable={isClearable}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          isDisabled={disabled}
          isMulti={isMulti}
          options={options}
          components={{
            IndicatorSeparator: () => null,
            Input: props => <components.Input {...props} maxLength={maxLength} />
          }}
        />
      ) : (
        <CreatableSelect
          {...field}
          value={
            typeof field.value === 'string' || typeof field.value === 'number'
              ? options.find(item => item.value === field.value)
              : field.value
          }
          styles={color ? { ...customStyles, multiValue } : customStyles}
          onChange={value => {
            typeof field.value === 'string' || typeof field.value === 'number'
              ? field.onChange(value.value)
              : field.onChange(value);
            onChangeValue && onChangeValue(value);
          }}
          placeholder={placeholder}
          formatOptionLabel={(option, { context }) => {
            if (color && context === 'menu') {
              return <FormatOptionLabel label={option.label} />;
            }

            return option.label;
          }}
          isClearable={isClearable}
          isDisabled={disabled}
          defaultValue={defaultValue}
          isSearchable={isSearchable}
          isMulti={isMulti}
          options={options}
          components={{
            IndicatorSeparator: () => null,
            Input: props => <components.Input {...props} maxLength={maxLength} />
          }}
        />
      );
    }}
  />
);
