import { authConstants } from '../actions';

const initialState = {
  loading: false,
  error: null,
  resetPassword: false,
  emailVerified: false,
  resetPasswordSuccess: false,
  login: false,
  showOnboard: false,
  user: {
    subscribeForEmails: true
  },
  subscriptionPlan: {},
  upgradeModalOpen: false,
  successModalOpen: false,
  company: {},
  countries: [],
  faqs: []
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.RESET_ERRORS:
      return {
        ...state,
        error: null
      };

    // REQUEST ACTIONS
    case authConstants.GET_COUNTRIES_REQUEST:
    case authConstants.GET_PROFILE_REQUEST:
    case authConstants.GET_COMPANY_REQUEST:
    case authConstants.UPDATE_PROFILE_REQUEST:
    case authConstants.PROFILE_COMPLETE_REQUEST:
    case authConstants.UPDATE_ADMIN_PROFILE_REQUEST:
    case authConstants.UPDATE_COMPANY_REQUEST:
    case authConstants.UPDATE_PROFILE_IMAGE_REQUEST:
    case authConstants.UPDATE_COMPANY_IMAGE_REQUEST:
    case authConstants.DELETE_PROFILE_IMAGE_REQUEST:
    case authConstants.DELETE_COMPANY_IMAGE_REQUEST:
    case authConstants.UPDATE_PROFILE_EMAIL_REQUEST:
    case authConstants.UPDATE_PROFILE_PASSWORD_REQUEST:
    case authConstants.PASSWORD_RECOVERY_REQUEST:
    case authConstants.VERIFY_EMAIL_REQUEST:
    case authConstants.RESEND_EMAIL_REQUEST:
    case authConstants.NEW_PASSWORD_REQUEST:
    case authConstants.REQUEST_ACCESS_REQUEST:
    case authConstants.REGISTER_REQUEST:
    case authConstants.GET_FAQ_REQUEST:
      return {
        ...state,
        loading: true
      };

    case authConstants.LOGOUT:
      return initialState;
    case authConstants.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        user: { ...state.user, ...action.payload.data },
        login: false
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        login: true,
        emailVerified: !!action.payload.verifiedAt,
        error: null
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        login: false,
        error: action.payload
      };
    case authConstants.CONFIRM_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        emailVerified: false
      };
    case authConstants.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        emailVerified: true,
        error: null
      };
    case authConstants.CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        emailVerified: false,
        error: action.payload
      };
    case authConstants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        loading: false,
        error: null
      };
    case authConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        emailVerified: !!action.payload.verifiedAt,
        error: null
      };
    case authConstants.GET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload,
        error: null
      };
    case authConstants.UPDATE_PROFILE_SUCCESS:
    case authConstants.PROFILE_COMPLETE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    case authConstants.UPDATE_ADMIN_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    case authConstants.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: false,
        error: null
      };
    case authConstants.UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null
      };
    case authConstants.UPDATE_COMPANY_IMAGE_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: false,
        error: null
      };
    case authConstants.DELETE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, image: null },
        loading: false,
        error: null
      };
    case authConstants.DELETE_COMPANY_IMAGE_SUCCESS:
      return {
        ...state,
        company: { ...state.company, logo: null },
        loading: false,
        error: null
      };
    case authConstants.UPDATE_PROFILE_EMAIL_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload.data },
        loading: false,
        error: null
      };
    case authConstants.UPDATE_PROFILE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case authConstants.PASSWORD_RECOVERY_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPassword: true,
        error: null
      };
    case authConstants.PASSWORD_RECOVERY_FAILURE:
      return {
        ...state,
        loading: false,
        resetPassword: false,
        error: action.payload
      };
    case authConstants.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case authConstants.RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case authConstants.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.payload },
        login: true,
        emailVerified: !!action.payload.verifiedAt,
        resetPasswordSuccess: true,
        error: null
      };
    case authConstants.NEW_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        resetPasswordSuccess: false,
        error: action.payload
      };
    case authConstants.REQUEST_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case authConstants.RESET_AUTH_ERROR:
      return {
        ...state,
        error: null
      };
    case authConstants.SAVE_USER_DATA:
      return {
        ...state,
        user: { ...state.user, ...action.payload }
      };
    case authConstants.SET_SHOW_ONBOARD:
      return {
        ...state,
        showOnboard: action.payload
      };
    case authConstants.SET_UPGRADE_MODAL_OPEN:
      return {
        ...state,
        upgradeModalOpen: action.payload
      };
    case authConstants.SET_SUCCESS_MODAL_OPEN:
      return {
        ...state,
        successModalOpen: action.payload
      };
    case authConstants.UPDATE_USER_REWARDS:
      return {
        ...state,
        user: { ...state.user, rewards: { ...state.user.rewards, ...action.payload } }
      };
    case authConstants.GET_FAQ_SUCCESS:
      return {
        ...state,
        loading: false,
        faqs: [...action.payload]
      };

    // FAIL ACTIONS
    case authConstants.REGISTER_FAILURE:
    case authConstants.GET_COUNTRIES_FAILURE:
    case authConstants.GET_PROFILE_FAILURE:
    case authConstants.GET_COMPANY_FAILURE:
    case authConstants.UPDATE_PROFILE_FAILURE:
    case authConstants.PROFILE_COMPLETE_FAILURE:
    case authConstants.UPDATE_ADMIN_PROFILE_FAILURE:
    case authConstants.UPDATE_COMPANY_FAILURE:
    case authConstants.UPDATE_PROFILE_IMAGE_FAILURE:
    case authConstants.UPDATE_COMPANY_IMAGE_FAILURE:
    case authConstants.DELETE_PROFILE_IMAGE_FAILURE:
    case authConstants.DELETE_COMPANY_IMAGE_FAILURE:
    case authConstants.UPDATE_PROFILE_EMAIL_FAILURE:
    case authConstants.UPDATE_PROFILE_PASSWORD_FAILURE:
    case authConstants.VERIFY_EMAIL_FAILURE:
    case authConstants.RESEND_EMAIL_FAILURE:
    case authConstants.REQUEST_ACCESS_FAILURE:
    case authConstants.GET_FAQ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}
