import { useState } from 'react';

import { useOutsideClick } from 'hooks/useOutsideClick/useOutsideClick';
import arrows from 'assets/icons/arrows.svg';

export const TextEditorSelect = ({ title, onClick, options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);
  return (
    <span className="relative inline-block">
      <button
        className={`flex gap-[3px] ${
          isOpen && 'bg-[#F5F5F5]'
        } rounded border-none bg-transparent px-[4px] text-sm text-[#082623] hover:bg-[#F1F3F3]`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(prev => !prev);
        }}>
        {title}
        <img src={arrows} />
      </button>
      <div
        className={`absolute z-10 mt-1 flex w-max flex-col rounded border border-[#C9CCCF] bg-white py-[2px] px-[1px] shadow-sm ${
          isOpen ? 'block' : 'hidden'
        }`}>
        {options.map(item => (
          <span
            key={item.value}
            onMouseDown={e => {
              e.preventDefault();
              onClick(item);
            }}
            className="cursor-pointer px-[12px] py-[4px] text-sm text-[#082623] hover:bg-[#F5F5F5]"
            value={item.label}>
            {item.label}
          </span>
        ))}
      </div>
    </span>
  );
};
