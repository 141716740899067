import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Form, Input, Button, ErrorMessage, Typography } from 'ui-kit';

import { yupResolver } from '@hookform/resolvers/yup';
import { SignUpSchema } from 'components/Forms/validation';

import { registerRequest, resetAuthErrors, saveUserData, resetErrors } from 'store/auth/actions';
import authImage1x from 'assets/authImage@1x.png';
import authImage2x from 'assets/authImage@2x.png';

import { Logo } from 'ui-kit/Logo';
import * as routes from 'routes/routes';
import { useEffect } from 'react';

const SignUpForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, error } = useSelector(store => store.auth);

  const onSubmit = data => {
    dispatch(registerRequest({ data, navigate }));
  };

  useEffect(() => () => dispatch(resetErrors()), []);

  return (
    <div className="flex h-full items-center justify-center">
      <img
        className="rounded-md"
        src={authImage1x}
        srcSet={`${authImage1x} 1x, ${authImage2x} 2x`}
      />
      <Form
        className="flex h-full max-w-[526px] flex-1 px-[64px] py-[32px]"
        mode="all"
        defaultValues={{ ...user }}
        onUnMount={data => dispatch(saveUserData(data))}
        resolver={yupResolver(SignUpSchema)}
        onSubmit={onSubmit}>
        {({ getValues, watch }) => (
          <div className="flex w-full flex-col">
            <div className="flex items-center justify-center">
              <Logo />
            </div>
            <div className="flex flex-1 items-center justify-center">
              <div>
                <Typography
                  className="text-center"
                  variant="heading-h2"
                  title="Get started with your Free plan"
                />
                <Typography
                  variant="small-default"
                  className="mb-[20px] text-center text-[#6D7175]"
                  title="No credit card required"
                />
                <div>
                  <Typography title="Email" variant="regular-default" />
                  <Input
                    onChangeValue={() => {
                      if (error) {
                        dispatch(resetAuthErrors());
                      }
                    }}
                    errorValidation={!!error}
                    name="email"
                    placeholder="myname@company.com"
                  />
                  {error && (
                    <Typography
                      variant="regular-default"
                      className="text-red-600"
                      title={error.response?.data[0].msg}
                    />
                  )}
                  {!error && <ErrorMessage name="email" />}
                </div>
                <div className="flex flex-col items-center gap-[12px]">
                  <Button
                    className="w-full"
                    type="submit"
                    variant="primary"
                    title="Create an Account"
                  />
                  <Typography className="text-center text-[#6D7175]" variant="small-default">
                    By creating an account, you confirm that you have read and agree to Hirenest's
                    <a
                      href="https://hirenest.com/privacy-policy"
                      target="_blank"
                      className="pl-1 text-green-400 underline"
                      rel="noreferrer">
                      privacy policy
                    </a>
                  </Typography>
                </div>
              </div>
            </div>
            <div className="text-center text-sm font-normal text-[#6D7175]">
              Already have an account?{' '}
              <Link
                to={routes.AUTH + '/' + routes.SIGNIN}
                className="text-sm font-normal text-[#007D6F] underline">
                Login
              </Link>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default SignUpForm;
