import moment from 'moment';
import CreditCard from 'assets/credit-card.svg';

const cardIcons = {
  visa: 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Old_Visa_Logo.svg',
  mastercard: 'https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg',
  default: CreditCard
};

const PaidSubscriptionInfo = ({ subscription }) => {
  if (!subscription) return <></>;

  return (
    <>
      <div className="mt-[6px] flex justify-between text-[18px] text-[#6D7175]">
        <div>{subscription.type === 'month' ? 'Monthly' : 'Annual'} Plan</div>
        <div>
          {subscription.type === 'month'
            ? `$${subscription.cost}/month`
            : `$${subscription.cost} ($${Math.round(subscription.cost / 12)}/month)`}
        </div>
      </div>
      <div className="my-[24px] h-[1px] border-t border-[#D2D5D8]" />
      {subscription.card && subscription.card.last4 && (
        <div className="flex gap-[24px]">
          <div className="flex-1 text-[16px]">
            <div>
              Billed <b>once per {subscription.type}.</b>
            </div>
            <div>
              Next billing on <b>{moment(subscription.expiresAt).format('MMM DD,YYYY')}.</b>
            </div>
          </div>
          <div className="flex flex-1 gap-[12px] rounded-[4px] border bg-[#FAFBFB] p-[6px]">
            <div>
              <img
                src={cardIcons[subscription?.card?.brand] ?? cardIcons.default}
                className="h-[43px] w-[43px]"
              />
            </div>
            <div>
              <div className="text-[14px] font-semibold">
                {subscription.card?.brand.charAt(0).toUpperCase() +
                  subscription.card?.brand.slice(1)}{' '}
                ending in {subscription.card?.last4}
              </div>
              <div className="text-[12px] text-[#6D7175]">
                Expires: {subscription.card?.exp_month}/{subscription.card?.exp_year}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-[24px] text-[14px] text-[#6D7175]">
        To cancel your current subscription just Downgrade to Free Plan.
      </div>
    </>
  );
};

export default PaidSubscriptionInfo;
