import Breadcrumbs from 'components/Breadcrumbs';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTestCategoryRequest,
  editTestCategoryRequest,
  getTestCategoriesRequest
} from 'store/configuration/actions/subscriptions';
import { Button, Typography } from 'ui-kit';
import { TestCategoryCard } from './TestCategoryCard/TestCategoryCard';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import CreateTestCategoryForm from 'components/Forms/CreateTestTypeForm';

const testCategoryInitial = {
  name: '',
  description: '',
  visible: true
};

export const ConfigurationTestCategories = () => {
  const dispatch = useDispatch();
  const { testCategories, loading } = useSelector(store => store.subscriptions);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState({ open: false, parent: null });
  const [openTestCategory, setOpenTestCategory] = useState(testCategoryInitial);

  useEffect(() => {
    dispatch(getTestCategoriesRequest());
  }, []);

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    dispatch(
      editTestCategoryRequest({
        id: result.draggableId,
        data: { index: result.destination.index }
      })
    );
  };

  const handleCreateTestType = () => setIsFormModalOpen({ open: true, parent: null });

  const handleEditTestCategory = data => {
    setOpenTestCategory(data);
    setIsFormModalOpen({ open: true, parent: !data?.subcategories });
  };

  const handleDeleteTestCategory = data => {
    setOpenTestCategory(data);
    setConfirmationModalOpen(true);
  };

  const handleCloseFormModal = () => {
    setIsFormModalOpen({ open: false, parent: null });
    setOpenTestCategory(testCategoryInitial);
  };

  const handleCloseConfirmModal = () => {
    setConfirmationModalOpen(false);
    setOpenTestCategory(testCategoryInitial);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteTestCategoryRequest(openTestCategory?._id));
    setConfirmationModalOpen(false);
    setOpenTestCategory(testCategoryInitial);
  };

  return (
    <div className="w-full px-6 py-9">
      <Breadcrumbs type="test-type" />
      {loading && <Loader />}
      <div className="flex gap-3">
        <Typography title="Test Categories" variant="subheader" />
        <Button
          className="border border-[#007D6F] font-semibold text-[#007D6F]"
          onClick={handleCreateTestType}
          variant="create"
          title="Create Category"
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="categories" type="droppable-category">
          {provided => (
            <div
              className="my-6 flex flex-col gap-6"
              {...provided.droppableProps}
              ref={provided.innerRef}>
              {testCategories.map((props, index) => (
                <Draggable key={props?._id} draggableId={props?._id} index={index}>
                  {provided => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}>
                      <TestCategoryCard
                        setIsFormModalOpen={setIsFormModalOpen}
                        deleteAction={handleDeleteTestCategory}
                        edit={handleEditTestCategory}
                        {...props}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <CreateTestCategoryForm
        isOpenModal={isFormModalOpen.open}
        parent={isFormModalOpen.parent}
        setIsOpenModal={handleCloseFormModal}
        defaultValue={openTestCategory}
      />
      <ConfirmationModal
        isConfirmModalOpen={confirmationModalOpen}
        setConfirmModalOpen={handleCloseConfirmModal}
        headerText={`Delete ${!openTestCategory?.subcategories ? 'Subcategory' : 'Category'}`}
        bodyText={
          <p>
            delete <b>{openTestCategory?.name}</b>?
          </p>
        }
        buttonText={`Delete ${!openTestCategory?.subcategories ? 'Subcategory' : 'Category'}`}
        buttonAction={handleConfirmDelete}
        danger
      />
    </div>
  );
};
