import { Link } from 'react-router-dom';

import * as routes from 'routes/routes';

import { Typography, Button } from 'ui-kit';
import { badgetStatus, calculatePercent } from 'utils/helpers';

export const AssessmentCard = ({ assessment, handleOpenInviteCandidates }) => {
  const widthPercentCalculation = calculatePercent(
    assessment.invited,
    assessment.started,
    assessment.completed
  );

  return (
    <Link to={routes.ASSESSMENTS + `/${assessment._id}`}>
      <div
        className={`group hover:shadow-3xl} relative mb-6 flex h-[132px] w-full cursor-pointer 
        flex-col justify-between break-words rounded-md border border-gray-400 bg-white p-6 pb-3 pt-[36px]
        shadow transition-all`}>
        <span
          style={{ borderRadius: '6px 0px 0px 6px' }}
          className={`absolute inset-y-0 left-0 ${badgetStatus(
            assessment.status
          )} w-[6px] rounded-r font-semibold capitalize`}
        />
        {(assessment.demo?.demoDaysLeft || assessment.daysLeft) && (
          <span
            style={{ boxShadow: '0px 1px 2px rgba(112, 145, 179, 0.4)' }}
            className={`absolute left-6 top-0 rounded-b bg-[#E0ECF9] py-[2px] px-[10px] font-semibold capitalize text-[#337195]`}>
            {`${assessment.demo?.demoDaysLeft || assessment.daysLeft} days left`}
          </span>
        )}
        <div className="flex justify-between">
          <Typography
            className="max-w-[800px] truncate transition-all group-hover:text-[#12A182]"
            style={{ wordBreak: 'break-word' }}
            variant="heading-h4"
            title={assessment.demo?.isDemo ? `${assessment.name}` : assessment.name}
          />
          <div className="flex items-center gap-3">
            {!assessment.demo?.isDemo && (
              <Button
                className="relative top-[-17px] flex h-[33px] w-[145px] items-center justify-center overflow-hidden
                    rounded-lg border border-[#C9CCCF] bg-[#FFFFFF] py-1.5 px-3 text-[14px] hover:bg-gray-200"
                onClick={e => {
                  e.preventDefault();
                  handleOpenInviteCandidates && handleOpenInviteCandidates(assessment._id);
                }}>
                Invite Candidates
              </Button>
            )}
          </div>
        </div>
        <div className="flex w-full items-end justify-end gap-8">
          {assessment.applications > 0 && (
            <div className="flex min-w-[180px] flex-col gap-[6px]">
              <Typography className="text-[#6D7175]" variant="small-default" title="Applications" />
              <Typography variant="small-default" title={assessment.applications} />
            </div>
          )}
          {(assessment.invited > 0 || assessment.started > 0 || assessment.completed > 0) && (
            <div className="flex w-full">
              <div
                style={{ width: widthPercentCalculation(assessment.invited) }}
                className="flex min-w-[15%] flex-col gap-1">
                <Typography className="text-[#6D7175]" variant="small-default" title="Invited" />
                <div className="max-h-[26px] flex-1 rounded-l-sm bg-[#E4E5E7] pl-[10px]">
                  <Typography
                    variant="small-default"
                    className="font-semibold text-[#5B3F00]"
                    title={assessment.invited}
                  />
                </div>
              </div>
              <div
                style={{ width: widthPercentCalculation(assessment.started) }}
                className="flex min-w-[15%] flex-col gap-1">
                <Typography className="text-[#6D7175]" variant="small-default" title="Started" />
                <div className="max-h-[26px] flex-1 bg-[#FFD79D] pl-[10px]">
                  <Typography
                    variant="small-default"
                    className="font-semibold text-[#5B3F00]"
                    title={assessment.started}
                  />
                </div>
              </div>
              <div
                style={{ width: widthPercentCalculation(assessment.completed) }}
                className="flex min-w-[20%] flex-col gap-1">
                <Typography className="text-[#6D7175]" variant="small-default" title="Completed" />
                <div className="max-h-[26px] flex-1 rounded-r-sm bg-[#AEE9D1] pl-[10px]">
                  <Typography
                    variant="small-default"
                    className="font-semibold text-[#5B3F00]"
                    title={assessment.completed}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
