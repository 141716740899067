import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from 'ui-kit';

import DefaultUserImage from 'components/DefaultUserImage';
import UploadPopUpForm from 'components/Forms/UploadAvatartPopUpForm';
import { useLocation } from 'react-router-dom';

import { deleteCompanyImageRequest, deleteProfileImageRequest } from 'store/auth/actions';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const AvatarPicker = ({ type, image }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();

  const deleteAvatarHandler = () => {
    if (type === 'user') {
      dispatch(deleteProfileImageRequest());
    } else {
      dispatch(deleteCompanyImageRequest());
    }
  };

  return (
    <div className="flex items-end">
      <div className={`${location?.pathname !== '/my-profile' && 'max-w-[40%]'} space-y-4`}>
        <div className="relative">
          <DefaultUserImage
            size={250}
            fullWidth={location?.pathname === '/my-profile'}
            url={image}
          />
          {image && (
            <Button
              onClick={deleteAvatarHandler}
              icon={faTrashCan}
              iconSize={24}
              className="absolute right-0 bottom-0 transition-all hover:scale-125 hover:duration-300"
            />
          )}
        </div>
        <Button
          onClick={() => setIsOpen(true)}
          variant="secondary"
          title={type === 'user' ? 'Change Photo' : 'Change logo'}
        />
      </div>
      <UploadPopUpForm isOpen={isOpen} setIsOpenModal={setIsOpen} type={type} />
    </div>
  );
};

export default AvatarPicker;
