import { useCallback, useEffect, useRef } from 'react';

type useDebouceCallbackType = (callback: (value: unknown) => void, delay?: number) => void;

export const useDebouceCallback: useDebouceCallbackType = (callback, delay = 500) => {
  const timer = useRef<NodeJS.Timeout>();
  const func = useRef(callback);

  const debounced = useCallback(
    (v: unknown) => {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => func.current(v), delay);
    },
    [delay]
  );

  useEffect(() => {
    func.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return debounced;
};
