import Breadcrumbs from 'components/Breadcrumbs';
import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSingleOccupationRequest } from 'store/configuration/actions/subscriptions';
import { getCurrentTestRequest } from 'store/tests/actions';
import { Button, Section, Typography } from 'ui-kit';
import { ChipList } from 'ui-kit/ChipList/ChipList';
import { OccupationCard } from '../OccupationCard/OccupationCard';

// const TemplateCard = () => (
//   <div className="relative flex flex-col gap-[12px] rounded-md border border-[#C9CCCF] p-4 pt-8">
//     <div className="absolute top-0 left-4.5 rounded-b bg-[#007D6F] px-[10px] py-[2px]">
//       <Typography className="text-white" variant="small-default" title="Published" />
//     </div>
//     <Typography variant="heading-h5" title="Accountants and Auditors" />
//     <div className="flex gap-8">
//       <div className="flex-col justify-between">
//         <Typography className="text-[#6D7175]" variant="small-default" title="Recommended Tests" />
//         <Typography variant="regular-default" title="8" />
//       </div>
//       <div className="flex-col justify-between">
//         <Typography className="text-[#6D7175]" variant="small-default" title="Custom Questions" />
//         <Typography variant="regular-default" title="-" />
//       </div>
//     </div>
//   </div>
// );

export const OccupationSingle = () => {
  const { _id } = useParams();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const dispatch = useDispatch();

  const { occupation } = useSelector(store => store.subscriptions);

  const {
    title,
    code,
    jobTitles,
    tasks,
    technology_skills,
    tools_used,
    work_activities,
    detailed_work_activities,
    work_contexts,
    job_zone,
    skills,
    knowledge,
    abilities,
    interests,
    work_values,
    work_styles,
    related_occupations
  } = occupation;

  const previewTestHandler = id => {
    if (!id) {
      return;
    }
    dispatch(getCurrentTestRequest(id));
    setViewModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSingleOccupationRequest(_id));
  }, [_id]);
  return (
    <div className="w-full space-y-[24px] px-6 py-9">
      <Breadcrumbs type="occupation" />
      <div className="flex justify-between">
        <Typography title={title} variant="subheader" />
        <a
          href={`https://www.onetonline.org/link/details/${code}`}
          target="_blank"
          rel="noreferrer">
          <Button icon="onet" className="bg-white" variant="primary-outline" title="onetonline.org" />
        </a>
      </div>
      <div className="flex gap-[24px]">
        <Section className="flex-2">
          <Section.Body className="space-y-[24px]">
            <div className="flex flex-col gap-[6px]">
              <Typography variant="regular-emphasis" title={code} />
              <Typography
                variant="regular-default"
                title="Examine, analyze, and interpret accounting records to prepare financial statements, give advice, or audit and evaluate statements prepared by others. Install or advise on systems of recording costs or other financial and budgetary data."
              />
            </div>
            {/* <div className="flex flex-col gap-[6px]">
              <Typography variant="regular-emphasis" title="Lists" />
              <div className="flex gap-2">
                <ChipList name="Rapid Growth" />
                <ChipList name="Numerous Job Openings" />
              </div>
            </div> */}
            {jobTitles?.length > 0 && (
              <div className="flex flex-col gap-[6px]">
                <Typography variant="regular-emphasis" title="Job Titles" />
                <div className="flex flex-wrap gap-2">
                  {jobTitles.map(item => (
                    <ChipList key={item} name={item} />
                  ))}
                </div>
              </div>
            )}
          </Section.Body>
        </Section>
        {/* <Section className="max-h-[290px] flex-1">
          <Section.Header>
            <Typography variant="heading-h3" title="Used in Template" />
          </Section.Header>
          <Section.Body className="space-y-[12px]">
            <TemplateCard />
            <Typography variant="small-default" title="2 More Templates" />
          </Section.Body>
        </Section> */}
      </div>
      {tasks?.length > 0 && (
        <OccupationCard
          arr={tasks.sort((a, b) => b.importance - a.importance)}
          previewTestHandler={previewTestHandler}
          name="Tasks"
        />
      )}
      {technology_skills?.length > 0 && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title="Technology Skills" />
          </Section.Header>
          <Section.Body className="space-y-[24px]">
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-emphasis" title="Skills" />
            </div>
            {technology_skills.map(({ title, items, _id }) => (
              <div key={_id} className="flex gap-8">
                <Typography className="flex-1" variant="regular-default" title={title} />
                <div className="flex flex-2 flex-col">
                  <ul className="list-disc space-y-[4px]">
                    {items.map(({ title, _id, mappedTests }) => (
                      <li key={_id}>
                        <Typography
                          onClick={() => previewTestHandler(mappedTests[0])}
                          variant={mappedTests?.length > 0 ? 'underline' : 'regular-default'}
                          className={`${
                            mappedTests?.length > 0 && 'cursor-pointer text-green-400'
                          }`}
                          title={title}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </Section.Body>
        </Section>
      )}
      {tools_used?.length > 0 && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title="Tools Used" />
          </Section.Header>
          <Section.Body className="space-y-[24px]">
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-emphasis" title="Tools" />
              <Typography className="flex-2" variant="regular-emphasis" title="Description" />
            </div>
            {tools_used.map(({ title, items }) => (
              <div key={_id} className="flex gap-8">
                <Typography className="flex-1" variant="regular-default" title={title} />
                <div className="flex flex-2 flex-col gap-2">
                  {items?.length > 0 &&
                    items.map(({ _id, title, mappedTests }) => (
                      <Typography
                        key={_id}
                        onClick={() => previewTestHandler(mappedTests[0])}
                        variant={mappedTests?.length > 0 ? 'underline' : 'regular-default'}
                        className={`${mappedTests?.length > 0 && 'cursor-pointer text-green-400'}`}
                        title={title}
                      />
                    ))}
                </div>
              </div>
            ))}
          </Section.Body>
        </Section>
      )}
      {work_activities?.length > 0 && (
        <OccupationCard
          arr={work_activities}
          previewTestHandler={previewTestHandler}
          name="Work Activities"
        />
      )}
      {detailed_work_activities?.length > 0 && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title="Detailed Work Activities" />
          </Section.Header>
          <Section.Body className="space-y-[24px]">
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-emphasis" title="Tasks" />
            </div>
            {detailed_work_activities.map(({ title, _id }) => (
              <Typography key={_id} className="flex-1" title={title} />
            ))}
          </Section.Body>
        </Section>
      )}
      {work_contexts?.length > 0 && (
        <OccupationCard
          arr={work_contexts}
          previewTestHandler={previewTestHandler}
          name="Work Context"
        />
      )}
      {job_zone && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title="Job Zone" />
          </Section.Header>
          <Section.Body className="space-y-[24px]">
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-default" title="Title" />
              <Typography className="flex-2" variant="regular-default" title={job_zone?.title} />
            </div>
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-default" title="Education" />
              <Typography className="flex-2" variant="regular-default" title={job_zone?.education} />
            </div>
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-default" title="Related Experience" />
              <Typography
                className="flex-2"
                variant="regular-default"
                title={job_zone?.relatedExperience}
              />
            </div>
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-default" title="Job Training" />
              <Typography className="flex-2" variant="regular-default" title={job_zone?.jobTraining} />
            </div>
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-default" title="Job Zone Examples" />
              <Typography
                className="flex-2"
                variant="regular-default"
                title={job_zone?.jobZoneExamples}
              />
            </div>
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-default" title="SVP Range" />
              <Typography className="flex-2" variant="regular-default" title={job_zone?.svpRange} />
            </div>
          </Section.Body>
        </Section>
      )}
      {skills?.length > 0 && (
        <OccupationCard arr={skills} previewTestHandler={previewTestHandler} name="Skills" />
      )}
      {knowledge?.length > 0 && (
        <OccupationCard arr={knowledge} previewTestHandler={previewTestHandler} name="Knowledge" />
      )}
      {abilities?.length > 0 && (
        <OccupationCard arr={abilities} previewTestHandler={previewTestHandler} name="Abilities" />
      )}
      {interests?.length > 0 && (
        <OccupationCard arr={interests} previewTestHandler={previewTestHandler} name="Interests" />
      )}
      {work_values?.length > 0 && (
        <OccupationCard
          arr={work_values}
          previewTestHandler={previewTestHandler}
          name="Work Values"
        />
      )}
      {work_styles?.length > 0 && (
        <OccupationCard
          arr={work_styles}
          previewTestHandler={previewTestHandler}
          name="Work Styles"
        />
      )}
      {related_occupations?.length > 0 && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title="Related Occupations" />
          </Section.Header>
          <Section.Body className="space-y-[24px]">
            <div className="flex gap-8">
              <Typography className="flex-1" variant="regular-emphasis" title="Occupation" />
            </div>
            {related_occupations.map(({ title, _id }) => (
              <Typography key={_id} className="flex-1" variant="regular-default" title={title} />
            ))}
          </Section.Body>
        </Section>
      )}
      <ViewTestModal isOpenModal={viewModalOpen} setIsOpenModal={setViewModalOpen} />
    </div>
  );
};
