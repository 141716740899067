import { Route, Routes } from 'react-router-dom';

import Library from 'pages/Author/Tests/Library';

import { Common } from 'layouts';

import * as routes from './routes';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import { AssessmentTemplatesLibrary } from 'pages/Author/AssessmentTemplates/AssessmentTemplatesLibrary/AssessmentTemplatesLibrary';
import { AssessmentTemplateCreate } from 'pages/Author/AssessmentTemplates/AssessmentTemplateCreate/AssessmentTemplateCreate';
import { AssessmentTemplatesView } from 'pages/Author/AssessmentTemplates/AssessmentTemplatesView/AssessmentTemplatesView';
import { CreateTest } from 'pages/Author/Tests/Test/Create';
import { PreviewTest } from 'pages/Author/Tests/Test/Preview';
import { EditTest } from 'pages/Author/Tests/Test/Edit';
import { ViewTest } from 'pages/Author/Tests/Test/View';

const AuthorRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Common />
          </PrivateRoute>
        }>
        <Route path={routes.TESTS} element={<Library />} />
        <Route path={routes.ASSESSMENT_TEMPLATES} element={<AssessmentTemplatesLibrary />} />
        <Route path={routes.ASSESSMENT_TEMPLATES_CREATE} element={<AssessmentTemplateCreate />} />
        <Route path={routes.ASSESSMENT_TEMPLATES_EDIT} element={<AssessmentTemplateCreate />} />
        <Route path={routes.ASSESSMENT_TEMPLATES_VIEW} element={<AssessmentTemplatesView />} />
        <Route path={routes.CREATE_TEST} element={<CreateTest />} />
        <Route path={routes.VIEW_TEST} element={<ViewTest />} />
        <Route path={routes.PUBLISH_TEST} element={<PreviewTest />} />
        <Route path={routes.EDIT_TEST} element={<EditTest />} />
      </Route>
    </Routes>
  );
};

export default AuthorRoutes;
