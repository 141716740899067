import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHourglassHalf, faStopwatch } from '@fortawesome/pro-solid-svg-icons';

import { Typography, Button, Icon } from 'ui-kit';
import { TestEditor } from '../components/TestEditor';
import { SaveNewTest, CreateNewTest } from 'components/Forms/validation';

import {
  deleteDraftTestRequest,
  getCurrentTestRequest,
  getTestVerionsRequest,
  updateAreasOfUse,
  updateSuitableFor,
  updateTestRequest
} from 'store/tests/actions';
import { badgetStatus, callToast } from 'utils/helpers';
import { clearSection } from 'helpers/clearSection';
import Loader from 'components/Loader';
import Breadcrumbs from 'components/Breadcrumbs';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt/useCallbackPrompt';
import ConfirmChangesModal from 'components/ConfirmChangesModal/ConfirmChangesModal';
import TimeBadget from 'components/TimeBadget';
import VersionsTestHistory from 'components/Modals/VersionsTestHistory/VersionsTestHistory';
import { ButtonSplit } from 'ui-kit/ButtonSplit/ButtonSplit';
import { localStorageService } from 'helpers/localStorageService';

export const EditTest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const role = localStorageService.getRole();

  const { _id: id } = useParams();

  const { currentTest, currentTestVersions, showDialog, loading } = useSelector(
    store => store.tests
  );
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  const onSubmit = (preview, confirm = false) => {
    if (!currentTest?.name) {
      callToast('error', 'Test Name is required');
      return;
    }
    let exist = false;
    const data = {
      test: clearSection({
        ...currentTest
      })
    };

    currentTest.tags.map(tag => {
      const tags = currentTest.sections
        .flatMap(section => section.questions)
        .flatMap(question => question.tags);
      const isExist = tags.some(({ value }) => value === tag.value);
      if (!isExist) {
        callToast('error', `Result for ${tag.label} tag exist without questions`);
        exist = !isExist;
      }
    });

    if (exist) {
      return;
    }

    const newAreas = data.test?.areasOfUse?.filter(item => item?.__isNew__ && item);
    const newSuitable = data.test?.suitableFor?.filter(item => item?.__isNew__ && item);

    try {
      preview ? CreateNewTest.validateSync(data.test) : SaveNewTest.validateSync(data.test);

      dispatch(updateTestRequest({ id, data, navigate, preview, confirm }));
      newAreas && dispatch(updateAreasOfUse(newAreas));
      newSuitable && dispatch(updateSuitableFor(newSuitable));
    } catch (e) {
      callToast('error', e.errors.at(0));
    }
  };

  const openVersionHistoryModal = () => {
    dispatch(getTestVerionsRequest(currentTest?._id)), setIsOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCurrentTestRequest(id));
  }, [id]);

  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      <Breadcrumbs type="draftTest" />
      <div className="flex justify-between">
        <div className="flex items-center gap-3">
          <div className="max-w-[700px]">
            <Typography variant="heading-h1" title={currentTest?.name} />
          </div>
          <span
            className={`rounded ${badgetStatus(
              currentTest?.status
            )} flex h-[22px] items-center justify-center py-[1px] px-[6px] text-xs font-semibold`}>
            {`#${currentTest?.version?.number} ${currentTest?.status?.toUpperCase()}`}
          </span>
          <TimeBadget
            className="font-semibold"
            time={currentTest?.estimatedDuration ?? currentTest?.timeLimit}
            icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]"/>}
          />
          <TimeBadget
            className="font-semibold"
            time={currentTest?.timeLimit}
            icon={<Icon icon={faHourglassHalf} size={16} className="text-[#2F6E63]"/>}
          />
        </div>
        <div className="flex items-center gap-3">
          <Button variant="secondary" onClick={openVersionHistoryModal} title="Versions History" />
          <ButtonSplit
            onClickMain={() => onSubmit(false)}
            onClickSecond={() =>
              dispatch(deleteDraftTestRequest({ id: currentTest?._id, navigate }))
            }
            variant="secondary"
            titleMain="Save Draft"
            titleSecond="Discard Draft"
          />
          <Button onClick={() => onSubmit(true)} variant="primary" title="Preview & Publish" />
        </div>
      </div>
      <TestEditor />
      <ConfirmChangesModal
        isConfirmModalOpen={showPrompt}
        closeConfirmModal={cancelNavigation}
        bodyText={currentTest?.name || 'Test'}
        confirmNavigation={confirmNavigation}
        buttonAction={() => onSubmit(false, true)}
      />
      {!loading && currentTestVersions?.length > 0 && (
        <VersionsTestHistory
          arr={currentTestVersions}
          current={currentTest?._id}
          isOpen={isOpen}
          setOpen={setIsOpen}
        />
      )}
    </div>
  );
};
