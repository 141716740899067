import { all } from 'redux-saga/effects';

import authWatcher from './auth/sagas';
import adminWatcher from './admin/sagas';
import assessmentWatcher from './assessment/sagas';
import candidateWatcher from './candidates/sagas';
import testsWatcher from './tests/sagas';
import subscriptionsWatcher from './configuration/sagas';

export default function* rootSaga() {
  yield all([
    authWatcher(),
    adminWatcher(),
    assessmentWatcher(),
    candidateWatcher(),
    testsWatcher(),
    subscriptionsWatcher()
  ]);
}
