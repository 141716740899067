import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Typography, Tab, Button, Section, Icon } from 'ui-kit';

import { SectionList } from 'components/SectionList';
import Breadcrumbs from 'components/Breadcrumbs';

import Loader from 'components/Loader';
import TimeBadget from 'components/TimeBadget';

import {
  archiveTestRequest,
  createNewVersionTestRequest,
  getCurrentTestRequest,
  getTestVerionsRequest
} from 'store/tests/actions';
import { badgetStatus, callToast } from 'utils/helpers';
import VersionsTestHistory from 'components/Modals/VersionsTestHistory/VersionsTestHistory';
import { TestViewSummary } from 'components/TestViewSummary/TestViewSummary';
import { TestViewResults } from 'components/TestViewResults/TestViewResults';
import { TestViewSettings } from 'components/TestViewSettings/TestViewSettings';
import { TestViewWebsite } from 'components/TestViewWebsite/TestViewWebsite';
import { EvaluateModal } from 'components/EvaluateModal/EvaluateModal';
import { clearEvaluateQuestions, getEvaluateQuestionsRequest } from 'store/assessment/actions';
import { IconTest } from 'components/IconTest/IconTest';
import { CoverTest } from 'components/CoverTest/CoverTest';
import { localStorageService } from 'helpers/localStorageService';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

export const ViewTest = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEvaluateModalOpen, setIsEvaluateModalOpen] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorageService.getRole();

  const { currentTest, currentTestVersions, loading } = useSelector(store => store.tests);
  const { evaluateQuestions, load } = useSelector(store => store.assessments);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 150);
    }, 500);
  }, []);

  useEffect(() => {
    if (isEvaluateModalOpen) {
      dispatch(getEvaluateQuestionsRequest(params?._id));
    } else {
      dispatch(clearEvaluateQuestions());
    }
  }, [isEvaluateModalOpen, params?._id]);

  const hasDraftVersion = useMemo(() => {
    return currentTestVersions?.some(item => item?.status === 'draft');
  }, [currentTestVersions]);

  useEffect(() => {
    dispatch(getCurrentTestRequest(params._id));
    role === 'author' && dispatch(getTestVerionsRequest(params._id));
    setIsOpen(false);
  }, [params._id, role]);

  const openVersionHistoryModal = () => {
    setIsOpen(true);
  };

  const createNewTestVerionsHandler = () => {
    dispatch(createNewVersionTestRequest({ id: currentTest?._id, navigate }));

    if (!hasDraftVersion) {
      callToast('success', 'Success - new version was created');
    }
  };

  const archiveTestHandler = () => {
    dispatch(
      archiveTestRequest({ id: params._id, data: { test: { status: 'archived' } }, navigate })
    );
  };

  return (
    <>
      {(loading || load) && <Loader />}
      <div className="bg-[#FFFFFF]">
        <CoverTest size={330} url={currentTest?.cover?.url} />
        <div className="py-8 px-7.5">
          <Breadcrumbs type="test" />
          <div className="my-3 flex justify-between">
            <div className="flex w-full justify-between">
              <div className="flex gap-3">
                <IconTest url={currentTest?.icon?.url} size={72} />
                <div className="flex items-center gap-3">
                  <Typography variant="heading-h1" title={currentTest?.name} />
                  <span
                    className={`rounded ${badgetStatus(
                      currentTest?.status
                    )} flex h-[22px] items-center justify-center py-[1px] px-[6px] text-xs font-semibold`}>
                    {`${
                      role === 'author' ? `#${currentTest?.version?.number}` : ''
                    } ${currentTest?.status?.toUpperCase()}`}
                  </span>
                  <TimeBadget
                    className="inline-flex w-auto font-semibold"
                    time={(currentTest?.estimatedDuration ?? currentTest?.timeLimit) || '-'}
                    icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
                  />
                </div>
              </div>
              <div className="flex items-center gap-2">
                {role === 'author' && (
                  <>
                    <Button
                      variant="secondary"
                      onClick={openVersionHistoryModal}
                      title="Versions History"
                    />
                    {currentTest?.status === 'published' && (
                      <Button variant="secondary" onClick={archiveTestHandler} title="Archive" />
                    )}
                    <Button
                      variant="primary"
                      onClick={createNewTestVerionsHandler}
                      title={hasDraftVersion ? 'Edit Draft' : 'Create Draft'}
                    />
                  </>
                )}
                {role === 'employer' && (
                  <Button
                    variant="secondary"
                    title="Evaluate Questions"
                    onClick={() => setIsEvaluateModalOpen(true)}
                  />
                )}
              </div>
            </div>
            {evaluateQuestions?.length > 0 && (
              <EvaluateModal
                testName={currentTest?.name}
                testIcon={currentTest?.icon?.url}
                isOpen={isEvaluateModalOpen}
                setIsOpen={() => setIsEvaluateModalOpen(false)}
                questions={evaluateQuestions}
              />
            )}
          </div>
          <div className="text-lg">{currentTest?.tagline}</div>
        </div>
      </div>
      <div className="py-8 px-7.5">
        <Tab.Group as="div" className="pt-7">
          {role !== 'employer' && (
            <Tab.List className="mb-6 flex gap-6">
              <Tab>
                <Typography variant="heading-h4" title="Summary" />
              </Tab>
              <Tab>
                <Typography
                  variant="heading-h4"
                  title={`Questions (${currentTest?.questionsCount})`}
                />
              </Tab>
              <Tab>
                <Typography variant="heading-h4" title="Results" />
              </Tab>
              <Tab>
                <Typography variant="heading-h4" title="Settings" />
              </Tab>
              <Tab>
                <Typography variant="heading-h4" title="Website" />
              </Tab>
              {role !== 'employer' && (
                <div className="flex flex-1 justify-end">
                  <Button
                    className="flex max-h-[33px] max-w-max items-center justify-center px-[12px] py-[6px] text-sm font-medium"
                    variant="secondary"
                    title="Evaluate Questions"
                    onClick={() => setIsEvaluateModalOpen(true)}
                  />
                </div>
              )}
            </Tab.List>
          )}
          <Tab.Panels>
            <Tab.Panel className="flex gap-6">
              <TestViewSummary currentTest={currentTest && currentTest} role={role && role} />
            </Tab.Panel>
            <Tab.Panel>
              <Section>
                <Section.Header>
                  <Typography variant="heading-h2" title="Questions" />
                </Section.Header>
                <Section.Body>
                  <SectionList
                    hidden={currentTest.sections?.length === 1}
                    sections={currentTest.sections}
                  />
                </Section.Body>
              </Section>
            </Tab.Panel>
            <Tab.Panel>
              <TestViewResults currentTest={currentTest && currentTest} />
            </Tab.Panel>
            <Tab.Panel>
              <TestViewSettings currentTest={currentTest && currentTest} />
            </Tab.Panel>
            <Tab.Panel>
              <TestViewWebsite currentTest={currentTest} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
        {!loading && currentTestVersions?.length > 0 && (
          <VersionsTestHistory
            arr={currentTestVersions}
            current={currentTest?._id}
            isOpen={isOpen}
            setOpen={setIsOpen}
          />
        )}
      </div>
    </>
  );
};
