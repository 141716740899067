import moment from 'moment';
import { calculateColorHirenestScore } from 'pages/Employer/CreateAssessment_old/components/HiringStatuses/HiringStatuses';
import { Typography } from 'ui-kit';

const tableHeader = [
  { label: 'Candidate', value: 'candidate' },
  {
    label: 'Score',
    value: 'score',
    sortable: true,
    customClass: 'bg-[#E2F1EA]'
  },
  { label: 'Assessment', value: 'assessment' },
  { label: 'Completed On', value: 'completed' }
];

const ApplicationTable = ({
  applications,
  handleSort,
  sortOrder,
  navigateToApplication,
  navigateToAssessment,
  sortBy = 'score' // could be customizable for other similar tables, but currently hardcoded for this one
}) => {
  return (
    <table className="min-h-[100px] w-full border border-gray-200 bg-white shadow-med">
      <thead className="top-[75px] bg-[#F6F6F7]">
        <tr>
          <th className={`border-b py-3 px-4`}></th>
          {tableHeader?.map(({ value, label, customClass, sortable }) => (
            <th
              key={value}
              className={`group cursor-pointer border-b py-3 px-4 text-start ${customClass ?? ''}`}
              onClick={() => sortable && handleSort(value)}>
              <div className="flex gap-2">
                <div className="text-base font-medium text-[#082623]">{label}</div>
                {sortable &&
                  sortBy === value &&
                  (sortOrder ? (
                    <div key="chevron-up">
                      <i className="fa-regular fa-chevron-up text-xs text-[#5C5F62]"></i>
                    </div>
                  ) : (
                    <div key="chevron-down">
                      <i className="fa-regular fa-chevron-down text-xs text-[#5C5F62]"></i>
                    </div>
                  ))}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {applications?.map(({ assessment, candidate, completedAt, hirenestScore, _id }) => (
          <tr key={_id}>
            <td className="cursor-pointer border-b p-3">
              <div
                className="flex h-[27px] w-[27px] items-center justify-center rounded-full bg-[#EDEFEF] text-sm font-medium text-[#6D7175]"
                onClick={() => navigateToApplication(_id)}>
                {candidate?.name[0].toUpperCase()}
              </div>
            </td>
            <td
              className="cursor-pointer border-b p-3 text-[#007D6F]"
              onClick={() => navigateToApplication(_id)}>
              {candidate?.name}
            </td>
            <td
              className={`min-w-[100px] border-b p-3 text-center ${
                calculateColorHirenestScore(hirenestScore)?.title
              }`}>
              {hirenestScore === -1 || hirenestScore === null || hirenestScore === undefined
                ? 'N/A'
                : hirenestScore.toFixed(0) + '%'}
            </td>
            <td className="cursor-pointer border-b p-3 text-[#007D6F]">
              <p className="cursor-pointer" onClick={() => navigateToAssessment(assessment?._id)}>
                {assessment?.name}
              </p>
            </td>
            <td className="min-w-[180px] border-b p-3">{moment(completedAt).format('MMM D')}</td>
          </tr>
        ))}

        {applications?.length === 0 && (
          <tr>
            <td colSpan={9}>
              <Typography
                className="text-center text-[#6D7175]"
                variant="regular-default"
                title="No Applications to Display"
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ApplicationTable;
