import React from 'react';
import { Typography, Button, Icon } from 'ui-kit';

import cn from 'utils/classNames';

import { variants } from './variants';

interface IChip {
  onDelete?: () => void;
  deleteIcon?: any;
  title: string;
  onClick?: () => void;
  icon?: any;
  active?: boolean;
  variant?: keyof typeof variants;
  smile?: string;
  className?: string;
}

const Chip: React.FC<IChip> = ({
  onDelete,
  deleteIcon,
  icon,
  title,
  onClick,
  active,
  smile,
  variant = 'default',
  className
}) => {
  const handleDelete = (e: Event) => {
    e.stopPropagation();
    onDelete?.();
  };

  const chip = variants[variant];

  return (
    <div
      onClick={onClick}
      className={cn(active ? chip.active : chip.noActive, onClick && chip.onClick, chip.className, className)}>
      {icon && <Icon icon={icon} size={14} />}
      {smile && <span>{smile}</span>}
      <Typography variant={chip.titleVariant} title={title} />
      {deleteIcon && onDelete && (
        <Button onClick={handleDelete} className={chip.deleteIcon}>
          <Icon icon={deleteIcon} size={chip.iconSize} />
        </Button>
      )}
    </div>
  );
};

export default Chip;
