import { yupResolver } from '@hookform/resolvers/yup';
import Loader from 'components/Loader';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdminProfileRequest } from 'store/auth/actions';
import { Button, ErrorMessage, Form, Input, Typography } from 'ui-kit';
import PasswordEdit from './PasswordEditForm';
import { EditAdminSchema } from './validation';

export const AdminProfileEditForm = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector(store => store.auth);
  const [isOpenPasswdEdit, setIsOpenPasswdEdit] = useState(false);

  const onSubmit = data => {
    dispatch(updateAdminProfileRequest(data));
  };
  return (
    <div className="max-w-[600px] flex-1 rounded-lg border border-[#C9CCCF] bg-[#FFFFFF] p-[32px]">
      {loading && <Loader />}
      <Form
        onSubmit={onSubmit}
        defaultValues={{
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email
        }}
        resolver={yupResolver(EditAdminSchema)}
        mode="onChange">
        {({ formState }) => (
          <>
            <div>
              <Typography title="First Name" variant="small-default" />
              <Input name="firstName" />
              <ErrorMessage name="firstName" />
            </div>
            <div>
              <Typography title="Last Name" variant="small-default" />
              <Input name="lastName" />
              <ErrorMessage name="lastName" />
            </div>
            <div>
              <Typography title="Email" variant="small-default" />
              <Input name="email" />
              <ErrorMessage name="email" />
            </div>
            <div className="mt-[24px] flex gap-[12px]">
              <Button
                disabled={!formState.isDirty}
                variant="primary"
                type="submit"
                title="Save Profile"
              />
              <Button
                variant="primary-outline"
                onClick={() => setIsOpenPasswdEdit(true)}
                title="Change password"
              />
            </div>
          </>
        )}
      </Form>
      <PasswordEdit isOpenModal={isOpenPasswdEdit} setIsOpenModal={setIsOpenPasswdEdit} />
    </div>
  );
};
