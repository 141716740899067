import { useModal } from 'hooks';

import { Button, Former, List, Radio, Typography, InputFile, Toggle } from 'ui-kit';
import { AnswerType } from 'pages/Author/Tests/Test/components/AnswerType';
import { DeleteAttachment } from 'pages/Author/Tests/Test/components/DeleteAttachment';
import { createNewQuestion } from 'components/Forms/validation';

import { timeLimit } from 'config/selectConfig';
import { questionType } from 'config/form';
import { callToast } from 'utils/helpers';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { Tooltip } from 'react-tooltip';
import deepEqual from 'deep-equal';
import ConfirmChangesModal from 'components/ConfirmChangesModal/ConfirmChangesModal';
import { useEffect, useRef, useState } from 'react';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

const AnswersPlaceholder = ({ onClick, type }) => (
  <div className="flex flex-col gap-[12px]">
    <div className="flex gap-[10px]">
      <input
        onClick={onClick}
        checked
        className={`h-5 w-5 cursor-pointer ${
          type === 'single' ? 'rounded-full' : 'rounded-sm'
        } border-[#8C9196] focus:ring-0 focus:ring-offset-0`}
        type={type === 'single' ? 'radio' : 'checkbox'}
      />
      <Typography title="Option A" variant="regular-default" />
    </div>
    <div className="flex gap-[10px]">
      <input
        onClick={onClick}
        className={`h-5 w-5 cursor-pointer ${
          type === 'single' ? 'rounded-full' : 'rounded-sm'
        } border-[#8C9196] focus:ring-0 focus:ring-offset-0`}
        type={type === 'single' ? 'radio' : 'checkbox'}
      />
      <Typography title="Option B" variant="regular-default" />
    </div>
    <div className="flex gap-[10px]">
      <input
        onClick={onClick}
        checked={type === 'single' ? false : true}
        className={`h-5 w-5 cursor-pointer ${
          type === 'single' ? 'rounded-full' : 'rounded-sm'
        } border-[#8C9196] focus:ring-0 focus:ring-offset-0`}
        type={type === 'single' ? 'radio' : 'checkbox'}
      />
      <Typography title="Option C" variant="regular-default" />
    </div>
  </div>
);

const initialValues = {
  distributePoints: false,
  answerTime: undefined,
  essayLimit: 5000,
  text: '',
  answers: [{ score: 0, text: '' }],
  tags: [],
  type: '',
  timeLimit: 60000
};

export const CustomQuestionCreate = ({
  onSubmit,
  type,
  close,
  questionsLength = null,
  defaultValues = initialValues
}) => {
  const deleteAttachment = useModal({ initialState: false });
  const [showConfirm, setShowConfirm] = useState(false);
  const addButtonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
        addButtonRef.current.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const save = (onSubmit, reset) => d => {
    setShowConfirm(false);
    if (!d.answers.some(({ score }) => score !== 0) && d.type !== 'essay') {
      callToast('error', 'Choose the correct answer');
      return;
    }
    try {
      createNewQuestion.validateSync(d);
      reset({
        ...defaultValues
      });
      onSubmit(d);
      close();
    } catch (e) {
      callToast('error', e?.errors?.at(0));
    }
  };

  return (
    <Former defaultValues={{ ...defaultValues }}>
      {({ handleSubmit, setValue, watch, reset, getValues }) => (
        <>
          <Former.Form
            id="test"
            className="rounded-md border border-[#D2D5D8] bg-[#FAFBFB] p-8"
            onSubmit={onSubmit}>
            <div className="flex gap-6">
              <div className="mt-[-5px] flex flex-2 flex-col gap-[2px] lg:min-w-[50%]">
                <Typography title="Question" variant="heading-h5" />
                <RichTextEditor maxLength={2000} classNameWrapper="bg-white mt-[4px]" name="text" />
              </div>
              <div className="mt-[27px]">
                {!watch('attachment') ? (
                  <InputFile
                    onChangeValue={file => setValue('attachment', file)}
                    className="w-full"
                    component={() => (
                      <div className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded border border-[#8C9196] bg-white p-3 hover:bg-[#F6F6F7]">
                        <i className="fa-light fa-paperclip-vertical text-base"></i>
                      </div>
                    )}
                  />
                ) : (
                  <div className="group relative max-h-[140px] max-w-[140px] cursor-pointer rounded-lg border border-[#C9CCCF]">
                    <Button
                      className="absolute left-[50%] top-[50%] z-10 hidden translate-y-[-50%] translate-x-[-50%] group-hover:block"
                      iconClassName="fill-white w-[22px] h-[22px]"
                      icon={faTrashCan}
                      onClick={deleteAttachment.open}
                    />
                    <div
                      onClick={deleteAttachment.open}
                      className="absolute inset-0 h-full w-full rounded-lg group-hover:bg-black/70"
                    />
                    <img
                      className="h-[100%] w-[100%] rounded-lg object-contain"
                      src={watch('attachment.url')}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-[20px]">
                <div className="flex gap-[24px]">
                  <div className="flex flex-col gap-[2px]">
                    <Typography title="Question Time Limit" variant="regular-default" />
                    <SelectCustom
                      placeholder="Time"
                      isClearable={false}
                      options={timeLimit}
                      name="timeLimit"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[24px] space-y-2">
              <div className="flex w-full items-center gap-[36px]">
                {!watch('type') ? (
                  <List
                    wrap="div"
                    className="flex w-full justify-between gap-6"
                    keyExtractor={({ value }) => value}
                    data={questionType}
                    element={({ label, value }) => (
                      <Radio
                        className="flex-1"
                        component={() => (
                          <div className="flex h-full w-full flex-col gap-[18px] rounded border-[0.5px] border-[#C9CCCF] bg-white p-4 shadow-small hover:border-[#999EA4] hover:bg-[#FCFCFC] hover:shadow-med">
                            <Typography title={label} variant="heading-h5" />
                            {value === 'single' && (
                              <AnswersPlaceholder
                                onClick={() => setValue('type', value)}
                                type={value}
                              />
                            )}
                            {value === 'multiple' && (
                              <AnswersPlaceholder
                                onClick={() => setValue('type', value)}
                                type={value}
                              />
                            )}
                            {value === 'essay' && (
                              <textarea
                                data-hj-allow
                                style={{ wordBreak: 'break-word' }}
                                readOnly
                                onClick={() => {
                                  setValue('type', value);
                                }}
                                placeholder="Text..."
                                className="flex min-h-[86px] w-full flex-1 cursor-pointer resize-none break-words rounded border border-[#C9CCCF] bg-[#FFFFFF] px-[13px] py-[7px] outline-none placeholder:text-gray-400 focus:border-[#C9CCCF] focus:ring-0"
                              />
                            )}
                          </div>
                        )}
                        value={value}
                        onChangeValue={() =>
                          reset(formValues => ({
                            ...formValues,
                            answers: formValues.answers.map(item => {
                              return formValues.distributePoints
                                ? { ...item }
                                : { ...item, score: 0 };
                            })
                          }))
                        }
                        name="type"
                      />
                    )}
                  />
                ) : (
                  <div
                    className="mt-[12px] w-[160px] cursor-pointer"
                    onClick={() => setValue('type', '')}>
                    <Typography
                      variant="heading-h5"
                      title={questionType?.find(item => item.value === watch('type'))?.label}
                    />
                    <Typography
                      variant="small-default"
                      className="text-[#007D6F] underline"
                      title="Change"
                    />
                  </div>
                )}
                {watch('type') && watch('type') !== 'essay' && (
                  <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                      <Toggle shouldUnregister name="isShuffleAnswers" />
                      <Typography variant="heading-h6" title="Shuffle Choices" />
                    </div>
                    <i
                      data-tooltip-id="shuffle-tooltip"
                      data-tooltip-content="When enabled, each candidate will see choices in random order."
                      data-tooltip-place="top"
                      className="fa-regular fa-circle-question cursor-pointer text-base text-[#8C9196]"></i>
                    <Tooltip className="max-w-[270px] bg-black text-sm" id="shuffle-tooltip" />
                  </div>
                )}
                {watch('type') && watch('type') !== 'essay' && (
                  <>
                    <div className="mt-[4px] flex items-center gap-[12px]">
                      <div className="flex gap-4">
                        <Toggle
                          name="distributePoints"
                          placeholder={
                            <Typography title="Distribute Points" variant="heading-h6" />
                          }
                          onChangeValue={value => {
                            !value &&
                              watch('type') !== 'single' &&
                              reset(formValues => ({
                                ...formValues,
                                answers: formValues.answers.map(item => {
                                  return { ...item, score: item.score > 0 ? 1 : 0 };
                                })
                              }));
                            !value &&
                              watch('type') === 'single' &&
                              reset(formValues => ({
                                ...formValues,
                                answers: formValues.answers.map(item => {
                                  return { ...item, score: 0 };
                                })
                              }));
                          }}
                        />
                      </div>
                      {watch('distributePoints') && (
                        <div className="flex">
                          <div
                            data-tooltip-id="ask"
                            data-tooltip-content="Ascending"
                            data-tooltip-place="top"
                            onClick={() => {
                              reset(formValues => ({
                                ...formValues,
                                answers: formValues.answers.map((item, idx) => {
                                  return { ...item, score: idx + 1 };
                                })
                              }));
                            }}
                            className="h-[36px] w-[36px] cursor-pointer rounded rounded-r-none border border-[#8C9196] bg-white p-[6px]">
                            <i className="fa-solid fa-arrow-down-small-big text-lg text-[#5C5F62]"></i>
                            <Tooltip className="max-w-[270px] bg-black text-sm" id="ask" />
                          </div>
                          <div
                            data-tooltip-id="desk"
                            data-tooltip-content="Descending"
                            data-tooltip-place="top"
                            onClick={() => {
                              reset(formValues => ({
                                ...formValues,
                                answers: formValues.answers.map((item, idx) => {
                                  return { ...item, score: formValues.answers.length - idx };
                                })
                              }));
                            }}
                            className="h-[36px] w-[36px] cursor-pointer rounded rounded-l-none border border-l-0 border-[#8C9196] bg-white p-[6px]">
                            <i className="fa-solid fa-arrow-up-big-small text-lg text-[#5C5F62]"></i>
                            <Tooltip className="max-w-[270px] bg-black text-sm" id="desk" />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="space-y-2">
              <AnswerType name="type" />
            </div>
            <div className="mt-6 flex items-center justify-center gap-3">
              <Button
                className="disabled:bg-transparent"
                onClick={() => {
                  const values = getValues();
                  if (deepEqual(values, defaultValues, { strict: true })) {
                    reset({
                      ...defaultValues
                    });
                    close();
                  } else {
                    setShowConfirm(true);
                  }
                }}
                disabled={questionsLength === 0}
                title="Cancel"
                variant="secondary"
              />
              <Button
                ref={addButtonRef}
                onClick={handleSubmit(save(onSubmit, reset))}
                disabled={!watch('type')}
                className="disabled:bg-white"
                title={type === 'edit' ? 'Save Question' : 'Create Question'}
                variant="primary"
              />
            </div>
          </Former.Form>
          <ConfirmChangesModal
            isConfirmModalOpen={showConfirm}
            closeConfirmModal={() => setShowConfirm(false)}
            bodyText={'Question'}
            buttonText={type === 'edit' ? 'Edit' : 'Save'}
            closeNavigation={() => {
              setShowConfirm(false);
              close();
            }}
            buttonAction={handleSubmit(save(onSubmit, reset))}
          />
          <DeleteAttachment
            isOpen={deleteAttachment.isOpen}
            onSubmit={() => {
              setValue('attachment', '');
              deleteAttachment.close();
            }}
            close={deleteAttachment.close}
          />
        </>
      )}
    </Former>
  );
};
