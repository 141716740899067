import Loader from 'components/Loader';
import { useDebouceCallback, useQueryParams } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTestCategoriesRequest } from 'store/configuration/actions/subscriptions';
import {
  getPublishedTestRequest,
  getTestByImportanceRequest,
  clearTests,
  getCurrentTestRequest,
  getAllTestsRequest,
  testRequestRequest
} from 'store/tests/actions';
import { Typography } from 'ui-kit';
import { getWithSubcategories } from 'utils/getWithSubcategories';
import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';
import { LibraryCategories } from 'components/LibraryCategories/LibraryCategories';

const perPage = 30;

export const TestsLibraryEmployer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFirstSearch, setIsFirstSearch] = useState(false);
  const [isNoTestComplete, setIsNoTestComplete] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, testCategories } = useSelector(store => store.subscriptions);
  const {
    loading: load,
    publishedTests,
    recommendedTests,
    publishedTestsTotal,
    allTests
  } = useSelector(store => store.tests);

  useEffect(() => {
    !loading && setSearchLoading(false);
  }, [loading]);

  const hasMore = useMemo(() => {
    return publishedTests?.length >= publishedTestsTotal ? false : true;
  }, [publishedTestsTotal, publishedTests]);

  const { params, set } = useQueryParams({ search: '', category: 'recommended' });

  const debounceSubmit = useDebouceCallback(value => {
    setSearchLoading(true);
    setPage(0);
    dispatch(clearTests());
    set({ ...params, ...value });
  });

  useEffect(() => {
    dispatch(clearTests());
  }, []);

  const previewTestHandler = id => {
    dispatch(getCurrentTestRequest(id));
    setIsModalOpen(true);
  };

  const clearSearchHandler = () => {
    set({ ...params, search: '' });
    setIsNoTestComplete(false);
  };

  const categoryClickHandler = id => {
    dispatch(clearTests());
    setPage(0);
    set({ ...params, category: id });
  };

  const browseAllTestHandler = () => {
    setIsNoTestComplete(false);
    navigate(routes.TESTS);
  };

  const requestTestHandler = data => {
    dispatch(testRequestRequest(data));
    setIsNoTestComplete(true);
  };

  useEffect(() => {
    if (params?.category === 'recommended' && recommendedTests?.length > 0) {
      return;
    }
    if (params?.category !== 'recommended' && publishedTests?.length > 0) {
      return;
    }
    dispatch(
      getAllTestsRequest({
        sortBy: 'updatedAt',
        category: '',
        search: params?.search
      })
    );
  }, [publishedTests, recommendedTests]);

  useEffect(() => {
    if (params?.search === '' && params?.category === 'all') {
      set({ ...params, category: 'recommended' });
    }
    dispatch(getTestCategoriesRequest({ search: params?.search }));
    if (!isFirstSearch && params?.search !== '') {
      categoryClickHandler('all');
      setIsFirstSearch(true);
    }
  }, [params?.search]);

  useEffect(() => {
    if (params?.category === 'recommended') {
      dispatch(getTestByImportanceRequest({ params: { search: params?.search, perPage: -1 } }));
    } else {
      dispatch(
        getPublishedTestRequest({
          skip: page * perPage,
          perPage,
          sortBy: 'updatedAt',
          category: params?.category === 'all' ? '' : params?.category,
          search: params?.search
        })
      );
    }
  }, [params, page]);

  const onScroll = () => {
    setPage(prev => prev + 1);
  };

  const categories = useMemo(() => {
    if (!testCategories) {
      return;
    }
    const data = getWithSubcategories(testCategories);
    return params?.search?.length > 0 ? data : data.filter(({ name }) => name !== 'All Results');
  }, [testCategories, params]);

  const tests = params?.category === 'recommended' ? recommendedTests : publishedTests;

  const currentCategory = categories?.find(({ _id }) => params?.category === _id);
  return (
    <div className="py-8 px-7.5">
      {loading && !searchLoading && <Loader />}
      <div className="flex flex-col gap-[24px]">
        <Typography variant="heading-h1" title="Hirenest Tests Library" />
        <LibraryCategories
          params={params}
          onScroll={onScroll}
          searchLoading={searchLoading}
          hasMore={hasMore}
          debounceSubmit={debounceSubmit}
          categories={categories}
          currentCategory={currentCategory}
          tests={tests}
          previewTestHandler={previewTestHandler}
          clearSearchHandler={clearSearchHandler}
          categoryClickHandler={categoryClickHandler}
          allTests={allTests}
          isNoTestComplete={isNoTestComplete}
          browseAllTestHandler={browseAllTestHandler}
          requestTestHandler={requestTestHandler}
          loading={load || loading}
        />
      </div>
      <ViewTestModal
        isOpenModal={isModalOpen}
        setIsOpenModal={setIsModalOpen}
        buttonText="Read More"
      />
    </div>
  );
};
