type Answer = {
  queueId: number;
  text: string;
  isCorrect: boolean;
};

type QuestionType = {
  text: string;
  timeLimit: number;
  type: 'single' | 'multi' | 'essay';
  isShuffleAnswers: boolean;
  answers?: Answer[];
  index: number;
  tags: string[];
};

type Grade = {
  scale: {
    min: number,
    max: number
  },
  description: string,
  title: string
}

type SectionType = {
  _id: number;
  name: string;
  instructions: string;
  resultInterpretation: {
    description: string;
    grades: Grade[],
    dimensionScales: {
      low: {
        name: string,
        description: string
      },
      high: {
        name: string,
        description: string,
      },
      hue: number
  },
  };
  showInstructions: false;
  questions: QuestionType[];
};

export class Section {
  private questionsCount = 0;
  private timeLimit = 0;
  private isEmpty = true;
  private hidden = true;
  private sections = [] as SectionType[];

  constructor(initialState = []) {
    this.sections = initialState;
  }

  public clear() {
    this.questionsCount = 0;
    this.timeLimit = 0;
    this.isEmpty = true;
    this.hidden = true;
    this.sections = [];
  }

  public getAll() {
    this.timeLimit = this.sections.reduce((fullTime, section) => {
      return (
        fullTime +
        section.questions.reduce((questionTime, question) => questionTime + question.timeLimit, 0)
      );
    }, 0);

    this.questionsCount = this.sections.reduce((len, { questions }) => len + questions.length, 0);

    if (!this.questionsCount) {
      this.setIsEmpty(true);
    }

    return { ...this };
  }

  public getSections() {
    return this.sections;
  }

  public setIsEmpty(value: boolean) {
    this.isEmpty = value;
  }
  public getIsEmpty() {
    return this.isEmpty;
  }

  public setHidden(value: boolean) {
    this.hidden = value;
  }

  public getHidden() {
    return this.hidden;
  }

  public recountQuestions() {
    this.sections.map(section => section.questions).flat().map((question, idx) => question.index = idx + 1);
  }

  public init(sections = [] as SectionType[]): void {
    this.sections = sections;

    if (sections.length > 1) {
      this.setHidden(false);
    } else {
      this.setHidden(true);
    }
    this.recountQuestions();
  }

  public createSection() {
    this.sections.push({
      _id: Date.now(),
      instructions: '',
      showInstructions: false,
      resultInterpretation: {
        description: '',
        grades: [],
        dimensionScales: {
          low: {
            name: '',
            description: ''
          },
          high: {
            name: '',
            description: '',
          },
          hue: 0
      }},
      name: 'Section Name',
      questions: []
    });

    if (this.sections.length > 1) {
      this.setHidden(false);
    }
  }

  public deleteSection(id: number) {
    const sectionIndex = this.sections.findIndex(section => section._id === id);
    if (sectionIndex !== 0) {
      this.sections[sectionIndex - 1].questions = [...this.sections[sectionIndex - 1].questions, ...this.sections[sectionIndex].questions];
    } else {
      this.sections[sectionIndex + 1].questions = [...this.sections[sectionIndex + 1].questions, ...this.sections[sectionIndex].questions];
    }
    this.sections = this.sections.filter(section => section._id !== id);
    this.recountQuestions();

    if (this.sections.length < 2) {
      if (!this.questionsCount) {
        this.setIsEmpty(true);
      }
      this.setHidden(true);
      if (this.sections.length === 0) {
        this.createSection();
      }
    }
  }

  public createQuestion({
    sectionId,
    data
  }: {
    sectionId: number;
    data: {
      text: string;
      type: 'single' | 'multi' | 'essay';
      timeLimit: number;
      tags: string[];
      isShuffleAnswers: boolean;
      answers: Answer[];
      index: number;
    };
  }) {
    this.setIsEmpty(false);

    this.sections = this.sections.reduce((acc, section) => {
      if (sectionId === section._id) {
        section.questions.push(data);
        this.recountQuestions();
      }
      return acc;
    }, this.sections);
  }

  public swapSection(from: number, to: number) {
    if (this.sections.length <= 1) return;

    const tmp = this.sections[from];

    if (to < 0) {
      this.sections[from] = this.sections[this.sections.length - 1];
      this.sections[this.sections.length - 1] = tmp;
    } else if (to >= this.sections.length) {
      this.sections[from] = this.sections[0];
      this.sections[0] = tmp;
    } else {
      this.sections[from] = this.sections[to];
      this.sections[to] = tmp;
    }
    this.recountQuestions();
  }

  public swapQuestion(sectionId: number, from: number, to: number) {
    const section = this.sections.find(({ _id }) => _id === sectionId);

    if (section) {
      const { questions } = section;

      if (questions.length <= 1 && this.sections.length <= 1) return;

      const tmp = questions[from];

      if (to < 0) {
        const prevSection = this.sections[this.sections.findIndex(item => item._id === sectionId) - 1];
        if (!prevSection) {
          return;
        }
        prevSection.questions.push(questions[from])
        questions.shift();
      } else if (to >= questions.length) {
        const prevSection = this.sections[this.sections.findIndex(item => item._id === sectionId) + 1];
        if(!prevSection) {
          return;
        }
        prevSection.questions.unshift(questions[from])
        questions.pop();
      } else {
        questions[from] = questions[to];
        questions[to] = tmp;
      }
      this.recountQuestions();
    }
  }

  public deleteQuestion(sectionId: number, questionId: number) {
    const section = this.sections.find(({ _id }) => _id === sectionId);

    if (section) {
      const { questions } = section;

      questions.splice(questionId, 1);

      this.recountQuestions();     
    }
  }

  public editQuestion(sectionId: number, questionId: number, payload: any) {
    const section = this.sections.find(({ _id }) => _id === sectionId);

    if (section) {
      const { questions } = section;

      questions[questionId] = payload;
    }
  }

  public duplicateQuestion(sectionId: number, questionId: number) {
    const section = this.sections.find(({ _id }) => _id === sectionId);

    if (section) {
      const { questions } = section;

      questions.splice(questionId, 0, { ...questions[questionId], index: questions[questionId].index + 1 });
    }
    this.recountQuestions();
  }

  public updateSection(index: number, data: any) {
    this.sections[index] = {
      ...this.sections[index],
      ...data
    };
  }
}
