import { useEffect, useState } from 'react';
import { Tab as TabUI } from '@headlessui/react';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';

import { Button, Typography, Tab, Icon } from 'ui-kit';
import Breadcrumbs from 'components/Breadcrumbs';
import { ArrowStepperComponent } from 'components/ArrowStepper/ArrowStepper';
import TestsTab from './Tabs/TestsTab';
import JobInfoTab from './Tabs/JobInfoTab';
import { useDispatch, useSelector } from 'react-redux';
import TimeBadget from 'components/TimeBadget';
import {
  getAssessmentGenericTemplatesRequest,
  updateAssessment,
  createNewAssessmentRequest,
  updateAssessmentRequest,
  publishAssessmentRequest,
  publishAssessmentWithoutConfirmation,
  deleteAssessmentRequest
} from 'store/assessment/actions';
import { getCurrentTestRequest } from 'store/tests/actions';
import CreateAssessmentModals from './Modals/Modals';
import { CustomQuestionsTab } from './Tabs/CustomQuestionsTab';
import { CustomizationTab } from './Tabs/CustomizationTab';
import { ReviewTab } from './Tabs/ReviewTab';
import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';
import { callToast } from 'utils/helpers';
import { getCurrentSubscriptionsRequest } from 'store/configuration/actions/subscriptions';
import { getCompanyRequest, setUpgradeModalOpen } from 'store/auth/actions';
import { localStorageService } from 'helpers/localStorageService';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import deepEqual from 'deep-equal';
import { initialcurrentAssessment } from 'store/assessment/reducer';

const steps = [
  { title: 'Job Info' },
  { title: 'Tests' },
  { title: 'Custom Questions' },
  { title: 'Customization' },
  { title: 'Review & Publish' }
];

export const CreateAssessment = () => {
  const [whatToAddModalOpen, setWhatToAddModalOpen] = useState(false);
  const [isDeleteDraftModalOpen, setDeleteDraftModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [previewTestId, setPreviewTestId] = useState(null);
  const [showPreviewButtons, setShowPreviewButtons] = useState(null);
  const [isGenericModalOpen, setIsGenericModalOpen] = useState(false);
  const [genericTests, setGenericTests] = useState([]);
  const [initialValues, setInitialValues] = useState(initialcurrentAssessment);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, company } = useSelector(store => store.auth);
  const { currentTest } = useSelector(store => store.tests);
  const { currentAssessment, genericTemplates } = useSelector(store => store.assessments);
  const [testsStateChanged, setTestsStateChanged] = useState(!!currentAssessment._id);

  const testTime = currentAssessment?.tests.reduce(
    (acc, cur) => acc + (cur.estimatedDuration ?? cur.timeLimit),
    0
  );
  const customQuestionsTime = currentAssessment?.customQuestions?.sections[0]?.questions.reduce(
    (acc, cur) => acc + cur.timeLimit,
    0
  );

  useEffect(() => {
    const step = localStorageService.getAssessmentStep();
    if (+step) {
      setSelectedIndex(+step);
    }
  }, []);

  useEffect(() => {
    onSubmit({
      description: `Welcome to our hiring assessment!<br /><br />We’re delighted that you’re considering joining the ${
        user.companyName || ['Company Name']
      } team. By taking the time to complete this assessment, you’re giving us a chance to get to know you better. We’re excited to determine if you’re the right fit for our organization and vice versa.<br /><br />You’re in for an enjoyable and rewarding experience. We look forward to reviewing the results and letting you know if you’re our ideal candidate.<br /><br />Good luck!`
    });
    setInitialValues(prev => {
      return {
        ...prev,
        description: `Welcome to our hiring assessment!<br /><br />We’re delighted that you’re considering joining the ${
          user.companyName || ['Company Name']
        } team. By taking the time to complete this assessment, you’re giving us a chance to get to know you better. We’re excited to determine if you’re the right fit for our organization and vice versa.<br /><br />You’re in for an enjoyable and rewarding experience. We look forward to reviewing the results and letting you know if you’re our ideal candidate.<br /><br />Good luck!`
      };
    });
    user?.companyName && dispatch(getCurrentSubscriptionsRequest());
    user?.companyName && dispatch(getCompanyRequest());
  }, [user.companyName]);

  useEffect(() => {
    dispatch(getAssessmentGenericTemplatesRequest());
  }, []);

  const previewTest = (_id, addHeaderButtons = false) => {
    dispatch(getCurrentTestRequest(_id));
    setPreviewTestId(_id);
    setShowPreviewButtons(addHeaderButtons);
  };

  const onSubmit = data => {
    dispatch(
      updateAssessment({
        ...data
      })
    );
  };

  const publishAssessmentHandlerModal = () => {
    try {
      dispatch(publishAssessmentRequest({ id: currentAssessment._id }));
      navigate(routes.ASSESSMENTS + '/' + currentAssessment?._id);
    } catch (e) {
      callToast('error', e.errors.at(0));
    }
  };

  const deleteDraftAssessmentConfirm = () => {
    setDeleteDraftModalOpen(false);
    dispatch(deleteAssessmentRequest({ id: currentAssessment._id, navigate }));
  };

  const publishAssessmentWithoutConfirm = () => {
    try {
      setTimeout(() => {
        dispatch(
          publishAssessmentWithoutConfirmation({
            data: {
              ...currentAssessment,
              questions: currentAssessment.customQuestions.sections[0].questions,
              tests: currentAssessment.tests.map(({ _id }) => _id)
            },
            assessmentId: currentAssessment?._id ?? null,
            navigate
          })
        );
      }, 0);
    } catch (e) {
      console.log('');
    }
  };

  const publishAssessmentHandler = () => {
    const data = {
      ...currentAssessment,
      questions: currentAssessment.customQuestions.sections[0].questions,
      tests: currentAssessment.tests.map(({ _id }) => _id)
    };
    if (!currentAssessment?._id) {
      dispatch(createNewAssessmentRequest({ data, navigate }));
    } else {
      dispatch(updateAssessmentRequest({ data, assessmentId: currentAssessment._id }));
    }
    if (
      currentAssessment?.tests?.length === 0 &&
      currentAssessment?.customQuestions?.sections[0]?.questions?.length === 0
    ) {
      setSelectedIndex(1);
      callToast('error', 'Add at least one test or custom question.');
      return;
    }

    try {
      if (company?.availablePublications !== 0) {
        publishAssessmentWithoutConfirm();
        return;
      }

      if (company?.availablePublications <= 0) {
        dispatch(setUpgradeModalOpen(true));
      }
    } catch (e) {
      if (e.errors.at(0) !== 'Assessment Name should contain at least 1 symbol') {
        callToast('error', e.errors.at(0));
      }
    }
  };

  const onChangeTab = index => {
    setSelectedIndex(index);
    localStorageService.setAssessmentStep(index);
    const data = {
      ...currentAssessment,
      questions: currentAssessment.customQuestions.sections[0].questions,
      tests: currentAssessment.tests.map(({ _id }) => _id)
    };

    if (currentAssessment._id) {
      dispatch(updateAssessmentRequest({ data, assessmentId: currentAssessment._id }));
    } else {
      if (
        deepEqual(currentAssessment, initialValues, { strict: true }) ||
        currentAssessment?.name === 'Unnamed' ||
        currentAssessment?.name === ''
      )
        return;
      dispatch(createNewAssessmentRequest({ data, navigate }));
    }
  };

  return (
    <div className="py-8 px-7.5">
      <Breadcrumbs type="draftAssessment" />
      <div className="mb-4 flex justify-between">
        <div className="flex items-center gap-2">
          <Typography
            className="max-w-[850px] break-words"
            variant="heading-h1"
            title={currentAssessment?.name ?? 'Create Assessment'}
          />
          {currentAssessment?._id && (
            <Button
              variant="secondary"
              className="text-[14px]"
              title="Delete Draft"
              onClick={() => {
                setDeleteDraftModalOpen(true);
              }}
            />
          )}
          <TimeBadget
            className="inline-flex w-auto font-semibold"
            time={testTime + customQuestionsTime}
            icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
          />
        </div>
        <div className="flex items-center gap-[12px]">
          {selectedIndex !== 0 && (
            <Button
              form="summary"
              type="submit"
              variant="secondary"
              className="w-[44px]"
              onClick={() => {
                onChangeTab(selectedIndex - 1);
              }}>
              <Icon icon={faChevronLeft} size={16} />
            </Button>
          )}

          {selectedIndex !== steps.length - 1 ? (
            <Button
              form="summary"
              type="submit"
              variant="primary"
              onClick={() => {
                onChangeTab(selectedIndex + 1);
              }}
              title="Next"
            />
          ) : (
            <Button
              form="summary"
              type="submit"
              variant="primary"
              onClick={publishAssessmentHandler}
              title="Publish Assessment"
            />
          )}
        </div>
      </div>

      <div>
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={index => {
            onChangeTab(index);
          }}
          as="div">
          <Tab.List className="relative mb-[36px] flex w-full shrink-0 flex-row items-start justify-start rounded-md bg-[#F6F6F7] p-[4px] shadow-[0_1px_8px_0_rgba(68,68,68,0.12)]">
            <ArrowStepperComponent
              activeStepIndex={selectedIndex}
              wrapper={children => (
                <TabUI className="flex h-12 flex-1 border-none">{children}</TabUI>
              )}
              options={{ isHoverable: true }}
              steps={steps}
            />
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <JobInfoTab
                currentAssessment={currentAssessment}
                previewTest={previewTest}
                testsStateChanged={testsStateChanged}
                setTestsStateChanged={setTestsStateChanged}
              />
            </Tab.Panel>
            <Tab.Panel>
              <TestsTab
                currentAssessment={currentAssessment}
                genericTemplates={genericTemplates}
                setTestsStateChanged={setTestsStateChanged}
                testsStateChanged={testsStateChanged}
                setIsGenericModalOpen={setIsGenericModalOpen}
                previewTest={previewTest}
                setRemoveId={setRemoveId}
                setGenericTests={setGenericTests}
                setWhatToAddModalOpen={setWhatToAddModalOpen}
                setConfirmationModalOpen={setConfirmationModalOpen}
              />
            </Tab.Panel>
            <Tab.Panel>
              <CustomQuestionsTab />
            </Tab.Panel>
            <Tab.Panel>
              <CustomizationTab onSubmit={onSubmit} currentAssessment={currentAssessment} />
            </Tab.Panel>
            <Tab.Panel>
              <ReviewTab
                currentAssessment={currentAssessment}
                setSelectedIndex={setSelectedIndex}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <CreateAssessmentModals
          currentTest={currentTest}
          currentAssessment={currentAssessment}
          dispatch={dispatch}
          viewTestModalOptions={{
            viewModalOpen: previewTestId !== null,
            setViewModalOpen: () => setPreviewTestId(null),
            showPreviewButtons,
            setTestsStateChanged,
            previewTestId,
            setRemoveId,
            setConfirmationModalOpen
          }}
          confirmationModalOptions={{
            confirmationModalOpen,
            setConfirmationModalOpen,
            setTestsStateChanged,
            removeId,
            setRemoveId
          }}
          deleteDraftAssessmentOptions={{
            confirmationModalOpen: isDeleteDraftModalOpen,
            setConfirmationModalOpen: setDeleteDraftModalOpen,
            acceptAction: deleteDraftAssessmentConfirm
          }}
          whatToAddModalOptions={{
            whatToAddModalOpen,
            setWhatToAddModalOpen
          }}
          genericModalOptions={{
            setIsGenericModalOpen,
            isGenericModalOpen,
            setTestsStateChanged,
            genericTests
          }}
        />
      </div>
    </div>
  );
};
