import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserRole } from 'hooks';

import { Button, Dropdown, Icon, List, Navbar as Nav, Typography } from 'ui-kit';
import Logo from 'components/Logo';
import FAQModal from 'components/Modals/FAQModal/FAQModal';

import { navConfig } from 'utils/helpers';
import { logout, setUpgradeModalOpen } from 'store/auth/actions';
import { BeamerHelmet } from 'react-beamer';
import * as routes from 'routes/routes';
import { clearAssessmentStore, resetAssessment } from 'store/assessment/actions';
import { localStorageService } from 'helpers/localStorageService';
import {
  faArrowRightFromBracket,
  faChevronDown,
  faChevronUp
} from '@fortawesome/pro-light-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';

export const Navbar = ({ auth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserRole();
  const [isFAQOpened, setFAQOpened] = useState(false);
  const { loading, company } = useSelector(store => store.auth);
  const email = localStorageService.getEmail();
  const currentUrl = window.location.pathname;

  const { dashboard, header, menu } = navConfig(auth ? 'employer' : role);

  const logOut = useCallback(() => {
    localStorageService.clearStorage();
    dispatch(clearAssessmentStore());
    dispatch(logout());
    localStorageService.setSession(true);
    navigate(routes.AUTH + '/' + routes.SIGNIN);
  }, []);

  const onScroll = () => {
    if (!document.querySelector('#beamerSelector')) {
      return;
    }
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollHeight - scrollTop < clientHeight + 119) {
      document.querySelector('#beamerSelector').style.bottom = '90px';
    } else {
      document.querySelector('#beamerSelector').style.bottom = '20px';
    }
  };

  const isElementLoaded = async selector => {
    while (document.querySelector(selector) === null) {
      await new Promise(resolve => requestAnimationFrame(resolve));
    }
    return document.querySelector(selector);
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    isElementLoaded('#beamerSelector').then(selector => {
      if (!selector) {
        return;
      }
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;
      if (scrollHeight === clientHeight) {
        selector.style.bottom = '90px';
      } else {
        selector.style.bottom = '20px';
      }
    });
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [loading]);

  return (
    <Nav className="gap-0">
      <Logo to={dashboard} />
      <List
        data={header}
        className="ml-6"
        keyExtractor={({ name }) => name}
        element={({ icon, name, link }) => (
          <Nav.Link
            className={() => 'border-b hover:border-green-400 hover:text-green-400'}
            icon={icon}
            to={link}
            title={name}
          />
        )}
        wrap={Nav.List}
      />
      <BeamerHelmet id={'bjLKozRb51871'} />
      {((role === 'employer' &&
        currentUrl !== routes.CREATE_ASSESSMENT &&
        !currentUrl.includes('/edit')) ||
        auth) && (
        <Button
          onClick={() => {
            dispatch(resetAssessment());
            localStorageService.setAssessmentStep(0);
            navigate(routes.CREATE_ASSESSMENT);
          }}
          title="Create Assessment"
          className="ml-3"
          variant={
            currentUrl === routes.SUBSCRIPTIONS && company?.subscription?.pricing === 'free'
              ? 'secondary'
              : 'primary'
          }
        />
      )}
      <div className="ml-auto flex items-center gap-3">
        {role === 'employer' && company?.subscription?.pricing === 'free' && (
          <Button
            onClick={() => dispatch(setUpgradeModalOpen(true))}
            title="Upgrade"
            variant="secondary"
          />
        )}
        <Dropdown
          className={`relative`}
          menuClassName="absolute border-t-0 right-0 left-0 overflow-hidden top-full z-30 bg-white rounded-b-lg border border-gray-300 border-solid shadow"
          controller={({ toggle, isOpen }) => (
            <Nav.Item
              className="border-b text-[#6D7175] hover:border-green-400"
              isActive={isOpen}
              onClick={toggle}>
              <Typography title={auth ? 'employer@gmail.com' : email} />
              <Icon size={18} color="#5C5F62" icon={isOpen ? faChevronUp : faChevronDown} />
            </Nav.Item>
          )}>
          <List
            data={menu}
            element={({ name, link, icon }) => (
              <Dropdown.Option
                element={({ toggle }) => (
                  <Nav.Link
                    className={() => 'last:rounded-b-lg'}
                    to={link}
                    onClick={toggle}
                    title={name}
                    icon={icon}
                  />
                )}
              />
            )}
            wrap={React.Fragment}
            keyExtractor={(_, idx) => idx}
          />
          <Dropdown.Option
            element={() => (
              <Nav.Item
                icon={faArrowRightFromBracket}
                className="last:rounded-b-lg"
                onClick={logOut}
                title="Log out"
              />
            )}
          />
        </Dropdown>
        {(role === 'employer' || auth) && (
          <>
            <Icon
              icon={faCircleQuestion}
              className="ml-[8px] cursor-pointer rounded-md px-[12px] py-[14px] text-[#5C5F62] hover:bg-green-100"
              onClick={() => setFAQOpened(true)}
            />

            <FAQModal auth={auth} isOpen={isFAQOpened} setOpen={setFAQOpened} />
          </>
        )}
      </div>
    </Nav>
  );
};
