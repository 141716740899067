import { yupResolver } from '@hookform/resolvers/yup';
import { NumericFormat } from 'react-number-format';
import { SettingsValidation } from 'components/Forms/validation';
import { ErrorMessage, Form, Input, Typography, Button, Icon } from 'ui-kit';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { truncate } from 'utils/truncate';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';

export const SettingsTab = ({ defaultValues, timeLimit, onSubmit, sections }) => {
  const questions = sections
    .map(({ questions }) =>
      questions.map(({ text, index }) => {
        return { value: text, label: `${index}.${truncate(text, 23)}` };
      })
    )
    .flat();
  return (
    <Form
      className="space-y-5 p-3"
      defaultValues={{
        ...defaultValues
      }}
      onSubmit={onSubmit}
      onBlur
      resolver={yupResolver(SettingsValidation)}
      mode="onChange">
      {({ getValues }) => (
        <div className="flex flex-col gap-[48px]">
          <div>
            <Typography variant="heading-h4" title="Estimated Duration" />
            <div className="flex gap-[4px] mt-[12px]">
              <Button
                onClick={() => {
                  let newVal = defaultValues.estimatedDurationPercent - 10;
                  if (newVal < 0) newVal = 0;
                  onSubmit({ ...defaultValues, estimatedDurationPercent: newVal });
                }}
                variant="secondary">
                <Icon icon={faMinus} size={16} />
              </Button>
              <NumericFormat
                className="w-[72px] rounded border border-[#C9CCCF] py-[8px] text-center"
                suffix={'%'}
                value={defaultValues.estimatedDurationPercent}
                isAllowed={({ floatValue }) => {
                  return floatValue > 0 && floatValue <= 100;
                }}
                onValueChange={({ floatValue }) =>
                  onSubmit({
                    ...defaultValues,
                    estimatedDurationPercent: floatValue,
                    estimatedDuration: (timeLimit * floatValue) / 100
                  })
                }
              />
              <Button
                onClick={() => {
                  let newVal = defaultValues.estimatedDurationPercent + 10;
                  if (newVal > 100) newVal = 100;
                  onSubmit({ ...defaultValues, estimatedDurationPercent: newVal });
                }}
                variant="secondary">
                <Icon icon={faPlus} size={16} />
              </Button>
            </div>
          </div>
          <div>
            <Typography variant="heading-h4" title="Priority in Recommended Tests" />
            <Input
              className="mt-3 w-[80px]"
              name="recommendedScore"
              type="number"
              onKeyDown={evt => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
            />
            <ErrorMessage name="recommendedScore" />
            <Typography
              className="text-[#6D7175]"
              variant="small-default"
              title="Lower number will appear first"
            />
          </div>
          <div className="flex flex-col gap-3">
            <Typography variant="heading-h4" title="Test Evaluation" />
            <div className="flex gap-8">
              <div className="flex flex-1 flex-col gap-[2px]">
                <Typography variant="regular-default" title="Question 1" />
                <SelectCustom
                  name="evaluation.[0]"
                  onChangeValue={() => onSubmit(getValues())}
                  isSearchable={false}
                  isClearable={false}
                  options={[
                    { value: 'none', label: 'None' },
                    { value: 'random', label: 'Random Question' },
                    ...questions
                  ]}
                />
              </div>
              <div className="flex flex-1 flex-col gap-[2px]">
                <Typography variant="regular-default" title="Question 2" />
                <SelectCustom
                  name="evaluation.[1]"
                  isSearchable={false}
                  isClearable={false}
                  onChangeValue={() => onSubmit(getValues())}
                  options={[
                    { value: 'none', label: 'None' },
                    { value: 'random', label: 'Random Question' },
                    ...questions
                  ]}
                />
              </div>
              <div className="flex flex-1 flex-col gap-[2px]">
                <Typography variant="regular-default" title="Question 3" />
                <SelectCustom
                  name="evaluation.[2]"
                  isSearchable={false}
                  isClearable={false}
                  onChangeValue={() => onSubmit(getValues())}
                  options={[
                    { value: 'none', label: 'None' },
                    { value: 'random', label: 'Random Question' },
                    ...questions
                  ]}
                />
              </div>
              <div className="flex flex-1 flex-col gap-[2px]">
                <Typography variant="regular-default" title="Question 4" />
                <SelectCustom
                  name="evaluation.[3]"
                  isSearchable={false}
                  isClearable={false}
                  onChangeValue={() => onSubmit(getValues())}
                  options={[
                    { value: 'none', label: 'None' },
                    { value: 'random', label: 'Random Question' },
                    ...questions
                  ]}
                />
              </div>
              <div className="flex flex-1 flex-col gap-[2px]">
                <Typography variant="regular-default" title="Question 5" />
                <SelectCustom
                  name="evaluation.[4]"
                  isSearchable={false}
                  isClearable={false}
                  onChangeValue={() => onSubmit(getValues())}
                  options={[
                    { value: 'none', label: 'None' },
                    { value: 'random', label: 'Random Question' },
                    ...questions
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
