import { TestsLibraryEmployer } from 'pages/Employer/TestsLibraryEmployer/TestsLibraryEmployer';
import TestsLibrary from '../TestsLibrary/TestsLibrary';
import { localStorageService } from 'helpers/localStorageService';

const Library = () => {
  const role = localStorageService.getRole();

  if (role === 'employer') {
    return <TestsLibraryEmployer />;
  } else {
    return <TestsLibrary />;
  }
};

export default Library;
