import { NavbarBox } from './Box';
import { NavbarItem } from './Item';
import { NavbarLink } from './Link';
import { Navbar } from './Navbar';
import { NavbarList } from './NavList';

Navbar.Box = NavbarBox;
Navbar.Link = NavbarLink;
Navbar.List = NavbarList;
Navbar.Item = NavbarItem;

export { Navbar };
