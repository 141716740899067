import { faXmark } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { Button, Modal, Typography } from 'ui-kit';

interface ConfirmationModalProps {
  isConfirmModalOpen: boolean;
  closeConfirmModal: () => void;
  bodyText: string;
  buttonAction: () => void;
  confirmNavigation: () => void;
  closeNavigation: () => void;
  buttonText?: string;
}

const ConfirmChangesModal: React.FC<ConfirmationModalProps> = ({
  isConfirmModalOpen,
  closeConfirmModal,
  bodyText,
  confirmNavigation,
  closeNavigation,
  buttonAction,
  buttonText
}) => {
  return (
    <Modal isOpen={isConfirmModalOpen} className="m-auto w-full max-w-[500px]">
      <Modal.Header className="px-6">
        <Typography variant="heading-h2" title="You have unsaved changes" />
        <Button icon={faXmark} iconSize={20} iconColor="#5C5F62" onClick={closeConfirmModal} />
      </Modal.Header>
      <Modal.Body className="flex min-h-[100px] items-center gap-1 px-6">
        <div>
          Would you like to save change to <b>{bodyText}</b>?
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end gap-4">
        <div className="flex justify-end gap-4">
          <Button variant="secondary" title="Cancel" onClick={closeConfirmModal} />
          <Button
            variant="primary-outline"
            title="Discard Changes"
            onClick={confirmNavigation || closeNavigation}
          />
          <Button
            variant="primary"
            title={buttonText || 'Save Draft'}
            onClick={() => {
              buttonAction();
              confirmNavigation();
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmChangesModal;
