import { useRef, useEffect, useState } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'draft-js/dist/Draft.css';
import TextEditorToolbar from './TextEditorToolbar/TextEditorToolbar';
import { useController } from 'react-hook-form';
import { Typography } from 'ui-kit';

function RichTextEditor({
  name,
  maxLength = 5000,
  showLength = false,
  placeholder,
  classNameWrapper,
  classNameEditor,
  classNameToolbar
}) {
  const editor = useRef(null);
  const [isDefaultValue, setIsDefaultValue] = useState(true);
  const { field } = useController({ name, defaultValue: '' });
  const { onChange, value, onBlur } = field;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const focusEditor = () => {
    setIsDefaultValue(false);
    editor.current.focus();
  };

  useEffect(() => {
    if (!value && !isDefaultValue) {
      setEditorState(EditorState.createEmpty()); // Reset the editor to an empty state
      setIsDefaultValue(true); // Mark it as default value again
    }
    if (value && isDefaultValue) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
        setIsDefaultValue(false);
      }
    }
  }, [value, isDefaultValue]);

  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      onChange(draftToHtml(convertToRaw(newState.getCurrentContent())));
      return true;
    }
    return false;
  };

  const handleBeforeInput = (input, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const currentLength = currentContent.getPlainText('').length;

    if (currentLength >= maxLength) {
      return 'handled';
    }

    return 'not-handled';
  };

  const handleEditorChange = newEditorState => {
    const contentState = newEditorState.getCurrentContent();
    const contentLength = contentState.getPlainText('').length;

    if (contentLength <= maxLength) {
      setEditorState(newEditorState);
      onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
    }
  };

  const handleEditorBlur = () => {
    onBlur(editorState);
  };

  const myBlockStyleFn = contentBlock => {
    const type = contentBlock.getType();
    if (type === 'blockquote') {
      return 'custom-blockquote';
    } else if (type === 'code') {
      return 'public-DraftStyleDefault-code-block';
    } else if (type === 'unstyled') {
      return 'custom-paragraph';
    }
    return '';
  };

  return (
    <div className={`flex flex-col items-start ${classNameWrapper}`} onBlur={handleEditorBlur}>
      <TextEditorToolbar
        editorState={editorState}
        setEditorState={handleEditorChange}
        className={classNameToolbar}
      />
      <div
        onClick={focusEditor}
        className={`relative min-h-[88px] w-full rounded-b border border-[#C9CCCF] px-[16px] py-[8px] ${classNameEditor}`}
        style={{ maxWidth: '100%', wordBreak: 'break-word' }}>
        <Editor
          ref={editor}
          placeholder={placeholder}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          blockStyleFn={myBlockStyleFn}
          handleBeforeInput={handleBeforeInput}
          onChange={handleEditorChange}
        />
        <style>
          {`
          .custom-paragraph {
            margin-top: 6px;
            margin-bottom: 6px;
          }
        `}
        </style>
      </div>
      {maxLength && showLength && (
        <Typography
          variant="regular-default"
          title={`${editorState.getCurrentContent().getPlainText('').length} / ${maxLength}`}
          className="self-end text-[#6D7175]"
        />
      )}
    </div>
  );
}

export default RichTextEditor;
