import CardLoader from 'components/Cards/CardLoader';
import MemberCard from 'components/Cards/MemberCard';
import EmptyResourse from 'components/EmptyResourse';
import Loader from 'components/Loader';
import Breadcrumbs from 'components/Breadcrumbs';
import * as routes from 'routes/routes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSingleCompanyRequest } from 'store/admin/actions';
import { List, Section, Typography } from 'ui-kit';
import { getModifyData, getModifyDataWithYear } from 'utils/helpers';
import { VerticalTextBlock } from 'components/VerticalTextBlock/VerticalTextBlock';
import AssessmenstCardCompany from './AssessmentCardCompany/AssessmentCardCompany';
import { ApplicationSectionOld } from 'components/ApplicationSectionOld/ApplicationSectionOld';

const ViewCompany = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();

  const { loading, currentCompany } = useSelector(store => store.admin);

  useEffect(() => {
    dispatch(getSingleCompanyRequest(_id));
  }, [_id]);

  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      <Breadcrumbs type="company" />
      <Typography className="mt-2" title={currentCompany?.name} variant="heading-h1" />
      <div className="mt-6 space-y-6">
        <div className="flex flex-col gap-6">
          <Section className="flex-1">
            <Section.Body className="flex gap-6">
              <div className="flex flex-1 flex-col">
                {currentCompany?.logo && (
                  <img
                    className="mb-1.5 max-h-[50px] w-full object-contain object-left"
                    src={currentCompany?.logo}
                    alt="company logo"
                  />
                )}
                <p className="mb-6 text-base font-bold text-gray-500">
                  {currentCompany?.country?.label}
                </p>
                <div className="mb-6 flex w-full justify-between pr-24">
                  <VerticalTextBlock
                    mainText="Published Assessments"
                    secondText={currentCompany?.publishedAssessments}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                  />
                  <VerticalTextBlock
                    mainText="Total Assessments"
                    secondText={currentCompany?.totalAssessments}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                  />
                  <VerticalTextBlock
                    mainText="Applications"
                    secondText={currentCompany?.applications?.length}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                  />
                </div>
                <div className="flex justify-between">
                  <Typography
                    className="text-sm font-normal text-gray-400"
                    title={
                      'Last Activity on ' +
                      getModifyData(
                        new Date(currentCompany?.lastActivity || currentCompany?.lastLoginAt)
                      )
                    }
                  />
                  <Typography
                    className="text-sm font-normal text-gray-400"
                    title={'Created On ' + getModifyData(new Date(currentCompany?.createdAt))}
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col border-l border-gray-400 pl-6">
                <Typography
                  title={currentCompany?.subscription?.name}
                  variant="heading-h5"
                  className="mb-1.5 text-bluegray"
                />
                <Typography
                  title={`Price $${currentCompany?.subscription?.cost} ($${Math.round(
                    currentCompany?.subscription?.cost / 12
                  )}/month)`}
                  variant="heading-h6"
                  className="mb-6 font-semibold text-gray-400"
                />
                <div className="mb-6 flex gap-10">
                  <VerticalTextBlock
                    mainText="Started On"
                    secondText={getModifyDataWithYear(currentCompany?.subscription?.startedAt)}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                  />
                  <VerticalTextBlock
                    mainText="Expires On"
                    secondText={getModifyDataWithYear(currentCompany?.subscription?.expiresAt)}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                  />
                </div>
                <div className="flex gap-10">
                  <VerticalTextBlock
                    mainText="Assessment Limit"
                    secondText={currentCompany?.subscription?.limits?.purchased}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                  />
                  <VerticalTextBlock
                    mainText="Available Assessments"
                    secondText={currentCompany?.subscription?.limits?.available}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                  />
                </div>
              </div>
            </Section.Body>
          </Section>
        </div>

        <Section>
          <Section.Header>
            <Typography title="Members" variant="heading-h1" />
          </Section.Header>
          <Section.Body>
            {currentCompany?.members?.map(
              ({ _id: id, role, image, firstName, lastName, email }) => (
                <MemberCard
                  url={routes.EMPLOYERS + `/${id}`}
                  image={image}
                  key={id}
                  firstName={firstName}
                  lastName={lastName}
                  role={role}
                  email={email}
                />
              )
            )}
          </Section.Body>
        </Section>

        <Section>
          <Section.Header className="flex justify-between">
            <Typography title="Assessments" variant="heading-h1" />
            <div className="flex gap-3">
              {/* <Form onSubmit={data => console.log(data)}>
                <Input
                  onChangeValue={() => console.log('click')}
                  icon="magnifier"
                  className="max-w-[250px] flex-3"
                  name="search"
                  placeholder="Search..."
                />
              </Form> */}
            </div>
          </Section.Header>
          <Section.Body>
            {currentCompany?.assessments?.length ? (
              <section className={`flex w-full flex-col justify-between`}>
                {loading ? (
                  <List
                    wrap="ul"
                    data={Array(12).fill(0)}
                    keyExtractor={(_, idx) => idx}
                    element={props => <CardLoader {...props} type="assessment" />}
                    className="mb-6 grid gap-6 lg:grid-cols-3 2xl:grid-cols-4"
                  />
                ) : (
                  <List
                    wrap="ul"
                    data={currentCompany?.assessments}
                    keyExtractor={(_, idx) => idx}
                    element={props => <AssessmenstCardCompany {...props} isAdmin />}
                    className="mb-6 grid gap-6 lg:grid-cols-3 2xl:grid-cols-4"
                  />
                )}
                {/* {totalAssessments > perPage && (
                  <Pagination
                    page={params.page}
                    itemsPerPage={perPage}
                    totalItems={totalAssessments}
                    setPage={page => push({ page })}
                  />
                )} */}
              </section>
            ) : (
              <EmptyResourse
                className="my-20"
                title={
                  // getValues()?.search
                  'No matches found'
                  // : "You don't have any assessment created yet"
                }
              />
            )}
          </Section.Body>
          <Section.Body>
            <ApplicationSectionOld applications={currentCompany?.applications} />
          </Section.Body>
        </Section>
      </div>
    </div>
  );
};

export default ViewCompany;
