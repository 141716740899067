import * as routes from 'routes/routes';

export const CreateFirstAssessmentButton = ({ navigate }) => (
  <div
    style={{
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%238C9196FF' stroke-width='2' stroke-dasharray='10%2c 14' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e")`
    }}
    onClick={() => navigate(routes.CREATE_ASSESSMENT)}
    className="flex h-[130px] w-full cursor-pointer flex-col items-center justify-center gap-[2px] rounded-md bg-[#FFFFFF] py-[32px] text-center shadow-small hover:bg-[#FCFCFC]">
    <p className="text-h3 font-medium text-[#007D6F]">+ Create your first assessment</p>
    <p className="text-h4 font-medium text-[#082623]">using our AI-powered templates</p>
  </div>
);
