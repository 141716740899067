import { Typography } from 'ui-kit';

import { Logo } from 'ui-kit/Logo';

const RedirectNote = () => {
  return (
    <div className="flex flex-1 items-center justify-center gap-12">
      <div className="container max-w-screen-sm rounded-xl border border-gray-400 bg-white py-6 px-12 text-center">
        <div className="mb-6 flex justify-center border-b border-gray-400 pb-6 ">
          <Logo width={140} height={28} />
        </div>
        <Typography variant="heading-h2" className="mb-6" title={'Check your Email!'} />
        <Typography
          className="mb-6 text-lg"
          title="We sent you an email to confirm!"
          variant="regular-default"
        />
      </div>
    </div>
  );
};

export default RedirectNote;
