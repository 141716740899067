import { faTrash } from '@fortawesome/pro-light-svg-icons';
import Breadcrumbs from 'components/Breadcrumbs';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import CreateAnswerChoiseForm from 'components/Forms/CreateAnswerChoiseForm';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAnswerChoisesRequest,
  getAnswerChoisesRequest
} from 'store/configuration/actions/subscriptions';
import { Button, List, Typography } from 'ui-kit';

const AnswerChoisesCard = ({ name, values, deleteAction, edit }) => (
  <div
    onClick={edit}
    className="group flex w-full cursor-pointer justify-between rounded-xl border border-[#C9CCCF] bg-[#FCFCFC] py-4 px-6 transition-all hover:border-green-400">
    <div className="flex w-full">
      <Typography
        variant="heading-h5"
        title={name}
        style={{ wordBreak: 'break-word' }}
        className="w-[30%] break-words transition-all group-hover:text-green-400"
      />
      <div className="flex flex-1 flex-col gap-[6px]">
        <Typography variant="small-default" title="Values" className="text-[#6D7175]" />
        <p style={{ wordBreak: 'break-word' }} className="break-words">
          {values?.map((item, index) => {
            return index !== values.length - 1 ? item + ', ' : item;
          })}
        </p>
      </div>
    </div>
    <Button
      onClick={e => {
        e.stopPropagation();
        deleteAction();
      }}
      icon={faTrash}
      variant="primary-outline"
      className="ml-10 hover:border-red-400 hover:bg-red-50"
    />
  </div>
);

export const ConfigurationAnswerChoises = () => {
  const dispatch = useDispatch();
  const { answerChoises, loading } = useSelector(store => store.subscriptions);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [openAnswerChoises, setOpenAnswerChoises] = useState(undefined);

  useEffect(() => {
    dispatch(getAnswerChoisesRequest());
  }, []);

  return (
    <div className="w-full px-6 py-9">
      <Breadcrumbs type="test-type" />
      {loading && <Loader />}
      <div className="flex gap-3">
        <Typography title="Answer Choices Templates" variant="subheader" />
        <Button onClick={() => setIsFormModalOpen(true)} variant="create" title="Create Template" />
      </div>
      <List
        wrap="div"
        className="mt-6 flex w-full flex-col gap-6"
        data={answerChoises}
        element={props => (
          <AnswerChoisesCard
            name={props.name}
            values={props.values}
            deleteAction={() => {
              setOpenAnswerChoises(props);
              setConfirmationModalOpen(true);
            }}
            edit={() => {
              setOpenAnswerChoises(props);
              setIsFormModalOpen(true);
            }}
          />
        )}
        keyExtractor={(_, idx) => idx}
      />
      <CreateAnswerChoiseForm
        isOpenModal={isFormModalOpen}
        setIsOpenModal={() => {
          setIsFormModalOpen(false);
          setOpenAnswerChoises(undefined);
        }}
        defaultValue={openAnswerChoises}
      />
      <ConfirmationModal
        isConfirmModalOpen={confirmationModalOpen}
        setConfirmModalOpen={() => {
          setConfirmationModalOpen(false);
          setOpenAnswerChoises(undefined);
        }}
        headerText="Delete Answer Choice Template"
        bodyText={
          <p>
            delete answer choiсe template <b>{openAnswerChoises?.name}</b>?
          </p>
        }
        buttonText="Delete Template"
        buttonAction={() => {
          dispatch(deleteAnswerChoisesRequest(openAnswerChoises?._id));
          setConfirmationModalOpen(false);
          setOpenAnswerChoises(false);
        }}
        danger
      />
    </div>
  );
};
