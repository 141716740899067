import React from 'react';

import { List } from 'ui-kit';
import { Section } from './Section';

export const SectionList = ({ sections, hidden }) => {
  return (
    <List
      data={sections}
      element={({ name, questions, instructions, showInstructions }, idx) => (
        <Section
          sectionLen={sections.length}
          idx={idx}
          showInstructions={showInstructions}
          instructions={instructions}
          name={name}
          hidden={hidden}
          questions={questions}
        />
      )}
      keyExtractor={(_, idx) => idx}
      wrap="div"
      className="space-y-5"
    />
  );
};
