import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { Button, Modal, Typography } from 'ui-kit';

export const DeleteAttachment = ({ onSubmit, isOpen, close }) => {
  return (
    <Modal isOpen={isOpen} onClose={close} size="2xl">
      <Modal.Header>
        <Typography variant="heading-h4" title="Delete Attachment" />
        <Button icon={faXmark} iconSize={20} iconColor="#5C5F62" onClick={close} />
      </Modal.Header>

      <Modal.Body>
        <Typography variant="regular-default">
          Are you sure you want to
          <Typography tag="span" variant="regular-emphasis" title=" delete Attachment" />?
        </Typography>
      </Modal.Body>

      <Modal.Footer className="flex justify-end gap-4">
        <Button variant="secondary" onClick={close} title="Cancel" />
        <Button variant="danger" onClick={onSubmit} title="Delete" />
      </Modal.Footer>
    </Modal>
  );
};
