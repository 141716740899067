export const LinearProgressBar = ({ percent }: { percent: number }) => {
  return (
    <div className="flex flex-col flex-wrap justify-center">
      <div className="relative h-[13px] w-full overflow-hidden rounded-full bg-[#e7e4da]">
        <div
          className="absolute h-full w-full rounded-full bg-black"
          style={{
            left: percent - 100 + '%',
            transition: '3s'
          }}
        />
      </div>
    </div>
  );
};
