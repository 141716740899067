import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Smartlook from 'smartlook-client';

import { useDebouceCallback } from 'hooks';

import { Typography, Form, Input, Icon } from 'ui-kit';

import {
  clearAssessments,
  clearAssessmentsPagination,
  getAllAssessmetsRequest,
  getArchivedAssessmetsRequest,
  getDraftAssessmetsRequest,
  getPublishedAssessmetsRequest,
  resetAssessment
} from 'store/assessment/actions';
import { TabLibrary } from 'components/TabLibrary/TabLibrary';
import AssessmenstCard from 'components/Cards/AssessmentCard';
import { EmployerPromoCountdown } from 'components/EmployerPromoCountdown/EmployerPromoCountdown';
import { localStorageService } from 'helpers/localStorageService';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { ThreeDots } from 'react-loader-spinner';

const perPage = 30;

const Assessments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(-1);
  const [params, setParams] = useState({ page: 0 });

  const {
    loading,
    publishedAssessments,
    draftAssessments,
    archivedAssessments,
    publishedTotal,
    draftTotal,
    archivedTotal,
    emptyAssessment
  } = useSelector(store => store.assessments);

  const { login, user } = useSelector(store => store.auth);

  if (login) {
    Smartlook.identify(user._id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email
    });
  }

  useEffect(() => {
    setParams(prev => {
      return { ...prev, page: 0 };
    });
    dispatch(clearAssessmentsPagination());
  }, [selectedTab]);

  const tabs = useMemo(() => {
    return [
      { title: 'drafts', count: draftTotal, arr: draftAssessments },
      { title: 'published', count: publishedTotal, arr: publishedAssessments },
      { title: 'archived', count: archivedTotal, arr: archivedAssessments }
    ];
  }, [publishedAssessments, draftAssessments, archivedAssessments]);

  const hasMore = useMemo(() => {
    if (tabs[selectedTab]?.arr?.length >= tabs[selectedTab]?.count) {
      return false;
    }

    return true;
  }, [tabs, selectedTab]);

  const debounceSubmit = useDebouceCallback(value => {
    setParams(prev => {
      return { ...prev, ...value, page: 0 };
    });
  });

  const onScroll = () => {
    setParams(prev => {
      return { ...prev, page: prev.page + 1 };
    });
  };

  useEffect(() => {
    dispatch(resetAssessment());
    dispatch(clearAssessments());
    dispatch(getAllAssessmetsRequest());
    localStorageService.setAssessmentStep(0);

    return () => dispatch(clearAssessments());
  }, []);

  useEffect(() => {
    setParams(prev => {
      return { ...prev, page: 0 };
    });
    dispatch(clearAssessments());
    dispatch(
      getPublishedAssessmetsRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
    dispatch(
      getDraftAssessmetsRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
    dispatch(
      getArchivedAssessmetsRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
  }, [params?.search, params?.type]);

  useEffect(() => {
    if (selectedTab === -1 || !hasMore) {
      return;
    }
    if (selectedTab === 0) {
      dispatch(
        getDraftAssessmetsRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
    if (selectedTab === 2) {
      dispatch(
        getArchivedAssessmetsRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
    if (selectedTab === 1) {
      dispatch(
        getPublishedAssessmetsRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
  }, [params?.page]);

  return (
    <Form className="py-8 px-7.5" defaultValues={{ search: params.search, status: params.status }}>
      {({ handleSubmit }) => (
        <>
          <ul className="relative mb-6 flex w-full  justify-between">
            <li className="flex items-center space-x-3">
              <Typography title="My Assessments" variant="heading-h1" />
            </li>
            {(!emptyAssessment || params?.search?.length > 0) && (
              <li className="flex space-x-3">
                <Input
                  leftComponent={() => (
                    <Icon icon={faMagnifyingGlass} size={16} color="#8C9196" className="ml-3" />
                  )}
                  rightComponent={() => (
                    <div className="mr-3 w-[16px]">
                      {loading && (
                        <ThreeDots height="16" width="16" color="grey" ariaLabel="loading" />
                      )}
                    </div>
                  )}
                  onChangeValue={handleSubmit(debounceSubmit)}
                  className="max-w-[250px] flex-3"
                  name="search"
                  placeholder="Search Assessment..."
                />
              </li>
            )}
          </ul>
          <TabLibrary
            tabs={tabs}
            search={params?.search?.length}
            onScroll={onScroll}
            hasMore={hasMore}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            children={AssessmenstCard}
            rewards={user?.rewards}
            navigate={navigate}
            customEmpty={EmployerPromoCountdown}
            timestamp={user?.createdAt}
            emptyAssessment={emptyAssessment}
          />
        </>
      )}
    </Form>
  );
};

export default Assessments;
