import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const ImageUploader = ({
  types,
  handleChange,
  handleClear,
  customClass,
  containerClass,
  iconClass,
  title,
  text,
  imageSrc,
  useConfirmModal = false,
  deleteImageConfirmText = '',
  deleteHeaderConfirmText = null,
  contain = false
}) => {
  const defaults = {
    acceptTypes: 'image/jpeg, image/jpg, image/png,'
  };
  const acceptTypes = types || defaults.acceptTypes;

  const onDrop = useCallback(filesAccepted => {
    if (filesAccepted.length) handleChange?.(filesAccepted[0]);
  }, []);
  const [showConfirmModal, setShowConfirmModal] = useState(null);

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: false,
    noKeyboard: true,
    multiple: false,
    maxFiles: 1,
    accept: acceptTypes,
    maxSize: 5242880
  });

  return (
    <>
      <section className="flex flex-col gap-[4px]">
        {title && <p>{title}</p>}
        <div
          onClick={() => {
            if (useConfirmModal && imageSrc) setShowConfirmModal(true);
            else imageSrc ? handleClear && handleClear() : open();
          }}
          className={`group dropzone relative flex h-[180px] w-full items-center justify-center rounded-xl 
            ${customClass ?? ''}`}
          style={{
            backgroundImage:
              !imageSrc &&
              `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%238C9196' stroke-width='2' stroke-dasharray='15%2c 10' stroke-dashoffset='6' stroke-linecap='round'/%3e%3c/svg%3e")`,
            borderRadius: !imageSrc && '8px'
          }}>
          {imageSrc ? (
            <>
              <img
                src={imageSrc}
                className={`relative h-full w-full rounded-xl ${
                  contain ? 'object-contain' : 'object-cover'
                }`}
              />
              <div className="invisible absolute inset-0 flex items-center justify-center rounded-xl bg-black bg-opacity-70 group-hover:visible">
                <div>
                  <i className="fa-solid fa-trash-can text-[24px] text-[#FFFFFF]"></i>
                </div>
              </div>
            </>
          ) : (
            <div className={`flex flex-col items-center ${containerClass ?? ''}`}>
              <input {...getInputProps()} />
              {iconClass && <i className={`${iconClass}`}></i>}
              {text && <p className="text-center text-base">{text}</p>}
            </div>
          )}
        </div>
      </section>

      {useConfirmModal && showConfirmModal && (
        <ConfirmationModal
          isConfirmModalOpen={showConfirmModal}
          headerText={deleteHeaderConfirmText || 'Delete image'}
          bodyText={deleteImageConfirmText}
          setConfirmModalOpen={() => setShowConfirmModal(false)}
          buttonAction={() => handleClear && handleClear()}
          buttonText="Delete"
          danger={true}
        />
      )}
    </>
  );
};

export default ImageUploader;
