import { Bar, PolarArea } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { optionsBarChartIsnide, optionsPolarWithotLegends } from 'config/charConfig';
import { charDataCalculate } from 'pages/Candidate/CandidateCertificate/ResultsDetailsCertificate/ResultsDetailsCertificate';
import { DimensionWidget } from 'components/DimensionWidget/DimenstionWidget';

export const ResultWidget = ({
  chartSettings,
  sections,
  scaledResult,
  chartData,
  result,
  calculationType,
  minValue,
  maxValue
}) => {
  return (
    <>
      {chartSettings?.chartStyle === 'polar' && (
        <div className="h-[210px] max-h-[210px] w-full">
          <PolarArea
            data={
              chartData || charDataCalculate(sections, chartSettings, scaledResult ?? result, true)
            }
            options={
              calculationType === 'avg'
                ? optionsPolarWithotLegends(minValue, maxValue)
                : optionsPolarWithotLegends(0, 100)
            }
          />
        </div>
      )}
      {chartSettings?.chartStyle === 'bar' && (
        <div style={{ height: sections.length * 40 + 40 }} className="w-[400px]">
          <Bar
            data={
              chartData || charDataCalculate(sections, chartSettings, scaledResult ?? result, true)
            }
            options={
              calculationType === 'avg'
                ? optionsBarChartIsnide(minValue, maxValue)
                : optionsBarChartIsnide(0, 100)
            }
            plugins={[ChartDataLabels]}
          />
        </div>
      )}
      {chartSettings?.chartStyle === 'dimensions' && <DimensionWidget sections={sections} />}
    </>
  );
};
