import { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

import { Typography, Input, Button, Form } from 'ui-kit';

import { InviteCandidateSchema } from './validation';
import * as routes from 'routes/routes';

import copyToClipboard from 'utils/copyToClipboard';
import { callToast } from 'utils/helpers';
import { inviteCandidateRequest } from 'store/assessment/actions';
import { Tooltip } from 'react-tooltip';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

const InviteCandidateForm = ({ isAdmin, hash, isDemo, verified }) => {
  const dispatch = useDispatch();
  const { _id } = useParams();

  const invitedLink = useMemo(
    () =>
      `${process.env.REACT_APP_ASSESSMENT_URL}${routes.CANDIDATE_INVITE}#${
        verified && !isDemo ? hash : 'hash'
      }`,
    [hash]
  );

  const onSubmit = (data, control) => {
    if (isDemo) {
      return;
    }
    dispatch(
      inviteCandidateRequest({
        data: { ...data },
        id: _id
      })
    );
    control.reset();
  };

  const copyLink = useCallback(
    text => () => {
      if (isDemo) {
        return;
      }
      copyToClipboard(text);
      callToast('success', 'Success - link was copy to clipboard');
    },
    [isDemo]
  );

  const shareLinkHandler = type => () => {
    copyLink(invitedLink);
    switch (type) {
      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${invitedLink}`);
        break;
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${invitedLink}`);
        break;
      case 'instagram':
        window.open(`https://www.instagram.com`);
        break;
      case 'linkedin':
        window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${invitedLink}`);
        break;
      default:
        break;
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      resolver={yupResolver(InviteCandidateSchema)}
      defaultValues={{ email: '' }}
      className={`flex flex-1 space-x-5 ${!verified && !isDemo && 'pointer-events-none'}`}>
      <div className="w-[50%] flex-1">
        <Typography variant="heading-h4" title="Share via Public Link" />
        <div className="flex gap-2 py-3">
          <Button
            className="flex w-[40px] cursor-pointer items-center justify-center rounded-md border border-[#9E9E9E] p-[10px] hover:bg-[#F6F6F7]"
            iconSize={16}
            iconColor="#5C5F62"
            onClick={shareLinkHandler('facebook')}
            icon={faFacebookF}
          />
          <Button
            className="flex w-[40px] cursor-pointer items-center justify-center rounded-md border border-[#9E9E9E] p-[10px] hover:bg-[#F6F6F7]"
            iconSize={16}
            iconColor="#5C5F62"
            onClick={shareLinkHandler('twitter')}
            icon={faTwitter}
          />
          <Button
            className="flex w-[40px] cursor-pointer items-center justify-center rounded-md border border-[#9E9E9E] p-[10px] hover:bg-[#F6F6F7]"
            iconSize={16}
            iconColor="#5C5F62"
            onClick={shareLinkHandler('linkedin')}
            icon={faLinkedinIn}
          />
        </div>
        <div
          data-tooltip-id="invite-tooltip"
          data-tooltip-content="You can’t share invite via public link cause it’s a Demo Assessment"
          data-tooltip-place="top"
          onClick={copyLink(invitedLink)}
          className="flex justify-between">
          <input
            value={invitedLink}
            disabled
            className="flex-1 overflow-hidden rounded-l-md border border-r-0 border-gray-400 bg-white p-2.5"
          />
          <Button variant="secondary" className="rounded-l-none" title="Copy Link" />
        </div>
      </div>
      {!isAdmin && (
        <div className="flex flex-1 flex-col justify-between">
          <Typography variant="heading-h4" title="Invite via Private Email" />
          <div className="mt-6 space-y-2">
            <Typography variant="heading-h5" title="Recipient" />
            <div
              data-tooltip-id="invite-tooltip"
              data-tooltip-content="You can’t send invite via private email cause it’s a Demo Assessment"
              data-tooltip-place="top"
              className="flex">
              <Input
                name="email"
                placeholder="candidate@..."
                className="flex-1 rounded-r-none border-r-0"
              />
              <Button
                className="min-w-[150px] rounded-l-none"
                variant="secondary"
                type="submit"
                title="Send Invitation"
              />
            </div>
          </div>
          {isDemo && <Tooltip className="max-w-[270px] bg-black text-sm" id="invite-tooltip" />}
        </div>
      )}
    </Form>
  );
};

export default InviteCandidateForm;
