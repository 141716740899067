import { Link } from 'react-router-dom';

import { Typography } from 'ui-kit';

import * as routes from 'routes/routes';

import { getModifyData } from 'utils/helpers';
import MemberCard from './MemberCard';
import { VerticalTextBlock } from 'components/VerticalTextBlock/VerticalTextBlock';

const EmployerCard = ({
  _id,
  firstName,
  lastName,
  lastActivityAt,
  companyId,
  createdAssessments,
  appliedAssessments,
  companyName,
  applications,
  companyLogo,
  country,
  image,
  createdAt,
  subscription,
  role
}) => {
  return (
    <div className="flex h-44 w-full gap-[75px] rounded-md border border-gray-400 bg-white p-6">
      <Link
        to={routes.EMPLOYERS + routes.COMPANY_ALL + `/${companyId}`}
        className="group flex min-w-[250px] flex-1 flex-col gap-1.5">
        {companyLogo && (
          <img
            className="h-8 w-full object-contain object-left"
            src={companyLogo}
            alt="company logo"
          />
        )}
        <Typography
          className="truncate text-xl font-bold text-bluegray group-hover:text-green-400"
          title={companyName || 'Company name'}
        />
        <Typography
          className="text-base font-medium text-gray-500"
          title={country?.label || 'Company country'}
        />
      </Link>
      <div className="flex min-w-[250px] flex-1 flex-col gap-1.5">
        <MemberCard
          url={routes.EMPLOYERS + `/${_id}`}
          image={image}
          firstName={firstName}
          lastName={lastName}
          role={role}
        />
      </div>
      <div className="flex flex-3 flex-col justify-between">
        <div className="flex w-full justify-between">
          <VerticalTextBlock
            mainText="Subscription"
            secondText={subscription}
            mainColor="text-bluegray"
            secondColor="text-gray-500"
          />
          <div className="flex flex-col gap-1.5">
            <Typography className="text-sm font-normal text-bluegray" title={'Assessments'} />
            {createdAssessments > 0 ? (
              <div className="flex">
                <Typography
                  className="text-base font-semibold text-green-400"
                  title={appliedAssessments}
                />
                <Typography
                  className="text-base font-semibold text-gray-500"
                  title={`/${createdAssessments}`}
                />
              </div>
            ) : (
              <Typography className="text-base font-semibold text-gray-500" title={'-'} />
            )}
          </div>
          <VerticalTextBlock
            mainText="Applications"
            secondText={applications > 0 ? applications : '-'}
            mainColor="text-bluegray"
            secondColor="text-gray-500"
            className="mr-20"
          />
        </div>
        <div className="flex justify-between">
          <Typography
            className="text-sm font-normal text-gray-400"
            title={'Last Activity on ' + getModifyData(new Date(lastActivityAt || createdAt))}
          />
          <Typography
            className="text-sm font-normal text-gray-400"
            title={'Created On ' + getModifyData(new Date(createdAt))}
          />
        </div>
      </div>
    </div>
  );
};

export default EmployerCard;
