import { Button, Modal, Typography, Input, Form } from 'ui-kit';

import { yupResolver } from '@hookform/resolvers/yup';
import { InviteCandidateSchema } from 'components/Forms/validation';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

export const InviteCandidatesModal = ({
  assessment,
  isModalOpened,
  closeModal,
  onSendInvitation,
  copyLink,
  verified,
  sniperLink,
  resendVerificationEmail
}) => (
  <Modal isOpen={isModalOpened} onClose={closeModal} className="m-auto w-[520px] max-w-[900px]">
    <Modal.Header className="px-6">
      <Typography variant="heading-h2" title={`Invite candidates to ${assessment.name}`} />
      <Button icon={faXmark} iconSize={20} iconColor="#5C5F62" onClick={closeModal} />
    </Modal.Header>
    <Modal.Body className="relative flex gap-4 p-6">
      {!verified && (
        <div className="z-1 absolute inset-0 bg-gray-400 bg-opacity-40 backdrop-blur-[2px]" />
      )}
      <div className="flex flex-1 flex-col gap-4">
        <Form
          resolver={yupResolver(InviteCandidateSchema)}
          defaultValues={{ email: '' }}
          onSubmit={onSendInvitation}>
          <div className="text-[16px] text-[#082623]">Candidate Email</div>
          <div className="mb-[35px] w-full">
            <Input className="h-[40px] w-full" name="email" />
          </div>
          <div className="flex justify-between">
            <Button onClick={copyLink} variant="primary-outline">
              <i className="fa-thin fa-link" />
              Copy Invitation Link
            </Button>
            <div className="flex">
              <Button variant="primary-outline" className="mr-3" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Invite
              </Button>
            </div>
          </div>
        </Form>

        {!verified && (
          <div
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2)'
            }}
            className="z-2 absolute flex flex-col items-center gap-3 rounded bg-white p-6">
            <Typography variant="heading-h4" title="Activate your account" />
            <Typography
              className="mt-[-4px]"
              variant="regular-default"
              title="To invite candidates, you have to confirm your email"
            />
            <div className="flex justify-center gap-3">
              {sniperLink?.url && (
                <Button
                  onClick={() => window.location.replace(sniperLink?.url)}
                  variant="primary"
                  title={`Open ${sniperLink?.name || 'email'}`}
                />
              )}
              <Button
                onClick={resendVerificationEmail}
                className="font-semibold text-[#007D6F]"
                title="Resend email"
              />
            </div>
          </div>
        )}
      </div>
    </Modal.Body>
  </Modal>
);
