import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { customStyles } from 'ui-kit/CustomSelect/SelectCustom';
import { getWithSubcategories } from 'utils/getWithSubcategories';

const formatOptionLabel = ({ label, parent }) => {
  if (parent) {
    return (
      <div>
        <span>{label}</span>
      </div>
    );
  }
  return (
    <div>
      <span>{`- ${label}`}</span>
    </div>
  );
};

const SelectTree = ({ onChange, options, name, defaultValue, isClearable = false }) => {
  const data = getWithSubcategories(options, true);
  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <Select
            {...field}
            value={data.find(({ value }) => value === field.value)}
            defaultValue={data.find(({ value }) => value === defaultValue)}
            options={data}
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
            onChange={onChange}
            isSearchable={false}
            isClearable={isClearable}
            placeholder="Category"
            components={{
              IndicatorSeparator: () => null
            }}
          />
        );
      }}
    />
  );
};

export default SelectTree;
