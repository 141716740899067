import { IconTest } from 'components/IconTest/IconTest';
import { Typography } from 'ui-kit';

export const GenericAssessmentCard = ({
  name,
  _id,
  actionsComponent,
  previewTestHandler,
  tests,
  className = '',
  containerClassName = ''
}) => (
  <div
    className={`hover:shadow-medium flex w-full rounded border-[0.5px] border-[#C9CCCF] bg-[#FAFBFB] shadow-small hover:bg-[#FCFCFC] ${className}`}>
    <div className={`flex w-full flex-col gap-[4px] p-6 ${containerClassName}`} key={_id}>
      <div className="flex items-center gap-3">
        <div className="flex flex-col gap-[18px]">
          <div className="flex items-center gap-3">
            <Typography variant="heading-h5" title={name} />
          </div>
          <div className="flex flex-wrap gap-[18px]">
            {tests?.map(({ name, _id, icon }) => (
              <div
                onClick={() => previewTestHandler(_id)}
                className="flex cursor-pointer items-center rounded border-[0.5px] border-[#D2D5D8] bg-[#F6F6F7] pr-[12px] shadow-small"
                key={_id}>
                <IconTest url={icon?.url} size={45} />
                {name}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div>{actionsComponent && actionsComponent}</div>
  </div>
);
