import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'ui-kit';
import * as routes from 'routes/routes';

import { resetRecentApplications, getRecentApplicationsRequest } from 'store/candidates/actions';
import ApplicationTable from './ApplicationsTable';

export const RecentApplications = ({ auth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recentApplications } = useSelector(store => store.candidates);
  const skip = recentApplications.length;

  useEffect(() => {
    if (auth) {
      return;
    }
    dispatch(getRecentApplicationsRequest({ perPage: 7, sortBy: 'completed' }));
    return () => dispatch(resetRecentApplications());
  }, [auth]);

  const loadMoreApplications = () =>
    dispatch(getRecentApplicationsRequest({ perPage: 7, skip, sortBy: 'completed' }));
  const navigateToAssessment = id => navigate(routes.ASSESSMENTS + '/' + id);
  const navigateToApplication = id => {
    navigate(`${routes.CANDIDATES}?sortBy=completed&application=${id}`);
  };

  return (
    <div className="mb-[36px]">
      <div className="mb-3 flex justify-between">
        <Typography title="Recent Applications" variant="heading-h4" />
        <Button
          onClick={() => navigate(routes.CANDIDATES + '?sortBy=completed')}
          variant="link"
          size="small"
          title="All Candidates"
        />
      </div>

      <ApplicationTable
        applications={recentApplications}
        navigateToApplication={navigateToApplication}
        navigateToAssessment={navigateToAssessment}
      />

      <div className="mt-3 flex justify-center">
        <Button
          onClick={loadMoreApplications}
          variant="link"
          size="small"
          title="More Applications"
        />
      </div>
    </div>
  );
};
