import { put, takeLatest, call, all } from 'redux-saga/effects';
import {
  assessmentConstants,
  createNewAssessmentSuccess,
  createNewAssessmentFailure,
  getAllAssessmetsSuccess,
  getAllAssessmetsFailure,
  updateAssessmentSuccess,
  updateAssessmentFailure,
  publishAssessmentSuccess,
  publishAssessmentFailure,
  getAssessmetSuccess,
  getAssessmetFailure,
  archiveAssessmentSuccess,
  archiveAssessmentFailure,
  restoreAssessmentSuccess,
  restoreAssessmentFailure,
  duplicateAssessmentSuccess,
  duplicateAssessmentFailure,
  inviteCandidateFailure,
  inviteCandidateSuccess,
  getPublishedAssessmetsSuccess,
  getPublishedAssessmetsFailure,
  getDraftAssessmetsSuccess,
  getDraftAssessmetsFailure,
  getArchivedAssessmetsSuccess,
  getArchivedAssessmetsFailure,
  getRecommendedTemplatesSuccess,
  getRecommendedTemplatesFailure,
  getAssessmentApplicationsFailure,
  getAssessmentApplicationsSuccess,
  getPublishedDemoAssessmetsSuccess,
  getArchivedDemoAssessmetsSuccess,
  getEvaluateQuestionsSuccess,
  getEvaluateQuestionsFailure,
  getAssessmentGenericTemplatesSuccess,
  getAssessmentGenericTemplatesFailure,
  getPublishedDemoAssessmetsFailure,
  getPublishedAssessmentsNamesSuccess,
  getPublishedAssessmentsNamesFailure,
  getLastPublishAssessmentSuccess,
  getLastPublishAssessmentFailure,
  getPublicDemoAssessmetsSuccess,
  getPublicDemoAssessmetsFailure,
  deleteAssessmentSuccess,
  deleteAssessmentFailure
} from 'store/assessment/actions';

import * as routes from 'routes/routes';
import { callToast } from 'utils/helpers';
import { commonApi, employerApi } from 'api/appApi';
import { localStorageService } from 'helpers/localStorageService';

function* createNewAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.createNewAssessment(payload.data);
    yield put(createNewAssessmentSuccess(data));
    yield call(() => payload.navigate(routes.ASSESSMENTS + `/${data._id}` + routes.EDIT));
  } catch (error) {
    callToast('error', 'Error - Assessment was not created');
    yield put(createNewAssessmentFailure(error.response.status));
  }
}

function* getEvaluateQuestionsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getEvaluateQuestions(payload);
    yield put(getEvaluateQuestionsSuccess(data));
  } catch (error) {
    yield put(getEvaluateQuestionsFailure(error.response.status));
  }
}

function* getAllAssessmentsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getAssessments(payload);
    yield put(getAllAssessmetsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting assessments');
    yield put(getAllAssessmetsFailure(error.response.status));
  }
}

function* getPublishedAssessmentsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getPublishedAssessments(payload);
    yield put(getPublishedAssessmetsSuccess(data));
    if (localStorageService.getRole() === 'employer') {
      const response = yield commonApi.getPublishedDemoAssessments();
      yield put(getPublishedDemoAssessmetsSuccess(response.data));
    }
  } catch (error) {
    callToast('error', 'Error - Failed in getting assessments');
    yield put(getPublishedAssessmetsFailure(error.response.status));
  }
}

function* getPublishedDemoAssessmentsSaga() {
  try {
    const response = yield commonApi.getPublishedDemoAssessments();
    yield put(getPublishedDemoAssessmetsSuccess(response.data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting assessments');
    yield put(getPublishedDemoAssessmetsFailure(error.response.status));
  }
}

function* getPublicDemoAssessmentsSaga() {
  try {
    const response = yield commonApi.getPublicDemoAssessments();
    yield put(getPublicDemoAssessmetsSuccess(response.data));
  } catch (error) {
    yield put(getPublicDemoAssessmetsFailure(error.response.status));
  }
}

function* getDraftAssessmentsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getDraftAssessments(payload);
    yield put(getDraftAssessmetsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting assessments');
    yield put(getDraftAssessmetsFailure(error.response.status));
  }
}

function* getArchivedAssessmentsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getArchivedAssessments(payload);
    yield put(getArchivedAssessmetsSuccess(data));
    if (localStorageService.getRole() === 'employer') {
      const response = yield commonApi.getArchivedDemoAssessments();
      yield put(getArchivedDemoAssessmetsSuccess(response.data));
    }
  } catch (error) {
    callToast('error', 'Error - Failed in getting assessments');
    yield put(getArchivedAssessmetsFailure(error.response.status));
  }
}

function* getAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getAssessment(payload);
    yield put(getAssessmetSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting assessment');
    yield put(getAssessmetFailure(error.response.status));
  }
}

function* getAssessmentApplicationsSaga(action) {
  const { payload } = action;
  try {
    const [data1, data2, data3, data4] = yield all([
      call(commonApi.getOpenedApplications, payload),
      call(commonApi.getEvaluatedApplications, payload),
      call(commonApi.getInterviewApplications, payload),
      call(commonApi.getHireApplications, payload)
    ]);
    yield put(
      getAssessmentApplicationsSuccess({
        measure: data1.data,
        evaluate: data2.data,
        interview: data3.data,
        hire: data4.data
      })
    );
  } catch (error) {
    callToast('error', 'Error - Failed in getting applications');
    yield put(getAssessmentApplicationsFailure(error.response.status));
  }
}

function* updateAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.updateAssessment(payload);
    yield put(updateAssessmentSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in updating assessments');
    yield put(updateAssessmentFailure(error.response.status));
  }
}

function* publishAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.publishAssessment(payload.id);
    yield put(publishAssessmentSuccess(data));
    callToast('success', 'Success - Assessment was published');
  } catch (error) {
    callToast('error', 'Error - Failed in publish assessment');
    yield put(publishAssessmentFailure(error.response.status));
    yield call(() => payload.navigate('/assessments'));
  }
}

function* publishAssessmentWithoutConfirmation(action) {
  const { payload } = action;
  let id = payload.assessmentId ?? null;

  if (payload.assessmentId) {
    // update assessment if draft already created
    try {
      const { data } = yield employerApi.updateAssessment(payload);
      data.continueLoading = true;
      yield put(updateAssessmentSuccess(data));
    } catch (error) {
      callToast('error', 'Error - Failed in updating assessments');
      yield put(updateAssessmentFailure(error.response.status));
      return;
    }
  } else {
    // create draft assessment before publishing
    try {
      const { data } = yield employerApi.createNewAssessment(payload.data);
      data.continueLoading = true;
      yield put(createNewAssessmentSuccess(data));
      id = data._id;
    } catch (error) {
      callToast('error', 'Error - Assessment was not created');
      yield put(createNewAssessmentFailure(error.response.status));
      return;
    }
  }

  try {
    const { data } = yield employerApi.publishAssessment(id);
    yield put(publishAssessmentSuccess(data));
    callToast('success', 'Success - Assessment was published');
    setTimeout(() => payload.navigate(routes.ASSESSMENTS + '/' + id), 10);
  } catch (error) {
    callToast('error', 'Error - Failed in publish assessment');
    yield put(publishAssessmentFailure(error.response.status));
    yield call(() => payload.navigate('/assessments'));
  }
}

function* getLastPublishAssessmentSaga() {
  try {
    const { data } = yield commonApi.getLastPublishAssessment();
    yield put(getLastPublishAssessmentSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed getting last publish assessment');
    yield put(getLastPublishAssessmentFailure(error.response.status));
  }
}

function* publishAssessmentFromTemplateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.createNewAssessment(payload.data);
    const { data: date } = yield employerApi.publishAssessment(data._id);
    yield put(publishAssessmentSuccess(date));
    payload.navigate(routes.ASSESSMENTS + '/' + data?._id, {
      state: { template: payload.template }
    });
    callToast('success', 'Success - Assessment was published');
  } catch (error) {
    callToast('error', 'Error - Failed in publish assessment');
    yield put(publishAssessmentFailure(error.response.status));
    yield call(() => payload.navigate('/assessments'));
  }
}

function* archiveAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.archiveAssessment(payload.id);
    yield put(archiveAssessmentSuccess(data));
    if (payload.navigate) {
      yield call(() => payload.navigate(routes.ASSESSMENTS));
    }
    callToast('success', 'Success - Assessment was archived');
  } catch (error) {
    callToast('error', 'Error - Failed in archive assessment');
    yield put(archiveAssessmentFailure(error.response.status));
    yield call(() => payload.navigate(routes.ASSESSMENTS));
  }
}

function* restoreAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.restoreAssessment(payload.id);
    yield put(restoreAssessmentSuccess(data));
    yield call(() => payload.navigate(routes.ASSESSMENTS));
    callToast('success', 'Success - Assessment was restored');
  } catch (error) {
    callToast('error', 'Error - Failed in restore assessment');
    yield put(restoreAssessmentFailure(error.response.status));
    yield call(() => payload.navigate(routes.ASSESSMENTS));
  }
}

function* duplicateAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.duplicateAssessment(payload);
    yield put(duplicateAssessmentSuccess(data));
    yield call(() => payload.navigate(routes.ASSESSMENTS + `/${data._id}` + routes.EDIT));
    callToast('success', 'Success - Assessment was duplicated');
  } catch (error) {
    callToast('error', 'Error - Failed in duplicate assessment');
    yield put(duplicateAssessmentFailure(error.response.status));
    yield call(() => payload.navigate(routes.ASSESSMENTS));
  }
}

function* inviteCandidateSaga(action) {
  const { payload } = action;
  try {
    const response = yield employerApi.inviteCandidate(payload);
    if (response) {
      const { data } = yield commonApi.getOpenedApplications(payload.id);
      yield put(inviteCandidateSuccess(data));
    }
    callToast('success', 'Invite sent successfully');
  } catch (error) {
    callToast('error', error.response.data.message);
    yield put(inviteCandidateFailure(error.response.status));
  }
}

function* getRecommendedTemplatesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getRecommendedTemplates(payload);
    yield put(getRecommendedTemplatesSuccess(data));
  } catch (error) {
    yield put(getRecommendedTemplatesFailure(error.response.status));
    callToast('error', 'Error - in getting recommended templates');
  }
}

function* getAssessmentGenericTemplatesSaga() {
  try {
    const { data } = yield commonApi.getAssessmentGenericTemplates();
    yield put(getAssessmentGenericTemplatesSuccess(data));
  } catch (error) {
    yield put(getAssessmentGenericTemplatesFailure(error.response.status));
    callToast('error', 'Error - in getting generic templates');
  }
}

function* getPublishedAssessmentsNamesSaga() {
  try {
    const { data } = yield commonApi.getPublishedAssessmentsNames();
    yield put(getPublishedAssessmentsNamesSuccess(data));
  } catch (error) {
    yield put(getPublishedAssessmentsNamesFailure(error.response.status));
    callToast('error', 'Error - in getting assessments for filtering');
  }
}

function* deleteDraftAssessmentSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.deleteAssessment(payload.id);
    yield put(deleteAssessmentSuccess(data));
    yield call(() => payload.navigate(routes.ASSESSMENTS));
  } catch (error) {
    yield put(deleteAssessmentFailure(error.response.status));
    callToast('error', 'Error - in deleting draft assessment');
  }
}

export default function* assessmentWatcher() {
  yield takeLatest(assessmentConstants.GET_ALL_ASSESSMENTS_REQUEST, getAllAssessmentsSaga);
  yield takeLatest(assessmentConstants.CREATE_NEW_ASSESSMENT_REQUEST, createNewAssessmentSaga);
  yield takeLatest(assessmentConstants.UPDATE_ASSESSMENT_REQUEST, updateAssessmentSaga);
  yield takeLatest(assessmentConstants.PUBLISH_ASSESSMENT_REQUEST, publishAssessmentSaga);
  yield takeLatest(
    assessmentConstants.PUBLISH_ASSESSMENT_WITHOUT_CONFIRMATION_REQUEST,
    publishAssessmentWithoutConfirmation
  );
  yield takeLatest(assessmentConstants.GET_ASSESSMENT_REQUEST, getAssessmentSaga);
  yield takeLatest(assessmentConstants.ARCHIVE_ASSESSMENT_REQUEST, archiveAssessmentSaga);
  yield takeLatest(assessmentConstants.RESTORE_ASSESSMENT_REQUEST, restoreAssessmentSaga);
  yield takeLatest(assessmentConstants.DUPLICATE_ASSESSMENT_REQUEST, duplicateAssessmentSaga);
  yield takeLatest(assessmentConstants.INVITE_CANDIDATE_REQUEST, inviteCandidateSaga);
  yield takeLatest(
    assessmentConstants.GET_PUBLISHED_ASSESSMENTS_REQUEST,
    getPublishedAssessmentsSaga
  );
  yield takeLatest(assessmentConstants.GET_DRAFT_ASSESSMENTS_REQUEST, getDraftAssessmentsSaga);
  yield takeLatest(
    assessmentConstants.GET_ARCHIVED_ASSESSMENTS_REQUEST,
    getArchivedAssessmentsSaga
  );
  yield takeLatest(
    assessmentConstants.GET_RECOMMENDED_TEMPLATES_REQUEST,
    getRecommendedTemplatesSaga
  );
  yield takeLatest(
    assessmentConstants.PUBLISH_ASSESSMENT_FROM_TEMPLATE_REQUEST,
    publishAssessmentFromTemplateSaga
  );
  yield takeLatest(
    assessmentConstants.GET_ASSESSMENT_APPLICATIONS_REQUEST,
    getAssessmentApplicationsSaga
  );
  yield takeLatest(assessmentConstants.GET_EVALUATE_QUESTIONS_REQUEST, getEvaluateQuestionsSaga);
  yield takeLatest(
    assessmentConstants.GET_ASSESSMENT_GENERIC_TEMPLATES_REQUEST,
    getAssessmentGenericTemplatesSaga
  );
  yield takeLatest(
    assessmentConstants.GET_PUBLISHED_DEMO_ASSESSMENTS_REQUEST,
    getPublishedDemoAssessmentsSaga
  );
  yield takeLatest(
    assessmentConstants.GET_PUBLISHED_ASSESSMENTS_NAMES_REQUEST,
    getPublishedAssessmentsNamesSaga
  );
  yield takeLatest(
    assessmentConstants.GET_LAST_PUBLISH_ASSESSMENT_REQUEST,
    getLastPublishAssessmentSaga
  );
  yield takeLatest(
    assessmentConstants.GET_PUBLIC_DEMO_ASSESSMENTS_REQUEST,
    getPublicDemoAssessmentsSaga
  );
  yield takeLatest(assessmentConstants.DELETE_ASSESSMENT_REQUEST, deleteDraftAssessmentSaga);
}
