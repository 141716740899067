import React from 'react';

import cn from 'utils/classNames';

interface BodyProps {
  className?: string;
  children?: React.ReactNode;
}

export const Body: React.FC<BodyProps> = ({ className, children }) => {
  return <div className={cn('p-8', className)}>{children}</div>;
};
