import { List, Typography } from 'ui-kit';
import { Tab } from 'ui-kit/TabSingle/Tab';
import { CreateFirstAssessmentButton } from 'components/CreateFirstAssessmentButton/CreateFirstAssessmentButton';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lottie from 'react-lottie';
import loading from 'assets/lotties/loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const TabLibrary = ({
  tabs,
  selectedTab,
  setSelectedTab,
  children: Children,
  emptyAssessment,
  navigate,
  onScroll,
  hasMore,
  search
}) => {
  return (
    <>
      {selectedTab === -1 ? (
        <section className="mt-6 flex w-full flex-col gap-6">
          {emptyAssessment && search === 0 && <CreateFirstAssessmentButton navigate={navigate} />}
          {tabs &&
            tabs.map(({ title, arr, count }, idx) => {
              return (
                count > 0 && (
                  <div key={title} className="flex flex-col gap-6">
                    <Tab
                      title={title}
                      count={count}
                      onClick={() => {
                        setSelectedTab(idx);
                      }}
                    />
                    <List
                      data={arr.slice(0, 3)}
                      element={item => <Children data={item} />}
                      keyExtractor={(_, idx) => idx}
                      wrap="ul"
                      className="flex flex-col gap-6"
                    />
                  </div>
                )
              );
            })}
        </section>
      ) : (
        <section className="mt-6 flex w-full flex-col gap-6">
          <div className="flex gap-12">
            <Typography
              variant="heading-h3"
              className="cursor-pointer text-[#6D7175] hover:text-black"
              onClick={() => setSelectedTab(-1)}
              title="Recents"
            />
            {tabs &&
              tabs.map(({ title, count }, idx) => {
                return (
                  count > 0 && (
                    <Tab
                      key={title}
                      title={title}
                      count={count}
                      selected={selectedTab === idx}
                      onClick={() => {
                        setSelectedTab(idx);
                      }}
                    />
                  )
                );
              })}
          </div>
          <InfiniteScroll
            dataLength={tabs[selectedTab]?.arr.length}
            next={onScroll}
            hasMore={hasMore}
            loader={<Lottie options={defaultOptions} height={50} width={50} />}>
            <List
              data={tabs[selectedTab]?.arr}
              element={item => <Children data={item} />}
              keyExtractor={(_, idx) => idx}
              wrap="ul"
              className="flex flex-col gap-6"
            />
          </InfiniteScroll>
        </section>
      )}
    </>
  );
};
