import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'ui-kit';
interface MemberCardProps {
  url: string;
  image: any;
  firstName: string;
  lastName: string;
  role: string;
  key?: string;
  email?: string;
}
const avatar: string = require('assets/image/avatar.svg').default;
const MemberCard: FC<MemberCardProps> = ({ url, key, image, firstName, lastName, role, email }) => (
  <Link
    to={url}
    key={key}
    className="group flex w-full max-w-[340px] gap-[18px] rounded-md border border-gray-300 px-[18px] py-3 transition-all hover:border-green-400">
    <img
      className="h-[60px] w-[60px] self-center rounded-full object-cover"
      src={image || avatar}
      alt="user image"
    />
    <div className="flex flex-1 flex-col gap-0.5">
      <Typography
        className="w-full break-words text-lg font-semibold text-bluegray group-hover:text-green-400"
        title={`${lastName} ${firstName}`}
      />
      <Typography className="text-base font-semibold capitalize text-gray-500" title={role} />
      {email && (
        <Typography
          className="max-w-[200px] truncate text-base font-normal text-gray-500"
          title={email}
        />
      )}
    </div>
  </Link>
);
export default MemberCard;
