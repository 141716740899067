import { useSelector } from 'react-redux';

import Typography from 'ui-kit/Typography';

import { Icon } from 'ui-kit';
import { badgetStatus, getModifyData } from 'utils/helpers';
import * as routes from 'routes/routes';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import { Link } from 'react-router-dom';
import { IconTest } from 'components/IconTest/IconTest';
import TimeBadget from 'components/TimeBadget';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

export const Author = props => {
  const {
    _id,
    name,
    icon,
    status,
    questionsCount,
    timeLimit,
    estimatedDuration,
    category,
    parentCategory,
    sections,
    tags,
    tagline,
    updatedAt,
    createdAt,
    version,
    publishedAt,
    archivedAt
  } = props.data || {};
  const { user } = useSelector(store => store.auth);

  const isAdmin = user?.role === 'admin';

  return (
    <Link
      to={
        status === 'draft' && !isAdmin
          ? routes.TESTS + `/${_id}` + routes.EDIT
          : routes.TESTS + `/${_id}`
      }
      style={{ wordBreak: 'break-word' }}
      className={`relative flex h-[168px] w-full ${
        !props?.current ? 'cursor-pointer bg-white hover:border-green-400' : 'bg-[#FAFBFB]'
      } flex-col justify-between break-words rounded-md border border-gray-400 p-6 pt-[38px] transition-all`}>
      <span
        className={`absolute top-0 left-6 rounded-b ${badgetStatus(
          status
        )} py-[2px] px-[10px] font-semibold capitalize`}>
        {`${user?.role === 'author' ? `#${version?.number}` : ''} ${status}`}
      </span>
      {props?.current && (
        <Typography
          variant="small-default"
          title="Current"
          className="absolute top-1 left-40 text-[#6D7175]"
        />
      )}
      <div className="flex h-full gap-12">
        <div className="flex flex-1 flex-col items-start">
          <div className="flex gap-3">
            <IconTest url={icon?.url} size={48} />
            <div className="flex-1">
              <div className="flex gap-1">
                {parentCategory?.name && (
                  <p className="text-base text-[#6D7175]">{parentCategory?.name}</p>
                )}
                {parentCategory?.name && <p className="mt-[-0.5px] text-base text-[#6D7175]">›</p>}
                <p className="text-base text-[#6D7175]">{category?.name || 'Not Selected'}</p>
              </div>
              <Typography variant="heading-h5" title={name} />
            </div>
          </div>
          <TextAreaView
            className="mt-3 overflow-hidden"
            variant="regular"
            title={tagline?.length > 100 ? tagline?.slice(0, 100) + '...' : tagline}
          />
        </div>
        <div className="flex w-full flex-1 flex-col justify-between">
          <div className="flex justify-between gap-6">
            <div className="flex-1 space-y-1">
              <Typography
                variant="small-default"
                className="text-[#6D7175]"
                title="Estimated Duration"
              />
              <TimeBadget
                className="inline-flex w-auto font-semibold"
                time={(estimatedDuration ?? timeLimit) || '-'}
                icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
              />
            </div>
            <div className="flex-1 space-y-1">
              <Typography variant="small-default" className="text-[#6D7175]" title="Time Limit" />
              <TimeBadget
                className="inline-flex w-auto font-semibold"
                time={timeLimit || '-'}
                icon={<Icon icon={faHourglassHalf} size={16} className="text-[#2F6E63]" />}
              />
            </div>
            <div className="flex-1 space-y-1">
              <Typography variant="small-default" className="text-[#6D7175]" title="Questions" />
              <Typography
                className="inline-flex h-[22px] items-center rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm !font-semibold text-[#05443D] shadow-small"
                variant="regular-default"
                title={questionsCount}
              />
            </div>
            <div className="flex-1 space-y-1">
              <Typography
                variant="small-default"
                className="text-[#6D7175]"
                title="Sections / Tags"
              />
              <Typography
                variant="regular-default"
                className="inline-flex h-[22px] items-center rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm !font-semibold text-[#05443D] shadow-small"
                title={`${sections > 1 ? sections : 0} / ${tags}`}
              />
            </div>
          </div>
          <div className="flex gap-3 self-end">
            {props?.isModal && status === 'draft' && (
              <Typography
                variant="small-default"
                className="text-[#6D7175]"
                title={createdAt ? `Created on ${getModifyData(createdAt)}` : '-'}
              />
            )}
            <Typography
              variant="small-default"
              className="text-[#6D7175]"
              title={updatedAt ? `Modified on ${getModifyData(updatedAt)}` : '-'}
            />
            {props?.isModal && status === 'published' && (
              <Typography
                variant="small-default"
                className="text-[#6D7175]"
                title={createdAt ? `Published on ${getModifyData(publishedAt)}` : '-'}
              />
            )}
            {props?.isModal && status === 'archived' && (
              <Typography
                variant="small-default"
                className="text-[#6D7175]"
                title={createdAt ? `Archived on ${getModifyData(archivedAt)}` : '-'}
              />
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
