import { TestCardNew } from 'components/TestCardNew/TestCardNew';
import TimeBadget from 'components/TimeBadget';
import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentTestRequest } from 'store/tests/actions';
import { Button, Section, Typography, Icon } from 'ui-kit';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

const ViewTests = ({ tests, timeLimit, showHeader = true, setSelectedIndex }) => {
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const dispatch = useDispatch();

  const previewTestHandler = id => {
    dispatch(getCurrentTestRequest(id));
    setViewModalOpen(true);
  };

  return (
    <Section className="border-none shadow-med">
      <Section.Body className="m-0 flex flex-col gap-6 p-8">
        {showHeader && (
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-[6px]">
              <Typography
                variant="heading-h4"
                title={`${tests?.length} Included Tests`}
                style={{ color: '#082623' }}
              />

              <TimeBadget
                className="w-auto font-semibold"
                time={timeLimit}
                icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
              />
            </div>
            <Button variant="link" title="Edit" onClick={() => setSelectedIndex(1)} />
          </div>
        )}
        {tests?.map(props => (
          <TestCardNew key={props?._id} previewTestHandler={previewTestHandler} {...props} />
        ))}
        <ViewTestModal isOpenModal={viewModalOpen} setIsOpenModal={setViewModalOpen} />
      </Section.Body>
    </Section>
  );
};

export default ViewTests;
