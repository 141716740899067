import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserRole } from 'hooks';

import { MailEdit } from 'components/Forms/MailEditForm';
import { Button, ErrorMessage, Form, Input, Textarea, Typography } from 'ui-kit';

import PasswordEdit from 'components/Forms/PasswordEditForm';
import AvatarPicker from 'components/AvatarPicker';
import Loader from 'components/Loader';

import { EditAuthorSchema, EditEmloyerSchema } from './validation';
import { updateProfileRequest } from 'store/auth/actions';
import { faLock, faMailForward } from '@fortawesome/pro-light-svg-icons';

const ProfileEditForm = () => {
  const dispatch = useDispatch();

  const [isOpenMailEdit, setIsOpenMailEdit] = useState(false);
  const [isOpenPasswdEdit, setIsOpenPasswdEdit] = useState(false);

  const { user, loading } = useSelector(store => store.auth);
  const { isAuthor } = useUserRole();

  const onSubmit = data => {
    const { firstName, lastName } = data;
    if (isAuthor) {
      const { title, bio, linkedin, facebook, behance, dribbble, website } = data;
      dispatch(
        updateProfileRequest({
          firstName,
          lastName,
          title,
          bio,
          linkedin,
          facebook,
          behance,
          dribbble,
          website
        })
      );
    } else {
      dispatch(updateProfileRequest({ firstName, lastName }));
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <Form
        resolver={yupResolver(isAuthor ? EditAuthorSchema : EditEmloyerSchema)}
        defaultValues={user}
        onSubmit={onSubmit}
        className="flex flex-1 flex-col rounded-md border border-gray-300 bg-white p-6">
        <div className="flex flex-col gap-5 xl:flex-row">
          <div className="flex-1 space-y-5">
            <Typography title="Photo" variant="heading-h5" />
            <AvatarPicker type="user" image={user?.image} />
            <div>
              <div className="flex gap-5">
                <div className="flex-1">
                  <Typography title="First name" variant="small-default" />
                  <Input name="firstName" />
                  <ErrorMessage name="firstName" />
                </div>
                <div className="flex-1">
                  <Typography title="Last name" variant="small-default" />
                  <Input name="lastName" />
                  <ErrorMessage name="lastName" />
                </div>
              </div>

              {isAuthor && (
                <div className="flex flex-1  space-x-5">
                  <div className="flex-1">
                    <div>
                      <Typography title="Title" variant="small-default" />
                      <Input name="title" />
                      <ErrorMessage name="title" />
                    </div>
                    <div>
                      <Typography title="Biography" variant="small-default" />
                      <Textarea maxLength={1000} name="bio" />
                      <ErrorMessage name="bio" />
                    </div>
                  </div>
                  <div className="flex-1">
                    <div>
                      <Typography title="LinkedIn" variant="small-default" />
                      <Input name="linkedin" />
                      <ErrorMessage name="linkedin" />
                    </div>
                    <div>
                      <Typography title="Facebook" variant="small-default" />
                      <Input name="facebook" />
                      <ErrorMessage name="facebook" />
                    </div>
                    <div>
                      <Typography title="Behance" variant="small-default" />
                      <Input name="behance" />
                      <ErrorMessage name="behance" />
                    </div>
                    <div>
                      <Typography title="Dribbble" variant="small-default" />
                      <Input name="dribbble" />
                      <ErrorMessage name="dribbble" />
                    </div>
                    <div>
                      <Typography title="Website" variant="small-default" />
                      <Input name="website" />
                      <ErrorMessage name="website" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5 flex justify-between">
          {user?.verifiedAt && (
            <div className="flex gap-4">
              <Button
                variant="primary-outline"
                onClick={() => setIsOpenPasswdEdit(true)}
                icon={faLock}
                iconSize={20}
                title="Change password"
              />
              <Button
                variant="primary-outline"
                onClick={() => setIsOpenMailEdit(true)}
                icon={faMailForward}
                iconSize={20}
                title="Change email"
              />
            </div>
          )}
          <Button type="submit" variant="primary" title="Save changes" />
        </div>
      </Form>
      <MailEdit isOpenModal={isOpenMailEdit} setIsOpenModal={setIsOpenMailEdit} />
      <PasswordEdit isOpenModal={isOpenPasswdEdit} setIsOpenModal={setIsOpenPasswdEdit} />
    </>
  );
};

export default ProfileEditForm;
