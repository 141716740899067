import { useUserRole } from 'hooks';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { navConfig } from 'utils/helpers';
import { Navbar } from 'components/Navbar';
import Footer from 'components/Footer';
import { Dashboard } from 'pages/Employer/Dashboard';
import { Modal } from 'ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { clearAssessments, getPublicDemoAssessmetsRequest } from 'store/assessment/actions';

export const AuthWrapper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role } = useUserRole();
  const { dashboard } = navConfig(role);

  const { loading } = useSelector(store => store.auth);

  useEffect(() => {
    if (role) {
      navigate(dashboard);
    }
    document.body.style.overflow = 'hidden';
    dispatch(getPublicDemoAssessmetsRequest());

    return () => {
      dispatch(clearAssessments());
      document.body.style.overflow = 'auto';
    };
  }, [role]);

  return (
    <div
      style={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #FCFFFE 0%, #F8FAF5 48.96%, #FAFCF7 100%)'
      }}
      className="flex flex-col">
      {loading && <Loader />}
      <Navbar auth />
      <div className="flex flex-1 flex-col">
        <Modal className="self-center" isOpen>
          <div className="h-[520px] w-[1046px]">
            <Outlet />
          </div>
        </Modal>
        <Dashboard auth />
        <Footer />
      </div>
    </div>
  );
};
