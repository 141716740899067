import { useState } from 'react';

import { sizes, variants } from '../Button/variants';
import { useOutsideClick } from 'hooks/useOutsideClick/useOutsideClick';
import Icon from 'ui-kit/Icon';
import { twMerge } from 'tailwind-merge';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

export const ButtonSplit = ({
  variant,
  titleMain,
  titleSecond,
  className,
  disabled,
  onClickMain,
  onClickSecond
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);
  return (
    <span className="relative flex">
      <button
        className={twMerge(
          className,
          variant && variants[variant],
          sizes['medium'],
          'cursor-pointer rounded-none',
          'disabled:opacity-50 rounded-l-md'
        )}
        onClick={onClickMain}
        disabled={disabled}>
        {titleMain}
      </button>
      <button
        className={twMerge(
          variant && variants[variant],
          sizes['medium'],
          'disabled:opacity-50 cursor-pointer border-0 rounded-none',
          'border-y border-r rounded-r-md',
          isOpen && 'bg-[#F1F2F3] hover:bg-[#F1F2F3'
        )}
        ref={ref}
        onClick={() => setIsOpen(prev => !prev)}>
        <Icon size={18} icon={isOpen ? faChevronUp : faChevronDown} />
      </button>
      <div
        className={`absolute z-10 mt-12 flex w-full flex-col rounded-md border border-[#8C9196] bg-white py-[5px] ${
          isOpen ? 'block' : 'hidden'
        }`}>
        <span
          onClick={onClickSecond}
          className="w-full cursor-pointer py-2 pl-4 text-base font-semibold hover:bg-gray-200">
          {titleSecond}
        </span>
      </div>
    </span>
  );
};
