import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';

import { Typography, Tab } from 'ui-kit';

import Tests from './Tests';
import Profile from './Profile';

import Breadcrumbs from 'components/Breadcrumbs';
import { getAuthorRequest } from 'store/admin/actions';
import { getFullName } from 'utils/helpers';

const ViewAuthor = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();

  const { currentAuthor, loading } = useSelector(store => store.admin);
  const { firstName, lastName } = currentAuthor || {};

  useEffect(() => {
    dispatch(getAuthorRequest(_id));
  }, [_id]);

  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      {currentAuthor && (
        <>
          {loading && <Loader />}
          <Breadcrumbs />
          <Typography
            title={getFullName({ firstName, lastName })}
            variant="heading-h1"
            className="mb-6"
          />
          <Tab.Group as="div">
            <Tab.List>
              <Tab>
                <Typography variant="heading-h6">Profile</Typography>
              </Tab>
              <Tab>
                <Typography variant="heading-h6">Tests</Typography>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="p-5">
                <Profile />
              </Tab.Panel>
              <Tab.Panel className="p-5">
                <Tests />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </>
      )}
    </div>
  );
};

export default ViewAuthor;
