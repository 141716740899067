import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Loader = () => {
  return (
    <div className="fixed inset-y-0 left-0 z-[1000] flex w-[100%] items-center justify-center bg-white/30 backdrop-blur-sm">
      <ThreeDots height="100" width="100" color="grey" ariaLabel="loading" />
    </div>
  );
};

export default Loader;
