import { Typography, List, Icon } from 'ui-kit';
import { TestCard } from 'ui-kit/TestCard';

import { useUserRole } from 'hooks';

import { calculateColorHirenestScore } from 'pages/Employer/CreateAssessment_old/components/HiringStatuses/HiringStatuses';

export const SingleApplicationTestsResults = ({
  application,
  setCurrentTestId,
  setForceModalClose,
  isModalOpen,
  setIsModalOpen
}) => {
  const { isAdmin } = useUserRole();

  const scores = [];

  if (!isAdmin)
    scores.push(
      calculateColorHirenestScore(application?.hirenestScore ?? application?.scaledScore)
    );
  else {
    if (application?.scaledScore) {
      scores.push(calculateColorHirenestScore(application?.scaledScore));
      scores.push({
        bg: 'bg-[#8C9196]',
        title: 'text-[#8C9196] font-[500] text-[22px] ml-[4px] font-normal',
        border: 'border-[#8C9196]',
        icon: null,
        score: application?.hirenestScore
      });
    } else scores.push(calculateColorHirenestScore(application?.hirenestScore));
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center gap-3">
        <Typography
          title={`${application?.candidate?.[0]?.firstName} ${application?.candidate?.[0]?.lastName}'s Test Results`}
          variant="heading-h2"
        />
        <div className="flex items-center justify-center">
          {scores.map((score, idx) => {
            const scoreText = `${idx !== 0 ? '(' : ''}${score?.score?.toFixed(0)}%${
              idx !== 0 ? ')' : ''
            }`;
            return (
              <div className="flex h-[32px] items-center justify-center gap-2" key={idx}>
                {score.icon && <Icon icon={score.icon} color={score.iconColor} size={24} />}
                <Typography
                  className={`font-semibold ${score.title}`}
                  variant={application?.hirenestScore >= 0 ? 'heading-h2' : 'heading-h4'}
                  title={
                    application?.hirenestScore || application?.hirenestScore === 0
                      ? application?.hirenestScore === -1
                        ? 'Not Available'
                        : scoreText
                      : '-'
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <List
        wrap="ul"
        data={application?.tests}
        keyExtractor={(_, idx) => idx}
        element={props => (
          <TestCard.Results
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();

              const openModal = () => {
                setCurrentTestId(props._id);
                setForceModalClose(false);
                setIsModalOpen(true);
              };

              // if another result interpretation slide is opened - close it and open new one after finishing animation effect
              if (isModalOpen) {
                setForceModalClose(true);
                setTimeout(openModal, 1100);
              } else openModal();
            }}
            {...props}
            result={props.result ?? props?.scaledResult}
            title={props?.resultInterpretation?.title}
            description={props?.resultInterpretation?.description}
          />
        )}
        className="flex w-full flex-col gap-6"
      />
    </div>
  );
};
