import StickyBox from 'react-sticky-box';
import Breadcrumbs from 'components/Breadcrumbs';
import { Typography, Tab, Icon } from 'ui-kit';
import { Tab as TabUI } from '@headlessui/react';
import { ChipList } from 'ui-kit/ChipList/ChipList';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import { IconTest } from 'components/IconTest/IconTest';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEvaluateQuestionsRequest } from 'store/assessment/actions';
import Loader from 'components/Loader';
import TimeBadget from 'components/TimeBadget';
import { stringToHslColor } from 'utils/stringToHslColor';
import EvaluateQuestionComponent from 'components/EvaluateModal/EvaluateQuestionComponent';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';

export const ViewTestSummary = ({ currentTest, component }) => {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [questionIdx, setQuestionIdx] = useState(0);
  const { evaluateQuestions, loading } = useSelector(store => store.assessments);

  useEffect(() => {
    if (currentTest?._id) {
      dispatch(getEvaluateQuestionsRequest(currentTest?._id));
    }
  }, [currentTest?._id]);

  return (
    <div className="flex w-full min-w-[1066px] flex-col">
      {loading && <Loader />}
      <StickyBox
        offsetTop={0}
        offsetBottom={20}
        className="z-10 bg-[#FFFFFF] px-6 pt-6 shadow-[0_2px_4px_0px_rgba(51,51,51,0.12)]">
        <div className="flex w-full gap-[18px]">
          <IconTest size={72} url={currentTest?.icon?.url} />
          <div className="flex flex-col justify-center gap-[4px]">
            <Breadcrumbs type="modalViewTest" />
            <Typography variant="heading-h2" title={currentTest?.name} />
          </div>
          <div className="flex flex-1 items-center justify-end">{component && component}</div>
        </div>
        <p className="mt-[6px] mb-[24px] text-lg">{currentTest?.tagline}</p>
        <div>
          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={index => {
              setSelectedIndex(index);
            }}
            as="div"
            className="w-[268px] rounded-md bg-white">
            <Tab.List>
              <TabUI
                className={({ selected }) =>
                  `${
                    selected && 'bg-[#F3F8F6]'
                  } rounded-t-md group flex flex-1 items-center justify-center gap-[12px] border-b border-[#D2D5D8] py-[12px] !outline-none hover:border-[#12A182] hover:bg-[#F5FEFD] ui-selected:border-[#007D6F]`
                }>
                <Typography
                  className="text-[#6D7175] group-hover:text-[#12A182] ui-selected:text-[#007D6F]"
                  variant="heading-h3"
                  title="About Test"
                />
              </TabUI>
              <TabUI
                className={({ selected }) =>
                  `${
                    selected && 'bg-[#F3F8F6]'
                  } rounded-t-md group flex flex-1 items-center justify-center gap-[12px] border-b border-[#D2D5D8] py-[12px] !outline-none hover:border-[#12A182] hover:bg-[#F5FEFD] ui-selected:border-[#007D6F]`
                }>
                <Typography
                  className="text-[#6D7175] group-hover:text-[#12A182] ui-selected:text-[#007D6F]"
                  variant="heading-h3"
                  title="Questions"
                />
              </TabUI>
            </Tab.List>
          </Tab.Group>
        </div>
      </StickyBox>
      <div className="flex w-full flex-col gap-[24px] bg-[#FAFCF7] p-6">
        <Tab.Group selectedIndex={selectedIndex} as="div">
          <Tab.List className="hidden">
            <TabUI></TabUI>
            <TabUI></TabUI>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <div className="flex flex-1 gap-6">
                <div className="flex min-w-[60%] flex-2 flex-col gap-6">
                  <div className="rounded-md bg-[#FFFFFF] p-6 shadow-[0_1px_8px_0px_rgba(68,68,68,0.12)]">
                    <TextAreaView className="pb-8" title={currentTest?.summary} />
                  </div>
                  {currentTest?.outcomes?.length > 0 && (
                    <div className="flex flex-col gap-2 rounded-md bg-[#FFFFFF] p-6 shadow-[0_1px_8px_0px_rgba(68,68,68,0.12)]">
                      <Typography variant="heading-h5" title="Outcomes" />
                      <TextAreaView title={currentTest?.outcomes} />
                    </div>
                  )}
                  {currentTest?.whyMeasure?.title?.length > 0 && (
                    <div className="flex flex-col gap-2 rounded-md bg-[#FFFFFF] p-6 shadow-[0_1px_8px_0px_rgba(68,68,68,0.12)]">
                      <Typography variant="heading-h5" title={currentTest?.whyMeasure?.title} />
                      <TextAreaView title={currentTest?.whyMeasure?.text} />
                    </div>
                  )}
                </div>
                <div className="flex h-max flex-col gap-6">
                  {currentTest?.sections?.length > 1 && (
                    <div className="flex h-max flex-1 flex-col gap-3 rounded-md bg-[#FFFFFF] p-6 shadow-[0_1px_8px_0px_rgba(68,68,68,0.12)]">
                      <div className="flex flex-col gap-6">
                        <div className="mt-[24px] flex flex-col gap-[18px] pr-[40px]">
                          <Typography
                            variant="heading-h5"
                            title={
                              (currentTest?.sections?.length > 1
                                ? currentTest?.displaySectionAs?.sectionPlural
                                : currentTest?.displaySectionAs?.sectionSingle) || 'Sections'
                            }
                          />
                          {currentTest?.sections?.map(({ name, _id, resultInterpretation }) => (
                            <div key={_id} className="flex max-w-[420px] flex-col gap-2">
                              <Typography variant="heading-h6" title={name} />
                              {resultInterpretation?.description && (
                                <HtmlTextReplacer html={resultInterpretation?.description} />
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex max-h-max flex-1 flex-col gap-3 rounded-md bg-[#FFFFFF] p-6 shadow-[0_1px_8px_0px_rgba(68,68,68,0.12)]">
                    <div className="flex flex-col gap-6">
                      <div className="flex items-center justify-between gap-6">
                        <div className="flex-1 space-y-1 flex-col">
                          <Typography variant="small-default" className="text-[#6D7175]" title="Time Limit" />
                          <TimeBadget
                            className="inline-flex w-auto font-semibold"
                            time={currentTest?.timeLimit || '-'}
                            icon={<Icon icon={faHourglassHalf} size={16} className="text-[#2F6E63]" />}
                          />
                        </div>
                        <div className="flex-1 space-y-1 flex-col">
                          <Typography
                            variant="small-default"
                            className="text-[#6D7175]"
                            title="Estimated Duration"
                          />
                          <TimeBadget
                            className="inline-flex w-auto font-semibold"
                            time={(currentTest?.estimatedDuration ?? currentTest?.timeLimit) || '-'}
                            icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
                          />
                        </div>
                      </div>
                      <div className="flex items-center justify-between gap-6">
                        <div className="flex flex-1 flex-col gap-1">
                          <Typography
                            variant="regular-default"
                            title="Questions"
                            className="text-[#6D7175]"
                          />
                          <Typography
                            variant="regular-default"
                            className="capitalize"
                            title={currentTest?.questionsCount}
                          />
                        </div>
                        <div className="flex flex-1 flex-col gap-1">
                          <Typography
                            variant="regular-default"
                            title="Language"
                            className="text-[#6D7175]"
                          />
                          <Typography variant="regular-default" title={currentTest?.language} />
                        </div>
                        <div className="flex flex-1 flex-col gap-1">
                          <Typography
                            variant="regular-default"
                            title="Difficulty"
                            className="text-[#6D7175]"
                          />
                          <Typography
                            variant="regular-default"
                            className="capitalize"
                            title={currentTest?.difficulty}
                          />
                        </div>
                      </div>
                      {currentTest?.suitableFor?.length > 0 && (
                        <div className="flex w-full flex-col gap-1">
                          <Typography
                            variant="regular-default"
                            title="Suitable for"
                            className="text-[#6D7175]"
                          />
                          <div className="flex flex-wrap gap-3">
                            {currentTest?.suitableFor?.map(({ label, _id }) => (
                              <ChipList
                                style={{ backgroundColor: stringToHslColor(label, 30, 94) }}
                                className="text-sm"
                                key={_id}
                                name={label}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                      {currentTest?.areasOfUse?.length > 0 && (
                        <div className="flex w-full flex-col gap-1">
                          <Typography
                            variant="regular-default"
                            title="Areas of Use"
                            className="text-[#6D7175]"
                          />
                          <div className="flex flex-wrap gap-3">
                            {currentTest?.areasOfUse?.map(({ label, _id }) => (
                              <ChipList
                                style={{ backgroundColor: stringToHslColor(label, 30, 94) }}
                                className="text-sm"
                                key={_id}
                                name={label}
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="flex items-center justify-center">
                <div className="max-w-[656px] rounded-md bg-[#FFFFFF] shadow-[0_1px_8px_0px_rgba(68,68,68,0.12)]">
                  <div className="h-[6px] w-full overflow-hidden rounded-t-md bg-[#F6F6F7]">
                    <div
                      className={`h-[6px] bg-[#00A47C]`}
                      style={{ width: `${((questionIdx + 1) * 100) / evaluateQuestions.length}%` }}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="flex w-full flex-col justify-between px-[32px] pt-[16px] pb-[32px] ">
                    <EvaluateQuestionComponent
                      questionIdx={questionIdx}
                      questions={evaluateQuestions}
                      prevQuestionHandler={() => setQuestionIdx(questionIdx - 1)}
                      nextQuestionHandler={() => {
                        if (questionIdx + 1 !== evaluateQuestions.length) {
                          setQuestionIdx(questionIdx + 1);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
