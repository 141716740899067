import { Typography } from 'ui-kit';

import cn from 'utils/classNames';
import { getModifyData } from 'utils/helpers';

const CandidateInfo = ({ data, className }) => {
  const { firstName, lastName, email, createdAt, lastActiveAt } = data || {};
  return (
    <div className={cn('flex flex-col flex-auto justify-between', className)}>
      <div>
        <Typography title={`${firstName} ${lastName}`} variant="heading-h4" />
        <Typography title={email} className="mt-1 text-gray-500" variant="small-default" />
      </div>
      <ul className="flex flex-col gap-2 text-gray-400">
        {!!lastActiveAt && (
          <li>
            <Typography title={`Last active on ${getModifyData(lastActiveAt)}`} variant="small-default" />
          </li>
        )}
        <li>
          <Typography title={`Registered on ${getModifyData(createdAt)}`} variant="small-default" />
        </li>
      </ul>
    </div>
  );
};

export default CandidateInfo;
