import Attachment from '../../../../components/Attachment';

const SelectAnswer = ({
  _id,
  name,
  disabled = false,
  className,
  label,
  handleChange,
  questionType,
  selectedAnswer,
  attachment,
  isAllAttachments
}) => {
  const isChecked = selectedAnswer.some(answer => answer === _id);

  const onClickHandler = () => {
    handleChange && handleChange(_id);
  };

  return (
    <div
      className={`flex cursor-pointer ${
        attachment && 'flex-col'
      } space-y-5 rounded-md border p-3 hover:border-gray-500  hover:bg-gray-200 ${
        isChecked ? 'border-[#00A47C] bg-[#F1F8F5]' : 'border-[#C9CCCF]'
      } ${className}`}
      onClick={() => !disabled && onClickHandler()}>
      {!isAllAttachments && (
        <div className="flex items-center gap-[6px]">
          {questionType === 'single' ? (
            <input type="radio" className="h-4 w-4" id={_id} name={name} checked={isChecked} />
          ) : (
            <input
              type="checkbox"
              className="h-6 w-6 rounded-md transition-all"
              id={_id}
              name={name}
              checked={isChecked}
            />
          )}
          <span
            style={{ wordBreak: 'break-word' }}
            className="ml-2 flex-1 break-words text-lg text-black">
            {label}
          </span>
        </div>
      )}
      {attachment?.url && <Attachment attachment={attachment} maxHeight={150} maxWidth={150} />}
    </div>
  );
};

export default SelectAnswer;
