import React from 'react';

import { useDispatch } from 'react-redux';

import { callToast, dataURLtoFile } from 'utils/helpers';
import classNames from 'utils/classNames';
import { uploadAttachmentRequest } from 'store/tests/actions';

interface InputFileProps {
  onChangeValue?(src: any): void;
  component: () => React.ReactElement;
  className?: string;
}

export const InputFile: React.FC<InputFileProps> = ({
  onChangeValue,
  component: Component,
  className
}) => {
  const dispatch = useDispatch();

  const uploadHandler = (file: any) => {
    const formData = new FormData();

    if (file) {
      formData.append('attachment', file);

      dispatch(
        uploadAttachmentRequest({
          formData,
          onSuccess: (data: { attachmentUrl: any }) => {
            onChangeValue?.(data.attachmentUrl);
          }
        })
      );
    }
  };

  const handeChange = (e: { target: HTMLInputElement }) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.addEventListener('load', data => {
        if (data.target?.result) {
          const photoFile = dataURLtoFile(data.target.result, file.name);
          const photo = URL.createObjectURL(photoFile);
          uploadHandler(photoFile);
          e.target.value = ''
        }
      });

      reader.addEventListener('error', err => callToast('error', err));
    }
  };

  return (
    <label
      className={classNames(
        className ||
          'rounded-lg border border-gray-400 w-11 h-11 overflow-hidden flex items-center justify-center hover:bg-gray-200 cursor-pointer disabled:opacity-50'
      )}>
      <Component />
      <input
        accept=".png,.jpg,.jpeg"
        className="hidden"
        type="file"
        onChange={handeChange}
      />
    </label>
  );
};
