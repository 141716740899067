import { useCallback } from 'react';

import { useDispatch } from 'react-redux';

import { Button, ErrorMessage, Form, Input, Modal, Typography } from 'ui-kit';
import { yupResolver } from '@hookform/resolvers/yup';
import { InviteAuthorSchema } from 'components/Forms/validation';
import { inviteAuthorRequest } from 'store/admin/actions';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

export const InviteAuthorModal = ({ isOpen, closeModal }) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(data => {
    dispatch(inviteAuthorRequest(data));
    closeModal();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={() => closeModal(false)} size="2xl">
      <Form onSubmit={onSubmit} resolver={yupResolver(InviteAuthorSchema)} mode="onBlur">
        {({ formState }) => (
          <>
            <Modal.Header className="px-6">
              <Typography variant="heading-h4" title="Invite Author" />
              <Button
                icon={faXmark}
                iconSize={20}
                iconColor="#5C5F62"
                onClick={() => closeModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="px-6">
              <div className="flex gap-4">
                <div className="flex-1">
                  <Typography title="First Name" variant="small-default" />
                  <Input name="firstName" />
                  <ErrorMessage name="firstName" />
                </div>
                <div className="flex-1">
                  <Typography title="Last Name" variant="small-default" />
                  <Input name="lastName" />
                  <ErrorMessage name="lastName" />
                </div>
              </div>
              <div>
                <Typography title="Email" variant="small-default" />
                <Input name="email" />
                <ErrorMessage name="email" />
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-end gap-4">
              <div className="flex justify-end gap-4">
                <Button variant="secondary" title="Cancel" onClick={closeModal} />
                <Button
                  disabled={!formState.isDirty}
                  variant="primary"
                  type="submit"
                  title="Send Invitation"
                />
              </div>
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};
