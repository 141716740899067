import { TestCard } from './TestCard';
import { Admin } from './Admin';
import { Assessment } from './Assessment';
import { Author } from './Author';
import { Preview } from './Preview';
import { Results } from './Results';
import { EmployerCard } from './Employer';

TestCard.Admin = Admin;
TestCard.Assessment = Assessment;
TestCard.Preview = Preview;
TestCard.Author = Author;
TestCard.Results = Results;
TestCard.Employer = EmployerCard;

export { TestCard };
