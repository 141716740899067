import React, {useState} from 'react';
import { Button, Typography } from 'ui-kit';

import { ModalWrapper } from '../wrapper';
import { ModalBody } from '../ModalBody';
import { ModalFooter } from '../ModalFooter';
import { ModalHeader } from '../ModalHeader';

import cn from 'utils/classNames';

enum Animations {
  DEFAULT = 'default',
  RIGHT_TO_LEFT = 'rightToLeft',
  LEFT_TO_RIGHT = 'leftToRight'
}

const animations: { [key in Animations]: any} = {
  [Animations.DEFAULT]: {
    className: 'inset-0'
  },
  [Animations.RIGHT_TO_LEFT]: {
    className: 'right-0 top-0 bottom-0',
    onShowAnimation: 'slide-out-right',
    onHideAnimation: 'slide-in-right'
  },
  [Animations.LEFT_TO_RIGHT]: {
    className: 'left-0 top-0 bottom-0',
    onShowAnimation: 'slide-out-left',
    onHideAnimation: 'slide-in-left'
  }
};

interface ModalComponents {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
}

// TODO: move interfaces to separate folder and do extending from base ModalProps interface
interface ModalSlideProps {
  isOpen: boolean;
  title: string;
  children?: React.ReactNode;
  headerChildren?: React.ReactNode;
  customWidth?: number;
  className?: string;
  onClose?: () => void;
  disableScroll?: boolean;
  animationDuration?: number;
  animationStyle?: string;
  animationType?: Animations;
  forceClosing?: boolean;
}

const ModalSlide: React.FC<ModalSlideProps> & ModalComponents = ({
  children,
  headerChildren,
  className,
  isOpen,
  title,
  onClose,
  customWidth = undefined,
  disableScroll = false,
  animationType = Animations.DEFAULT,
  animationDuration= 1, // in seconds
  animationStyle = 'ease-in-out',
  forceClosing
}) => {
  const animationString = `${
    animationType !== Animations.DEFAULT ? `${animations[animationType].onShowAnimation} ${animationDuration}s ${animationStyle}` : ''
  }`;

  const [slideAnimationString, setSlideAnimationString] = useState(animationString);
  const [closing, setClosing] = useState(false);
  const closeWithAnimation = () => {
    if (animationType === Animations.DEFAULT) return onClose && onClose();
    setSlideAnimationString(`${animations[animationType].onHideAnimation} ${animationDuration}s ${animationStyle}`);
    // wait for closing animation before removing component from DOM
    setTimeout(() =>  {
      setClosing(false);
      setSlideAnimationString(animationString);
      onClose && onClose();
    }, animationDuration * 1000);
  }
  // avoid rerender component during closing animation forced by parent
  if (forceClosing && !closing) {
    setClosing(true);
    closeWithAnimation();
  }

  return (
    <ModalWrapper isOpen={isOpen} disableScroll={disableScroll}>
      {({ stopPropagation }) => (
        <>
        {disableScroll && (
          <div className="fixed inset-0 bg-[rgba(100,100,100,0.5)] z-10" onClick={closeWithAnimation}/>
        )}
        <div
          className={`fixed inset-y-0 z-20 flex ${animations[animationType].className}
            ${customWidth ? `w-[${customWidth}px}] min-w-[${customWidth}px] max-w[${customWidth}px]`: ''}
            drop-shadow-[0_1px_18px_rgba(82,82,82,0.3)] justify-center overflow-auto`}
          onClick={closeWithAnimation}
          style={{
            animation: slideAnimationString
          }}
        >
          <div
            className={cn('bg-white max-h-full h-full w-full flex flex-col', className)}
            onClick={stopPropagation}>
            <ModalHeader className="px-6 py-5 !bg-[#007D6F] !rounded-none">
              <Typography variant="heading-h2" className="font-semibold text-[20px] text-[#FFFFFF]" title={title} />
              {headerChildren}
              <div className="flex flex-1 justify-end">
                <Button
                  onClick={closeWithAnimation}
                  className="flex"
                >
                  <i className="fa-regular fa-xmark cursor-pointer text-lg text-[#FFFFFF]"></i>
                </Button>
              </div>
            </ModalHeader>
            <div className="overflow-auto h-full grid">
              {children}
            </div>
          </div>
        </div>
        </>
      )}
    </ModalWrapper>
  );
};

ModalSlide.Header = ModalHeader;
ModalSlide.Body = ModalBody;
ModalSlide.Footer = ModalFooter;

export { ModalSlide };
