import Footer from 'components/Footer';

import { useNavigate } from 'react-router-dom';

import { Typography, List, Icon } from 'ui-kit';
import { insertTags } from 'utils/insertTags';
import { eulaConfig, privacyConfig } from './config';
import { faChevronLeft, faChevronsLeft } from '@fortawesome/pro-light-svg-icons';

const Terms = ({ type }) => {
  const navigate = useNavigate();

  const getConfig = () => {
    switch (type) {
      case 'eula':
        return { name: 'End User License Agreement', config: eulaConfig };
      case 'privacyPolicy':
        return { name: 'Privacy Policy of Hirenest', config: privacyConfig };
    }
  };

  return (
    <div>
      <div className="my-10 mx-auto max-w-[736px] rounded-md border border-gray-400 bg-white p-10 ">
        <div className="mb-6 flex w-full items-baseline">
          <button onClick={() => navigate(-1)}>
            <div className="mb-5 flex items-center">
              <Icon icon={faChevronLeft} size={20} />
              <Typography title="Back" variant="regular-emphasis" className="text-green-400" />
            </div>
          </button>
          <div className="flex flex-1 justify-center">
            <Typography title={getConfig().name} variant="heading-h2" />
          </div>
        </div>
        <List
          data={getConfig().config}
          element={({ supTitle, title, subTitle, isList, text }) => (
            <li>
              {supTitle && <Typography title={supTitle} variant="heading-h3" className="mb-4" />}
              {title && <Typography title={title} variant="heading-h5" className="mb-2" />}
              {subTitle && <Typography title={subTitle} variant="regular-default" className="mb-2" />}
              <List
                data={text}
                element={prop => (
                  <li className={isList ? `list-disc ` : 'list-none'}>
                    <Typography
                      variant="regular-default"
                      children={isList ? insertTags(prop) : prop}
                    />
                  </li>
                )}
                keyExtractor={(_, idx) => idx}
                wrap="ul"
                className={`space-y-3 ${isList && 'pl-5'} `}
              />
            </li>
          )}
          keyExtractor={(_, idx) => idx}
          wrap="ul"
          className="space-y-6"
        />
        {type === 'privacyPolicy' && (
          <div className="mt-2 flex gap-1">
            <p>Email:</p>
            <a className="text-green-400" href="mailto:ernest@hirenest.com">
              ernest@hirenest.com
            </a>
          </div>
        )}
        <button onClick={() => navigate(-1)} className="mt-5 flex items-center">
          <Icon icon={faChevronsLeft} size={20} />
          <Typography title="Back" variant="regular-emphasis" className="text-green-400" />
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
