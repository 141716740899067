import { yupResolver } from '@hookform/resolvers/yup';
import { profileCompleteSchema } from 'components/Forms/validation';
import { localStorageService } from 'helpers/localStorageService';
import { useDispatch } from 'react-redux';
import { profileCompleteRequest, setShowOnboard } from 'store/auth/actions';
import { Button, ErrorMessage, Form, Input, Modal, Typography } from 'ui-kit';
import PhoneInputReact from 'ui-kit/PhoneInput';

export const ModalPromoEmployer = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(profileCompleteRequest(data));
    setIsOpen(false);
    dispatch(setShowOnboard(true));
    localStorageService.setCompleteProfile(true);
  };
  return (
    <Modal className="m-auto max-w-[600px] overflow-auto px-9" isOpen={isOpen}>
      <div className="flex flex-col p-6">
        <p className="text-center text-xl font-medium">Complete your profile</p>
        <Typography
          className="mt-[8px] text-[#6D7175]"
          variant="regular-default"
          title="You are once step away from changing your hiring process with pre-employment assessments."
        />
        <Form
          className="mt-[12px]"
          mode="onBlur"
          resolver={yupResolver(profileCompleteSchema)}
          onSubmit={onSubmit}>
          {({ watch }) => (
            <>
              <div className="flex gap-4">
                <div className="flex-1">
                  <Typography className="mb-[4px]" title="First Name" />
                  <Input name="firstName" />
                  <ErrorMessage name="firstName" />
                </div>
                <div className="flex-1">
                  <Typography className="mb-[4px]" title="Last Name" />
                  <Input name="lastName" />
                  <ErrorMessage name="lastName" />
                </div>
              </div>
              <div>
                <Typography className="mb-[4px]" title="Company Name" />
                <Input name="companyName" />
                <ErrorMessage name="companyName" />
              </div>
              <div>
                <Typography className="mb-[4px]" title="Phone Number" />
                <PhoneInputReact name="phone" />
                <ErrorMessage name="phone" />
              </div>
              <div>
                <div className="mb-[4px]">
                  What’s your job title? <span className="text-[#6D7175]">(optional)</span>
                </div>
                <Input autoComplete="off" name="title" />
                <ErrorMessage name="title" />
              </div>
              <div className="mt-3 flex justify-center">
                <Button type="submit" variant="primary" title="Finish Registration" />
              </div>
            </>
          )}
        </Form>
      </div>
    </Modal>
  );
};
