import {
  faBallotCheck,
  faBuildingColumns,
  faClipboardList,
  faGem,
  faUser
} from '@fortawesome/pro-light-svg-icons';
import * as routes from 'routes/routes';

export const navConfigAdmin = {
  dashboard: routes.HOME,
  header: [
    {
      name: 'Assessments',
      link: routes.ASSESSMENTS,
      button: true
    },
    {
      name: 'Candidates',
      link: routes.CANDIDATES,
      canditates: [1]
    },
    {
      name: 'Employers',
      link: routes.EMPLOYERS
    },
    {
      name: 'Authors',
      link: routes.AUTHORS
    },
    {
      name: 'Tests',
      link: routes.TESTS
    },
    {
      name: 'Configuration',
      link: routes.CONFIGURATION
    }
  ],
  configurationMenu: [
    {
      name: 'General',
      link: routes.CONFIGURATION + '/' + routes.GENERAL
    },
    {
      name: 'Accounts',
      link: routes.CONFIGURATION + '/' + routes.ACCOUNTS
    },
    {
      name: 'Admins',
      link: routes.CONFIGURATION + '/' + routes.ADMINS
    },
    {
      name: 'Assessments',
      link: routes.CONFIGURATION + '/' + routes.ASSESSMENTS_CONF
    },
    {
      name: 'Attachments',
      link: routes.CONFIGURATION + '/' + routes.ATTACHMENTS
    },
    {
      name: 'Authors',
      link: routes.CONFIGURATION + '/' + routes.AUTHORCONF
    },
    {
      name: 'Billing',
      link: routes.CONFIGURATION + '/' + routes.BILLING
    },
    {
      name: 'Subscription Plans',
      link: routes.CONFIGURATION + '/' + routes.BILLING + '/' + routes.SUBSCRIPTION_PLANS,
      submenu: true
    },
    {
      name: 'Employers',
      link: routes.CONFIGURATION + '/' + routes.EMPLOYERSCONF
    },
    {
      name: 'Candidates',
      link: routes.CONFIGURATION + '/' + routes.CANDIDATESCONF
    },
    {
      name: 'Companies',
      link: routes.CONFIGURATION + '/' + routes.COMPANIES
    },
    {
      name: 'Common',
      link: routes.CONFIGURATION + '/' + routes.COMMON
    },
    {
      name: 'Notifications',
      link: routes.CONFIGURATION + '/' + routes.NOTIFICATIONS
    },
    {
      name: 'Occupations',
      link: routes.CONFIGURATION + '/' + routes.OCCUPATIONS
    },
    {
      name: 'Tests',
      link: routes.CONFIGURATION + routes.TESTS
    }
  ],
  menu: [
    {
      name: 'My profile',
      link: routes.PROFILE,
      icon: faUser
    }
  ]
};

export const navConfigEmployer = {
  dashboard: routes.DASHBOARD,
  header: [
    {
      name: 'Dashboard',
      link: routes.DASHBOARD
    },
    {
      name: 'Candidates',
      link: routes.CANDIDATES,
      canditates: [1]
    },
    {
      name: 'Assessments',
      link: routes.ASSESSMENTS,
      button: true
    },
    {
      name: 'Tests',
      link: routes.TESTS
    }
  ],
  menu: [
    {
      name: 'My profile',
      link: routes.PROFILE,
      icon: faUser
    },
    // {
    //   name: 'Notifications',
    //   link: routes.NOTIFICATIONS,
    //   icon: 'notification'
    // },
    // {
    //   name: 'Team management',
    //   link: routes.TEAM_MANAGMENT,
    //   icon: 'team'
    // },
    {
      name: 'My company',
      link: routes.COMPANY,
      icon: faBuildingColumns
    },
    {
      name: 'Manage Subscription',
      link: routes.SUBSCRIPTIONS,
      icon: faGem
    }
    // {
    //   name: 'Assesment settings',
    //   link: routes.ASSESSMENTS_SETTINGS,
    //   icon: 'settings'
    // }
  ]
};

export const navConfigAuthor = {
  dashboard: routes.TESTS,
  header: [
    {
      name: 'My Tests',
      link: routes.TESTS,
      icon: faBallotCheck
    },
    {
      name: 'My Assessment Templates',
      link: routes.ASSESSMENT_TEMPLATES,
      icon: faClipboardList
    }
  ],
  menu: [
    {
      name: 'My profile',
      link: routes.PROFILE,
      icon: faUser
    }
  ]
};

export const navConfigUnauthorized = {
  dashboard: routes.AUTH + '/' + routes.SIGNIN,
  header: []
};
