import { useEffect } from 'react';
import { createPasswordSchema } from 'components/Forms/validation';
import { useDispatch } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { newPasswordRequest, validateHashRequest } from 'store/auth/actions';
import { Button, ErrorMessage, Form, Strength, Typography } from 'ui-kit';

import { PasswordInput } from 'components/PasswordInput';
import { Logo } from 'ui-kit/Logo';

const CreatePasswordForm = () => {
  const { hash } = useLocation();
  const preparedHash = hash.substring(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = data => {
    dispatch(
      newPasswordRequest({
        data: {
          hash: preparedHash,
          password: data.newPassword,
          passwordConfirmation: data.passwordConfirmation
        },
        navigate
      })
    );
  };

  useEffect(() => {
    if (preparedHash) {
      dispatch(
        validateHashRequest({
          data: preparedHash,
          navigate
        })
      );
    }
  }, [preparedHash]);

  return (
    <div className="flex flex-1 items-center justify-center gap-12">
      <Form
        className="container max-w-screen-sm rounded-xl border border-gray-400 bg-white py-6 px-12"
        onSubmit={onSubmit}
        mode="all"
        resolver={yupResolver(createPasswordSchema)}>
        {({ formState, watch }) => (
          <>
            <div className="mb-6 flex justify-center border-b border-gray-400 pb-6 ">
              <Logo width={140} height={28} />
            </div>
            <p className="mb-[12px] text-center text-lg font-medium">Set Your Password</p>
            <div>
              <Typography className="mb-1" title="New Password" />
              <PasswordInput name="newPassword" />
              <Strength className="mt-2" value={watch('newPassword')} />
              <ErrorMessage name="newPassword" />
            </div>
            <div className="flex justify-center gap-4 pt-2">
              <Button
                disabled={!formState.isValid}
                title="Save New Password"
                type="submit"
                variant="primary"
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default CreatePasswordForm;
