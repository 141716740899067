import { Button, Typography } from 'ui-kit';
import { Droppable, Draggable } from 'react-beautiful-dnd';

export const TestCategoryCard = ({
  _id,
  name,
  visible,
  description,
  subcategories,
  nonLinear,
  scales,
  deleteAction,
  edit,
  setIsFormModalOpen
}) => {
  return (
    <div className="flex w-full flex-col gap-[18px] rounded-xl border-[0.5px] border-[#C9CCCF] bg-[#FCFCFC] py-[18px] px-6 transition-all hover:shadow-small">
      <div className="flex w-full items-center justify-between pr-6">
        <div className="flex items-center gap-[6px]">
          <Typography variant="heading-h5" title={name} />
          {nonLinear && (
            <div className="rounded-[3px] border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] font-semibold text-[#005359] text-[14px] shadow-[0_1px_2px_rgba(82,104,149,0.12)]">
              NON-LINEAR
            </div>
          )}
          {!visible && (
            <div className="rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] font-semibold text-[#6D7175] text-[14px] shadow-small">
              HIDDEN
            </div>
          )}
        </div>
        <div className="flex gap-3">
          <Button
            title="Create Subcategory"
            onClick={e => {
              e.stopPropagation();
              setIsFormModalOpen({ open: true, parent: _id });
            }}
    variant="primary-outline"
            className="w-[185px] font-semibold text-[#082623] hover:border-[#999EA4] hover:bg-[#F6F6F7]"
          />
          <Button
            onClick={e => {
              e.stopPropagation();
              edit({ _id, name, visible, subcategories, description, nonLinear, scales });
            }}
    variant="primary-outline"
            className="hover:border-[#999EA4] hover:bg-[#F6F6F7]">
            <i className="fa-light fa-pencil h-[16px] w-[16px] text-[#5C5F62]"></i>
          </Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              deleteAction({ _id, name, visible, subcategories, description });
            }}
    variant="primary-outline"
            className="hover:border-red-400 hover:bg-red-50">
            <i className="fa-light fa-trash-can h-[16px] w-[14px] text-[#5C5F62]"></i>
          </Button>
        </div>
      </div>
      {subcategories?.length > 0 && (
        <Droppable key={_id} droppableId={_id} type="droppable-subcategory">
          {provided => (
            <div
              className="flex flex-col gap-[18px]"
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {subcategories?.map((props, index) => (
                <Draggable key={props?._id} draggableId={props?._id} index={index}>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <div
                        key={props?._id}
                        className="hover:shadow-medium flex w-full items-center justify-between rounded-md border-[0.5px] border-[#C9CCCF] bg-[#F6F6F7] px-6 py-[10px] shadow-small hover:border-[#C9CCCF] hover:bg-[#EFEFF0]">
                        <div className="flex items-center gap-[6px]">
                          <Typography variant="heading-h5" title={props?.name} />
                          {!props?.visible && (
                            <div className="rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] font-semibold text-[#6D7175] text-[14px] shadow-small">
                              HIDDEN
                            </div>
                          )}
                        </div>
                        <div className="flex gap-3">
                          <Button
                            onClick={e => {
                              e.stopPropagation();
                              edit(props);
                            }}
                    variant="primary-outline"
                            className="hover:border-[#999EA4] hover:bg-[#F6F6F7]">
                            <i className="fa-light fa-pencil h-[16px] w-[16px] text-[#5C5F62]"></i>
                          </Button>
                          <Button
                            onClick={e => {
                              e.stopPropagation();
                              deleteAction(props);
                            }}
                    variant="primary-outline"
                            className="hover:border-red-400 hover:bg-red-50">
                            <i className="fa-light fa-trash-can h-[16px] w-[14px] text-[#5C5F62]"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
};
