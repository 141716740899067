import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { Icon, Section, Typography } from 'ui-kit';
import { ToolTipsApplicationBody } from './ToolTipsApplicationBody/ToolTipsApplicationBody';
import ImageCarousel from 'components/ImageCarousel/ImageCarousel';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

const styles = {
  Passed: 'bg-[#D1E7DD] text-[#051B11]',
  'Not Passed': 'bg-[#F8D7DA] text-[#2C0B0E]',
  Suspicious: 'bg-[#FFF3CD] text-[#332701]'
};

export const SectionAntiCheatMonitor = ({ antiCheatMonitor, embedded, images }) => {
  return (
    <Section className={`h-max ${!embedded ? 'flex-1' : 'max-w-[565px]'} p-[32px]`}>
      <ToolTipsApplicationBody />
      <Typography variant="heading-h4" title="Anti-Cheating Monitor" />
      <div className="mt-[32px] flex flex-col gap-[24px]">
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Monitor IP" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="monitorIp-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.monitorIp]
            }`}>
            {antiCheatMonitor?.monitorIp}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Unique IP" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="uniqueIp-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.uniqueIp]
            }`}>
            {antiCheatMonitor?.uniqueIp}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Email Similarity" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="emailSimilarity-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.emailSimilarity]
            }`}>
            {antiCheatMonitor?.emailSimilarity}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Browser Fingerprint" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="browserFingerprint-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.browserFingerprint]
            }`}>
            {antiCheatMonitor?.browserFingerprint}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Assessment Completion Time" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="assessmentCompletionTime-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.assessmentCompletionTime]
            }`}>
            {antiCheatMonitor?.assessmentCompletionTime}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Test Completion Time" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="testCompletionTime-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.testCompletionTime]
            }`}>
            {antiCheatMonitor?.testCompletionTime}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Full-screen mode always active" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="fullScreen-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.fullScreenMode]
            }`}>
            {antiCheatMonitor?.fullScreenMode}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Mouse always is the assessment window" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="mouseInAssessmentWindow-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.mouseInAssessment]
            }`}>
            {antiCheatMonitor?.mouseInAssessment}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="flex items-center gap-[8px]">
            <Typography variant="heading-h6" title="Paste Detection" />
            <Icon
              className="cursor-pointer"
              data-tooltip-id="pasteDetection-tooltip"
              data-tooltip-place="top"
              icon={faCircleInfo}
              size={16}
              color="#8C9196"
            />
          </div>
          <div
            className={`flex items-center rounded px-[6px] text-sm font-bold uppercase shadow-sm ${
              styles[antiCheatMonitor?.pasteDetection]
            }`}>
            {antiCheatMonitor?.pasteDetection}
          </div>
        </div>
        {images?.length > 0 ? (
          <ImageCarousel images={images} />
        ) : (
          <div className="flex items-center gap-3 rounded border border-[#FFEBD3] bg-[#FFF5EA] p-3 pl-[18px] shadow-med">
            <Icon icon={faTriangleExclamation} size={20} color="#BB8100" />
            <Typography
              className="m-auto"
              variant="regular-default"
              title="The candidate did not grant the right to use the webcam."
            />
          </div>
        )}
      </div>
    </Section>
  );
};
