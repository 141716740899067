import moment from 'moment';
import { calculateColorHirenestScore } from 'pages/Employer/CreateAssessment_old/components/HiringStatuses/HiringStatuses';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'ui-kit';
import { secondsToHms } from 'utils/helpers';
import StickyBox from 'react-sticky-box';
import { statusColor } from 'components/ApplicationSection';

const tableHeader = [
  { label: 'Candidate', value: 'candidate', showInMini: true },
  { label: 'Score', value: 'score', showInMini: true },
  { label: 'Status', value: 'status', showInMini: false },
  { label: 'Assessment', value: 'assessment', showInMini: false },
  { label: 'Completed On', value: 'completed', showInMini: false },
  { label: 'Time Spent', value: 'timeSpent', showInMini: false },
  { label: 'Email', value: 'email', showInMini: false },
  { label: 'Source', value: 'source', showInMini: false }
];

const TableContainer = ({
  applications,
  navigateToAssessment,
  handleSort,
  handleOpenApplication,
  miniVersion,
  params
}) => {
  const navigate = useNavigate();
  return (
    <table className="min-h-[100px] w-full border border-gray-200 bg-white shadow-med">
      <thead className="sticky top-[75px] bg-[#F6F6F7]">
        <tr>
          <th
            className={`border-b py-3 px-4 ${
              params?.sortBy === 'candidate' && 'bg-[#E2F1EA]'
            }`}></th>
          {tableHeader?.map(
            ({ value, label, showInMini }) =>
              ((miniVersion && showInMini) || !miniVersion) && (
                <th
                  key={value}
                  className={`group cursor-pointer border-b py-3 px-4 text-start ${
                    params?.sortBy === value && 'bg-[#E2F1EA]'
                  }`}
                  onClick={() => handleSort(value)}>
                  <div className="flex gap-2">
                    <div className="text-base font-medium text-[#082623]">{label}</div>
                    {params?.sortBy !== value && (
                      <div className="invisible group-hover:visible" key="chevron-up">
                        <i className="fa-regular fa-chevron-up text-xs text-[#5C5F62]"></i>
                      </div>
                    )}
                    {params?.sortBy === value &&
                      (params?.sortOrder === '1' ? (
                        <div key="chevron-up">
                          <i className="fa-regular fa-chevron-up text-xs text-[#5C5F62]"></i>
                        </div>
                      ) : (
                        <div key="chevron-down">
                          <i className="fa-regular fa-chevron-down text-xs text-[#5C5F62]"></i>
                        </div>
                      ))}
                  </div>
                </th>
              )
          )}
        </tr>
      </thead>
      <tbody>
        {applications?.map(
          ({
            assessment,
            candidate,
            completedAt,
            hirenestScore,
            source,
            status,
            timeSpent,
            _id
          }) => (
            <tr
              key={_id}
              className={`hover:bg-[#FCFCFC] ${_id === params.application && 'bg-[#F6F6F7]'}`}>
              <td className="cursor-pointer border-b p-3">
                <div
                  className="flex h-[27px] w-[27px] items-center justify-center rounded-full bg-[#EDEFEF] text-sm font-medium text-[#6D7175]"
                  onClick={() => handleOpenApplication(_id)}>
                  {candidate?.name[0].toUpperCase()}
                </div>
              </td>
              <td
                className="cursor-pointer border-b p-3 text-[#007D6F]"
                onClick={() => handleOpenApplication(_id)}>
                {candidate?.name}
              </td>
              <td
                className={`min-w-[100px] border-b p-3 text-center ${
                  calculateColorHirenestScore(hirenestScore)?.title
                }`}>
                {hirenestScore === -1 || hirenestScore === null || hirenestScore === undefined
                  ? 'N/A'
                  : hirenestScore.toFixed(0) + '%'}
              </td>
              {!miniVersion && (
                <td className="min-w-[150px] border-b p-3">
                  <div
                    className={`flex max-w-max items-center justify-center rounded px-[6px] py-[3px] text-center text-xs font-bold uppercase tracking-wider text-[#031633] shadow-sm ${statusColor?.[status]?.bg} ${statusColor?.[status]?.text}`}>
                    {statusColor?.[status]?.title}
                  </div>
                </td>
              )}
              {!miniVersion && (
                <td className="cursor-pointer border-b p-3 text-[#007D6F]">
                  <p
                    className="cursor-pointer"
                    onClick={() => navigateToAssessment(assessment?._id)}>
                    {assessment?.name}
                  </p>
                </td>
              )}
              {!miniVersion && (
                <td className="min-w-[180px] border-b p-3">
                  {moment(completedAt).format('MMM D')}
                </td>
              )}
              {!miniVersion && (
                <td className="min-w-[140px] border-b p-3 text-end">{secondsToHms(timeSpent)}</td>
              )}
              {!miniVersion && (
                <td className="border-b p-3 text-[#007D6F]">
                  <a href={`mailto:${candidate?.email}`} target="_blank" rel="noreferrer">
                    {candidate?.email}
                  </a>
                </td>
              )}
              {!miniVersion && <td className="min-w-[110px] border-b p-3">{source}</td>}
            </tr>
          )
        )}
        {applications?.length === 0 && (
          <tr>
            <td colSpan={9}>
              <Typography
                className="text-center text-[#6D7175]"
                variant="regular-default"
                title="No Applications to Display"
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const ApplicationTable = props => (
  <>
    {props.miniVersion ? (
      <StickyBox offsetTop={60} offsetBottom={20}>
        <TableContainer {...props} />
      </StickyBox>
    ) : (
      <TableContainer {...props} />
    )}
  </>
);

export default ApplicationTable;
