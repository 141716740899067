import { useEffect, useMemo, useState } from 'react';
import { Typography, Button, Modal, Form } from 'ui-kit';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import {
  getRecommendedTemplatesRequest,
  updateTests,
  changeTemplateData,
  setCustomJobRole
} from 'store/assessment/actions';
import {
  clearTests,
  getJobRolesRequest,
  getTestByImportanceRequest,
  getTestByImportanceSuccess
} from 'store/tests/actions';
import defaultLogo from 'assets/defaultLogoHirenest.svg';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import JobRoleSelect from '../components/JobRoleSelect';

const testsLimit = 6;

const JobInfoTab = ({
  currentAssessment,
  previewTest,
  testsStateChanged,
  setTestsStateChanged
}) => {
  const dispatch = useDispatch();
  const { loading, jobRoles, recommendedTests } = useSelector(store => store.tests);
  const { withTemplate } = useSelector(store => store.assessments);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [newTemplateToApply, setNewTemplateToApply] = useState(null);
  const [templateTypeChanged, setTemplateTypeChanged] = useState(false);
  const [autoApplyTests, setAutoApplyTests] = useState(false);
  const [showTestsLimit, setShowTestsLimit] = useState(testsLimit);
  const [isUseTemplate, setIsUseTemplate] = useState(withTemplate);

  // get job roles (templates)
  useEffect(() => {
    dispatch(getJobRolesRequest());
  }, []);

  // get tests by selected template id
  useEffect(() => {
    if (currentAssessment?.template) {
      setShowTestsLimit(testsLimit);
      dispatch(
        getTestByImportanceRequest({ id: currentAssessment.template, params: { perPage: -1 } })
      );
    }
  }, [currentAssessment?.template]);

  // auto apply tests from template
  useEffect(() => {
    // if tests were changed manually - avoid auto applying without confirmation changes
    // also avoid auto applying tests if user changed template but kept existing tests in the array
    if (!autoApplyTests) return;

    let time = 0;
    const tests = [];
    for (const data of recommendedTests) {
      if (time + data.timeLimit > 5400000) break;
      tests.push(data);
      time += data.timeLimit;
    }

    dispatch(clearTests());
    dispatch(updateTests(tests));
  }, [recommendedTests]);

  // switch assessment type (with template or blank template)
  const toggleTemplateType = () => {
    // if user changed tests manually - ask him if he wants to change the type
    if (testsStateChanged) {
      // save that user wanted to change template type if he apply changes by confirmation modal
      setTemplateTypeChanged(true);
      setConfirmationModalOpen(true);
    } else {
      // user haven't done any changes so switch type in background
      setIsUseTemplate(!isUseTemplate);
      // if currently assessment uses template (so it will be switched to the blank during the next tick)
      if (isUseTemplate) {
        // this action clears template and tests from assessment
        dispatch(changeTemplateData());
        dispatch(clearTests());
      } else {
        if (currentAssessment.jobRole && !currentAssessment.template) {
          setAutoApplyTests(true);
          dispatch(getRecommendedTemplatesRequest(currentAssessment.jobRole));
        }
      }
    }
  };

  // action on change template event according to the new selected item in dropdown
  const changeTemplate = (value, isNew) => {
    // if user previously changes some tests manually - ask him if he wants to replace tests
    if (testsStateChanged) {
      // save selected template to the temporary state to apply it if user accepts this action by modal
      setNewTemplateToApply({ value, isNew });
      setConfirmationModalOpen(true);
      return;
    }

    // if no restrictions (or user has already applied replacing type or template) - allow auto applying tests
    setAutoApplyTests(true);
    setIsUseTemplate(true);

    // if there is no such job role from BE - clear template data from assessment and set up custom job role
    // otherwise start a process of getting information about template
    if (isNew) {
      dispatch(getTestByImportanceSuccess({ tests: [] }));
      dispatch(setCustomJobRole({ value, replaceTests: true }));
    } else dispatch(getRecommendedTemplatesRequest(value));
  };

  // if user chose that he wants to replace type / template
  const applyChanges = () => {
    // allow auto applying tests for the futher actions (or if the new template was chosen)
    setAutoApplyTests(true);

    // if user changed template to the new one
    if (newTemplateToApply) {
      // clear the temporary data and set type to "with template"
      setNewTemplateToApply(null);
      setIsUseTemplate(true);
      // if user selected unknown template - clear template data from assessment and set custom job role
      // otherwise start a process of getting information about selected template
      if (newTemplateToApply.isNew) {
        dispatch(getTestByImportanceSuccess({ tests: [] }));
        dispatch(
          setCustomJobRole({
            value: newTemplateToApply.value,
            replaceTests: false
          })
        );
      } else dispatch(getRecommendedTemplatesRequest(newTemplateToApply.value));
    } else if (templateTypeChanged) {
      // user selected to change a type before confirmation window loaded, so switch the type
      setTemplateTypeChanged(false);
      setIsUseTemplate(!isUseTemplate);
      // if currently assessment uses template (so it will be switched to the blank during the next tick)
      if (isUseTemplate) {
        // this action clears template and tests from assessment
        dispatch(changeTemplateData());
        dispatch(clearTests());
      } else {
        if (currentAssessment.jobRole && !currentAssessment.template) {
          setAutoApplyTests(true);
          dispatch(getRecommendedTemplatesRequest(currentAssessment.jobRole));
        }
      }
    }

    // notify the main component that currently tests array is clear of manually changes by user
    setTestsStateChanged(false);
    setConfirmationModalOpen(false);
  };

  const cancelChanges = () => {
    setConfirmationModalOpen(false);
    setNewTemplateToApply(null);
  };

  // apply new template without affecting existing tests
  // this action doesn't change the flag about clear tests array, cause using the new template or blank template
  // also have to react that user has already done some manually actions
  const applyTemplateWithoutTest = () => {
    setConfirmationModalOpen(false);

    // if user selected a new template previously - clear the data and start get new information about template
    // but without auto applying tests
    if (newTemplateToApply) {
      setNewTemplateToApply(null);
      setIsUseTemplate(true);
      if (newTemplateToApply.isNew) dispatch(setCustomJobRole(newTemplateToApply.value));
      else dispatch(getRecommendedTemplatesRequest(newTemplateToApply.value));
    }
  };

  // set of text for different confirmation modals accoridng to each corner case
  const modalTexts = useMemo(() => {
    // if user wants to change a template and currently template is blank, but there are existing tests in array to confirm changes
    if (templateTypeChanged && !isUseTemplate)
      return {
        title: 'Use Template',
        content: 'Are you sure want to use template? Your changes will be overwritten.',
        applyButton: 'Use Template'
      };
    // if user wants to change a template and currently there is selected template
    else if (templateTypeChanged && isUseTemplate)
      return {
        title: 'Start from the scratch',
        content: 'Are you sure want start from the scratch?',
        applyButton: 'Start from scratch'
      };
    // in any other cases (user didn't want to change a template type and wants to switch between different job roles)
    else
      return {
        title: 'Changing Job Role and Template',
        content: 'After applying the new template, the previous settings will be reset to default',
        applyButton: 'Use new Template'
      };
  }, [templateTypeChanged, isUseTemplate]);

  return (
    <div className="flex gap-[48px] rounded-md bg-[#FFF] p-6 shadow-[0_1px_8px_0_rgba(68,68,68,0.12)]">
      {loading && <Loader />}
      <div className="w-[50%]">
        <Typography title="Job Role" variant="heading-h5" className="mb-[6px] text-[18px]" />

        <Form
          mode="onChange"
          defaultValues={{
            jobRoles: currentAssessment?.jobRole
              ? { label: currentAssessment?.jobRole, value: currentAssessment?.jobRole }
              : null
          }}>
          {() => (
            <JobRoleSelect
              name="jobRoles"
              onChangeValue={({ value, __isNew__ }) => changeTemplate(value, __isNew__)}
              options={jobRoles?.jobTitles?.map(value => {
                return { label: value, value };
              })}
            />
          )}
        </Form>

        <Typography
          title="How do you want to build Assessment?"
          className="mt-[24px] mb-[6px] text-[16px]"
        />
        <div className="flex gap-[24px]">
          <div
            className={`w-[50%] cursor-pointer rounded-md border border-[#D2D5D8] p-4 ${
              isUseTemplate ? 'border-[#95C9B4] bg-[#EBF7F1]' : 'hover:bg-[#F3F8F6]'
            }`}
            onClick={toggleTemplateType}>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM8 14.8571C6.64379 14.8571 5.31803 14.455 4.19038 13.7015C3.06273 12.948 2.18383 11.8771 1.66483 10.6241C1.14583 9.37113 1.01003 7.99239 1.27462 6.66224C1.5392 5.33208 2.19228 4.11026 3.15127 3.15127C4.11026 2.19228 5.33209 1.5392 6.66224 1.27461C7.9924 1.01003 9.37114 1.14582 10.6241 1.66483C11.8771 2.18383 12.948 3.06272 13.7015 4.19037C14.455 5.31803 14.8571 6.64378 14.8571 8C14.8571 9.81863 14.1347 11.5628 12.8487 12.8487C11.5628 14.1347 9.81863 14.8571 8 14.8571Z"
                  fill={`${isUseTemplate ? '#007D6F' : '#8C9196'}`}
                />
                {isUseTemplate && <circle cx="8" cy="8" r="5" fill="#007D6F" />}
              </svg>
              <Typography title="Use Assessment Template" className="ml-[6px]" />
            </div>
            <Typography
              title="Recommended a set of tests related to job role"
              className="text-[14px] text-[#6D7175]"
            />
          </div>

          <div
            className={`w-[50%] cursor-pointer rounded-md border border-[#D2D5D8] p-4 ${
              !isUseTemplate ? 'border-[#95C9B4] bg-[#EBF7F1]' : 'hover:bg-[#F3F8F6]'
            }`}
            onClick={toggleTemplateType}>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM8 14.8571C6.64379 14.8571 5.31803 14.455 4.19038 13.7015C3.06273 12.948 2.18383 11.8771 1.66483 10.6241C1.14583 9.37113 1.01003 7.99239 1.27462 6.66224C1.5392 5.33208 2.19228 4.11026 3.15127 3.15127C4.11026 2.19228 5.33209 1.5392 6.66224 1.27461C7.9924 1.01003 9.37114 1.14582 10.6241 1.66483C11.8771 2.18383 12.948 3.06272 13.7015 4.19037C14.455 5.31803 14.8571 6.64378 14.8571 8C14.8571 9.81863 14.1347 11.5628 12.8487 12.8487C11.5628 14.1347 9.81863 14.8571 8 14.8571Z"
                  fill={`${!isUseTemplate ? '#007D6F' : '#8C9196'}`}
                />
                {!isUseTemplate && <circle cx="8" cy="8" r="5" fill="#007D6F" />}
              </svg>
              <Typography title="Building your own" className="ml-[6px]" />
            </div>
            <Typography
              title="Start creation without using the template"
              className="text-[14px] text-[#6D7175]"
            />
          </div>
        </div>
      </div>

      <div className="w-[50%]">
        {isUseTemplate && (
          <>
            <Typography title="Preview Template" variant="heading-h5" className="text-[20px]" />
            {!currentAssessment.jobRole && (
              <Typography
                title="Enter the Job Role so that we can help you find the right Tests."
                className="text-[16px] text-[#6D7175]"
              />
            )}
            {currentAssessment.jobRole && !currentAssessment.template && (
              <Typography
                title={`Unfortunately, we don’t have a template for ${currentAssessment.jobRole}.`}
                className="text-[16px] text-[#6D7175]"
              />
            )}
            {currentAssessment.template && (
              <>
                <Typography
                  title={`This displays suggested tests for ${currentAssessment.jobRole}.`}
                  className="text-[16px] text-[#6D7175]"
                />
                <Typography
                  title="You will be able to choose the content you need at the next stages of creating an assessment."
                  className="text-[16px] text-[#6D7175]"
                />
              </>
            )}
            <div className="bt-[12px] border-b-[1px] border-[#D2D5D8]" />
            {recommendedTests && recommendedTests.length !== 0 && currentAssessment.template && (
              <div className="my-[12px] text-[18px] text-[#082623]">
                {recommendedTests.length} Test{recommendedTests.length > 1 ? 's' : ''}
              </div>
            )}
            {currentAssessment.template && (
              <div className="flex w-full flex-wrap gap-[18px]">
                {recommendedTests.slice(0, showTestsLimit).map(({ _id, name, icon }) => (
                  <div
                    key={`info_tab_tests_${_id}`}
                    onClick={() => previewTest && previewTest(_id)}
                    className="flex w-[calc(50%-9px)] cursor-pointer items-center rounded-sm border border-[#D2D5D8]
                    bg-[#F6F6F7] py-[6px] pl-[6px] pr-[12px] shadow-[0_1px_4px_0_rgba(68,68,68,0.08)]
                    hover:border-[#999EA4] hover:shadow-[0_1px_6px_0_rgba(68,68,68,0.1)]">
                    <img className="h-[25px] w-[25px]" src={icon?.url || defaultLogo} />
                    <div className="ml-[6px]">{name}</div>
                  </div>
                ))}
              </div>
            )}
            {currentAssessment.template && showTestsLimit < recommendedTests.length && (
              <div className="mt-[18px] flex w-full justify-center">
                <Button
                  onClick={() => setShowTestsLimit(recommendedTests.length)}
                  variant="primary-outline"
                  title="Show All"
                />
              </div>
            )}
          </>
        )}
      </div>

      {/*Confirmation modal*/}
      <Modal isOpen={confirmationModalOpen} className="m-auto w-full min-w-[600px] max-w-max">
        <Modal.Header className="px-6">
          <Typography variant="heading-h4" title={modalTexts.title} />
          <Button
            icon={faXmark}
            iconSize={20}
            iconColor="#5C5F62"
            onClick={() => {
              setConfirmationModalOpen(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="flex min-h-[100px] items-center px-6">
          <Typography variant="regular-default" title={modalTexts.content} />
        </Modal.Body>
        <Modal.Footer className="flex justify-end gap-4">
          <div className="flex justify-end gap-4">
            <Button variant="primary-outline" title="Cancel" onClick={cancelChanges} />
            {newTemplateToApply && (
              <Button
                variant="primary-outline"
                title="Keep existing Tests"
                onClick={applyTemplateWithoutTest}
              />
            )}
            <Button variant="primary" title={modalTexts.applyButton} onClick={applyChanges} />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JobInfoTab;
