import { PaymentElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';
import { setUpgradeModalOpen } from 'store/auth/actions';

export default function CheckoutForm({ setIsLoading, closeModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: `${process.env.REACT_APP_HOST_URL}/subscription`
      }
    });

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        navigate(routes.SUBSCRIPTIONS + routes.FAILED);
      } else {
        navigate(routes.SUBSCRIPTIONS + routes.FAILED);
      }
    }
    dispatch(setUpgradeModalOpen(false));
    setIsLoading(false);
    closeModal(true);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
    </form>
  );
}
