import { combineReducers } from 'redux';
import { authentication } from '../store/auth/reducer';
import { deviceReducer } from 'store/auth/reducer/devices';
import { assessments } from '../store/assessment/reducer/';
import { tests } from '../store/tests/reducer/';
import { candidates } from '../store/candidates/reducer/';
import { admin } from '../store/admin/reducer/';
import { subscriptions } from './configuration/reducer/subscriptions';

export const rootReducer = combineReducers({
  auth: authentication,
  devices: deviceReducer,
  assessments,
  tests,
  candidates,
  admin,
  subscriptions
});
