import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'ui-kit';
import * as routes from 'routes/routes';

export const FailedSubscription = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-6 px-[30px] py-[36px]">
      <Typography variant="heading-h1" title="Manage Subscription" />
      <div className="m-auto flex max-w-[390px] flex-col items-center justify-center gap-3 rounded-md bg-white p-6 text-center shadow-med">
        <Typography variant="heading-h2" title="Payment Failed" />
        <Typography variant="regular-default">
          Please reach out to the team at{' '}
          <a className="text-[#007D6F] underline" href="mailto:support@hirenest.com">
            support@hirenest.com
          </a>{' '}
          if the error continues.
        </Typography>
        <Button
          onClick={() => navigate(routes.SUBSCRIPTIONS)}
          variant="primary"
          title="Continue"
          className="mt-3 w-full"
        />
      </div>
    </div>
  );
};
