import { useCallback, useRef, useState } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Button, Typography, Modal } from 'ui-kit';

import getCroppedImg from 'utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { uploadAttachmentRequest } from 'store/tests/actions';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

const ImageModalCropper = ({
  isOpen,
  close,
  save,
  title,
  imgSrc,
  fileType,
  fileName,
  modalClassName,
  options = {}
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(store => store.auth);
  const { aspect, minWidth, minHeight, fullSize } = options;

  const defaults =
    minWidth && minHeight
      ? {
          unit: 'px',
          x: 0,
          y: 0,
          minWidth: minWidth || 100,
          minHeight: minHeight || 100
        }
      : null;

  const [crop, setCrop] = useState(defaults);
  const imgRef = useRef(null);

  const onSave = useCallback(async () => {
    const croppedImage = await getCroppedImg(fileName, user._id, imgRef.current, crop, fileType);
    try {
      const formData = new FormData();
      formData.append('attachment', croppedImage);
      dispatch(
        uploadAttachmentRequest({
          formData,
          onSuccess: data => {
            save?.(data.attachmentUrl.url);
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  }, [imgRef, crop]);

  function centerAspectCrop(mediaWidth, mediaHeight) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 99
        },
        mediaWidth / mediaHeight,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e) {
    if (fullSize) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height));
    }
  }

  return (
    <Modal isOpen={isOpen} className={`m-auto ${modalClassName ?? 'sm:w-[100%] lg:w-[75%]'}`}>
      <>
        <Modal.Header>
          <Typography title={title ?? 'Crop Image'} variant="heading-h4" />
          <Button icon={faXmark} iconSize={20} iconColor="#5C5F62" onClick={() => close()} />
        </Modal.Header>
        <Modal.Body className="flex-1 p-6">
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(pixelCrop)}
            onComplete={(pixelCrop, percentCrop) => setCrop(pixelCrop)}
            className="w-full"
            aspect={aspect || undefined}
            minWidth={minWidth || '100%'}
            minHeight={minHeight || '100%'}>
            <img
              ref={imgRef}
              className="w-full object-contain"
              alt="Crop me"
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Modal.Body>
        <Modal.Footer className="flex h-[68px] items-center justify-between px-6">
          <div className="flex w-full justify-end">
            <Button
              title="Cancel"
              variant="secondary"
              className="h-[44px]"
              onClick={() => close()}
            />
            <Button onClick={onSave} className="ml-3 h-[44px]" title="Done" variant="primary" />
          </div>
        </Modal.Footer>
      </>
    </Modal>
  );
};

export default ImageModalCropper;
