export const StarsRating = ({ rate, setRate }) => (
  <div className="flex cursor-pointer space-x-1">
    {[...Array(5)].map((_, index) => {
      const ratingFloor = Math.floor(rate);
      const ratingRound = Math.round(rate * 2) / 2;

      let starColor = '#E2E2E2';
      if (index < ratingFloor) {
        starColor = '#FFC96B';
      } else if (index < ratingRound) {
        starColor = 'url(#half-grad)';
      }

      return (
        <svg
          key={index}
          onClick={() => {
            setRate(prev => {
              return {
                ...prev,
                rating: index + 1
              };
            });
          }}
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
          height="20px"
          viewBox="0 0 32 32">
          <defs>
            <linearGradient id="grad">
              <stop offset="50%" stopColor="#FFC96B" />
              <stop offset="50%" stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient id="half-grad">
              <stop offset="50%" stopColor="#FFC96B" />
              <stop offset="50%" stopColor="#E2E2E2" />
              <stop offset="50%" stopColor="#E2E2E2" />
            </linearGradient>
          </defs>
          <path
            fill={starColor}
            d="M20.388,10.918L32,12.118l-8.735,7.749L25.914,31.4l-9.893-6.088L6.127,31.4l2.695-11.533L0,12.118 l11.547-1.2L16.026,0.6L20.388,10.918z"
          />
        </svg>
      );
    })}
  </div>
);
