import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from 'ui-kit';
import ErrorInfo from 'components/ErrorInfo';

const DropZonePickerAvatar = ({
  handleChange,
  onDelete,
  setFilesUploaded,
  className,
  icon,
  showError,
  setShowError,
  error,
  setError
}) => {
  const dropConfig = () => {
    return {
      text: 'PNG or Jpeg file (to 5MB)',
      acceptTypes: '.jpeg,.jpg,.png,'
    };
  };

  const { text, acceptTypes } = dropConfig();

  const onDrop = useCallback(filesAccepted => {
    if (filesAccepted.length) {
      handleChange?.(filesAccepted[0]);
    }
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } = useDropzone({
    onDrop,
    noClick: false,
    noKeyboard: true,
    multiple: false,
    maxFiles: 1,
    accept: acceptTypes,
    maxSize: 5242880
  });

  const handleDelete = e => {
    setFilesUploaded(undefined);
    e.preventDefault();
    e.stopPropagation();
    onDelete?.();
    acceptedFiles.pop();
    handleChange && handleChange(undefined);
  };

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      <span className="mb-2 flex items-center justify-between rounded-xl border bg-red-200 p-2.5 transition-all">
        {file.path} - {file?.size} bytes
      </span>
      <ul>
        {errors.map(e => (
          <li key={e.code} className="text-red-600">
            {e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  return (
    <>
      <section
        onClick={() => open()}
        {...getRootProps({ className: 'dropzone' })}
        className={`dropzone flex h-[180px] w-full cursor-default items-center justify-center rounded-xl border border-dashed border-gray-400 ${className}`}>
        <div className="flex flex-col items-center">
          <input {...getInputProps()} />
          {icon && <Icon icon={`${icon}`} className="m-3" size={18} color="#CBD5E0" />}
          <p>Upload photo</p>
          <p className="mb-3.5 text-center text-base text-gray-400">{text}</p>
        </div>
      </section>
      {fileRejectionItems.length !== 0 && (
        <div className="mt-2.5">
          <h4 className="mb-2.5 text-base font-semibold text-red-600">Errors:</h4>
          <ul>{fileRejectionItems}</ul>
        </div>
      )}
      {showError && <ErrorInfo error={error} setError={setError} setShowError={setShowError} />}
    </>
  );
};

export default DropZonePickerAvatar;
