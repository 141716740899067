import Loader from 'components/Loader';
import { useUserRole } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDashboardStatRequest } from 'store/admin/actions';
import { navigateUser } from 'store/auth/sagas';
import { List, Section, Typography } from 'ui-kit';

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserRole();

  const { loading, dashboard } = useSelector(store => store.admin);

  const { activityData, libraryData, usersData } = dashboard;

  useEffect(() => {
    if (role) {
      navigateUser(role, { navigate });
    }
  }, [role]);

  useEffect(() => {
    if (role !== 'admin') {
      return;
    }
    dispatch(getDashboardStatRequest());
  }, [role]);
  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      <Typography title="Dashboard" variant="heading-h1" className="mb-6" />
      <div className="space-y-6">
        <Section className="flex-1">
          <Section.Header>
            <Typography title="Users" variant="heading-h2" />
          </Section.Header>
          <Section.Body>
            <List
              data={usersData}
              element={props => {
                return (
                  <li className="space-y-1 rounded-md border border-gray-400 p-6">
                    <Typography
                      title={`${props.count} ${props.name}`}
                      variant="heading-h3"
                      className="capitalize"
                    />
                    <Typography
                      title={`${props.differense > 0 ? '+' : ''}${props.differense} in 24 hours`}
                      variant="regular-default"
                    />
                  </li>
                );
              }}
              keyExtractor={(_, idx) => idx}
              wrap="ul"
              className="grid grid-cols-4 gap-6"
            />
          </Section.Body>
        </Section>
        <div className="flex space-x-6">
          <Section className="flex-1">
            <Section.Header>
              <Typography title="Library" variant="heading-h2" />
            </Section.Header>
            <Section.Body>
              <List
                data={libraryData}
                element={props => {
                  return (
                    <li className="space-y-1 rounded-md border border-gray-400 p-6">
                      <Typography
                        title={`${props.count} ${props.name}`}
                        variant="heading-h3"
                        className="capitalize"
                      />
                      <Typography
                        title={`${props.differense > 0 ? '+' : ''}${props.differense} in 24 hours`}
                        variant="regular-default"
                      />
                    </li>
                  );
                }}
                keyExtractor={(_, idx) => idx}
                wrap="ul"
                className="grid grid-cols-2 gap-6"
              />
            </Section.Body>
          </Section>
          <Section className="flex-1">
            <Section.Header>
              <Typography title="Activity" variant="heading-h2" />
            </Section.Header>
            <Section.Body>
              <List
                data={activityData}
                element={props => {
                  return (
                    <li className="space-y-1 rounded-md border border-gray-400 p-6">
                      <Typography
                        title={`${props.count} ${props.name}`}
                        variant="heading-h3"
                        className="capitalize"
                      />
                      <Typography
                        title={`${props.differense > 0 ? '+' : ''}${props.differense} in 24 hours`}
                        variant="regular-default"
                      />
                    </li>
                  );
                }}
                keyExtractor={(_, idx) => idx}
                wrap="ul"
                className="grid grid-cols-2 gap-6"
              />
            </Section.Body>
          </Section>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
