import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Form, Section, Typography, Tab } from 'ui-kit';
import { SectionList } from 'components/SectionList';
import * as routes from 'routes/routes';

import Loader from 'components/Loader';
import TimeBadget from 'components/TimeBadget';
import Breadcrumbs from 'components/Breadcrumbs';
import { TestViewSummary } from 'components/TestViewSummary/TestViewSummary';
import { TestViewResults } from 'components/TestViewResults/TestViewResults';
import { TestViewSettings } from 'components/TestViewSettings/TestViewSettings';
import { TestViewWebsite } from 'components/TestViewWebsite/TestViewWebsite';
import { EvaluateModal } from 'components/EvaluateModal/EvaluateModal';

import { getCurrentTestRequest, publishTestRequest } from 'store/tests/actions';
import { clearEvaluateQuestions, getEvaluateQuestionsRequest } from 'store/assessment/actions';
import { IconTest } from 'components/IconTest/IconTest';
import { CoverTest } from 'components/CoverTest/CoverTest';

export const PreviewTest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id } = useParams();

  const [isEvaluateModalOpen, setIsEvaluateModalOpen] = useState(false);
  const [isVersionTextOpen, setIsVersionTextOpen] = useState(false);
  const { loading } = useSelector(store => store.tests);
  const { evaluateQuestions } = useSelector(store => store.assessments);

  useEffect(() => {
    dispatch(getCurrentTestRequest(_id));
  }, [_id]);

  useEffect(() => {
    if (isEvaluateModalOpen) {
      dispatch(getEvaluateQuestionsRequest(_id));
    } else {
      dispatch(clearEvaluateQuestions());
    }
  }, [isEvaluateModalOpen, _id]);

  const { currentTest } = useSelector(s => s.tests);

  const onSubmit = ({ releaseNotes }) => {
    const data = {
      test: {
        status: 'published',
        releaseNotes: ''
      }
    };

    dispatch(publishTestRequest({ data, id: _id, navigate }));
  };

  return (
    <>
      {loading && <Loader />}
      <div className="bg-[#FFFFFF]">
        <CoverTest size={330} url={currentTest?.cover?.url} />
        <div className="py-8 px-7.5">
          <Breadcrumbs type="publishTest" />

          <div className="my-3 flex justify-between">
            <div className="flex w-full">
              <div className="grow">
                <div className="flex">
                  <div className="mr-3 h-[72px] w-[72px] flex-none">
                    <IconTest url={currentTest?.icon?.url} size={72} />
                  </div>
                  <div className="flex items-center gap-3">
                    <Typography variant="heading-h1" title={currentTest.name} />
                    <TimeBadget className="font-semibold" time={currentTest.timeLimit} />
                  </div>
                </div>
                <div>{currentTest.tagline}</div>
              </div>
              <div className="ml-3 w-[570px] rounded-md bg-[#F3F8F6] p-6">
                <div className="flex items-center gap-2">
                  <div className="text-[22px] font-semibold text-[#082623]">
                    Ready for publication!
                  </div>
                  <Button
                    rounded={false}
                    title="Back to Edit"
                    className="ml-auto text-base font-semibold text-[#007D6F]"
                    onClick={() => navigate(routes.TESTS + `/${_id}` + routes.EDIT)}
                  />
                  <Form
                    onSubmit={onSubmit}
                    defaultValues={{ releaseNotes: '' }}
                    className="ml-6 space-y-3">
                    <Button type="submit" variant="primary" title="Publish Test" />
                  </Form>
                </div>
                <div>
                  <p
                    className="cursor-pointer break-words capitalize text-green-400 underline"
                    onClick={() => setIsVersionTextOpen(!isVersionTextOpen)}>
                    {!isVersionTextOpen ? 'How does versioning works?' : 'Hide'}
                  </p>
                  {isVersionTextOpen && (
                    <div className="mt-1.5 text-base text-[#082623]">
                      <p>
                        Once you publish this version of the test, it will be available in the Test
                        Library and you will no longer be able to edit it. All further changes
                        should be done in a new version.
                      </p>
                      <br />
                      <p>
                        The previously published version will be archived, but it will still be
                        available to Employers in Assessments where they have used it before.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-8 px-7.5">
        <Tab.Group as="div" className="pt-7">
          <Tab.List className="mb-6 flex gap-6">
            <Tab>
              <Typography variant="heading-h4" title="Summary" />
            </Tab>
            <Tab>
              <Typography
                variant="heading-h4"
                title={`Questions (${currentTest.questionsCount})`}
              />
            </Tab>
            <Tab>
              <Typography variant="heading-h4" title="Results" />
            </Tab>
            <Tab>
              <Typography variant="heading-h4" title="Settings" />
            </Tab>
            <Tab>
              <Typography variant="heading-h4" title="Website" />
            </Tab>
            <div className="flex flex-1 justify-end">
              <Button
                className="flex max-h-[33px] max-w-max items-center justify-center px-[12px] py-[6px] text-sm font-medium"
                variant="secondary"
                title="Evaluate Questions"
                onClick={() => setIsEvaluateModalOpen(true)}
              />
              {evaluateQuestions?.length > 0 && (
                <EvaluateModal
                  testName={currentTest?.name}
                  testIcon={currentTest?.icon?.url}
                  isOpen={isEvaluateModalOpen}
                  setIsOpen={() => setIsEvaluateModalOpen(false)}
                  questions={evaluateQuestions}
                />
              )}
            </div>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="flex gap-6">
              <TestViewSummary currentTest={currentTest && currentTest} role="author" />
            </Tab.Panel>
            <Tab.Panel>
              <Section>
                <Section.Body>
                  <SectionList
                    hidden={currentTest.sections?.length === 1}
                    sections={currentTest.sections}
                  />
                </Section.Body>
              </Section>
            </Tab.Panel>
            <Tab.Panel>
              <TestViewResults currentTest={currentTest} />
            </Tab.Panel>
            <Tab.Panel>
              <TestViewSettings currentTest={currentTest} />
            </Tab.Panel>
            <Tab.Panel>
              <TestViewWebsite currentTest={currentTest} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};
