import { Form, Typography, Input, Textarea, ErrorMessage, Radio, List } from 'ui-kit';

import { languageConfig, testDifficultyConfig } from 'config/selectConfig';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { SaveNewTest } from '../validation';
import { useState } from 'react';
import ImageUploader from '../../ImageUploader';
import ImageModalCropper from '../../ImageModalCropper';
import SelectTree from 'ui-kit/SelectTree/SelectTree';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';

const SettingsTestForm = ({ defaultValues, onSubmit, testCategories }) => {
  const { areasOfUse, suitableFor, displaySectionAs } = useSelector(store => store.tests);

  const [iconSrc, setIconSrc] = useState(null);
  const [iconData, setIconData] = useState({ fileName: null, fileType: null });
  const [iconCropModal, setIconCropModal] = useState(false);
  const [coverSrc, setCoverSrc] = useState(null);
  const [coverData, setCoverData] = useState({ fileName: null, fileType: null });
  const [coverCropModal, setCoverCropModal] = useState(false);

  function onSelectFile(file, fileStateMethod, dataStateMethod, openModalMethod) {
    if (file) {
      dataStateMethod({
        fileName: file.name,
        fileType: file.type
      });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        fileStateMethod(reader.result.toString() || '');
        openModalMethod(true);
      });
      reader.readAsDataURL(file);
    }
  }

  const closeModal = (closeModalMethod, clearImageMethod) => () => {
    closeModalMethod(false);
    clearImageMethod(null);
  };

  const clearImage = clearImageMethod => () => {
    clearImageMethod(null);
  };

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={onSubmit}
      onBlur
      mode="onChange"
      resolver={yupResolver(SaveNewTest)}
      isReset={true}
      className="flex flex-col gap-5 xl:flex-row xl:space-x-5">
      {({ handleSubmit, watch, setValue }) => (
        <>
          <div className="flex-2 space-y-1">
            <div className="flex">
              <div className="flex-1">
                <ImageUploader
                  iconClass="fa-solid fa-cloud-arrow-up text-[32px] mb-3"
                  customClass="h-[144px] w-[144px] bg-[#F6F6F7] text-[14px] text-[#BABEC3]
                    items-center cursor-pointer
                    hover:border-[#12A182] hover:bg-[#F3F8F6] hover:text-[#12A182]"
                  text="Upload Icon"
                  types="image/png"
                  title="Icon"
                  handleChange={file =>
                    onSelectFile(file, setIconSrc, setIconData, setIconCropModal)
                  }
                  handleClear={() => {
                    setValue('icon.url', null);
                    handleSubmit(onSubmit({ icon: { url: null } }));
                  }}
                  imageSrc={!iconCropModal ? watch('icon.url') : null}
                  useConfirmModal={true}
                  deleteImageConfirmText="delete Icon?"
                  deleteHeaderConfirmText="Delete Icon"
                />
                {iconCropModal && (
                  <ImageModalCropper
                    modalClassName="max-w-[568px]"
                    title="Test Icon"
                    isOpen={iconCropModal}
                    close={closeModal(setIconCropModal, setIconSrc)}
                    save={file => {
                      setValue('icon.url', file);
                      handleSubmit(onSubmit({ icon: { url: file } }));
                      setIconCropModal(false);
                    }}
                    imgSrc={iconSrc}
                    fileType={iconData.fileType}
                    fileName={iconData.fileName}
                    options={{
                      aspect: 1,
                      minWidth: 200,
                      minHeight: 200
                    }}
                  />
                )}
              </div>
              <div className="flex-[2_2_45%]">
                <div>
                  <Typography title="Name" variant="small-default" />
                  <Input autoComplete="off" name="name" />
                  <ErrorMessage name="name" />
                </div>
                <div>
                  <Typography title="Tagline" variant="small-default" />
                  <Textarea
                    placeholder="Short description of a test for Test Card"
                    height="min-h-[132px] max-h-[132px]"
                    name="tagline"
                    maxLength={200}
                  />
                  <ErrorMessage name="tagline" />
                </div>
              </div>
            </div>
            <div>
              <ImageUploader
                iconClass="fa-solid fa-cloud-arrow-up text-[32px] mb-3"
                customClass="bg-[#F6F6F7] text-[14px] text-[#BABEC3]
                    items-center cursor-pointer
                    hover:border-[#12A182] hover:bg-[#F3F8F6] hover:text-[#12A182]"
                text="Upload Cover"
                title="Cover"
                handleChange={file =>
                  onSelectFile(file, setCoverSrc, setCoverData, setCoverCropModal)
                }
                handleClear={() => {
                  setValue('cover.url', null);
                  handleSubmit(onSubmit({ cover: { url: null } }));
                }}
                imageSrc={!coverCropModal ? watch('cover.url') : null}
                useConfirmModal={true}
                deleteImageConfirmText="delete Cover?"
                deleteHeaderConfirmText="Delete Cover"
              />
              {coverCropModal && (
                <ImageModalCropper
                  modalClassName="w-full"
                  title="Test Cover"
                  isOpen={coverCropModal}
                  close={closeModal(setCoverCropModal, setCoverSrc)}
                  save={file => {
                    setValue('cover.url', file);
                    handleSubmit(onSubmit({ cover: { url: file } }));
                    setCoverCropModal(false);
                  }}
                  imgSrc={coverSrc}
                  fileType={coverData.fileType}
                  fileName={coverData.fileName}
                  options={{
                    aspect: 4,
                    minWidth: 1320,
                    minHeight: 330
                  }}
                />
              )}
            </div>
            <div>
              <Typography title="Summary" variant="small-default" />
              <RichTextEditor name="summary" showLength maxLength={5000} />
              <ErrorMessage name="summary" />
            </div>
            <div>
              <Typography title="Outcomes" variant="small-default" />
              <RichTextEditor name="outcomes" showLength maxLength={5000} />
              <ErrorMessage name="outcomes" />
            </div>
            <div>
              <Typography title="Why Measure..." variant="small-default" />
              <Input className="mb-3" maxLength={100} name="whyMeasure.title" />
              <RichTextEditor name="whyMeasure.text" showLength maxLength={5000} />
              <ErrorMessage name="whyMeasure.text" />
            </div>
            <div>
              <Typography title="General Test Instructions" variant="small-default" />
              <RichTextEditor name="instructions" showLength maxLength={5000} />
              <ErrorMessage name="instructions" />
            </div>
          </div>
          <div className="flex-1">
            <div>
              <Typography title="Category" variant="small-default" />
              <SelectTree
                onChange={data => handleSubmit(onSubmit({ category: data.value }))}
                name="category"
                options={testCategories && testCategories}
              />
              <ErrorMessage name="category" />
            </div>
            <div>
              <Typography variant="small-default" title="Test difficulty" />
              <List
                data={testDifficultyConfig}
                element={({ value }) => (
                  <Radio
                    value={value}
                    onChangeValue={handleSubmit(onSubmit)}
                    name="difficulty"
                    placeholder={
                      <Typography
                        variant="small-default"
                        title={`${value[0].toUpperCase() + value.slice(1)}`}
                      />
                    }
                  />
                )}
                keyExtractor={(_, idx) => idx}
                wrap="div"
                className="flex gap-4"
              />
              <ErrorMessage name="difficulty" />
            </div>
            <div>
              <Typography title="Language" variant="small-default" />
              <SelectCustom
                name="language"
                isSearchable={false}
                isClearable={false}
                onChangeValue={handleSubmit(onSubmit)}
                options={languageConfig}
              />
              <ErrorMessage name="language" />
            </div>
            <div>
              <Typography variant="small-default" title="Areas of Use" />
              <SelectCustom
                name="areasOfUse"
                onChangeValue={handleSubmit(onSubmit)}
                creatable
                isMulti
                options={areasOfUse}
              />
              <ErrorMessage name="areasOfUse" />
            </div>
            <div>
              <Typography title="Suitable for" variant="small-default" />
              <SelectCustom
                name="suitableFor"
                onChangeValue={handleSubmit(onSubmit)}
                creatable
                isMulti
                options={suitableFor}
              />
              <ErrorMessage name="suitableFor" />
            </div>
            <div>
              <Typography title="Display Sections & Tags As" variant="small-default" />
              <SelectCustom
                name="displaySectionAs"
                onChangeValue={handleSubmit(onSubmit)}
                defaultValue={displaySectionAs[0]}
                options={displaySectionAs}
              />
              <ErrorMessage name="displaySectionAs" />
            </div>
          </div>
        </>
      )}
    </Form>
  );
};

export default SettingsTestForm;
