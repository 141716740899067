import React from 'react';
import { Typography } from 'ui-kit';

import cn from 'utils/classNames';

interface SectionWrapperProps {
  title?: string;
  children?: any;
  className?: string;
}

const SectionWrapper: React.FC<SectionWrapperProps> = ({ title, children, className }) => {
  return (
    <section
      className={cn('flex flex-col flex-1 bg-white rounded-md border border-gray-300', className)}>
      <Typography
        variant="heading-h2"
        title={title}
        className="mb-5 border-b border-gray-300 p-5 pb-5"
      />
      <div className="mb-5 flex flex-col space-y-10 xl:flex-row xl:justify-between xl:space-y-0 xl:space-x-5">
        {children}
      </div>
    </section>
  );
};

export default SectionWrapper;
