import { Typography, List } from 'ui-kit';

import TimeBadget from 'components/TimeBadget';
import { QuestionType } from 'components/Question';

export const SingleApplicationCustomQuestions = ({ application }) => (
  <div className="flex flex-col gap-6">
    <div className="flex items-center">
      <Typography
        title={`${application?.candidate?.[0]?.firstName} ${application?.candidate?.[0]?.lastName}'s Custom Questions`}
        variant="heading-h2"
      />
      {application?.customQuestions?.result?.calculatedResult && (
        <span
          className={`flex ml-[12px] h-[22px] items-center gap-[4.5px] rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm font-semibold text-[#05443D] shadow-small`}
        >
          {Math.round(application?.customQuestions?.result?.calculatedResult)}%
        </span>
      )}
    </div>
    <List
      data={application?.customQuestions?.sections[0]?.questions}
      element={({ timeLimit, tags, type, index, ...props }, idx) => (
        <div className="flex flex-col rounded border border-[#C9CCCF] bg-white">
          <div className="flex justify-between rounded-t bg-[#F6F6F7] py-3 pl-6 pr-3">
            <div className="flex items-center gap-[16px]">
              <div className="flex gap-1">
                <p className="text-[#2F6E63]">{props?.index || idx + 1}</p>
                <p className="text-[#8C9196]">
                  / {application?.customQuestions?.sections[0]?.questions?.length}
                </p>
              </div>
              <TimeBadget time={timeLimit} />
            </div>
          </div>
          <div className="px-[24px] py-[18px]">
            <QuestionType type={type} {...props} showAnswers />
          </div>
        </div>
      )}
      keyExtractor={(_, idx) => idx}
      wrap="div"
      className="space-y-3"
    />
  </div>
);
