const answerType = ({ type, answers, essayPlaceholder, essayLimit }) => {
  const answerTypes = {
    single: () => ({
      answers: answers.map(({ score, text, _id, attachment }) => ({
        score,
        text,
        _id,
        attachment
      }))
    }),
    multiple: () => ({
      answers: answers.map(({ score, text, _id, attachment }) => ({
        score,
        text,
        _id,
        attachment
      }))
    }),
    essay: () => ({ essayPlaceholder, essayLimit })
  };

  return answerTypes[type]();
};

// TODO: someday here will be rest operator without this spaghetti, but it will be another story
export const clearSection = ({
  sections,
  name,
  recommendedScore,
  cover,
  icon,
  evaluation,
  tagline,
  summary,
  outcomes,
  instructions,
  language,
  difficulty,
  whyMeasure,
  releaseNotes,
  result,
  tags,
  areasOfUse,
  displaySectionAs,
  suitableFor,
  category,
  website,
  estimatedDurationPercent,
  estimatedDuration,
}) => {
  const parseQuestions = ({
    text,
    isShuffleAnswers,
    type,
    distributePoints,
    timeLimit,
    tags,
    answers,
    essayPlaceholder,
    essayLimit,
    _id,
    attachment
  }) => {
    return {
      _id,
      text,
      isShuffleAnswers,
      distributePoints,
      timeLimit,
      tags,
      attachment,
      type,
      ...answerType({ type, answers, essayPlaceholder, essayLimit })
    };
  };

  const parseSection = ({
    questions,
    name,
    instructions,
    resultInterpretation,
    showInstructions
  }) => {
    return {
      name,
      showInstructions,
      resultInterpretation,
      instructions: showInstructions ? instructions : '',
      questions: questions.map(parseQuestions)
    };
  };

  return {
    name,
    tagline,
    summary,
    recommendedScore,
    evaluation,
    cover,
    icon,
    outcomes,
    language,
    difficulty,
    tags,
    releaseNotes,
    instructions,
    whyMeasure,
    sections: sections.map(parseSection),
    result,
    areasOfUse,
    suitableFor,
    displaySectionAs,
    category,
    website,
    estimatedDurationPercent,
    estimatedDuration
  };
};
