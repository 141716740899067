import React from 'react';

import { useSelector } from 'react-redux';

import { Form, Input, Textarea } from 'ui-kit';

const Profile = () => {
  const { currentAuthor } = useSelector(store => store.admin);

  if (!currentAuthor) return null;

  return (
    <Form defaultValues={currentAuthor} className="flex gap-5">
      <div className="flex-1 space-y-5">
        <div className="flex gap-5">
          <Input className="flex-1" name="firstName" placeholder="First Name" disabled />
          <Input className="flex-1" name="lastName" placeholder="Last Name" disabled />
        </div>
        <Input className="flex-1" name="title" placeholder="Title" disabled />
        <Textarea name="biography" placeholder="Bio" disabled />
      </div>
      <div className="flex-1 space-y-5">
        <Input name="linkedIn" placeholder="Linked In" disabled />
        <Input name="facebook" placeholder="Facebook" disabled />
        <Input name="behance" placeholder="Behance" disabled />
        <Input name="dribbble" placeholder="Dribbble" disabled />
        <Input name="website" placeholder="Website" disabled />
      </div>
    </Form>
  );
};

export default Profile;
