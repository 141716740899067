import ContentLoader from 'react-content-loader';

const CardLoader = ({ type, ...rest }) => {
  switch (type) {
    case 'assessment':
      return (
        // <ContentLoader
        //   speed={0.5}
        //   viewBox="0 0 530 334"
        //   backgroundColor="#e2e2e2"
        //   foregroundColor="#ffffff"
        //   className="border border-gray-400 rounded-xl bg-white"
        //   {...rest}>
        //   <rect x="219" y="300" rx="0" ry="0" width="0" height="1" />
        //   <rect x="25" y="33" rx="5" ry="5" width="261" height="25" />
        //   <rect x="25" y="132" rx="5" ry="5" width="362" height="15" />
        //   <rect x="25" y="166" rx="5" ry="5" width="362" height="15" />
        //   <rect x="25" y="199" rx="5" ry="5" width="121" height="15" />
        //   <rect x="25" y="297" rx="5" ry="5" width="488" height="15" />
        //   <rect x="394" y="0" rx="5" ry="5" width="123" height="20" />
        //   <rect x="25" y="80" rx="5" ry="5" width="114" height="15" />
        // </ContentLoader>
        <ContentLoader
          speed={0.5}
          viewBox="0 0 590 266"
          backgroundColor="#e2e2e2"
          foregroundColor="#ffffff"
          className="rounded-xl border border-gray-400 bg-white"
          {...rest}>
          <rect x="219" y="300" rx="0" ry="0" width="0" height="1" />
          <rect x="454" y="0" rx="5" ry="5" width="122" height="23" />
          <rect x="20" y="74" rx="2" ry="2" width="162" height="10" />
          <rect x="20" y="122" rx="2" ry="2" width="69" height="20" />
          <rect x="20" y="222" rx="2" ry="2" width="188" height="10" />
          <rect x="25" y="297" rx="5" ry="5" width="488" height="15" />
          <rect x="20" y="25" rx="2" ry="2" width="215" height="28" />
          <rect x="207" y="122" rx="2" ry="2" width="95" height="20" />
          <rect x="395" y="122" rx="2" ry="2" width="115" height="20" />
          <rect x="383" y="222" rx="2" ry="2" width="188" height="10" />
        </ContentLoader>
      );
    case 'test':
      return (
        <ContentLoader
          speed={0.5}
          viewBox="0 0 529 193"
          backgroundColor="#e2e2e2"
          foregroundColor="#ffffff"
          className="rounded-xl border border-gray-400 bg-white"
          {...rest}>
          <rect x="219" y="300" rx="0" ry="0" width="0" height="1" />
          <rect x="25" y="30" rx="5" ry="5" width="306" height="30" />
          <rect x="25" y="121" rx="5" ry="5" width="486" height="10" />
          <rect x="25" y="163" rx="5" ry="5" width="362" height="10" />
          <rect x="25" y="199" rx="5" ry="5" width="121" height="15" />
          <rect x="25" y="297" rx="5" ry="5" width="488" height="15" />
          <rect x="394" y="0" rx="5" ry="5" width="123" height="20" />
          <rect x="25" y="80" rx="5" ry="5" width="114" height="10" />
        </ContentLoader>
      );
    case 'test--add':
      return (
        <ContentLoader
          speed={0.5}
          viewBox="0 0 515 271"
          backgroundColor="#e2e2e2"
          foregroundColor="#ffffff"
          className="rounded-xl border border-gray-400 bg-white"
          {...rest}>
          <rect x="219" y="300" rx="0" ry="0" width="0" height="1" />
          <rect x="25" y="30" rx="5" ry="5" width="306" height="30" />
          <rect x="25" y="121" rx="5" ry="5" width="462" height="10" />
          <rect x="25" y="172" rx="5" ry="5" width="362" height="10" />
          <rect x="25" y="215" rx="10" ry="10" width="80" height="37" />
          <rect x="25" y="297" rx="5" ry="5" width="488" height="15" />
          <rect x="25" y="80" rx="5" ry="5" width="114" height="10" />
          <rect x="416" y="215" rx="10" ry="10" width="80" height="37" />
        </ContentLoader>
      );
  }
};

export default CardLoader;
