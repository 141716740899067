import React, { useEffect } from 'react';
import { List, Navbar as Nav } from 'ui-kit';
import { navConfig } from 'utils/helpers';
import { useUserRole } from 'hooks';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import * as routes from '../../../routes/routes';

export const Configuration = props => {
  const { role } = useUserRole();
  const { configurationMenu } = navConfig(role);
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/configuration') {
      navigate(routes.CONFIGURATION + '/' + routes.GENERAL);
    }
  }, [location]);
  return (
    <div className="h-full">
      <div className="flex h-full">
        <List
          data={configurationMenu}
          className="h-full min-w-[250px] flex-col gap-1.5 border-r border-gray-300 bg-white py-[36px]"
          keyExtractor={({ name }) => name}
          element={({ name, link, submenu }) =>
            !submenu ? (
              <Nav.Link
                className={() =>
                  'border-r max-h-[43px] pl-[32px] text-base font-semibold hover:border-green-400 hover:text-green-400'
                }
                to={link}
                title={name}
                configuration
              />
            ) : (
              <Nav.Link
                className={() =>
                  'border-r max-h-[43px] pl-[40px] text-base font-normal hover:border-green-400 hover:text-green-400'
                }
                to={link}
                title={name}
                configuration
              />
            )
          }
          wrap={Nav.List}
        />
        <Outlet />
      </div>
    </div>
  );
};
