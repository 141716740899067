import { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { useDebouceCallback } from 'hooks';
import { Typography, Icon, Input, Form } from 'ui-kit';
import { customStyles } from 'ui-kit/CustomSelect/SelectCustom';

import { getPublishedAssessmentsNamesRequest } from 'store/assessment/actions';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { ThreeDots } from 'react-loader-spinner';

const statuses = [
  { label: 'Completed', value: 'Not Evaluated' },
  { label: 'Evaluated', value: 'Evaluated' },
  { label: 'Invited for Interview', value: 'Invited for Interview' },
  { label: 'Interviewed', value: 'Interviewed' },
  { label: 'Offer Sent', value: 'Offer Sent' },
  { label: 'Offer Accepted', value: 'Offer Accepted' },
  { label: 'Offer Declined', value: 'Offer Declined' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Candidate Unresponsive', value: 'Candidate Unresponsive' },
  { label: 'Candidate Withdrew', value: 'Candidate Withdrew' }
];

const sources = [
  { label: 'Any Source', value: 'Any Source' },
  { label: 'Public Link', value: 'Public Link' },
  { label: 'Email', value: 'Email' }
];

const scores = [
  { label: 'Any Score', value: 'any score' },
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' }
];

const customFilterStyles = {
  ...customStyles,
  control: () => ({
    ...customStyles.control(),
    minHeight: '40px'
  })
};

const checkBoxSelectStyles = {
  ...customFilterStyles,
  menu: provided => ({
    ...customFilterStyles.menu(provided),
    width: '250px'
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...customFilterStyles.option(styles, { data, isDisabled, isFocused, isSelected }),
    background: '#FFFFFF',
    ':active': {
      ...styles[':active'],
      backgroundColor: '#FFFFFF'
    }
  })
};

const Option = props => {
  return (
    <div className="h-[40px] w-full overflow-hidden">
      <components.Option {...props} className="overflow-hidden truncate">
        <input type="checkbox" checked={props.isSelected} onChange={() => null} /> {props.label}
      </components.Option>
    </div>
  );
};

const multiValueContainerAssessments = ({ index, getValue }) => {
  return index === 0 ? (
    <div>
      {getValue().length} assessment{getValue().length > 1 ? 's' : ''}
    </div>
  ) : null;
};

const multiValueContainerStatuses = ({ index, getValue }) => {
  return index === 0 ? (
    <div>
      {getValue().length} status{getValue().length > 1 ? 'es' : ''}
    </div>
  ) : null;
};

export const CandidatesFilters = ({
  handleChangingFilters,
  params,
  candidatesLength = 0,
  setSearchLoading,
  searchLoading
}) => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(params.search);

  const { assessmentsForFilter } = useSelector(store => store.assessments);

  const selectedAssessments =
    params.assessment === ''
      ? assessmentsForFilter
      : assessmentsForFilter.filter(item => params.assessment.split(',').includes(item._id));

  const selectedStatuses =
    params.status === ''
      ? statuses
      : statuses.filter(item => params.status.split(',').includes(item.value));

  useEffect(() => {
    dispatch(getPublishedAssessmentsNamesRequest());
  }, []);

  const debounceSubmit = useDebouceCallback(value => {
    setSearchLoading(true);
    handleChangingFilters('search', value.search);
  });

  const onSubmit = value => {
    setSearchValue(value.search);
    debounceSubmit(value);
  };

  return (
    <Form>
      {({ handleSubmit }) => (
        <div className="flex justify-between gap-[8px]">
          <div className="flex items-center gap-3">
            <Typography variant="heading-h1" title="Candidates" />

            {assessmentsForFilter.length !== 0 && (
              <Select
                name="statuses"
                placeholder="All Assessments"
                onChange={value =>
                  handleChangingFilters('assessment', value.map(item => item.value).join(','))
                }
                isMulti
                hideSelectedOptions={false}
                isClearable={false}
                isSearchable={false}
                styles={checkBoxSelectStyles}
                className="h-[40px] w-[180px] rounded border-black"
                options={assessmentsForFilter.map(assessment => ({
                  label: assessment.name,
                  value: assessment._id
                }))}
                value={selectedAssessments.map(assessment => ({
                  label: assessment.name,
                  value: assessment._id
                }))}
                components={{
                  MultiValue: multiValueContainerAssessments,
                  IndicatorSeparator: () => null,
                  Option
                }}
              />
            )}

            <div className="flex max-h-[21px] items-center justify-center rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] font-semibold text-[#05443D] shadow-small">
              {candidatesLength} Candidates
            </div>
          </div>
          <div className="flex">
            <Select
              name="score"
              onChange={value =>
                handleChangingFilters('score', value.value !== scores[0].value ? value.value : '')
              }
              options={scores}
              value={params?.score ? scores.find(item => item.value === params.score) : null}
              placeholder="Score"
              className="mr-3 h-[40px] w-[140px] min-w-[140px] "
              styles={customFilterStyles}
              components={{
                IndicatorSeparator: () => null
              }}
            />

            <Select
              name="statuses"
              placeholder="Status"
              isMulti
              hideSelectedOptions={false}
              styles={checkBoxSelectStyles}
              options={statuses}
              value={selectedStatuses}
              className="mr-3 h-[40px] w-[145px] min-w-[145px]"
              isClearable={false}
              isSearchable={false}
              onChange={value =>
                handleChangingFilters('status', value.map(item => item.value).join(','))
              }
              components={{
                MultiValue: multiValueContainerStatuses,
                IndicatorSeparator: () => null,
                Option
              }}
            />

            <Select
              name="source"
              onChange={value =>
                handleChangingFilters('source', value.value !== sources[0].value ? value.value : '')
              }
              options={sources}
              value={params?.source ? sources.find(item => item.value === params.source) : null}
              placeholder="Source"
              className="mr-3 h-[40px] w-[140px] min-w-[140px]"
              styles={customFilterStyles}
              components={{
                IndicatorSeparator: () => null
              }}
            />
            <Input
              leftComponent={() => (
                <Icon className="ml-3" size={16} color="#8C9196" icon={faMagnifyingGlass} />
              )}
              rightComponent={() => (
                <div className="mr-3 w-[16px]">
                  {searchLoading && (
                    <ThreeDots height="16" width="16" color="grey" ariaLabel="loading" />
                  )}
                </div>
              )}
              name="search"
              onChangeValue={handleSubmit(onSubmit)}
              value={searchValue}
              className="h-[40px] w-[176px]"
              placeholder="Search"
            />
          </div>
        </div>
      )}
    </Form>
  );
};
