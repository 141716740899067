import { Tab as TabUI } from '@headlessui/react';

import cn from 'utils/classNames';

const Tab = ({ children, className, create, ...rest }) => (
  <TabUI {...rest}>
    {({ selected }) => (
      <div
        className={cn(
          !selected && !create && 'text-[#6D7175]',
          create && selected
            ? 'border border-b-white bg-white rounded-t-xl'
            : 'border border-b-0 border-x-gray-200 border-t-gray-200 opacity-50',
          className
        )}>
        {children}
      </div>
    )}
  </TabUI>
);

const List = ({ children, className }) => (
  <TabUI.List className={cn(className, 'flex')}>{children}</TabUI.List>
);

const Panels = ({ children, className }) => (
  <TabUI.Panels className={cn(className)}>{children}</TabUI.Panels>
);

const Panel = ({ children, className }) => (
  <TabUI.Panel className={className}>{children}</TabUI.Panel>
);

const Group = ({ children, className, as, ...rest }) => (
  <TabUI.Group as={as} className={cn(className)} {...rest}>
    {children}
  </TabUI.Group>
);

Tab.Group = Group;
Tab.Panels = Panels;
Tab.Panel = Panel;
Tab.List = List;

export default Tab;
