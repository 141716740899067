import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { Icon } from 'ui-kit';

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleSliderChange = event => {
    setCurrentIndex(parseInt(event.target.value));
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex w-full justify-center">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            className={`max-h-[290px] max-w-[496px] object-cover transition-opacity ${
              index === currentIndex ? 'block' : 'hidden'
            }`}
          />
        ))}
      </div>
      <div className="flex items-center justify-between gap-[19px]">
        <div
          onClick={prevImage}
          className="flex h-[28px] w-[32px] cursor-pointer items-center justify-center rounded-full hover:bg-gray-300">
          <Icon icon={faChevronLeft} size={16} color="#5C5F62" />
        </div>
        <input
          type="range"
          min={0}
          max={images.length - 1}
          value={currentIndex}
          onChange={handleSliderChange}
          className="slider-thumb-custom h-3 w-full appearance-none rounded-sm bg-[#E4E5E7]"
        />
        <div
          onClick={nextImage}
          className="flex h-[28px] w-[32px] cursor-pointer items-center justify-center rounded-full hover:bg-gray-300">
          <Icon icon={faChevronRight} size={16} color="#5C5F62" />
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
