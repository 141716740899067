
import { Chip, List, Typography } from 'ui-kit';
import { QuestionType } from 'components/Question';
import { secondsToHms } from 'utils/helpers';
import { FormatOptionLabel } from 'ui-kit/CustomSelect/SelectCustom';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';

export const Section = ({
  sectionLen,
  hidden,
  questions,
  name,
  instructions,
  showInstructions,
  idx
}) => {
  return (
    <div className="flex flex-col gap-3">
      {!hidden && (
        <div className="space-y-3 rounded-lg border border-gray-200 bg-gray-100 py-9 px-6.5">
          <div className="flex items-center gap-4">
            <Typography variant="heading-h5" title={`Section ${idx + 1} of ${sectionLen}`} />
            <Chip variant="primary" title={`${questions.length} Questions`} />
          </div>

          <Typography title={name} variant="heading-h2" />
          {showInstructions && <HtmlTextReplacer html={instructions} />}
        </div>
      )}
      <List
        data={questions}
        element={({ timeLimit, tags, type, index, ...props }, idx) => (
          <div className="flex flex-col gap-4 rounded-lg border border-gray-300 p-6 pl-3">
            <div className="flex items-center justify-between">
              <div className="flex items-start gap-[12px]">
                <Typography
                  title={index}
                  variant="heading-h6"
                  className="ml-4.5 mt-1.5 text-green-500"
                />
                <div className="ml-4.5 rounded bg-[#E4E5E7] px-[12px] py-[5.5px]">
                  <Typography title={secondsToHms(timeLimit)} variant="small-default" />
                </div>
                <div className="flex flex-wrap gap-[12px]">
                  {tags?.map(({ label }) => (
                    <FormatOptionLabel label={label} key={label} />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex gap-3 pl-14">
              <QuestionType type={type} {...props} />
            </div>
          </div>
        )}
        keyExtractor={(_, idx) => idx}
        wrap="div"
        className="space-y-3"
      />
    </div>
  );
};
