import { Button, Typography } from 'ui-kit';
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as routes from 'routes/routes';

export const EmployerPromoCountdown = ({ timestamp }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const oneDayFromTimestamp = moment(timestamp).add(1, 'day');

    if (moment().isBefore(oneDayFromTimestamp)) {
      setTimeLeft(moment.duration(oneDayFromTimestamp.diff(moment())));
      const interval = setInterval(() => {
        const diff = moment.duration(oneDayFromTimestamp.diff(moment()));
        setTimeLeft(diff);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimeLeft(null);
    }
  }, [timestamp]);

  if (timeLeft) {
    const hours = timeLeft.hours();
    const minutes = timeLeft.minutes();
    const seconds = timeLeft.seconds();

    return (
      <div className="flex w-full flex-col items-center justify-center gap-[24px]">
        <div className="flex flex-col gap-[6px]">
          <Typography variant="heading-h1" title="Publish Your First Assessment" />
          <Typography
            className="text-center"
            variant="regular-default"
            title="& Get 1 Additional Assessment Publications for Free"
          />
        </div>
        <div className="flex w-[230px] flex-col items-center gap-[4px] rounded-md border border-[#C9CCCF] bg-[#FFFFFF] px-[24px] py-[12px]">
          <Typography className="text-[#6D7175]" variant="small-default" title="This Offer Ends In" />
          <div className="flex gap-[8px]">
            <div className="flex items-end gap-[2px]">
              <Typography variant="heading-h1" title={hours} />
              <Typography className="mb-[3px]" variant="heading-h3" title="h" />
            </div>
            <div className="flex items-end gap-[2px]">
              <Typography variant="heading-h1" title={minutes} />
              <Typography className="mb-[3px]" variant="heading-h3" title="m" />
            </div>
            <div className="flex items-end gap-[2px]">
              <Typography variant="heading-h1" title={seconds} />
              <Typography className="mb-[3px]" variant="heading-h3" title="s" />
            </div>
          </div>
        </div>
        <Button
          className="flex w-[275px] justify-center"
          variant="primary"
          title="Create my First Assessment"
          onClick={() => navigate(routes.ASSESSMENTS + routes.CREATE)}
        />
      </div>
    );
  } else {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-[24px]">
        <div className="flex flex-col gap-[6px]">
          <Typography variant="heading-h1" title="Publish Your First Assessment" />
        </div>
        <Button
          className="flex w-[275px] justify-center"
          variant="primary"
          title="Create my First Assessment"
          onClick={() => navigate(routes.ASSESSMENTS + routes.CREATE)}
        />
      </div>
    );
  }
};
