import { assessmentConstants } from '../actions';

import { questionMove } from 'utils/helpers';

export const initialcurrentAssessment = {
  title: '',
  name: 'Unnamed',
  antiCheat: {
    enableCamera: true
  },
  applications: [],
  description: `Welcome to '[Company Name]' 's hiring assessment!<br /><br />We are delighted that you are considering joining our team and taking the time to complete this assessment. It's a great opportunity for us to get to know you better and to determine if you are the right fit for our organization, and if our organization is the right fit for you. We are certain that this will be an enjoyable and rewarding experience for you, and we look forward to reviewing the results and letting you know if you are the ideal candidate.<br /><br /> Good luck!`,
  jobRole: '',
  template: null,
  status: null,
  testCount: null,
  questionsCount: null,
  applicationCount: null,
  tests: [],
  questions: [],
  authorName: null,
  customQuestions: {
    sections: [{ questions: [] }]
  }
};

const initialState = {
  loading: false,
  allAssessments: [],
  lastPublishedAssessment: { _id: null },
  publishedAssessments: [],
  draftAssessments: [],
  archivedAssessments: [],
  publishedTotal: 0,
  draftTotal: 0,
  archivedTotal: 0,
  emptyAssessment: true,
  recommendedTemplates: [],
  genericTemplates: [],
  evaluateQuestions: [],
  showModal: false,
  showConfirmPublishModal: true,
  withTemplate: true,
  applications: {
    measure: [],
    evaluate: [],
    interview: [],
    hire: []
  },
  currentAssessment: initialcurrentAssessment,
  totalAssessments: null,
  assessmentsForFilter: [],
  error: null
};

export function assessments(state = initialState, action) {
  switch (action.type) {
    // RESET ERRORS - NO BACKEND
    case assessmentConstants.RESET_ERRORS:
      return {
        ...state,
        error: null
      };

    // REQUEST ACTIONS
    case assessmentConstants.GET_DRAFT_ASSESSMENTS_REQUEST:
    case assessmentConstants.GET_PUBLISHED_ASSESSMENTS_REQUEST:
    case assessmentConstants.GET_ARCHIVED_ASSESSMENTS_REQUEST:
    case assessmentConstants.GET_ASSESSMENT_REQUEST:
    case assessmentConstants.GET_ASSESSMENT_APPLICATIONS_REQUEST:
    case assessmentConstants.GET_EVALUATE_QUESTIONS_REQUEST:
    case assessmentConstants.GET_ASSESSMENT_GENERIC_TEMPLATES_REQUEST:
    case assessmentConstants.PUBLISH_ASSESSMENT_REQUEST:
    case assessmentConstants.ARCHIVE_ASSESSMENT_REQUEST:
    case assessmentConstants.RESTORE_ASSESSMENT_REQUEST:
    case assessmentConstants.DUPLICATE_ASSESSMENT_REQUEST:
    case assessmentConstants.INVITE_CANDIDATE_REQUEST:
    case assessmentConstants.GET_PUBLISHED_ASSESSMENTS_NAMES_REQUEST:
    case assessmentConstants.GET_ALL_ASSESSMENTS_REQUEST:
    case assessmentConstants.PUBLISH_ASSESSMENT_WITHOUT_CONFIRMATION_REQUEST:
    case assessmentConstants.GET_LAST_PUBLISH_ASSESSMENT_REQUEST:
    case assessmentConstants.DELETE_ASSESSMENT_REQUEST:
      return {
        ...state,
        loading: true
      };

    // REQUEST ACTIONS WITH CLOSING MODAL
    case assessmentConstants.CREATE_NEW_ASSESSMENT_REQUEST:
    case assessmentConstants.UPDATE_ASSESSMENT_REQUEST:
    case assessmentConstants.PUBLISH_ASSESSMENT_FROM_TEMPLATE_REQUEST:
      return {
        ...state,
        showModal: false,
        loading: true,
        error: null
      };

    // SUCCESS ACTIONS
    case assessmentConstants.GET_ALL_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allAssessments: action.payload.assessments,
        totalAssessments: action.payload.total,
        error: null
      };
    case assessmentConstants.GET_LAST_PUBLISH_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        showConfirmPublishModal:
          action.payload._id !== null || state.archivedAssessments.length !== 0,
        lastPublishedAssessment: action.payload
      };
    case assessmentConstants.GET_DRAFT_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        draftAssessments: [...state.draftAssessments, ...action.payload.assessments],
        draftTotal: action.payload.total,
        emptyAssessment:
          action.payload.total > 0 || state.publishedTotal > 0 || state.archivedTotal > 0
            ? false
            : true,
        error: null
      };
    case assessmentConstants.GET_PUBLISHED_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        publishedAssessments: [...state.publishedAssessments, ...action.payload.assessments],
        publishedTotal: action.payload.total,
        emptyAssessment:
          action.payload.total > 0 || state.draftTotal > 0 || state.archivedTotal > 0
            ? false
            : true,
        error: null
      };
    case assessmentConstants.GET_PUBLISHED_DEMO_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        publishedAssessments: [...state.publishedAssessments, ...action.payload.assessments],
        publishedTotal: state.publishedTotal + action.payload.assessments.length,
        error: null
      };
    case assessmentConstants.GET_PUBLIC_DEMO_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        publishedAssessments: action.payload.assessments,
        error: null
      };
    case assessmentConstants.GET_ARCHIVED_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        archivedAssessments: [...state.archivedAssessments, ...action.payload.assessments],
        archivedTotal: action.payload.total,
        showConfirmPublishModal:
          state.lastPublishedAssessment._id !== null || action.payload.assessments.length !== 0,
        emptyAssessment:
          action.payload.total > 0 || state.publishedTotal > 0 || state.draftTotal > 0
            ? false
            : true,
        error: null
      };
    case assessmentConstants.GET_ARCHIVED_DEMO_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        archivedAssessments: [...state.archivedAssessments, ...action.payload.assessments],
        archivedTotal: state.archivedTotal + action.payload.assessments.length,
        error: null
      };
    case assessmentConstants.GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        withTemplate: !!action.payload.template,
        currentAssessment: action.payload,
        error: null
      };
    case assessmentConstants.GET_ASSESSMENT_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: action.payload,
        error: null
      };
    case assessmentConstants.GET_EVALUATE_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        evaluateQuestions: action.payload,
        error: null
      };
    case assessmentConstants.GET_ASSESSMENT_GENERIC_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        genericTemplates: action.payload,
        error: null
      };
    case assessmentConstants.PUBLISH_ASSESSMENT_SUCCESS:
      return {
        ...state,
        currentAssessment: {
          ...state.currentAssessment,
          _id: action.payload._id,
          status: action.payload.status,
          hash: action.payload.hash,
          expiresAt: action.payload.expiresAt
        },
        lastPublishedAssessment: {
          _id: action.payload._id,
          hash: action.payload.hash,
          name: action.payload.name
        },
        loading: false,
        error: null
      };
    case assessmentConstants.ARCHIVE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case assessmentConstants.RESTORE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case assessmentConstants.DUPLICATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentAssessment: action.payload
      };
    case assessmentConstants.CREATE_NEW_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: action.payload?.continueLoading ?? false,
        showModal: false,
        currentAssessment: {
          ...state.currentAssessment,
          _id: action.payload._id,
          status: action.payload.status
        },
        error: null
      };
    case assessmentConstants.UPDATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        showModal: false,
        loading: action.payload?.continueLoading ?? false,
        error: null
      };
    case assessmentConstants.INVITE_CANDIDATE_SUCCESS:
      return {
        ...state,
        applications: { ...state.applications, measure: action.payload },
        loading: false,
        error: null
      };
    case assessmentConstants.SET_CUSTOM_JOB_ROLE:
      return {
        ...state,
        loading: false,
        withTemplate: true,
        currentAssessment: {
          ...state.currentAssessment,
          name: action.payload.value,
          jobRole: action.payload.value,
          template: undefined,
          tests: action.payload.replaceTests ? [] : state.currentAssessment.tests
        },
        error: null
      };
    case assessmentConstants.GET_RECOMMENDED_TEMPLATES_REQUEST: // DIFFERS FROM DEFAULT REQUEST ACTIONS
      return {
        ...state,
        loading: true,
        currentAssessment: {
          ...state.currentAssessment,
          name: action.payload,
          jobRole: action.payload
        },
        error: null
      };
    case assessmentConstants.GET_RECOMMENDED_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAssessment: { ...state.currentAssessment, template: action.payload[0]._id },
        withTemplate: true,
        error: null
      };

    case assessmentConstants.GET_PUBLISHED_ASSESSMENTS_NAMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        assessmentsForFilter: [...action.payload]
      };
    case assessmentConstants.DELETE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currentAssessment: { ...initialState.currentAssessment }
      };

    //ACTION FOR DEV - NO BACKEND ===================
    case assessmentConstants.CLEAR_ASSESSMENTS_PAGINATION:
      return {
        ...state,
        draftAssessments: state.draftAssessments.slice(0, 30),
        publishedAssessments: state.publishedAssessments.slice(0, 30),
        archivedAssessments: state.archivedAssessments.slice(0, 30)
      };
    case assessmentConstants.CLEAR_ASSESSMENTS: {
      return {
        ...state,
        draftAssessments: [],
        publishedAssessments: [],
        archivedAssessments: []
      };
    }
    case assessmentConstants.CLEAR_EVALUATE_QUESTIONS:
      return {
        ...state,
        evaluateQuestions: []
      };
    case assessmentConstants.UPDATE_TESTS:
      return {
        ...state,
        currentAssessment: {
          ...state.currentAssessment,
          tests: action.payload
        }
      };
    case assessmentConstants.ADD_TEST_TO_ASSESSMENT: {
      let newTests =
        action.payload.length > 1
          ? [...state.currentAssessment.tests, ...action.payload]
          : [...state.currentAssessment.tests, action.payload];
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          tests: newTests,
          testCount: newTests.length
        }
      };
    }
    case assessmentConstants.ADD_CUSTOM_QUESTION:
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          customQuestions: {
            ...state.currentAssessment.customQuestions,
            sections: [
              {
                questions: [
                  ...state.currentAssessment.customQuestions.sections[0].questions,
                  action.payload
                ]
              }
            ]
          }
        }
      };
    case assessmentConstants.UPDATE_CUSTOM_QUESTION: {
      const { idx, data } = action.payload;
      const updated = state.currentAssessment.customQuestions.sections[0].questions.map(
        (item, id) => (id === idx ? data : item)
      );
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          customQuestions: {
            ...state.currentAssessment.customQuestions,
            sections: [
              {
                questions: updated
              }
            ]
          }
        }
      };
    }
    case assessmentConstants.DELETE_CUSTOM_QUESTION: {
      const updated = state.currentAssessment.customQuestions.sections[0].questions.filter(
        (item, id) => id !== action.payload
      );
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          customQuestions: {
            ...state.currentAssessment.customQuestions,
            sections: [
              {
                questions: updated
              }
            ]
          }
        }
      };
    }
    case assessmentConstants.DUPLICATE_CUSTOM_QUESTION: {
      const updated = state.currentAssessment.customQuestions.sections[0].questions.find(
        (item, id) => id === action.payload
      );
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          customQuestions: {
            ...state.currentAssessment.customQuestions,
            sections: [
              {
                questions: [
                  ...state.currentAssessment.customQuestions.sections[0].questions,
                  updated
                ]
              }
            ]
          }
        }
      };
    }
    case assessmentConstants.SWAP_CUSTOM_QUESTION: {
      const { from, to } = action.payload;
      const arr = [...state.currentAssessment.customQuestions.sections[0].questions];
      if (arr.length <= 1 || arr.length <= to || to < 0) {
        return {
          ...state
        };
      }
      [arr[from], arr[to]] = [arr[to], arr[from]];
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          customQuestions: {
            ...state.currentAssessment.customQuestions,
            sections: [
              {
                questions: arr
              }
            ]
          }
        }
      };
    }
    case assessmentConstants.REMOVE_TEST_FROM_ASSESSMENT: {
      const newTest = state.currentAssessment.tests.filter(item => item._id !== action.payload);
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          tests: newTest,
          testCount: newTest.length
        }
      };
    }
    case assessmentConstants.EDIT_ASSESSMENT:
      return {
        ...state,
        currentAssessment: action.payload
      };
    case assessmentConstants.UPDATE_ASSESSMENT:
      return {
        ...state,
        showModal: true,
        currentAssessment: { ...state.currentAssessment, ...action.payload }
      };
    case assessmentConstants.RESET_ASSESSMENT_DATA:
      return {
        ...state,
        showModal: false,
        recommendedTemplates: [],
        currentAssessment: initialState.currentAssessment
      };
    case assessmentConstants.CHANGE_TEMPLATE_DATA:
      return {
        ...state,
        showModal: true,
        withTemplate: false,
        currentAssessment: {
          ...state.currentAssessment,
          template: null,
          tests: []
        }
      };
    case assessmentConstants.ADD_ASSESSMENT_QUESTION:
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment.questions,
          questions: [...state.currentAssessment.questions, ...state.currentAssessmentQuestion]
        }
      };
    case assessmentConstants.DUBLICATE_ASSESSMENT_QUESTION: {
      const dublicatedQuestion = state.currentAssessment.questions.filter(
        item => item.queueId === action.payload
      )[0];
      const newId =
        state.currentAssessment.questions.sort((a, b) => a.queueId - b.queueId).at(-1).queueId + 1;
      const newQuestion = { ...dublicatedQuestion, queueId: newId };
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          questions: [...state.currentAssessment.questions, newQuestion]
        }
      };
    }
    case assessmentConstants.DELETE_ASSESSMENT_QUESTION: {
      const newQuestions = state.currentAssessment.questions.filter(
        item => item.queueId !== action.payload
      );
      return {
        ...state,
        showModal: true,
        currentAssessment: { ...state.currentAssessment, questions: newQuestions }
      };
    }
    case assessmentConstants.MOVE_UP_ASSESSMENT_QUESTION:
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          questions: [
            ...questionMove(state.currentAssessment.questions, action.payload, action.payload - 1)
          ]
        }
      };
    case assessmentConstants.MOVE_DOWN_ASSESSMENT_QUESTION:
      return {
        ...state,
        showModal: true,
        currentAssessment: {
          ...state.currentAssessment,
          questions: [
            ...questionMove(state.currentAssessment.questions, action.payload, action.payload + 1)
          ]
        }
      };
    case assessmentConstants.SET_ASSESSMENT_ANTICHEAT:
      return {
        ...state,
        currentAssessment: {
          ...state.currentAssessment,
          antiCheat: {
            ...state.currentAssessment.antiCheat,
            ...action.payload
          }
        }
      };
    case assessmentConstants.CLEAR_ASSESSMENT_STORE:
      return {
        ...initialState
      };

    // FAIL ACTIONS
    case assessmentConstants.GET_ALL_ASSESSMENTS_FAILURE:
    case assessmentConstants.GET_DRAFT_ASSESSMENTS_FAILURE:
    case assessmentConstants.GET_PUBLISHED_ASSESSMENTS_FAILURE:
    case assessmentConstants.GET_ARCHIVED_ASSESSMENTS_FAILURE:
    case assessmentConstants.GET_ASSESSMENT_FAILURE:
    case assessmentConstants.GET_ASSESSMENT_APPLICATIONS_FAILURE:
    case assessmentConstants.GET_EVALUATE_QUESTIONS_FAILURE:
    case assessmentConstants.GET_ASSESSMENT_GENERIC_TEMPLATES_FAILURE:
    case assessmentConstants.PUBLISH_ASSESSMENT_FAILURE:
    case assessmentConstants.ARCHIVE_ASSESSMENT_FAILURE:
    case assessmentConstants.RESTORE_ASSESSMENT_FAILURE:
    case assessmentConstants.DUPLICATE_ASSESSMENT_FAILURE:
    case assessmentConstants.CREATE_NEW_ASSESSMENT_FAILURE:
    case assessmentConstants.UPDATE_ASSESSMENT_FAILURE:
    case assessmentConstants.INVITE_CANDIDATE_FAILURE:
    case assessmentConstants.GET_RECOMMENDED_TEMPLATES_FAILURE:
    case assessmentConstants.GET_PUBLISHED_ASSESSMENTS_NAMES_FAILURE:
    case assessmentConstants.GET_PUBLISHED_DEMO_ASSESSMENTS_FAILURE:
    case assessmentConstants.GET_LAST_PUBLISH_ASSESSMENT_FAILURE:
    case assessmentConstants.DELETE_ASSESSMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    //===============================================
    default:
      return state;
  }
}
