/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const ModalWrapper = ({ isOpen, setIsOpen = () => {}, children, maxWidth = 'max-w-2xl' }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[100] flex h-full overflow-auto bg-white/30 backdrop-blur-sm"
        onClose={() => setIsOpen(false)}>
        <div className="m-auto flex flex-1 items-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div
              className={`mx-auto my-6 inline-block w-full overflow-auto ${maxWidth} transform rounded-xl border border-gray-300 bg-white align-middle shadow-xl transition-all`}>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalWrapper;
