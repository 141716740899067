import { createAction } from 'redux-actions';

export const adminConstants = {
  RESET_ERRORS: 'RESET_ERRORS',

  GET_ALL_EMPLOYERS_REQUEST: 'GET_ALL_EMPLOYERS_REQUEST',
  GET_ALL_EMPLOYERS_SUCCESS: 'GET_ALL_EMPLOYERS_SUCCESS',
  GET_ALL_EMPLOYERS_FAILURE: 'GET_ALL_EMPLOYERS_FAILURE',

  GET_SINGLE_EMPLOYER_REQUEST: 'GET_SINGLE_EMPLOYER_REQUEST',
  GET_SINGLE_EMPLOYER_SUCCESS: 'GET_SINGLE_EMPLOYER_SUCCESS',
  GET_SINGLE_EMPLOYER_FAILURE: 'GET_SINGLE_EMPLOYER_FAILURE',

  GET_SINGLE_COMPANY_REQUEST: 'GET_SINGLE_COMPANY_REQUEST',
  GET_SINGLE_COMPANY_SUCCESS: 'GET_SINGLE_COMPANY_SUCCESS',
  GET_SINGLE_COMPANY_FAILURE: 'GET_SINGLE_COMPANY_FAILURE',

  GET_DASHBOARD_STAT_REQUEST: 'GET_DASHBOARD_STAT_REQUEST',
  GET_DASHBOARD_STAT_SUCCESS: 'GET_DASHBOARD_STAT_SUCCESS',
  GET_DASHBOARD_STAT_FAILURE: 'GET_DASHBOARD_STAT_FAILURE',

  GET_AUTHOR_REQUEST: 'GET_AUTHOR_REQUEST',
  GET_AUTHOR_SUCCESS: 'GET_AUTHOR_SUCCESS',
  GET_AUTHOR_FAILED: 'GET_AUTHOR_FAILED',

  GET_TEST_BY_AUTHOR_REQUEST: 'GET_TEST_BY_AUTHOR_REQUEST',
  GET_TEST_BY_AUTHOR_SUCCESS: 'GET_TEST_BY_AUTHOR_SUCCESS',
  GET_TEST_BY_AUTHOR_FAILED: 'GET_TEST_BY_AUTHOR_FAILED',

  GET_ALL_AUTHORS_REQUEST: 'GET_ALL_AUTHORS_REQUEST',
  GET_ALL_AUTHORS_SUCCESS: 'GET_ALL_AUTHORS_SUCCESS',
  GET_ALL_AUTHORS_FAILED: 'GET_ALL_AUTHORS_FAILED',

  GET_ALL_TESTS_FAMILIES_REQUEST: 'GET_ALL_TESTS_FAMILIES_REQUEST',
  GET_ALL_TESTS_FAMILIES_SUCCESS: 'GET_ALL_TESTS_FAMILIES_SUCCESS',
  GET_ALL_TESTS_FAMILIES_FAILED: 'GET_ALL_TESTS_FAMILIES_FAILED',

  SET_DEMO_ASSESSMENTS_ID_REQUEST: 'SET_DEMO_ASSESSMENTS_ID_REQUEST',
  SET_DEMO_ASSESSMENTS_ID_SUCCESS: 'SET_DEMO_ASSESSMENTS_ID_SUCCESS',
  SET_DEMO_ASSESSMENTS_ID_FAILED: 'SET_DEMO_ASSESSMENTS_ID_FAILED',

  GET_DEMO_ASSESSMENTS_ID_REQUEST: 'GET_DEMO_ASSESSMENTS_ID_REQUEST',
  GET_DEMO_ASSESSMENTS_ID_SUCCESS: 'GET_DEMO_ASSESSMENTS_ID_SUCCESS',
  GET_DEMO_ASSESSMENTS_ID_FAILED: 'GET_DEMO_ASSESSMENTS_ID_FAILED',

  INVITE_AUTHORS_REQUEST: 'INVITE_AUTHORS_REQUEST',
  INVITE_AUTHORS_SUCCESS: 'INVITE_AUTHORS_SUCCESS',
  INVITE_AUTHORS_FAILED: 'INVITE_AUTHORS_FAILED',

  INVITE_EMPLOYER_REQUEST: 'INVITE_EMPLOYER_REQUEST',
  INVITE_EMPLOYER_SUCCESS: 'INVITE_EMPLOYER_SUCCESS',
  INVITE_EMPLOYER_FAILED: 'INVITE_EMPLOYER_FAILED',

  NOTE_EMPLOYER_REQUEST: 'NOTE_EMPLOYER_REQUEST',
  NOTE_EMPLOYER_SUCCESS: 'NOTE_EMPLOYER_SUCCESS',
  NOTE_EMPLOYER_FAILED: 'NOTE_EMPLOYER_FAILED',

  DELETE_NOTE_EMPLOYER_REQUEST: 'DELETE_NOTE_EMPLOYER_REQUEST',
  DELETE_NOTE_EMPLOYER_SUCCESS: 'DELETE_NOTE_EMPLOYER_SUCCESS',
  DELETE_NOTE_EMPLOYER_FAILED: 'DELETE_NOTE_EMPLOYER_FAILED',

  CLEAR_COMPANY_EMPLOYER_DATA: 'CLEAR_COMPANY_EMPLOYER_DATA',

  GENERATE_APPLICATIONS_REQUEST: 'GENERATE_APPLICATIONS_REQUEST',
  GENERATE_APPLICATIONS_SUCCESS: 'GENERATE_APPLICATIONS_SUCCESS',
  GENERATE_APPLICATIONS_FAILURE: 'GENERATE_APPLICATIONS_FAILURE',
};

export const adminResetErrors = createAction(adminConstants.RESET_ERRORS);

export const getAllEmployersRequest = createAction(
  adminConstants.GET_ALL_EMPLOYERS_REQUEST,
  action => action
);
export const getAllEmployersSuccess = createAction(
  adminConstants.GET_ALL_EMPLOYERS_SUCCESS,
  data => data
);
export const getAllEmployersFailure = createAction(
  adminConstants.GET_ALL_EMPLOYERS_FAILURE,
  err => err
);

export const getSingleEmployerRequest = createAction(
  adminConstants.GET_SINGLE_EMPLOYER_REQUEST,
  action => action
);
export const getSingleEmployerSuccess = createAction(
  adminConstants.GET_SINGLE_EMPLOYER_SUCCESS,
  data => data
);
export const getSingleEmployerFailure = createAction(
  adminConstants.GET_SINGLE_EMPLOYER_FAILURE,
  err => err
);

export const getSingleCompanyRequest = createAction(
  adminConstants.GET_SINGLE_COMPANY_REQUEST,
  action => action
);
export const getSingleCompanySuccess = createAction(
  adminConstants.GET_SINGLE_COMPANY_SUCCESS,
  data => data
);
export const getSingleCompanyFailure = createAction(
  adminConstants.GET_SINGLE_COMPANY_FAILURE,
  err => err
);

export const getDashboardStatRequest = createAction(
  adminConstants.GET_DASHBOARD_STAT_REQUEST,
  data => data
);
export const getDashboardStatSuccess = createAction(
  adminConstants.GET_DASHBOARD_STAT_SUCCESS,
  response => response
);
export const getDashboardStatFailure = createAction(
  adminConstants.GET_DASHBOARD_STAT_FAILURE,
  errors => errors
);

export const getAuthorRequest = createAction(adminConstants.GET_AUTHOR_REQUEST, action => action);
export const getAuthorSuccess = createAction(adminConstants.GET_AUTHOR_SUCCESS, authors => authors);
export const getAuthorFailed = createAction(adminConstants.GET_AUTHOR_FAILED, error => error);

export const getTestByAuthorRequest = createAction(
  adminConstants.GET_TEST_BY_AUTHOR_REQUEST,
  action => action
);
export const getTestByAuthorSuccess = createAction(
  adminConstants.GET_TEST_BY_AUTHOR_SUCCESS,
  authors => authors
);
export const getTestByAuthorFailed = createAction(
  adminConstants.GET_TEST_BY_AUTHOR_FAILED,
  error => error
);

export const getAllAuthorsRequest = createAction(
  adminConstants.GET_ALL_AUTHORS_REQUEST,
  action => action
);
export const getAllAuthorsSuccess = createAction(
  adminConstants.GET_ALL_AUTHORS_SUCCESS,
  authors => authors
);
export const getAllAuthorsFailed = createAction(
  adminConstants.GET_ALL_AUTHORS_FAILED,
  error => error
);

export const getAllTestsFamiliesRequest = createAction(
  adminConstants.GET_ALL_TESTS_FAMILIES_REQUEST,
  action => action
);
export const getAllTestsFamiliesSuccess = createAction(
  adminConstants.GET_ALL_TESTS_FAMILIES_SUCCESS,
  authors => authors
);
export const getAllTestsFamiliesFailed = createAction(
  adminConstants.GET_ALL_TESTS_FAMILIES_FAILED,
  error => error
);

export const inviteAuthorRequest = createAction(
  adminConstants.INVITE_AUTHORS_REQUEST,
  action => action
);
export const inviteAuthorSuccess = createAction(
  adminConstants.INVITE_AUTHORS_SUCCESS,
  authors => authors
);
export const inviteAuthorFailed = createAction(
  adminConstants.INVITE_AUTHORS_FAILED,
  error => error
);

export const inviteEmployerRequest = createAction(
  adminConstants.INVITE_EMPLOYER_REQUEST,
  action => action
);
export const inviteEmployerSuccess = createAction(
  adminConstants.INVITE_EMPLOYER_SUCCESS,
  authors => authors
);
export const inviteEmployerFailed = createAction(
  adminConstants.INVITE_EMPLOYER_FAILED,
  error => error
);

export const setDemoAssessmentsIdRequest = createAction(
  adminConstants.SET_DEMO_ASSESSMENTS_ID_REQUEST,
  action => action
);
export const setDemoAssessmentsIdSuccess = createAction(
  adminConstants.SET_DEMO_ASSESSMENTS_ID_SUCCESS,
  authors => authors
);
export const setDemoAssessmentsIdFailed = createAction(
  adminConstants.SET_DEMO_ASSESSMENTS_ID_FAILED,
  error => error
);

export const getDemoAssessmentsIdRequest = createAction(
  adminConstants.GET_DEMO_ASSESSMENTS_ID_REQUEST,
  action => action
);
export const getDemoAssessmentsIdSuccess = createAction(
  adminConstants.GET_DEMO_ASSESSMENTS_ID_SUCCESS,
  authors => authors
);
export const getDemoAssessmentsIdFailed = createAction(
  adminConstants.GET_DEMO_ASSESSMENTS_ID_FAILED,
  error => error
);

export const noteEmployerRequest = createAction(
  adminConstants.NOTE_EMPLOYER_REQUEST,
  action => action
);
export const noteEmployerSuccess = createAction(adminConstants.NOTE_EMPLOYER_SUCCESS, data => data);
export const noteEmployerFailed = createAction(adminConstants.NOTE_EMPLOYER_FAILED, error => error);

export const deleteNoteEmployerRequest = createAction(
  adminConstants.DELETE_NOTE_EMPLOYER_REQUEST,
  action => action
);
export const deleteNoteEmployerSuccess = createAction(
  adminConstants.DELETE_NOTE_EMPLOYER_SUCCESS,
  data => data
);
export const deleteNoteEmployerFailed = createAction(
  adminConstants.DELETE_NOTE_EMPLOYER_FAILED,
  error => error
);

export const clearCompanyEmployerData = createAction(
  adminConstants.CLEAR_COMPANY_EMPLOYER_DATA,
  action => action
);

export const generateApplicationsRequest = createAction(
  adminConstants.GENERATE_APPLICATIONS_REQUEST,
  action => action
);
export const generateApplicationsSuccess = createAction(
  adminConstants.GENERATE_APPLICATIONS_SUCCESS,
  data => data
);
export const generateApplicationsFailure = createAction(
  adminConstants.GENERATE_APPLICATIONS_FAILURE,
  error => error
);
