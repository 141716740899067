import ImageModalCropper from 'components/ImageModalCropper';
import ImageUploader from 'components/ImageUploader';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useState } from 'react';
import * as routes from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompanyImageRequest, updateCompanyImageRequest } from 'store/auth/actions';
import { ErrorMessage, Form, Input, Typography } from 'ui-kit';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { draftAssessmentSchema } from 'components/Forms/validation';
import AntiCheatBlock from '../components/AntiCheatBlock';

export const CustomizationTab = ({ currentAssessment, onSubmit }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [logoSrc, setLogoSrc] = useState(null);
  const [logoData, setLogoData] = useState({ fileName: null, fileType: null });
  const [logoCropModal, setLogoCropModal] = useState(false);

  const logo = useSelector(store => store.auth.company.logo);

  function onSelectFile(file, fileStateMethod, dataStateMethod, openModalMethod) {
    if (file) {
      dataStateMethod({
        fileName: file.name,
        fileType: file.type
      });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        fileStateMethod(reader.result.toString() || '');
        openModalMethod(true);
      });
      reader.readAsDataURL(file);
    }
  }

  return (
    <>
      <Form
        id="summary"
        onBlur
        onSubmit={onSubmit}
        onUnMount={onSubmit}
        resolver={yupResolver(draftAssessmentSchema)}
        mode="all"
        defaultValues={{
          name:
            location.pathname === routes.CREATE_ASSESSMENT && currentAssessment?.name === 'Unnamed'
              ? ''
              : currentAssessment?.name || '',
          description: currentAssessment?.description || ''
        }}>
        {({ handleSubmit, setValue }) => (
          <div
            onMouseLeave={handleSubmit(onSubmit)}
            className="flex min-h-[479px] w-full gap-[48px] rounded-md bg-white p-6 shadow-med">
            <div className="flex flex-1 flex-col gap-6">
              <Typography variant="heading-h4" title="Branding" />
              <div className="w-full">
                <ImageUploader
                  iconClass="fa-solid fa-cloud-arrow-up text-[#BABEC3] text-[32px] mb-3"
                  customClass="h-[144px] w-full bg-[#FAFBFB] text-[14px] text-[#6D7175]
                items-center cursor-pointer
                hover:border-[#12A182] hover:bg-[#F3F8F6] hover:text-[#12A182]"
                  text="Company Logo"
                  title="Company Logo"
                  handleChange={file =>
                    onSelectFile(file, setLogoSrc, setLogoData, setLogoCropModal)
                  }
                  handleClear={() => {
                    dispatch(deleteCompanyImageRequest());
                  }}
                  imageSrc={!logoCropModal ? logo : null}
                  useConfirmModal={true}
                  contain={true}
                  deleteImageConfirmText="delete Company Logo?"
                  deleteHeaderConfirmText="Delete Company Logo"
                />
                {logoCropModal && (
                  <ImageModalCropper
                    modalClassName="max-w-[568px]"
                    title="Company Logo"
                    isOpen={logoCropModal}
                    options={{
                      fullSize: true
                    }}
                    close={() => {
                      setLogoCropModal(false);
                    }}
                    save={file => {
                      dispatch(updateCompanyImageRequest({ logo: { url: file } }));
                      setLogoCropModal(false);
                    }}
                    imgSrc={logoSrc}
                    fileType={logoData.fileType}
                    fileName={logoData.fileName}
                  />
                )}
              </div>
              <div className="flex flex-col gap-1">
                <Typography variant="regular-default" title="Assessment Name" />
                <Input maxLength={100} name="name" />
                <ErrorMessage className="m-0 p-0" name="name" />
              </div>
            </div>
            <div className="w-[1px] border-r border-[#D2D5D8]" />
            <div className="flex flex-1 flex-col gap-3">
              <div className="flex flex-col">
                <Typography variant="heading-h4" title="Intro Message" />
                <Typography
                  className="text-[#6D7175]"
                  variant="regular-default"
                  title="This message will be shown before the candidate takes the assessment."
                />
              </div>
              <RichTextEditor name="description" maxLength={1000} showLength />
            </div>
          </div>
        )}
      </Form>
      <AntiCheatBlock currentAssessment={currentAssessment} />
    </>
  );
};
