import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { Form, ErrorMessage, Input, Typography, Button } from 'ui-kit';

import { PasswordInput } from 'components/PasswordInput';
import { SignInSchema } from 'components/Forms/validation';
import { logInRequest, resetErrors } from 'store/auth/actions';
import authImage1x from 'assets/authImage@1x.png';
import authImage2x from 'assets/authImage@2x.png';
import { Logo } from 'ui-kit/Logo';
import * as routes from 'routes/routes';

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(store => store.auth);

  useEffect(() => () => dispatch(resetErrors()), []);

  const onSubmit = data => {
    dispatch(logInRequest({ data, navigate }));
  };

  return (
    <div className="flex h-full items-center justify-center">
      <img
        className="rounded-md"
        src={authImage1x}
        srcSet={`${authImage1x} 1x, ${authImage2x} 2x`}
      />
      <Form
        className="flex h-full max-w-[526px] flex-1 px-[64px] py-[32px]"
        mode="all"
        defaultValues={{ ...user }}
        onSubmit={onSubmit}
        resolver={yupResolver(SignInSchema)}>
        {({ formState }) => (
          <div className="flex w-full flex-col">
            <div className="mb-[33px] flex items-center justify-center">
              <Logo />
            </div>
            <div className="flex w-full flex-col gap-[8px]">
              <Typography className="text-center" variant="heading-h2" title="Log In to Hirenest" />
              <div>
                <Typography title="Email" variant="regular-default" />
                <Input name="email" />
                <ErrorMessage name="email" />
              </div>
              <div>
                <Typography title="Password" variant="regular-default" />
                <PasswordInput name="password" isShowBar={false} />
                <ErrorMessage name="password" />
              </div>
              <div className="flex flex-col items-center gap-[8px]">
                <Button
                  disabled={!formState.isDirty}
                  className="w-full"
                  type="submit"
                  variant="primary"
                  title="Log In"
                />
                <Typography variant="small-default" title="or" className="text-[#6D7175]" />
                <Link
                  className="text-base font-semibold text-[#007D6F] hover:text-[#12A182]"
                  to={routes.AUTH + '/' + routes.SIGNUP}>
                  Create Employer Account
                </Link>
                <div className="mt-[30px] text-sm font-normal text-[#6D7175]">
                  Forgot your password?{' '}
                  <Link
                    to={routes.AUTH + '/' + routes.RESET_PASSWORD}
                    className="text-sm font-normal text-[#007D6F] underline">
                    Reset Password
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default SignInForm;
