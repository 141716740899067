const Footer = ({ to = '/', ...props }) => {
  return (
    <a href={to}>
      <svg
        width="125"
        height="26"
        viewBox="0 0 125 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4199 2.81641H33.1894V10.8951C33.4665 10.477 33.7991 10.1147 34.2148 9.78021C34.9067 9.22362 35.9312 8.66617 37.3436 8.66617C40.3902 8.66617 42.6063 10.8672 42.6063 14.5159V22.3159H39.8369V14.5159C39.8369 12.5379 38.5898 11.3123 36.7903 11.3123C34.7127 11.3123 33.1894 12.8445 33.1894 15.0734V22.3159H30.4199V2.81641Z"
          fill="#565656"
        />
        <mask id="mask0_4840_13954" maskUnits="userSpaceOnUse" x="0" y="0" width="125" height="26">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 25.2002H125V0.200195H0V25.2002Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4840_13954)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.929 22.3121H48.6985V8.94108H45.929V22.3121ZM47.3142 3.08984C48.1724 3.08984 48.9761 3.78666 48.9761 4.76046C48.9761 5.736 48.1724 6.43282 47.3142 6.43282C46.4551 6.43282 45.6523 5.736 45.6523 4.76046C45.6523 3.78666 46.4551 3.08984 47.3142 3.08984Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.0225 8.93888H54.7919V10.7489C55.0968 10.3865 55.4293 10.025 55.8173 9.71844C56.4815 9.18887 57.4783 8.66016 58.8076 8.66016H59.5004V11.585L59.1402 11.5293C58.9185 11.5014 58.6968 11.4457 58.3928 11.4457C56.4815 11.4457 54.7919 13.1172 54.7919 15.4846V22.3099H52.0225V8.93888Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.5781 14.2377C70.1624 12.4277 68.944 11.1734 66.9773 11.1734C64.8729 11.1734 63.6544 12.4277 63.1002 14.2377H70.5781ZM72.795 20.0898C72.4347 20.5636 71.9636 20.9817 71.4094 21.3431C70.4403 21.9842 69.0833 22.5965 67.1166 22.5965C63.2395 22.5965 60.1929 19.5332 60.1929 15.6327C60.1929 11.7332 63.2395 8.66895 66.978 8.66895C70.7451 8.66895 73.4869 11.4266 73.4869 15.2155C73.4869 15.6327 73.4315 15.9672 73.4038 16.2172L73.3483 16.6083H63.1009C63.4057 18.4183 65.0122 19.9504 67.2551 19.9504C68.4459 19.9504 69.4158 19.5053 70.08 19.0593C70.468 18.8085 70.8283 18.5019 71.1331 18.1404L72.795 20.0898Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.8403 8.93888H78.6098V10.8882C78.8869 10.4701 79.2195 10.1087 79.6352 9.77419C80.3271 9.21674 81.3516 8.66016 82.764 8.66016C85.8106 8.66016 88.0268 10.8604 88.0268 14.5099V22.3099H85.2573V14.5099C85.2573 12.5318 84.0102 11.3063 82.2107 11.3063C80.1331 11.3063 78.6098 12.8384 78.6098 15.0665V22.3099H75.8403V8.93888Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.766 14.2377C100.35 12.4277 99.132 11.1734 97.1653 11.1734C95.0609 11.1734 93.8424 12.4277 93.2882 14.2377H100.766ZM102.983 20.0898C102.623 20.5636 102.152 20.9817 101.597 21.3431C100.628 21.9842 99.2713 22.5965 97.3046 22.5965C93.4275 22.5965 90.3809 19.5332 90.3809 15.6327C90.3809 11.7332 93.4275 8.66895 97.166 8.66895C100.933 8.66895 103.675 11.4266 103.675 15.2155C103.675 15.6327 103.619 15.9672 103.592 16.2172L103.536 16.6083H93.2889C93.5937 18.4183 95.2002 19.9504 97.4431 19.9504C98.6339 19.9504 99.6038 19.5053 100.268 19.0593C100.656 18.8085 101.016 18.5019 101.321 18.1404L102.983 20.0898Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.584 18.2701C106.861 18.6325 107.166 18.9391 107.525 19.1899C108.162 19.6359 108.965 20.081 110.046 20.081C111.597 20.081 112.4 19.2735 112.4 18.4095C112.4 16.1805 105.337 17.3233 105.337 12.5597C105.337 10.4701 107.166 8.66016 110.184 8.66016C111.763 8.66016 112.954 9.13312 113.729 9.57995C114.2 9.82994 114.588 10.1365 114.892 10.4701L113.093 12.2819C112.871 12.0859 112.621 11.8908 112.317 11.7244C111.791 11.4178 111.07 11.167 110.184 11.167C108.772 11.167 108.107 11.8629 108.107 12.5597C108.107 14.7878 115.169 13.6746 115.169 18.4095C115.169 20.6384 113.258 22.5878 110.046 22.5878C108.273 22.5878 106.999 21.9754 106.113 21.3344C105.587 20.9729 105.143 20.5548 104.783 20.081L106.584 18.2701Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.768 11.5875H116.276V8.94138H117.799C118.657 8.94138 119.045 8.52329 119.045 7.40926V4.48438H121.538V8.94138H124.584V11.5875H121.538V17.8554C121.538 19.2482 122.175 19.9441 123.477 19.9441C123.892 19.9441 124.252 19.8605 124.557 19.749L125 19.5269V22.173L124.418 22.396C124.058 22.5066 123.56 22.5903 122.922 22.5903C120.264 22.5903 118.768 21.059 118.768 18.1341V11.5875Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1053 1.46309L17.1045 1.46222C16.4792 1.02235 15.6184 1.17478 15.1811 1.80366L0 23.6088L2.26114 25.2019L7.17918 18.1379C8.12053 16.7843 10.1132 16.7843 11.0554 18.1379L15.9726 25.2019L18.2337 23.6088L12.8273 15.8427C12.1656 14.8925 11.1186 14.2862 9.96945 14.1887L17.4465 3.39501C17.8813 2.767 17.7289 1.90208 17.1053 1.46309Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.73634 10.5287L7.43025 8.09504L3.05173 1.80541C2.6144 1.17653 1.75272 1.0241 1.12833 1.46484C0.503942 1.90383 0.351525 2.76875 0.787991 3.39763L5.73634 10.5287Z"
            fill="#565656"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.11841 0.200195C9.97748 0.200195 10.7803 0.897011 10.7803 1.87081C10.7803 2.84635 9.97748 3.5423 9.11841 3.5423C8.2602 3.5423 7.45654 2.84635 7.45654 1.87081C7.45654 0.897011 8.2602 0.200195 9.11841 0.200195Z"
            fill="#565656"
          />
        </g>
      </svg>
    </a>
  );
};

export default Footer;
