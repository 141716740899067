import { Section, Typography, Icon } from 'ui-kit';
import { ChipList } from 'ui-kit/ChipList/ChipList';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import { getModifyData } from 'utils/helpers';
import TimeBadget from 'components/TimeBadget';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

export const TestViewSummary = ({ currentTest, role }) => (
  <>
    <div className="flex flex-2 flex-col gap-6">
      <Section>
        <Section.Body>
          <TextAreaView title={currentTest?.summary} variant="regular-default" />
        </Section.Body>
      </Section>
      {currentTest?.outcomes?.length > 0 && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title="Outcomes" />
          </Section.Header>
          <Section.Body>
            <TextAreaView title={currentTest?.outcomes} variant="regular-default" />
          </Section.Body>
        </Section>
      )}
      {currentTest?.whyMeasure?.text?.length > 0 && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title={`${currentTest?.whyMeasure?.title}`} />
          </Section.Header>
          <Section.Body>
            <TextAreaView title={currentTest?.whyMeasure?.text} variant="regular-default" />
          </Section.Body>
        </Section>
      )}
    </div>
    <div className="flex flex-1 flex-col gap-6">
      {role === 'admin' && (
        <Section>
          <Section.Header>
            <Typography variant="heading-h2" title="System Info" />
          </Section.Header>
          <Section.Body className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <Typography variant="regular-default" title="Author" className="text-[#6D7175]" />
              <Typography
                variant="regular-default"
                title={`${currentTest?.author?.firstName} ${currentTest?.author?.lastName}`}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="regular-default" title="Family ID" className="text-[#6D7175]" />
              <Typography variant="regular-default" title={currentTest?.version?.testFamily} />
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-1">
                <Typography variant="regular-default" title="Created On" className="text-[#6D7175]" />
                <Typography variant="regular-default" title={getModifyData(currentTest?.createdAt)} />
              </div>
              <div className="flex flex-col gap-1">
                <Typography variant="regular-default" title="Modified On" className="text-[#6D7175]" />
                <Typography
                  variant="regular-default"
                  title={currentTest?.updatedAt ? getModifyData(currentTest?.updatedAt) : '-'}
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col gap-1">
                <Typography
                  variant="regular-default"
                  title="Published On"
                  className="text-[#6D7175]"
                />
                <Typography
                  variant="regular-default"
                  title={currentTest?.publishedAt ? getModifyData(currentTest?.publishedAt) : '-'}
                />
              </div>
              <div className="flex flex-col gap-1">
                <Typography variant="regular-default" title="Archived On" className="text-[#6D7175]" />
                <Typography
                  variant="regular-default"
                  title={currentTest?.archivedAt ? getModifyData(currentTest?.archivedAt) : '-'}
                />
              </div>
            </div>
          </Section.Body>
        </Section>
      )}
      <Section>
        <Section.Header>
          <Typography variant="heading-h2" title="Details" />
        </Section.Header>
        <Section.Body className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <Typography variant="small-default" className="text-[#6D7175]" title="Time Limit" />
              <TimeBadget
                className="inline-flex w-auto font-semibold"
                time={currentTest?.timeLimit || '-'}
                icon={<Icon icon={faHourglassHalf} size={16} className="text-[#2F6E63]" />}
              />
            </div>
            <div className="flex flex-col gap-1">
              <Typography
                variant="small-default"
                className="text-[#6D7175]"
                title="Estimated Duration"
              />
              <TimeBadget
                className="inline-flex w-auto font-semibold"
                time={(currentTest?.estimatedDuration ?? currentTest?.timeLimit) || '-'}
                icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
              />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-1">
              <Typography variant="regular-default" title="Language" className="text-[#6D7175]" />
              <Typography variant="regular-default" title={currentTest?.language} />
            </div>
            <div className="flex flex-col gap-1">
              <Typography variant="regular-default" title="Difficulty" className="text-[#6D7175]" />
              <Typography
                variant="regular-default"
                className="capitalize"
                title={currentTest?.difficulty}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <Typography variant="regular-default" title="Category" className="text-[#6D7175]" />
            <Typography
              variant="regular-default"
              className="capitalize"
              title={currentTest.category?.name}
            />
          </div>
          {currentTest?.suitableFor?.length > 0 && (
            <div className="flex w-full flex-col gap-1">
              <Typography variant="regular-default" title="Suitable for" className="text-[#6D7175]" />
              <div className="flex flex-wrap gap-3">
                {currentTest?.suitableFor?.map(({ label, _id }) => (
                  <ChipList key={_id} name={label} />
                ))}
              </div>
            </div>
          )}
          {currentTest?.areasOfUse?.length > 0 && (
            <div className="flex w-full flex-col gap-1">
              <Typography variant="regular-default" title="Areas of Use" className="text-[#6D7175]" />
              <div className="flex flex-wrap gap-3">
                {currentTest?.areasOfUse?.map(({ label, _id }) => (
                  <ChipList key={_id} name={label} />
                ))}
              </div>
            </div>
          )}
        </Section.Body>
      </Section>
      {currentTest?.sections?.length > 1 && (
        <Section>
          <Section.Header>
            <Typography
              variant="heading-h2"
              title={
                (currentTest?.sections?.length > 1
                  ? currentTest?.displaySectionAs?.sectionPlural
                  : currentTest?.displaySectionAs?.sectionSingle) || 'Sections'
              }
            />
          </Section.Header>
          <Section.Body className="flex flex-col gap-4">
            {currentTest?.sections.map(({ name, resultInterpretation, _id }) => (
              <div key={_id} className="flex flex-col gap-[6px]">
                <Typography variant="heading-h5" title={name} />
                <TextAreaView
                  className="text-[#6D7175]"
                  title={resultInterpretation?.description}
                />
              </div>
            ))}
          </Section.Body>
        </Section>
      )}
    </div>
  </>
);
