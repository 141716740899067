import { localStorageService } from 'helpers/localStorageService';

const url = (paths, params = {}, isSearch = false) => {
  const role = localStorageService.getRole();
  const urlPath = paths.join('/');

  const filterParams = Object.entries(params).reduce(
    (acc, [key, value]) => (value || typeof value === 'number' ? (acc[key] = value) : acc, acc),
    {}
  );

  const urlParams = new URLSearchParams(filterParams).toString();

  if (role && !isSearch) {
    return role + '/' + urlPath.concat(urlParams && '?', urlParams);
  } else {
    return urlPath.concat(urlParams && '?', urlParams);
  }
};

export default url;
