import { useDispatch } from 'react-redux';
import { useModal } from 'hooks';

import { Button } from 'ui-kit';
import { QuestionModal } from '../QuestionModal/index.js';

import { createQuestion as createQuest } from 'store/tests/actions/index';
import { addCustomQuestion } from 'store/assessment/actions/index.js';
import { CustomQuestionCreate } from 'components/CustomQuestionCreate/CustomQuestionCreate.js';
import { localStorageService } from 'helpers/localStorageService.js';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

export const CreateQuestion = ({ id, tags, questionsLength }) => {
  const dispatch = useDispatch();
  const role = localStorageService.getRole();
  const { isOpen, close, open } = useModal({ initialState: false });

  const createQuestion = data => {
    if (role === 'author') {
      dispatch(createQuest({ sectionId: id, data }));
    } else if (role === 'employer') {
      dispatch(addCustomQuestion(data));
    }
  };

  return (
    <>
      {role === 'employer' ? (
        questionsLength > 0 && !isOpen ? (
          <Button
            onClick={open}
            className="flex items-center justify-center gap-3 rounded-lg border-2 border-dashed border-[#8C9196] fill-[#8C9196] p-9.5 font-semibold text-[#6D7175] hover:border-[#12A182] hover:bg-[#F3F8F6] hover:fill-[#12A182] hover:text-[#12A182]"
            iconStart={faPlus}
            iconSize={24}
            title="Create Question..."
          />
        ) : (
          <CustomQuestionCreate
            questionsLength={questionsLength}
            close={close}
            onSubmit={createQuestion}
          />
        )
      ) : (
        <>
          <Button
            onClick={open}
            className="flex items-center justify-center gap-3 rounded-lg border-2 border-dashed border-gray-300 fill-gray-400 p-9.5 font-semibold text-gray-400 hover:border-[#12A182] hover:bg-[#F3F8F6] hover:fill-[#12A182] hover:text-[#12A182]"
            iconStart={faPlus}
            iconSize={24}
            title="Create Question..."
          />
          <QuestionModal
            defaultValues={{}}
            type="create"
            tags={tags}
            needClear
            time={60000}
            onSubmit={createQuestion}
            isOpen={isOpen}
            close={close}
          />
        </>
      )}
    </>
  );
};
