import React from 'react';

import { Body } from './Body';
import { Header } from './Header';

import cn from 'utils/classNames';

interface SectionProps {
  className?: string;
  style?: any;
  children?: React.ReactNode;
}

interface SectionComposition {
  Header: typeof Header;
  Body: typeof Body;
}

const Section: React.FC<SectionProps> & SectionComposition = ({ className, style, children }) => {
  return (
    <section style={style} className={cn('bg-white rounded-md border border-[#C9CCCF]', className)}>
      {children}
    </section>
  );
};

Section.Header = Header;
Section.Body = Body;

export default Section;
