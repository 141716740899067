import 'chartjs-plugin-datalabels';
const axis: 'y' | 'x' | 'undefined' = 'y';
const position: 'right' | 'top' | 'bottom' | 'left' | 'center' | 'chartArea' = 'right';

export const optionsBarChart = (min: number, max: number) => ({
  indexAxis: axis,
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      suggestedMin: min,
      suggestedMax: max
    },
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  plugins: {
    legend: {
      display: false,
      position: position
    }
  }
});

export const optionsBarChartIsnide = (min: number, max: number) => ({
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      display: false // Hide Y axis labels
    },
    x: {
      suggestedMin: min,
      suggestedMax: max
    },
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  },
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: true,
      position: 'start',
      color: function (context: any) {
        if (
          context.chart.data.labels[context.dataIndex] === 'Test Overall' &&
          context.dataset.data[context.dataIndex] > 15
        ) {
          return 'white';
        } else {
          return 'black';
        }
      },
      anchor: 'start',
      align: 'end',
      font: {
        size: 14,
        weight: 500,
        family: 'Kumbh Sans, sans-serif;'
      },
      formatter: (value: any, context: any) => context.chart.data.labels[context.dataIndex]
    }
  },
  indexAxis: 'y'
});

export const optionsPolar = (min: number, max: number) => ({
  scale: {
    ticks: {
      stepSize: 1
    }
  },
  scales: {
    r: {
      min: min,
      max: max,
      ticks: {
        display: false
      }
    }
  }
});

export const optionsPolarWithotLegends = (min: number, max: number) => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }
  },
  scale: {
    ticks: {
      stepSize: 10
    }
  },
  scales: {
    r: {
      min: min,
      max: max,
      ticks: {
        display: true
      },
      pointLabels: {
        display: true,
        centerPointLabels: true,
        font: {
          size: 10,
          color: '#6D7175'
        }
      }
    }
  }
});
