export const steps = [
  {
    title: 'Select a Template',
    secondTitle:
      'Select an assessment template based on your job role to get started with creating your assessment.'
  },
  {
    title: 'Configure an Assessment',
    secondTitle:
      'Fine-tune or customize an assessment template to get the most accurate and relevant results for your hiring needs.'
  },
  {
    title: 'Invite Candidates',
    secondTitle: 'Publish your assessment and start inviting candidates to complete it.'
  }
];
