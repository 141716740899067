import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumbs from 'components/Breadcrumbs';
import { EditAdminSchema } from 'components/Forms/validation';
import Loader from 'components/Loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editAdminRequest, getAdminRequest } from 'store/configuration/actions/subscriptions';
import { Button, ErrorMessage, Form, Input, Typography } from 'ui-kit';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { callToast, getFullName, getModifyData } from 'utils/helpers';

const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Disabled', value: 'disabled' },
  { label: 'Invited', value: 'invited' }
];

export const EditAdmin = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { loading, currentAdmin } = useSelector(store => store.subscriptions);

  const onSubmit = data => {
    if (
      currentAdmin.status === 'invited' &&
      data.status !== 'disabled' &&
      data.status !== 'invited'
    ) {
      callToast('error', 'You can only switch to disabled from invited status');
      return;
    }
    if (
      currentAdmin.status === 'active' &&
      data.status !== 'disabled' &&
      data.status !== 'active'
    ) {
      callToast('error', 'You can only switch to disabled from active status');
      return;
    }
    if (
      currentAdmin.status === 'disabled' &&
      data.status !== 'active' &&
      data.status !== 'disabled'
    ) {
      callToast('error', 'You can only switch to active from disabled status');
      return;
    }
    dispatch(editAdminRequest({ id: currentAdmin?._id, data }));
  };

  useEffect(() => {
    dispatch(getAdminRequest(_id));
  }, [_id]);

  return (
    <div className="w-full px-6 py-9">
      {loading && <Loader />}
      <Breadcrumbs type="editAdmin" />
      <Typography
        title={getFullName({
          firstName: currentAdmin?.firstName,
          lastName: currentAdmin?.lastName
        })}
        variant="subheader"
      />
      <div className="mt-[24px] flex w-full gap-[24px]">
        <div className="flex-1 rounded-lg border border-[#C9CCCF] bg-[#FFFFFF] p-[32px]">
          <Form
            onSubmit={onSubmit}
            defaultValues={{
              ...currentAdmin
            }}
            resolver={yupResolver(EditAdminSchema)}
            mode="all">
            {({ formState }) => (
              <>
                <div>
                  <Typography title="First Name" variant="small-default" />
                  <Input name="firstName" />
                  <ErrorMessage name="firstName" />
                </div>
                <div>
                  <Typography title="Last Name" variant="small-default" />
                  <Input name="lastName" />
                  <ErrorMessage name="lastName" />
                </div>
                <div>
                  <Typography title="Email" variant="small-default" />
                  <Input name="email" />
                  <ErrorMessage name="email" />
                </div>
                <div>
                  <Typography title="Status" variant="small-default" />
                  <SelectCustom
                    name="status"
                    isClearable={false}
                    isSearchable={false}
                    options={statusOptions}
                  />
                </div>
                <Button
                  disabled={!formState.isDirty}
                  variant="primary"
                  type="submit"
                  title="Save Profile"
                  className="mt-[24px]"
                />
              </>
            )}
          </Form>
        </div>
        <div className="flex max-h-[112px] flex-1 justify-between rounded-lg border border-[#C9CCCF] bg-[#FFFFFF] p-[32px]">
          <div className="flex flex-col gap-[4px]">
            <Typography variant="small-default" className="text-[#6D7175]" title="Created On" />
            <Typography variant="regular-default" title={getModifyData(currentAdmin?.createdAt)} />
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography variant="small-default" className="text-[#6D7175]" title="Updated On" />
            <Typography variant="regular-default" title={getModifyData(currentAdmin?.updatedAt)} />
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography variant="small-default" className="text-[#6D7175]" title="Last Active On" />
            <Typography
              variant="regular-default"
              title={getModifyData(currentAdmin?.lastActivityAt)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
