import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'ui-kit';

import { createSection as createSect } from 'store/tests/actions/index';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

export const CreateSection: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => dispatch(createSect())}
      className="flex flex-1 items-center justify-center gap-3 rounded-lg border-2 border-dashed border-gray-300 bg-gray-100 fill-gray-400 p-9.5 font-semibold text-gray-400 hover:border-[#12A182] hover:bg-[#F3F8F6] hover:fill-[#12A182] hover:text-[#12A182]"
      iconStart={faPlus}
      iconSize={24}
      title="Create Section..."
    />
  );
};
