import Breadcrumbs from 'components/Breadcrumbs';
import { LinearProgressBar } from 'components/LinearProgressBar/LinearProgressBar';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Icon, Input, Modal, Section, Typography } from 'ui-kit';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import * as routes from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getAllOccupationsRequest,
  getImportStatusRequest,
  getLastImportStatusRequest,
  startImportRequest
} from 'store/configuration/actions/subscriptions';
import Loader from 'components/Loader';
import { getModifyData, secondsToHms } from 'utils/helpers';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons';

const DataProviderCard = ({ title, description }) => (
  <div className="flex flex-col gap-[4px]">
    <Typography variant="small-bold" title={title} />
    <Typography className="text-gray-500" variant="regular-emphasis" title={description} />
  </div>
);

export const Occupations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [importTime, setImportTime] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    importData,
    lastImportData,
    occupations,
    loading: loader
  } = useSelector(store => store.subscriptions);

  const handleImport = () => {
    dispatch(startImportRequest());
  };

  const calculateTime = data => {
    const now = moment.utc();
    const start = moment(data);
    setImportTime(
      moment
        .utc(moment(now, 'DD/MM/YYYY HH:mm:ss').diff(moment(start, 'DD/MM/YYYY HH:mm:ss')))
        .format('HH:mm:ss')
    );
  };

  const filteredOccupations = useMemo(() => {
    if (!occupations) {
      return;
    }
    return occupations
      .filter(({ title }) => title.toLowerCase().includes(searchInput.toLowerCase()))
      .filter(({ status }) => (statusFilter ? status === statusFilter : true));
  }, [occupations, searchInput, statusFilter]);

  useEffect(() => {
    let timer;
    let time;
    if (importData?.status === 'In Progress') {
      timer = setInterval(() => dispatch(getImportStatusRequest()), 5000);
      time = setInterval(() => calculateTime(importData?.startedAt), 1000);
    } else {
      clearInterval(timer);
      clearInterval(time);
      setImportTime('');
    }
    return () => {
      clearInterval(timer);
      clearInterval(time);
    };
  }, [importData?.status, importData?.timeSpent]);

  useEffect(() => {
    dispatch(getAllOccupationsRequest());
    dispatch(getLastImportStatusRequest());
    dispatch(getImportStatusRequest());
  }, []);
  return (
    <div className="flex w-full flex-col gap-6 px-6 py-9">
      <div>
        <Breadcrumbs />
        {loader && <Loader />}
        <div className="flex justify-between">
          <Typography title="Occupations" variant="subheader" />
          <Button
            variant="primary"
            title="Tests Mapping"
            onClick={() => navigate(routes.CONFIGURATION_TEST_MAPPING_ROUTE)}
          />
        </div>
      </div>
      <Section>
        <Section.Header className="flex items-center justify-between">
          <Typography variant="heading-h2" title="Data Provider" />
          <Button
            variant="primary-outline"
            onClick={() => setIsModalOpen(true)}
            disabled={importData?.status === 'In Progress'}
            title={
              importData?.status === 'In Progress' ? 'Cancel Import' : 'Import from Data Provider'
            }
          />
        </Section.Header>
        <Section.Body className="flex flex-col gap-[24px]">
          <div className="flex justify-between pr-[100px]">
            <DataProviderCard title="Data Source" description="onetonline.com" />
            <DataProviderCard
              title="Updated On"
              description={getModifyData(lastImportData?.updatedAt)}
            />
            <DataProviderCard
              title="Time Spent"
              description={secondsToHms(lastImportData?.timeSpent)}
            />
            <DataProviderCard title="Imported" description={lastImportData?.imported} />
            <DataProviderCard title="Updated" description={lastImportData?.updated} />
            <DataProviderCard title="Ignored" description={lastImportData?.ignored} />
          </div>
          {importData?.status === 'In Progress' && (
            <>
              <Typography variant="heading-h3" title="Importing Data..." />
              <LinearProgressBar
                percent={+((importData?.processed / importData?.found || 0) * 100)?.toFixed(0)}
              />
              <div className="flex justify-between pr-[100px]">
                <DataProviderCard
                  title="Started On"
                  description={getModifyData(importData?.startedAt)}
                />
                <DataProviderCard title="Time Elapsed" description={importTime || 'Loading...'} />
                <DataProviderCard title="Found" description={importData?.found} />
                <DataProviderCard
                  title="Processed"
                  description={`${importData?.processed} (${(
                    (importData?.processed / importData?.found || 0) * 100
                  )?.toFixed(0)}%)`}
                />
                <DataProviderCard
                  title="Queued"
                  description={importData?.found - importData?.processed}
                />
                <DataProviderCard title="Errors" description={importData?.errors} />
              </div>
              <Typography
                className="text-[#6D7175]"
                variant="small-default"
                title="You can leave the page, this will not stop the update process."
              />
            </>
          )}
        </Section.Body>
      </Section>
      <Section>
        <Section.Header className="flex items-center gap-[32px]">
          <div className="flex flex-1 gap-[10px]">
            <div className="flex rounded bg-[#E4E5E7] px-[8px] py-[4px]">{occupations?.length}</div>
            <Typography variant="heading-h2" title="Occupations" />
          </div>
          <Form className="flex gap-[12px]">
            <div className="min-w-[250px] flex-1">
              <SelectCustom
                options={[
                  { value: '', label: 'All' },
                  { value: 'Imported', label: 'Imported' },
                  { value: 'Updated', label: 'Updated' },
                  { value: 'No Changes', label: 'No Changes' }
                ]}
                onChangeValue={value => setStatusFilter(value.value)}
                isClearable={false}
                isSearchable={false}
                name="status"
                placeholder="Status"
              />
            </div>
            {/* <div className="flex-1">
              <SelectCustom name="templates" placeholder="Templates" />
            </div> */}
            {/* <div className="flex-1">
              <SelectCustom name="allBrightOutlook" placeholder="All Bright Outlook" />
            </div> */}
            <Input
              leftComponent={() => (
                <Icon className="ml-3" size={16} color="#8C9196" icon={faMagnifyingGlass} />
              )}
              name="search"
              onChangeValue={value => setSearchInput(value)}
              className="h-[44px] w-[250px]"
              placeholder="Search..."
            />
          </Form>
        </Section.Header>
        <Section.Body className="flex flex-col gap-[24px]">
          <div className="flex">
            <Typography className="flex-2" variant="regular-emphasis" title="Occupation" />
            <Typography className="flex-1" variant="regular-emphasis" title="Status" />
            {/* <Typography className="flex-1" variant="regular-emphasis" title="Templates" />
            <Typography className="flex-1" variant="regular-emphasis" title="Lists" /> */}
          </div>
          {filteredOccupations?.map(({ title, status, _id, templates, lists }) => (
            <div className="flex" key={_id}>
              <Typography
                className="flex-2 cursor-pointer pr-4 hover:text-green-400"
                onClick={() =>
                  navigate(routes.CONFIGURATION + '/' + routes.OCCUPATIONS + `/${_id}`)
                }
                variant="underline"
                title={title && title}
              />
              <Typography className="flex-1" variant="regular-default" title={status && status} />
              {/* <Typography
                className="flex-1"
                variant="regular-default"
                title={templates?.length || 'No Template'}
              />
              <div className="flex flex-1 flex-col gap-[8px]">
                {lists?.map(list => (
                  <ChipList key={list} name={list} />
                ))}
              </div> */}
            </div>
          ))}
        </Section.Body>
      </Section>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="m-auto w-full max-w-[600px]">
        <Modal.Header>
          <Typography
            variant="heading-h4"
            title={
              importData?.status === 'In Progress' ? 'Cancel Import' : 'Import from Data Provider'
            }
          />
          <Button
            icon={faXmark}
            iconSize={20}
            iconColor="#5C5F62"
            onClick={() => setIsModalOpen(false)}
          />
        </Modal.Header>
        <Modal.Body className="p-6">
          {importData?.status === 'In Progress' ? (
            <div>
              Are you sure you want to <b>cancel import</b> occupations data from
              <br />
              <b>onetonline.com</b>?
            </div>
          ) : (
            <div>
              Are you sure you want to import occupations data from
              <br /> <b>onetonline.com</b> ?
              <br />
              This process may take a while, and will continue running after closing the browser.
              <br />
              <br /> New occupations will be created, existing occupations will be updating, deleted
              occupations will be preserved.
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="flex justify-end gap-4">
          <Button
            variant="primary-outline"
            title={importData?.status === 'In Progress' ? 'Nevermind' : 'Cancel'}
            onClick={() => setIsModalOpen(false)}
          />
          <Button
            variant={importData?.status === 'In Progress' ? 'danger' : 'primary'}
            disabled={importData?.status === 'In Progress'}
            title={importData?.status === 'In Progress' ? 'Confirm Canceling' : 'Start Import'}
            onClick={() => {
              handleImport();
              setIsModalOpen(false);
            }}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};
