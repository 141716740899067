import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Navbar } from 'components/Navbar';
import Main from 'hocs/Main';
import Footer from 'components/Footer';
import { useEffect, useState } from 'react';
import { ModalPromoEmployer } from 'components/ModalPromoEmployer/ModalPromoEmployer';
import { useDispatch, useSelector } from 'react-redux';
import { useUserRole } from 'hooks';
import {
  getCompanyRequest,
  resendEmailRequest,
  setSuccessModalOpen,
  setUpgradeModalOpen
} from 'store/auth/actions';
import { generateSniperLink } from 'utils/gerenateSniperLink';
import * as routes from 'routes/routes';
import { navConfig } from 'utils/helpers';
import { errorConfig } from 'pages/Common/ErrorPage';
import { ErrorMain } from 'pages/Common/ErrorPage/ErrorMain';
import { localStorageService } from 'helpers/localStorageService';
import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import UpgradeSubscriptionModal from 'components/Modals/UpgradeSubscriptionModal/UpgradeSubscriptionModal';
import {
  getLastPublishAssessmentRequest,
  publishAssessmentWithoutConfirmation
} from 'store/assessment/actions';

export const Common = () => {
  const [completeProfileOpen, setCompleteProfileOpen] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [sniperLink, setSniperLink] = useState({ url: '', name: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { role } = useUserRole();
  const { dashboard } = navConfig(role);
  const { user, upgradeModalOpen, successModalOpen } = useSelector(store => store.auth);
  const { currentAssessment } = useSelector(store => store.assessments);
  const isCompleteProfile = localStorageService.getCompleteProfile();

  const adminError = useSelector(store => store.admin.error);
  const { lastPublishedAssessment } = useSelector(store => store.assessments);
  const assessmentsError = useSelector(store => store.assessments.error);
  const candidatesError = useSelector(store => store.candidates.error);
  const subscribtionsError = useSelector(store => store.subscriptions.error);
  const testsError = useSelector(store => store.tests.error);
  const currentUrl = window.location.pathname;

  const resendVerificationEmail = () => {
    dispatch(resendEmailRequest({ userId: localStorageService.getUserId() }));
  };

  useEffect(() => {
    if (role !== 'employer') return;
    dispatch(getLastPublishAssessmentRequest());
  }, [user, upgradeModalOpen]);

  useEffect(() => {
    if (user?.email) {
      setSniperLink(generateSniperLink(localStorageService.getDomain(), user?.email));
    }
  }, [user?.email]);

  useEffect(() => {
    if (role !== 'employer') return;
    if (user?.createdAt && !user?.verifiedAt && lastPublishedAssessment?._id) {
      setShowEmailVerification(true);
    }
    if (user?.createdAt && !user?.firstName && !isCompleteProfile) {
      // dispatch(getPublishedDemoAssessmetsRequest());
      setCompleteProfileOpen(true);
    }
  }, [user, role, pathname, isCompleteProfile, lastPublishedAssessment]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (role === 'employer') {
      dispatch(getCompanyRequest());
    }
  }, [pathname]);

  const publishAssessmentWithoutConfirm = () => {
    try {
      setTimeout(() => {
        dispatch(
          publishAssessmentWithoutConfirmation({
            data: {
              ...currentAssessment,
              questions: currentAssessment.customQuestions.sections[0].questions,
              tests: currentAssessment.tests.map(({ _id }) => _id)
            },
            assessmentId: currentAssessment?._id ?? null,
            navigate
          })
        );
      }, 0);
    } catch (e) {
      console.log('');
    }
  };

  const errorCode =
    testsError || adminError || assessmentsError || candidatesError || subscribtionsError;

  if (errorCode === 401) {
    localStorageService.clearStorage();
    localStorageService.setSession(true);
    navigate(routes.AUTH + '/' + routes.SIGNIN);
  }
  const error = errorCode ? errorConfig[errorCode] ?? errorConfig.default : null;

  return (
    <div
      style={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #FCFFFE 0%, #F8FAF5 48.96%, #FAFCF7 100%)'
      }}
      className="flex flex-col">
      <Navbar />
      {showEmailVerification && (
        <div className="flex min-h-[52px] items-center gap-3 border border-[#FFEBD3] bg-[#FFF5EA] px-[20px]">
          <i className="fa-solid fa-triangle-exclamation text-[#FFB027]"></i>
          <div className="flex gap-3 text-base">
            <p className="text-base">
              You have to activate your account. We’ve sent you a welcome email to{' '}
              <b>{user?.email}</b>.
            </p>
            {sniperLink?.url && (
              <a
                href={sniperLink?.url}
                target="_blank"
                rel="noopener noreferrer"
                className="cursor-pointer text-[#007D6F] underline">
                {`Open ${sniperLink?.name || 'email'}`}
              </a>
            )}

            <button
              onClick={resendVerificationEmail}
              type="button"
              className="cursor-pointer text-[#6D7175] underline">
              Resend email
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-1 flex-col">
        <Main className="flex-1">
          {error ? (
            <div className="flex h-screen flex-col justify-between">
              <main className="flex h-full flex-col items-center justify-center">
                <ErrorMain {...error} dashboard={dashboard} />
              </main>
            </div>
          ) : (
            <Outlet />
          )}
        </Main>
        {role === 'employer' && (
          <>
            <ModalPromoEmployer isOpen={completeProfileOpen} setIsOpen={setCompleteProfileOpen} />
          </>
        )}
        <Footer />
      </div>
      <UpgradeSubscriptionModal
        isModalOpen={upgradeModalOpen}
        setModalOpen={data => dispatch(setUpgradeModalOpen(data))}
        showArchiveOption={currentUrl.includes('/edit')}
        publishAssessmentWithoutConfirm={publishAssessmentWithoutConfirm}
      />
      <SuccessModal
        isModalOpen={successModalOpen}
        setModalOpen={data => dispatch(setSuccessModalOpen(data))}
      />
    </div>
  );
};
