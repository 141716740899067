import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentTestRequest } from 'store/tests/actions';
import Button from 'ui-kit/Button';
import Typography from 'ui-kit/Typography';
import { secondsToHms } from 'utils/helpers';

export const Preview = ({ ...props }) => {
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { idArrow, index } = props || {};
  const { _id, name, questionsCount, category, timeLimit } = props.data || {};

  const previewTestHandler = () => {
    setIsOpenModal(true);
    dispatch(getCurrentTestRequest(_id));
  };

  return (
    <>
      <div
        id={idArrow}
        style={{ wordBreak: 'break-word' }}
        className="relative flex w-[300px] min-w-[345px] flex-col justify-between break-words rounded-md bg-white  transition-all">
        <div className="rounded-t-xl bg-green-400 py-2.5 px-4 ">
          <Typography variant="regular" className="text-white" title={`Test #${index}`} />
        </div>
        <div className="space-y-4 rounded-b-md border border-t-0 border-gray-300 p-4">
          <div className="space-y-1">
            <Typography variant="heading-h3" className="truncate" title={name || 'In draft'} />
            <Typography
              variant="regular-emphasis"
              className="truncate text-gray-400"
              title={category?.name || category || 'Not selected'}
            />
          </div>
          <div className="flex justify-between">
            <div className="space-y-1">
              <Typography variant="small-default" title="Questions" />
              <Typography variant="regular-emphasis" className="text-gray-500" title={questionsCount} />
            </div>
            <div className="space-y-1">
              <Typography variant="small-default" title="Time Limit" />
              <Typography
                variant="regular-emphasis"
                className="text-gray-500"
                title={secondsToHms(timeLimit)}
              />
            </div>
          </div>
          <Button variant="primary-outline" onClick={previewTestHandler} title="Preview" />
        </div>
      </div>
      <ViewTestModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
};
