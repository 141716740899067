import { testConstants } from '../actions';

import { questionMove } from 'utils/helpers';

import { Section } from 'helpers/Section';

const SectionManage = new Section();

SectionManage.createSection();

const reviveRemoveTags = allTags => {
  let removeTags = [...allTags];

  const tags = SectionManage.getSections()
    .flatMap(section => section.questions)
    .flatMap(question => question.tags);

  allTags.map(tag => {
    const selectedTag = allTags.find(result => result.value === tag.value);
    const isExistQuestion = tags.some(result => result.value === tag.value);
    const isExistResult =
      typeof selectedTag.resultInterpretation !== 'undefined' || selectedTag.resultInterpretation
        ? Object.values(selectedTag.resultInterpretation).some(value => value.length > 0)
        : false;

    if (isExistQuestion || isExistResult) {
      removeTags = removeTags.filter(result => result.value !== tag.value);
    }
  });

  return removeTags;
};

const initialState = {
  loading: false,
  tests: [],
  recommendedTests: [],
  draftTests: [],
  publishedTests: [],
  archivedTests: [],
  draftTestsTotal: 0,
  publishedTestsTotal: 0,
  archivedTestsTotal: 0,
  draftTemplates: [],
  publishedTemplates: [],
  archivedTemplates: [],
  draftTemplatesTotal: 0,
  publishedTemplatesTotal: 0,
  archivedTemplatesTotal: 0,
  showDialog: false,
  jobRoles: {
    jobTitles: []
  },
  currentTemplate: {
    name: '',
    jobRoles: [],
    type: 'role_specific',
    totalTests: 0,
    recommendedTests: []
  },
  areasOfUse: [],
  suitableFor: [],
  displaySectionAs: [],
  currentTestVersions: [],
  currentTest: {
    isEmpty: SectionManage.getIsEmpty(),
    hidden: SectionManage.getHidden(),
    sections: SectionManage.getSections(),
    category: '',
    estimatedDurationPercent: 80,
    estimatedDuration: 0,
    tags: [],
    whyMeasure: {
      title: '',
      text: ''
    },
    attachment: '',
    icon: { url: null },
    cover: { url: null },
    evaluation: ['random', 'random', 'random', 'random', 'random'],
    recommendedScore: null,
    timeLimit: 0,
    questionsCount: 0,
    difficulty: 'intermediate',
    language: 'English',
    website: {},
    result: {
      calculationMethod: 'sum',
      includeToHirenestScore: false,
      chartSettings: {
        chartStyle: 'none',
        interpretationType: 'simple',
        includeTestOverall: true,
        includeSections: false,
        includeTags: false
      },
      resultInterpretation: {
        description: '',
        grades: []
      }
    }
  },
  total: 0
};

export function tests(state = initialState, action) {
  switch (action.type) {
    // RESET ERRORS - NO BACKEND
    case testConstants.RESET_ERRORS:
      return {
        ...state,
        error: null
      };

    // REQUEST ACTIONS
    case testConstants.GET_PUBLISHED_TESTS_REQUEST:
    case testConstants.GET_TESTS_BY_IMPORTANCE_REQUEST:
    case testConstants.GET_DRAFT_TESTS_REQUEST:
    case testConstants.GET_ARCHIVED_TESTS_REQUEST:
    case testConstants.GET_DRAFT_TEMPLATES_REQUEST:
    case testConstants.GET_PUBLISHED_TEMPLATES_REQUEST:
    case testConstants.GET_ARCHIVED_TEMPLATES_REQUEST:
    case testConstants.TEST_REQUEST_REQUEST:
    case testConstants.GET_TEST_VERSIONS_REQUEST:
    case testConstants.GET_AREAS_OF_USE_REQUEST:
    case testConstants.GET_SUITABLE_FOR_REQUEST:
    case testConstants.GET_SECTIONS_TAGS_REQUEST:
    case testConstants.GET_JOB_TITLES_REQUEST:
    case testConstants.GET_JOB_ROLES_REQUEST:
    case testConstants.GET_ALL_TESTS_REQUEST:
    case testConstants.UPLOAD_ATTACHMENT_REQUEST:
    case testConstants.DELETE_ATTACHMENT_REQUEST:
      return {
        ...state,
        loading: true
      };

    // REQUEST ACTIONS - HIDE MODAL
    case testConstants.CREATE_TEST_REQUEST:
    case testConstants.CREATE_TEMPLATE_REQUEST:
    case testConstants.EDIT_TEMPLATE_REQUEST:
    case testConstants.GET_TEMPLATE_REQUEST:
    case testConstants.PUBLISH_TEMPLATE_REQUEST:
    case testConstants.ARCHIVE_TEMPLATE_REQUEST:
    case testConstants.DRAFT_TEMPLATE_REQUEST:
    case testConstants.DELETE_DRAFT_TEST_REQUEST:
    case testConstants.CREATE_NEW_VERSION_TEST_REQUEST:
    case testConstants.UPDATE_TEST_REQUEST:
    case testConstants.GET_CURRENT_TEST_REQUEST:
      return {
        ...state,
        loading: true,
        showDialog: false
      };

    // SUCCESS ACTIONS
    case testConstants.GET_ALL_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTests: action.payload.tests,
        total: action.payload.total,
        error: null
      };
    case testConstants.GET_PUBLISHED_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        publishedTests: [...state.publishedTests, ...action.payload.tests],
        publishedTestsTotal: action.payload.total,
        error: null
      };
    case testConstants.GET_TESTS_BY_IMPORTANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendedTests: action.payload.tests,
        error: null
      };
    case testConstants.GET_DRAFT_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        draftTests: [...state.draftTests, ...action.payload.tests],
        draftTestsTotal: action.payload.total,
        error: null
      };
    case testConstants.GET_ARCHIVED_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        archivedTests: [...state.archivedTests, ...action.payload.tests],
        archivedTestsTotal: action.payload.total,
        error: null
      };
    case testConstants.GET_DRAFT_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        draftTemplates: [...state.draftTemplates, ...action.payload.assessmentTemplates],
        draftTemplatesTotal: action.payload.total,
        error: null
      };
    case testConstants.GET_PUBLISHED_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        publishedTemplates: [...state.publishedTemplates, ...action.payload.assessmentTemplates],
        publishedTemplatesTotal: action.payload.total,
        error: null
      };
    case testConstants.GET_ARCHIVED_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        archivedTemplates: [...state.archivedTemplates, ...action.payload.assessmentTemplates],
        archivedTemplatesTotal: action.payload.total,
        error: null
      };
    case testConstants.GET_CURRENT_TEST_SUCCESS:
      SectionManage.init(action.payload.sections);
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTest: { ...action.payload, hidden: SectionManage.getHidden() },
        error: null
      };
    case testConstants.CREATE_TEST_SUCCESS: {
      SectionManage.init(action.payload.sections);
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTest: { ...action.payload, hidden: SectionManage.getHidden() },
        error: null
      };
    }
    case testConstants.TEST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case testConstants.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTemplate: {
          ...state.currentTemplate,
          ...action.payload
        },
        error: null
      };
    case testConstants.EDIT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTemplate: {
          ...state.currentTemplate,
          ...action.payload
        },
        error: null
      };
    case testConstants.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTemplate: {
          ...state.currentTemplate,
          ...action.payload
        },
        error: null
      };
    case testConstants.PUBLISH_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTemplate: action.payload,
        error: null
      };
    case testConstants.ARCHIVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTemplate: action.payload,
        error: null
      };
    case testConstants.DRAFT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTemplate: {
          ...state.currentTemplate,
          ...action.payload
        },
        error: null
      };
    case testConstants.DELETE_DRAFT_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        error: null
      };
    case testConstants.CREATE_NEW_VERSION_TEST_SUCCESS: {
      SectionManage.init(action.payload.sections);
      return {
        ...state,
        loading: false,
        showDialog: false,
        currentTest: { ...action.payload, hidden: SectionManage.getHidden() },
        error: null
      };
    }
    case testConstants.GET_TEST_VERSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTestVersions: action.payload,
        error: null
      };
    case testConstants.UPDATE_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        showDialog: false,
        error: null
      };
    case testConstants.GET_AREAS_OF_USE_SUCCESS:
      return {
        ...state,
        loading: false,
        areasOfUse: action.payload,
        error: null
      };
    case testConstants.UPDATE_AREAS_OF_USE:
      return {
        ...state,
        areasOfUse: [...state.areasOfUse, ...action.payload],
        error: null
      };
    case testConstants.GET_SUITABLE_FOR_SUCCESS:
      return {
        ...state,
        loading: false,
        suitableFor: action.payload,
        error: null
      };
    case testConstants.UPDATE_SUITABLE_FOR:
      return {
        ...state,
        suitableFor: [...state.suitableFor, ...action.payload],
        error: null
      };
    case testConstants.GET_SECTIONS_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        displaySectionAs: action.payload,
        error: null
      };
    case testConstants.GET_JOB_TITLES_SUCCESS:
      return {
        ...state,
        jobRoles: {
          ...state.jobRoles,
          jobTitles:
            state.jobRoles.jobTitles.length > 0
              ? [...state.jobRoles.jobTitles, ...action.payload.jobTitles]
              : action.payload.jobTitles
        },
        error: null
      };
    case testConstants.GET_JOB_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        jobRoles: { jobTitles: action.payload },
        error: null
      };
    //ACTION FOR DEV - NO BACKEND ===================

    case testConstants.RESET_JOB_ROLES:
      return {
        ...state,
        jobRoles: {}
      };
    case testConstants.EDIT_TEST:
      return {
        ...state,
        newTest: action.payload
      };
    case testConstants.CLEAR_JOB_TITLES:
      return {
        ...state,
        jobRoles: {
          ...state.jobRoles,
          jobTitles: []
        }
      };
    case testConstants.SAVE_TEMPLATES_SUMMARY:
      return {
        ...state,
        showDialog: true,
        currentTemplate: { ...state.currentTemplate, ...action.payload }
      };
    case testConstants.ADD_TEST_TO_TEMPLATE: {
      const { test } = action.payload;
      return {
        ...state,
        showDialog: true,
        currentTemplate: {
          ...state.currentTemplate,
          totalTests: state.currentTemplate.totalTests + 1,
          recommendedTests: [...state.currentTemplate.recommendedTests, test]
        }
      };
    }
    case testConstants.REMOVE_TEST_FROM_TEMPLATE: {
      const { test } = action.payload;
      return {
        ...state,
        showDialog: true,
        currentTemplate: {
          ...state.currentTemplate,
          totalTests: state.currentTemplate.totalTests - 1,
          recommendedTests: state.currentTemplate.recommendedTests.filter(({ _id }) => _id !== test)
        }
      };
    }
    case testConstants.AUTOFILL_TEMPLATE: {
      const { occupation } = action.payload;
      const recommendedTests = [...state.currentTemplate.recommendedTests];

      const fillTemplate = (resource, occupationResource) => {
        const maxImportance = resource.items
          ? resource.items[0].importance
          : resource[0].importance;
        if (!maxImportance) return false;

        const items = resource.items ?? resource;
        items.forEach(item => {
          if (!item.mappedTests[0] || !item.mappedTests[0]?._id) return false;
          if (recommendedTests.find(test => test._id === item.mappedTests[0]._id)) return false;

          const importance = parseInt(item.importance, 10);
          if (maxImportance - importance > 10) return false;

          const id = item.mappedTests[0].type.value;

          const test = {
            _id: item.mappedTests[0]._id,
            name: item.mappedTests[0].name,
            timeLimit: item.mappedTests[0].timeLimit,
            tagline: item.mappedTests[0].tagline,
            occupationResource: occupationResource,
            importance: item.importance
          };

          recommendedTests.push(test);
        });
      };

      fillTemplate(occupation.skills, 'Skills');
      fillTemplate(occupation.technology_skills, 'Technology Skills');
      fillTemplate(occupation.abilities, 'Abilities');
      fillTemplate(occupation.work_styles, 'Work Styles');
      fillTemplate(occupation.knowledge, 'Knowledge');
      fillTemplate(occupation.work_activities, 'Work Activities');

      return {
        ...state,
        showDialog: true,
        currentTemplate: {
          ...state.currentTemplate,
          totalTests: recommendedTests.length,
          recommendedTests: [...recommendedTests]
        }
      };
    }
    case testConstants.RESET_TEST_DATA:
      return {
        ...state,
        currentTest: {
          isEmpty: SectionManage.getIsEmpty(),
          hidden: SectionManage.getHidden(),
          sections: SectionManage.getSections(),
          tags: [],
          evaluation: ['random', 'random', 'random', 'random', 'random'],
          recommendedScore: null,
          whyMeasure: {
            title: '',
            text: ''
          },
          attachment: '',
          timeLimit: 0,
          questionsCount: 0,
          difficulty: 'intermediate',
          language: 'English',
          result: {
            calculationMethod: 'sum',
            includeToHirenestScore: false,
            chartSettings: {
              chartStyle: 'none',
              interpretationType: 'simple',
              includeTestOverall: true,
              includeSections: false,
              includeTags: false
            },
            resultInterpretation: {
              description: '',
              grades: []
            }
          }
        }
      };
    case testConstants.CLEAR_TESTS_PAGINATION:
      return {
        ...state,
        draftTests: state.draftTests?.slice(0, 30),
        publishedTests: state.publishedTests?.slice(0, 30),
        archivedTests: state.archivedTests?.slice(0, 30)
      };
    case testConstants.CLEAR_TESTS:
      return {
        ...state,
        draftTests: [],
        publishedTests: [],
        archivedTests: [],
        publishedTestsTotal: 0,
        draftTestsTotal: 0,
        archivedTestsTotal: 0
      };
    case testConstants.CLEAR_TEMPLATES_PAGINATION:
      return {
        ...state,
        draftTemplates: state.draftTemplates?.slice(0, 30),
        publishedTemplates: state.publishedTemplates?.slice(0, 30),
        archivedTemplates: state.archivedTemplates?.slice(0, 30)
      };
    case testConstants.CLEAR_TEMPLATES:
      return {
        ...state,
        draftTemplates: [],
        publishedTemplates: [],
        archivedTemplates: []
      };
    case testConstants.CLEAR_TEMPLATE:
      return {
        ...state,
        currentTemplate: {
          name: '',
          jobRoles: [],
          type: 'role_specific',
          totalTests: 0,
          recommendedTests: []
        }
      };
    case testConstants.CHANGE_CALC_METHOD:
      return {
        ...state,
        currentTest: {
          ...state.currentTest,
          result: { ...state.currentTest.result, calculationMethod: action.payload }
        }
      };
    case testConstants.CHANGE_INCLUDE_TO_HIRENEST_SCORE:
      return {
        ...state,
        currentTest: {
          ...state.currentTest,
          result: { ...state.currentTest.result, includeToHirenestScore: action.payload }
        }
      };
    case testConstants.UPDATE_TEST:
      return {
        ...state,
        newTest: { ...state.newTest, ...action.payload }
      };
    case testConstants.DELETE_TEST: {
      const newAllTests = state.allTests.filter(item => item.queueId !== action.payload);
      return {
        ...state,
        allTests: newAllTests
      };
    }
    case testConstants.ADD_TEST_QUESTION:
      return {
        ...state,
        newTest: {
          ...state.newTest.questions,
          questions: [...state.newTest.questions, ...state.newTestQuestion]
        },
        newTestQuestion: null
      };
    case testConstants.DUBLICATE_TEST_QUESTION: {
      const dublicatedQuestion = state.newTest.questions.filter(
        item => item.queueId === action.payload
      )[0];
      const newId =
        state.newTest.questions.sort((a, b) => a.queueId - b.queueId).at(-1).queueId + 1;
      const newQuestion = { ...dublicatedQuestion, queueId: newId };
      return {
        ...state,
        newTest: { ...state.newTest, questions: [...state.newTest.questions, newQuestion] }
      };
    }
    case testConstants.DELETE_TEST_QUESTION: {
      const newQuestions = state.newTest.questions.filter(item => item.queueId !== action.payload);
      return {
        ...state,
        newTest: { ...state.newTest, questions: newQuestions }
      };
    }
    case testConstants.MOVE_UP_TEST_QUESTION:
      return {
        ...state,
        newTest: {
          ...state.newTest,
          questions: [...questionMove(state.newTest.questions, action.payload, action.payload - 1)]
        }
      };
    case testConstants.MOVE_DOWN_TEST_QUESTION:
      return {
        ...state,
        newTest: {
          ...state.newTest,
          questions: [...questionMove(state.newTest.questions, action.payload, action.payload + 1)]
        }
      };
    case testConstants.CREATE_SECTION: {
      SectionManage.createSection();
      return { ...state, currentTest: { ...state.currentTest, ...SectionManage.getAll() } };
    }
    case testConstants.DELETE_SECTION: {
      SectionManage.deleteSection(action.payload.sectionId);
      return { ...state, currentTest: { ...state.currentTest, ...SectionManage.getAll() } };
    }
    case testConstants.UPDATE_SECTION: {
      SectionManage.updateSection(action.payload.idx, action.payload.data);
      return { ...state, currentTest: { ...state.currentTest, ...SectionManage.getAll() } };
    }
    case testConstants.UPDATE_RESULT_TAGS: {
      const updatedTags = state.currentTest.tags.map((tag, idx) =>
        idx === action.payload.idx ? { ...tag, ...action.payload.data } : tag
      );
      const removeTags = reviveRemoveTags(updatedTags);
      return {
        ...state,
        currentTest: {
          ...state.currentTest,
          tags: updatedTags.filter(tag => !removeTags.some(result => result.value === tag.value))
        }
      };
    }
    case testConstants.CREATE_QUESTION: {
      const exist = new Set(state.currentTest.tags.map(d => d.value));
      SectionManage.createQuestion(action.payload);
      const allSections = SectionManage.getAll();
      return {
        ...state,
        currentTest: {
          ...state.currentTest,
          ...allSections,
          estimatedDuration:
            (allSections.timeLimit * state.currentTest.estimatedDurationPercent) / 100,
          tags: [
            ...state.currentTest.tags,
            ...action.payload.data.tags.filter(d => !exist.has(d.value))
          ]
        }
      };
    }
    case testConstants.SWAP_QUESTION: {
      SectionManage.swapQuestion(action.payload.sectionId, action.payload.from, action.payload.to);
      return { ...state, currentTest: { ...state.currentTest, ...SectionManage.getAll() } };
    }
    case testConstants.DELETE_QUESTION: {
      SectionManage.deleteQuestion(action.payload.sectionId, action.payload.idx);
      const removeTags = reviveRemoveTags(state.currentTest.tags);
      const allSections = SectionManage.getAll();
      return {
        ...state,
        currentTest: {
          ...state.currentTest,
          tags: state.currentTest.tags.filter(
            tag => !removeTags.some(result => result.value === tag.value)
          ),
          ...allSections,
          estimatedDuration:
            (allSections.timeLimit * state.currentTest.estimatedDurationPercent) / 100
        }
      };
    }
    case testConstants.UPDATE_QUESTION: {
      const exist = new Set(state.currentTest.tags.map(d => d.value));
      SectionManage.editQuestion(action.payload.sectionId, action.payload.idx, action.payload.data);
      const removeTags = reviveRemoveTags(state.currentTest.tags);
      const allSections = SectionManage.getAll();
      return {
        ...state,
        currentTest: {
          ...state.currentTest,
          tags: [
            ...state.currentTest.tags.filter(
              tag => !removeTags.some(result => result.value === tag.value)
            ),
            ...action.payload.data.tags.filter(d => !exist.has(d.value))
          ],
          ...allSections,
          estimatedDuration:
            (allSections.timeLimit * state.currentTest.estimatedDurationPercent) / 100
        }
      };
    }
    case testConstants.DUPLICATE_QUESTION: {
      SectionManage.duplicateQuestion(action.payload.sectionId, action.payload.idx);
      const allSections = SectionManage.getAll();
      return {
        ...state,
        currentTest: {
          ...state.currentTest,
          ...allSections,
          estimatedDuration:
            (allSections.timeLimit * state.currentTest.estimatedDurationPercent) / 100
        }
      };
    }
    case testConstants.SWAP_SECTION: {
      SectionManage.swapSection(action.payload.from, action.payload.to);
      return { ...state, currentTest: { ...state.currentTest, ...SectionManage.getAll() } };
    }
    case testConstants.SAVE_SUMMARY:
      return {
        ...state,
        showDialog: true,
        currentTest: { ...state.currentTest, ...action.payload }
      };
    case testConstants.SAVE_SETTINGS:
      return {
        ...state,
        showDialog: true,
        currentTest: {
          ...state.currentTest,
          result: { ...state.currentTest.result, ...action.payload }
        }
      };
    case testConstants.SAVE_WEBSITE_SETTINGS:
      return {
        ...state,
        showDialog: true,
        currentTest: {
          ...state.currentTest,
          website: { ...state.currentTest.website, ...action.payload }
        }
      };
    case testConstants.UPDATE_CHART_SETTINGS:
      return {
        ...state,
        showDialog: true,
        currentTest: {
          ...state.currentTest,
          result: {
            ...state.currentTest.result,
            calculationMethod:
              action.payload.chartSettings.interpretationType === 'dimension'
                ? 'avg'
                : state.currentTest.result.calculationMethod,
            chartSettings: {
              ...state.currentTest.result.chartSettings,
              ...action.payload.chartSettings
            }
          }
        }
      };
    case testConstants.UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case testConstants.UPLOAD_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        currentTest: { ...state.currentTest, attachment: '' }
      };
    case testConstants.DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTest: { ...state.currentTest, attachment: '' }
      };
    case testConstants.DELETE_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        currentTest: { ...state.currentTest, attachment: '' }
      };
    case testConstants.INIT_EDIT_TEST: {
      SectionManage.init(action.payload.sections);

      return {
        ...state,
        currentTest: { ...state.currentTest, ...SectionManage.getAll() }
      };
    }
    // TODO remove this f*cking sh*t and spread initial state. sorry)
    case testConstants.CLEAR_EDIT_TEST: {
      SectionManage.clear();
      SectionManage.createSection();
      return {
        loading: false,
        tests: [],
        recommendedTests: [],
        draftTests: [],
        publishedTests: [],
        archivedTests: [],
        draftTestsTotal: 0,
        publishedTestsTotal: 0,
        archivedTestsTotal: 0,
        showDialog: false,
        areasOfUse: [],
        suitableFor: [],
        displaySectionAs: [],
        jobRoles: {
          jobTitles: []
        },
        currentTemplate: {
          name: '',
          jobRoles: [],
          type: 'role_specific',
          totalTests: 0,
          recommendedTests: []
        },
        currentTest: {
          isEmpty: SectionManage.getIsEmpty(),
          hidden: SectionManage.getHidden(),
          sections: SectionManage.getSections(),
          category: '',
          tags: [],
          evaluation: ['random', 'random', 'random', 'random', 'random'],
          recommendedScore: null,
          estimatedDurationPercent: 80,
          estimatedDuration: 0,
          whyMeasure: {
            title: '',
            text: ''
          },
          attachment: '',
          icon: { url: null },
          cover: { url: null },
          timeLimit: 0,
          questionsCount: 0,
          difficulty: 'intermediate',
          language: 'English',
          result: {
            calculationMethod: 'sum',
            includeToHirenestScore: false,
            chartSettings: {
              chartStyle: 'none',
              interpretationType: 'simple',
              includeTestOverall: true,
              includeSections: false,
              includeTags: false
            },
            resultInterpretation: {
              description: '',
              grades: []
            }
          }
        },
        total: 0
      };
    }

    // FAIL ACTIONS
    case testConstants.GET_PUBLISHED_TESTS_FAILURE:
    case testConstants.GET_TESTS_BY_IMPORTANCE_FAILURE:
    case testConstants.GET_DRAFT_TESTS_FAILURE:
    case testConstants.GET_ARCHIVED_TESTS_FAILURE:
    case testConstants.GET_DRAFT_TEMPLATES_FAILURE:
    case testConstants.GET_PUBLISHED_TEMPLATES_FAILURE:
    case testConstants.GET_ARCHIVED_TEMPLATES_FAILURE:
    case testConstants.GET_CURRENT_TEST_FAILURE:
    case testConstants.CREATE_TEST_FAILURE:
    case testConstants.TEST_REQUEST_FAILURE:
    case testConstants.CREATE_TEMPLATE_FAILURE:
    case testConstants.EDIT_TEMPLATE_FAILURE:
    case testConstants.GET_TEMPLATE_FAILURE:
    case testConstants.PUBLISH_TEMPLATE_FAILURE:
    case testConstants.ARCHIVE_TEMPLATE_FAILURE:
    case testConstants.DRAFT_TEMPLATE_FAILURE:
    case testConstants.DELETE_DRAFT_TEST_FAILURE:
    case testConstants.CREATE_NEW_VERSION_TEST_FAILURE:
    case testConstants.GET_TEST_VERSIONS_FAILURE:
    case testConstants.UPDATE_TEST_FAILURE:
    case testConstants.GET_AREAS_OF_USE_FAILURE:
    case testConstants.GET_SUITABLE_FOR_FAILURE:
    case testConstants.GET_SECTIONS_TAGS_FAILURE:
    case testConstants.GET_JOB_TITLES_FAILURE:
    case testConstants.GET_JOB_ROLES_FAILURE:
    case testConstants.PUBLISH_TEST_FAILURE:
    case testConstants.ARCHIVE_TEST_FAILURE:
    case testConstants.GET_ALL_TESTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    default:
      return state;
  }
}
