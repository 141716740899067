import { createAction } from 'redux-actions';

export const deviceConstants = {
  SET_DEFAULT_MIC: 'SET_DEFAUT_MIC',
  SET_DEFAULT_CAMERA: 'SET_DEFAUT_CAMERA',
  SET_ERRORS_DEVICE: 'SET_ERRORS_DEVICE'
};

export const setDefaultMic = createAction(deviceConstants.SET_DEFAULT_MIC, payload => payload);

export const setDefaultCamera = createAction(
  deviceConstants.SET_DEFAULT_CAMERA,
  payload => payload
);

export const setErrorsDevice = createAction(deviceConstants.SET_ERRORS_DEVICE, errors => errors);
