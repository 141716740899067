import { QuestionType } from 'components/Question';
import TimeBadget from 'components/TimeBadget';
import ViewTests from 'pages/Employer/CreateAssessment_old/components/ViewTests/ViewTests';
import { Button, List, Section, Typography, Icon } from 'ui-kit';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';
import { ToolTipsApplicationBody } from 'components/SingleApplication/SingleApplicatonBody/ToolTipsApplicationBody/ToolTipsApplicationBody';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

export const ReviewTab = ({ currentAssessment, setSelectedIndex }) => {
  const testTime = currentAssessment?.tests.reduce(
    (acc, cur) => acc + (cur.estimatedDuration ?? cur.timeLimit),
    0
  );
  const customQuestionsTime = currentAssessment?.customQuestions?.sections[0]?.questions.reduce(
    (acc, cur) => acc + cur.timeLimit,
    0
  );
  return (
    <div className="flex flex-col gap-9">
      {currentAssessment.tests?.length !== 0 && (
        <ViewTests
          tests={currentAssessment?.tests}
          timeLimit={testTime}
          setSelectedIndex={setSelectedIndex}
        />
      )}
      {currentAssessment?.customQuestions?.sections[0]?.questions?.length !== 0 && (
        <Section className="border-none shadow-med">
          <Section.Body className="m-0 flex flex-col gap-6 p-8">
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-[6px]">
                <Typography
                  style={{ color: '#082623' }}
                  variant="heading-h4"
                  title={`${currentAssessment?.customQuestions?.sections[0]?.questions?.length} Custom Questions`}
                />
                <TimeBadget
                  className="inline-flex w-auto font-semibold"
                  time={customQuestionsTime}
                  icon={<Icon icon={faHourglassHalf} size={16} className="text-[#2F6E63]" />}
                />
              </div>
              <Button variant="link" title="Edit" onClick={() => setSelectedIndex(2)} />
            </div>
            <List
              data={currentAssessment?.customQuestions?.sections[0]?.questions}
              element={({ timeLimit, tags, type, index, ...props }, idx) => (
                <div className="flex flex-col rounded border border-[#C9CCCF]">
                  <div className="flex justify-between rounded-t bg-[#F6F6F7] py-3 pl-6 pr-3">
                    <div className="flex items-center gap-[16px]">
                      <div className="flex gap-1">
                        <p className="text-[#2F6E63]">{props?.index || idx + 1}</p>
                        <p className="text-[#8C9196]">
                          / {currentAssessment?.customQuestions?.sections[0]?.questions?.length}
                        </p>
                      </div>
                      <TimeBadget time={timeLimit} />
                    </div>
                  </div>
                  <div className="px-[24px] py-[18px]">
                    <QuestionType type={type} {...props} />
                  </div>
                </div>
              )}
              keyExtractor={(_, idx) => idx}
              wrap="div"
              className="space-y-3"
            />
          </Section.Body>
        </Section>
      )}
      <div className="flex gap-[36px]">
        {currentAssessment?.description && (
          <Section className="flex-1 border-none shadow-med">
            <Section.Body className="m-0 flex flex-col gap-6 p-8">
              <div className="flex items-center gap-3">
                <Typography
                  style={{ color: '#082623' }}
                  variant="heading-h4"
                  title="Preview Intro Message"
                />
                <Button variant="link" title="Edit" onClick={() => setSelectedIndex(3)} />
              </div>
              <TextAreaView title={currentAssessment?.description} />
            </Section.Body>
          </Section>
        )}
        <Section className="flex-1 border-none shadow-med">
          <ToolTipsApplicationBody />
          <Section.Body className="m-0 flex flex-col gap-8 p-8">
            <Typography variant="heading-h4" title="Anti-Cheating Monitor" />
            <div className="flex flex-col gap-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Webcam" />
                </div>
                <div
                  className={`flex h-[24px] w-[32px] items-center justify-center rounded ${
                    currentAssessment.antiCheat?.enableCamera ? 'bg-[#D1E7DD]' : 'bg-[#F8D7DA]'
                  } ${
                    currentAssessment.antiCheat?.enableCamera ? 'text-[#051B11]' : 'text-[#2C0B0E]'
                  } px-[6px] py-[3px] text-xs font-bold shadow-small`}>
                  {currentAssessment.antiCheat?.enableCamera ? 'ON' : 'OFF'}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Monitor IP" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="monitorIp-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Unique IP" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="uniqueIp-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Email Similarity" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="emailSimilarity-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Browser Fingerprint" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="browserFingerprint-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Assessment Completion Time" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="assessmentCompletionTime-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Test Completion Time" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="testCompletionTime-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Full-screen mode always active" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="fullScreen-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Mouse always is the assessment window" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="mouseInAssessmentWindow-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-[8px]">
                  <Typography variant="heading-h6" title="Paste Detection" />
                  <Icon
                    className="cursor-pointer"
                    data-tooltip-id="pasteDetection-tooltip"
                    data-tooltip-place="top"
                    icon={faCircleInfo}
                    size={16}
                    color="#8C9196"
                  />
                </div>
                <div className="flex h-[24px] items-center justify-center rounded bg-[#D1E7DD] px-[6px] py-[3px] text-xs font-bold text-[#051B11] shadow-small">
                  ALWAYS ON
                </div>
              </div>
            </div>
          </Section.Body>
        </Section>
      </div>
    </div>
  );
};
