import { yupResolver } from '@hookform/resolvers/yup';
import { createSectionSchema } from 'components/Forms/validation';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSection, deleteSection as deleteSect } from 'store/tests/actions';

import { Form, Icon, Input, List, Toggle, Typography } from 'ui-kit';
import { ErrorMessage } from '@hookform/error-message';
import { CreateQuestion } from '../CreateQuestion';
import { Question } from '../Question';
import { QuestionSectionHeader } from '../QuestionSectionHeader';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { variants } from 'ui-kit/Button/variants';
import { twMerge } from 'tailwind-merge';

interface QuestionSectionProps {
  sectionId: number;
  idx: number;
  allQuestionsLength: any;
  name: string;
  hidden: boolean;
  questions: any[];
}

export const QuestionSection: React.FC<QuestionSectionProps> = ({
  sectionId,
  idx,
  allQuestionsLength,
  hidden,
  questions
}) => {
  const dispatch = useDispatch();
  const [handleHoverInput, setHandleHoverInput] = useState(false);
  const [handleHoverTextArea, setHandleHoverTextArea] = useState(false);

  const { sections, tags } = useSelector((s: any) => s.tests.currentTest);

  const { length } = sections;
  const defaultValues = sections[idx];

  const deleteSection = () => dispatch(deleteSect({ sectionId }));

  const save = (data: any) => {
    setHandleHoverInput(false);
    setHandleHoverTextArea(false);
    dispatch(updateSection({ idx, data }));
  };

  return (
    <div className="flex flex-col gap-3">
      {!hidden && (
        <div className="group flex gap-4 space-y-3 rounded-lg border border-gray-200 bg-gray-100 py-9 px-6.5">
          <QuestionSectionHeader className="invisible group-hover:visible" idx={idx} />
          <Form
            id={String(sectionId)}
            defaultValues={{
              showInstructions: defaultValues.showInstructions,
              name: defaultValues.name,
              instructions: defaultValues.instructions
            }}
            mode="onChange"
            onSubmit={save}
            resolver={yupResolver(createSectionSchema)}
            onBlur={() => console.log()}
            onKeyDown={() => {
              setHandleHoverInput(false);
              setHandleHoverTextArea(false);
            }}
            className="flex-1 space-y-3">
            {({ watch, handleSubmit, formState }) => (
              <>
                <div className="flex items-center justify-between gap-3 pl-3">
                  <div className="flex items-center gap-3">
                    <Typography
                      title={`Section ${idx + 1} of ${length}`}
                      variant="heading-h5"
                      className="text-[#6D7175]"
                    />
                    <div className="rounded border border-[#C9CCCF] bg-[#E4E5E7] px-[8px] py-[4px]">
                      <Typography
                        title={`${questions.length} Questions`}
                        className="text-[#202223]"
                        variant="small-default"
                      />
                    </div>
                  </div>
                  <Icon
                    className={twMerge(variants['danger-outline'], 'p-[10px] cursor-pointer')}
                    onClick={deleteSection}
                    icon={faTrashCan}
                  />
                </div>
                <div className="space-y-3">
                  <div className="flex gap-3">
                    <div className="flex flex-1">
                      {!handleHoverInput && (
                        <Typography
                          onClick={() => setHandleHoverInput(true)}
                          variant="heading-h2"
                          title={defaultValues.name}
                          className="mt-0 flex h-[43px] max-w-[1215px] flex-1 items-center gap-3 truncate pl-3 hover:rounded-lg hover:border hover:border-gray-300 hover:bg-white hover:pl-[11px]"
                        />
                      )}
                      {handleHoverInput && (
                        <Input
                          inputClassName="text-h2 font-bold p-0"
                          name="name"
                          maxLength={100}
                          autoFocus
                          className="h-[43px] flex-1 pl-[11px]"
                        />
                      )}
                    </div>
                  </div>
                  <ErrorMessage
                    errors={formState.errors}
                    name="name"
                    render={({ message }) => <Typography variant={'error'} title={message} />}
                  />
                  <div className="flex gap-3 pl-3">
                    <Toggle onChangeValue={handleSubmit(save)} name="showInstructions" />
                    <Typography
                      title="Display Section Instructions to Candidates"
                      variant="small-default"
                    />
                  </div>
                  {watch('showInstructions') && (
                    <RichTextEditor
                      maxLength={5000}
                      showLength
                      name="instructions"
                      placeholder={undefined}
                      classNameWrapper={undefined}
                      classNameEditor="bg-white"
                      classNameToolbar="bg-white"
                    />
                  )}
                </div>
              </>
            )}
          </Form>
        </div>
      )}
      <List
        data={questions}
        element={(props, idx) => (
          <Question
            sectionId={sectionId}
            questionsLength={questions?.length}
            allQuestionsLength={allQuestionsLength}
            allResultTags={tags}
            idx={idx}
            type={props.questionType}
            {...props}
          />
        )}
        keyExtractor={(_, idx) => idx}
        wrap="div"
        className="space-y-3"
      />
      <CreateQuestion questionsLength={questions?.length} tags={tags} id={sectionId} />
    </div>
  );
};
