import Attachment from 'components/Attachment';
import SelectAnswer from 'pages/Candidate/components/SelectAnswer/SelectAnswer';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import shuffle from 'utils/shuffle';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { Button, Form, List, Textarea, Typography } from 'ui-kit';
import { useMemo, useState } from 'react';

const EvaluateQuestionComponent = ({
  questionIdx,
  questions,
  prevQuestionHandler,
  nextQuestionHandler
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const isSelectedAnswer = id => selectedAnswer.some(answer => answer === id);

  const isAllAttachments = useMemo(() => {
    return !questions[questionIdx]?.answers?.some(({ text }) => text);
  }, [questions, questionIdx]);

  const selectAnswerHandler = id => {
    if (questions[questionIdx]?.type === 'single') {
      isSelectedAnswer(id) ? setSelectedAnswer([]) : setSelectedAnswer([id]);
    } else {
      isSelectedAnswer(id)
        ? setSelectedAnswer(selectedAnswer.filter(items => items !== id))
        : setSelectedAnswer(prev => [...prev, id]);
    }
  };

  return (
    <div className="flex flex-col p-5">
      <Typography
        variant="heading-h6"
        title={`Question ${questionIdx + 1} of ${questions.length}`}
      />
      <HtmlTextReplacer
        style={{
          wordBreak: 'break-word',
          maxWidth:
            isAllAttachments && questions[questionIdx]?.answer?.length > 0
              ? `${questions[questionIdx]?.answers?.length * 180}px`
              : '800px'
        }}
        className="mt-[8px] break-words text-base"
        html={questions[questionIdx]?.text}
      />
      <div className="mb-6 mt-[8px] flex w-full items-center justify-center">
        {questions[questionIdx]?.attachment?.url && (
          <Attachment attachment={questions[questionIdx]?.attachment} maxWidth={400} />
        )}
      </div>
      {questions[questionIdx]?.type === 'essay' ? (
        <Form
          id="essayAnswer"
          className={`flex-1 ${questions[questionIdx]?.attachment?.url && 'pt-10'}`}>
          <Textarea name="essay" maxLength={questions[questionIdx]?.essayLimit} />
        </Form>
      ) : (
        <div className="flex flex-1 flex-col gap-3">
          <Typography
            variant="regular-default"
            title={`Choose ${
              questions[questionIdx]?.type === 'single'
                ? 'one correct option'
                : 'a few correct options'
            }:`}
            className="text-[#6D7175]"
          />
          <List
            data={
              questions[questionIdx]?.isShuffle
                ? shuffle(questions[questionIdx]?.answers)
                : questions[questionIdx]?.answers
            }
            element={({ text, _id, attachment }) => (
              <SelectAnswer
                _id={_id}
                handleChange={selectAnswerHandler}
                label={text}
                attachment={attachment}
                questionType={questions[questionIdx]?.type}
                selectedAnswer={selectedAnswer}
                isAllAttachments={isAllAttachments}
              />
            )}
            keyExtractor={(_, idx) => idx}
            wrap="div"
            className={`flex flex-1 ${
              !isAllAttachments && 'max-w-[800px] flex-col'
            } flex-wrap gap-3`}
          />
        </div>
      )}
      <div className="mt-[18px] flex items-center gap-[6px]">
        <p className="text-base text-[#6D7175]">Something wrong with this question?</p>
        <a
          className="text-base text-[#007D6F]"
          href={`mailto:${'support@hirenest.com'}?subject=${encodeURIComponent('Report Question')}`}
          target="_blank"
          rel="noopener noreferrer">
          Report it.
        </a>
      </div>
      <div className="mt-6 flex justify-center gap-3">
        {questionIdx !== 0 && (
          <Button
            className="flex items-center gap-2 text-base"
            variant="secondary"
            icon={faChevronLeft}
            title="Previous Question"
            onClick={prevQuestionHandler}
          />
        )}
        {/*Todo temporary hide Finish Test button in view test modal */}
        {questionIdx + 1 !== questions.length && (
          <Button
            className="flex max-w-max justify-center text-base disabled:border-none disabled:bg-[#F1F1F1] disabled:text-[#8C9196] disabled:hover:bg-[#F1F1F1]"
            variant="primary"
            iconEnd="arrow-right"
            onClick={nextQuestionHandler}
            title={questionIdx + 1 === questions.length ? 'Finish Test' : 'Next question'}
          />
        )}
      </div>
    </div>
  );
};

export default EvaluateQuestionComponent;
