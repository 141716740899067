import { Icon, Typography } from 'ui-kit';
import { IconTest } from 'components/IconTest/IconTest';
import { secondsToHms } from 'utils/helpers';

export const TestCardSmall = ({
  timeLimit,
  name,
  onClickButton,
  onClick,
  iconButton,
  selected,
  category,
  icon
}) => (
  <div
    onClick={onClick}
    className={`flex cursor-pointer ${
      selected
        ? 'border-[#575959] bg-[#F6F6F7]'
        : 'border-[#C9CCCF] bg-white hover:border-[#575959] hover:bg-[#F6F6F7]'
    } justify-between rounded-md border py-[10px] pl-[18px] pr-[12px]`}>
    <div className="mr-[12px] flex items-center justify-center">
      <IconTest icon={icon?.url} size={18} className="p-[2px]" />
    </div>
    <div className="flex min-w-[150px] flex-1 flex-col">
      <Typography
        className="truncate text-[14px] text-[#6D7175]"
        variant="small-bold"
        title={category?.name}
      />
      <Typography
        className="truncate"
        variant="heading-h6 text-[16px] font-medium overflow-hidden"
        title={name}
      />
    </div>
    <div className="flex w-[100px] justify-end gap-3">
      <Typography variant="small-default" className="text-[#6D7175]" title={secondsToHms(timeLimit)} />
      {iconButton && (
        <div
          onClick={e => {
            e.stopPropagation();
            onClickButton();
          }}
          className={`flex ${
            iconButton === 'trash'
              ? 'hover:border-[#E25136] hover:bg-[#FFF0F0]'
              : 'hover:bg-[#F6F6F7]'
          } h-[36px] w-[36px] cursor-pointer items-center justify-center self-center rounded-md border border-[#8C9196] bg-white`}>
          <Icon size={16} icon={iconButton} />
        </div>
      )}
    </div>
  </div>
);
