import React from 'react';
import { useController } from 'react-hook-form';

import cn from 'utils/classNames';

interface RadioProps {
  disabled?: boolean;
  placeholder?: string;
  value?: string | number;
  component?: (isActive?: boolean) => React.ReactNode;
  name: string;
  onChangeValue?: (value: string) => void;
  className?: string;
  shouldUnregister?: boolean;
  defaultValue?: string | number;
  classNameInput?: string;
}

const Radio: React.FC<RadioProps> = ({
  disabled,
  placeholder,
  value,
  component,
  name,
  onChangeValue,
  shouldUnregister,
  className,
  classNameInput,
  defaultValue
}) => {
  const { field } = useController({ name, shouldUnregister, defaultValue: defaultValue || '' });

  const { onChange, ...restField } = field;

  const handeChange = (e: { target: HTMLInputElement }) => {
    onChange(e);
    onChangeValue?.(e.target.value);
  };

  const isActive = field.value === value;

  return (
    <label
      className={cn(className, 'flex gap-2 items-center disabled:text-[#8C9196] cursor-pointer')}>
      <input
        {...restField}
        onChange={handeChange}
        checked={isActive}
        value={value}
        className={cn(
          'w-5 h-5 rounded-full disabled:border-[#BDC1CC]',
          `${isActive && 'disabled:bg-[#BDC1CC]'}`,
          component && 'hidden',
          classNameInput
        )}
        id={name}
        disabled={disabled}
        type="radio"
      />
      {placeholder ?? component?.(isActive)}
    </label>
  );
};

export default Radio;
