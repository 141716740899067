import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Subscription } from 'components/Cards/SubscriptionsCard';
import UpgradeSubscriptions from 'components/Forms/UpgradeSubscriptions';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { LogoSvg } from 'components/Logo';
import DowngradeSubscriprions from 'components/Modals/DowngradeSubscriptions/DowngradeSubscriprions';
import React, { useState, useMemo } from 'react';
import { Button, Icon, Typography } from 'ui-kit';

interface SubscriptionViewCardProps extends Subscription {
  currentPlan: any;
  avaliableAssesment?: number;
  isSettings: boolean;
  setSuccessModalOpen: any;
  idModalOpen?: string;
  isHighlighted?: boolean;
  benefits?: Array<string>;
  modal?: boolean;
}

const SubscriptionViewCard: React.FC<SubscriptionViewCardProps> = ({
  description,
  name,
  benefits,
  pricing,
  currentPlan,
  isSettings,
  _id,
  priceYear,
  priceMonth,
  modal = false
}) => {
  const [isUpgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [isDowngradeModalOpen, setDowngradeModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<any>();
  const [selectedAnnual, setSelectedAnnual] = useState(true);

  const planStatus = () => {
    if (currentPlan?.subscription === _id) {
      return 'Current Plan';
    } else if (pricing === 'free') {
      return `Downgrade to ${name}`;
    } else {
      return `Upgrade to ${name}`;
    }
  };

  const upgradePlan = () => {
    setSelectedPlan({ name, description, _id, priceYear, priceMonth, pricing });
    if (pricing === 'free') {
      setDowngradeModalOpen(true);
    } else {
      setUpgradeModalOpen(true);
    }
  };
  const saveAnnual = useMemo(() => {
    if (pricing === 'free') return 0;

    return Math.round(100 - (priceYear?.price * 100) / (priceMonth?.price * 12));
  }, []);

  return (
    <div className={`flex ${!modal && 'h-[420px] min-w-[400px] max-w-[400px]'} flex-1 flex-col`}>
      <div
        className={`relative flex h-full flex-col justify-start rounded-md ${
          pricing === 'free' && 'bg-[#FAFBFB]'
        } ${!modal && 'border border-gray-300 p-[24px]'} ${
          pricing === 'paid' &&
          !modal &&
          'bg-gradient-to-r from-[#F2FCF4] to-[#EBF7F1] shadow-[2px_2px_8px_rgba(0,0,0,0.12)]'
        }`}>
        <div className="flex flex-col justify-between gap-2">
          <div className="flex items-center justify-center">
            {pricing === 'paid' && <LogoSvg width={115} height={22} />}
            <Typography variant="heading-h2" title={name} className="text-center" />
          </div>
          <HtmlTextReplacer
            html={description}
            className="flex justify-center text-center text-h5 font-medium"
          />
        </div>
        <div className="flex min-h-[170px] flex-col justify-between gap-2 border-b border-gray-300">
          {pricing === 'free' ? (
            <Typography variant="heading-h1" title="$0" className="text-center" />
          ) : (
            <div className="flex flex-col justify-center">
              <div className="flex justify-center">
                <Typography
                  variant="heading-h1"
                  title={`$${Math.round(
                    selectedAnnual ? priceYear?.price / 12 : priceMonth?.price
                  )}`}
                  className="text-center"
                />
                <Typography variant="regular-default" title="/month" className="self-end" />
              </div>
              <div className="mt-[16px] mb-[12px] flex justify-center">
                <div
                  onClick={() => setSelectedAnnual(true)}
                  className={`cursor-pointer rounded-l-md border px-[10px] py-[4px] text-[16px] shadow-small
                    ${
                      selectedAnnual
                        ? 'border-[#00A47C] bg-[#F5FEFD] font-semibold text-[#016B69]'
                        : 'border-[#8C9196] bg-[#FFFFFF] text-[#082623]'
                    }`}>
                  Annual{' '}
                  <span
                    className="rounded-md border border-[#C9CCD0] bg-[#F3F8F6] px-[3px] py-[2px] text-[14px]
                  font-semibold text-[#016B69] shadow-small">
                    -{saveAnnual}%
                  </span>
                </div>
                <div
                  onClick={() => setSelectedAnnual(false)}
                  className={`cursor-pointer rounded-r-md border border-l-0 px-[10px] py-[4px] text-[16px]
                    ${
                      !selectedAnnual
                        ? 'border-[#00A47C] bg-[#F5FEFD] font-semibold text-[#016B69]'
                        : 'border-[#8C9196] bg-[#FFFFFF] text-[#082623]'
                    }`}>
                  Monthly
                </div>
              </div>
            </div>
          )}

          {isSettings && (
            <Button
              onClick={upgradePlan}
              disabled={currentPlan?.subscription === _id}
              variant="primary"
              title={planStatus()}
              className={`mb-4 ${pricing === 'paid' && 'w-full'} self-center`}
            />
          )}
        </div>
        <div className="flex flex-col gap-2 pt-[18px]">
          {benefits &&
            benefits.map((benefit, idx) => (
              <div className="flex items-start gap-1" key={idx}>
                <div className="mr-1 w-[14px]">
                  <Icon icon={faCheck} size={16} color="#639910" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: benefit }} />
              </div>
            ))}
        </div>
      </div>
      {isUpgradeModalOpen && (
        <UpgradeSubscriptions
          selectedAnnual={selectedAnnual}
          setSelectedAnnual={setSelectedAnnual}
          selectedPlan={selectedPlan}
          isUpgradeModalOpen={isUpgradeModalOpen}
          setUpgradeModalOpen={setUpgradeModalOpen}
        />
      )}
      <DowngradeSubscriprions
        isDowngradeModalOpen={isDowngradeModalOpen}
        setDowngradeModalOpen={setDowngradeModalOpen}
        currentName={currentPlan?.name}
        updateName={name}
        daysLeft={currentPlan?.expiresAt}
      />
    </div>
  );
};

export default SubscriptionViewCard;
