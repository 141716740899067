import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Tooltip } from 'react-tooltip';
import { Icon, List, Typography } from 'ui-kit';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import cn from 'utils/classNames';

export const Multi = props => {
  return (
    <div className="flex w-full gap-3">
      <div className="flex flex-1 flex-col gap-[8px] border-r border-[#D2D5D8]">
        <TextAreaView className="break-words" title={props.text} variant="regular-default" />
        <div className="overflow-hidden">
          {props.attachment?.url && (
            <img
              className="m-0 block h-36 max-w-[250px] object-contain"
              src={props.attachment.url}
              alt="question attachment"
            />
          )}
        </div>
      </div>
      <div className="ml-2 flex flex-1">
        <List
          data={props.answers}
          element={({ score, text, attachment, wasAnswered, _id }) => (
            <div
              data-tooltip-id={`answer-explain-tooltip-${_id}`}
              data-tooltip-place="top-start"
              className="flex w-full gap-3">
              {props?.showAnswers && (score > 0 || wasAnswered) && (
                <Tooltip
                  id={`answer-explain-tooltip-${_id}`}
                  className="relative z-50 ml-[-21px] mb-[10px] rounded pb-[100px]
        after:absolute after:left-[25px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
                  noArrow={true}
                  style={{
                    background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
                    fontSize: '14px'
                  }}>
                  {props?.showAnswers &&
                    !wasAnswered &&
                    score > 0 &&
                    'This is a correct choice, but a candidate hasn’t selected it.'}
                  {props?.showAnswers &&
                    wasAnswered &&
                    (score > 0
                      ? 'A candidate has selected the correct choice.'
                      : 'A candidate has selected the wrong choice.')}
                </Tooltip>
              )}
              <div className="flex items-center gap-3">
                {!props?.distributePoints ? (
                  <div
                    className={cn(
                      'flex items-center justify-center w-[18px] h-[18px] p-1 rounded border',
                      score > 0 && !props?.showAnswers
                        ? 'bg-green-500 text-white'
                        : 'border-gray-300',
                      props?.showAnswers &&
                        wasAnswered &&
                        (score > 0 ? 'bg-green-500 text-white' : 'bg-[#D1361A] text-white'),
                      props?.showAnswers && !wasAnswered && score > 0 && 'bg-[#A2BCB0] text-white'
                    )}>
                    <Icon icon={faCheck} size={14} color="#fff" />
                  </div>
                ) : (
                  <Typography
                    className="flex h-[16px] w-[16px] items-center justify-center rounded-full border border-[#8C9196] p-2 text-center text-[#8C9196]"
                    title={score}
                    variant="regular-default"
                  />
                )}
                <Typography
                  title={text}
                  variant="regular-default"
                  style={{ wordBreak: 'break-word' }}
                  className="flex-1 break-words"
                />
              </div>
              {attachment?.url && (
                <div className="overflow-hidden ">
                  <img className="m-0 block h-32 object-contain" src={attachment.url} alt="" />
                </div>
              )}
            </div>
          )}
          keyExtractor={(_, idx) => idx}
          wrap="div"
          className="space-y-2"
        />
      </div>
    </div>
  );
};
