import { useMemo } from 'react';
import { Button, Modal, ModalSlide, Typography } from 'ui-kit';
import { FormatOptionLabel } from 'ui-kit/CustomSelect/SelectCustom';

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { optionsBarChartIsnide, optionsPolarWithotLegends } from 'config/charConfig';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { stringToHslColor } from 'utils/stringToHslColor';
import { ResultDetailsDimensionsSection } from 'pages/Candidate/CandidateCertificate/ResultsDetailsCertificate/ResultDetailsDimensionsSection';

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

export const ResultCard = ({ name, result, description, title, type = false, explanation }) => {
  return (
    <div
      className={`flex ${
        type && 'bg-[#F6F6F7]'
      } w-full flex-auto flex-col gap-1 rounded-xl border border-gray-300 p-6 transition-all`}>
      <div className="flex gap-[48px]">
        <div className="flex flex-2 flex-col gap-[12px]">
          <div className="flex items-center gap-3">
            {type ? (
              <FormatOptionLabel label={name} />
            ) : (
              <h4 className="text-[22px] font-semibold text-[#082623]">{name}</h4>
            )}
            {result && (
              <div className="rounded border bg-gray-300 px-2 py-1 text-[18px] text-[#05443D]">
                {result}
              </div>
            )}
            <Typography
              className="break-words text-[20px] font-normal text-[#8C9196]"
              variant="heading-h4"
              title={title && title}
            />
          </div>
          <HtmlTextReplacer
            style={{ wordBreak: 'break-word' }}
            className="break-words"
            html={description}
          />
        </div>
        {explanation && (
          <div className="flex flex-1 flex-col gap-1">
            <Typography
              className="break-words text-[#8C9196]"
              variant="heading-h5"
              title="Explanation"
            />
            <HtmlTextReplacer
              style={{ wordBreak: 'break-word' }}
              className="break-words"
              html={explanation}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const ResultInterpretationModal = ({
  isOpen,
  setOpen,
  setCurrentTestId,
  forceModalClose,
  name,
  result,
  scaledResult,
  sectionDescription,
  resultInterpretation,
  sections,
  tags,
  chartSettings,
  calculationType,
  minValue,
  maxValue,
  handleExplain
}) => {
  const charData = useMemo(() => {
    if (!chartSettings || !sections || !tags) {
      return;
    }
    const { includeTestOverall, includeSections, includeTags } = chartSettings;
    const data = [];
    const results = [];

    includeTestOverall?.result &&
      data.push('Test Overall') &&
      results.push(+result?.replace(/[^0-9]/g, ''));
    includeSections &&
      sections.length > 1 &&
      sections.map(({ sectionName, calculatedResult }) => {
        data.push(sectionName);
        results.push(
          typeof calculatedResult === 'string'
            ? +calculatedResult.replace(/[^0-9]/g, '')
            : calculatedResult
        );
      });
    includeTags &&
      tags.map(({ tagName, calculatedResult }) => {
        data.push(tagName);
        results.push(
          typeof calculatedResult === 'string'
            ? +calculatedResult.replace(/[^0-9]/g, '')
            : calculatedResult
        );
      });

    return {
      labels: data,
      datasets: [
        {
          data: results,
          backgroundColor: data.map(value => value && stringToHslColor(value, 45, 55, 0.5)),
          borderWidth: 1
        }
      ]
    };
  }, [sections, tags, chartSettings]);

  return (
    <ModalSlide
      isOpen={isOpen}
      customWidth={654}
      onClose={() => {
        setOpen(false);
        setCurrentTestId('');
      }}
      forceClosing={forceModalClose}
      headerChildren={
        <Button
          variant="primary-outline"
          type="submit"
          title="Explain Results"
          onClick={handleExplain}
          className="ml-[12px] h-[33px] w-[124px] px-[12px] py-[6px] text-[14px]"
        />
      }
      title={`${name} Result`}
      animationType="rightToLeft"
      className="m-auto w-full max-w-[900px]">
      <Modal.Body className="flex h-full flex-col gap-4 p-6">
        <div className="flex">
          {chartSettings?.chartStyle !== 'none' &&
            chartSettings?.chartStyle !== 'dimensions' &&
            charData && (
              <div className="flex h-[390px] max-h-[390px] flex-1 items-center justify-center">
                {chartSettings.chartStyle === 'polar' && (
                  <div className="h-full w-full">
                    <PolarArea
                      data={charData}
                      options={{
                        ...(calculationType === 'avg'
                          ? optionsPolarWithotLegends(minValue, maxValue)
                          : optionsPolarWithotLegends(0, 100))
                      }}
                    />
                  </div>
                )}
                {chartSettings.chartStyle === 'bar' && (
                  <div style={{ height: sections.length * 40 + 40 }} className="w-[400px]">
                    <Bar
                      data={charData}
                      options={
                        calculationType === 'avg'
                          ? optionsBarChartIsnide(minValue, maxValue)
                          : optionsBarChartIsnide(0, 100)
                      }
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                )}
              </div>
            )}
        </div>
        {chartSettings?.chartStyle !== 'dimensions' ? (
          <div className="flex flex-col gap-[24px]">
            {sections?.length > 1 &&
              sections?.map(
                ({ sectionName, _id, calculatedResult, ...props }) =>
                  calculatedResult && (
                    <ResultCard
                      key={_id}
                      name={sectionName}
                      result={calculatedResult}
                      description={props?.resultInterpretation?.description}
                      title={props?.resultInterpretation?.title}
                    />
                  )
              )}
            {tags?.map(({ tagName, _id, calculatedResult, ...props }) => (
              <ResultCard
                type
                key={_id}
                name={tagName}
                result={calculatedResult}
                description={props?.resultInterpretation?.description}
                title={props?.resultInterpretation?.title}
              />
            ))}
          </div>
        ) : (
          sections?.map(section => (
            <div key={section?._id} className="flex flex-col gap-3">
              <ResultDetailsDimensionsSection {...section} />
            </div>
          ))
        )}
      </Modal.Body>
    </ModalSlide>
  );
};

export default ResultInterpretationModal;
