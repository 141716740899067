import { Button, Modal, Typography } from 'ui-kit';
import React, { useState, useEffect, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { clearSecret, stripeConnectRequest } from 'store/configuration/actions/subscriptions';
import CheckoutForm from './CheckoutForm';
import { useNavigate } from 'react-router-dom';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { setSuccessModalOpen } from 'store/auth/actions';

interface UpgradeSubscriptionsProps {
  isUpgradeModalOpen: boolean;
  setUpgradeModalOpen: (isOpen: boolean) => void;
  selectedAnnual: any;
  setSelectedAnnual: any;
  selectedPlan: any;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

const UpgradeSubscriptions: React.FC<UpgradeSubscriptionsProps> = ({
  isUpgradeModalOpen,
  setUpgradeModalOpen,
  setSelectedAnnual,
  selectedAnnual,
  selectedPlan,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientSecret } = useSelector((store: any) => store.subscriptions);
  const [isLoading, setIsLoading] = useState(false);
  const [elementsKey, setElementsKey] = useState(0);

  const options = useMemo(() => {
    if (clientSecret && selectedPlan) {
      return {
        clientSecret,
        theme: 'stripe'
      };
    }
  }, [clientSecret]);

  useEffect(() => {
    if (clientSecret && selectedPlan) {
      // Update the key to force re-render
      setElementsKey((prevKey) => prevKey + 1);
    }
  }, [clientSecret, selectedPlan]);

  const closeModal = (success = false) => {
    setUpgradeModalOpen(false);
    dispatch(clearSecret());
    success && dispatch(setSuccessModalOpen(true));
  }

  useEffect(() => {
    selectedPlan &&
      isUpgradeModalOpen &&
      dispatch(stripeConnectRequest({ data: selectedPlan?.pricing === 'free' ? { subscription: selectedPlan?._id } : { subscription: selectedPlan?._id, stripePriceId: selectedAnnual ? selectedPlan?.priceYear?.stripePriceId : selectedPlan?.priceMonth?.stripePriceId }, navigate }));
  }, [selectedPlan, isUpgradeModalOpen, selectedAnnual]);
  return (
    <Modal 
      isOpen={isUpgradeModalOpen} 
      onClose={() => closeModal()}
      className="m-auto w-full max-w-[1024px]">
      <Modal.Header className="border-none px-6">
        <Typography variant="heading-h4" title="Checkout" />
        <Button
          icon={faXmark}
          iconSize={20} 
          iconColor="#5C5F62"
          onClick={() => {
            setUpgradeModalOpen(false);
            dispatch(clearSecret());
          }}
        />
      </Modal.Header>
      <Modal.Body className="flex gap-6 p-6">
        <div className="flex flex-1 flex-col gap-3">
          <Typography title="Billing Period" variant="heading-h5" />
              <div className="flex justify-center gap-3">
                <div
                  onClick={() => setSelectedAnnual(true)}
                  className={`flex flex-1 cursor-pointer flex-col gap-[2px] rounded-md border px-[12px] py-[8px] shadow-small
                    ${
                      selectedAnnual
                        ? 'border-[#00A47C] bg-[#F5FEFD] font-semibold'
                        : 'border-[#8C9196] bg-[#FFFFFF]'
                    }`}>
                      <div className='flex items-center gap-[8px]'>
                        <Typography variant='heading-h6' title="Annual" className='font-medium text-[#082623]' />
                        <div className="rounded border border-[#C9CCD0] bg-[#F3F8F6] px-[6px] text-[#016B69] shadow-small">
                        Save ${(selectedPlan?.priceMonth?.price * 12) - selectedPlan?.priceYear?.price}
                        </div>
                      </div>
                      <Typography variant="small-default" className="font-normal text-[#082623]" title={`$${selectedPlan?.priceYear?.price / 12} per month`} />
                      <Typography variant="label-default" className="font-normal text-[#6D7175]" title={`$${selectedPlan?.priceYear?.price} billed yearly`} />
                </div>
                <div
                  onClick={() => setSelectedAnnual(false)}
                  className={`flex flex-1 cursor-pointer flex-col gap-[2px] rounded-md border px-[12px] py-[8px] text-[16px]
                    ${
                      !selectedAnnual
                        ? 'border-[#00A47C] bg-[#F5FEFD] font-semibold'
                        : 'border-[#8C9196] bg-[#FFFFFF]'
                    }`}>
                      <Typography variant='heading-h6' title="Monthly" className='font-medium text-[#082623]' />
                      <Typography variant="small-default" className="font-normal text-[#082623]" title={`$${selectedPlan?.priceMonth?.price} per month`} />
                      <Typography variant="label-default" className="font-normal text-[#6D7175]" title={`billed monthly`} />
                </div>
              </div>
          {clientSecret && options && (
            <Elements key={elementsKey} options={options} stripe={stripePromise}>
              <CheckoutForm setIsLoading={setIsLoading} closeModal={closeModal}/>
            </Elements>
          )}
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className='flex items-center justify-between'>
            <Typography variant="heading-h5" title="Total due:" />
            <Typography variant="heading-h2" title={`$${selectedAnnual ? selectedPlan?.priceYear?.price.toFixed(2) : selectedPlan?.priceMonth?.price.toFixed(2)}`} />
          </div>
          <div className='flex items-center justify-between'>
            <Typography variant="regular-default" title={`$${selectedAnnual ? selectedPlan?.priceYear?.price.toFixed(2) / 12 : selectedPlan?.priceMonth?.price.toFixed(2)} x ${selectedAnnual ? 12 : 1} month`} />
            <Typography variant="refular-default" title={`$${selectedAnnual ? selectedPlan?.priceYear?.price.toFixed(2) : selectedPlan?.priceMonth?.price.toFixed(2)}`} />
          </div>
          <div className='border-t border-[#C9CCCF]' />
          <Typography variant="regular-default" title="You can cancel your subscription at any time." />
          <Typography variant='label-default' className='text-[#6D7175]' title="All future payments would be processed automatically using that payment account." />
          <Typography variant='label-default' className='text-[#6D7175]' title="We will not store your payment information on our server, we will send it encrypted to Stripe payment system." />
          <Typography variant='label-default' className='text-[#6D7175]'>
            Checkout is handled by <b>Stripe</b>.
          </Typography>
          <Button
            variant="primary"
            type="submit"
            form="payment-form"
            id="submit"
            className="mt-auto"
            disabled={isLoading || !clientSecret}
            title="Agree and Purchase"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradeSubscriptions;
