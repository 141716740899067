import React from 'react';

import cn from 'utils/classNames';

interface ModalFooterProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ children, className }) => {
  return <div className={cn('p-4', className)}>{children}</div>;
};
