import React from 'react';

export const Essay: React.FC<{ watch: any, setValue: any }> = ({ watch, setValue }) => {
  // commented Essay placeholder component
  return <></>;
  // return (
  //   <div className="mt-[36px] flex justify-center gap-6">
  //     <div className="flex-3 space-y-1">
  //       <Typography variant="regular-default" title="Essay placeholder (optional)" />
  //       <Textarea maxLength={watch('essayLimit')} height="min-h-[100px]" name="essayPlaceholder" />
  //     </div>
  //     <div className="max-w-[140px] flex-1 space-y-1">
  //       <Typography variant="regular-default" title="Characters limit" />
  //       <Input
  //         type="number"
  //         onChangeValue={num =>
  //           setValue('essayPlaceholder', cutTextLength(watch('essayPlaceholder'), num))
  //         }
  //         name="essayLimit"
  //         onKeyDown={(evt: any) => ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()}
  //       />
  //     </div>
  //   </div>
  // );
};
