import Loader from 'components/Loader';
import Breadcrumbs from 'components/Breadcrumbs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteNoteEmployerRequest,
  getSingleEmployerRequest,
  noteEmployerRequest
} from 'store/admin/actions';
import { List, Section, Typography } from 'ui-kit';
import { getFullName, getModifyDataWithYear } from 'utils/helpers';
import { AddNoteForm } from 'components/Forms/AddNoteForm';
import { Note } from 'components/Note';
import { VerticalTextBlock } from 'components/VerticalTextBlock/VerticalTextBlock';

const ViewEmployer = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();

  const { loading, currentEmployer } = useSelector(store => store.admin);

  const {
    firstName,
    lastName,
    image,
    email,
    lastActivityAt,
    lastLoginAt,
    status,
    createdAt,
    notes
  } = currentEmployer || {};

  const updateNotes = (note, control) => {
    dispatch(
      noteEmployerRequest({
        data: note,
        userId: _id
      })
    );
    control.reset();
  };

  useEffect(() => {
    _id && dispatch(getSingleEmployerRequest(_id));
    window.scrollTo(0, 0);
  }, [_id]);

  const deleteNote = id => {
    dispatch(deleteNoteEmployerRequest({ userId: _id, noteId: id }));
  };

  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      <Breadcrumbs type="employer" />
      <Typography
        title={getFullName({ firstName, lastName })}
        variant="heading-h1"
        className="mt-2 text-bluegray"
      />
      <div className="mt-6 space-y-6">
        <div className="flex flex-col gap-6">
          <Section className="flex-1">
            <Section.Body className="flex gap-6">
              {image ? (
                <img src={image} className="h-[180px] w-[180px] rounded-md" />
              ) : (
                <div className="flex h-[180px] w-[180px] items-center justify-center rounded-md bg-gray-300 text-xl font-semibold text-gray-500">
                  User image
                </div>
              )}
              <div className="flex flex-col gap-6">
                <div className="flex justify-between gap-6">
                  <VerticalTextBlock
                    mainText="Email"
                    secondText={email}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                    className="min-w-[215px]"
                  />
                  <VerticalTextBlock
                    mainText="Status"
                    secondText={status}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                    className="min-w-[215px]"
                  />
                </div>
                <div className="flex justify-between gap-6">
                  <VerticalTextBlock
                    mainText="Register On"
                    secondText={getModifyDataWithYear(new Date(createdAt))}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                    className="min-w-[215px]"
                  />
                  <VerticalTextBlock
                    mainText="Last Activity"
                    secondText={getModifyDataWithYear(new Date(lastActivityAt || lastLoginAt))}
                    mainColor="text-bluegray"
                    secondColor="text-gray-500"
                    className="min-w-[215px]"
                  />
                </div>
              </div>
            </Section.Body>
          </Section>
          <Section>
            <Section.Header>
              <Typography title="Notes" variant="heading-h2" />
            </Section.Header>
            <Section.Body>
              <List
                wrap="div"
                className="mb-6 flex flex-col gap-6"
                data={notes}
                element={({ note, createdAt, _id }) => (
                  <Note
                    onSubmit={updateNotes}
                    deleteNote={deleteNote}
                    text={note}
                    id={_id}
                    createdAt={createdAt}
                    key={createdAt}
                  />
                )}
                keyExtractor={(_, idx) => idx}
              />
              <AddNoteForm onSubmit={updateNotes} />
            </Section.Body>
          </Section>
        </div>

        {/* <Section>
          <Section.Header>
            <Typography title="Assessments" variant="heading-h2" />
          </Section.Header>
          <Section.Body>
            <Form defaultValues={{ search: params.search, status: params.status }}>
              {({ handleSubmit, getValues }) => (
                <>
                  <ul className="flex relative justify-between mb-6  w-full">
                    <li className="flex items-center space-x-3">
                      <Typography title="Assessments" variant="heading-h1" />
                      {!isAdmin && (
                        <Button
                          onClick={newAssessmentHandler}
                          icon="plus"
                          title="Create Assessment"
                          variant="create"
                        />
                      )}
                    </li>
                    <li className="flex space-x-3">
                      <Select
                        onChangeValue={handleSubmit(set)}
                        className="flex-1 max-w-[200px]"
                        name="status"
                        options={typeAssessmentConfig}
                        placeholder="All assessments"
                      />
                      <Input
                        onChangeValue={handleSubmit(debounceSubmit)}
                        icon="magnifier"
                        className="flex-3 max-w-[250px]"
                        name="search"
                        placeholder="Search for the assessments"
                      />
                    </li>
                  </ul>
                  {createdAssessments?.length ? (
                    <section className={`flex w-full flex-col justify-between`}>
                      {loading ? (
                        <List
                          wrap="ul"
                          data={Array(perPage).fill(0)}
                          keyExtractor={(_, idx) => idx}
                          TODO: ADD CardLoader 2 sprint
                          element={props => <CardLoader {...props} type="assessment" />}
                          element={props => (
                            <div className="h-[248px] min-w-[345px] bg-gray-300"></div>
                          )}
                          className="mb-6 grid gap-6 lg:grid-cols-3 2xl:grid-cols-4"
                        />
                      ) : (
                        <List
                          wrap="ul"
                          data={createdAssessments}
                          keyExtractor={(_, idx) => idx}
                          element={props => (
                            <AssessmenstCard
                              {...props}
                              author={allEmployers[0] || {}}
                              // isAdmin={isAdmin}
                              currentAssessment={props}
                            />
                          )}
                          className="mb-6 grid gap-6 lg:grid-cols-3 2xl:grid-cols-4"
                        />
                      )}
                      {totalAssessments > perPage && (
                        <Pagination
                          page={params.page}
                          itemsPerPage={perPage}
                          totalItems={totalAssessments}
                          setPage={page => push({ page })}
                        />
                      )}
                    </section>
                  ) : (
                    <EmptyResourse
                      className="my-20"
                      title={
                        getValues()?.search
                          ? 'No matches found'
                          : "You don't have any assessment created yet"
                      }
                    />
                  )}
                </>
              )}
            </Form>
          </Section.Body>
        </Section> */}

        {/* <Section>
            <Section.Header>
              <Typography title="Candidate" variant="heading-h2" />
            </Section.Header>
            <Section.Body className="flex flex-wrap flex-auto min-h-[269px]">
              <CandidateInfo data={currentCandidate} />
            </Section.Body>
          </Section>
        </div>
        {currentAssessment.tests !== 0 && currentApplication?.status !== 'started' && (
          <Section>
            <Section.Header>
              <Typography title="Tests Results" variant="heading-h2" />
            </Section.Header>
            <Section.Body className="flex flex-wrap flex-auto min-h-[269px]">
              <List
                data={currentAssessment.tests}
                element={(props, idx) => {
                  const { score, totalCorrcetQuestions } =
                    currentApplication?.testsResults[idx] || {};
                  return (
                    <TestCard.Results
                      {...props}
                      score={score}
                      totalCorrcetQuestions={totalCorrcetQuestions}
                    />
                  );
                }}
                keyExtractor={(_, idx) => idx}
                wrap="div"
                className="flex flex-col flex-auto gap-4"
              />
            </Section.Body>
          </Section>
        )}
        {currentAssessment.questions.length !== 0 && currentApplication?.status !== 'started' && (
          <Section>
            <Section.Header>
              <Typography title="Custom Question Results" variant="heading-h2" />
            </Section.Header>
            <Section.Body className="flex flex-wrap flex-auto min-h-[269px]">
              <List
                data={questionsArr}
                element={(props, idx) => {
                  return <QuestionCard data={{ ...props }} index={idx} typeCard="application" />;
                }}
                keyExtractor={(_, idx) => idx}
                wrap="div"
                className="flex flex-col flex-auto gap-1"
              />
            </Section.Body>
          </Section>
        )}
        {currentApplication && (
          <Section>
            <Section.Header>
              <Typography title="Employer Rating" variant="heading-h2" />
            </Section.Header>
            <Section.Body className="flex flex-wrap flex-auto min-h-[269px]">
              <div className="flex flex-col flex-auto gap-5">
                <div>
                  <Typography title="Rate:" variant="heading-h5" />
                  <Rate
                    rating={currentApplication.rating}
                    applicationId={currentApplication._id}
                    candidateId={candidateId}
                  />
                </div>
                <Form onSubmit={onSubmit} defaultValues={{ note: currentApplication?.note || '' }}>
                  <Typography title="Employer Note" variant="heading-h5" />
                  <Textarea
                    disabled={role === 'admin'}
                    className="mt-2.5"
                    name="note"
                    placeholder="Some notes about this candidate..."
                    maxLength={2000}
                  />
                  {role !== 'admin' && (
                    <Button
                      title="Save note"
                      className="justify-center mt-8"
                      type="submit"
                      variant="primary"
                    />
                  )}
                </Form>
              </div>
            </Section.Body>
          </Section>
        )} */}
      </div>
    </div>
  );
};

export default ViewEmployer;
