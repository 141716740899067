import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentTestRequest } from 'store/tests/actions';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import Typography from 'ui-kit/Typography';
import { secondsToHms } from 'utils/helpers';
import * as routes from 'routes/routes';

export const EmployerCard: React.FC<any> = props => {
  const { name, tagline, category, timeLimit, questionsCount, _id } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editTestHandler = () => {
    dispatch(getCurrentTestRequest(_id));
    navigate(routes.TESTS + '/' + _id, { state: { _id } });
  };

  return (
    <div
      onClick={() => editTestHandler()}
      style={{ wordBreak: 'break-word' }}
      className="flex min-w-[345px] cursor-pointer flex-col justify-between space-y-4 break-words rounded-md border border-gray-400 bg-white p-4 hover:border-green-400">
      <div>
        <Typography title={name} variant="heading-h4" />
        <Typography
          title={category?.name || category}
          variant="regular-emphasis"
          className="text-gray-400"
        />
      </div>
      <TextAreaView title={tagline} variant="regular-default" />
      <div className="flex">
        <div className="flex-1 space-y-1">
          <Typography variant="small-default" title="Questions" />
          <Typography variant="regular-emphasis" className="text-gray-500" title={questionsCount} />
        </div>
        <div className="flex-1 space-y-1">
          <Typography variant="small-default" title="Time Limit" />
          <Typography
            variant="regular-emphasis"
            className="text-gray-500"
            title={secondsToHms(timeLimit) || '-'}
          />
        </div>
      </div>
    </div>
  );
};
