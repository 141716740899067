import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { generateSniperLink } from 'utils/gerenateSniperLink';
import { localStorageService } from 'helpers/localStorageService';

export const useSniperLink = () => {
  const { user } = useSelector(store => store.auth);
  const [sniperLink, setSniperLink] = useState({ url: '', name: '' });

  useEffect(() => {
    if (user?.email) {
      setSniperLink(generateSniperLink(localStorageService.getDomain(), user?.email));
    }
  }, [user?.email]);

  return { sniperLink };
};
