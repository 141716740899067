import { faXmark } from '@fortawesome/pro-light-svg-icons';

import { Icon } from 'ui-kit';

const ErrorInfo = ({ error, setShowError }) => {
  const onCloseErrorHandler = () => {
    setShowError(false);
  };

  return (
    <div className="fixed right-10 bottom-10 flex rounded-xl bg-red-400 p-5 text-white shadow-xl">
      <p className="text-sl font-semibold">{error}</p>
      <button onClick={() => onCloseErrorHandler()} className="ml-5">
        <Icon icon={faXmark} size={12} color="#fff" />
      </button>
    </div>
  );
};

export default ErrorInfo;
