import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';
import { Typography, Section } from 'ui-kit';

import Breadcrumbs from 'components/Breadcrumbs';
import { getApplicationRequest } from 'store/candidates/actions';
import { secondsToHms } from 'utils/helpers';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { ResultCard } from 'components/Modals/ResultInterpretationModal/ResultInterpretationModal';
import { optionsBarChartIsnide, optionsPolarWithotLegends } from 'config/charConfig';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { charDataCalculate } from 'pages/Candidate/CandidateCertificate/ResultsDetailsCertificate/ResultsDetailsCertificate';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DimensionWidget } from 'components/DimensionWidget/DimenstionWidget';
import { Tooltip as CustomToolTip } from 'react-tooltip';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

const DimensionCard = ({
  sectionName,
  resultInterpretation,
  calculatedResult,
  allTags,
  tags,
  sectionDescription
}) => (
  <div
    style={{
      background: `linear-gradient(180deg, hsl(${resultInterpretation?.dimensionScales?.hue}, 63%, 95%) 0%, hsl(${resultInterpretation?.dimensionScales?.hue}, 54%, 99%) 38.54%, hsl(0, 0%, 100%) 100%)`
    }}
    className="flex w-full flex-col gap-3 rounded-md p-6 shadow-med">
    <p
      style={{ color: `hsl(${resultInterpretation?.dimensionScales?.hue}, 38%, 29%)` }}
      className="text-h3 font-medium">
      {sectionName}
    </p>
    <CustomToolTip
      id="card-dimension-tooltip"
      className="rounded-s z-50 mt-[3px] w-[226px] max-w-[300px] text-sm
        after:absolute after:left-[50%] after:bottom-[-5px] after:translate-x-[-3px]
        after:border-x-[5px] after:border-b-0
        after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D] after:transition"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)'
      }}
    />
    <div className="flex gap-[48px]">
      <div className="flex flex-1 flex-col gap-3">
        <div className="flex w-full flex-col gap-[4px]">
          <div className="flex justify-between">
            <div className="flex items-center gap-[4px] text-base">
              <p className="text-h6">{resultInterpretation?.dimensionScales?.low?.name}</p>
              <div className="ml-1 text-[#5C5F62]">
                <i
                  className="fa-regular fa-circle-info relative text-sm text-[#8C9196]"
                  data-tooltip-id="card-dimension-tooltip"
                  data-tooltip-html={resultInterpretation?.dimensionScales?.low?.description}
                />
              </div>
            </div>
            <div className="flex items-center gap-[4px] text-base">
              <p className="text-h6">{resultInterpretation?.dimensionScales?.high?.name}</p>
              <div className="ml-1 text-[#5C5F62]">
                <i
                  className="fa-regular fa-circle-info relative text-sm text-[#8C9196]"
                  data-tooltip-id="card-dimension-tooltip"
                  data-tooltip-html={resultInterpretation?.dimensionScales?.high?.description}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full gap-[6px]">
            {resultInterpretation?.grades
              ?.sort((a, b) => a.scale.min - b.scale.min)
              ?.map(({ scale, _id }) => (
                <div
                  className="h-[10px] flex-1 rounded-sm"
                  key={_id}
                  style={{
                    backgroundColor: `hsl(${resultInterpretation?.dimensionScales?.hue}, ${
                      scale.max >= calculatedResult && scale.min <= calculatedResult ? '32%' : '31%'
                    }, ${
                      scale.max >= calculatedResult && scale.min <= calculatedResult ? '56%' : '85%'
                    })`
                  }}
                />
              ))}
          </div>
        </div>
        <HtmlTextReplacer
          style={{ wordBreak: 'break-word' }}
          className="break-words"
          html={resultInterpretation?.description}
        />
      </div>
      <HtmlTextReplacer
        style={{ wordBreak: 'break-word' }}
        className="flex flex-1 break-words text-sm"
        html={sectionDescription}
      />
    </div>
    {allTags?.length > 0 && <p className="text-h5 font-medium">Opennes Traits</p>}
    <div className="flex flex-wrap gap-6">
      {allTags?.map(
        ({
          calculatedResult,
          points,
          tagDescription,
          tagName,
          _id,
          resultInterpretation: result
        }) =>
          tags.includes(tagName) && (
            <div className="flex basis-[calc(33%-12px)] flex-wrap gap-3 rounded border border-[#C9CCCF] bg-[#FAFBFB] p-3" key={_id}>
              <div className="h-[100px] w-[100px]">
                <CircularProgressbarWithChildren
                  minValue={resultInterpretation?.min}
                  maxValue={resultInterpretation?.max}
                  value={calculatedResult}
                  strokeWidth={8}
                  background
                  styles={buildStyles({
                    backgroundColor: '#FAFBFB',
                    strokeLinecap: 'butt',
                    pathColor: `hsl(${resultInterpretation?.dimensionScales?.hue}, 32%, 56%)`,
                    trailColor: '#EEEEEE'
                  })}>
                  <div className="rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] py-[1px]">
                    {result?.title}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="flex flex-col w-[calc(100%-120px)] ">
                <div className="flex items-center gap-[4px]">
                  <p className="text-h6">{tagName}</p>
                  {tagDescription && (
                    <div className="ml-1 text-[#5C5F62]">
                      <i
                        className="fa-regular fa-circle-info relative text-sm text-[#8C9196]"
                        data-tooltip-id="card-dimension-tooltip"
                        data-tooltip-html={tagDescription}
                      />
                    </div>
                  )}
                </div>
                <div className="text-sm">
                  <HtmlTextReplacer
                    style={{ wordBreak: 'break-word' }}
                    className="flex flex-1 break-words text-sm"
                    html={result?.description}
                  />
                </div>
              </div>
            </div>
          )
      )}
    </div>
  </div>
);

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const ViewTestResultExplanation = () => {
  const { applicationId, testId } = useParams();
  const dispatch = useDispatch();

  const { loading, application } = useSelector(store => store.candidates);

  const currentTest = useMemo(() => {
    return application?.tests?.find(test => test._id === testId) || {};
  }, [testId, application.tests]);

  const charData = useMemo(() => {
    if (!currentTest?.chartSettings) return;
    return charDataCalculate(
      currentTest.sections,
      currentTest.chartSettings,
      currentTest.scaledResult ?? currentTest.result,
      true
    );
  }, [currentTest]);

  useEffect(() => {
    applicationId && dispatch(getApplicationRequest(applicationId));
  }, [applicationId]);

  return (
    <div className="py-8 px-7.5">
      {loading && !currentTest.name && <Loader />}
      <Breadcrumbs type="test-result" />
      <Typography
        title={
          currentTest?.chartSettings?.chartStyle !== 'dimensions'
            ? `Explaining ${currentTest.name}`
            : `${application?.candidate[0]?.firstName} ${application?.candidate[0]?.lastName}’s ${currentTest?.name}`
        }
        variant="heading-h1"
      />
      <div className="mt-5 space-y-6">
        <div className="flex gap-[24px]">
          {currentTest?.chartSettings?.chartStyle !== 'dimensions' ? (
            <div
              className={`flex ${
                currentTest?.chartSettings?.chartStyle !== 'none' && 'flex-col'
              } w-full flex-1 gap-[24px]`}>
              {currentTest?.result && (
                <Section className="flex-1">
                  <Section.Body className="flex flex-col gap-[12px]">
                    <div className="flex items-center gap-[12px]">
                      <div className="rounded border bg-gray-300 px-2 py-1 font-bold">
                        {currentTest?.result}
                      </div>
                      <Typography
                        variant="heading-h2"
                        title={currentTest?.resultInterpretation?.title}
                      />
                    </div>
                    <HtmlTextReplacer html={currentTest?.resultInterpretation?.description} />
                  </Section.Body>
                </Section>
              )}
              <Section className="flex-1">
                <Section.Body>
                  <div className="flex flex-col gap-[24px]">
                    <HtmlTextReplacer
                      style={{ wordBreak: 'break-word' }}
                      className="break-words"
                      html={currentTest?.tagline}
                    />
                    <div className="flex">
                      <div className="flex flex-1 flex-col gap-[6px]">
                        <Typography
                          className="text-[#6D7175]"
                          variant="regular-default"
                          title="Time Spent"
                        />
                        <Typography
                          variant="regular-default"
                          title={secondsToHms(currentTest?.timeLimit)}
                        />
                      </div>
                      <div className="flex flex-1 flex-col gap-[6px]">
                        <Typography
                          className="text-[#6D7175]"
                          variant="regular-default"
                          title="Difficulty"
                        />
                        <Typography
                          className="capitalize"
                          variant="regular-default"
                          title={currentTest?.difficulty}
                        />
                      </div>
                    </div>
                  </div>
                </Section.Body>
              </Section>
            </div>
          ) : (
            <div className="h-max w-full flex-1 flex-col gap-3 rounded-md bg-white p-6 shadow-med">
              <div className="flex items-center justify-center">
                <DimensionWidget sections={currentTest?.sections} />
              </div>
              <Typography
                variant="regular-default"
                title={currentTest?.result?.resultInterpretation?.description}
              />
            </div>
          )}
          {currentTest?.chartSettings?.chartStyle !== 'dimensions' ? (
            currentTest?.chartSettings?.chartStyle !== 'none' && (
              <Section className="flex-1">
                <Section.Body>
                  {currentTest?.chartSettings?.chartStyle !== 'none' &&
                    charData &&
                    (currentTest?.chartSettings?.chartStyle === 'polar' ? (
                      <div className="flex h-[400px] max-h-[400px] justify-center">
                        <PolarArea
                          data={charData}
                          options={{
                            ...(currentTest?.calculationType === 'avg'
                              ? optionsPolarWithotLegends(
                                  currentTest?.minValue,
                                  currentTest?.maxValue
                                )
                              : optionsPolarWithotLegends(0, 100)),
                            plugins: { legend: { display: true } }
                          }}
                        />
                      </div>
                    ) : (
                      <Bar
                        height={400}
                        data={charData}
                        options={
                          currentTest?.calculationType === 'avg'
                            ? optionsBarChartIsnide(currentTest?.minValue, currentTest?.maxValue)
                            : optionsBarChartIsnide(0, 100)
                        }
                        plugins={[ChartDataLabels]}
                      />
                    ))}
                </Section.Body>
              </Section>
            )
          ) : (
            <div className="h-max w-full flex-1 flex-col rounded-md bg-white p-6 shadow-med">
              <Typography variant="regular-default" title={currentTest?.tagline} />
              <div className="mt-6 flex">
                <div className="flex flex-1 flex-col gap-[6px]">
                  <Typography
                    className="text-[#6D7175]"
                    variant="regular-default"
                    title="Time Spent"
                  />
                  <Typography variant="regular-default" title={secondsToHms(currentTest?.timeLimit)} />
                </div>
                <div className="flex flex-1 flex-col gap-[6px]">
                  <Typography
                    className="text-[#6D7175]"
                    variant="regular-default"
                    title="Difficulty"
                  />
                  <Typography
                    className="capitalize"
                    variant="regular-default"
                    title={currentTest?.difficulty}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {currentTest?.chartSettings?.chartStyle !== 'dimensions' ? (
          ((currentTest?.sections?.length > 1 &&
            currentTest?.sections.some(({ calculatedResult }) => calculatedResult)) ||
            currentTest?.tags?.length > 0) && (
            <Section>
              <Section.Header>
                <Typography title="Dimensions & Traits" variant="heading-h2" />
              </Section.Header>
              <Section.Body>
                <div className="flex flex-col gap-[24px]">
                  {currentTest?.sections?.length > 1 &&
                    currentTest?.sections?.map(
                      ({ sectionName, _id, sectionDescription, calculatedResult, ...props }) => (
                        <ResultCard
                          key={_id}
                          name={sectionName}
                          result={calculatedResult}
                          description={props?.resultInterpretation?.description}
                          title={props?.resultInterpretation?.title}
                          explanation={sectionDescription}
                        />
                      )
                    )}
                  {currentTest?.tags?.map(
                    ({ tagName, _id, tagDescription, calculatedResult, ...props }) => (
                      <ResultCard
                        type
                        key={_id}
                        name={tagName}
                        result={calculatedResult}
                        description={props?.resultInterpretation?.description}
                        title={props?.resultInterpretation?.title}
                        explanation={tagDescription}
                      />
                    )
                  )}
                </div>
              </Section.Body>
            </Section>
          )
        ) : (
          <div className="flex flex-col gap-6">
            <Typography title="Dimensions & Traits" variant="heading-h2" />
            {currentTest?.sections?.map(section => (
              <DimensionCard key={section?._id} {...section} allTags={currentTest?.tags} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewTestResultExplanation;
