import { faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { Icon } from 'ui-kit';

const DefaultUserImage = ({ url, size = 43, fullWidth, alt = 'Image' }) => {
  return url ? (
    <img
      alt={alt}
      className="rounded-md object-contain"
      style={{ height: size, width: fullWidth ? size : '100%' }}
      src={url}
    />
  ) : (
    <Icon icon={faCircleUser} size={size} color="gray" />
  );
};
export default DefaultUserImage;
