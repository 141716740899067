import React, { useEffect } from 'react';
import {
  FieldValues,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
  UseFormReturn
} from 'react-hook-form';
import { List } from 'ui-kit';

interface FieldArrayProps {
  name: string;
  keyExtractor(props: any): number | string;
  element: (props: {
    control: UseFieldArrayReturn<FieldValues, string, 'id'>;
    form: UseFormReturn<FieldValues, any>;
    idx: number;
    props: any;
  }) => React.ReactNode;
  className?: string;
  controller?: (props: {
    name: string;
    form: UseFormReturn<FieldValues, any>;
    control: UseFieldArrayReturn<FieldValues, string, 'id'>;
  }) => React.ReactNode;
  wrap:
    | React.JSXElementConstructor<{
        children: JSX.Element[];
      }>
    | keyof JSX.IntrinsicElements;
  shouldUnregister?: boolean;
}

const FieldArray: React.FC<FieldArrayProps> = ({
  name,
  wrap,
  element,
  className,
  controller,
  keyExtractor,
  shouldUnregister,
}) => {
  const form = useFormContext();
  const control = useFieldArray({ name, control: form.control, shouldUnregister });

  const { fields } = control;

  return (
    <>
      <List
        data={fields}
        element={(props, idx) => element({ props, form, idx, control })}
        keyExtractor={keyExtractor}
        wrap={wrap}
        className={className}
      />
      {controller?.({ control, form, name })}
    </>
  );
};

export default FieldArray;
