export const DimensionWidget = ({ sections }) => (
  <div className="flex w-full flex-col items-center gap-[6px] py-3">
    {sections?.map(({ _id, resultInterpretation, calculatedResult }) => (
      <div className="flex items-center gap-[10px]" key={_id}>
        <p className="w-[300px] text-end text-[#082623]">
          {resultInterpretation?.dimensionScales?.low?.name}
        </p>
        <div
          style={{
            width: `${
              resultInterpretation?.grades?.length * 32 + resultInterpretation?.grades?.length * 6
            }px`
          }}
          className="flex items-center justify-center gap-[6px]">
          {resultInterpretation?.grades
            ?.sort((a, b) => a.scale.min - b.scale.min)
            ?.map(({ scale, _id }) => (
              <div
                className="h-[10px] w-[32px] rounded-sm"
                key={_id}
                style={{
                  backgroundColor: `hsl(${resultInterpretation?.dimensionScales?.hue}, ${
                    scale.max >= calculatedResult && scale.min <= calculatedResult ? '32%' : '31%'
                  }, ${
                    scale.max >= calculatedResult && scale.min <= calculatedResult ? '56%' : '85%'
                  })`
                }}
              />
            ))}
        </div>
        <p className="w-[300px] text-start text-[#082623]">
          {resultInterpretation?.dimensionScales?.high?.name}
        </p>
      </div>
    ))}
  </div>
);
