import Breadcrumbs from 'components/Breadcrumbs';
import TestLibraryModal from 'components/TestLibraryModal/TestLibraryModal';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTestToTemplate,
  createTemplateRequest,
  editTemplateRequest,
  getCurrentTestRequest,
  getJobTitlesRequest,
  getTemplateRequest,
  publishTemplateRequest,
  removeTestFromTemplate,
  resetTest,
  saveTemplatesSummary
} from 'store/tests/actions';
import { Button, ErrorMessage, Form, Input, Section, Typography } from 'ui-kit';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleOccupationRequest } from 'store/configuration/actions/subscriptions';
import { badgetStatus, callToast } from 'utils/helpers';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt/useCallbackPrompt';
import ConfirmChangesModal from 'components/ConfirmChangesModal/ConfirmChangesModal';
import AsyncSelect from 'ui-kit/SelectAsync/SelectAsync';
import { autoFillTemplate } from 'store/tests/actions';
import { TestCardNew } from 'components/TestCardNew/TestCardNew';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';

export const SkillCard = ({ arr, header, onAdd, onDelete, recommendedTests, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const mapItems = item => (
    <div key={item._id} className="flex items-center gap-3">
      <p
        className={`${
          arr?.[0]?.importance && arr?.[0].importance - item.importance >= 10 && 'text-gray-400'
        } min-w-[20px] text-center`}>
        {item.importance || '-'}
      </p>
      <input
        type="checkbox"
        checked={recommendedTests?.some(({ _id }) => _id === item.mappedTests?.[0]?._id)}
        disabled={!item.mappedTests[0] || disabled}
        onChange={e => {
          e.target.checked
            ? onAdd({
                _id: item.mappedTests[0]._id,
                name: item.mappedTests[0].name,
                timeLimit: item.mappedTests[0].timeLimit,
                tagline: item.mappedTests[0].tagline,
                occupationResource: header,
                importance: item.importance
              })
            : onDelete(item.mappedTests[0]._id);
        }}
        className={`h-4 w-4 rounded-sm border border-gray-300 focus:ring-0 focus:ring-offset-0 ${
          item.mappedTests[0] && 'border-[#8C9196]'
        } ${disabled && 'border-[#BDC1CC] checked:bg-[#BDC1CC] checked:hover:bg-[#BDC1CC]'}`}
      />
      <p
        onClick={() => {
          if (item.mappedTests[0]) {
            dispatch(getCurrentTestRequest(item.mappedTests[0]._id));
            setIsModalOpen(true);
          }
        }}
        className={item.mappedTests[0] && 'cursor-pointer text-[#007D6F] underline'}>
        {item.title}
      </p>
    </div>
  );

  return (
    <div className="flex flex-col gap-3">
      <Typography variant="heading-h5" title={header} />
      <div className="flex flex-col gap-1">
        {arr.flatMap(item => (item.items ? item.items.map(mapItems) : mapItems(item)))}
      </div>
      <ViewTestModal isOpenModal={isModalOpen} setIsOpenModal={setIsModalOpen} />
    </div>
  );
};

export const AssessmentTemplateCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useParams();
  const [isLibraryModalOpen, setIsLibraryModalOpen] = useState(false);
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [isPublishConfirmModalOpen, setIsPublishConfirmModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [deleteTest, setDeleteTest] = useState('');

  const { loading, currentTemplate, jobRoles, publishedTests, showDialog, currentTest } =
    useSelector(store => store.tests);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const { loading: load, occupation } = useSelector(store => store.subscriptions);

  useEffect(() => {
    dispatch(getJobTitlesRequest());
  }, []);

  const filterTests = (tests, addedTests) => {
    return tests?.filter(({ _id }) => {
      return addedTests.findIndex(({ _id: id }) => _id === id) === -1;
    });
  };

  const preparedPublished = useMemo(() => {
    let filteredTests = filterTests(publishedTests, currentTemplate?.recommendedTests);
    return filteredTests;
  }, [publishedTests, currentTemplate?.recommendedTests]);

  useEffect(() => {
    _id && dispatch(getTemplateRequest(_id));
  }, [_id]);

  useEffect(() => {
    currentTemplate?.occupation && dispatch(getSingleOccupationRequest(currentTemplate.occupation));
  }, [currentTemplate?.occupation]);

  const addTestHandler = () => {
    const { _id, name, timeLimit, tagline, category, icon } = currentTest;
    dispatch(addTestToTemplate({ test: { _id, name, timeLimit, tagline, category, icon } }));
  };

  const addTestMappedHandler = test => dispatch(addTestToTemplate({ test }));

  const removeTestFromTemplateHandler = test => dispatch(removeTestFromTemplate({ test }));

  const preparedJobRoles = useMemo(() => {
    return jobRoles?.jobTitles?.map(value => {
      return { label: value, value };
    });
  }, [jobRoles]);

  const onSubmit = data => {
    const newValues = data.jobRoles?.map(({ value }) => {
      return value;
    });
    dispatch(saveTemplatesSummary({ ...data, jobRoles: newValues }));
  };

  const onFill = () => {
    dispatch(autoFillTemplate({ occupation }));
  };

  const createDraftHandler = () => {
    if (currentTemplate?.name?.trim()?.length === 0) {
      callToast('error', 'Assessment Template name is required');
      return;
    }
    currentTemplate?._id
      ? dispatch(editTemplateRequest({ id: currentTemplate?._id, data: currentTemplate }))
      : dispatch(createTemplateRequest({ data: currentTemplate, navigate }));
  };

  const publishDraftHandler = () => {
    if (currentTemplate?.name?.trim()?.length === 0) {
      callToast('error', 'Assessment Template name is required');
      return;
    }
    if (currentTemplate?.recommendedTests?.length === 0) {
      callToast('error', 'At least one test should be added');
      return;
    }
    currentTemplate?._id
      ? dispatch(editTemplateRequest({ id: currentTemplate?._id, data: currentTemplate }))
      : dispatch(createTemplateRequest({ data: currentTemplate, navigate }));
    setIsPublishConfirmModalOpen(true);
  };

  const previewTestHandler = id => {
    dispatch(getCurrentTestRequest(id));
    setViewModalOpen(true);
  };

  return (
    <div className="py-8 px-7.5">
      {/* {(load || loading) && <Loader />} */}
      <Breadcrumbs type="assessmentTemplates" />
      <div className="flex justify-between">
        <div className="flex items-center gap-[12px]">
          <Typography
            className="max-w-[850px]"
            title={currentTemplate?._id ? currentTemplate.name : 'Create Assessment Template'}
            variant="heading-h1"
          />
          {_id && (
            <span
              className={`rounded ${badgetStatus(
                currentTemplate?.status
              )} py-[4px] px-[8px] font-semibold capitalize`}>
              {`${currentTemplate?.status}`}
            </span>
          )}
        </div>
        <div className="flex items-center gap-3">
          <Button variant="secondary" title="Save Draft" onClick={createDraftHandler} />
          <Button variant="primary" title="Publish Template" onClick={publishDraftHandler} />
        </div>
      </div>
      <Section className="mt-6">
        <Section.Header>
          <Typography variant="heading-h2" title="Summary" />
        </Section.Header>
        <Section.Body>
          <Form
            defaultValues={{
              name: currentTemplate?.name,
              jobRoles: currentTemplate?.jobRoles?.map(value => {
                return { label: value, value };
              }),
              type: currentTemplate?.type
            }}
            onSubmit={onSubmit}
            onBlur
            mode="onChange">
            {({ handleSubmit }) => (
              <div className="flex flex-col gap-5 xl:flex-row xl:space-x-5">
                <div className="flex flex-1 flex-col gap-1">
                  <Typography title="Assessment Template Name" variant="small-default" />
                  <Input showMaxLength maxLength={100} name="name" />
                  <ErrorMessage name="name" />
                </div>
                <div className="flex flex-1 flex-col gap-6">
                  <div className="flex flex-col gap-1">
                    <Typography title="Template Type" />
                    <SelectCustom
                      name="type"
                      onChangeValue={handleSubmit(onSubmit)}
                      isClearable={false}
                      isSearchable={false}
                      options={[
                        { value: 'role_specific', label: 'Role-Specific Template' },
                        { value: 'generic', label: 'Generic Template' }
                      ]}
                    />
                  </div>
                  {currentTemplate?.type === 'role_specific' && (
                    <div className="flex flex-col gap-1">
                      <Typography title="Job Roles" />
                      <AsyncSelect
                        name="jobRoles"
                        onChangeValue={handleSubmit(onSubmit)}
                        options={preparedJobRoles}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Form>
        </Section.Body>
      </Section>
      {occupation?._id && (
        <Section className="mt-6">
          <Section.Header className="flex items-center justify-between">
            <div className="flex gap-3">
              <Typography variant="heading-h2" title="Occupation" />
              <Typography
                className="text-[#6D7175]"
                variant="heading-h2"
                title={occupation?.title}
              />
            </div>
            <div className="flex items-center gap-3">
              <a rel="noreferrer">
                <Button
                  className="bg-white"
                  variant="primary-outline"
                  title="Auto Select"
                  onClick={onFill}
                />
              </a>
              <a
                href={`https://www.onetonline.org/link/details/${occupation?.code}`}
                target="_blank"
                rel="noreferrer">
                <Button
                  icon="onet"
                  className="bg-white"
                  variant="primary-outline"
                  title="onetonline.org"
                />
              </a>
            </div>
          </Section.Header>
          <Section.Body className="grid grid-cols-3 gap-8">
            {occupation?.skills && (
              <SkillCard
                onAdd={addTestMappedHandler}
                arr={occupation?.skills}
                header="Skills"
                recommendedTests={currentTemplate?.recommendedTests}
                onDelete={removeTestFromTemplateHandler}
              />
            )}
            {occupation?.technology_skills && (
              <SkillCard
                onAdd={addTestMappedHandler}
                arr={occupation?.technology_skills}
                header="Technology Skills"
                recommendedTests={currentTemplate?.recommendedTests}
                onDelete={removeTestFromTemplateHandler}
              />
            )}
            {occupation?.abilities && (
              <SkillCard
                onAdd={addTestMappedHandler}
                arr={occupation?.abilities}
                header="Abilities"
                recommendedTests={currentTemplate?.recommendedTests}
                onDelete={removeTestFromTemplateHandler}
              />
            )}
            {occupation?.work_styles && (
              <SkillCard
                onAdd={addTestMappedHandler}
                arr={occupation?.work_styles}
                header="Work Styles"
                recommendedTests={currentTemplate?.recommendedTests}
                onDelete={removeTestFromTemplateHandler}
              />
            )}
            {occupation?.knowledge && (
              <SkillCard
                onAdd={addTestMappedHandler}
                arr={occupation?.knowledge}
                header="Knowledge"
                recommendedTests={currentTemplate?.recommendedTests}
                onDelete={removeTestFromTemplateHandler}
              />
            )}
            {occupation?.work_context && (
              <SkillCard
                onAdd={addTestMappedHandler}
                arr={occupation?.work_context}
                header="Work Context"
                recommendedTests={currentTemplate?.recommendedTests}
                onDelete={removeTestFromTemplateHandler}
              />
            )}
            {occupation?.work_activities && (
              <SkillCard
                onAdd={addTestMappedHandler}
                arr={occupation?.work_activities}
                header="Work Activities"
                recommendedTests={currentTemplate?.recommendedTests}
                onDelete={removeTestFromTemplateHandler}
              />
            )}
          </Section.Body>
        </Section>
      )}
      <Section className="mt-6">
        <Section.Header className="flex gap-3">
          <div className="bg-[#F6F6F7] px-[8px] py-[2px]">{currentTemplate?.totalTests}</div>
          <Typography variant="heading-h2" title="Recommended Tests" />
        </Section.Header>
        <Section.Body className="flex flex-col gap-[72px]">
          <div className="flex flex-col gap-6">
            <div className="flex w-full">
              <div
                className="group flex w-full cursor-pointer items-center justify-center
                gap-3 rounded-lg border-2 border-dashed border-gray-300 fill-gray-400 p-9.5
                font-semibold text-gray-400 hover:border-[#12A182] hover:bg-[#F3F8F6] hover:text-[#12A182]
                "
                onClick={() => {
                  setIsLibraryModalOpen(true);
                }}>
                <i className="fa-light fa-plus text-[20px]"></i> Add Test
              </div>
            </div>
            <div className="flex flex-wrap gap-6">
              {currentTemplate?.recommendedTests.map(
                ({ name, _id, icon, category, timeLimit, estimatedDuration }) => (
                  <TestCardNew
                    key={_id}
                    previewTestHandler={previewTestHandler}
                    icon={icon}
                    category={category}
                    name={name}
                    _id={_id}
                    timeLimit={timeLimit}
                    estimatedDuration={estimatedDuration}
                    className="w-full"
                    containerClassName="w-full"
                    actionsComponent={
                      <div
                        className="ml-auto flex h-full min-w-[100px] cursor-pointer items-center justify-center
                      border-l-[1px] border-[#D2D5D8] bg-[#FAFBFB] text-[#6D7175] hover:bg-[#FFF0F0] hover:text-[#B31F13]"
                        onClick={e => {
                          e.stopPropagation();
                          setDeleteTest({ name, _id });
                          setIsDeleteConfirmModalOpen(true);
                        }}>
                        <div className="w-full text-center">
                          <div>
                            <i className="fa-light fa-trash-can h-[16px] w-[16px] text-[16px]"></i>
                          </div>
                          <div className="text-[14px] font-semibold">Remove</div>
                        </div>
                      </div>
                    }
                  />
                )
              )}
            </div>
          </div>
        </Section.Body>
      </Section>
      <TestLibraryModal
        tests={preparedPublished}
        isModalOpen={isLibraryModalOpen}
        setModalOpen={() => {
          dispatch(resetTest());
          setIsLibraryModalOpen(false);
        }}
        onClick={addTestHandler}
      />
      <ConfirmationModal
        isConfirmModalOpen={isDeleteConfirmModalOpen}
        setConfirmModalOpen={() => setIsDeleteConfirmModalOpen(false)}
        headerText="Remove Test"
        bodyText={
          <p>
            Remove <b>{deleteTest?.name}?</b>
          </p>
        }
        buttonText="Remove"
        buttonAction={() => {
          removeTestFromTemplateHandler(deleteTest?._id);
        }}
        danger
      />
      <ConfirmationModal
        isConfirmModalOpen={isPublishConfirmModalOpen}
        setConfirmModalOpen={() => setIsPublishConfirmModalOpen(false)}
        headerText="Publish Assessment Template"
        bodyText={
          <p>
            publish <b>{currentTemplate?.name}?</b>
          </p>
        }
        buttonText="Publish Template"
        buttonAction={() => {
          dispatch(publishTemplateRequest({ id: currentTemplate?._id, navigate }));
          setIsDeleteConfirmModalOpen(false);
        }}
      />
      <ConfirmChangesModal
        isConfirmModalOpen={showPrompt}
        closeConfirmModal={cancelNavigation}
        bodyText={currentTemplate?.name || 'Template'}
        confirmNavigation={confirmNavigation}
        buttonAction={createDraftHandler}
      />
      <ViewTestModal isOpenModal={viewModalOpen} setIsOpenModal={setViewModalOpen} />
    </div>
  );
};
