import { commonApi } from 'api/appApi';
import { put, takeLatest } from 'redux-saga/effects';
import {
  candidateConstants,
  setApplicationRatingSuccess,
  setApplicationRatingFailure,
  getAllCandidateSuccess,
  getAllCandidateFailure,
  getTopPerformersSuccess,
  getTopPerformersFailure,
  getPerformanceSuccess,
  getPerformanceFailure,
  getRecentApplicationsSuccess,
  getRecentApplicationsFailure,
  getCandidateSuccess,
  getCandidateFailure,
  getApplicationSuccess,
  getApplicationFailure,
  getEmbeddedApplicationSuccess,
  getEmbeddedApplicationFailure,
  setApplicationNoteSuccess,
  setApplicationNoteFailure,
  setApplicationStatusSuccess,
  setApplicationStatusFailure,
  setApplicationOptionalStatusSuccess,
  setApplicationOptionalStatusFailure,
  restoreApplicationStatusSuccess,
  restoreApplicationStatusFailure,
  getApplicationInfoSuccess,
  getApplicationInfoFailure,
  getApplicationTestsSuccess,
  getApplicationTestsFailure,
  getApplicationQuestionsSuccess,
  getApplicationQuestionsFailure,
  getAllApplicationSuccess,
  getAllApplicationFailure,
  getPendingApplicationSuccess,
  getPendingApplicationFailure
} from 'store/candidates/actions';

import { callToast } from 'utils/helpers';

function* getCandidatesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getAllCandidates(payload);
    yield put(getAllCandidateSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting candidates');
    yield put(getAllCandidateFailure(error.response.status));
  }
}

function* getCandidateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getCandidate(payload);
    yield put(getCandidateSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting candidate');
    yield put(getCandidateFailure(error.response.status));
  }
}

function* getApplicationSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getApplication(payload);
    yield put(getApplicationSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting application');
    yield put(getApplicationFailure(error.response.status));
  }
}

function* getEmbeddedApplicationSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getApplication(payload);
    yield put(getEmbeddedApplicationSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting application');
    yield put(getEmbeddedApplicationFailure(error.response.status));
  }
}

function* getAllApplicationsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getAllApplications(payload);
    yield put(getAllApplicationSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting applications');
    yield put(getAllApplicationFailure(error.response.status));
  }
}

function* getTopPerformersSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getAllApplications(payload);
    yield put(getTopPerformersSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting applications');
    yield put(getTopPerformersFailure(error.response.status));
  }
}

function* getPerformanceSaga() {
  try {
    const { data } = yield commonApi.getPerformance();
    yield put(getPerformanceSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting performance');
    yield put(getPerformanceFailure(error.response.status));
  }
}

function* getRecentApplicationsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getAllApplications(payload);
    yield put(getRecentApplicationsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting applications');
    yield put(getRecentApplicationsFailure(error.response.status));
  }
}

function* getPendingApplicationsSaga() {
  try {
    const { data } = yield commonApi.getPendingApplications();
    yield put(getPendingApplicationSuccess(data));
  } catch (error) {
    yield put(getPendingApplicationFailure(error.response.status));
  }
}

function* getApplicationInfoSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getApplicationInfo(payload);
    yield put(getApplicationInfoSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting application');
    yield put(getApplicationInfoFailure(error.response.status));
  }
}

function* getApplicationTestsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getApplicationTests(payload);
    yield put(getApplicationTestsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting application');
    yield put(getApplicationTestsFailure(error.response.status));
  }
}

function* getApplicationQuestionsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getApplicationQuestions(payload);
    yield put(getApplicationQuestionsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting application');
    yield put(getApplicationQuestionsFailure(error.response.status));
  }
}

function* getApplicationSubscribedSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getApplicationSubscribed(payload);
    yield put(getApplicationSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in getting application');
    yield put(getApplicationFailure(error.response.status));
  }
}

function* setApplicationRatingSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.setApplicationRating(payload);
    yield put(setApplicationRatingSuccess(data));
    callToast('success', 'Success - Application rating was changed');
  } catch (error) {
    callToast('error', 'Error - Error in change application rating');
    yield put(setApplicationRatingFailure(error.response.status));
  }
}

function* setApplicationStatusSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.setApplicationStatus(payload);
    yield put(setApplicationStatusSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in changing status of application');
    yield put(setApplicationStatusFailure(error.response.status));
  }
}

function* restoreApplicationStatusSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.restoreApplicationStatus(payload);
    yield put(restoreApplicationStatusSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in restoring status of application');
    yield put(restoreApplicationStatusFailure(error.response.status));
  }
}

function* setApplicationOptionalStatusSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.setApplicationOptionalStatus(payload);
    yield put(setApplicationOptionalStatusSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Error in changing status of application');
    yield put(setApplicationOptionalStatusFailure(error.response.status));
  }
}

function* setApplicationNote(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.setApplicationNotes(payload);
    yield put(setApplicationNoteSuccess(data));
    callToast('success', 'Success - Application note was changed');
  } catch (error) {
    callToast('error', 'Error - Error in change application note');
    yield put(setApplicationNoteFailure(error.response.status));
  }
}
export default function* candidateWatcher() {
  yield takeLatest(candidateConstants.GET_CANDIDATE_REQUEST, getCandidateSaga);
  yield takeLatest(candidateConstants.GET_ALL_CANDIDATE_REQUEST, getCandidatesSaga);
  yield takeLatest(candidateConstants.GET_TOP_PERFORMERS_REQUEST, getTopPerformersSaga);
  yield takeLatest(candidateConstants.GET_PERFORMANCE_REQUEST, getPerformanceSaga);
  yield takeLatest(candidateConstants.GET_RECENT_APPLICATIONS_REQUEST, getRecentApplicationsSaga);
  yield takeLatest(candidateConstants.GET_APPLICATION_REQUEST, getApplicationSaga);
  yield takeLatest(candidateConstants.GET_EMBEDDED_APPLICATION_REQUEST, getEmbeddedApplicationSaga);
  yield takeLatest(
    candidateConstants.GET_APPLICATION_SUBSCRIBED_REQUEST,
    getApplicationSubscribedSaga
  );
  yield takeLatest(candidateConstants.SET_APPLICATION_RATING_REQUEST, setApplicationRatingSaga);
  yield takeLatest(candidateConstants.SET_APPLICATION_NOTE_REQUEST, setApplicationNote);
  yield takeLatest(candidateConstants.SET_APPLICATION_STATUS_REQUEST, setApplicationStatusSaga);
  yield takeLatest(candidateConstants.GET_ALL_APPLICATION_REQUEST, getAllApplicationsSaga);
  yield takeLatest(
    candidateConstants.SET_APPLICATION_OPTIONAL_STATUS_REQUEST,
    setApplicationOptionalStatusSaga
  );
  yield takeLatest(
    candidateConstants.RESTORE_APPLICATION_STATUS_REQUEST,
    restoreApplicationStatusSaga
  );
  yield takeLatest(candidateConstants.GET_APPLICATION_INFO_REQUEST, getApplicationInfoSaga);
  yield takeLatest(candidateConstants.GET_APPLICATION_TESTS_REQUEST, getApplicationTestsSaga);
  yield takeLatest(
    candidateConstants.GET_APPLICATION_QUESTIONS_REQUEST,
    getApplicationQuestionsSaga
  );
  yield takeLatest(candidateConstants.GET_PENDING_APPLICATION_REQUEST, getPendingApplicationsSaga);
}
