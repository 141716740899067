import { emptyStringToNull } from 'components/Forms/validation';
import * as Yup from 'yup';

export const resultInterpretationCalculate = (sections, method, mode, name, _id) => {
  let questions = [];
  let min = 0;
  let max = 0;

  switch (mode) {
    case 'Overall':
      questions = sections.flatMap(section => section.questions);
      break;

    case 'Section':
      questions = sections.find(sect => sect._id === _id).questions;
      break;

    case 'Tag':
      questions = sections
        .flatMap(section => section.questions)
        .filter(question => question.tags.some(tag => tag.value === name));
      break;

    default:
      questions = sections.map(section => section.questions).flat();
      break;
  }

  if (questions.length === 0) {
    return [0, 0];
  }

  for (let i = 0; i < questions.length; i++) {
    const scoreArr = questions[i].answers.map(({ score }) => score);
    if (questions[i].type === 'single') {
      min += Math.min(...scoreArr);
      max += Math.max(...scoreArr);
    } else if (questions[i].type === 'multiple') {
      min += Math.min(...scoreArr);
      scoreArr.map(score => score > 0 && (max += score));
    }
  }

  return method === 'sum'
    ? [min, max]
    : [+(min / questions.length)?.toFixed(2), +(max / questions.length)?.toFixed(2)];
};

export const resultInterpretationValidation = arr =>
  Yup.object({
    resultInterpretation: Yup.object({
      dimensionScales: Yup.object({
        hue: Yup.number()
          .transform(emptyStringToNull)
          .nullable()
          .positive('Hue must be positive')
          .min(0, 'Hue must be more than 0')
          .max(250, 'Hue must be less than 250')
      }),
      description: Yup.string().label('Description').max(1000),
      grades: Yup.array().of(
        Yup.object({
          scale: Yup.object({
            min: Yup.number()
              .transform(emptyStringToNull)
              .nullable()
              .positive('must be positive')
              .min(arr[0], `Min score must be more than or equal to ${arr[0]}`)
              .max(arr[1], `Min score must be less than or equal to ${arr[1]}`),
            max: Yup.number()
              .transform(emptyStringToNull)
              .nullable()
              .positive('must be positive')
              .min(arr[0], `Max score must be more than or equal to ${arr[0]}`)
              .max(arr[1], `Max score must be less than or equal to ${arr[1]}`)
          }),
          description: Yup.string().label('Description').max(1000),
          title: Yup.string().label('Title').max(50)
        })
      )
    })
  });
