import { useEffect, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useUserRole } from 'hooks';

import Loader from 'components/Loader';
import Breadcrumbs from 'components/Breadcrumbs';
import TimeBadget from 'components/TimeBadget';
import confetti from 'assets/lotties/confetti.json';

import { Typography, Button, Modal, Form, Input, ErrorMessage, Icon } from 'ui-kit';

import { badgetStatus } from 'utils/helpers';
import { draftAssessmentSchema } from 'components/Forms/validation';

import {
  archiveAssessmentRequest,
  duplicateAssessmentRequest,
  getAssessmentApplicationsRequest,
  getAssessmetRequest,
  restoreAssessmentRequest
} from 'store/assessment/actions';

import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { ViewSummary } from './ViewSummary/ViewSummary';
import Stepper from 'components/CustomStepper';
import { steps } from 'mockData/stepperSteps';
import { resendEmailRequest, setUpgradeModalOpen } from 'store/auth/actions';
import { generateSniperLink } from 'utils/gerenateSniperLink';
import { localStorageService } from 'helpers/localStorageService';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

export const ViewAssessment = () => {
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [sniperLink, setSniperLink] = useState({ url: '', name: '' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const { role } = useUserRole();
  const location = useLocation();

  const { company } = useSelector(store => store.auth);

  const resendVerificationEmail = () => {
    dispatch(resendEmailRequest({ userId: localStorageService.getUserId() }));
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const state = location.state;

  const isAdmin = role === 'admin';

  const timeNow = moment();

  const { loading, currentAssessment, applications } = useSelector(store => store.assessments);
  const { loading: load, currentTest } = useSelector(store => store.tests);
  const { loading: loads, user } = useSelector(store => store.auth);

  useEffect(() => {
    if (user?.email) {
      setSniperLink(generateSniperLink(localStorageService.getDomain(), user?.email));
    }
  }, [user?.email]);

  const daysLeft = currentAssessment?.expiresAt
    ? moment(currentAssessment?.expiresAt).diff(timeNow, 'days')
    : currentAssessment?.daysLeft;

  useEffect(() => {
    dispatch(getAssessmetRequest(param._id));
    dispatch(getAssessmentApplicationsRequest(param._id));
  }, [param._id]);

  const testTime = currentAssessment?.tests.reduce(
    (acc, cur) => acc + (cur.estimatedDuration ?? cur.timeLimit),
    0
  );
  const customTime = currentAssessment.customQuestions.timeLimit;

  const archivedAssessmentHandler = () => {
    dispatch(archiveAssessmentRequest({ id: currentAssessment._id, navigate }));
  };

  const restoreAssessmentHandler = () => {
    dispatch(restoreAssessmentRequest({ id: currentAssessment._id, navigate }));
  };

  const duplicateAssessmentHandler = data => {
    dispatch(duplicateAssessmentRequest({ id: currentAssessment._id, data, navigate }));
  };

  return (
    <div className="py-8 px-7.5">
      {(loading || load || loads) && <Loader />}
      <Breadcrumbs />
      <div className="mb-5 flex justify-between">
        <div className="flex items-center gap-3">
          <Typography
            className="max-w-[600px] break-words"
            title={currentAssessment?.name || 'Default assessment name'}
            variant="heading-h1"
          />
          <span
            className={`flex items-center rounded-md px-[8px] py-[4px] ${badgetStatus(
              currentAssessment?.status
            )}`}>
            <Typography
              title={currentAssessment?.status}
              variant="small-bold "
              className="capitalize"
            />
          </span>
          {daysLeft && (
            <div className="flex h-[22px] min-w-max items-center gap-[4.5px] rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm font-semibold text-[#05443D] shadow-small">
              {`${daysLeft} days left`}
            </div>
          )}
          <div className="flex min-w-max items-center">
            <TimeBadget
              className="inline-flex w-auto font-semibold"
              time={testTime + customTime}
              icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
            />
          </div>
        </div>
        {!isAdmin && (
          <div className="flex items-center gap-3 font-bold">
            {currentAssessment.status === 'published' ? (
              <Button
                variant="secondary"
                onClick={() => setArchiveModalOpen(true)}
                title={
                  !currentAssessment?.demo?.isDemo
                    ? 'Archive Assessment'
                    : 'Archive Demo Assessment'
                }
              />
            ) : (
              !currentAssessment?.demo?.isDemo && (
                <Button
                  variant="secondary"
                  title="Restore"
                  onClick={() => {
                    if (company?.availablePublications <= 0) {
                      dispatch(setUpgradeModalOpen(true));
                    } else {
                      dispatch(restoreAssessmentHandler());
                    }
                  }}
                />
              )
            )}
            {!currentAssessment?.demo?.isDemo && (
              <Button
                variant="secondary"
                title="Duplicate Assessment"
                onClick={() => setDuplicateModalOpen(true)}
              />
            )}
          </div>
        )}
      </div>
      {currentAssessment?.demo?.isDemo && (
        <div className="mb-6 flex gap-6 rounded-md border border-[#E5EFFD] bg-[#F2F7FE] px-[20px] py-[16px] shadow-md">
          <Icon icon={faCircleQuestion} size={20} color="#018AC1" />
          <div className="flex flex-col gap-2 text-sm">
            <Typography variant="heading-h6" title="This is a Demo Assessment." />
            Any changes will not affect it and will be returned to its original state after the page
            refreshes.
            <br />
            If you pass the demo assessment, a demo application won’t be created.
            <br />
            Also, you will not be able to send the assessment link to the candidate's email, but you
            can take the assessment using the public link.
          </div>
        </div>
      )}
      {state?.template && <Stepper steps={steps} activeStep={2} />}
      <ViewSummary
        currentAssessment={currentAssessment}
        applications={applications}
        currentTest={currentTest}
        customTime={customTime}
        isAdmin={isAdmin}
        testTime={testTime}
        verified={!!user?.verifiedAt}
        resendVerificationEmail={resendVerificationEmail}
        sniperLink={sniperLink}
      />
      <Modal
        isOpen={archiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
        className="m-auto w-full max-w-[600px]">
        <Modal.Header>
          <Typography
            variant="heading-h4"
            title={`Archive ${currentAssessment?.name} Assessment`}
          />
          <Button
            icon={faXmark}
            iconSize={20}
            iconColor="#5C5F62"
            onClick={() => setArchiveModalOpen(false)}
          />
        </Modal.Header>
        <Modal.Body className="p-6">
          {currentAssessment?.demo?.isDemo ? (
            <div>
              Are you sure you want to archive <b>{currentAssessment?.name}</b> assessment?
            </div>
          ) : (
            <div>
              Are you sure you want to archive <b>{currentAssessment?.name}</b> assessment?
              <br />
              <br />
              Candidates wouldn't be able to submit new applications to this assessment.
              <br />
              <br />
              Remaiming assessment lifetime is <b>{daysLeft} days.</b> You will be able to
              re-publish this assessment later for free
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="flex justify-end gap-4">
          <div className="flex justify-end gap-4">
            <Button
              variant="primary-outline"
              title="Cancel"
              onClick={() => setArchiveModalOpen(false)}
            />
            <Button
              variant="danger"
              title="Archive Assessment"
              onClick={() => {
                archivedAssessmentHandler();
                setArchiveModalOpen(false);
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
      <Modal isOpen={duplicateModalOpen} className="m-auto w-full max-w-[600px]">
        <Form
          defaultValues={{
            name: currentAssessment?.name
          }}
          resolver={yupResolver(draftAssessmentSchema)}
          mode="onChange">
          {({ getValues }) => (
            <>
              <Modal.Header>
                <Typography variant="heading-h4" title={`Duplicate ${currentAssessment?.name}`} />
                <Button
                  icon={faXmark}
                  iconSize={20}
                  iconColor="#5C5F62"
                  onClick={() => setDuplicateModalOpen(false)}
                />
              </Modal.Header>
              <Modal.Body className="p-6">
                New Assessment will have the same Summary, Tests and Custom Questions
                <div className="mt-4">
                  <Typography title="New Assessment Name" variant="small-default" />
                  <Input name="name" />
                  <ErrorMessage name="name" />
                </div>
              </Modal.Body>
              <Modal.Footer className="flex justify-end gap-4">
                <div className="flex justify-end gap-4">
                  <Button
                    variant="primary-outline"
                    title="Cancel"
                    onClick={() => setDuplicateModalOpen(false)}
                  />
                  <Button
                    variant="primary"
                    title="Duplicate Assessment"
                    onClick={() => {
                      const data = getValues();
                      duplicateAssessmentHandler(data);
                      setDuplicateModalOpen(false);
                    }}
                  />
                </div>
              </Modal.Footer>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};
