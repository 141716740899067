import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { clearAssessmentStore, getAssessmetRequest } from 'store/assessment/actions';
import Loader from 'components/Loader';
import { CreateAssessment } from '../CreateAssessment';

export const EditAssessment = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();
  const { loading } = useSelector(store => store.assessments);

  useEffect(() => {
    _id && dispatch(getAssessmetRequest(_id));

    return () => dispatch(clearAssessmentStore());
  }, [_id]);

  return (
    <div>
      {loading && <Loader />}
      <CreateAssessment />
    </div>
  );
};
