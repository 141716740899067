import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp as FontAwesomeIconProp } from '@fortawesome/fontawesome-svg-core';

type IconProps = {
  icon: FontAwesomeIconProp,
  color?: string,
  size?: number | string,
  className?: string,
  onClick?: any
};

const Icon: React.FC<IconProps> = props => {
  const { color, size = '100%', icon, className = '', onClick, ...rest } = props;

  return (
    <FontAwesomeIcon
      onClick={onClick}
      className={className}
      style={{ color, fontSize: size }}
      icon={icon}
      {...rest}
    />
  );
};

export default Icon;
