import * as routes from 'routes/routes';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from 'ui-kit';
import { getFullName } from 'utils/helpers';
import { useUserRole } from 'hooks';

const Breadcrumbs = ({ type }) => {
  const location = useLocation();
  const mainPage = location.pathname.split('/');
  const { role } = useUserRole();
  const { currentTest } = useSelector(store => store.tests);
  const { currentAssessment } = useSelector(store => store.assessments);
  const { application } = useSelector(store => store.candidates);
  const { currentEmployer } = useSelector(store => store.admin);
  const getSettings = () => {
    const base = { name: mainPage[1], link: `/${mainPage[1]}` };
    switch (type) {
      case 'test':
        return [
          {
            name: role === 'author' ? 'My Tests' : 'Tests',
            link: base.link
          },
          {
            name: currentTest?.parentCategory?.name,
            link: routes.TESTS + `?category=${currentTest?.parentCategory?._id}`
          },
          {
            name: currentTest?.category?.name,
            link: routes.TESTS + `?category=${currentTest?.category?._id}`
          }
        ];
      case 'modalViewTest':
        return [
          {
            name: currentTest?.parentCategory?.name,
            link: '#'
          },
          {
            name: currentTest?.category?.name,
            link: '#'
          }
        ];
      case 'publishTest':
        return [
          base,
          {
            name: currentTest?.parentCategory?.name,
            link: routes.TESTS + `?category=${currentTest?.parentCategory?._id}`
          },
          {
            name: currentTest?.category?.name,
            link: routes.TESTS + `?category=${currentTest?.category?._id}`
          },
          { name: currentTest.name, link: routes.TESTS + `/${currentTest._id}` + routes.EDIT }
        ];
      case 'publishAssessment':
        return [
          base,
          {
            name: currentAssessment.name,
            link: routes.ASSESSMENTS + `/${currentAssessment._id}` + routes.EDIT
          }
        ];
      case 'draftTest':
        return [base];
      case 'assessmentTemplates':
        return [
          {
            name: 'My Assessment Templates',
            link: routes.ASSESSMENT_TEMPLATES
          }
        ];
      case 'draftAssessment':
        return [{ name: 'My Assessments', link: routes.ASSESSMENTS }];
      case 'application':
        return [
          base,
          {
            name: application.assessment.name,
            link: routes.ASSESSMENTS + `/${application.assessment.assessmentId}`
          }
        ];
      case 'test-result':
        return [
          base,
          {
            name: application?.assessment?.name,
            link: routes.ASSESSMENTS + `/${application?.assessment?.assessmentId}`
          },
          {
            name: getFullName(application?.candidate && application.candidate[0]),
            link:
              routes.ASSESSMENTS +
              `/${
                application?.assessment?.assessmentId + routes.APPLICATIONS + `/${application?._id}`
              }`
          }
        ];
      case 'billing':
        return [base, { name: 'billing', link: routes.CONFIGURATION + '/' + routes.BILLING }];
      case 'test-type':
        return [
          base,
          { name: 'Tests', link: routes.CONFIGURATION + '/' + routes.CONFIGURATION_TESTS }
        ];
      case 'occupation':
        return [
          base,
          { name: 'Occupations', link: routes.CONFIGURATION + '/' + routes.OCCUPATIONS }
        ];
      case 'test-mapping-single':
        return [
          base,
          { name: 'Occupations', link: routes.CONFIGURATION + '/' + routes.OCCUPATIONS },
          { name: 'Tests Mapping', link: routes.CONFIGURATION_TEST_MAPPING_ROUTE }
        ];
      case 'company':
        return [base];
      case 'employer':
        return [
          base,
          {
            name: currentEmployer.companyName,
            link: routes.EMPLOYERS + routes.COMPANY_ALL + '/' + currentEmployer.company
          }
        ];
      case 'editAdmin':
        return [
          base,
          {
            name: 'Admins',
            link: routes.CONFIGURATION + '/' + routes.ADMINS
          }
        ];
      default:
        return mainPage.slice(1, -1).map(item => {
          return { name: item, link: `/${item}` };
        });
    }
  };

  return (
    <ul className="flex">
      {getSettings()?.map((item, index) => (
        <li key={index} className="flex">
          <Link to={item.link}>
            <Typography title={item.name} variant="regular-default" className="capitalize text-green-400" />
          </Link>
          {getSettings()?.length !== index + 1 && item?.name && <span className="mx-2">/</span>}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
