import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';
import { Button, Section, Typography } from 'ui-kit';

export const OccupationCard = ({
  arr,
  name,
  previewTestHandler
}: {
  arr: any,
  name: string,
  previewTestHandler: (id: string) => void
}) => {
  const [open, setOpen] = useState(false);

  const preparedArr = open ? arr : arr.slice(0, 10);
  return (
    <Section>
      <Section.Header className="flex items-center gap-3">
        <Typography variant="heading-h2" title={name} />
        {arr.length > 10 && (
          <Button
            onClick={() => setOpen(prev => !prev)}
            icon={open ? faChevronUp : faChevronDown}
            variant="primary-outline"
            title={`10 of ${arr.length} displayed`}
          />
        )}
      </Section.Header>
      <Section.Body className="space-y-[24px]">
        <div className="flex gap-8">
          <Typography className="w-[120px] text-end" variant="regular-emphasis" title="Importance" />
          <Typography className="w-[200px]" variant="regular-emphasis" title="Category" />
          <Typography className="flex-1" variant="regular-emphasis" title="Description" />
        </div>
        {preparedArr.map(({ _id, importance, description, category, title, mappedTests }: any) => (
          <div key={_id} className="flex gap-8">
            <Typography
              className={`w-[120px] text-end ${
                arr[0]?.importance && arr[0].importance - importance >= 10 && 'text-gray-400'
              }`}
              variant="regular-default"
              title={importance || 'Not avaliable'}
            />
            <Typography
              onClick={() => title && previewTestHandler(mappedTests[0])}
              className={`w-[200px] ${
                title && mappedTests?.length > 0 && 'cursor-pointer text-green-400'
              }`}
              variant={title && mappedTests?.length > 0 ? 'underline' : 'regular-default'}
              title={category || title || 'Not avaliable'}
            />
            <Typography
              onClick={() => category && previewTestHandler(mappedTests[0])}
              className={`w-[200px] flex-1 ${
                category && mappedTests?.length > 0 && 'cursor-pointer text-green-400'
              }`}
              variant={category && mappedTests?.length > 0 ? 'underline' : 'regular-default'}
              title={description}
            />
          </div>
        ))}
      </Section.Body>
    </Section>
  );
};
