import { useEffect, useState } from 'react';

import { useOutsideClick } from 'hooks/useOutsideClick/useOutsideClick';
import { twMerge } from 'tailwind-merge';
import Icon from 'ui-kit/Icon';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

export const ButtonSelect = ({
  buttonTitle,
  children,
  className,
  iconClassname,
  iconColor,
  width,
  disabled = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => !disabled && setIsOpen(!isOpen);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [buttonTitle]);

  const ref = useOutsideClick(handleClickOutside);
  return (
    <div ref={ref} className="group relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        type="button"
        className={twMerge(
          `group flex w-full ${
            isOpen && 'border-[#E3714F] bg-[#FFE9E8] text-[#B31F13]'
          } items-center justify-center gap-[10px] rounded-md border border-[#C9CCCF] font-semibold text-black
        ${!disabled && 'hover:border-[#FED3D1] hover:bg-[#FFF0F0] hover:text-[#E25136]'}`,
          className
        )}
        disabled={disabled}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true">
        {buttonTitle}
        {isOpen ? (
          <Icon icon={faChevronUp} size={16} className={iconColor} />
        ) : (
          <Icon
            icon={faChevronDown}
            size={16}
            className={twMerge(`${!disabled && 'group-hover:text-[#B31F13]'}`, iconClassname)}
          />
        )}
      </button>
      {isOpen && (
        <div
          className={`absolute right-0 z-[1] mt-2 ${
            width || 'mt-4 w-60'
          } origin-top-right rounded-md bg-white shadow-lg`}>
          <div
            onClick={() => setIsOpen(false)}
            className="flex flex-col"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
