import { useEffect, useMemo, useRef, useState } from 'react';

import { useModal, useUserRole } from 'hooks';

import { Button, Former, List, Modal, Radio, Typography, InputFile, Toggle } from 'ui-kit';
import Select from 'react-select';
import { AnswerType } from '../AnswerType';
import { DeleteAttachment } from '../DeleteAttachment';
import { createNewQuestion } from 'components/Forms/validation';
import deepEqual from 'deep-equal';

import { timeLimit } from 'config/selectConfig';
import { questionType } from 'config/form';
import { callToast } from 'utils/helpers';
import { customStyles, SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { useSelector } from 'react-redux';
import ConfirmChangesModal from 'components/ConfirmChangesModal/ConfirmChangesModal';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { faTrash, faXmark } from '@fortawesome/pro-light-svg-icons';

const AnswersPlaceholder = ({ onClick, type }) => (
  <div className="flex flex-col gap-[12px]">
    <div className="flex gap-[10px]">
      <input
        onClick={onClick}
        checked
        className={`h-5 w-5 cursor-pointer ${
          type === 'single' ? 'rounded-full' : 'rounded-sm'
        } border-[#8C9196] focus:ring-0 focus:ring-offset-0`}
        type={type === 'single' ? 'radio' : 'checkbox'}
      />
      <Typography title="Option A" variant="small-default" />
    </div>
    <div className="flex gap-[10px]">
      <input
        onClick={onClick}
        className={`h-5 w-5 cursor-pointer ${
          type === 'single' ? 'rounded-full' : 'rounded-sm'
        } border-[#8C9196] focus:ring-0 focus:ring-offset-0`}
        type={type === 'single' ? 'radio' : 'checkbox'}
      />
      <Typography title="Option B" variant="small-default" />
    </div>
    <div className="flex gap-[10px]">
      <input
        onClick={onClick}
        checked={type === 'single' ? false : true}
        className={`h-5 w-5 cursor-pointer ${
          type === 'single' ? 'rounded-full' : 'rounded-sm'
        } border-[#8C9196] focus:ring-0 focus:ring-offset-0`}
        type={type === 'single' ? 'radio' : 'checkbox'}
      />
      <Typography title="Option C" variant="small-default" />
    </div>
  </div>
);

const QuestionModal = ({
  defaultValues,
  isOpen,
  onSubmit,
  close,
  needClear,
  type,
  question,
  time,
  tags
}) => {
  const deleteAttachment = useModal({ initialState: false });
  const { answerChoises } = useSelector(store => store.subscriptions);
  const modalRef = useRef();
  const { isAuthor } = useUserRole();
  const [isAddMore, setIsAddMore] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);

  const preparedTags = useMemo(() => {
    return tags.map(tag => ({ value: tag.value, label: tag.label }));
  }, [tags]);

  const initialValues = {
    distributePoints: false,
    answerTime: undefined,
    text: '',
    answers: [{ score: 0, text: '' }],
    tags: [],
    ...defaultValues,
    type: question || '',
    timeLimit: time || 60000
  };

  const save = (onSubmit, reset) => d => {
    if (!d.answers.some(({ score }) => score !== 0) && d.type !== 'essay') {
      callToast('error', 'Choose the correct answer');
      return;
    }
    try {
      createNewQuestion.validateSync(d);
      onSubmit(d);
      !isAddMore && close();
      needClear &&
        reset({
          ...initialValues,
          type: d.type,
          timeLimit: d.timeLimit,
          distributePoints: d.distributePoints,
          tags: d.tags,
          answers: d.distributePoints
            ? d.answers.map(item => ({ ...item, text: '' }))
            : d.answers.map(() => ({ score: 0, text: '' }))
        });
      setTimeout(() => {
        modalRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }, 0);
    } catch (e) {
      callToast('error', e?.errors?.at(0));
    }
  };

  useEffect(() => {
    modalRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    setIsAddMore(type === 'create' ? true : false);
  }, [isOpen, type]);

  return (
    <Modal isOpen={isOpen} className="sm:w-[100%] lg:w-[75%]">
      <Former defaultValues={{ ...initialValues }}>
        {({ handleSubmit, setValue, watch, reset, getValues }) => (
          <>
            <Modal.Header>
              <Typography
                title={type === 'create' ? 'Create Question' : 'Edit Question'}
                variant="heading-h2"
              />
              <Button
                icon={faXmark}
                iconSize={20}
                iconColor="#5C5F62"
                onClick={() => {
                  const values = getValues();
                  deepEqual(values, initialValues, { strict: true })
                    ? close()
                    : setShowConfirm(true);
                }}
              />
            </Modal.Header>
            <Modal.Body className="flex-1 p-8">
              <Former.Form id="test" onSubmit={onSubmit}>
                <div ref={modalRef} className="flex gap-4">
                  <div className="flex flex-2 flex-col gap-[2px] lg:min-w-[50%]">
                    <Typography title="Question" variant="regular-default" className="ml-[12px]" />
                    <RichTextEditor maxLength={2000} name="text" />
                  </div>
                  <div className="flex flex-col gap-[20px]">
                    <div className="flex gap-[24px]">
                      <div className="flex flex-col gap-[2px]">
                        <Typography title="Question Time Limit" variant="regular-default" />
                        <SelectCustom
                          placeholder="Time"
                          isClearable={false}
                          options={timeLimit}
                          name="timeLimit"
                        />
                      </div>
                      {isAuthor && (
                        <div className="flex flex-col gap-[2px]">
                          <Typography title="Answer Time Limit" variant="regular-default" />
                          <SelectCustom
                            disabled
                            placeholder="Time"
                            options={timeLimit}
                            name="answerTime"
                          />
                        </div>
                      )}
                    </div>
                    {isAuthor && (
                      <div className="flex max-w-[464px] flex-col gap-[2px]">
                        <Typography title="Result Tags" variant="regular-default" />
                        <SelectCustom
                          className="h-[44px]"
                          creatable
                          maxLength={100}
                          isMulti
                          color
                          options={preparedTags && preparedTags}
                          name="tags"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  {!watch('attachment') ? (
                    <InputFile
                      onChangeValue={file => setValue('attachment', file)}
                      className="w-full"
                      component={() => (
                        <Typography
                          tag="span"
                          title="Add Attachment"
                          className="cursor-pointer rounded border border-[#C9CCCF] px-[12px] py-[6px] font-semibold transition-all hover:border-[#575959] hover:bg-[#F6F6F7]"
                        />
                      )}
                    />
                  ) : (
                    <div className="group relative max-h-[140px] max-w-[140px] cursor-pointer rounded-lg border border-[#C9CCCF]">
                      <Button
                        className="absolute left-[50%] top-[50%] z-10 hidden translate-y-[-50%] translate-x-[-50%] group-hover:block"
                        iconClassName="fill-white w-[22px] h-[22px]"
                        icon={faTrash}
                        onClick={deleteAttachment.open}
                      />
                      <div
                        onClick={deleteAttachment.open}
                        className="absolute inset-0 h-full w-full rounded-lg group-hover:bg-black/70"
                      />
                      <img
                        className="h-[100%] max-h-[140px] w-[100%] rounded-lg object-contain p-1"
                        src={watch('attachment.url')}
                      />
                    </div>
                  )}
                </div>
                <div className="mt-[32px] space-y-2">
                  <div className="flex w-full items-center gap-[24px]">
                    {!watch('type') ? (
                      <List
                        wrap="div"
                        className="flex w-full justify-between gap-4"
                        keyExtractor={({ value }) => value}
                        data={questionType}
                        element={({ label, value }) => (
                          <Radio
                            className="flex-1"
                            component={() => (
                              <div className="flex w-full flex-col gap-[18px] rounded-lg border border-[#C9CCCF] bg-[#F6F6F7] p-4">
                                <Typography title={label} variant="regular-emphasis" />
                                {value === 'single' && (
                                  <AnswersPlaceholder
                                    onClick={() => setValue('type', value)}
                                    type={value}
                                  />
                                )}
                                {value === 'multiple' && (
                                  <AnswersPlaceholder
                                    onClick={() => setValue('type', value)}
                                    type={value}
                                  />
                                )}
                                {value === 'essay' && (
                                  <textarea
                                    data-hj-allow
                                    style={{ wordBreak: 'break-word' }}
                                    readOnly
                                    onClick={() => {
                                      setValue('type', value);
                                    }}
                                    placeholder="Text..."
                                    className="flex min-h-[86px] w-full flex-1 cursor-pointer resize-none break-words rounded-sm border border-[#C9CCCF] bg-[#FFFFFF] px-[13px] py-[7px] outline-none placeholder:text-gray-400 focus:border-[#C9CCCF] focus:ring-0"
                                  />
                                )}
                              </div>
                            )}
                            value={value}
                            onChangeValue={() =>
                              reset(formValues => ({
                                ...formValues,
                                answers: formValues.answers.map(item => {
                                  return formValues.distributePoints
                                    ? { ...item }
                                    : { ...item, score: 0 };
                                })
                              }))
                            }
                            name="type"
                          />
                        )}
                      />
                    ) : (
                      <div
                        className="w-[300px] cursor-pointer rounded border border-[#C9CCCF] bg-[#F6F6F7] p-[16px]"
                        onClick={() => setValue('type', '')}>
                        <Typography
                          variant="regular-emphasis"
                          title={questionType?.find(item => item.value === watch('type'))?.label}
                        />
                        <Typography
                          variant="regular-default"
                          className="text-[#6D7175]"
                          title="Change answer type"
                        />
                      </div>
                    )}
                    {watch('type') && watch('type') !== 'essay' && (
                      <>
                        <div className="flex flex-col gap-[12px]">
                          <div className="flex gap-4">
                            <Toggle
                              name="distributePoints"
                              placeholder={
                                <Typography
                                  title="Distribute Points Across Choices"
                                  variant="heading-h5"
                                />
                              }
                              onChangeValue={value => {
                                !value &&
                                  watch('type') !== 'single' &&
                                  reset(formValues => ({
                                    ...formValues,
                                    answers: formValues.answers.map(item => {
                                      return { ...item, score: item.score > 0 ? 1 : 0 };
                                    })
                                  }));
                                !value &&
                                  watch('type') === 'single' &&
                                  reset(formValues => ({
                                    ...formValues,
                                    answers: formValues.answers.map(item => {
                                      return { ...item, score: 0 };
                                    })
                                  }));
                              }}
                            />
                          </div>
                          <div className="flex gap-[12px]">
                            <Button
                              onClick={() => {
                                reset(formValues => ({
                                  ...formValues,
                                  answers: formValues.answers.map((item, idx) => {
                                    return { ...item, score: formValues.answers.length - idx };
                                  })
                                }));
                              }}
                              iconEnd="arrow-up"
                              disabled={!watch('distributePoints')}
                              iconSize={20}
                              variant="primary-outline"
                              title="Auto"
                            />
                            <Button
                              onClick={() => {
                                reset(formValues => ({
                                  ...formValues,
                                  answers: formValues.answers.map((item, idx) => {
                                    return { ...item, score: idx + 1 };
                                  })
                                }));
                              }}
                              iconEnd="arrow-down"
                              disabled={!watch('distributePoints')}
                              iconSize={20}
                              variant="primary-outline"
                              title="Auto"
                            />
                          </div>
                        </div>
                        <div className="max-w-[220px]">
                          <Select
                            styles={customStyles}
                            options={answerChoises.map(({ name, values }) => {
                              return { label: name, value: name, values };
                            })}
                            onChange={value => {
                              reset(formValues => ({
                                ...formValues,
                                answers: value.values.map(item => {
                                  return { text: item, score: 0 };
                                })
                              }));
                            }}
                            placeholder="Values Template"
                            components={{
                              IndicatorSeparator: () => null
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="space-y-2">
                  <AnswerType name="type" />
                </div>
              </Former.Form>
            </Modal.Body>
            <Modal.Footer className="flex h-[68px] items-center justify-between">
              <div className="flex items-center gap-[10px]">
                <input
                  type="checkbox"
                  className="h-4 w-4 rounded-sm border border-gray-300 focus:ring-0 focus:ring-offset-0"
                  checked={isAddMore}
                  onChange={() => setIsAddMore(prev => !prev)}
                  value={isAddMore}
                />
                <Typography title="Keep Adding Questions" variant="regular-default" />
              </div>
              <div className="flex gap-3">
                <Button
                  title="Cancel"
                  variant="secondary"
                  className="h-[44px]"
                  onClick={() => {
                    const values = getValues();
                    deepEqual(values, initialValues, { strict: true })
                      ? close()
                      : setShowConfirm(true);
                  }}
                />
                <Button
                  onClick={handleSubmit(save(onSubmit, reset))}
                  className="h-[44px]"
                  disabled={!watch('type')}
                  title="Save Question"
                  variant="primary"
                />
              </div>
              <ConfirmChangesModal
                isConfirmModalOpen={showConfirm}
                closeConfirmModal={() => setShowConfirm(false)}
                bodyText={'Question'}
                buttonText={'Save'}
                confirmNavigation={() => {
                  setShowConfirm(false);
                  close();
                }}
                buttonAction={handleSubmit(save(onSubmit, reset))}
              />
            </Modal.Footer>
            <DeleteAttachment
              isOpen={deleteAttachment.isOpen}
              onSubmit={() => {
                setValue('attachment', '');
                deleteAttachment.close();
              }}
              close={deleteAttachment.close}
            />
          </>
        )}
      </Former>
    </Modal>
  );
};

export { QuestionModal };
