import React, { useCallback, useContext } from 'react';

import { Button } from 'ui-kit';

import { DropdownContext, DropdownState } from '../DropdownContext';

export interface DropdownOptionProps {
  icon?: any;
  closeOnClick?: boolean;
  title?: string | number;
  children?: React.ReactNode;
  onClick: (control: DropdownState) => void;
  element?: (control: DropdownState) => React.ReactNode;
}

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  icon,
  title,
  children,
  onClick,
  closeOnClick,
  element
}) => {
  const control = useContext(DropdownContext);

  const handleClick = useCallback(() => {
    onClick(control);

    if (closeOnClick) {
      control.toggle();
    }
  }, []);

  if (element) {
    return <>{element(control)}</>;
  }

  return (
    <li className="border-b border-gray-300 last:border-none">
      <Button
        icon={icon}
        onClick={handleClick}
        className="flex gap-3 relative items-center py-[9px] px-[19px] pr-5 my-2.5 mx-[5px] first:mt-[5px] last:mb-[5px] min-w-[198px] text-sl font-normal text-gray-500 hover:text-black whitespace-nowrap hover:bg-gray-200 rounded-xl transition-all cursor-pointer">
        {title ?? children}
      </Button>
    </li>
  );
};
