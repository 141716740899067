import { useNavigate, Link } from 'react-router-dom';

import { Typography, Icon } from 'ui-kit';

import * as routes from 'routes/routes';

import TimeBadget from 'components/TimeBadget';
import { badgetStatus, getModifyData, secondsToHms } from 'utils/helpers';
import { useUserRole } from 'hooks';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

const AssessmenstCard = props => {
  const navigate = useNavigate();
  const { isAdmin } = useUserRole();

  const calculatePercent = number => {
    const total = props?.data?.invited + props?.data?.started + props?.data?.completed;
    return (number / total) * 100 + '%';
  };

  const viewAssessmentHandler = () => {
    if (props?.data?.status === 'draft' && !isAdmin) {
      navigate(routes.ASSESSMENTS + `/${props?.data?._id}` + routes.EDIT);
      return;
    }
    navigate(routes.ASSESSMENTS + `/${props?.data?._id}`, { state: { _id: props?.data?._id } });
  };

  return (
    <Link
      to={
        props?.data?.status === 'draft' && !isAdmin
          ? routes.ASSESSMENTS + `/${props?.data?._id}` + routes.EDIT
          : routes.ASSESSMENTS + `/${props?.data?._id}`
      }>
      <div
        onClick={() => viewAssessmentHandler()}
        className={`group relative flex h-[168px] w-full cursor-pointer flex-col justify-between break-words rounded-md border border-gray-400 bg-white p-6 pb-4 ${
          props?.data?.status === 'draft' ? 'pt-[20px]' : 'pt-[36px]'
        } shadow transition-all hover:shadow-3xl ${props.customClass ?? ''}`}>
        <span
          style={{ borderRadius: '6px 0px 0px 6px' }}
          className={`absolute inset-y-0 left-0 rounded-r ${badgetStatus(
            props?.data?.status
          )} w-[6px] font-semibold capitalize`}
        />
        {props?.data?.status !== 'draft' &&
          (props?.data?.demo?.demoDaysLeft || props?.data?.daysLeft) && (
            <span
              style={{ boxShadow: '0px 1px 2px rgba(112, 145, 179, 0.4)' }}
              className={`absolute left-6 top-0 rounded-b bg-[#E0ECF9] py-[2px] px-[10px] font-semibold capitalize text-[#337195]`}>
              {`${props?.data?.demo?.demoDaysLeft || props?.data?.daysLeft} days left`}
            </span>
          )}
        <div className="flex justify-between">
          <Typography
            className="max-w-[800px] transition-all group-hover:text-[#12A182]"
            style={{ wordBreak: 'break-word' }}
            variant="heading-h4"
            title={props?.data?.demo?.isDemo ? `${props?.data?.name}` : props?.data?.name}
          />
          <div className="flex items-center gap-3">
            <div className="flex items-center justify-center gap-[6px]  ">
              <TimeBadget
                className="inline-flex w-auto font-semibold"
                time={props?.data?.timeLimit}
                icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
              />
            </div>
            <div className="flex items-center justify-center rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] text-sm font-semibold text-[#05443D] shadow-sm">
              {`${props?.data?.testCount} Tests`}
            </div>
            <div className="flex items-center justify-center rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] px-[6px] text-sm font-semibold text-[#05443D] shadow-sm">
              {`${props?.data?.customQuestionsCount} Custom Questions`}
            </div>
          </div>
        </div>
        <div className="flex w-full items-end justify-end gap-8">
          {props?.data?.applications > 0 && (
            <div className="flex min-w-[180px] flex-col gap-[6px]">
              <Typography className="text-[#6D7175]" variant="small-default" title="Applications" />
              <Typography variant="small-default" title={props?.data?.applications} />
            </div>
          )}
          {(props?.data?.invited > 0 || props?.data?.started > 0 || props?.data?.completed > 0) && (
            <div className="flex w-full">
              <div
                style={{ width: calculatePercent(props?.data?.invited) }}
                className="flex min-w-[15%] flex-col gap-1">
                <Typography className="text-[#6D7175]" variant="small-default" title="Invited" />
                <div className="max-h-[26px] flex-1 rounded-l-sm bg-[#E4E5E7] pl-[10px]">
                  <Typography
                    variant="small-default"
                    className="font-semibold text-[#5B3F00]"
                    title={props?.data?.invited}
                  />
                </div>
              </div>
              <div
                style={{ width: calculatePercent(props?.data?.started) }}
                className="flex min-w-[15%] flex-col gap-1">
                <Typography className="text-[#6D7175]" variant="small-default" title="Started" />
                <div className="max-h-[26px] flex-1 bg-[#FFD79D] pl-[10px]">
                  <Typography
                    variant="small-default"
                    className="font-semibold text-[#5B3F00]"
                    title={props?.data?.started}
                  />
                </div>
              </div>
              <div
                style={{ width: calculatePercent(props?.data?.completed) }}
                className="flex min-w-[20%] flex-col gap-1">
                <Typography className="text-[#6D7175]" variant="small-default" title="Completed" />
                <div className="max-h-[26px] flex-1 rounded-r-sm bg-[#AEE9D1] pl-[10px]">
                  <Typography
                    variant="small-default"
                    className="font-semibold text-[#5B3F00]"
                    title={props?.data?.completed}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex min-w-[300px] justify-between">
            <div className="flex flex-col gap-[6px]">
              <Typography className="text-[#6D7175]" variant="small-default" title="Updated On" />
              <Typography variant="small-default" title={getModifyData(props?.data?.updatedOn)} />
            </div>
            {props?.data?.publishedOn && (
              <div className="flex flex-col gap-[6px]">
                <Typography
                  className="text-[#6D7175]"
                  variant="small-default"
                  title="Published On"
                />
                <Typography
                  variant="small-default"
                  title={getModifyData(props?.data?.publishedOn)}
                />
              </div>
            )}
            {props?.data?.archivedOn && (
              <div className="flex flex-col gap-[6px]">
                <Typography
                  className="text-[#6D7175]"
                  variant="small-default"
                  title="Archived On"
                />
                <Typography
                  variant="small-default"
                  title={getModifyData(props?.data?.archivedOn)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default AssessmenstCard;
