import { ErrorMessage, Form, Textarea } from 'ui-kit';
import { NoteSchema } from './validation';
import { yupResolver } from '@hookform/resolvers/yup';

export const AddNoteForm = ({ className, onSubmit }) => {
  return (
    <Form
      className={className}
      onKeyDown={onSubmit}
      onBlur={onSubmit}
      onSubmit={onSubmit}
      mode="onChange"
      resolver={yupResolver(NoteSchema)}>
      <Textarea
        classNameLabel="items-center"
        height="min-h-[72px]"
        name="note"
        placeholder="Add Note..."
      />
      <ErrorMessage name="note" />
    </Form>
  );
};
