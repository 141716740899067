import { Route, Routes } from 'react-router-dom';

import { Common } from 'layouts';

import Terms from 'pages/Common/Terms';
import ErrorPage from 'pages/Common/ErrorPage';

import * as routes from './routes';

const CommonRoutes = () => {
  return (
    <Routes>
      <Route path={routes.PRIVACY_POLICY} element={<Terms type="privacyPolicy" />} />
      <Route path={routes.EULA} element={<Terms type="eula" />} />
      <Route path={routes.ERROR_PAGE + '/:code'} element={<ErrorPage />} />
      <Route element={<Common />} />
      <Route
        path={routes.HOME}
        render={({ location }) => {
          window.gtag('config', 'UA-XXXXXXXXX', {
            page_path: location.pathname + location.search
          });
        }}
      />
    </Routes>
  );
};

export default CommonRoutes;
