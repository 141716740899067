import React, { useEffect } from 'react';

import {
  FieldValues,
  FormProvider,
  Resolver, useForm,
  useFormContext,
  UseFormReturn,
  ValidationMode
} from 'react-hook-form';

interface FormProps<T> {
  onSubmit?: (values: { [x: string]: any }) => void;
  children: React.ReactNode;
  className?: string;
  onUnMount?: (values: { [x: string]: any }) => void;
  onChange?: (values: { [x: string]: any }) => void;
  id?: string;
}

interface FormerProps<T extends FieldValues> {
  defaultValues?: any;
  resolver?: Resolver<T, any>;
  children?: React.ReactNode | ((values: UseFormReturn<T, any>) => React.ReactNode);
  mode?: keyof ValidationMode;
}

const Former = <T extends FieldValues,>({ defaultValues, resolver, children, mode, ...rest }: FormerProps<T>) => {
  const methods = useForm({ defaultValues, resolver, mode, ...rest });

  const isFunction = typeof children === 'function';

  return <FormProvider {...methods}>{isFunction ? children(methods) : children}</FormProvider>;
};

const Form = <T,>({ id, className, children, onChange, onSubmit, onUnMount }: FormProps<T>) => {
  const { watch, handleSubmit, getValues } = useFormContext();

  useEffect(() => {
    return () => {
      onUnMount?.(getValues());
    };
  }, []);

  useEffect(() => {
    if (onChange) {
      const subscription = watch(() => onChange(getValues()));

      return () => subscription.unsubscribe();
    }
  }, [watch]);

  return (
    <form
      id={id}
      className={className}
      onChange={onChange}
      onSubmit={handleSubmit(d => onSubmit?.(d))}>
      {children}
    </form>
  );
};

Former.Form = Form;

export { Former };
