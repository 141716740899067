import ProfileEditForm from 'components/Forms/ProfileEditForm';
import { Typography } from 'ui-kit';
import { AdminProfileEditForm } from 'components/Forms/AdminProfileEditForm';
import { localStorageService } from 'helpers/localStorageService';

const MyProfile = () => {
  const role = localStorageService.getRole();
  return (
    <div className="py-8 px-7.5">
      <Typography title="My Profile" variant="heading-h1" />
      <div className="mt-6 flex">
        {role !== 'admin' ? <ProfileEditForm /> : <AdminProfileEditForm />}
      </div>
    </div>
  );
};

export default MyProfile;
