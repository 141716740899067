import React, { useEffect } from 'react';
import {
  FieldValues,
  FormProvider,
  Resolver,
  useForm,
  UseFormReturn,
  ValidationMode
} from 'react-hook-form';

interface FormProps<T extends FieldValues> {
  onSubmit?: (values: T, control: UseFormReturn<T, any>) => void;
  defaultValues?: any;
  resolver?: Resolver<T, any>;
  children?: React.ReactNode | ((values: UseFormReturn<T, any>) => React.ReactNode);
  className?: string;
  onUnMount?: (values: T, control: UseFormReturn<T, any>) => void;
  mode?: keyof ValidationMode;
  id?: string;
  onChange?: (values: T) => void;
  onKeyDown?: (values: T) => void;
  onBlur?: () => void;
  context?: any;
  formRef?: any;
  reset?: any;
  resetDefault?: boolean;
  [key: string]: any;
}

const Form = <T extends FieldValues,>({
  onSubmit,
  defaultValues,
  resolver,
  children,
  className,
  onUnMount,
  onBlur,
  id,
  context,
  mode,
  onChange,
  formRef,
  isReset,
  onKeyDown,
  resetDefault = true,
  ...rest
}: FormProps<T>) => {
  const methods = useForm({ defaultValues, resolver, mode, context });

  useEffect(() => {
    methods.reset(defaultValues);
    !resetDefault && methods.trigger();
  }, resetDefault ? [defaultValues] : [])


  const isFunction = typeof children === 'function';

  useEffect(() => {
    return () => {
      onUnMount?.(methods.getValues(), methods);
    };
  }, []);

  useEffect(() => {
    if (onChange) {
      const subscription = methods.watch(() => onChange(methods.getValues()));

      return () => subscription.unsubscribe();
    }
  }, [methods.watch]);

  return (
    <FormProvider {...methods}>
      <form
        onKeyDown={e => {
          if (e.key === 'Escape' && onKeyDown) {
            methods.reset();
            return onKeyDown?.(methods.getValues());
          }
        }}
        onBlur={onBlur && methods.handleSubmit(data => onSubmit?.(data, methods))}
        ref={formRef}
        className={className}
        id={id}
        onSubmit={methods.handleSubmit(data => onSubmit?.(data, methods))}
        {...rest}>
        {isFunction ? children(methods) : children}
      </form>
    </FormProvider>
  );
};

export default Form;
