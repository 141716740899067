export const generateSniperLink = (mxDomain, email) => {
  let domain = mxDomain || email.split('@')[1];
  let emailWithoutPlus = email.split('+')[0] + '@' + email.split('@')[1];

  switch (domain) {
    case 'gmail.com':
    case 'google.com':
    case 'gsuite.google.com':
      return {
        url: `https://mail.google.com/mail/u/${emailWithoutPlus}/#search/from%3Asupport%40hirenest.com+in%3Aanywhere+newer_than%3A1d`,
        name: 'my Gmail'
      };
    case 'yahoo.com':
      return {
        url: `https://mail.yahoo.com/d/search/search?from=${encodeURIComponent(
          `support@hirenest.com`
        )}`,
        name: 'my Yahoo'
      };
    case 'hotmail.com':
    case 'outlook.com':
      return {
        url: `https://outlook.live.com/mail/inbox?nlp=1&to=${emailWithoutPlus}&search=%22support%40hirenest.com%22`,
        name: 'my Outlook'
      };
    case 'protonmail.com':
      return {
        url: `https://mail.protonmail.com/inbox/search?keyword=${encodeURIComponent(
          `from:support@hirenest.com`
        )}`,
        name: 'my Proton'
      };
    case 'icloud.com':
      return {
        url: `https://www.icloud.com/mail/#${encodeURIComponent(
          `search/from=support%40hirenest.com`
        )}`,
        name: 'my iCloud'
      };
    case 'dropbox.com':
      return {
        url: `https://www.dropbox.com/search?query=${encodeURIComponent(`support@hirenest.com`)}`,
        name: 'my Dropbox'
      };
    case 'amazon.com':
      return {
        url: `https://www.amazon.com/gp/message?ie=UTF8&ref_=nav_cs_msg#inbox`,
        name: 'my Amazon'
      };
    case 'zoho.com':
      return {
        url: `https://mail.zoho.com/zm/#search/subject:support%40hirenest.com`,
        name: 'my Zoho'
      };
    case 'zoho.eu':
      return {
        url: `https://mail.zoho.eu/zm/#search/subject:support%40hirenest.com`,
        name: 'my Zoho'
      };
    case 'office365.com':
    case 'microsoft365.com':
      return {
        url: `https://outlook.office365.com/mail/inbox/search/query=${encodeURIComponent(
          `from:support@hirenest.com`
        )}`,
        name: 'my Office 365'
      };
    case 'fastmail.com':
      return {
        url: `https://www.fastmail.com/search?utf8=%E2%9C%93&q=${encodeURIComponent(
          `from:support@hirenest.com`
        )}`,
        name: 'my Fastmail'
      };
    case 'amazonaws.com':
      return {
        url: `https://console.aws.amazon.com/workmail/home?region=us-east-1#inbox`,
        name: 'my Amazon'
      };
    default:
      return '';
  }
};
