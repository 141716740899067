import { useEffect, useMemo, useRef } from 'react';
import { CheckBox, Form, Typography } from 'ui-kit';
import { SelectCustom } from 'ui-kit/CustomSelect/SelectCustom';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCalcMethod,
  changeIncludeToHirenestScore,
  updateChartSettings,
  updateResultTag,
  updateSection
} from 'store/tests/actions';
import { ResultInterpretation } from '../ResultInterpretation';
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { stringToHslColor } from 'utils/stringToHslColor';
import { optionsBarChart, optionsPolar } from 'config/charConfig';
import { DimensionWidget } from 'components/DimensionWidget/DimenstionWidget';

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

const calculationMethodOptions = [
  { value: 'sum', label: 'Sum' },
  { value: 'avg', label: 'Average' }
];

const resultInterpretationOptions = [
  { value: 'simple', label: 'Simple Scales' },
  { value: 'dimension', label: 'Dimension Scales' }
];

const chartStyleOptions = [
  { value: 'none', label: 'None' },
  { value: 'polar', label: 'Polar Area' },
  { value: 'bar', label: 'Bar Chart' },
  { value: 'dimensions', label: 'Dimensions' }
];

export const SettingsAdditionalForm = ({ defaultValues, sections, onSubmit }) => {
  const blocksRef = useRef([]);
  const dispatch = useDispatch();
  const { currentTest } = useSelector(store => store.tests);

  useEffect(() => {
    blocksRef.current = blocksRef.current.slice(0, currentTest?.tags?.length);
  }, [currentTest?.tags]);

  const charData = useMemo(() => {
    const { includeTestOverall, includeSections, includeTags } = currentTest.result.chartSettings;
    let data = [];

    includeTestOverall && data.push('Test Overall');
    includeSections &&
      currentTest.sections.length > 1 &&
      currentTest.sections.map(({ name }) => data.push(name));
    includeTags && currentTest.tags.map(({ label }) => data.push(label));

    return {
      labels: data,
      datasets: [
        {
          data: data.map(() => Math.floor(Math.random() * (80 - 15 + 1)) + 15),
          backgroundColor: data.map(value => stringToHslColor(value, 45, 55, 0.5)),
          borderWidth: 1,
          minBarLength: 20,
          barThickness: 24
        }
      ]
    };
  }, [currentTest.result.chartSettings]);

  const scrollToBlock = blockId => {
    const block = blocksRef.current.find(ref => ref.id === blockId);
    if (block) {
      const blockOffsetTop = block.offsetTop - 80;
      window.scrollTo({ top: blockOffsetTop, behavior: 'smooth' });
    }
  };

  const onUpdateSection = (idx, data) => {
    dispatch(updateSection({ idx, data }));
  };

  const onUpdateTags = (idx, data) => {
    dispatch(updateResultTag({ idx, data }));
  };

  const saveCharSettings = data => {
    dispatch(updateChartSettings(data));
  };

  return (
    <>
      <Form
        defaultValues={{
          calculationMethod: defaultValues?.calculationMethod || 'sum',
          includeToHirenestScore: defaultValues?.includeToHirenestScore,
          chartSettings: {
            ...defaultValues?.chartSettings
          }
        }}
        onSubmit={saveCharSettings}>
        {({ watch, handleSubmit }) => (
          <div className="flex flex-col gap-[24px]">
            <div className="w-max">
              <CheckBox
                onChangeValue={value => dispatch(changeIncludeToHirenestScore(value))}
                name="includeToHirenestScore"
                label="Include Test Results to Hirenest Score Calculation"
              />
            </div>
            <div className="flex gap-8">
              <div className="flex flex-1 flex-col gap-1">
                <Typography title="Result Calculation Method" variant="regular-default" />
                <div>
                  <SelectCustom
                    options={calculationMethodOptions}
                    onChangeValue={value => {
                      dispatch(changeCalcMethod(value.value));
                    }}
                    disabled={watch('chartSettings.interpretationType') === 'dimension'}
                    isSearchable={false}
                    isClearable={false}
                    name="calculationMethod"
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-1">
                <Typography title="Interpretation Type" variant="regular-default" />
                <div>
                  <SelectCustom
                    options={resultInterpretationOptions}
                    onChangeValue={handleSubmit(saveCharSettings)}
                    isSearchable={false}
                    isClearable={false}
                    name="chartSettings.interpretationType"
                  />
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-6">
                <div className="space-y-1">
                  <Typography title="Widget" variant="regular-default" />
                  <SelectCustom
                    options={chartStyleOptions}
                    onChangeValue={handleSubmit(saveCharSettings)}
                    isSearchable={false}
                    isClearable={false}
                    name="chartSettings.chartStyle"
                  />
                </div>
                {watch('chartSettings.chartStyle') !== 'none' &&
                  watch('chartSettings.chartStyle') !== 'dimensions' && (
                    <div className="space-y-3">
                      <CheckBox
                        onChangeValue={handleSubmit(saveCharSettings)}
                        name="chartSettings.includeTestOverall"
                        label="Include Test Overall"
                      />
                      <CheckBox
                        onChangeValue={handleSubmit(saveCharSettings)}
                        name="chartSettings.includeSections"
                        label="Include Sections"
                      />
                      <CheckBox
                        onChangeValue={handleSubmit(saveCharSettings)}
                        name="chartSettings.includeTags"
                        label="Include Tags"
                      />
                    </div>
                  )}
                {watch('chartSettings.chartStyle') === 'dimensions' && (
                  <DimensionWidget sections={sections} />
                )}
              </div>
              {watch('chartSettings.chartStyle') !== 'none' &&
                watch('chartSettings.chartStyle') !== 'dimensions' && (
                  <div className="max-h-[430px] flex-1">
                    {watch('chartSettings.chartStyle') === 'polar' && (
                      <PolarArea
                        data={charData}
                        options={
                          currentTest?.calculationType === 'avg'
                            ? optionsPolar(currentTest?.minValue, currentTest?.maxValue)
                            : optionsPolar(0, 100)
                        }
                      />
                    )}
                    {watch('chartSettings.chartStyle') === 'bar' && (
                      <div className="mr-20 max-h-[400px]">
                        <Bar
                          height={300}
                          data={charData}
                          options={
                            currentTest?.calculationType === 'avg'
                              ? optionsBarChart(currentTest?.minValue, currentTest?.maxValue)
                              : optionsBarChart(0, 100)
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
        )}
      </Form>
      <div className="mt-6 space-y-3">
        <Typography title="Result Interpretation" variant="heading-h4" />
        {defaultValues?.chartSettings.interpretationType === 'simple' && (
          <ResultInterpretation
            mode="Overall"
            name="Test Overall"
            blocksRef={blocksRef}
            onSubmit={onSubmit}
            method={defaultValues?.calculationMethod}
            chartSettings={defaultValues?.chartSettings}
            sections={sections}
            {...defaultValues}
          />
        )}
        {currentTest?.sections?.length > 1 &&
          currentTest?.sections?.map((section, idx) => (
            <ResultInterpretation
              key={section?._id}
              blocksRef={blocksRef}
              scrollToBlock={scrollToBlock}
              index={idx}
              mode="Section"
              method={defaultValues?.calculationMethod}
              chartSettings={defaultValues?.chartSettings}
              sections={sections}
              {...section}
              onSubmit={data => onUpdateSection(idx, { ...data })}
            />
          ))}
        {currentTest?.tags?.length > 0 &&
          currentTest?.tags?.map((tag, idx) => (
            <ResultInterpretation
              key={tag?._id}
              blocksRef={blocksRef}
              index={idx}
              mode="Tag"
              method={defaultValues?.calculationMethod}
              chartSettings={defaultValues?.chartSettings}
              sections={sections}
              name={tag.value}
              {...tag}
              onSubmit={data => onUpdateTags(idx, { ...data })}
            />
          ))}
      </div>
    </>
  );
};
