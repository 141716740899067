import Typography from 'ui-kit/Typography';
import { badgetStatus } from 'utils/helpers';

interface Tab {
  count: number;
  title: string;
  selected: boolean;
  onClick: () => void;
}

export const Tab: React.FC<Tab> = ({ count, title, selected, onClick }) => (
  <div onClick={onClick} className="group flex max-w-max cursor-pointer gap-[6px]">
    <div
      className={`flex items-center justify-center rounded px-[8px] font-semibold ${
        selected
          ? `text-white ${badgetStatus(title)}`
          : `bg-[#E4E5E7] transition-all ${badgetStatus(title + 'Hover')} text-black`
      } py-[1px]`}>
      {count}
    </div>
    <Typography
      className={`font-medium capitalize text-black ${
        !selected && 'text-[#6D7175] transition-all group-hover:text-black'
      }`}
      variant="heading-h3"
      title={title}
    />
  </div>
);
