import { useState, useEffect } from 'react';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from 'ui-kit/CustomSelect/SelectCustom';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clearJobTitles, getJobTitlesRequest } from 'store/tests/actions';
import { useDebouceCallback } from 'hooks';

const perPage = 20;

const AsyncSelect = ({ name, options, onChangeValue }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  const loadOptions = (page, search) => {
    dispatch(getJobTitlesRequest({ search, perPage, skip: page * perPage }));
  };

  const debounceSubmit = useDebouceCallback(value => {
    if (search === value) {
      return;
    }
    setPage(0);
    dispatch(clearJobTitles());
    setSearch(value);
  });

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleCreateOption = (inputValue, field) => {
    field.onChange([...field.value, { label: inputValue, value: inputValue }]);
    onChangeValue([...field.value, { label: inputValue, value: inputValue }]);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [options]);

  useEffect(() => {
    setIsLoading(true);
    loadOptions(page, search);
  }, [page, search]);

  return (
    <Controller
      name={name}
      render={({ field }) => {
        return (
          <CreatableSelect
            {...field}
            onChange={value => {
              typeof field.value === 'string' || typeof field.value === 'number'
                ? field.onChange(value.value)
                : field.onChange(value);
              onChangeValue && onChangeValue(value);
            }}
            filterOption={createFilter({ ignoreAccents: false })}
            styles={customStyles}
            isLoading={isLoading}
            options={options}
            onCreateOption={value => handleCreateOption(value, field)}
            onMenuScrollToBottom={handleLoadMore}
            onInputChange={debounceSubmit}
            components={{
              IndicatorSeparator: () => null
            }}
            isMulti
          />
        );
      }}
    />
  );
};

export default AsyncSelect;
