import React from 'react';

import { Typography } from 'ui-kit';
import CompanyEditForm from 'components/Forms/CompanyEditForm';

const MyCompany = () => {
  return (
    <div className="py-8 px-7.5">
      <Typography title="My Company" variant="heading-h1" />
      <div className="mt-6 flex">
        <CompanyEditForm />
      </div>
    </div>
  );
};

export default MyCompany;
