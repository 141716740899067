import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import React, { useState } from 'react';
import { Icon, Input } from 'ui-kit';

interface PasswordInputProps {
  name: string;
  placeholder?: string;
  isShowBar?: boolean;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({ name, placeholder }) => {
  const [type, setType] = useState('password');

  const toggle = () => {
    setType(type => (type === 'password' ? 'text' : 'password'));
  };

  return (
    <Input
      placeholder={placeholder}
      autoComplete="off"
      autoFocus
      className="h-[46px]"
      name={name}
      type={type}
      rightComponent={() => (
        <Icon className="mr-4 cursor-pointer" color='#5C5F62' onClick={toggle} icon={type === 'text' ? faEye : faEyeSlash} size={16} />
      )}
    />
  );
};
