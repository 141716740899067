export const privacyConfig = [
  {
    subTitle: null,
    title: null,
    isList: false,
    text: [
      'Hirenest ("we", "us", "our") are committed to protecting and respecting your privacy.',
      'This privacy policy ("Privacy Policy") together with our Terms and Conditions and any other documents referred to herein, sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting hirenest.com, ("Site") you are accepting and consenting to the practices described in this Privacy Policy.',
      'For the purpose of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, (“GDPR”), The Act on the Implementation of the General Data Protection Regulation, the Data Protection Act 2018 and any applicable national implementing laws, regulations and secondary legislation relating to the processing of personal data (together “Data Protection Law”), the data controller is Hirenest.com'
    ]
  },
  {
    supTitle: 'Legal Basis for Processing',
    title: null,
    subTitle:
      'We collect and use the personal data described below in order to provide you with access to our Site in a reliable and secure manner. We also collect and use personal data:',
    isList: true,
    text: [
      'For our legitimate business needs.',
      'To fulfil our contractual obligations to you.',
      'To comply with our legal obligations.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'To the extent we process your personal data for any other purposes, we ask for your consent in advance or require that our partners obtain such consent.'
    ]
  },
  {
    supTitle: 'Information We May Collect from You',
    title: null,
    subTitle: 'We may collect and process the following data about you:',
    isList: false,
    text: [
      'Information you give us. You may give us information about you by filling in forms on our Site or by corresponding with us by phone, e-mail or otherwise. This includes information you provide when you register to use our Site, take a test, create a test or a question, contact us via chat, e-mail or phone, people you invited to take a test (their name and e-mail address and technical information listed below). The information you give us may include your name, address, e-mail address and phone number, financial and credit card information.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle: null,
    isList: true,
    text: [
      'Interest in Services: If you have an interest in obtaining information about our services; request customer support; contact us; register to use our Site; sign up for an event, webinar or contest; or download content, we may require you to provide your contact information (your name, job title, company name and address, phone number, email address or username and password).',
      'Purchases: If you make purchases via our Site, or register for an event or webinar, we may require you to provide your contact information plus financial and invoicing information, such as your credit card number or bank account information.',
      'Community: If you register for an online community that we provide, we may ask you to provide your name, photo or other biographical information, such as your occupation, job title, location, social media profiles, company name, areas of expertise and interests.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle:
      'Surveys: If you voluntarily submit certain information to our services, such as filling out a survey about your experience or provide any feedback, we may collect the information you have provided.',
    isList: false,
    text: [
      'Information we collect about you. If you interact with our services, Sites or emails, we automatically collect information about your device and your usage of our services, Sites or emails (such as Internet Protocol (IP) addresses or other identifiers, which may qualify as Personal Data. With regard to each of your visits to our Site or use of our services we may automatically collect the following information using common information-gathering tools, such as cookies, web beacons, pixels, and similar technologies about your device, and your use of our Site.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle: null,
    isList: true,
    text: [
      'Technical information: including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform;',
      'Information about your visit: including the full Uniform Resource Locators (URL) clickstream to, through and from our Site (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.',
      'Photos from proctored tests: for candidates taking proctored tests, periodic webcam snapshots are taken which usually contain a photo of your face. These webcam snapshots are automatically deleted after 60 days.',
      'Information from other online accounts: where you have given us permission to collect data from within your settings or the privacy policies of these other online services: For example, this can be via social media or by choosing to send us your location data when accessing our Site from your smartphone; or it can be from the integrations and connections that you choose to install when using the Site.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'Information we receive from other sources. We may receive information about you if you use any of the other websites we operate or the other services we provide. In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on this Site.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle:
      'Information we collect from other sources. We also collect information about you from publicly available sources. We may combine this information with personal data provided by you. This helps us update, expand, and analyse our records, identify new customers, and create more tailored advertising to provide services that may be of interest to you. We also use this for the purposes of targeted advertising, delivering relevant email content, event promotion and profiling, determining eligibility and verifying contact information. The Personal Data we collect includes:',
    isList: true,
    text: [
      'Publicly available information about you: such as information published about you, for example by Companies House, including job title, email address, phone number, professional or employment-related information, education information and commercial information.',
      'Internet activities: internet data (or user behavioral data), IP addresses, internet activity information and inferences about your preferences and behaviour.',
      'Social media profiles: information published about you on social media profiles such as LinkedIn, Facebook, Twitter etc.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle:
      'Information collected by third parties. We are also working closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies) and may receive information about you from them.',
    isList: true,
    text: [
      'FreshDesk and UserReport: to collect information about customer requests (for support and development purposes).',
      'SparkPost: to enable email communications with you.',
      'FreshSales: to manage contacts and keep a record of communications/ interactions with customers.',
      'FastSpring: to process payments.',
      'Microsoft Application Insights: to collect error logs to help identify and remediate issues.',
      'Google Analytics: to collect analytics data to help us improve our product.',
      'Calendly: to schedule live demos and phone calls.'
    ]
  },
  {
    supTitle: 'Cookies',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'We use cookies on our Site to distinguish you from other users of our Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve the Site. Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the Site. We use persistent cookies and session cookies on our Site. We use persistent cookies to save your login information for future logins to the Site. We use session cookies to enable certain features of the Site, to better understand how you interact with the Site and to monitor aggregate usage by users and web traffic routing on the Site. Unlike persistent cookies, session cookies are deleted from your computer when you log off from the Site and then close your browser.'
    ]
  },
  {
    supTitle: 'Uses Made of the Information',
    title: null,
    subTitle: 'We use information held about you in the following ways.',
    isList: false,
    text: ['Information you give to us. We will use this information:']
  },
  {
    supTitle: null,
    title: null,
    subTitle: null,
    isList: true,
    text: [
      'to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;',
      'to provide you with information about other goods and services we offer that are similar to those that you have already purchased or enquired about;',
      'to provide you, or permit selected third parties to provide you, with information about goods or services we feel may interest you. If you are an existing customer, we will only contact you by electronic means (e-mail or SMS) with information about goods and services similar to those which were the subject of a previous sale or negotiations of a sale to you. If you are a new customer, and where we permit selected third parties to use your data, we (or they) will contact you by electronic means only if you have consented to this. If you do not want us to use your data in this way, or to pass your details on to third parties for marketing purposes, please tick the relevant box situated on the form on which we collect your data (the [order form OR registration form]);',
      'to notify you about changes to our service;',
      'to ensure that content from our Site is presented in the most effective manner for you and for your computer.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle: 'Information we collect about you. We will use this information:',
    isList: true,
    text: [
      'to administer our Site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;',
      'to improve our Site to ensure that content is presented in the most effective manner for you and your computer;',
      'to allow you to participate in interactive features of our service, when you choose to do so;',
      'as part of our efforts to keep our Site safe and secure;',
      'to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant advertising to you;',
      'to monitor your use of the Site is in compliance with any legal agreement we have in place;',
      'to make suggestions and recommendations to you and other users of our Site about goods or services that may interest you or them.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle:
      'Information we receive from other sources. We may combine this information with information you give to us and information we collect about you. We may use this information and the combined information for the purposes set out above (depending on the types of information we receive).',
    isList: false,
    text: ['We will not sell or rent your personal data to anyone.']
  },
  {
    supTitle: 'Disclosure of Your Information',
    title: null,
    subTitle:
      'Information we share with third parties. We may share your information with selected third parties including:',
    isList: true,
    text: [
      'Any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006.',
      'Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you to provide services such as IT and system administration, email communications, hosting services, backup services, credit card processing, research, development, marketing and customer support, including: Google, Microsoft, FastSpring, SparkPost, FreshWorks, Atlassian, Mailchimp.',
      `Advertisers and advertising networks that require the data to select and serve relevant adverts to you and others. We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that 250 women aged over 25 have clicked on their advertisement on any given day). We may also use such aggregate information to help advertisers reach the kind of audience they want to target (for example, men living in London). We may make use of the personal data we have collected from you to enable us to comply with our advertisers' wishes by displaying their advertisement to that target audience.`,
      'Analytics and search engine providers that assist us in the improvement and optimization of our Site.',
      'Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you.',
      'We may share anonymous and aggregated usage data and reports in the normal course of operating our business; for example, we may share information with other Site users, our customers or publicly to show trends or benchmark the general use of our Site.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle:
      'Information we disclose to third parties. We may disclose your personal data to third parties:',
    isList: true,
    text: [
      'In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.',
      'If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Terms and Conditions and/or any other agreements; or to protect our rights, property, safety, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.'
    ]
  },
  {
    supTitle: 'Where We Store Your Personal Data',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'The personal data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA") or the UK. It may also be processed by staff operating outside the EEA or the UK who work for us or for one of our suppliers or partners. Such staff or subcontractors maybe engaged in, among other things, the fulfillment of your order, the processing of your payment details or the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing outside of the EEA or the UK.',
      'We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. In particular, this means that your personal data will only be transferred to a country that provides an adequate level of protection (for example, where the European Commission has determined that a country provides an adequate level of protection) or where the recipient is bound by standard contractual clause according to conditions provided by the European Commission ("Standard Contractual Clauses").',
      'Our Site is accessible via the internet and may potentially be accessed by anyone around the world. Other users may access the Site from outside the EEA or the UK. This means that where you chose to post your data on our Site, it could be accessed from anywhere around the world and therefore a transfer of your data outside of the EEA or the UK may be deemed to have occurred. You consent to such transfer of your data for and by way of this purpose.'
    ]
  },
  {
    supTitle: 'Protection of Information',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using TLS technology. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Site, you are responsible for keeping this password confidential. We ask you not to share any password with anyone.',
      'Unfortunately, the transmission of information via the Internet is not completely secure. Although we will endeavor to protect your personal data, we cannot guarantee the security of your data transmitted to our Site. Any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.'
    ]
  },
  {
    supTitle: 'Links to Other Websites',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'Our Site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.'
    ]
  },
  {
    supTitle: 'Your Rights',
    title: null,
    subTitle: 'You have the right under Data Protection Law, free of charge, to request:',
    isList: true,
    text: [
      'Access to your personal data.',
      'Rectification or deletion of your personal data.',
      'A restriction on the processing of your personal data.',
      'Object to the processing of your personal data.',
      'A transfer of your personal data (data portability) in a structured, machine readable and commonly used format.',
      'Withdraw your consent to us processing your personal data, at any time.'
    ]
  },
  {
    supTitle: null,
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'You can make a request in relation to any of the above rights by writing to us at the contact address given at the end of this Privacy Policy. We will respond to such queries within 30 days and deal with requests we receive from you, in accordance with the provisions of Data Protection Law.'
    ]
  },
  {
    supTitle: 'Marketing Communications',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'We will send you marketing emails if you “opt in” to receive marketing emails when registering on our Site, or if you have enquired about, or purchased any of our goods or services.',
      'Please note that if you change your mind about being sent marketing emails you can opt out at any time by clicking the “unsubscribe” link at the bottom of any marketing email from us and we will remove you from our mailing lists. We will continue to communicate with you regarding your service billing and support via email.',
      'We send push notifications from time to time in order to update you about any service updates, events and promotions we may be running. If you no longer wish to receive these communications, please disable these in the settings on your device.'
    ]
  },
  {
    supTitle: 'Data Retention',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'We retain personal data for as long as necessary for the relevant activity for which it was provided or collected. This will be for as long as we provide access to the Site to you, your account with us remains open or and period set out in any relevant contract you have with us. However, we may keep some data after your account is closed or you cease using the Site for the purposes set out below.',
      'After you have closed your account, or ceased using the Site for a period of at least 37 months, we usually delete personal data, however we may retain personal data where reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, maintain security, prevent fraud and abuse, resolve disputes, enforce our Terms and Conditions, or fulfill your request to "unsubscribe" from further messages from us.',
      'We will retain de-personalized information after your account has been closed.',
      'Please note: After you have closed your account or deleted information from your account, any information you have shared with others will remain visible. We do not control data that other users may have copied from the Site. Your profile may continue to be displayed in the services of others (e.g. search engine results) until they refresh their cache'
    ]
  },
  {
    supTitle: 'Complaints',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'If you have any complaints about our use of your personal data please contact us as set out at the end of this Privacy Policy or contact your local data protection supervisory authority.'
    ]
  },
  {
    supTitle: 'Age of Users',
    title: null,
    subTitle: null,
    isList: false,
    text: ['This Site is not intended for and shall not be used by anyone under the age of 16.']
  },
  {
    supTitle: 'Changes to Our Privacy Policy',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our Privacy Policy. This Privacy Policy was last updated on 1st of January 2020 and replaces any other Privacy Policy previously applicable from this date.'
    ]
  },
  {
    supTitle: 'Contact',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'Questions, comments and requests regarding this Privacy Policy are welcomed and should be addressed to:'
    ]
  }
];
export const eulaConfig = [
  {
    supTitle: 'End-User License Agreement ("Agreement")',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'Last updated: July 25, 2022',
      'Please read this End-User License Agreement carefully before clicking the "I Agree" button, downloading or using Hirenest.'
    ]
  },
  {
    supTitle: 'Interpretation and Definitions',
    title: 'Interpretation',
    subTitle: null,
    isList: false,
    text: [
      'The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.'
    ]
  },
  {
    supTitle: null,
    title: 'Definitions',
    subTitle: 'For the purposes of this End-User License Agreement:',
    isList: true,
    text: [
      '<b>Agreement<b> means this End-User License Agreement that forms the entire agreement between You and the Company regarding the use of the Application. This Agreement has been created with the help of the TermsFeed EULA Generator.',
      '<b>Application<b> means the software program provided by the Company downloaded by You to a Device, named Hirenest',
      '<b>Company<b> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Hirenest LLC, ADDRESS',
      '<b>Content<b> refers to content such as text, images, or other information that can be posted, uploaded, linked to or otherwise made available by You, regardless of the form of that content',
      '<b>Country<b> refers to: California, United States',
      '<b>Device<b> means any device that can access the Application such as a computer, a cellphone or a digital tablet',
      '<b>Third-Party<b> Services means any services or content (including data, information, applications and other products services) provided by a third-party that may be displayed, included or made available by the Application',
      '<b>You<b> means the individual accessing or using the Application or the company, or other legal entity on behalf of which such individual is accessing or using the Application, as applicable'
    ]
  },
  {
    supTitle: 'Acknowledgment',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'By clicking the "I Agree" button, downloading or using the Application, You are agreeing to be bound by the terms and conditions of this Agreement. If You do not agree to the terms of this Agreement, do not click on the "I Agree" button, do not download or do not use the Application.',
      'This Agreement is a legal document between You and the Company and it governs your use of the Application made available to You by the Company.',
      'The Application is licensed, not sold, to You by the Company for use strictly in accordance with the terms of this Agreement.'
    ]
  },
  {
    supTitle: 'License',
    title: 'Scope of License',
    subTitle: null,
    isList: false,
    text: [
      'The Company grants You a revocable, non-exclusive, non-transferable, limited license to download, install and use the Application strictly in accordance with the terms of this Agreement.',
      'The license that is granted to You by the Company is solely for your personal, non-commercial purposes strictly in accordance with the terms of this Agreement.'
    ]
  },
  {
    supTitle: 'Third-Party Services',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services.',
      'You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.',
      "You must comply with applicable Third parties' Terms of agreement when using the Application. Third-party Services and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and subject to such third parties' Terms and conditions."
    ]
  },
  {
    supTitle: 'Indemnification',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      "You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party."
    ]
  },
  {
    supTitle: 'No Warranties',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'The Application is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.',
      "Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.",
      'Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company shall be solely responsible for such warranty.'
    ]
  },
  {
    supTitle: 'Limitation of Liability',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      "Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You for the Application or through the Application or 100 USD if You haven't purchased anything through the Application.",
      ' To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Application, third-party software and/or third-party hardware used with the Application, or otherwise in connection with any provision of this Agreement), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.',
      'Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to You.'
    ]
  },
  {
    supTitle: 'Severability and Waiver',
    title: 'Severability',
    subTitle: null,
    isList: false,
    text: [
      'If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.'
    ]
  },
  {
    supTitle: null,
    title: 'Waiver',
    subTitle: null,
    isList: false,
    text: [
      "Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach."
    ]
  },
  {
    supTitle: 'Product Claims',
    title: null,
    subTitle: null,
    isList: false,
    text: ['The Company does not make any warranties concerning the Application.']
  },
  {
    supTitle: 'United States Legal Compliance',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.'
    ]
  },
  {
    supTitle: 'Changes to this Agreement',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      "The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at the sole discretion of the Company.",
      'By continuing to access or use the Application after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Application.'
    ]
  },
  {
    supTitle: 'Governing Law',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'The laws of the Country, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws.'
    ]
  },
  {
    supTitle: 'Entire Agreement',
    title: null,
    subTitle: null,
    isList: false,
    text: [
      'The Agreement constitutes the entire agreement between You and the Company regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between You and the Company',
      "You may be subject to additional terms and conditions that apply when You use or purchase other Company's services, which the Company will provide to You at the time of such use or purchase."
    ]
  },
  {
    supTitle: 'Contact Us',
    title: null,
    subTitle: 'If you have any questions about this Agreement, You can contact Us:',
    isList: true,
    text: ['By email: support@hirenest.com']
  }
];
