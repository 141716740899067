import React from 'react';
import emptyLibrary from 'assets/empty-library.svg';
import classNames from 'utils/classNames';

const EmptyResourse = ({ title, image = emptyLibrary, className, classNameImage }) => {
  return (
    <div className={classNames(`flex flex-col items-center px-5 w-full my-[150px] ${className}`)}>
      <p className="mb-10 text-center text-xl font-medium">{title}</p>
      <p className="flex w-[366px] items-center justify-center">
        <img className={classNameImage} src={image} alt="" />
      </p>
    </div>
  );
};

export default EmptyResourse;
