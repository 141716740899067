import { QuestionSection } from 'pages/Author/Tests/Test/components/QuestionSection';

import { useSelector } from 'react-redux';
import { List } from 'ui-kit';

export const CustomQuestionsTab = () => {
  const { sections } = useSelector(store => store.assessments.currentAssessment.customQuestions);

  return (
    <List
      data={sections}
      element={({ name, _id, questions }, idx) => (
        <QuestionSection name={name} hidden idx={idx} sectionId={_id} questions={questions} />
      )}
      keyExtractor={({ _id }) => _id}
      wrap="div"
      className="space-y-5"
    />
  );
};
