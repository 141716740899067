import React from 'react';

import cn from 'utils/classNames';

interface HeaderProps {
  className?: string;
  children?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ className, children }) => {
  return <div className={cn(className, 'px-8 py-4 border-b border-[#C9CCCF]')}>{children}</div>;
};
