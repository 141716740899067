import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';
import TestLibraryModal from 'components/TestLibraryModal/TestLibraryModal';
import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getSingleOccupationResourseRequest,
  getTestCategoriesRequest,
  resourceMapTestRequest,
  resourceUnMapTestRequest
} from 'store/configuration/actions/subscriptions';
import { getCurrentTestRequest } from 'store/tests/actions';
import { Button, Form, Icon, Input, Section, Typography } from 'ui-kit';
import { Tab } from 'ui-kit/TabSingle/Tab';

export const TestMappingSingle = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedResource, setSelectedResource] = useState();
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const { _id } = useParams();

  const { resource, loading } = useSelector(store => store.subscriptions);
  const { currentTest, loading: load } = useSelector(store => store.tests);
  const { testsFamilies, loading: loader } = useSelector(store => store.admin);

  const previewTestHandler = id => {
    dispatch(getCurrentTestRequest(id));
    setViewModalOpen(true);
  };

  const unMapResourceHandler = () => {
    dispatch(resourceUnMapTestRequest(selectedResource.id));
    setViewModalOpen(false);
  };

  const mapResourceHandler = testId => {
    dispatch(
      resourceMapTestRequest({
        resourceId: selectedResource,
        data: { testId }
      })
    );
    setMapModalOpen(false);
  };

  const filteredResource = useMemo(() => {
    if (!resource?.items) {
      return;
    }
    return resource?.items
      .filter(({ title }) => title.toLowerCase().includes(searchInput.toLowerCase()))
      .filter(({ items, mappedTests }) => {
        if (selectedTab === 1) {
          return items ? items.some(item => item.mappedTests.length > 0) : mappedTests.length > 0;
        }

        if (selectedTab === 2) {
          return items
            ? items.some(item => item.mappedTests.length === 0)
            : mappedTests.length === 0;
        }

        return true;
      });
  }, [resource?.items, searchInput, selectedTab]);

  const tabs = useMemo(() => {
    return [
      { count: resource?.all, title: 'All' },
      { count: resource?.mapped, title: 'Mapped' },
      { count: resource?.notMapped, title: 'Not Mapped' }
    ];
  }, [resource]);

  useEffect(() => {
    dispatch(getSingleOccupationResourseRequest(_id));
    dispatch(getTestCategoriesRequest());
  }, [_id]);
  return (
    <div className="w-full px-6 py-9">
      <Breadcrumbs type="test-mapping-single" />
      {(loading || load || loader) && <Loader />}
      <Typography title={resource?.resourceType} variant="subheader" />
      <div className="mt-[33px] flex items-center justify-between">
        <div className="flex gap-[48px]">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.title}
              title={tab.title}
              count={tab.count}
              chipColor="bg-[#007D6F]"
              selected={selectedTab === index}
              onClick={() => setSelectedTab(index)}
            />
          ))}
        </div>
        <Form>
          <Input
            leftComponent={() => (
              <Icon className="ml-3" size={16} color="#8C9196" icon={faMagnifyingGlass} />
            )}
            name="search"
            onChangeValue={value => setSearchInput(value)}
            className="h-[44px] w-[200px]"
            placeholder="Search..."
          />
        </Form>
      </div>
      <Section className="mt-[33px]">
        <Section.Header className="flex">
          <Typography
            className="mr-4 flex-1"
            title={!resource?.items?.[0]?.items ? 'Title' : 'Category'}
            variant="regular-emphasis"
          />
          <Typography
            className="mr-4 flex-2"
            title={!resource?.items?.[0]?.items ? 'Description' : 'Skills'}
            variant="regular-emphasis"
          />
          <Typography className="flex-1" title="Hirenest Test" variant="regular-emphasis" />
        </Section.Header>
        <Section.Body className="flex flex-col p-0">
          {filteredResource?.map((item, idx) => (
            <div
              key={item?._id}
              className={`${
                item?.items && idx !== filteredResource?.length - 1 && 'border-b border-[#C9CCCF]'
              } flex w-full p-8`}>
              <Typography
                className={`${item?.items && 'self-center'} mr-4 flex-1`}
                title={item?.title}
                variant="regular-default"
              />
              {item?.description && (
                <Typography
                  className="mr-4 flex-2"
                  title={item?.description}
                  variant="regular-default"
                />
              )}
              {item?.items && (
                <ul className="flex w-full flex-3 list-disc flex-col gap-[28px] px-2">
                  {item?.items.map(res => (
                    <div key={res?._id} className="flex w-full items-center pl-3">
                      <li className="mr-4 flex-2">
                        <Typography title={res.description} variant="regular-default" />
                      </li>
                      {res.mappedTests.length > 0 ? (
                        <Typography
                          className="flex-1 cursor-pointer"
                          title={res?.mappedTests[0]?.name}
                          onClick={() => {
                            setSelectedResource({ id: res._id, title: item.title });
                            previewTestHandler(res?.mappedTests[0]?._id);
                          }}
                          variant="underline"
                        />
                      ) : (
                        <div className="flex-1">
                          <Button
                            iconEnd="Union"
                            onClick={() => {
                              setSelectedResource(res._id);
                              setMapModalOpen(true);
                            }}
                            variant="primary-outline"
                            title="Add Test Mapping"
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
              )}
              {item?.mappedTests &&
                (item?.mappedTests?.length > 0 ? (
                  <Typography
                    className="flex-1 cursor-pointer px-2"
                    title={item?.mappedTests[0]?.name}
                    onClick={() => {
                      setSelectedResource({ id: item._id, title: item.title });
                      previewTestHandler(item?.mappedTests[0]?._id);
                    }}
                    variant="underline"
                  />
                ) : (
                  <div className="flex flex-1 items-start">
                    <Button
                      iconEnd="Union"
                      onClick={() => {
                        setSelectedResource(item._id);
                        setMapModalOpen(true);
                      }}
                      variant="primary-outline"
                      title="Add Test Mapping"
                    />
                  </div>
                ))}
            </div>
          ))}
        </Section.Body>
      </Section>
      <TestLibraryModal
        isModalOpen={mapModalOpen}
        setModalOpen={() => setMapModalOpen(false)}
        tests={testsFamilies}
        mapping
        onClick={() => mapResourceHandler(currentTest?._id)}
      />
      <ViewTestModal
        resource={selectedResource}
        unMapResourceHandler={unMapResourceHandler}
        isOpenModal={viewModalOpen}
        setIsOpenModal={setViewModalOpen}
      />
    </div>
  );
};
