import { useState } from 'react';

import { useDispatch } from 'react-redux';
import { useModal } from 'hooks';

import {
  deleteQuestion,
  duplicateQuestion,
  swapQuestion,
  updateQuestion
} from 'store/tests/actions/index.js';
import {
  deleteCustomQuestion,
  duplicateCustomQuestion,
  swapCustomQuestion,
  updateCustomQuestion
} from 'store/assessment/actions/index.js';
import { CustomQuestionCreate } from 'components/CustomQuestionCreate/CustomQuestionCreate.js';
import { QuestionModal } from '../QuestionModal';
import TimeBadget from 'components/TimeBadget';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { QuestionType } from 'components/Question';
import { localStorageService } from 'helpers/localStorageService';

export const Question = ({
  sectionId,
  idx,
  type,
  tags,
  timeLimit,
  allResultTags,
  allQuestionsLength,
  questionsLength,
  ...props
}) => {
  const dispatch = useDispatch();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { isOpen, close, open } = useModal({ initialState: false });
  const role = localStorageService.getRole();

  const handleDuplicate = () => {
    if (role === 'author') {
      dispatch(duplicateQuestion({ sectionId, idx }));
    } else {
      dispatch(duplicateCustomQuestion(idx));
    }
  };

  const handleEdit = data => {
    if (role === 'author') {
      dispatch(updateQuestion({ sectionId, idx, data }));
    } else {
      dispatch(updateCustomQuestion({ idx, data }));
    }
  };

  const handleDelete = () => {
    if (role === 'author') {
      dispatch(deleteQuestion({ sectionId, idx, tags }));
    } else {
      dispatch(deleteCustomQuestion(idx));
    }
    document.body.style.overflow = 'auto';
  };

  const handleUp = () => {
    if (role === 'author') {
      dispatch(swapQuestion({ sectionId, from: idx, to: idx - 1 }));
    } else {
      dispatch(swapCustomQuestion({ from: idx, to: idx - 1 }));
    }
  };

  const handleDown = () => {
    if (role === 'author') {
      dispatch(swapQuestion({ sectionId, from: idx, to: idx + 1 }));
    } else {
      dispatch(swapCustomQuestion({ from: idx, to: idx + 1 }));
    }
  };

  return !isOpen ? (
    <div className="flex flex-col rounded border border-[#C9CCCF]">
      <div className="flex justify-between rounded-t bg-[#F6F6F7] py-3 pl-6 pr-3">
        <div className="flex items-center gap-[16px]">
          <div className="flex gap-1">
            <p className="text-[#2F6E63]">{props?.index || idx + 1}</p>
            <p className="text-[#8C9196]">/ {allQuestionsLength || questionsLength}</p>
          </div>
          <TimeBadget time={timeLimit} />
          <div className="flex h-full gap-3">
            <div onClick={handleUp} className="flex cursor-pointer items-center justify-center">
              <i className="fa-regular fa-chevron-up text-base text-[#007D6F]"></i>
            </div>
            <div className="border-r border-[#D2D5D8]" />
            <div onClick={handleDown} className="flex cursor-pointer items-center justify-center">
              <i className="fa-regular fa-chevron-down text-base text-[#007D6F]"></i>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center gap-3">
            <div
              onClick={open}
              className="flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded border border-[#8C9196] bg-white p-[10px] hover:bg-gray-200">
              <i className="fa-light fa-pen text-[#5C5F62]"></i>
            </div>
            <div
              onClick={handleDuplicate}
              className="flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded border border-[#8C9196] bg-white p-[10px] hover:bg-gray-200">
              <i className="fa-light fa-copy text-[#5C5F62]"></i>
            </div>
            <div
              onClick={() => setConfirmationModalOpen(true)}
              className="flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded border border-[#8C9196] bg-white p-[10px] hover:border-red-400 hover:bg-red-50">
              <i className="fa-light fa-trash-can text-[#5C5F62]"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="rounded bg-white px-[24px] py-[18px]">
        <QuestionType type={type} {...props} />
      </div>
      <ConfirmationModal
        isConfirmModalOpen={confirmationModalOpen}
        setConfirmModalOpen={setConfirmationModalOpen}
        headerText="Delete Question"
        bodyText={
          <p>
            Delete Question <b>#{props?.index || idx + 1}?</b>
          </p>
        }
        buttonText="Delete"
        buttonAction={handleDelete}
        danger
      />
    </div>
  ) : role === 'employer' ? (
    <CustomQuestionCreate
      type="edit"
      defaultValues={{ ...props, timeLimit, type }}
      close={close}
      onSubmit={handleEdit}
    />
  ) : (
    <QuestionModal
      type="edit"
      isOpen={isOpen}
      tags={allResultTags}
      close={close}
      defaultValues={{ ...props, tags }}
      question={type}
      time={timeLimit}
      onSubmit={handleEdit}
    />
  );
};
