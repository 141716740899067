import { Link, NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Icon from 'ui-kit/Icon';

export const NavbarLink = ({ to, className, onClick, children, title, icon, configuration }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return configuration ? (
    <Link
      onClick={onClick}
      to={to}
      className={twMerge(
        className?.(match),
        match ? 'bg-green-100 border-green-500 text-green-500' : 'border-transparent',
        'flex gap-2.5 items-center text-[#6D7175] text-baseN h-[75px] py-6 px-5 box-border hover:bg-green-100'
      )}>
      {
        <>
          <Icon
            size={20}
            className="min-w-[20px]"
            icon={icon}
            color={match ? '#007D6E' : '#5C5F62'}
          />
          {title ?? children}
        </>
      }
    </Link>
  ) : (
    <NavLink
      onClick={onClick}
      to={to}
      className={({ isActive }) =>
        twMerge(
          className?.(isActive),
          isActive ? 'bg-green-100 border-green-500 text-green-500' : 'border-transparent',
          'flex gap-2.5 items-center h-[75px] text-[#6D7175] text-base py-6 px-5 box-border hover:bg-green-100'
        )
      }>
      {({ isActive }) => (
        <>
          <Icon
            size={20}
            className="min-w-[20px]"
            icon={icon}
            color={isActive ? '#007D6E' : '#5C5F62'}
          />
          {title ?? children}
        </>
      )}
    </NavLink>
  );
};
