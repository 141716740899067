import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { Button, Modal, Typography } from 'ui-kit';
import { TestCard } from 'ui-kit/TestCard';

const VersionsTestHistory = ({ isOpen, setOpen, arr, current }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => setOpen(false)} className="m-auto w-full max-w-[1200px]">
      <Modal.Header className="px-6">
        <Typography variant="heading-h2" title="Test Versions" />
        <Button icon={faXmark} iconSize={20} iconColor="#5C5F62" onClick={() => setOpen(false)} />
      </Modal.Header>
      <Modal.Body className="flex flex-col gap-4 p-6">
        {arr?.map(item => (
          <TestCard.Author isModal data={item} key={item?._id} current={current === item?._id} />
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default VersionsTestHistory;
