import { Tooltip } from 'react-tooltip';

export const ToolTipsApplicationBody = () => (
  <>
    <Tooltip
      id="monitorIp-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      We monitor the candidate for not changing his IP address during the assessment.
    </Tooltip>
    <Tooltip
      id="uniqueIp-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      We detect multiple attempts of passing an assessment from the same IP.
    </Tooltip>
    <Tooltip
      id="emailSimilarity-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      Detects if similar email addresses are used for an assessment, possibly indicating multiple
      attempts by the same candidate.
    </Tooltip>
    <Tooltip
      id="browserFingerprint-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      We detect if different browsers are used during an assessment, which could indicate fraudulent
      or suspicious behavior.
    </Tooltip>
    <Tooltip
      id="assessmentCompletionTime-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      Warns if a candidate completes an assessment unusually quickly, helping you to detect
      potential dishonest behavior or cheating.
    </Tooltip>
    <Tooltip
      id="testCompletionTime-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      Warns if a candidate completes an individual test within an assessment unusually quickly,
      helping you to detect potential dishonest behavior or cheating.
    </Tooltip>
    <Tooltip
      id="fullScreen-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      For candidates that use a desktop or laptop, activate full-screen mode to ensure candidates don’t browse the internet to look up answers. While we cannot prevent candidates from deactivating full-screen mode, we can detect if they did. It indicates a potential violation.
    </Tooltip>
    <Tooltip
      id="mouseInAssessmentWindow-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      We can detect if the mouse has always been on the test window. Candidates with two screens could still have another window to browse the internet.
    </Tooltip>
    <Tooltip
      id="pasteDetection-tooltip"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[102px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      Using the paste function to answer essay questions.
    </Tooltip>
  </>
);
