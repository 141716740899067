import { Button, Modal, Typography } from 'ui-kit';
import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import {
  addTestsToAssessment,
  removeTestsFromAssessment,
  updateTests
} from 'store/assessment/actions';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import WhatToAddModal from 'components/WhatToAddModal/WhatToAddModal';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const CreateAssessmentModals = ({
  currentAssessment,
  viewTestModalOptions,
  confirmationModalOptions,
  upgradeSubscriptionsOptions,
  successModalOptions,
  upgradeSubscriptionModalOptions,
  genericModalOptions,
  whatToAddModalOptions,
  deleteDraftAssessmentOptions,
  dispatch,
  currentTest
}) => (
  <>
    <ViewTestModal
      isOpenModal={viewTestModalOptions.viewModalOpen}
      setIsOpenModal={viewTestModalOptions.setViewModalOpen}
      buttonText="Read More"
      component={
        !viewTestModalOptions.showPreviewButtons ? (
          <></>
        ) : !currentAssessment?.tests?.find(
            ({ _id }) => viewTestModalOptions.previewTestId === _id
          ) ? (
          <Button
            variant="primary"
            title="Add to Assessment"
            className="mr-[44px]"
            onClick={() => {
              dispatch(addTestsToAssessment(currentTest));
              viewTestModalOptions.setTestsStateChanged(true);
              viewTestModalOptions.setViewModalOpen(false);
            }}
          />
        ) : (
          <Button
            variant="danger"
            title="Remove from Assessment"
            className="mr-[44px]"
            onClick={() => {
              viewTestModalOptions.setTestsStateChanged(true);
              viewTestModalOptions.setRemoveId(viewTestModalOptions.previewTestId);
              viewTestModalOptions.setConfirmationModalOpen(true);
            }}
          />
        )
      }
    />
    <ConfirmationModal
      isConfirmModalOpen={confirmationModalOptions.confirmationModalOpen}
      setConfirmModalOpen={() => confirmationModalOptions.setConfirmationModalOpen(false)}
      headerText={`Remove ${
        currentAssessment?.tests?.find(({ _id }) => _id === confirmationModalOptions.removeId)?.name
      }`}
      bodyText={
        <p>
          remove{' '}
          {
            currentAssessment?.tests?.find(({ _id }) => _id === confirmationModalOptions.removeId)
              ?.name
          }{' '}
          from {'  '}
          <b>{currentAssessment?.name || 'Assessment'}?</b>
        </p>
      }
      buttonText="Remove"
      buttonAction={() => {
        dispatch(removeTestsFromAssessment(confirmationModalOptions.removeId));
        confirmationModalOptions.setConfirmationModalOpen(false);
        confirmationModalOptions.setTestsStateChanged(true);
      }}
      danger
    />

    <ConfirmationModal
      isConfirmModalOpen={deleteDraftAssessmentOptions.confirmationModalOpen}
      setConfirmModalOpen={() => deleteDraftAssessmentOptions.setConfirmationModalOpen(false)}
      headerText="Delete Assessment"
      bodyText={
        <p>
          Are you sure want to delete <b>{currentAssessment?.name || 'Assessment'}</b> assessment?
        </p>
      }
      buttonText="Delete"
      buttonAction={deleteDraftAssessmentOptions.acceptAction}
      danger
    />

    <WhatToAddModal
      isOpen={whatToAddModalOptions.whatToAddModalOpen}
      setOpen={() => whatToAddModalOptions.setWhatToAddModalOpen(false)}
    />
    <Modal
      isOpen={genericModalOptions.isGenericModalOpen}
      className="m-auto w-full min-w-[600px] max-w-max">
      <Modal.Header className="px-6">
        <Typography variant="heading-h4" title="Overwrite Included Tests" />
        <Button
          icon={faXmark}
          iconSize={20}
          iconColor="#5C5F62"
          onClick={() => genericModalOptions.setIsGenericModalOpen(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex min-h-[100px] items-center px-6">
        <Typography variant="regular-default" title="This will overwrite any included tests." />
      </Modal.Body>
      <Modal.Footer className="flex justify-end gap-4">
        <div className="flex justify-end gap-4">
          <Button
            variant="primary-outline"
            title="Cancel"
            onClick={() => genericModalOptions.setIsGenericModalOpen(false)}
          />
          <Button
            variant="primary"
            title="Replace Tests"
            onClick={() => {
              dispatch(updateTests(genericModalOptions.genericTests));
              genericModalOptions.setTestsStateChanged(false);
              genericModalOptions.setIsGenericModalOpen(false);
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  </>
);

export default CreateAssessmentModals;
