import { adminApi } from 'api/appApi';
import { put, takeLatest } from 'redux-saga/effects';
import {
  getAllEmployersSuccess,
  getAllEmployersFailure,
  adminConstants,
  getDashboardStatSuccess,
  getDashboardStatFailure,
  getAuthorSuccess,
  getAuthorFailed,
  getAllAuthorsSuccess,
  getAllAuthorsFailed,
  inviteAuthorSuccess,
  inviteAuthorFailed,
  getTestByAuthorSuccess,
  getTestByAuthorFailed,
  inviteEmployerSuccess,
  inviteEmployerFailed,
  getSingleEmployerSuccess,
  getSingleEmployerFailure,
  getSingleCompanySuccess,
  getSingleCompanyFailure,
  noteEmployerSuccess,
  noteEmployerFailed,
  deleteNoteEmployerSuccess,
  deleteNoteEmployerFailed,
  getAllTestsFamiliesSuccess,
  getAllTestsFamiliesFailed,
  setDemoAssessmentsIdSuccess,
  setDemoAssessmentsIdFailed,
  getDemoAssessmentsIdSuccess,
  getDemoAssessmentsIdFailed,
  generateApplicationsSuccess,
  generateApplicationsFailure,
} from 'store/admin/actions';
import { callToast } from 'utils/helpers';

function* getAllEmployersSage(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.getAllEmployers(payload);
    yield put(getAllEmployersSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting employers');
    yield put(getAllEmployersFailure(error.response.status));
  }
}

function* getSingleEmployerSage(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.getSingleEmployer(payload);
    yield put(getSingleEmployerSuccess(data));
  } catch (error) {
    yield put(getSingleEmployerFailure(error.response.status));
  }
}

function* getAllTestsFamiliesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.getAllTestsFamilies(payload);
    yield put(getAllTestsFamiliesSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting tests');
    yield put(getAllTestsFamiliesFailed(error.response.status));
  }
}

function* getSingleCompanySage(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.getSingleCompany(payload);
    yield put(getSingleCompanySuccess(data));
  } catch (error) {
    yield put(getSingleCompanyFailure(error.response.status));
  }
}

function* getDashboardStatSaga() {
  try {
    const { data } = yield adminApi.getDashboardStat();
    yield put(getDashboardStatSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting data');
    yield put(getDashboardStatFailure(error.response.status));
  }
}

function* getAuthorSaga(action) {
  const { payload } = action;

  try {
    const { data } = yield adminApi.getAuthor(payload);
    yield put(getAuthorSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting author');
    yield put(getAuthorFailed(error.response.status));
  }
}

function* getTestByAuthorSaga(action) {
  const { payload } = action;

  try {
    const { data } = yield adminApi.getTestByAuthor(payload);
    yield put(getTestByAuthorSuccess(data));
  } catch (error) {
    yield put(getTestByAuthorFailed(error.response.status));
  }
}

function* getAllAuthorsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.getAllAuthors({ perPage: 0, ...payload });
    yield put(getAllAuthorsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed in getting authors');
    yield put(getAllAuthorsFailed(error.response.status));
  }
}

function* inviteAuthorSaga(action) {
  const { payload } = action;

  try {
    const { data } = yield adminApi.inviteAuthor(payload);
    yield put(inviteAuthorSuccess(data));
    callToast('success', 'Success - Author was invited');
  } catch (error) {
    callToast('error', 'Error - Failed invite author');
    yield put(inviteAuthorFailed(error.response.status));
  }
}

function* inviteEmployerSaga(action) {
  const { payload } = action;

  try {
    const { data } = yield adminApi.inviteEmployer(payload);
    yield put(inviteEmployerSuccess(data));
    callToast('success', 'Success - Employer was invited');
  } catch (error) {
    callToast('error', 'Error - Failed invite employer');
    yield put(inviteEmployerFailed(error.response.status));
  }
}

function* setNoteEmployerSaga(action) {
  const { payload } = action;

  try {
    const { data } = yield adminApi.setNoteEmployer(payload);
    yield put(noteEmployerSuccess(data));
  } catch (error) {
    yield put(noteEmployerFailed(error.response.status));
  }
}

function* deleteNoteEmployerSaga(action) {
  const { payload } = action;

  try {
    const { data } = yield adminApi.deleteNoteEmployer(payload);
    yield put(deleteNoteEmployerSuccess(data));
  } catch (error) {
    yield put(deleteNoteEmployerFailed(error.response.status));
  }
}

function* setDemoAssessmentsIdSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.setDemoAssessmentsId(payload);
    yield put(setDemoAssessmentsIdSuccess(data));
  } catch (error) {
    yield put(setDemoAssessmentsIdFailed(error.response.status));
  }
}

function* getDemoAssessmentsIdSaga() {
  try {
    const { data } = yield adminApi.getDemoAssessmentsId();
    yield put(getDemoAssessmentsIdSuccess(data));
  } catch (error) {
    yield put(getDemoAssessmentsIdFailed(error.response.status));
  }
}

function* generateApplicationsSaga(action) {
  try {
    const { assessmentId, data } = action.payload;
    yield adminApi.generateApplications(assessmentId, data);
    yield put(generateApplicationsSuccess());
  } catch (error) {
    yield put(generateApplicationsFailure(error.response.status));
  }
}

export default function* adminWatcher() {
  yield takeLatest(adminConstants.GET_ALL_EMPLOYERS_REQUEST, getAllEmployersSage);
  yield takeLatest(adminConstants.GET_DASHBOARD_STAT_REQUEST, getDashboardStatSaga);
  yield takeLatest(adminConstants.GET_ALL_AUTHORS_REQUEST, getAllAuthorsSaga);
  yield takeLatest(adminConstants.INVITE_AUTHORS_REQUEST, inviteAuthorSaga);
  yield takeLatest(adminConstants.GET_AUTHOR_REQUEST, getAuthorSaga);
  yield takeLatest(adminConstants.GET_TEST_BY_AUTHOR_REQUEST, getTestByAuthorSaga);
  yield takeLatest(adminConstants.INVITE_EMPLOYER_REQUEST, inviteEmployerSaga);
  yield takeLatest(adminConstants.GET_SINGLE_EMPLOYER_REQUEST, getSingleEmployerSage);
  yield takeLatest(adminConstants.GET_SINGLE_COMPANY_REQUEST, getSingleCompanySage);
  yield takeLatest(adminConstants.NOTE_EMPLOYER_REQUEST, setNoteEmployerSaga);
  yield takeLatest(adminConstants.DELETE_NOTE_EMPLOYER_REQUEST, deleteNoteEmployerSaga);
  yield takeLatest(adminConstants.GET_ALL_TESTS_FAMILIES_REQUEST, getAllTestsFamiliesSaga);
  yield takeLatest(adminConstants.SET_DEMO_ASSESSMENTS_ID_REQUEST, setDemoAssessmentsIdSaga);
  yield takeLatest(adminConstants.GET_DEMO_ASSESSMENTS_ID_REQUEST, getDemoAssessmentsIdSaga);
  yield takeLatest(adminConstants.GENERATE_APPLICATIONS_REQUEST, generateApplicationsSaga);
}
