import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Button, Form, Icon, Input, Typography } from 'ui-kit';
import { customStyles } from 'ui-kit/CustomSelect/SelectCustom';
import * as routes from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
  clearTemplate,
  clearTemplates,
  clearTemplatesPagination,
  getArchivedTemplatesRequest,
  getDraftTemplatesRequest,
  getPublishedTemplatesRequest
} from 'store/tests/actions';
import { useDebouceCallback } from 'hooks';
import { TabLibrary } from 'components/TabLibrary/TabLibrary';
import { badgetStatus } from 'utils/helpers';
import { clearOccupation } from 'store/configuration/actions/subscriptions';
import { Link } from 'react-router-dom';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { ThreeDots } from 'react-loader-spinner';
import Loader from 'components/Loader';

const perPage = 30;

function displayStrings(strings, maxWidth, fontSize) {
  if (strings?.length === 0) {
    return;
  }
  const letterWidth = fontSize / 2;
  const maxLetters = Math.floor(maxWidth / letterWidth);

  let stringIndex = 0;
  let totalLetters = 0;
  let displayedStrings = [];

  while (totalLetters < maxLetters && stringIndex < strings?.length) {
    totalLetters += strings[stringIndex]?.length;
    if (totalLetters <= maxLetters) {
      displayedStrings.push(strings[stringIndex]);
    }
    stringIndex++;
  }

  return displayedStrings;
}

const Component = ({ data }) => {
  return (
    <Link
      to={
        data?.status === 'draft'
          ? routes.ASSESSMENT_TEMPLATES + `/${data?._id}` + routes.EDIT
          : routes.ASSESSMENT_TEMPLATES + `/${data?._id}`
      }
      style={{ wordBreak: 'break-word' }}
      className={`group relative flex w-full cursor-pointer flex-col justify-between break-words rounded-md border border-gray-400 bg-white p-6 pt-[36px] transition-all hover:border-green-400`}>
      <span
        className={`absolute top-0 left-6 rounded-b ${badgetStatus(
          data?.status
        )} py-[2px] px-[10px] font-semibold capitalize`}>
        {`${data?.status}`}
      </span>
      <div className="flex h-full gap-12">
        <div className="flex flex-2 flex-col items-start gap-3">
          <Typography
            className="transition-all group-hover:text-green-400"
            variant="heading-h5"
            title={data?.name}
          />
          {data?.type === 'generic' ? (
            <span className="flex h-[25px] items-center justify-center rounded bg-[#E0ECF9] px-[6px] py-[1px] text-xs font-bold text-[#337195] shadow-small">
              GENERIC TEMPLATE
            </span>
          ) : (
            <div className="flex items-center gap-3">
              {displayStrings(data?.jobRoles, 500, 14)?.map(role => (
                <div
                  className="rounded border border-[#C9CCCF] bg-[#F6F6F7] px-3 py-[1px] text-sm"
                  key={role}>
                  {role}
                </div>
              ))}
              {data?.jobRoles?.length - displayStrings(data?.jobRoles, 500, 14)?.length > 0 && (
                <Typography
                  className="text-[#8C9196]"
                  variant="small-default"
                  title={`${
                    data?.jobRoles?.length - displayStrings(data?.jobRoles, 500, 14)?.length
                  } More`}
                />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-1 justify-between gap-6">
          <div className="flex-1">
            {data?.occupation && (
              <div className="space-y-1">
                <Typography variant="small-default" className="text-[#6D7175]" title="Occupation" />
                <Typography variant="regular-default" title={data?.occupation?.name} />
              </div>
            )}
          </div>
          <div className="flex-1 space-y-1 justify-self-end">
            <Typography
              variant="small-default"
              className="text-[#6D7175]"
              title="Recommended Tests"
            />
            <Typography variant="regular-default" title={data?.recommendedTests} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export const AssessmentTemplatesLibrary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(-1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [params, setParams] = useState({ page: 0 });

  const {
    loading,
    publishedTemplates,
    publishedTemplatesTotal,
    draftTemplates,
    draftTemplatesTotal,
    archivedTemplates,
    archivedTemplatesTotal
  } = useSelector(store => store.tests);

  useEffect(() => {
    !loading && setSearchLoading(false);
  }, [loading]);

  useEffect(() => {
    setParams(prev => {
      return { ...prev, page: 0 };
    });
    dispatch(clearTemplatesPagination());
  }, [selectedTab]);

  useEffect(() => {
    dispatch(clearTemplate());
    dispatch(clearOccupation());
  }, []);

  const tabs = useMemo(() => {
    return [
      { title: 'draft', count: draftTemplatesTotal, arr: draftTemplates },
      { title: 'published', count: publishedTemplatesTotal, arr: publishedTemplates },
      { title: 'archived', count: archivedTemplatesTotal, arr: archivedTemplates }
    ];
  }, [publishedTemplates, draftTemplates, archivedTemplates]);

  const hasMore = useMemo(() => {
    if (tabs[selectedTab]?.arr?.length >= tabs[selectedTab]?.count) {
      return false;
    }

    return true;
  }, [tabs, selectedTab]);

  useEffect(() => {
    if (selectedTab === -1 || !hasMore) {
      return;
    }
    if (selectedTab === 0) {
      dispatch(
        getDraftTemplatesRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
    if (selectedTab === 2) {
      dispatch(
        getArchivedTemplatesRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
    if (selectedTab === 1) {
      dispatch(
        getPublishedTemplatesRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
  }, [params?.page]);

  const debounceSubmit = useDebouceCallback(value => {
    setSearchLoading(true);
    setParams(prev => {
      return { ...prev, ...value, page: 0 };
    });
  });

  useEffect(() => {
    setParams(prev => {
      return { ...prev, page: 0 };
    });
    dispatch(clearTemplates());
    dispatch(
      getPublishedTemplatesRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
    dispatch(
      getDraftTemplatesRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
    dispatch(
      getArchivedTemplatesRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
  }, [params?.search, params?.origin, params?.type]);

  const onScroll = () => {
    setParams(prev => {
      return { ...prev, page: prev.page + 1 };
    });
  };

  return (
    <div className="py-8 px-7.5">
      {loading && !searchLoading && <Loader />}
      <Form defaultValues={{ search: params.search, type: params.type }}>
        {({ handleSubmit }) => (
          <>
            <div className="flex justify-between ">
              <div className="flex items-center space-x-3">
                <Typography title="Assessment Templates" variant="heading-h1" />
                <Button
                  onClick={() => navigate(routes.ASSESSMENT_TEMPLATES_CREATE)}
                  variant="primary"
                  title="Create Template"
                  iconSize={20}
                  iconStart={faPlus}
                />
              </div>
              <div className="flex w-[650px] items-center space-x-3">
                <div className="flex-1">
                  <Select
                    onChange={value =>
                      setParams(prev => {
                        return { ...prev, origin: value.value };
                      })
                    }
                    options={[
                      { value: '', label: 'All Templates' },
                      { value: 'occupation', label: 'Occupation' },
                      { value: 'custom', label: 'Custom' }
                    ]}
                    placeholder="Origin"
                    isClearable={false}
                    isSearchable={false}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                  />
                </div>
                <div className="flex-1">
                  <Select
                    onChange={value =>
                      setParams(prev => {
                        return { ...prev, type: value.value };
                      })
                    }
                    options={[
                      { value: '', label: 'All Templates' },
                      { value: 'role_specific', label: 'Role-Specific' },
                      { value: 'generic', label: 'Generic' }
                    ]}
                    placeholder="Type"
                    isClearable={false}
                    isSearchable={false}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                  />
                </div>
                <Input
                  leftComponent={() => (
                    <Icon className="ml-3" size={16} color="#8C9196" icon={faMagnifyingGlass} />
                  )}
                  rightComponent={() => (
                    <div className="mr-3 w-[16px]">
                      {searchLoading && (
                        <ThreeDots height="16" width="16" color="grey" ariaLabel="loading" />
                      )}
                    </div>
                  )}
                  name="search"
                  onChangeValue={handleSubmit(debounceSubmit)}
                  width="w-[250px]"
                  placeholder="Search..."
                />
              </div>
            </div>
            <TabLibrary
              tabs={tabs}
              hasMore={hasMore}
              onScroll={onScroll}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              children={Component}
            />
          </>
        )}
      </Form>
    </div>
  );
};
