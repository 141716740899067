import { Section, Typography } from 'ui-kit';

export const TestViewSettings = ({ currentTest }) => (
  <>
    <Section>
      <Section.Body className="flex flex-col gap-6">
        <div className="flex flex-col gap-[48px]">
          <div>
            <Typography variant="heading-h4" title="Priority in Recommended Tests" />

            <div className="mt-3 mb-1.5 flex h-[44px] w-[80px] items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
              <Typography
                className="capitalize text-[#8C9196]"
                variant="regular-default"
                title={currentTest?.recommendedScore}
              />
            </div>

            <Typography
              className="text-[#6D7175]"
              variant="small-default"
              title="Lower number will appear first"
            />
          </div>
          <div className="flex flex-col gap-3">
            <Typography variant="heading-h4" title="Test Evaluation" />
            <div className="flex gap-8">
              {currentTest?.evaluation.map((evaluation, index) => (
                <div className="flex flex-1 flex-col gap-[2px] min-w-[0px]" key={index}>
                  <Typography variant="regular-default" title={`Question ${index + 1}`} />

                  <div className="flex h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
                    <Typography
                      className="capitalize text-[#8C9196] overflow-hidden whitespace-nowrap text-ellipsis max-w-[184px]"
                      variant="regular-default"
                      title={evaluation}
                    />
                    <div className="flex h-[20px] w-[20px] items-center">
                      <i className="fa-solid fa-chevron-down text-[#D2D5D8] text-xl"></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section.Body>
    </Section>
  </>
);
