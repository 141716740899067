import React from 'react';

import { useSelector } from 'react-redux';
import { List } from 'ui-kit';
import { CreateSection } from '../CreateSection';

import { QuestionSection } from '../QuestionSection';

interface IQuestionSectionsProps {
  isWithSections?: boolean;
  isCustom?: boolean;
}

export const QuestionSections: React.FC<IQuestionSectionsProps> = ({
  isWithSections = true,
  isCustom = false
}) => {
  const sectionsTest: any[] = useSelector((s: any) => s.tests.currentTest.sections);
  const sectionsCustom: any[] = useSelector(
    (s: any) => s.assessments.currentAssessment.customQuestions.sections
  );
  const hidden: boolean = useSelector((s: any) => s.tests.currentTest.hidden);
  const allQuestionsLength = (isCustom ? sectionsCustom : sectionsTest)?.reduce(
    (acc, { questions }) => acc + (questions?.length || 0),
    0
  );

  return (
    <>
      <List
        data={isCustom ? sectionsCustom : sectionsTest}
        element={({ name, _id, questions }, idx) => (
          <QuestionSection
            name={name}
            hidden={isCustom ? true : hidden}
            allQuestionsLength={allQuestionsLength}
            idx={idx}
            sectionId={_id}
            questions={questions}
          />
        )}
        keyExtractor={({ _id }) => _id}
        wrap="div"
        className="space-y-5"
      />
      {isWithSections && <CreateSection />}
    </>
  );
};
