import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Button, Form, List, Input, Icon } from 'ui-kit';

import RequestAccessForm from 'components/Forms/RequestAccessForm';
import EmployerCard from 'components/Cards/EmployerCard';
import EmptyResourse from 'components/EmptyResourse';

import { useDebouceCallback, useQueryParams } from 'hooks';

import { clearCompanyEmployerData, getAllEmployersRequest } from 'store/admin/actions';
import Pagination from 'components/Pagination';
import { faMagnifyingGlass, faPlus } from '@fortawesome/pro-light-svg-icons';
import { ThreeDots } from 'react-loader-spinner';

const Employers = () => {
  const dispatch = useDispatch();

  const { loading, employers, total } = useSelector(store => store.admin);
  const perPage = 39;
  const { params, set, push } = useQueryParams({ page: 0 });
  const debounceSubmit = useDebouceCallback(set);

  const [isInviteModalOpen, setInviteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(clearCompanyEmployerData());
  }, []);

  useEffect(() => {
    dispatch(
      getAllEmployersRequest({
        skip: params.page * perPage,
        perPage,
        ...params
      })
    );
  }, [params]);
  return (
    <div className="py-8 px-7.5">
      <Form defaultValues={{ search: params.search }}>
        {({ handleSubmit, getValues }) => (
          <>
            <ul className="relative mb-6 flex w-full  justify-between">
              <li className="flex items-center space-x-3">
                <Typography title="Employers" variant="heading-h1" />
                <Button
                  onClick={() => setInviteModalOpen(true)}
                  icon={faPlus}
                  title="Invite Employer"
                  variant="create"
                />
              </li>
              <li className="flex space-x-3">
                <Input
                  onChangeValue={handleSubmit(debounceSubmit)}
                  leftComponent={() => (
                    <Icon icon={faMagnifyingGlass} size={16} className="ml-3" color="#8C9196" />
                  )}
                  rightComponent={() => (
                    <div className="mr-3 w-[16px]">
                      {loading && (
                        <ThreeDots height="16" width="16" color="grey" ariaLabel="loading" />
                      )}
                    </div>
                  )}
                  className="max-w-[250px] flex-3"
                  name="search"
                  placeholder="Search..."
                />
              </li>
            </ul>
            {employers?.length ? (
              <section className={`flex w-full flex-col justify-between`}>
                {loading ? (
                  <List
                    wrap="ul"
                    data={Array(perPage).fill(0)}
                    keyExtractor={(_, idx) => idx}
                    //TODO: ADD CardLoader 2 sprint
                    // element={props => <CardLoader {...props} type="assessment" />}
                    element={props => <div className="h-[184px] min-w-[345px] bg-gray-300"></div>}
                    className="mb-6 flex min-h-[596px] flex-col gap-6"
                  />
                ) : (
                  <List
                    wrap="ul"
                    data={employers}
                    keyExtractor={(_, idx) => idx}
                    element={props => <EmployerCard {...props} />}
                    className="mb-6 flex min-h-[596px] flex-col gap-6"
                  />
                )}
                {total > perPage && (
                  <Pagination
                    page={params.page}
                    itemsPerPage={perPage}
                    totalItems={total}
                    setPage={page => push({ page })}
                  />
                )}
              </section>
            ) : (
              <EmptyResourse
                className="my-20"
                title={
                  getValues()?.search ? 'No matches found' : "You don't have any employers yet"
                }
              />
            )}
          </>
        )}
      </Form>
      <RequestAccessForm
        isInviteModalOpen={isInviteModalOpen}
        setInviteModalOpen={setInviteModalOpen}
      />
    </div>
  );
};

export default Employers;
