import { Tooltip } from 'react-tooltip';

export const Tooltips = () => (
  <>
    <Tooltip
      id="premium-tooltip-top"
      className="rounded-s z-50 ml-[43px] mt-[3px] w-[300px] max-w-[300px]
        after:absolute after:left-[18.5px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      Unlock unlimited views of candidates' assessments by upgrading your account to the{' '}
      <b>Starter</b> or <b>Business subscription.</b>
    </Tooltip>
    <Tooltip
      id="premium-tooltip-bottom"
      className="rounded-s relative z-50 ml-[12px] mt-[-3px] w-[300px] max-w-[300px]
        after:absolute after:left-[18.5px] after:top-[-5px] after:border-x-[5px]
        after:border-t-0 after:border-b-[6px] after:border-x-transparent after:border-b-[#02574D]"
      noArrow={true}
      style={{
        background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
        fontSize: '14px'
      }}>
      Unlock unlimited views of candidates' assessments by upgrading your account to the{' '}
      <b>Starter</b> or <b>Business subscription.</b>
    </Tooltip>
  </>
);
