import url from 'utils/url';
import axiosInstance from './axiosHelper';

export const authApi = {
  signIn(body) {
    return axiosInstance.post('auth/login', body);
  },
  signUp(body) {
    return axiosInstance.post('auth/register', body);
  },
  emailVerify(body) {
    return axiosInstance.post('auth/email/verify', body);
  },
  resendEmail(userId) {
    return axiosInstance.get(`auth/email/resend/${userId}`);
  },
  resetPassword(body) {
    return axiosInstance.post('auth/password/email', body);
  },
  newPassword(body) {
    return axiosInstance.post('auth/password/reset', body);
  },
  validateHash(hash) {
    return axiosInstance.get(`auth/hash/validate?hash=${hash}`);
  }
};

export const commonApi = {
  //profile

  getProfile() {
    return axiosInstance.get('profile');
  },
  editProfile(body) {
    return axiosInstance.put('profile', body);
  },
  profileComplete(body) {
    return axiosInstance.put('profile/complete', body);
  },
  editAdminProfile(body) {
    return axiosInstance.put('admin/my', body);
  },
  editProfileImage(body) {
    return axiosInstance.put('profile/image', body);
  },
  deleteProfileImage() {
    return axiosInstance.delete('profile/image');
  },
  editProfileEmail(body) {
    return axiosInstance.put('profile/email', body);
  },
  editProfilePassword(body) {
    return axiosInstance.put('profile/password', body);
  },

  //company
  getCompanyData() {
    return axiosInstance.get('profile/company');
  },
  editCompany(body) {
    return axiosInstance.put('profile/company', body);
  },
  editCompanyImage(body) {
    return axiosInstance.put('profile/company/logo', body);
  },
  deleteCompanyImage() {
    return axiosInstance.delete('profile/company/logo');
  },

  //tests
  getAllTests(params) {
    return axiosInstance.get(url(['tests'], params));
  },
  getTestsByImportance({ id, params }) {
    return axiosInstance.get(url(['tests', 'recommended', id && id], params));
  },
  getTestsVersionsTest(id) {
    return axiosInstance.get(url(['tests', id, 'versions']));
  },
  getPublishedTests(params) {
    return axiosInstance.get(url(['tests', 'published'], params));
  },
  getDraftTests(params) {
    return axiosInstance.get(url(['tests', 'draft'], params));
  },
  deleteDraftTest(id) {
    return axiosInstance.delete(url(['tests', id]));
  },
  getArchivedTests(params) {
    return axiosInstance.get(url(['tests', 'archived'], params));
  },
  getCurrentTest(id) {
    return axiosInstance.get(url(['tests', id]));
  },

  //subscriptions
  getSubscriptions() {
    return axiosInstance.get(url(['subscriptions']));
  },
  downgradeFree() {
    return axiosInstance.delete(url(['subscriptions']));
  },
  stripeConnect(body) {
    return axiosInstance.post(url(['checkout']), body);
  },
  getCurrentSubscription() {
    return axiosInstance.get(url(['subscriptions', 'current']));
  },

  //occupations
  getAllOccupations() {
    return axiosInstance.get(url(['occupations']));
  },
  getSingleOccupation(id) {
    return axiosInstance.get(url(['occupations', id]));
  },
  getAllOccupationsResources() {
    return axiosInstance.get(url(['occupation-resources']));
  },
  getSingleOccupationResource(params) {
    return axiosInstance.get(url(['occupation-resources', params]));
  },

  //candidates
  getAllCandidates(params) {
    return axiosInstance.get(url(['candidates'], params));
  },
  getCandidate(id) {
    return axiosInstance.get(url(['candidates', id]));
  },
  getApplication(id) {
    return axiosInstance.get(url(['applications', id]));
  },
  getAllApplications(params) {
    return axiosInstance.get(url(['applications'], params));
  },
  getApplicationTests(id) {
    return axiosInstance.get(url(['applications', id, 'tests']));
  },
  getApplicationQuestions(id) {
    return axiosInstance.get(url(['applications', id, 'customQuestions']));
  },
  setApplicationStatus({ id, data }) {
    return axiosInstance.post(url(['applications', id, 'status']), data);
  },
  restoreApplicationStatus(id) {
    return axiosInstance.post(url(['applications', id, 'restore']));
  },
  setApplicationOptionalStatus({ id, data }) {
    return axiosInstance.post(url(['applications', id, 'optional-status']), data);
  },
  setApplicationRating({ applicationId, data }) {
    return axiosInstance.post(url(['application', applicationId, 'rating']), data);
  },
  setApplicationNotes({ applicationId, data }) {
    return axiosInstance.post(url(['applications', applicationId, 'note']), data);
  },
  getOpenedApplications(id) {
    return axiosInstance.get(url(['assessments', id, 'applications', 'opened']));
  },
  getEvaluatedApplications(id) {
    return axiosInstance.get(url(['assessments', id, 'applications', 'not-evaluated']));
  },
  getInterviewApplications(id) {
    return axiosInstance.get(url(['assessments', id, 'applications', 'invited-for-interview']));
  },
  getHireApplications(id) {
    return axiosInstance.get(url(['assessments', id, 'applications', 'offer-sent']));
  },

  //dashboard
  getPerformance() {
    return axiosInstance.get(url(['applications', 'performance']));
  },
  getPendingApplications() {
    return axiosInstance.get(url(['applications', 'pending', 'count']));
  },
  getApplicationInfo(id) {
    return axiosInstance.get(url(['applications', id, 'info']));
  },
  getApplicationSubscribed(id) {
    return axiosInstance.get(url(['applications', id, 'subscription-payed']));
  },

  //assessments
  getAssessments(params) {
    return axiosInstance.get(url(['assessments'], params));
  },
  getPublishedAssessments(params) {
    return axiosInstance.get(url(['assessments', 'published'], params));
  },
  getPublishedAssessmentsNames(params) {
    return axiosInstance.get(url(['assessments', 'published', 'names'], params));
  },
  getPublishedDemoAssessments(params) {
    return axiosInstance.get(url(['assessments', 'demo', 'published'], params));
  },
  getPublicDemoAssessments() {
    return axiosInstance.get('assessments/demo');
  },
  getDraftAssessments(params) {
    return axiosInstance.get(url(['assessments', 'draft'], params));
  },
  getLastPublishAssessment() {
    return axiosInstance.get(url(['assessments', 'published', 'last']));
  },
  getArchivedAssessments(params) {
    return axiosInstance.get(url(['assessments', 'archived'], params));
  },
  getArchivedDemoAssessments(params) {
    return axiosInstance.get(url(['assessments', 'demo', 'archived'], params));
  },
  getAssessment(id) {
    return axiosInstance.get(url(['assessments', id]));
  },

  //other
  getRecommendedTemplates(body) {
    return axiosInstance.post(url(['assessment-templates']), { jobRole: body });
  },
  getAssessmentGenericTemplates() {
    return axiosInstance.get(url(['generic-templates']));
  },
  getEvaluateQuestions(id) {
    return axiosInstance.get(url(['tests', id, 'evaluation']));
  },
  getCountries() {
    return axiosInstance.get('countries');
  },
  uploadAttachment(body) {
    return axiosInstance.post(url(['attachment']), body);
  },
  getAreasOfUse() {
    return axiosInstance.get(url(['areas-of-use']));
  },
  getSuitableFor() {
    return axiosInstance.get(url(['suitable-for']));
  },
  getSectionsTags() {
    return axiosInstance.get(url(['section-tag-names']));
  },
  getJobTitles(params) {
    return axiosInstance.get(url(['job-titles'], params));
  },
  getJobRoles(params) {
    return axiosInstance.get(url(['job-roles'], params));
  },
  getTestCategories(params) {
    return axiosInstance.get(url(['categories'], params));
  },
  getTypesWithTests() {
    return axiosInstance.get(url(['tests', 'test-types']));
  },
  getAnswerChoises() {
    return axiosInstance.get(url(['answer-templates']));
  }
};

export const adminApi = {
  createSubscription(body) {
    return axiosInstance.post('admin/subscriptions', body);
  },
  editSubscription({ subscriptionId, data }) {
    return axiosInstance.put(`admin/subscriptions/${subscriptionId}`, data);
  },
  publishSubscription(id) {
    return axiosInstance.put(`admin/subscriptions/${id}/publish`);
  },
  archiveSubscription(id) {
    return axiosInstance.put(`admin/subscriptions/${id}/archive`);
  },
  createTestCategory(body) {
    return axiosInstance.post('admin/categories', body);
  },
  editTestCategory({ id, data }) {
    return axiosInstance.put(`admin/categories/${id}`, data);
  },
  deleteTestCategory(id) {
    return axiosInstance.delete(`admin/categories/${id}`);
  },
  createAnswerChoise(body) {
    return axiosInstance.post('admin/answer-templates', body);
  },
  editAnswerChoise({ id, data }) {
    return axiosInstance.put(`admin/answer-templates/${id}`, data);
  },
  deleteAnswerChoise(id) {
    return axiosInstance.delete(`admin/answer-templates/${id}`);
  },
  getAllAdmins() {
    return axiosInstance.get('admin/admins');
  },
  inviteAdmin(body) {
    return axiosInstance.post('admin/admins/invite', body);
  },
  getAdmin(id) {
    return axiosInstance.get(`admin/admins/${id}`);
  },
  editAdmin({ id, data }) {
    return axiosInstance.put(`admin/admins/${id}`, data);
  },
  resourseMapTest({ resourceId, data }) {
    return axiosInstance.post(`admin/resources/${resourceId}/map`, data);
  },
  resourseUnMapTest(id) {
    return axiosInstance.delete(`admin/resources/${id}/map`);
  },
  getImportStatus() {
    return axiosInstance.get('admin/onetonline.import');
  },
  getLastImportStatus() {
    return axiosInstance.get('admin/onetonline/import/last');
  },
  startImport() {
    return axiosInstance.post('admin/onetonline/import');
  },
  getDashboardStat() {
    return axiosInstance.get('admin/dashboard');
  },
  getAllEmployers(params) {
    return axiosInstance.get('admin/employers', { params });
  },
  getSingleEmployer(id) {
    return axiosInstance.get(`admin/employers/${id}`);
  },
  getSingleCompany(id) {
    return axiosInstance.get(`admin/company/${id}`);
  },
  getAuthor(id) {
    return axiosInstance.get(`admin/authors/${id}`);
  },
  getAllAuthors(params) {
    return axiosInstance.get('admin/authors', { params });
  },
  getTestByAuthor({ id, params }) {
    return axiosInstance.get(`admin/authors/${id}/tests`, { params });
  },
  inviteAuthor(body) {
    return axiosInstance.post('admin/authors/invite', body);
  },
  inviteEmployer(body) {
    return axiosInstance.post('admin/employers/invite', body);
  },
  setNoteEmployer({ userId, data }) {
    return axiosInstance.post(`admin/user/${userId}/note`, data);
  },
  deleteNoteEmployer({ userId, noteId }) {
    return axiosInstance.delete(`admin/user/${userId}/note/${noteId}`);
  },
  getAllTestsFamilies(params) {
    return axiosInstance.get('admin/tests/test-families', { params });
  },
  setDemoAssessmentsId(body) {
    return axiosInstance.post('admin/assessments/demo', body);
  },
  getDemoAssessmentsId() {
    return axiosInstance.get('admin/assessments/demo');
  },
  generateApplications(assessmentId, data) {
    return axiosInstance.post(`admin/applications/generate/${assessmentId}`, data);
  }
};

export const authorApi = {
  createTest(body) {
    return axiosInstance.post('author/tests', body);
  },
  createNewVersionTest(id) {
    return axiosInstance.post(`author/tests/${id}/versions`);
  },
  updateTest({ id, data }) {
    return axiosInstance.put(`author/tests/${id}`, data.test);
  },
  deleteTest(id) {
    return axiosInstance.delete(`author/tests/${id}`);
  },
  createTemplate(body) {
    return axiosInstance.post('author/assessment-templates', body);
  },
  editTemplate({ id, data }) {
    return axiosInstance.put(`author/assessment-templates/${id}`, data);
  },
  publishTemplate(id) {
    return axiosInstance.put(`author/assessment-templates/${id}/publish`);
  },
  archiveTemplate(id) {
    return axiosInstance.put(`author/assessment-templates/${id}/archive`);
  },
  draftTemplate(id) {
    return axiosInstance.put(`author/assessment-templates/${id}/draft`);
  },
  getSingleTemplate(id) {
    return axiosInstance.get(`author/assessment-templates/${id}`);
  },
  getDraftTemplates(params) {
    return axiosInstance.get('author/assessment-templates/draft', { params });
  },
  getPublishedTemplates(params) {
    return axiosInstance.get('author/assessment-templates/published', { params });
  },
  getArchivedTemplates(params) {
    return axiosInstance.get('author/assessment-templates/archived', { params });
  }
};

export const employerApi = {
  createNewAssessment(body) {
    return axiosInstance.post('employer/assessments', body);
  },
  updateAssessment({ assessmentId, data }) {
    return axiosInstance.put(`employer/assessments/${assessmentId}`, data);
  },
  publishAssessment(id) {
    return axiosInstance.put(`employer/assessments/${id}/publish`);
  },
  archiveAssessment(id) {
    return axiosInstance.put(`employer/assessments/${id}/archive`);
  },
  restoreAssessment(id) {
    return axiosInstance.put(`employer/assessments/${id}/restore`);
  },
  duplicateAssessment({ id, data }) {
    return axiosInstance.put(`employer/assessments/${id}/duplicate`, data);
  },
  inviteCandidate({ id, data }) {
    return axiosInstance.post(`employer/assessments/${id}/invite-candidate`, data);
  },
  getFAQs() {
    return axiosInstance.get('employer/faq');
  },
  testRequest(body) {
    return axiosInstance.post('employer/tests/request', body);
  },
  deleteAssessment(id) {
    return axiosInstance.delete(`employer/assessments/${id}`);
  }
};
