import { createAction } from 'redux-actions';
import { testConstants } from '../../tests/actions';

export const configurationsConstants = {
  RESET_ERRORS: 'RESET_ERRORS',

  GET_SUBSCRIPTIONS_REQUEST: 'GET_SUBSCRIPTIONS_REQUEST',
  GET_SUBSCRIPTIONS_SUCCESS: 'GET_SUBSCRIPTIONS_SUCCESS',
  GET_SUBSCRIPTIONS_FAILURE: 'GET_SUBSCRIPTIONS_FAILURE',

  GET_CURRENT_SUBSCRIPTION_REQUEST: 'GET_CURRENT_SUBSCRIPTION_REQUEST',
  GET_CURRENT_SUBSCRIPTION_SUCCESS: 'GET_CURRENT_SUBSCRIPTION_SUCCESS',
  GET_CURRENT_SUBSCRIPTION_FAILURE: 'GET_CURRENT_SUBSCRIPTION_FAILURE',

  CREATE_SUBSCRIPTION_REQUEST: 'CREATE_SUBSCRIPTION_REQUEST',
  CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_FAILURE: 'CREATE_SUBSCRIPTION_FAILURE',

  EDIT_SUBSCRIPTION_REQUEST: 'EDIT_SUBSCRIPTION_REQUEST',
  EDIT_SUBSCRIPTION_SUCCESS: 'EDIT_SUBSCRIPTION_SUCCESS',
  EDIT_SUBSCRIPTION_FAILURE: 'EDIT_SUBSCRIPTION_FAILURE',

  PUBLISH_SUBSCRIPTION_REQUEST: 'PUBLISH_SUBSCRIPTION_REQUEST',
  PUBLISH_SUBSCRIPTION_SUCCESS: 'PUBLISH_SUBSCRIPTION_SUCCESS',
  PUBLISH_SUBSCRIPTION_FAILURE: 'PUBLISH_SUBSCRIPTION_FAILURE',

  ARCHIVE_SUBSCRIPTION_REQUEST: 'ARCHIVE_SUBSCRIPTION_REQUEST',
  ARCHIVE_SUBSCRIPTION_SUCCESS: 'ARCHIVE_SUBSCRIPTION_SUCCESS',
  ARCHIVE_SUBSCRIPTION_FAILURE: 'ARCHIVE_SUBSCRIPTION_FAILURE',

  DOWNGRADE_FREE_REQUEST: 'DOWNGRADE_FREE_REQUEST',
  DOWNGRADE_FREE_SUCCESS: 'DOWNGRADE_FREE_SUCCESS',
  DOWNGRADE_FREE_FAILURE: 'DOWNGRADE_FREE_FAILURE',

  STRIPE_CONNECT_REQUEST: 'STRIPE_CONNECT_REQUEST',
  STRIPE_CONNECT_SUCCESS: 'STRIPE_CONNECT_SUCCESS',
  STRIPE_CONNECT_FAILURE: 'STRIPE_CONNECT_FAILURE',

  GET_TEST_TYPES_WITH_TESTS_REQUEST: 'GET_TEST_TYPES_WITH_TESTS_REQUEST',
  GET_TEST_TYPES_WITH_TESTS_SUCCESS: 'GET_TEST_TYPES_WITH_TESTS_SUCCESS',
  GET_TEST_TYPES_WITH_TESTS_FAILURE: 'GET_TEST_TYPES_WITH_TESTS_FAILURE',

  GET_TEST_CATEGORIES_REQUEST: 'GET_TEST_CATEGORIES_REQUEST',
  GET_TEST_CATEGORIES_SUCCESS: 'GET_TEST_CATEGORIES_SUCCESS',
  GET_TEST_CATEGORIES_FAILURE: 'GET_TEST_CATEGORIES_FAILURE',
  SHOW_HIDE_CATEGORY: 'SHOW_HIDE_CATEGORY',

  CREATE_TEST_CATEGORY_REQUEST: 'CREATE_TEST_CATEGORY_REQUEST',
  CREATE_TEST_CATEGORY_SUCCESS: 'CREATE_TEST_CATEGORY_SUCCESS',
  CREATE_TEST_CATEGORY_FAILURE: 'CREATE_TEST_CATEGORY_FAILURE',

  EDIT_TEST_CATEGORY_REQUEST: 'EDIT_TEST_CATEGORY_REQUEST',
  EDIT_TEST_CATEGORY_SUCCESS: 'EDIT_TEST_CATEGORY_SUCCESS',
  EDIT_TEST_CATEGORY_FAILURE: 'EDIT_TEST_CATEGORY_FAILURE',

  DELETE_TEST_CATEGORY_REQUEST: 'DELETE_TEST_CATEGORY_REQUEST',
  DELETE_TEST_CATEGORY_SUCCESS: 'DELETE_TEST_CATEGORY_SUCCESS',
  DELETE_TEST_CATEGORY_FAILURE: 'DELETE_TEST_CATEGORY_FAILURE',

  GET_ANSWER_CHOISES_REQUEST: 'GET_ANSWER_CHOISES_REQUEST',
  GET_ANSWER_CHOISES_SUCCESS: 'GET_ANSWER_CHOISES_SUCCESS',
  GET_ANSWER_CHOISES_FAILURE: 'GET_ANSWER_CHOISES_FAILURE',

  CREATE_ANSWER_CHOISE_REQUEST: 'CREATE_ANSWER_CHOISE_REQUEST',
  CREATE_ANSWER_CHOISE_SUCCESS: 'CREATE_ANSWER_CHOISE_SUCCESS',
  CREATE_ANSWER_CHOISE_FAILURE: 'CREATE_ANSWER_CHOISE_FAILURE',

  EDIT_ANSWER_CHOISE_REQUEST: 'EDIT_ANSWER_CHOISE_REQUEST',
  EDIT_ANSWER_CHOISE_SUCCESS: 'EDIT_ANSWER_CHOISE_SUCCESS',
  EDIT_ANSWER_CHOISE_FAILURE: 'EDIT_ANSWER_CHOISE_FAILURE',

  DELETE_ANSWER_CHOISE_REQUEST: 'DELETE_ANSWER_CHOISE_REQUEST',
  DELETE_ANSWER_CHOISE_SUCCESS: 'DELETE_ANSWER_CHOISE_SUCCESS',
  DELETE_ANSWER_CHOISE_FAILURE: 'DELETE_ANSWER_CHOISE_FAILURE',

  GET_ALL_ADMINS_REQUEST: 'GET_ALL_ADMINS_REQUEST',
  GET_ALL_ADMINS_SUCCESS: 'GET_ALL_ADMINS_SUCCESS',
  GET_ALL_ADMINS_FAILURE: 'GET_ALL_ADMINS_FAILURE',

  INVITE_ADMIN_REQUEST: 'INVITE_ADMIN_REQUEST',
  INVITE_ADMIN_SUCCESS: 'INVITE_ADMIN_SUCCESS',
  INVITE_ADMIN_FAILURE: 'INVITE_ADMIN_FAILURE',

  GET_ADMIN_REQUEST: 'GET_ADMIN_REQUEST',
  GET_ADMIN_SUCCESS: 'GET_ADMIN_SUCCESS',
  GET_ADMIN_FAILURE: 'GET_ADMIN_FAILURE',

  EDIT_ADMIN_REQUEST: 'EDIT_ADMIN_REQUEST',
  EDIT_ADMIN_SUCCESS: 'EDIT_ADMIN_SUCCESS',
  EDIT_ADMIN_FAILURE: 'EDIT_ADMIN_FAILURE',

  GET_ALL_OCCUPATIONS_REQUEST: 'GET_ALL_OCCUPATIONS_REQUEST',
  GET_ALL_OCCUPATIONS_SUCCESS: 'GET_ALL_OCCUPATIONS_SUCCESS',
  GET_ALL_OCCUPATIONS_FAILURE: 'GET_ALL_OCCUPATIONS_FAILURE',

  GET_SINGLE_OCCUPATION_REQUEST: 'GET_SINGLE_OCCUPATION_REQUEST',
  GET_SINGLE_OCCUPATION_SUCCESS: 'GET_SINGLE_OCCUPATION_SUCCESS',
  GET_SINGLE_OCCUPATION_FAILURE: 'GET_SINGLE_OCCUPATION_FAILURE',

  GET_ALL_OCCUPATIONS_RESOURCES_REQUEST: 'GET_ALL_OCCUPATIONS_RESOURCES_REQUEST',
  GET_ALL_OCCUPATIONS_RESOURCES_SUCCESS: 'GET_ALL_OCCUPATIONS_RESOURCES_SUCCESS',
  GET_ALL_OCCUPATIONS_RESOURCES_FAILURE: 'GET_ALL_OCCUPATIONS_RESOURCES_FAILURE',

  GET_SINGLE_OCCUPATION_RESOURCE_REQUEST: 'GET_SINGLE_OCCUPATION_RESOURCE_REQUEST',
  GET_SINGLE_OCCUPATION_RESOURCE_SUCCESS: 'GET_SINGLE_OCCUPATION_RESOURCE_SUCCESS',
  GET_SINGLE_OCCUPATION_RESOURCE_FAILURE: 'GET_SINGLE_OCCUPATION_RESOURCE_FAILURE',

  RESOURCE_MAP_TEST_REQUEST: 'RESOURCE_MAP_TEST_REQUEST',
  RESOURCE_MAP_TEST_SUCCESS: 'RESOURCE_MAP_TEST_SUCCESS',
  RESOURCE_MAP_TEST_FAILURE: 'RESOURCE_MAP_TEST_FAILURE',

  RESOURCE_UNMAP_TEST_REQUEST: 'RESOURCE_UNMAP_TEST_REQUEST',
  RESOURCE_UNMAP_TEST_SUCCESS: 'RESOURCE_UNMAP_TEST_SUCCESS',
  RESOURCE_UNMAP_TEST_FAILURE: 'RESOURCE_UNMAP_TEST_FAILURE',

  GET_IMPORT_STATUS_REQUEST: 'GET_IMPORT_STATUS_REQUEST',
  GET_IMPORT_STATUS_SUCCESS: 'GET_IMPORT_STATUS_SUCCESS',
  GET_IMPORT_STATUS_FAILURE: 'GET_IMPORT_STATUS_FAILURE',

  GET_LAST_IMPORT_STATUS_REQUEST: 'GET_LAST_IMPORT_STATUS_REQUEST',
  GET_LAST_IMPORT_STATUS_SUCCESS: 'GET_LAST_IMPORT_STATUS_SUCCESS',
  GET_LAST_IMPORT_STATUS_FAILURE: 'GET_LAST_IMPORT_STATUS_FAILURE',

  START_IMPORT_REQUEST: 'START_IMPORT_REQUEST',
  START_IMPORT_SUCCESS: 'START_IMPORT_SUCCESS',
  START_IMPORT_FAILURE: 'START_IMPORT_FAILURE',

  ADD_OPEN_ID: 'ADD_OPEN_ID',
  CLEAR_SECRET: 'CLEAR_SECRET',
  CLEAR_OCCUPATION: 'CLEAR_OCCUPATION'
};

export const subscriptionsResetErrors = createAction(configurationsConstants.RESET_ERRORS);

export const addOpenId = createAction(configurationsConstants.ADD_OPEN_ID, action => action);
export const clearSecret = createAction(configurationsConstants.CLEAR_SECRET, action => action);
export const clearOccupation = createAction(
  configurationsConstants.CLEAR_OCCUPATION,
  action => action
);

export const getSubscriptionsRequest = createAction(
  configurationsConstants.GET_SUBSCRIPTIONS_REQUEST,
  action => action
);
export const getSubscriptionsSuccess = createAction(
  configurationsConstants.GET_SUBSCRIPTIONS_SUCCESS,
  data => data
);
export const getSubscriptionsFailure = createAction(
  configurationsConstants.GET_SUBSCRIPTIONS_FAILURE,
  errors => errors
);

export const getCurrentSubscriptionsRequest = createAction(
  configurationsConstants.GET_CURRENT_SUBSCRIPTION_REQUEST,
  action => action
);
export const getCurrentSubscriptionsSuccess = createAction(
  configurationsConstants.GET_CURRENT_SUBSCRIPTION_SUCCESS,
  data => data
);
export const getCurrentSubscriptionsFailure = createAction(
  configurationsConstants.GET_CURRENT_SUBSCRIPTION_FAILURE,
  errors => errors
);

export const createSubscriptionRequest = createAction(
  configurationsConstants.CREATE_SUBSCRIPTION_REQUEST,
  action => action
);
export const createSubscriptionSuccess = createAction(
  configurationsConstants.CREATE_SUBSCRIPTION_SUCCESS,
  data => data
);
export const createSubscriptionFailure = createAction(
  configurationsConstants.CREATE_SUBSCRIPTION_FAILURE,
  errors => errors
);

export const downgradeFreeRequest = createAction(
  configurationsConstants.DOWNGRADE_FREE_REQUEST,
  action => action
);
export const downgradeFreeSuccess = createAction(
  configurationsConstants.DOWNGRADE_FREE_SUCCESS,
  data => data
);
export const downgradeFreeFailure = createAction(
  configurationsConstants.DOWNGRADE_FREE_FAILURE,
  errors => errors
);

export const editSubscriptionRequest = createAction(
  configurationsConstants.EDIT_SUBSCRIPTION_REQUEST,
  action => action
);
export const editSubscriptionSuccess = createAction(
  configurationsConstants.EDIT_SUBSCRIPTION_SUCCESS,
  data => data
);
export const editSubscriptionFailure = createAction(
  configurationsConstants.EDIT_SUBSCRIPTION_FAILURE,
  errors => errors
);

export const publishSubscriptionRequest = createAction(
  configurationsConstants.PUBLISH_SUBSCRIPTION_REQUEST,
  action => action
);
export const publishSubscriptionSuccess = createAction(
  configurationsConstants.PUBLISH_SUBSCRIPTION_SUCCESS,
  data => data
);
export const publishSubscriptionFailure = createAction(
  configurationsConstants.PUBLISH_SUBSCRIPTION_FAILURE,
  errors => errors
);

export const archiveSubscriptionRequest = createAction(
  configurationsConstants.ARCHIVE_SUBSCRIPTION_REQUEST,
  action => action
);
export const archiveSubscriptionSuccess = createAction(
  configurationsConstants.ARCHIVE_SUBSCRIPTION_SUCCESS,
  data => data
);
export const archiveSubscriptionFailure = createAction(
  configurationsConstants.ARCHIVE_SUBSCRIPTION_FAILURE,
  errors => errors
);

export const stripeConnectRequest = createAction(
  configurationsConstants.STRIPE_CONNECT_REQUEST,
  action => action
);
export const stripeConnectSuccess = createAction(
  configurationsConstants.STRIPE_CONNECT_SUCCESS,
  data => data
);
export const stripeConnectFailure = createAction(
  configurationsConstants.STRIPE_CONNECT_FAILURE,
  errors => errors
);

export const getAnswerChoisesRequest = createAction(
  configurationsConstants.GET_ANSWER_CHOISES_REQUEST,
  action => action
);
export const getAnswerChoisesSuccess = createAction(
  configurationsConstants.GET_ANSWER_CHOISES_SUCCESS,
  data => data
);
export const getAnswerChoisesFailure = createAction(
  configurationsConstants.GET_ANSWER_CHOISES_FAILURE,
  errors => errors
);

export const createAnswerChoisesRequest = createAction(
  configurationsConstants.CREATE_ANSWER_CHOISE_REQUEST,
  action => action
);
export const createAnswerChoisesSuccess = createAction(
  configurationsConstants.CREATE_ANSWER_CHOISE_SUCCESS,
  data => data
);
export const createAnswerChoisesFailure = createAction(
  configurationsConstants.CREATE_ANSWER_CHOISE_FAILURE,
  errors => errors
);

export const editAnswerChoisesRequest = createAction(
  configurationsConstants.EDIT_ANSWER_CHOISE_REQUEST,
  action => action
);
export const editAnswerChoisesSuccess = createAction(
  configurationsConstants.EDIT_ANSWER_CHOISE_SUCCESS,
  data => data
);
export const editAnswerChoisesFailure = createAction(
  configurationsConstants.EDIT_ANSWER_CHOISE_FAILURE,
  errors => errors
);

export const deleteAnswerChoisesRequest = createAction(
  configurationsConstants.DELETE_ANSWER_CHOISE_REQUEST,
  action => action
);
export const deleteAnswerChoisesSuccess = createAction(
  configurationsConstants.DELETE_ANSWER_CHOISE_SUCCESS,
  data => data
);
export const deleteAnswerChoisesFailure = createAction(
  configurationsConstants.DELETE_ANSWER_CHOISE_FAILURE,
  errors => errors
);

export const getTestTypesWithTestsRequest = createAction(
  configurationsConstants.GET_TEST_TYPES_WITH_TESTS_REQUEST,
  action => action
);
export const getTestTypesWithTestsSuccess = createAction(
  configurationsConstants.GET_TEST_TYPES_WITH_TESTS_SUCCESS,
  data => data
);
export const getTestTypesWithTestsFailure = createAction(
  configurationsConstants.GET_TEST_TYPES_WITH_TESTS_FAILURE,
  errors => errors
);

export const getTestCategoriesRequest = createAction(
  configurationsConstants.GET_TEST_CATEGORIES_REQUEST,
  action => action
);
export const getTestCategoriesSuccess = createAction(
  configurationsConstants.GET_TEST_CATEGORIES_SUCCESS,
  data => data
);
export const getTestCategoriesFailure = createAction(
  configurationsConstants.GET_TEST_CATEGORIES_FAILURE,
  errors => errors
);
export const showHideCategory = createAction(
  configurationsConstants.SHOW_HIDE_CATEGORY,
  action => action
);

export const createTestCategoryRequest = createAction(
  configurationsConstants.CREATE_TEST_CATEGORY_REQUEST,
  action => action
);
export const createTestCategorySuccess = createAction(
  configurationsConstants.CREATE_TEST_CATEGORY_SUCCESS,
  data => data
);
export const createTestCategoryFailure = createAction(
  configurationsConstants.CREATE_TEST_CATEGORY_FAILURE,
  errors => errors
);

export const editTestCategoryRequest = createAction(
  configurationsConstants.EDIT_TEST_CATEGORY_REQUEST,
  action => action
);
export const editTestCategorySuccess = createAction(
  configurationsConstants.EDIT_TEST_CATEGORY_SUCCESS,
  data => data
);
export const editTestCategoryFailure = createAction(
  configurationsConstants.EDIT_TEST_CATEGORY_FAILURE,
  errors => errors
);

export const deleteTestCategoryRequest = createAction(
  configurationsConstants.DELETE_TEST_CATEGORY_REQUEST,
  action => action
);
export const deleteTestCategorySuccess = createAction(
  configurationsConstants.DELETE_TEST_CATEGORY_SUCCESS,
  data => data
);
export const deleteTestCategoryFailure = createAction(
  configurationsConstants.DELETE_TEST_CATEGORY_FAILURE,
  errors => errors
);

export const getAllAdminsRequest = createAction(
  configurationsConstants.GET_ALL_ADMINS_REQUEST,
  action => action
);
export const getAllAdminsSuccess = createAction(
  configurationsConstants.GET_ALL_ADMINS_SUCCESS,
  data => data
);
export const getAllAdminsFailure = createAction(
  configurationsConstants.GET_ALL_ADMINS_FAILURE,
  errors => errors
);

export const inviteAdminRequest = createAction(
  configurationsConstants.INVITE_ADMIN_REQUEST,
  action => action
);
export const inviteAdminSuccess = createAction(
  configurationsConstants.INVITE_ADMIN_SUCCESS,
  data => data
);
export const inviteAdminFailure = createAction(
  configurationsConstants.INVITE_ADMIN_FAILURE,
  errors => errors
);

export const getAdminRequest = createAction(
  configurationsConstants.GET_ADMIN_REQUEST,
  action => action
);
export const getAdminSuccess = createAction(
  configurationsConstants.GET_ADMIN_SUCCESS,
  data => data
);
export const getAdminFailure = createAction(
  configurationsConstants.GET_ADMIN_FAILURE,
  errors => errors
);

export const editAdminRequest = createAction(
  configurationsConstants.EDIT_ADMIN_REQUEST,
  action => action
);
export const editAdminSuccess = createAction(
  configurationsConstants.EDIT_ADMIN_SUCCESS,
  data => data
);
export const editAdminFailure = createAction(
  configurationsConstants.EDIT_ADMIN_FAILURE,
  errors => errors
);

export const getAllOccupationsRequest = createAction(
  configurationsConstants.GET_ALL_OCCUPATIONS_REQUEST,
  action => action
);
export const getAllOccupationsSuccess = createAction(
  configurationsConstants.GET_ALL_OCCUPATIONS_SUCCESS,
  data => data
);
export const getAllOccupationsFailure = createAction(
  configurationsConstants.GET_ALL_OCCUPATIONS_FAILURE,
  errors => errors
);

export const getSingleOccupationRequest = createAction(
  configurationsConstants.GET_SINGLE_OCCUPATION_REQUEST,
  action => action
);
export const getSingleOccupationSuccess = createAction(
  configurationsConstants.GET_SINGLE_OCCUPATION_SUCCESS,
  data => data
);
export const getSingleOccupationFailure = createAction(
  configurationsConstants.GET_SINGLE_OCCUPATION_FAILURE,
  errors => errors
);

export const getAllOccupationsResourcesRequest = createAction(
  configurationsConstants.GET_ALL_OCCUPATIONS_RESOURCES_REQUEST,
  action => action
);
export const getAllOccupationsResourcesSuccess = createAction(
  configurationsConstants.GET_ALL_OCCUPATIONS_RESOURCES_SUCCESS,
  data => data
);
export const getAllOccupationsResourcesFailure = createAction(
  configurationsConstants.GET_ALL_OCCUPATIONS_RESOURCES_FAILURE,
  errors => errors
);

export const getSingleOccupationResourseRequest = createAction(
  configurationsConstants.GET_SINGLE_OCCUPATION_RESOURCE_REQUEST,
  action => action
);
export const getSingleOccupationResourseSuccess = createAction(
  configurationsConstants.GET_SINGLE_OCCUPATION_RESOURCE_SUCCESS,
  data => data
);
export const getSingleOccupationResourseFailure = createAction(
  configurationsConstants.GET_SINGLE_OCCUPATION_RESOURCE_FAILURE,
  errors => errors
);

export const resourceMapTestRequest = createAction(
  configurationsConstants.RESOURCE_MAP_TEST_REQUEST,
  action => action
);
export const resourceMapTestSuccess = createAction(
  configurationsConstants.RESOURCE_MAP_TEST_SUCCESS,
  data => data
);
export const resourceMapTestFailure = createAction(
  configurationsConstants.RESOURCE_MAP_TEST_FAILURE,
  errors => errors
);

export const resourceUnMapTestRequest = createAction(
  configurationsConstants.RESOURCE_UNMAP_TEST_REQUEST,
  action => action
);
export const resourceUnMapTestSuccess = createAction(
  configurationsConstants.RESOURCE_UNMAP_TEST_SUCCESS,
  data => data
);
export const resourceUnMapTestFailure = createAction(
  configurationsConstants.RESOURCE_UNMAP_TEST_FAILURE,
  errors => errors
);

export const getImportStatusRequest = createAction(
  configurationsConstants.GET_IMPORT_STATUS_REQUEST,
  action => action
);
export const getImportStatusSuccess = createAction(
  configurationsConstants.GET_IMPORT_STATUS_SUCCESS,
  data => data
);
export const getImportStatusFailure = createAction(
  configurationsConstants.GET_IMPORT_STATUS_FAILURE,
  errors => errors
);

export const getLastImportStatusRequest = createAction(
  configurationsConstants.GET_LAST_IMPORT_STATUS_REQUEST,
  action => action
);
export const getLastImportStatusSuccess = createAction(
  configurationsConstants.GET_LAST_IMPORT_STATUS_SUCCESS,
  data => data
);
export const getLastImportStatusFailure = createAction(
  configurationsConstants.GET_LAST_IMPORT_STATUS_FAILURE,
  errors => errors
);

export const startImportRequest = createAction(
  configurationsConstants.START_IMPORT_REQUEST,
  action => action
);
export const startImportSuccess = createAction(
  configurationsConstants.START_IMPORT_SUCCESS,
  data => data
);
export const startImportFailure = createAction(
  configurationsConstants.START_IMPORT_FAILURE,
  errors => errors
);
