import { IconTest } from 'components/IconTest/IconTest';
import TimeBadget from 'components/TimeBadget';
import { Typography, Icon } from 'ui-kit';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';

export const TestCardNew = ({
  name,
  _id,
  icon,
  tagline,
  timeLimit: time,
  estimatedDuration,
  category,
  parentCategory,
  previewTestHandler,
  actionsComponent,
  className = '',
  containerClassName = ''
}) => (
  <div
    className={`hover:shadow-medium flex h-full rounded border-[0.5px] border-[#C9CCCF] shadow-small hover:bg-[#FCFCFC] ${className}`}>
    <div
      onClick={() => previewTestHandler(_id)}
      className={`flex w-full cursor-pointer flex-col gap-[4px] p-3 ${containerClassName}`}
      key={_id}>
      <div className="flex items-center gap-3">
        <IconTest url={icon?.url} size={72} />
        <div className="flex flex-col">
          <div className="flex gap-1">
            {parentCategory?.name && (
              <p className="text-base text-[#6D7175]">{parentCategory?.name}</p>
            )}
            {parentCategory?.name && <p className="mt-[-0.5px] text-base text-[#6D7175]">›</p>}
            <p className="text-base text-[#6D7175]">{category?.name || 'Not Selected'}</p>
          </div>
          <div className="flex items-center gap-3">
            <Typography variant="heading-h5" title={name} />
            <TimeBadget
              className="inline-flex w-auto font-semibold"
              time={(estimatedDuration ?? time) || '-'}
              icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
            />
          </div>
        </div>
      </div>
      <p dangerouslySetInnerHTML={{ __html: tagline }} />
    </div>
    <div>{actionsComponent && actionsComponent}</div>
  </div>
);
