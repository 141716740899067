import React from 'react';
import CheckBox from 'ui-kit/CheckBox';

import cn from 'utils/classNames';

interface ToggleProps {
  shouldUnregister?: boolean;
  name: string;
  onChangeValue?: () => void;
  placeholder?: any;
  label?: any;
  disabled?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  name,
  onChangeValue,
  placeholder,
  label,
  disabled,
  shouldUnregister
}) => {
  return (
    <CheckBox
      shouldUnregister={shouldUnregister}
      placeholder={placeholder}
      label={label}
      name={name}
      onChangeValue={onChangeValue}
      disabled={disabled}
      component={isActive => (
        <div
          className={cn(
            isActive ? 'bg-gray-300' : `${!disabled ? 'bg-green-400' : 'bg-[#A2BCB0]'}`,
            'flex items-center rounded-full h-[20px] w-[36px] transition-all relative'
          )}>
          <div
            className={cn(
              isActive ? 'left-[2px]' : 'left-[18px]',
              'bg-white w-[16px] h-[16px] rounded-full absolute transition-all'
            )}
          />
        </div>
      )}
    />
  );
};
