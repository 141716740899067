import { secondsToHms } from 'utils/helpers';

const TimeBadget = ({ time, className, icon }) => {
  return (
    <span
      className={`flex h-[22px] items-center gap-[4.5px] rounded border-[0.5px] border-[#CBD6D6] bg-[#EEF2F2] py-[1px] px-[6px] text-sm font-semibold text-[#05443D] shadow-small ${
        className || ''
      }`}>
      {/* set default icon if needed instead of Fragment */}
      {icon ? icon : <></>}
      {secondsToHms(time) || '0 min'}
    </span>
  );
};

export default TimeBadget;
