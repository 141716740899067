import { twMerge } from 'tailwind-merge';
import Icon from 'ui-kit/Icon';

export const NavbarItem = ({
  isActive,
  onClick,
  className,
  children,
  title,
  icon,
  as: Wrap = 'div'
}) => {
  return (
    <Wrap
      onClick={onClick}
      className={twMerge(
        className,
        isActive ? 'bg-green-100 border-green-400' : 'border-transparent',
        'flex gap-2.5 items-center text-base text-[#6D7175] py-6 px-5 h-[75px] cursor-pointer box-border hover:bg-green-100'
      )}>
      <Icon size={20} className="min-w-[20px]" color="#5C5F62" icon={icon} />
      {title ?? children}
    </Wrap>
  );
};
