import React from 'react';
import { InputFile, Button, Input, Radio, Typography, CheckBox, List } from 'ui-kit';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

interface answer {
  type: 'single' | 'multiple';
}

export const Answer: React.FC<answer> = ({ type }) => {
  const form = useFormContext();
  const control = useFieldArray({ name: 'answers', control: form.control });
  const onDragEnd = (result: any, control: any) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Move the item in the array
    control.move(sourceIndex, destinationIndex);
  };

  return (
    <DragDropContext onDragEnd={result => onDragEnd(result, control)}>
      <Droppable droppableId="answers">
        {provided => (
          <div
            className="mt-[36px] flex flex-col"
            {...provided.droppableProps}
            ref={provided.innerRef}>
            <List
              data={control.fields}
              wrap="div"
              className="flex flex-col"
              keyExtractor={({ id }: any) => id}
              element={(props, idx) => {
                return (
                  <Draggable draggableId={`answer-${idx}`} index={idx} key={`draggable-${idx}`}>
                    {(provided: any) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="flex items-start gap-[17px] rounded border border-transparent p-[10px] pl-[14px] hover:border-[#D2D5D8] hover:bg-[#FFFFFF]">
                        <div
                          {...provided.dragHandleProps}
                          className={`mt-[8px] flex h-full items-center gap-3`}>
                          <div>
                            <i className="fa-regular fa-bars text-base"></i>
                          </div>
                        </div>
                        {form.watch('distributePoints') && (
                          <div className="flex h-[40px] rounded border border-[#C9CCCF]">
                            <div
                              onClick={() => {
                                if (form.getValues(`answers[${idx}].score`) === 0) {
                                  return;
                                }
                                form.setValue(
                                  `answers[${idx}].score`,
                                  +form.getValues(`answers[${idx}].score`) - 1
                                );
                              }}
                              className="flex cursor-pointer items-center justify-center rounded rounded-r-none border-r border-[#C9CCCF] bg-[#F6F6F7] px-[13.5px] py-[12.5px]">
                              <i className="fa-regular fa-minus h-[16px] w-[16px] text-[#5C5F62]"></i>
                            </div>
                            <div className="flex w-[50px] select-none items-center justify-center bg-[#FFFFFF] px-[18px] py-[8px] text-[#6D7175]">
                              {form.watch(`answers[${idx}].score`)}
                            </div>
                            <div
                              onClick={() =>
                                form.setValue(
                                  `answers[${idx}].score`,
                                  +form.getValues(`answers[${idx}].score`) + 1
                                )
                              }
                              className="flex cursor-pointer items-center justify-center rounded rounded-l-none border-l border-[#C9CCCF] bg-[#F6F6F7] px-[13.5px] py-[12.5px]">
                              <i className="fa-regular fa-plus h-[16px] w-[16px] text-[#5C5F62]"></i>
                            </div>
                          </div>
                        )}
                        <div className="flex w-full flex-col gap-2">
                          <div className="flex items-center gap-2">
                            <div
                              className={`flex h-[40px] flex-1 ${
                                form.watch(`answers[${idx}].score`) > 0
                                  ? 'border-[#00A47C] bg-[#F3F8F6]'
                                  : 'rounded hover:border-[#999EA4] hover:bg-[#FCFCFC]'
                              } group gap-[8px] rounded border border-[#C9CCCF] bg-white px-[12px] py-[8px]`}>
                              {!form.watch('distributePoints') &&
                                (type === 'single' ? (
                                  <Radio
                                    onChangeValue={() => {
                                      control.fields.forEach((_, idx) => {
                                        form.setValue(`answers[${idx}].score`, 0);
                                      });
                                      form.setValue(`answers[${idx}].score`, 1);
                                    }}
                                    value={1}
                                    classNameInput={`w-[16px] cursor-pointer h-[16px] ${
                                      form.watch(`answers[${idx}].score`) === 0 &&
                                      'group-hover:bg-[#FCFCFC]'
                                    }`}
                                    name={`answers[${idx}].score`}
                                  />
                                ) : (
                                  <CheckBox
                                    className={`h-[16px] w-[16px] cursor-pointer ${
                                      form.watch(`answers[${idx}].score`) === 0 &&
                                      'group-hover:bg-[#FCFCFC]'
                                    } border border-[#8C9196]`}
                                    onChangeValue={v => {
                                      form.setValue(`answers[${idx}].score`, v ? 1 : 0);
                                    }}
                                    name={`answers[${idx}].score`}
                                  />
                                ))}
                              <Input
                                name={`answers[${idx}].text`}
                                placeholder={`Choice ${idx + 1}`}
                                autoComplete="off"
                                inputClassName="h-[24px]"
                                className={`flex-1 border-none ${
                                  form.watch(`answers[${idx}].score`) > 0 && 'bg-[#F3F8F6]'
                                }`}
                                maxLength={1000}
                                autoFocus
                              />
                            </div>
                            <InputFile
                              onChangeValue={file =>
                                form.setValue(`answers[${idx}].attachment`, file)
                              }
                              className="flex"
                              component={() => (
                                <div className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded border border-[#8C9196] bg-white p-3 hover:bg-[#F6F6F7]">
                                  <i className="fa-light fa-paperclip-vertical text-base"></i>
                                </div>
                              )}
                            />
                            <div
                              key={`trash-can-${idx}`}
                              onClick={() => {
                                if (control.fields.length < 3) {
                                  return;
                                }

                                control.remove(idx);
                              }}
                              data-tooltip-id="delete-question"
                              data-tooltip-content="Provide at least two choices."
                              data-tooltip-place="top"
                              className={`flex h-[40px] w-[40px] ${
                                control.fields.length < 3
                                  ? 'border-[#D2D5D8] text-[#BABEC3]'
                                  : 'text-black'
                              } cursor-pointer items-center justify-center rounded border border-[#8C9196] bg-white p-3 hover:border-red-300 hover:bg-red-50`}>
                              <i className={`fa-light fa-trash-can text-base`}></i>
                              {control.fields.length < 3 && (
                                <Tooltip
                                  className="max-w-[270px] bg-black text-sm"
                                  id="delete-question"
                                />
                              )}
                            </div>
                          </div>
                          {form.watch(`answers[${idx}].attachment`) && (
                            <div className="group relative max-h-[140px] max-w-[140px] cursor-pointer rounded border border-[#C9CCCF]">
                              <Button
                                className="absolute left-[50%] top-[50%] z-10 hidden translate-y-[-50%] translate-x-[-50%] group-hover:block"
                                iconClassName="fill-white w-[22px] h-[22px]"
                                icon={faTrash}
                                onClick={() => form.setValue(`answers[${idx}].attachment`, '')}
                              />
                              <div
                                onClick={() => form.setValue(`answers[${idx}].attachment`, '')}
                                className="absolute inset-0 h-full w-full rounded group-hover:bg-black/70"
                              />
                              <img
                                className="h-[138px] w-[138px] rounded-lg object-cover"
                                src={form.watch(`answers[${idx}].attachment.url`)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              }}
            />
            <Button
              className="group mb-6 flex flex-1 items-center gap-2 rounded border border-transparent p-4 pl-8 text-left text-gray-400 hover:border-[#999EA4] hover:bg-[#FCFCFC]"
              onClick={() => control.append({ text: '', score: 0 })}>
              <div className="flex items-center justify-center" key="plus">
                <i className="fa-light fa-plus h-[20px] w-[20px] text-[#8C9196] group-hover:text-[#5C5F62]"></i>
              </div>
              <Typography
                className="text-[#6D7175] group-hover:text-black"
                title="Add Choice..."
                variant="regular-default"
              />
            </Button>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
