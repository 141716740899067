import { variants, TypographyVariant } from './variants';

export interface TypographyProps<T extends keyof typeof variants> {
  variant?: T;
  className?: string;
  title?: string | number;
  style?: any;
  children?: any;
  onClick?: any;
}

const Typography = <T extends keyof typeof variants>({
  variant,
  className = '',
  title,
  style,
  onClick,
  children
}: TypographyProps<T>) => {
  const variantConfig = (variants as Record<string, TypographyVariant>)[variant || 'regular-default'];

  const { tag, className: typographyClassName } = variantConfig || {};

  const Tag = tag ?? 'p';

  if (title === undefined && children === undefined) {
    return null;
  }

  return (
    <Tag onClick={onClick} style={style} className={`${variant && typographyClassName} ${className}`}>
      {title !== undefined ? title : children}
    </Tag>
  );
};


export default Typography;
