import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation, unstable_HistoryRouter as Router } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { Provider, useDispatch } from 'react-redux';
import { SmartToaster, toast } from 'react-smart-toaster';
import { QueryParamProvider } from 'use-query-params';
import smartlookClient from 'smartlook-client';
import { ToastContainer, Flip } from 'react-toastify';
import { Toaster } from 'react-hot-toast';
import { AppRoute } from './routes';

import { configureStore } from './store/configureStore';

import 'react-toastify/dist/ReactToastify.css';
import history from 'helpers/useHistory/useHistory';
import { SmallScreenSize } from 'pages/SmallScreenSize/SmallScreenSize';
import * as routes from 'routes/routes';
import { testsResetErrors } from 'store/tests/actions';
import { adminResetErrors } from './store/admin/actions';
import { assessmentResetErrors } from './store/assessment/actions';
import { candidatesResetErrors } from './store/candidates/actions';
import { subscriptionsResetErrors } from './store/configuration/actions/subscriptions';
import { localStorageService } from 'helpers/localStorageService';
import { hotjar } from 'react-hotjar';

const options = {
  autoConfig: true,
  debug: false
};

const RouteAdapter = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const adaptedHistory = React.useMemo(
    () => ({
      replace(location) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location) {
        navigate(location, { replace: false, state: location.state });
      }
    }),
    [navigate]
  );

  useEffect(() => {
    dispatch(adminResetErrors());
    dispatch(testsResetErrors());
    dispatch(assessmentResetErrors());
    dispatch(candidatesResetErrors());
    dispatch(subscriptionsResetErrors());
  }, [location]);

  return children({ history: adaptedHistory, location });
};

function App() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const currentUrl = window.location.pathname;
  const currentApp = window.location.origin;
  const role = localStorageService.getRole();

  useEffect(() => {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, options);
    ReactPixel.pageView();
    if (currentApp === 'https://app.hirenest.com') {
      smartlookClient.init('dc42f619c74367699de95b31987f75dc2d3a48ac', { region: 'eu' });
      hotjar.initialize(3627856, 6);
      smartlookClient.record({ forms: true, ips: true, emails: true, numbers: true });
    }
  }, [currentApp, role]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://kit.fontawesome.com/d97d3a9354.js';
    script.async = true;

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
  }, []);

  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return screenSize < 900 && currentUrl !== routes.CANDIDATE_CERTIFICATE ? (
    <SmallScreenSize />
  ) : (
    <Provider store={configureStore}>
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={RouteAdapter}>
          <AppRoute />
        </QueryParamProvider>
        <SmartToaster store={toast} lightBackground={true} position={'top-right'} />
        <ToastContainer
          bodyClassName={() => 'items-center flex font-sans text-base w-full p-2.5'}
          position="top-right"
          autoClose={2000}
          hideProgressBar
          transition={Flip}
          style={{ width: 'auto' }}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Toaster />
      </Router>
    </Provider>
  );
}

export default App;
