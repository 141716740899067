import { yupResolver } from '@hookform/resolvers/yup';
import { InviteCandidateSchema } from 'components/Forms/validation';
import { StarsRating } from 'components/StarsRating/StarsRating';
import moment from 'moment';
import { calculateColorHirenestScore } from 'pages/Employer/CreateAssessment_old/components/HiringStatuses/HiringStatuses';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { inviteCandidateRequest } from 'store/assessment/actions';
import { Link } from 'react-router-dom';

import { Button, ErrorMessage, Form, Icon, Input, Section, Typography } from 'ui-kit';
import { Tooltip } from 'react-tooltip';
import * as routes from 'routes/routes';
import { ButtonSelect } from 'ui-kit/ButtonSelect/ButtonSelect';
import { useEffect, useMemo, useState } from 'react';
import { useUserRole } from 'hooks';

export const statusColor = {
  Invited: { bg: 'bg-[#EDEEEF]', text: 'text-[#082623]', title: 'Invited' },
  Opened: { bg: 'bg-[#EDEEEF]', text: 'text-[#082623]', title: 'Opened' },
  Started: { bg: 'bg-[#EDEEEF]', text: 'text-[#082623]]', title: 'In Progress' },
  'Not Evaluated': { bg: 'bg-[#E7F1FF]', text: 'text-[#031633]', title: 'Completed' },
  'Invited for Interview': {
    bg: 'bg-[#FFF3CD]',
    text: 'text-[#332701]',
    title: 'Invited for Interview'
  },
  Interviewed: { bg: 'bg-[#FFF3CD]', text: 'text-[#332701]', title: 'Interviewed' },
  'Offer Sent': { bg: 'bg-[#E2D9F3]', text: 'text-[#160D27]', title: 'Offer Sent' },
  Hired: { bg: 'bg-[#D1E7DD]', text: 'text-[#051B11]', title: 'Hired' },
  Rejected: { bg: 'bg-[#F8D7DA]', text: 'text-[#051B11]', title: 'Application Rejected' },
  'Candidate Unresponsive': {
    bg: 'bg-[#F8D7DA]',
    text: 'text-[#051B11]',
    title: 'Candidate Unresponsive'
  },
  'Candidate Withdrew': {
    bg: 'bg-[#F8D7DA]',
    text: 'text-[#051B11]',
    title: 'Candidate Withdrew'
  },
  'Offer Accepted': {
    bg: 'bg-[#D1E7DD]',
    text: 'text-[#051B11]',
    title: 'Hired'
  },
  'Offer Declined': {
    bg: 'bg-[#F8D7DA]',
    text: 'text-[#051B11]',
    title: 'Offer Declined'
  }
};

const workFlowPlaces = {
  measure: ['Started', 'Opened', 'Invited'],
  evaluate: ['Not Evaluated', 'Rejected', 'Candidate Unresponsive', 'Candidate Withdrew'],
  interview: [
    'Invited for Interview',
    'Interviewed',
    'Rejected',
    'Candidate Unresponsive',
    'Candidate Withdrew'
  ],
  hire: [
    'Offer Sent',
    'Offer Accepted',
    'Offer Declined',
    'Rejected',
    'Candidate Unresponsive',
    'Candidate Withdrew'
  ]
};

const rejectedStatuses = [
  'Rejected',
  'Candidate Unresponsive',
  'Candidate Withdrew',
  'Offer Declined'
];

const SmallCard = ({ name, arr, assessmentId, showRating }) => {
  const [filterBy, setFilterBy] = useState('byScore');
  const [sortedArr, setSortedArr] = useState(arr);
  const { role } = useUserRole();
  const isAdmin = role === 'admin';

  useEffect(() => {
    setSortedArr(sortArray(arr, filterBy));
  }, [arr, filterBy]);

  function sortArray(array, option) {
    const sorted = array.slice();
    if (name === 'Not Evaluated') {
      sorted.sort((a, b) =>
        option === 'byDate'
          ? new Date(b.lastStatusChange) - new Date(a.lastStatusChange)
          : b.hirenestScore - a.hirenestScore
      );
    }
    return sorted;
  }

  return (
    <div className="my-[32px] flex flex-col gap-[32px]" key={name}>
      <Tooltip
        id="premium-tooltip"
        className="rounded-s relative z-50 ml-[-5px] mt-[20px] w-[300px] max-w-[300px]
        after:absolute after:left-[18.5px] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
        noArrow={true}
        style={{
          background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
          fontSize: '14px'
        }}>
        Unlock unlimited views of candidates' assessments by upgrading your account to the{' '}
        <b>Starter</b> or <b>Business subscription.</b>
      </Tooltip>
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-between border-b border-[#BABFC3] pb-[8px]">
          <div className="flex items-center gap-[8px]">
            <Typography
              variant="badge-default"
              className={`flex items-center justify-center rounded px-[6px] py-[3px] shadow-sm ${statusColor?.[name]?.bg} ${statusColor?.[name]?.text}`}>
              {statusColor?.[name]?.title}
            </Typography>
            {name === 'Not Evaluated' && (
              <ButtonSelect
                buttonTitle={filterBy === 'byDate' ? 'By Date' : 'By Score'}
                iconClassname="group-hover:text-green-400"
                iconColor="text-green-400"
                width="w-[110px]"
                className="m-0 items-center gap-[5px] border-none bg-transparent p-0 text-base text-[#6D7175] hover:bg-transparent hover:text-green-400">
                <button
                  onClick={() => setFilterBy('byDate')}
                  className="flex w-full px-[13px] py-[8px] hover:bg-gray-100"
                  role="menuitem">
                  <p className="flex w-max items-center justify-center text-[#2C0B0E]">By Date</p>
                </button>
                <button
                  onClick={() => setFilterBy('byScore')}
                  className="flex w-full px-[13px] py-[8px] hover:bg-gray-100"
                  role="menuitem">
                  <p className="flex w-max items-center justify-center text-[#2C0B0E]">By Score</p>
                </button>
              </ButtonSelect>
            )}
          </div>
          <p className="font-semibold text-[#6D7175]">{arr.length}</p>
        </div>
        {sortedArr?.map(
          ({
            _id,
            lastStatusChange,
            percentComplete,
            candidate,
            hirenestScore,
            scaledScore,
            rating,
            candidateEmail,
            visible
          }) => {
            const scores = [];
            if (!isAdmin) scores.push(calculateColorHirenestScore(scaledScore ?? hirenestScore));
            else {
              if (scaledScore) {
                scores.push(calculateColorHirenestScore(scaledScore));
                scores.push({
                  bg: 'bg-[#8C9196]',
                  title: 'text-[#8C9196] font-[500]',
                  border: 'border-[#8C9196]',
                  icon: 'hirenestScoreDefault',
                  score: hirenestScore
                });
              } else scores.push(calculateColorHirenestScore(hirenestScore));
            }

            return (
              <Link
                to={routes.ASSESSMENTS + '/' + assessmentId + routes.APPLICATIONS + '/' + _id}
                style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)' }}
                key={_id}
                id={_id}
                data-tooltip-content="Unlock unlimited views of candidates' assessments by upgrading your account to the business subscription."
                data-tooltip-id={`${!visible ? 'premium-tooltip' : null}`}
                className="group relative flex cursor-pointer flex-col gap-3 rounded-md bg-[#FFFFFF] p-4 transition-all hover:bg-[#FCFCFC] hover:shadow-xl">
                <Typography
                  className="font-medium transition-all group-hover:text-[#2F6E63]"
                  variant="heading-h6">
                  {!visible && <i className="fa-solid fa-gem mr-1 text-[#2C6ECB]"></i>}
                  {candidate || candidateEmail}
                </Typography>
                {percentComplete < 100 && percentComplete !== 0 ? (
                  <div className="h-[12px] w-full overflow-hidden rounded-sm border border-[#C9CCCF] bg-[#FFFFFF]">
                    <div
                      className="h-full bg-[#EDEEEF]"
                      style={{ width: `${percentComplete}%` }}></div>
                  </div>
                ) : (
                  (hirenestScore || hirenestScore === 0) && (
                    <div className="flex items-center gap-2">
                      {scores.map((score, idx) => (
                        <div className="flex items-center gap-1" key={idx}>
                          <Icon icon={score.icon} color={score.iconColor} size={20} />
                          <Typography
                            className={`${score.title} font-semibold`}
                            variant="regular-default"
                            title={hirenestScore === -1 ? 'N/A' : score.score.toFixed(0) + '%'}
                          />
                        </div>
                      ))}
                      {!showRating && <StarsRating rate={rating} />}
                    </div>
                  )
                )}
                <Typography
                  variant="small-default"
                  className="text-end text-[#8C9196]"
                  title={moment(lastStatusChange).fromNow()}
                />
              </Link>
            );
          }
        )}
      </div>
    </div>
  );
};

const Card = ({ title, description, children, list, assessmentId, showRating, type }) => (
  <div className="flex min-h-[250px] flex-1 flex-col rounded-md bg-[#F6F6F7] py-6 px-3">
    <div className="flex flex-col items-center gap-1">
      <Typography variant="heading-h6" title={title} />
      <Typography
        className="h-[50px] text-center text-[#6D7175]"
        variant="small-default"
        title={description}
      />
    </div>
    {workFlowPlaces[type].map(
      item =>
        list[item]?.length > 0 && (
          <SmallCard
            name={item}
            showRating={showRating}
            arr={list[item]}
            assessmentId={assessmentId}
          />
        )
    )}
    {children}
  </div>
);

export const ApplicationSection = ({
  applications,
  assessmentId,
  applicationsCount,
  assessmentPublished,
  verified
}) => {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const [filteredApplications, setFilteredApplications] = useState(applications);
  const { _id } = useParams();

  const isRejectedExist = useMemo(() => {
    if (!applications) {
      return;
    }
    return Object.values(applications).some(item =>
      Object.keys(item).some(key => rejectedStatuses.includes(key))
    );
  }, [applications]);

  useEffect(() => {
    if (showAll) {
      setFilteredApplications(applications);
    } else {
      const filteredData = {};

      for (const key in applications) {
        if (Object.prototype.hasOwnProperty.call(applications, key)) {
          filteredData[key] = {};

          for (const subKey in applications[key]) {
            if (Object.prototype.hasOwnProperty.call(applications[key], subKey)) {
              filteredData[key][subKey] = applications[key][subKey].filter(
                obj => !rejectedStatuses.includes(obj.status)
              );
            }
          }
        }
      }

      setFilteredApplications(filteredData);
    }
  }, [applications, showAll]);

  const onSubmit = (data, control) => {
    dispatch(
      inviteCandidateRequest({
        data: { ...data },
        id: _id
      })
    );
    control.reset();
  };

  return (
    <Section>
      <Section.Header className="flex items-center gap-4">
        <Typography title="Applications" variant="heading-h2" />
        <div className="rounded bg-[#E4E5E7] px-[8px] py-[4px] font-semibold">
          {applicationsCount}
        </div>
      </Section.Header>
      <Section.Body className="flex flex-col gap-[14px]">
        <div className="flex gap-[14px]">
          <Card
            title="1. Measure"
            description="Measure candidates' skills and qualifications."
            assessmentId={assessmentId}
            type="measure"
            list={filteredApplications?.measure}>
            {assessmentPublished && (
              <div className="mt-[24px] flex h-[95px] flex-col gap-[6px] rounded-md bg-[#FFFFFF] p-[16px]">
                <Typography variant="regular-default" title="Invite a candidate via email" />
                <Form
                  onSubmit={onSubmit}
                  resolver={yupResolver(InviteCandidateSchema)}
                  defaultValues={{ email: '' }}>
                  <div className="flex max-h-[33px]">
                    <Input
                      name="email"
                      placeholder="candidate@..."
                      inputClassName="max-h-[31px]"
                      className="flex-1 rounded rounded-r-none"
                    />
                    <Button
                      disabled={!verified}
                      className="flex min-w-[60px] items-center justify-center rounded-l-none border-l-0 bg-[#007D6F] font-medium text-white"
                      variant="primary-outline"
                      type="submit"
                      title="Invite"
                    />
                  </div>
                  <ErrorMessage name="email" />
                </Form>
              </div>
            )}
          </Card>
          <Card
            title="2. Evaluate"
            description="Evaluate results and consider other factors."
            showRating
            type="evaluate"
            assessmentId={assessmentId}
            list={filteredApplications?.evaluate}
          />
          <Card
            title="3. Interview"
            description="Interview candidates in-person, by phone, or via video."
            type="interview"
            assessmentId={assessmentId}
            list={filteredApplications?.interview}
          />
          <Card
            title="4. Hire"
            description="Hire the best fit after negotiation."
            type="hire"
            assessmentId={assessmentId}
            list={filteredApplications?.hire}
          />
        </div>
        {isRejectedExist && !showAll && (
          <Button
            onClick={() => setShowAll(true)}
            className="mt-2 w-max self-center"
            variant="primary-outline"
            title={`Show All ${applicationsCount} Applications`}
          />
        )}
      </Section.Body>
    </Section>
  );
};
