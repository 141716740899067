import { Link } from 'react-router-dom';

import * as routes from 'routes/routes';
import { getModifyData } from 'utils/helpers';
import { Typography } from 'ui-kit';
import { StarsRating } from 'components/StarsRating/StarsRating';
import { statusColor } from 'components/ApplicationSection';

const ApplicationCard = ({
  _id,
  rating,
  assessment,
  candidateName,
  startedAt,
  completedAt,
  invitedAt,
  hirenestScore,
  status,
  assessmentName,
  invitationEmail
}) => {
  return (
    status && (
      <Link
        to={routes.ASSESSMENTS + '/' + assessment + routes.APPLICATIONS + '/' + _id}
        className="list-none rounded-md border border-gray-400 p-4 transition-all hover:border-green-400">
        <div className="flex flex-col gap-[6px]">
          <Typography variant="heading-h5" title={candidateName || invitationEmail} />
          <div
            className={`flex items-center justify-center rounded px-[6px] py-[3px] shadow-sm ${statusColor?.[status]?.bg} ${statusColor?.[status]?.text}`}>
            {statusColor?.[status]?.title}
          </div>
        </div>
        <div className="flex gap-[40px]">
          <div className=" py-5">
            <Typography className="text-[#6D7175]" variant="small-default" title="Rating" />
            <StarsRating rate={rating} />
          </div>
          <div className=" py-5">
            <Typography className="text-[#6D7175]" variant="small-default" title="Hirenest Score" />
            <Typography variant="small-default" title={hirenestScore?.toFixed(0) || '-'} />
          </div>
        </div>
        <div>
          <Typography variant="regular-default" className="flex gap-1">
            on <Typography className="text-green-400" title={assessmentName} />
          </Typography>
          <Typography variant="small-default" className="text-gray-400">
            {status === 'completed' && `Completed on ${getModifyData(completedAt)}`}
            {status === 'started' && `Started on ${getModifyData(startedAt)}`}
            {status === 'invited' && `Invited on ${getModifyData(invitedAt)}`}
            {status !== 'started' &&
              status !== 'invited' &&
              `Completed on ${getModifyData(completedAt)}`}
          </Typography>
        </div>
      </Link>
    )
  );
};

export default ApplicationCard;
