export const variants = {
  primary:
    'bg-[#007D6F] rounded-md disabled:bg-[#F1F1F1] text-[#FFFFFF] disabled:text-[#8C9196] flex justify-center items-center hover:bg-[#12A182] active:bg-[#017063]',
  'primary-outline':
    'bg-[#FFFFFF] rounded-md flex justify-center items-center border border-[#007D6F] text-[#007D6F] hover:border-[#12A182] hover:text-[#12A182] hover:bg-[#F5FEFD] active:border-[#017063] active:text-[#016B69] active:bg-[#E2F1EA] disabled:border-[#D2D5D8] disabled:bg-[#FAFBFB] disabled:text-[#8C9196]',
  secondary:
    'bg-[#FFFFFF] border border-[#8C9196] text-[#082623] rounded-md flex justify-center items-center hover:bg-[#F6F6F7] border-[#999EA4] active:bg-[#F1F2F3] active:border-[#575959] disabled:bg-[#FFFFFF] disabled:border-[#E2E2E2] disabled:text-[#8C9196]',
  link: 'bg-transparent text-[#007D6F] rounded-md flex justify-center items-center hover:bg-[#F3F8F6] hover:text-[#12A182] active:bg-transparent active:text-[#016B69] disabled:bg-white disabled:border disabled:border-[#E2E2E2] disabled:text-[#8C9196]',
  danger:
    'bg-[#D1361A] text-white rounded-md flex justify-center items-center hover:bg-[#E25136] active:bg-[#B31F13] disabled:bg-[#F1F1F1] disabled:text-[#8C9196]',
  'danger-outline':
    'bg-[#FFFFFF] text-[#082623] border border-[#8C9196] rounded-md flex justify-center items-center hover:border-[#FED3D1] hover:bg-[#FFF0F0] hover:text-[#B31F13] active:border-[#B31F13] active:bg-[#FFE9E8] active:text-[#B31F13]'
};

export const sizes = {
  large:
    'text-center font-Figtree gap-[6px] text-18 font-normal font-weight-600 leading-150 tracking-0.5 px-[20px] py-[10px]',
  medium:
    'px-[16px] py-[8px] gap-[6px] text-center font-Figtree text-16 font-semibold leading-[24px]',
  small: 'px-[12px] py-[6px] gap-[4px] text-center font-Figtree text-14 font-medium leading-[21px]'
};
