import { put, takeLatest, call } from 'redux-saga/effects';
import {
  archiveSubscriptionFailure,
  archiveSubscriptionSuccess,
  createSubscriptionFailure,
  createSubscriptionSuccess,
  downgradeFreeFailure,
  downgradeFreeSuccess,
  editSubscriptionFailure,
  editSubscriptionSuccess,
  getCurrentSubscriptionsFailure,
  getCurrentSubscriptionsSuccess,
  getSubscriptionsFailure,
  getSubscriptionsSuccess,
  publishSubscriptionFailure,
  publishSubscriptionSuccess,
  stripeConnectFailure,
  stripeConnectSuccess,
  configurationsConstants,
  getAnswerChoisesSuccess,
  getAnswerChoisesFailure,
  createAnswerChoisesSuccess,
  createAnswerChoisesFailure,
  editAnswerChoisesSuccess,
  editAnswerChoisesFailure,
  deleteAnswerChoisesSuccess,
  deleteAnswerChoisesFailure,
  getAllAdminsSuccess,
  getAllAdminsFailure,
  inviteAdminSuccess,
  inviteAdminFailure,
  getAdminSuccess,
  getAdminFailure,
  editAdminSuccess,
  editAdminFailure,
  getAllOccupationsSuccess,
  getAllOccupationsFailure,
  getSingleOccupationResourseSuccess,
  getSingleOccupationResourseFailure,
  getAllOccupationsResourcesSuccess,
  getAllOccupationsResourcesFailure,
  resourceMapTestSuccess,
  resourceMapTestFailure,
  resourceUnMapTestSuccess,
  resourceUnMapTestFailure,
  getImportStatusSuccess,
  getImportStatusFailure,
  startImportSuccess,
  startImportFailure,
  getLastImportStatusSuccess,
  getLastImportStatusFailure,
  getSingleOccupationSuccess,
  getSingleOccupationFailure,
  getTestCategoriesSuccess,
  getTestCategoriesFailure,
  createTestCategorySuccess,
  createTestCategoryFailure,
  deleteTestCategorySuccess,
  deleteTestCategoryFailure,
  editTestCategorySuccess,
  editTestCategoryFailure
} from '../actions/subscriptions';

import * as routes from 'routes/routes';
import { callToast } from 'utils/helpers';
import { adminApi, commonApi } from 'api/appApi';

function* getSubscriptionsSaga() {
  try {
    const { data } = yield commonApi.getSubscriptions();
    yield put(getSubscriptionsSuccess(data));
  } catch (error) {
    yield put(getSubscriptionsFailure(error.response.status));
  }
}

function* createSubscriptionsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.createSubscription(payload);
    yield put(createSubscriptionSuccess(data));
    callToast('success', 'Subscription plan created');
  } catch (error) {
    callToast('error', 'Subscription plan was not created');
    yield put(createSubscriptionFailure(error.response.status));
  }
}

function* editSubscriptionsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.editSubscription(payload);
    yield put(editSubscriptionSuccess(data));
    callToast('success', 'Subscription plan updated');
  } catch (error) {
    callToast('error', 'Subscription plan was not updated');
    yield put(editSubscriptionFailure(error.response.status));
  }
}

function* publishSubscriptionsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.publishSubscription(payload);
    yield put(publishSubscriptionSuccess(data));
    callToast('success', 'Subscription plan published');
  } catch (error) {
    yield put(publishSubscriptionFailure(error.response.status));
    callToast('error', 'Subscription plan was not published');
  }
}

function* archiveSubscriptionsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.archiveSubscription(payload);
    yield put(archiveSubscriptionSuccess(data));
    callToast('success', 'Subscription plan archived');
  } catch (error) {
    yield put(archiveSubscriptionFailure(error.response.status));
    callToast('error', 'Subscription plan was not archived');
  }
}

function* downgradeFreeSaga() {
  try {
    const { data } = yield commonApi.downgradeFree();
    yield put(downgradeFreeSuccess(data));
    yield call(() => window.location.replace(routes.SUBSCRIPTIONS));
    callToast('success', 'Your plan successfully moved to Free');
  } catch (error) {
    callToast('error', 'Error - your plan was not moved to Free');
    yield put(downgradeFreeFailure(error.response.status));
  }
}

function* connectStripeSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.stripeConnect(payload.data);
    yield put(stripeConnectSuccess(data.client_secret));
  } catch (error) {
    yield put(stripeConnectFailure(error.response.status));
    yield call(() => payload.navigate(routes.SUBSCRIPTIONS));
    callToast('error', 'Failed connection with stripe');
  }
}

function* getCurrnetSubscriptionSaga() {
  try {
    const { data } = yield commonApi.getCurrentSubscription();
    yield put(getCurrentSubscriptionsSuccess(data));
  } catch (error) {
    yield put(getCurrentSubscriptionsFailure(error.response.status));
  }
}

function* getTestCategoriesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getTestCategories(payload);
    yield put(getTestCategoriesSuccess(data));
  } catch (error) {
    yield put(getTestCategoriesFailure(error.response.status));
  }
}

function* createTestCategorySaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.createTestCategory(payload);
    yield put(createTestCategorySuccess(data));
    callToast('success', 'Test category created');
  } catch (error) {
    callToast('error', error.response.data.message);
    yield put(createTestCategoryFailure(error.response.status));
  }
}

function* editTestCategorySaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.editTestCategory(payload);
    yield put(editTestCategorySuccess(data));
  } catch (error) {
    callToast('error', error.response.data.message);
    yield put(editTestCategoryFailure(error.response.status));
  }
}

function* deleteTestCategorySaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.deleteTestCategory(payload);
    yield put(deleteTestCategorySuccess(data));
    callToast('success', 'Test category deleted');
  } catch (error) {
    callToast('error', error.response.data.message);
    yield put(deleteTestCategoryFailure(error.response.status));
  }
}

function* getAnswerChoisesSaga() {
  try {
    const { data } = yield commonApi.getAnswerChoises();
    yield put(getAnswerChoisesSuccess(data));
  } catch (error) {
    yield put(getAnswerChoisesFailure(error.response.status));
  }
}

function* createAnswerChoiseSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.createAnswerChoise(payload);
    yield put(createAnswerChoisesSuccess(data));
    callToast('success', 'Answer сhoice created');
  } catch (error) {
    callToast('error', 'Answer choiсe was not created');
    yield put(createAnswerChoisesFailure(error.response.status));
  }
}

function* editAnswerChoiseSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.editAnswerChoise(payload);
    yield put(editAnswerChoisesSuccess(data));
    callToast('success', 'Answer сhoice updated');
  } catch (error) {
    callToast('error', 'Answer choiсe was not updated');
    yield put(editAnswerChoisesFailure(error.response.status));
  }
}

function* deleteAnswerChoiseSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.deleteAnswerChoise(payload);
    yield put(deleteAnswerChoisesSuccess(data));
    callToast('success', 'Answer сhoice deleted');
  } catch (error) {
    callToast('error', 'Answer choiсe was not deleted');
    yield put(deleteAnswerChoisesFailure(error.response.status));
  }
}

function* getAllAdminsSaga() {
  try {
    const { data } = yield adminApi.getAllAdmins();
    yield put(getAllAdminsSuccess(data));
  } catch (error) {
    callToast('error', 'Falied to get admins data');
    yield put(getAllAdminsFailure(error.response.status));
  }
}

function* inviteAdminSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.inviteAdmin(payload);
    callToast('success', 'An Admin has been invited!');
    yield put(inviteAdminSuccess(data));
  } catch (error) {
    callToast('error', error.response.data[0].msg || 'An Admin has not been invited');
    yield put(inviteAdminFailure(error.response.status));
  }
}

function* getAdminSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.getAdmin(payload);
    yield put(getAdminSuccess(data));
  } catch (error) {
    callToast('error', 'Error in getting admin data');
    yield put(getAdminFailure(error.response.status));
  }
}

function* editAdminSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.editAdmin(payload);
    callToast('success', 'Admin profile saved');
    yield put(editAdminSuccess(data));
  } catch (error) {
    callToast('error', 'Error in updating admin data');
    yield put(editAdminFailure(error.response.status));
  }
}

function* getAllOccupationsSaga() {
  try {
    const { data } = yield commonApi.getAllOccupations();
    yield put(getAllOccupationsSuccess(data));
  } catch (error) {
    callToast('error', 'Error in getting occupations data');
    yield put(getAllOccupationsFailure(error.response.status));
  }
}

function* getSingleOccupationSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getSingleOccupation(payload);
    yield put(getSingleOccupationSuccess(data));
  } catch (error) {
    callToast('error', 'Error in getting occupation data');
    yield put(getSingleOccupationFailure(error.response.status));
  }
}

function* getAllOccupationsResourcesSaga() {
  try {
    const { data } = yield commonApi.getAllOccupationsResources();
    yield put(getAllOccupationsResourcesSuccess(data));
  } catch (error) {
    callToast('error', 'Error in getting occupation resources data');
    yield put(getAllOccupationsResourcesFailure(error.response.status));
  }
}

function* getSingleOccupationResourceSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getSingleOccupationResource(payload);
    yield put(getSingleOccupationResourseSuccess(data));
  } catch (error) {
    callToast('error', 'Error in getting occupation resource data');
    yield put(getSingleOccupationResourseFailure(error.response.status));
  }
}

function* resourceMapTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.resourseMapTest(payload);
    yield put(resourceMapTestSuccess(data));
    callToast('success', 'Test mapped successfully');
  } catch (error) {
    callToast('error', 'Error map test to resource');
    yield put(resourceMapTestFailure(error.response.status));
  }
}

function* resourceUnMapTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield adminApi.resourseUnMapTest(payload);
    yield put(resourceUnMapTestSuccess(data));
    callToast('success', 'Test unmapped successfully');
  } catch (error) {
    callToast('error', 'Error map test to resource');
    yield put(resourceUnMapTestFailure(error.response.status));
  }
}

function* getImportStatusSaga() {
  try {
    const { data } = yield adminApi.getImportStatus();
    yield put(getImportStatusSuccess(data));
  } catch (error) {
    callToast('error', 'Error in getting import status');
    yield put(getImportStatusFailure(error.response.status));
  }
}

function* getLastImportStatusSaga() {
  try {
    const { data } = yield adminApi.getLastImportStatus();
    yield put(getLastImportStatusSuccess(data));
  } catch (error) {
    callToast('error', 'Error in getting import status');
    yield put(getLastImportStatusFailure(error.response.status));
  }
}

function* startImportSaga() {
  try {
    const { data } = yield adminApi.startImport();
    yield put(startImportSuccess(data));
  } catch (error) {
    callToast('error', 'Failure in starting import');
    yield put(startImportFailure(error.response.status));
  }
}

export default function* subscriptionsWatcher() {
  yield takeLatest(configurationsConstants.GET_SUBSCRIPTIONS_REQUEST, getSubscriptionsSaga);
  yield takeLatest(configurationsConstants.STRIPE_CONNECT_REQUEST, connectStripeSaga);
  yield takeLatest(configurationsConstants.CREATE_SUBSCRIPTION_REQUEST, createSubscriptionsSaga);
  yield takeLatest(configurationsConstants.EDIT_SUBSCRIPTION_REQUEST, editSubscriptionsSaga);
  yield takeLatest(configurationsConstants.DOWNGRADE_FREE_REQUEST, downgradeFreeSaga);
  yield takeLatest(
    configurationsConstants.GET_CURRENT_SUBSCRIPTION_REQUEST,
    getCurrnetSubscriptionSaga
  );
  yield takeLatest(configurationsConstants.PUBLISH_SUBSCRIPTION_REQUEST, publishSubscriptionsSaga);
  yield takeLatest(configurationsConstants.ARCHIVE_SUBSCRIPTION_REQUEST, archiveSubscriptionsSaga);
  yield takeLatest(configurationsConstants.GET_TEST_CATEGORIES_REQUEST, getTestCategoriesSaga);
  yield takeLatest(configurationsConstants.CREATE_TEST_CATEGORY_REQUEST, createTestCategorySaga);
  yield takeLatest(configurationsConstants.EDIT_TEST_CATEGORY_REQUEST, editTestCategorySaga);
  yield takeLatest(configurationsConstants.DELETE_TEST_CATEGORY_REQUEST, deleteTestCategorySaga);
  yield takeLatest(configurationsConstants.GET_ANSWER_CHOISES_REQUEST, getAnswerChoisesSaga);
  yield takeLatest(configurationsConstants.CREATE_ANSWER_CHOISE_REQUEST, createAnswerChoiseSaga);
  yield takeLatest(configurationsConstants.EDIT_ANSWER_CHOISE_REQUEST, editAnswerChoiseSaga);
  yield takeLatest(configurationsConstants.DELETE_ANSWER_CHOISE_REQUEST, deleteAnswerChoiseSaga);
  yield takeLatest(configurationsConstants.GET_ALL_ADMINS_REQUEST, getAllAdminsSaga);
  yield takeLatest(configurationsConstants.INVITE_ADMIN_REQUEST, inviteAdminSaga);
  yield takeLatest(configurationsConstants.GET_ADMIN_REQUEST, getAdminSaga);
  yield takeLatest(configurationsConstants.EDIT_ADMIN_REQUEST, editAdminSaga);
  yield takeLatest(configurationsConstants.GET_ALL_OCCUPATIONS_REQUEST, getAllOccupationsSaga);
  yield takeLatest(
    configurationsConstants.GET_ALL_OCCUPATIONS_RESOURCES_REQUEST,
    getAllOccupationsResourcesSaga
  );
  yield takeLatest(
    configurationsConstants.GET_SINGLE_OCCUPATION_RESOURCE_REQUEST,
    getSingleOccupationResourceSaga
  );
  yield takeLatest(configurationsConstants.RESOURCE_MAP_TEST_REQUEST, resourceMapTestSaga);
  yield takeLatest(configurationsConstants.RESOURCE_UNMAP_TEST_REQUEST, resourceUnMapTestSaga);
  yield takeLatest(configurationsConstants.GET_IMPORT_STATUS_REQUEST, getImportStatusSaga);
  yield takeLatest(configurationsConstants.START_IMPORT_REQUEST, startImportSaga);
  yield takeLatest(configurationsConstants.GET_LAST_IMPORT_STATUS_REQUEST, getLastImportStatusSaga);
  yield takeLatest(configurationsConstants.GET_SINGLE_OCCUPATION_REQUEST, getSingleOccupationSaga);
}
