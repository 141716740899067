import React from 'react';

import { List, Typography } from 'ui-kit';

import cn from 'utils/classNames';
import * as yup from 'yup';

type StrengthOption = {
  label: string;
  color: string;
  schema: yup.StringSchema;
};

interface StrengthProps {
  options?: StrengthOption[];
  value: string;
  className?: string;
}

const defaultOptions = [
  { schema: yup.string().min(1), color: 'bg-red-400', label: 'weak' },
  { schema: yup.string().min(8), color: 'bg-yellow-400', label: 'okay' },
  { schema: yup.string().min(12), color: 'bg-green-300', label: 'good' },
  { schema: yup.string().min(16), color: 'bg-green-400', label: 'strong' }
];

export const Strength: React.FC<StrengthProps> = ({
  className,
  options = defaultOptions,
  value = ''
}) => {
  const errorIdx = options.map(({ schema }) => schema.isValidSync(value)).lastIndexOf(true);
  const { color, label } = options[errorIdx] || {};

  return (
    <div className={`relative ${className}`}>
      <List
        data={options}
        element={(_, idx) => (
          <div className={cn('h-1 flex-1 rounded-lg', errorIdx >= idx ? color : 'bg-gray-300')} />
        )}
        keyExtractor={({ label }, idx) => label + idx}
        wrap="div"
        className="flex gap-2"
      />
      <Typography className="absolute right-0" title={label} variant="small-default" />
    </div>
  );
};
