import { Route, Routes } from 'react-router-dom';

import MyProfile from 'pages/Common/MyProfile';
import MyCompany from 'pages/Employer/MyCompany';

import { Common } from 'layouts';

import * as routes from './routes';
import { ManageSubscription } from 'pages/Employer/ManageSubscription/ManageSubscription';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import { FailedSubscription } from 'pages/Employer/FailedSubscription/FailedSubscription';

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Common />
          </PrivateRoute>
        }>
        <Route exact path={routes.PROFILE} element={<MyProfile />} />
        <Route exact path={routes.COMPANY} element={<MyCompany />} />
        <Route exact path={routes.SUBSCRIPTIONS} element={<ManageSubscription />} />
        <Route exact path={routes.SUBSCRIPTIONS + routes.FAILED} element={<FailedSubscription />} />
      </Route>
    </Routes>
  );
};

export default ProfileRoutes;
