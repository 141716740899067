import { useController } from 'react-hook-form';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputReact = ({ name, onChangeValue, ...props }) => {
  const { field, fieldState, formState } = useController({
    name
  });
  const { onChange, onBlur, value, ref } = field;

  const handleChange = value => {
    onChange(value);
    onChangeValue?.(value);
  };

  return (
    <PhoneInput
      inputProps={{
        name,
        required: true,
        ref,
        onBlur
      }}
      containerStyle={{
        width: '100%',
        height: '46px'
      }}
      inputStyle={{
        width: '100%',
        height: '46px',
        borderRadius: '8px',
        outline: 'none',
        boxShadow: 'none',
        borderColor: '#CACACA'
      }}
      buttonStyle={{
        background: 'none',
        paddingLeft: '5px',
        backgroundColor: 'transparent',
        borderRight: 'none',
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px'
      }}
      country={'us'}
      value={value}
      onChange={handleChange}
    />
  );
};

export default PhoneInputReact;
