import React, { useCallback, useEffect, useState } from 'react';

interface useModalProps {
  initialState: boolean;
}

export const useModal = ({ initialState }: useModalProps) => {
  const [isOpen, setOpen] = useState<boolean>(initialState);
  const [props, setProps] = useState<any>();

  const toggle = useCallback(
    (state: boolean) =>
      <T>(props?: T) => {
        setProps(props);
        setOpen(state);
      },
    []
  );

  const close = toggle(false);
  const open = toggle(true);

  useEffect(() => {
    const isPortalEmpty = !document.querySelector('#portal')?.childNodes.length;

    if (isPortalEmpty) {
      document.body.classList.remove('overflow-hidden');
    } else {
      document.body.classList.add('overflow-hidden');
    }
  }, [isOpen]);

  return {
    toggle,
    close,
    open,
    isOpen,
    props
  };
};
