import { createAction } from 'redux-actions';

export const testConstants = {
  RESET_ERRORS: 'RESET_ERRORS',

  GET_ALL_TESTS_REQUEST: 'GET_ALL_TESTS_REQUEST',
  GET_ALL_TESTS_SUCCESS: 'GET_ALL_TESTS_SUCCESS',
  GET_ALL_TESTS_FAILURE: 'GET_ALL_TESTS_FAILURE',

  PUBLISH_TEST_REQUEST: 'PUBLISH_TEST_REQUEST',
  PUBLISH_TEST_SUCCESS: 'PUBLISH_TEST_SUCCESS',
  PUBLISH_TEST_FAILURE: 'PUBLISH_TEST_FAILURE',

  ARCHIVE_TEST_REQUEST: 'ARCHIVE_TEST_REQUEST',
  ARCHIVE_TEST_SUCCESS: 'ARCHIVE_TEST_SUCCESS',
  ARCHIVE_TEST_FAILURE: 'ARCHIVE_TEST_FAILURE',

  GET_PUBLISHED_TESTS_REQUEST: 'GET_PUBLISHED_TESTS_REQUEST',
  GET_PUBLISHED_TESTS_SUCCESS: 'GET_PUBLISHED_TESTS_SUCCESS',
  GET_PUBLISHED_TESTS_FAILURE: 'GET_PUBLISHED_TESTS_FAILURE',

  GET_TESTS_BY_IMPORTANCE_REQUEST: 'GET_TESTS_BY_IMPORTANCE_REQUEST',
  GET_TESTS_BY_IMPORTANCE_SUCCESS: 'GET_TESTS_BY_IMPORTANCE_SUCCESS',
  GET_TESTS_BY_IMPORTANCE_FAILURE: 'GET_TESTS_BY_IMPORTANCE_FAILURE',

  GET_DRAFT_TESTS_REQUEST: 'GET_DRAFT_TESTS_REQUEST',
  GET_DRAFT_TESTS_SUCCESS: 'GET_DRAFT_TESTS_SUCCESS',
  GET_DRAFT_TESTS_FAILURE: 'GET_DRAFT_TESTS_FAILURE',

  DELETE_DRAFT_TEST_REQUEST: 'DELETE_DRAFT_TEST_REQUEST',
  DELETE_DRAFT_TEST_SUCCESS: 'DELETE_DRAFT_TEST_SUCCESS',
  DELETE_DRAFT_TEST_FAILURE: 'DELETE_DRAFT_TEST_FAILURE',

  GET_ARCHIVED_TESTS_REQUEST: 'GET_ARCHIVED_TESTS_REQUEST',
  GET_ARCHIVED_TESTS_SUCCESS: 'GET_ARCHIVED_TESTS_SUCCESS',
  GET_ARCHIVED_TESTS_FAILURE: 'GET_ARCHIVED_TESTS_FAILURE',

  GET_CURRENT_TEST_REQUEST: 'GET_CURRENT_TEST_REQUEST',
  GET_CURRENT_TEST_SUCCESS: 'GET_CURRENT_TEST_SUCCESS',
  GET_CURRENT_TEST_FAILURE: 'GET_CURRENT_TEST_FAILURE',

  CREATE_TEST_REQUEST: 'CREATE_TEST_REQUEST',
  CREATE_TEST_SUCCESS: 'CREATE_TEST_SUCCESS',
  CREATE_TEST_FAILURE: 'CREATE_TEST_FAILURE',

  TEST_REQUEST_REQUEST: 'TEST_REQUEST_REQUEST',
  TEST_REQUEST_SUCCESS: 'TEST_REQUEST_SUCCESS',
  TEST_REQUEST_FAILURE: 'TEST_REQUEST_FAILURE',

  CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_FAILURE: 'CREATE_TEMPLATE_FAILURE',

  EDIT_TEMPLATE_REQUEST: 'EDIT_TEMPLATE_REQUEST',
  EDIT_TEMPLATE_SUCCESS: 'EDIT_TEMPLATE_SUCCESS',
  EDIT_TEMPLATE_FAILURE: 'EDIT_TEMPLATE_FAILURE',

  PUBLISH_TEMPLATE_REQUEST: 'PUBLISH_TEMPLATE_REQUEST',
  PUBLISH_TEMPLATE_SUCCESS: 'PUBLISH_TEMPLATE_SUCCESS',
  PUBLISH_TEMPLATE_FAILURE: 'PUBLISH_TEMPLATE_FAILURE',

  ARCHIVE_TEMPLATE_REQUEST: 'ARCHIVE_TEMPLATE_REQUEST',
  ARCHIVE_TEMPLATE_SUCCESS: 'ARCHIVE_TEMPLATE_SUCCESS',
  ARCHIVE_TEMPLATE_FAILURE: 'ARCHIVE_TEMPLATE_FAILURE',

  DRAFT_TEMPLATE_REQUEST: 'DRAFT_TEMPLATE_REQUEST',
  DRAFT_TEMPLATE_SUCCESS: 'DRAFT_TEMPLATE_SUCCESS',
  DRAFT_TEMPLATE_FAILURE: 'DRAFT_TEMPLATE_FAILURE',

  GET_TEMPLATE_REQUEST: 'GET_TEMPLATE_REQUEST',
  GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
  GET_TEMPLATE_FAILURE: 'GET_TEMPLATE_FAILURE',

  GET_DRAFT_TEMPLATES_REQUEST: 'GET_DRAFT_TEMPLATES_REQUEST',
  GET_DRAFT_TEMPLATES_SUCCESS: 'GET_DRAFT_TEMPLATES_SUCCESS',
  GET_DRAFT_TEMPLATES_FAILURE: 'GET_DRAFT_TEMPLATES_FAILURE',

  GET_PUBLISHED_TEMPLATES_REQUEST: 'GET_PUBLISHED_TEMPLATES_REQUEST',
  GET_PUBLISHED_TEMPLATES_SUCCESS: 'GET_PUBLISHED_TEMPLATES_SUCCESS',
  GET_PUBLISHED_TEMPLATES_FAILURE: 'GET_PUBLISHED_TEMPLATES_FAILURE',

  GET_ARCHIVED_TEMPLATES_REQUEST: 'GET_ARCHIVED_TEMPLATES_REQUEST',
  GET_ARCHIVED_TEMPLATES_SUCCESS: 'GET_ARCHIVED_TEMPLATES_SUCCESS',
  GET_ARCHIVED_TEMPLATES_FAILURE: 'GET_ARCHIVED_TEMPLATES_FAILURE',

  CREATE_NEW_VERSION_TEST_REQUEST: 'CREATE_NEW_VERSION_TEST_REQUEST',
  CREATE_NEW_VERSION_TEST_SUCCESS: 'CREATE_NEW_VERSION_TEST_SUCCESS',
  CREATE_NEW_VERSION_TEST_FAILURE: 'CREATE_NEW_VERSION_TEST_FAILURE',

  GET_TEST_VERSIONS_REQUEST: 'GET_TEST_VERSIONS_REQUEST',
  GET_TEST_VERSIONS_SUCCESS: 'GET_TEST_VERSIONS_SUCCESS',
  GET_TEST_VERSIONS_FAILURE: 'GET_TEST_VERSIONS_FAILURE',

  UPDATE_TEST_REQUEST: 'UPDATE_TEST_REQUEST',
  UPDATE_TEST_SUCCESS: 'UPDATE_TEST_SUCCESS',
  UPDATE_TEST_FAILURE: 'UPDATE_TEST_FAILURE',

  GET_AREAS_OF_USE_REQUEST: 'GET_AREAS_OF_USE_REQUEST',
  GET_AREAS_OF_USE_SUCCESS: 'GET_AREAS_OF_USE_SUCCESS',
  GET_AREAS_OF_USE_FAILURE: 'GET_AREAS_OF_USE_FAILURE',

  UPDATE_AREAS_OF_USE: 'UPDATE_AREAS_OF_USE',

  GET_SUITABLE_FOR_REQUEST: 'GET_SUITABLE_FOR_REQUEST',
  GET_SUITABLE_FOR_SUCCESS: 'GET_SUITABLE_FOR_SUCCESS',
  GET_SUITABLE_FOR_FAILURE: 'GET_SUITABLE_FOR_FAILURE',

  UPDATE_SUITABLE_FOR: 'UPDATE_SUITABLE_FOR',

  GET_SECTIONS_TAGS_REQUEST: 'GET_SECTIONS_TAGS_REQUEST',
  GET_SECTIONS_TAGS_SUCCESS: 'GET_SECTIONS_TAGS_SUCCESS',
  GET_SECTIONS_TAGS_FAILURE: 'GET_SECTIONS_TAGS_FAILURE',

  GET_JOB_TITLES_REQUEST: 'GET_JOB_TITLES_REQUEST',
  GET_JOB_TITLES_SUCCESS: 'GET_JOB_TITLES_SUCCESS',
  GET_JOB_TITLES_FAILURE: 'GET_JOB_TITLES_FAILURE',

  GET_JOB_ROLES_REQUEST: 'GET_JOB_ROLES_REQUEST',
  GET_JOB_ROLES_SUCCESS: 'GET_JOB_ROLES_SUCCESS',
  GET_JOB_ROLES_FAILURE: 'GET_JOB_ROLES_FAILURE',
  RESET_JOB_ROLES: 'RESET_JOB_ROLES',

  //ACTION FOR DEV - NO BACKEND ===================
  INIT_EDIT_TEST: 'INIT/EDIT/TEST',
  CLEAR_EDIT_TEST: 'CLEAR/EDIT/TEST',

  CLEAR_JOB_TITLES: 'CLEAR_JOB_TITLES',

  SAVE_SUMMARY: 'SAVE/SUMMARY',
  SAVE_SETTINGS: 'SAVE/SETTINGS',
  SAVE_WEBSITE_SETTINGS: 'SAVE/WEBSITE_SETTINGS',

  SAVE_TEMPLATES_SUMMARY: 'SAVE_TEMPLATES_SUMMARY',

  ADD_TEST_TO_TEMPLATE: 'ADD_TEST_TO_TEMPLATE',
  REMOVE_TEST_FROM_TEMPLATE: 'REMOVE_TEST_FROM_TEMPLATE',
  AUTOFILL_TEMPLATE: 'AUTOFILL_TEMPLATE',

  CREATE_SECTION: 'CREATE/SECTION',
  DELETE_SECTION: 'DELETE/SECTION',
  UPDATE_SECTION: 'UPDATE/SECTION',
  SWAP_SECTION: 'SWAP/SECTION',
  CREATE_QUESTION: 'CREATE/QUESTION',
  DELETE_QUESTION: 'DELETE/QUESTION',
  SWAP_QUESTION: 'SWAP/QUESTION',
  UPDATE_QUESTION: 'UPDATE/QUESTION',
  DUPLICATE_QUESTION: 'DUPLICATE/QUESTION',

  UPLOAD_ATTACHMENT_REQUEST: 'UPLOAD/ATTACHMENT/REQUEST',
  UPLOAD_ATTACHMENT_SUCCESS: 'UPLOAD/ATTACHMENT/SUCCESS',
  UPLOAD_ATTACHMENT_FAILED: 'UPLOAD/ATTACHMENT/FAILED',

  DELETE_ATTACHMENT_REQUEST: 'DELETE/ATTACHMENT/REQUEST',
  DELETE_ATTACHMENT_SUCCESS: 'DELETE/ATTACHMENT/SUCCESS',
  DELETE_ATTACHMENT_FAILED: 'DELETE/ATTACHMENT/FAILED',

  EDIT_TEST: 'EDIT_TEST',
  UPDATE_TEST: 'UPDATE_TEST',
  DELETE_TEST: 'DELETE_TEST',
  RESET_TEST_DATA: 'RESET_TEST_DATA',

  ADD_TEST_QUESTION: 'ADD_TEST_QUESTION',
  DUBLICATE_TEST_QUESTION: 'DUBLICATE_TEST_QUESTION',
  DELETE_TEST_QUESTION: 'DELETE_TEST_QUESTION',
  MOVE_UP_TEST_QUESTION: 'MOVE_UP_TEST_QUESTION',
  MOVE_DOWN_TEST_QUESTION: 'MOVE_DOWN_TEST_QUESTION',
  CHANGE_CALC_METHOD: 'CHANGE_CALC_METHOD',
  CHANGE_INCLUDE_TO_HIRENEST_SCORE: 'CHANGE_INCLUDE_TO_HIRENEST_SCORE',
  UPDATE_RESULT_TAGS: 'UPDATE_RESULT_TAGS',
  UPDATE_CHART_SETTINGS: 'UPDATE_CHART_SETTINGS',

  CLEAR_TESTS_PAGINATION: 'CLEAR_TESTS_PAGINATION',
  CLEAR_TESTS: 'CLEAR_TESTS',

  CLEAR_TEMPLATES_PAGINATION: 'CLEAR_TEMPLATES_PAGINATION',
  CLEAR_TEMPLATES: 'CLEAR_TEMPLATES',
  CLEAR_TEMPLATE: 'CLEAR_TEMPLATE'
  //===============================================
};

export const testsResetErrors = createAction(testConstants.RESET_ERRORS);

export const getAllTestsRequest = createAction(
  testConstants.GET_ALL_TESTS_REQUEST,
  action => action
);
export const getAllTestsSuccess = createAction(testConstants.GET_ALL_TESTS_SUCCESS, data => data);
export const getAllTestsFailure = createAction(
  testConstants.GET_ALL_TESTS_FAILURE,
  errors => errors
);

export const publishTestRequest = createAction(
  testConstants.PUBLISH_TEST_REQUEST,
  action => action
);
export const publishTestSuccess = createAction(testConstants.PUBLISH_TEST_SUCCESS, data => data);
export const publishTestFailure = createAction(
  testConstants.PUBLISH_TEST_FAILURE,
  errors => errors
);

export const archiveTestRequest = createAction(
  testConstants.ARCHIVE_TEST_REQUEST,
  action => action
);
export const archiveTestSuccess = createAction(testConstants.ARCHIVE_TEST_SUCCESS, data => data);
export const archiveTestFailure = createAction(
  testConstants.ARCHIVE_TEST_FAILURE,
  errors => errors
);

export const getPublishedTestRequest = createAction(
  testConstants.GET_PUBLISHED_TESTS_REQUEST,
  action => action
);
export const getPublishedTestSuccess = createAction(
  testConstants.GET_PUBLISHED_TESTS_SUCCESS,
  data => data
);
export const getPublishedTestFailure = createAction(
  testConstants.GET_PUBLISHED_TESTS_FAILURE,
  errors => errors
);

export const getTestByImportanceRequest = createAction(
  testConstants.GET_TESTS_BY_IMPORTANCE_REQUEST,
  action => action
);
export const getTestByImportanceSuccess = createAction(
  testConstants.GET_TESTS_BY_IMPORTANCE_SUCCESS,
  data => data
);
export const getTestByImportanceFailure = createAction(
  testConstants.GET_TESTS_BY_IMPORTANCE_FAILURE,
  errors => errors
);

export const getDraftTestRequest = createAction(
  testConstants.GET_DRAFT_TESTS_REQUEST,
  action => action
);
export const getDraftTestSuccess = createAction(
  testConstants.GET_DRAFT_TESTS_SUCCESS,
  data => data
);
export const getDraftTestFailure = createAction(
  testConstants.GET_DRAFT_TESTS_FAILURE,
  errors => errors
);

export const deleteDraftTestRequest = createAction(
  testConstants.DELETE_DRAFT_TEST_REQUEST,
  action => action
);
export const deleteDraftTestSuccess = createAction(
  testConstants.DELETE_DRAFT_TEST_SUCCESS,
  data => data
);
export const deleteDraftTestFailure = createAction(
  testConstants.DELETE_DRAFT_TEST_FAILURE,
  errors => errors
);

export const getArchivedTestRequest = createAction(
  testConstants.GET_ARCHIVED_TESTS_REQUEST,
  action => action
);
export const getArchivedTestSuccess = createAction(
  testConstants.GET_ARCHIVED_TESTS_SUCCESS,
  data => data
);
export const getArchivedTestFailure = createAction(
  testConstants.GET_ARCHIVED_TESTS_FAILURE,
  errors => errors
);

export const getCurrentTestRequest = createAction(
  testConstants.GET_CURRENT_TEST_REQUEST,
  action => action
);
export const getCurrentTestSuccess = createAction(
  testConstants.GET_CURRENT_TEST_SUCCESS,
  data => data
);
export const getCurrentTestFailure = createAction(
  testConstants.GET_CURRENT_TEST_FAILURE,
  errors => errors
);

export const createTemplateRequest = createAction(
  testConstants.CREATE_TEMPLATE_REQUEST,
  action => action
);
export const createTemplateSuccess = createAction(
  testConstants.CREATE_TEMPLATE_SUCCESS,
  data => data
);
export const createTemplateFailure = createAction(
  testConstants.CREATE_TEMPLATE_FAILURE,
  errors => errors
);

export const testRequestRequest = createAction(
  testConstants.TEST_REQUEST_REQUEST,
  action => action
);
export const testRequestSuccess = createAction(testConstants.TEST_REQUEST_SUCCESS, data => data);
export const testRequestFailure = createAction(
  testConstants.TEST_REQUEST_FAILURE,
  errors => errors
);

export const editTemplateRequest = createAction(
  testConstants.EDIT_TEMPLATE_REQUEST,
  action => action
);
export const editTemplateSuccess = createAction(testConstants.EDIT_TEMPLATE_SUCCESS, data => data);
export const editTemplateFailure = createAction(
  testConstants.EDIT_TEMPLATE_FAILURE,
  errors => errors
);

export const publishTemplateRequest = createAction(
  testConstants.PUBLISH_TEMPLATE_REQUEST,
  action => action
);
export const publishTemplateSuccess = createAction(
  testConstants.PUBLISH_TEMPLATE_SUCCESS,
  data => data
);
export const publishTemplateFailure = createAction(
  testConstants.PUBLISH_TEMPLATE_FAILURE,
  errors => errors
);

export const archiveTemplateRequest = createAction(
  testConstants.ARCHIVE_TEMPLATE_REQUEST,
  action => action
);
export const archiveTemplateSuccess = createAction(
  testConstants.ARCHIVE_TEMPLATE_SUCCESS,
  data => data
);
export const archiveTemplateFailure = createAction(
  testConstants.ARCHIVE_TEMPLATE_FAILURE,
  errors => errors
);

export const draftTemplateRequest = createAction(
  testConstants.DRAFT_TEMPLATE_REQUEST,
  action => action
);
export const draftTemplateSuccess = createAction(
  testConstants.DRAFT_TEMPLATE_SUCCESS,
  data => data
);
export const draftTemplateFailure = createAction(
  testConstants.DRAFT_TEMPLATE_FAILURE,
  errors => errors
);

export const getTemplateRequest = createAction(
  testConstants.GET_TEMPLATE_REQUEST,
  action => action
);
export const getTemplateSuccess = createAction(testConstants.GET_TEMPLATE_SUCCESS, data => data);
export const getTemplateFailure = createAction(
  testConstants.GET_TEMPLATE_FAILURE,
  errors => errors
);

export const getDraftTemplatesRequest = createAction(
  testConstants.GET_DRAFT_TEMPLATES_REQUEST,
  action => action
);
export const getDraftTemplatesSuccess = createAction(
  testConstants.GET_DRAFT_TEMPLATES_SUCCESS,
  data => data
);
export const getDraftTemplatesFailure = createAction(
  testConstants.GET_DRAFT_TEMPLATES_FAILURE,
  errors => errors
);

export const getPublishedTemplatesRequest = createAction(
  testConstants.GET_PUBLISHED_TEMPLATES_REQUEST,
  action => action
);
export const getPublishedTemplatesSuccess = createAction(
  testConstants.GET_PUBLISHED_TEMPLATES_SUCCESS,
  data => data
);
export const getPublishedTemplatesFailure = createAction(
  testConstants.GET_PUBLISHED_TEMPLATES_FAILURE,
  errors => errors
);

export const getArchivedTemplatesRequest = createAction(
  testConstants.GET_ARCHIVED_TEMPLATES_REQUEST,
  action => action
);
export const getArchivedTemplatesSuccess = createAction(
  testConstants.GET_ARCHIVED_TEMPLATES_SUCCESS,
  data => data
);
export const getArchivedTemplatesFailure = createAction(
  testConstants.GET_ARCHIVED_TEMPLATES_FAILURE,
  errors => errors
);

export const createTestRequest = createAction(testConstants.CREATE_TEST_REQUEST, action => action);
export const createTestSuccess = createAction(testConstants.CREATE_TEST_SUCCESS, data => data);
export const createTestFailure = createAction(testConstants.CREATE_TEST_FAILURE, errors => errors);

export const getJobTitlesRequest = createAction(
  testConstants.GET_JOB_TITLES_REQUEST,
  action => action
);
export const getJobTitlesSuccess = createAction(testConstants.GET_JOB_TITLES_SUCCESS, data => data);
export const getJobTitlesFailure = createAction(
  testConstants.GET_JOB_TITLES_FAILURE,
  errors => errors
);

export const getJobRolesRequest = createAction(
  testConstants.GET_JOB_ROLES_REQUEST,
  action => action
);
export const getJobRolesSuccess = createAction(testConstants.GET_JOB_ROLES_SUCCESS, data => data);
export const getJobRolesFailure = createAction(
  testConstants.GET_JOB_ROLES_FAILURE,
  errors => errors
);
export const resetJobRoles = createAction(testConstants.RESET_JOB_ROLES, action => action);

export const createNewVersionTestRequest = createAction(
  testConstants.CREATE_NEW_VERSION_TEST_REQUEST,
  action => action
);
export const createNewVersionTestSuccess = createAction(
  testConstants.CREATE_NEW_VERSION_TEST_SUCCESS,
  data => data
);
export const createNewVersionTestFailure = createAction(
  testConstants.CREATE_NEW_VERSION_TEST_FAILURE,
  errors => errors
);

export const getTestVerionsRequest = createAction(
  testConstants.GET_TEST_VERSIONS_REQUEST,
  action => action
);
export const getTestVerionsSuccess = createAction(
  testConstants.GET_TEST_VERSIONS_SUCCESS,
  data => data
);
export const getTestVerionsFailure = createAction(
  testConstants.GET_TEST_VERSIONS_FAILURE,
  errors => errors
);

export const updateTestRequest = createAction(testConstants.UPDATE_TEST_REQUEST, action => action);
export const updateTestSuccess = createAction(testConstants.UPDATE_TEST_SUCCESS, data => data);
export const updateTestFailure = createAction(testConstants.UPDATE_TEST_FAILURE, errors => errors);

//ACTION FOR DEV - NO BACKEND ===================
export const editTest = createAction(testConstants.EDIT_TEST, testId => testId);
export const updateTest = createAction(testConstants.UPDATE_TEST, newTestData => newTestData);
export const deleteTest = createAction(testConstants.DELETE_TEST, testId => testId);
export const resetTest = createAction(testConstants.RESET_TEST_DATA);

export const addTestQuestion = createAction(
  testConstants.ADD_TEST_QUESTION,
  questionData => questionData
);
export const dublicateTestQuestion = createAction(
  testConstants.DUBLICATE_TEST_QUESTION,
  questionId => questionId
);
export const deleteTestQuestion = createAction(
  testConstants.DELETE_TEST_QUESTION,
  questionId => questionId
);
export const moveUpTestQuestion = createAction(
  testConstants.MOVE_UP_TEST_QUESTION,
  questionId => questionId
);
export const moveDownTestQuestion = createAction(
  testConstants.MOVE_DOWN_TEST_QUESTION,
  questionId => questionId
);

export const initEditTest = createAction(testConstants.INIT_EDIT_TEST, action => action);

export const saveSummary = createAction(testConstants.SAVE_SUMMARY, action => action);
export const saveSettings = createAction(testConstants.SAVE_SETTINGS, action => action);
export const saveWebsiteSettings = createAction(
  testConstants.SAVE_WEBSITE_SETTINGS,
  action => action
);

export const saveTemplatesSummary = createAction(
  testConstants.SAVE_TEMPLATES_SUMMARY,
  action => action
);

export const createSection = createAction(testConstants.CREATE_SECTION, action => action);
export const deleteSection = createAction(testConstants.DELETE_SECTION, action => action);
export const updateSection = createAction(testConstants.UPDATE_SECTION, action => action);
export const swapSection = createAction(testConstants.SWAP_SECTION, action => action);

export const createQuestion = createAction(testConstants.CREATE_QUESTION, action => action);
export const deleteQuestion = createAction(testConstants.DELETE_QUESTION, action => action);
export const updateQuestion = createAction(testConstants.UPDATE_QUESTION, action => action);
export const swapQuestion = createAction(testConstants.SWAP_QUESTION, action => action);
export const duplicateQuestion = createAction(testConstants.DUPLICATE_QUESTION, action => action);

export const uploadAttachmentRequest = createAction(
  testConstants.UPLOAD_ATTACHMENT_REQUEST,
  action => action
);
export const uploadAttachmentSuccess = createAction(
  testConstants.UPLOAD_ATTACHMENT_SUCCESS,
  action => action
);
export const uploadAttachmentFailed = createAction(
  testConstants.UPLOAD_ATTACHMENT_FAILED,
  action => action
);

export const deleteAttachmentRequest = createAction(
  testConstants.DELETE_ATTACHMENT_REQUEST,
  action => action
);
export const deleteAttachmentSuccess = createAction(
  testConstants.DELETE_ATTACHMENT_SUCCESS,
  action => action
);
export const deleteAttachmentFailed = createAction(
  testConstants.DELETE_ATTACHMENT_FAILED,
  action => action
);

export const getAreasOfUseRequest = createAction(
  testConstants.GET_AREAS_OF_USE_REQUEST,
  action => action
);
export const getAreasOfUseSuccess = createAction(
  testConstants.GET_AREAS_OF_USE_SUCCESS,
  action => action
);
export const getAreasOfUseFailed = createAction(
  testConstants.GET_AREAS_OF_USE_FAILURE,
  action => action
);

export const getSuitableForRequest = createAction(
  testConstants.GET_SUITABLE_FOR_REQUEST,
  action => action
);
export const getSuitableForSuccess = createAction(
  testConstants.GET_SUITABLE_FOR_SUCCESS,
  action => action
);
export const getSuitableForFailed = createAction(
  testConstants.GET_SUITABLE_FOR_FAILURE,
  action => action
);

export const getSectionsTagsRequest = createAction(
  testConstants.GET_SECTIONS_TAGS_REQUEST,
  action => action
);
export const getSectionsTagsSuccess = createAction(
  testConstants.GET_SECTIONS_TAGS_SUCCESS,
  action => action
);
export const getSectionsTagsFailed = createAction(
  testConstants.GET_SECTIONS_TAGS_FAILURE,
  action => action
);

export const updateAreasOfUse = createAction(testConstants.UPDATE_AREAS_OF_USE, action => action);

export const updateSuitableFor = createAction(testConstants.UPDATE_SUITABLE_FOR, action => action);

export const clearTest = createAction(testConstants.CLEAR_EDIT_TEST, action => action);

export const changeCalcMethod = createAction(testConstants.CHANGE_CALC_METHOD, action => action);

export const changeIncludeToHirenestScore = createAction(
  testConstants.CHANGE_INCLUDE_TO_HIRENEST_SCORE,
  action => action
);

export const updateResultTag = createAction(testConstants.UPDATE_RESULT_TAGS, action => action);

export const updateChartSettings = createAction(
  testConstants.UPDATE_CHART_SETTINGS,
  action => action
);

export const clearTestsPagination = createAction(
  testConstants.CLEAR_TESTS_PAGINATION,
  action => action
);

export const clearTests = createAction(testConstants.CLEAR_TESTS, action => action);

export const clearTemplatesPagination = createAction(
  testConstants.CLEAR_TEMPLATES_PAGINATION,
  action => action
);

export const clearTemplates = createAction(testConstants.CLEAR_TEMPLATES, action => action);

export const resetTestData = createAction(testConstants.RESET_TEST_DATA, action => action);

export const addTestToTemplate = createAction(testConstants.ADD_TEST_TO_TEMPLATE, action => action);
export const autoFillTemplate = createAction(testConstants.AUTOFILL_TEMPLATE, action => action);
export const removeTestFromTemplate = createAction(
  testConstants.REMOVE_TEST_FROM_TEMPLATE,
  action => action
);

export const clearTemplate = createAction(testConstants.CLEAR_TEMPLATE, action => action);

export const clearJobTitles = createAction(testConstants.CLEAR_JOB_TITLES, action => action);
