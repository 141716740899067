import { Form, Typography, Toggle } from 'ui-kit';
import { useDispatch } from 'react-redux';
import { setAssessmentAntiCheat } from 'store/assessment/actions';

const AntiCheatBlock = ({ currentAssessment, className }) => {
  const dispatch = useDispatch();
  return (
    // As we have fake passive anticheat that user can not change, here is using one default parameter for all fake toggle
    <Form
      defaultValues={{
        passiveAntiCheat: true,
        enableCamera: currentAssessment.antiCheat?.enableCamera ?? false
      }}>
      <div className={`mt-[36px] flex gap-[24px] rounded-md bg-white p-6 shadow-med ${className}`}>
        <div className="flex-1">
          <Typography variant="heading-h4" title="Active Anti-Cheat" className="mb-[24px]" />
          <div className="flex items-center">
            <Toggle
              name="enableCamera"
              onChangeValue={() => {
                dispatch(
                  setAssessmentAntiCheat({
                    enableCamera: !currentAssessment.antiCheat.enableCamera
                  })
                );
              }}
            />
            <Typography
              variant="heading-h6"
              title="Enable Camera"
              className="ml-[12px] text-[16px] text-[#082623]"
            />
          </div>
          <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
            We capture an image of the candidate every 30 seconds during the assessment. These
            images are part of our anti-cheating monitoring system. Before accessing their webcam,
            we always seek the candidate's permission.
          </div>
        </div>
        <div className="flex-1">
          <Typography variant="heading-h4" title="Passive Anti-Cheat" />
          <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
            These settings are always enabled to ensure that candidates are as fair as possible in
            their assessments.
          </div>
          <div className="mt-[24px] flex flex-col gap-[24px]">
            <div>
              <div className="flex items-center">
                <Toggle name="passiveAntiCheat" disabled />
                <Typography
                  variant="heading-h6"
                  title="Monitor IP"
                  className="ml-[12px] text-[16px] text-[#082623]"
                />
              </div>
              <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
                Ensures that the candidate does not change their IP address during the assessment.
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <Toggle name="passiveAntiCheat" disabled />
                <Typography
                  variant="heading-h6"
                  title="Filled out only once from the IP address"
                  className="ml-[12px] text-[16px] text-[#082623]"
                />
              </div>
              <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
                Using the IP address, we check if candidates fill out the assessment only once (with
                a public link to the assessment, candidates could use multiple email addresses to
                take repeated attempts at the assessment).
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <Toggle name="passiveAntiCheat" disabled />
                <Typography
                  variant="heading-h6"
                  title="Browser Fingerprint"
                  className="ml-[12px] text-[16px] text-[#082623]"
                />
              </div>
              <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
                Completing several tests from the same browser.
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <Toggle name="passiveAntiCheat" disabled />
                <Typography
                  variant="heading-h6"
                  title="Assessment Completion Time"
                  className="ml-[12px] text-[16px] text-[#082623]"
                />
              </div>
              <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
                Controls the speed of passing the assessment.
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <Toggle name="passiveAntiCheat" disabled />
                <Typography
                  variant="heading-h6"
                  title="Email Similarity"
                  className="ml-[12px] text-[16px] text-[#082623]"
                />
              </div>
              <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
                Using similar emails (jan@gmail.com, jan+1@gmail.com, etc)
              </div>
            </div>

            <div>
              <div className="flex items-center">
                <Toggle name="passiveAntiCheat" disabled />
                <Typography
                  variant="heading-h6"
                  title="Test Completion Time"
                  className="ml-[12px] text-[16px] text-[#082623]"
                />
              </div>
              <div className="mt-[6px] max-w-[500px] text-[14px] text-[#6D7175]">
                Controls the speed of passing tests.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AntiCheatBlock;
