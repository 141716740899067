import React from 'react';

export const insertTags = text => {
  let tags = text.split('>');
  let result = [];
  tags.forEach(tag => {
    if (tag.includes('<')) {
      let tagName = tag.split('<')[1];
      let tagContent = tag.split('<')[0];
      result.push(<span>{React.createElement(tagName, null, tagContent)}</span>);
    } else {
      result.push(<span key={tag}>{tag}</span>);
    }
  });
  return result;
};
