import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import ModalWrapper from 'hocs/ModalWrapper';

import { Button, Typography } from 'ui-kit';
import DropZonePickerAvatar from 'components/DropPickerAvatar';

import getCroppedImg from 'utils/helpers';
import { updateCompanyImageRequest, updateProfileImageRequest } from 'store/auth/actions';
import { faPlus, faXmark } from '@fortawesome/pro-light-svg-icons';

const UploadPopUpForm = ({ isOpen, setIsOpenModal, type }) => {
  const dispatch = useDispatch();

  const { user } = useSelector(store => store.auth);

  const [imgSrc, setImgSrc] = useState(null);
  const [fileType, setFileType] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [aspect, setAspect] = useState(1 / 1);
  const [completedCrop, setCompletedCrop] = useState();

  const closeModal = () => {
    setIsOpenModal(false);
    setImgSrc('');
    setAspect(1 / 1);
  };

  const resetSelectedPhoto = () => {
    setImgSrc('');
    setAspect(1 / 1);
  };

  const showCroppedImage = useCallback(async () => {
    const croppedImage = await getCroppedImg(
      type,
      user._id,
      imgRef.current,
      completedCrop,
      fileType
    );
    try {
      const formData = new FormData();
      if (type === 'user') {
        formData.append('image', croppedImage);
        dispatch(updateProfileImageRequest(formData));
        setIsOpenModal(false);
      } else {
        formData.append('logo', croppedImage);
        dispatch(updateCompanyImageRequest(formData));
        setIsOpenModal(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [imgRef, completedCrop]);

  function centerAspectCrop(mediaWidth, mediaHeight) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 99
        },
        type === 'user' ? aspect : mediaWidth / mediaHeight,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  function onSelectFile(file) {
    if (file) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result.toString() || ''));
      setFileType(file.type);
      reader.readAsDataURL(file);
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
    setAspect(undefined);
  }

  return (
    <ModalWrapper isOpen={isOpen}>
      <form className="py-8">
        <div className="flex justify-between border-b border-gray-300 px-10.5 pb-8 align-baseline">
          <Typography title="Upload profile photo" variant="heading-h3" />
          <Button icon={faXmark} onClick={closeModal} iconSize={20} iconColor="#5C5F62" />
        </div>
        {imgSrc ? (
          <div className="my-6 flex justify-center px-10.5">
            <div className="relative w-[300px] ">
              <div className="flex h-[300px] items-center justify-center">
                <ReactCrop
                  crop={crop}
                  className="max-h-[300px] max-w-[300px]"
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={c => setCompletedCrop(c)}
                  aspect={aspect}>
                  <img
                    ref={imgRef}
                    className="object-contain"
                    alt="Crop me"
                    src={imgSrc}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
            </div>
            <div
              onClick={resetSelectedPhoto}
              className="absolute right-[100px] bottom-[100px] cursor-pointer">
              <span className="font-sans text-green-400">Replace</span>
            </div>
          </div>
        ) : (
          <div className="my-6 mx-10.5">
            <div>
              <DropZonePickerAvatar icon={faPlus} handleChange={file => onSelectFile(file)} />
            </div>
          </div>
        )}

        <div className="flex justify-end gap-4 px-10">
          <Button onClick={closeModal} variant="primary-outline" title="Cancel" />
          <Button
            disabled={!imgSrc}
            onClick={showCroppedImage}
            variant="primary"
            title="Upload as profile photo"
          />
        </div>
      </form>
    </ModalWrapper>
  );
};

export default UploadPopUpForm;
