import Footer from 'components/Footer';
import Loader from 'components/Loader';
import { useUserRole } from 'hooks';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Typography } from 'ui-kit';
import { navConfig } from 'utils/helpers';

export const AuthWrapperDefault = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { role } = useUserRole();
  const { dashboard } = navConfig(role);

  const { loading } = useSelector(store => store.auth);

  useEffect(() => {
    if (hash) {
      return;
    }
    if (role) {
      navigate(dashboard);
    }
  }, [role, hash]);

  return (
    <main
      style={{
        background:
          'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), linear-gradient(99.09deg, #F0FAF8 0%, rgba(234, 241, 214, 0.7) 51.13%, #FAE2DD 100%)'
      }}
      className="flex h-full min-h-[100vh] w-full flex-col justify-between px-5">
      {loading && <Loader />}
      <Outlet />
      <div className="mb-6 flex items-center justify-center gap-[8px]">
        <i className="fa-solid fa-lock-keyhole h-4 w-4 text-[#6D7175]"></i>
        <Typography
          className="mt-[2px] text-base text-[#8C9196]"
          variant="small-default"
          title="Security, privacy, and confidentiality guarantee: 2048-bit SSL encryption, NDA protection"
        />
      </div>
      <Footer />
    </main>
  );
};
