import React, { useCallback, useEffect } from 'react';

import ReactDom from 'react-dom';

import { useLocation } from 'react-router-dom';

interface WrapperMethods {
  stopPropagation?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface ModalProps {
  isOpen: boolean;
  children?: React.ReactNode | ((values: WrapperMethods) => React.ReactNode);
  disableScroll?: boolean;
}

const ModalWrapper: React.FC<ModalProps> = ({
  isOpen,
  children,
  disableScroll= true
}) => {
  const location = useLocation();
  const stopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }, []);
  const isFunction = typeof children === 'function';
  
  useEffect(() => {
    document.body.style.overflow = isOpen && disableScroll ? 'hidden' : 'auto';
    const beamer = document.querySelector<HTMLElement>('#beamerSelector');
    if (!beamer) {
      return;
    }
    beamer.style.display = isOpen ? 'none' : 'block';
  }, [isOpen]);
  
  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, [location.pathname]);
  
  if (!isOpen) return null;
  
  const methods = {stopPropagation};
  
  return ReactDom.createPortal(
    <>{isFunction ? children(methods) : children}</>,
    document.getElementById('portal') as Element
  );
}

export { ModalWrapper };