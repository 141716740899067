import { Typography } from 'ui-kit';

import { TopPerformers } from './templates/TopPerformers';
import { PublishedAssessments } from './templates/PublishedAssessments';
import { RecentApplications } from './templates/RecentApplications';
import { PerformanceChart } from './templates/PerformanceChart';
import { useEffect } from 'react';
import { localStorageService } from 'helpers/localStorageService';

export const Dashboard = ({ auth }) => {
  useEffect(() => {
    localStorageService.setAssessmentStep(0);
  }, []);
  return (
    <div className="py-8 px-7.5">
      <Typography title="Dashboard" variant="heading-h1" className="mb-6" />

      <div className="flex gap-6">
        <div className="flex w-[calc(50%-0.75rem)] flex-1 flex-col">
          {/* Recent applications widget */}
          <RecentApplications auth={auth} />

          {/* Top Performers widget */}
          <TopPerformers auth={auth} />
        </div>

        <div className="flex w-[calc(50%-0.75rem)] flex-1 flex-col">
          {/* Published Assessments */}
          <PublishedAssessments auth={auth} />
          {/* Performance */}
          <PerformanceChart auth={auth} />
        </div>
      </div>
    </div>
  );
};
