import { createFilter, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStyles } from 'ui-kit/CustomSelect/SelectCustom';
import { Controller } from 'react-hook-form';
import { Icon } from 'ui-kit';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const JobRoleSelect = ({ name, options, onChangeValue, onMenuOpen, onMenuClose }) => (
  <Controller
    name={name}
    render={({ field }) => {
      return (
        <CreatableSelect
          {...field}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          onChange={value => {
            field.onChange(value);
            onChangeValue && onChangeValue(value);
          }}
          formatCreateLabel={userInput => `Use '${userInput}'`}
          defaultValue={''}
          placeholder="Job Roles..."
          filterOption={createFilter({ ignoreAccents: false })}
          styles={customStyles}
          options={options}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (
              <Icon
                icon={faMagnifyingGlass}
                size={16}
                color="#8C9196"
                className="absolute left-0 ml-3 items-center"
              />
            ),
            ValueContainer: ({ children, ...props }) => {
              return (
                components.ValueContainer && (
                  <components.ValueContainer {...props} className="!pl-[32px]">
                    {children}
                  </components.ValueContainer>
                )
              );
            }
          }}
        />
      );
    }}
  />
);

export default JobRoleSelect;
