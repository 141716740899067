import React, { MutableRefObject, useEffect, useRef } from 'react';

import { ModalWrapper } from '../wrapper';
import { ModalBody } from '../ModalBody';
import { ModalFooter } from '../ModalFooter';
import { ModalHeader } from '../ModalHeader';

import cn from 'utils/classNames';

interface ModalComponents {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
}

interface ModalProps {
  isOpen: boolean;
  children?: React.ReactNode;
  className?: string;
  onClose?: () => void;
  onScroll?: (ref: MutableRefObject<HTMLDivElement | null>) => void;
  size?: 'full' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl';
}

const Modal: React.FC<ModalProps> & ModalComponents = ({
  children,
  className,
  isOpen,
  onClose,
  onScroll,
  size
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const scrollListener = () => {
    onScroll && onScroll(modalRef);
  };
  useEffect(() => {
    if (modalRef && modalRef.current && onScroll) {
      modalRef.current.addEventListener('scroll', scrollListener);
      return () => modalRef.current?.removeEventListener('scroll', scrollListener);
    }
  }, [modalRef, onScroll]);

  return (
    <ModalWrapper isOpen={isOpen}>
      {({ stopPropagation }) => (
        <div
          className={`fixed inset-0 z-20 flex justify-center overflow-auto bg-[rgba(100,100,100,0.5)] px-8 backdrop-blur-[3px]`}
          ref={modalRef}
          onClick={onClose}>
          <div
            className={cn(
              'rounded-lg max-h-full table flex-col',
              size && `w-full max-w-${size} m-auto`,
              className
            )}
            onClick={stopPropagation}>
            <div className={`my-8 rounded-lg bg-white `}>{children}</div>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export { Modal };
