import { Tooltip } from 'react-tooltip';
import { useEffect, useState } from 'react';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';

export const ResultDetailsDimensionsSection = ({
  sectionName,
  calculatedResult,
  sectionDescription,
  resultInterpretation
}) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setScreenSize(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const lowDimensionComponent = (
    <div className="flex text-base text-[#082623]">
      <div>{resultInterpretation?.dimensionScales?.low?.name}</div>
      <div className="ml-1 text-[#5C5F62]">
        <i
          className="fa-regular fa-circle-info relative"
          data-tooltip-id="certificate-dimension-tooltip"
          data-tooltip-html={resultInterpretation?.dimensionScales?.low?.description}
        />
      </div>
    </div>
  );

  const highDimensionComponent = (
    <div className="flex text-base text-[#082623]">
      <div>{resultInterpretation?.dimensionScales?.high?.name}</div>
      <div className="ml-1 text-[#5C5F62]">
        <i
          className="fa-regular fa-circle-info relative"
          data-tooltip-id="certificate-dimension-tooltip"
          data-tooltip-html={resultInterpretation?.dimensionScales?.high?.description}
        />
      </div>
    </div>
  );

  return (
    <div
      className="mx-6 mt-6 flex flex-col rounded border-[#D2D5D8] px-6 py-3"
      style={{
        backgroundColor: `hsl(${resultInterpretation?.dimensionScales?.hue} 33% 96%)`
      }}>
      <Tooltip
        id="certificate-dimension-tooltip"
        className="rounded-s z-50 mt-[3px] w-[226px] max-w-[300px] text-sm
        after:absolute after:left-[50%] after:bottom-[-5px] after:translate-x-[-3px]
        after:border-x-[5px] after:border-b-0
        after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D] after:transition"
        noArrow={true}
        style={{
          background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)'
        }}
      />

      <div className="flex items-center">
        {screenSize >= 744 && lowDimensionComponent}
        <div className="flex-1">
          <div className="mx-auto flex items-center justify-center text-lg">
            <div
              className="font-semibold"
              style={{
                color: `hsl(${resultInterpretation?.dimensionScales?.hue} 43% 28%)`
              }}>
              {sectionName}
            </div>
            <div className="ml-1 text-base text-[#5C5F62]">
              <i
                className="fa-regular fa-circle-info relative"
                data-tooltip-id="certificate-dimension-tooltip"
                data-tooltip-html={sectionDescription}
              />
            </div>
          </div>
        </div>
        {screenSize >= 744 && highDimensionComponent}
      </div>
      <div className="mt-1 mb-3 flex gap-1">
        {resultInterpretation?.grades
          ?.sort((a, b) => a.scale.min - b.scale.min)
          .map((grade, idx) => (
            <div
              key={idx}
              className={`h-[10px] flex-1 shrink-0 rounded-sm`}
              style={{
                backgroundColor: `hsl(${resultInterpretation?.dimensionScales?.hue}, ${
                  grade.scale.max >= calculatedResult && grade.scale.min <= calculatedResult
                    ? '32%'
                    : '30%'
                }, ${
                  grade.scale.max >= calculatedResult && grade.scale.min <= calculatedResult
                    ? '56%'
                    : '85%'
                })`
              }}>
              &nbsp;
            </div>
          ))}
      </div>
      {screenSize < 744 && (
        <div className="flex justify-between">
          {lowDimensionComponent}
          {highDimensionComponent}
        </div>
      )}
      <HtmlTextReplacer html={resultInterpretation?.description} />
    </div>
  );
};
