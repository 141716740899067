import moment from 'moment';
import SectionWrapper from 'hocs/SectionWrapper';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Typography } from 'ui-kit';
import Loader from 'components/Loader';
import SubscriptionView from 'components/SubscriptionViewCard/SubscriptionView';
import { useLocation } from 'react-router-dom';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import PaidSubscriptionInfo from './PaidSubscriptionInfo';
import SuccessModal from 'components/Modals/SuccessModal/SuccessModal';
import { getSubscriptionsRequest } from 'store/configuration/actions/subscriptions';

export const ManageSubscription = () => {
  const dispatch = useDispatch();
  const { subscriptions, clientSecret, loading } = useSelector(store => store.subscriptions);
  const { loading: load, user, company } = useSelector(store => store.auth);
  const [message, setMessage] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const statusPayment = params.get('redirect_status');
  const transactionId = params.get('payment_intent');

  const googleTag = () => {
    window.gtag('event', 'conversion', {
      send_to: process.env.REACT_APP_TRANSACTION_GOOGLE_TAG,
      transaction_id: transactionId
    });
  };

  useEffect(() => {
    if (!statusPayment) {
      return;
    }

    switch (statusPayment) {
      case 'succeeded':
        setMessage('Your subscription has been started, thank you!');
        googleTag();
        break;
      case 'processing':
        setMessage('Your payment is processing.');
        googleTag();
        break;
      case 'requires_payment_method':
        setMessage('We are unable to process your payment.');
        break;
      default:
        setMessage('We are unable to process your payment.');
        break;
    }
  }, [statusPayment]);

  useEffect(() => {
    if (clientSecret) {
      return;
    }
    setTimeout(() => {
      dispatch(getSubscriptionsRequest());
    }, 1000);
  }, [user, clientSecret]);
  return (
    <div className="flex flex-col gap-6 py-8 px-7.5">
      {(loading || load) && <Loader />}
      <Typography title="Manage Subscription" variant="heading-h1" />
      {message && (
        <div
          className={`flex h-[48px] w-full items-center justify-between rounded-md border ${
            statusPayment === 'succeeded'
              ? 'border-[#95C9B4] bg-[#F1F8F5]'
              : 'border-[#FAC4A2] bg-[#FFF4F4]'
          } px-[18px]`}>
          <Typography
            title={message}
            variant="regular-default"
            className={`${statusPayment === 'succeeded' ? 'text-[#264623]' : 'text-[#774030]'}`}
          />
          <button
            onClick={() => {
              window.history.replaceState(null, null, window.location.pathname);
              setMessage('');
            }}
            type="button"
            className="mb-0.5">
            <Icon icon={faXmark} size={12} color="#5C5F62" />
          </button>
        </div>
      )}
      <div className="flex gap-6">
        <SectionWrapper title="Current Subscription" className="h-full flex-1">
          <div className="flex w-full gap-6 px-[20px] pt-2">
            <div className="flex flex-1 flex-col gap-3">
              <Typography variant="heading-h3" title={company?.subscription?.name} />
              {company?.subscription?.pricing !== 'free' && (
                <PaidSubscriptionInfo subscription={company?.subscription} />
              )}
              {company?.subscription?.pricing === 'free' && company?.subscription?.expiresAt && (
                <>
                  <div className="my-[24px] h-[1px] border-t border-[#D2D5D8]" />
                  <div className="text-[16px] text-[#6D7175]">
                    You will be transferred to the <b>Free Plan</b> on{' '}
                    <b>{moment(company?.subscription?.expiresAt).format('MMM DD,YYYY')}.</b>
                  </div>
                </>
              )}
            </div>
          </div>
        </SectionWrapper>
        <SectionWrapper title="Invoices" className="h-full flex-1">
          <div className="px-8 pt-2">
            If you need an invoice please email us at{' '}
            <a href="mailto:support@hirenest.com" className="text-green-500">
              support@hirenest.com
            </a>
          </div>
        </SectionWrapper>
      </div>

      <div className="flex w-full flex-wrap justify-center gap-[20px] p-8">
        {subscriptions.map((subscription, idx) => (
          <SubscriptionView
            key={`subscription_${idx}`}
            avaliableAssesment={company?.availablePublications}
            setSuccessModalOpen={setSuccessModalOpen}
            currentPlan={company.subscription}
            isHighlighted={false}
            isSettings
            {...subscription}
          />
        ))}
        <SuccessModal isModalOpen={successModalOpen} setModalOpen={setSuccessModalOpen} />
      </div>
    </div>
  );
};
