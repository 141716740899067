import { useNavigate } from 'react-router-dom';

import { Typography } from 'ui-kit';

import * as routes from 'routes/routes';

import { badgetStatus, booleanClassName, getModifyData } from 'utils/helpers';

const AssessmenstCardCompany = ({
  _id,
  status,
  name,
  tests,
  applicantsPassed,
  customQuestionsCount,
  updatedAt,
  isAdmin,
  createdAt,
  author
}) => {
  const navigate = useNavigate();

  const viewAssessmentHandler = () => {
    if (status === 'draft' && !isAdmin) {
      navigate(routes.ASSESSMENTS + `/${_id}` + routes.EDIT);
      return;
    }
    navigate(routes.ASSESSMENTS + `/${_id}`, { state: { _id } });
  };

  return (
    <li
      onClick={() => viewAssessmentHandler()}
      className={`relative flex max-h-[266px] w-full min-w-[345px] cursor-pointer flex-col justify-between rounded-md border border-gray-400 bg-white p-4 pt-5.5 transition-all  hover:border-green-400`}>
      <div>
        <span
          className={`absolute top-0 right-4 py-0.5 px-3 text-center capitalize ${badgetStatus(
            status
          )} rounded-b-xl `}>
          <Typography variant="small-default" title={status} />
        </span>
        <Typography
          variant="heading-h3"
          className="mb-2.5 truncate capitalize"
          title={name || 'Default assessment name'}
        />
      </div>
      <div className="flex flex-col space-y-5">
        <ul className="grid grid-cols-2 gap-2.5">
          <li className={`flex flex-col ${booleanClassName(!tests?.length, 'opacity-50')}`}>
            <Typography
              variant="small-default"
              className="mb-1.5 truncate capitalize"
              title="Tests"
            />
            <Typography variant="small-default" title={tests?.length ? tests.length : '-'} />
          </li>
          <li className={`flex flex-col ${booleanClassName(!customQuestionsCount, 'opacity-50')}`}>
            <Typography
              variant="small-default"
              className="mb-1.5 truncate capitalize"
              title="Questions"
            />
            <Typography variant="small-default" title={customQuestionsCount || '-'} />
          </li>
          <li className={`flex flex-col ${booleanClassName(!applicantsPassed, 'opacity-50')}`}>
            <Typography
              variant="small-default"
              className="mb-1.5 truncate capitalize"
              title="Applications"
            />
            <Typography variant="small-default" title={applicantsPassed || '-'} />
          </li>
        </ul>
        <ul>
          <Typography variant="regular-default" title={`by ${author}` || 'by Company Name'} />
          <Typography
            variant="small-default"
            title={`Updated on ${updatedAt ? getModifyData(updatedAt) : getModifyData(createdAt)} `}
            className="opacity-50"
          />
        </ul>
      </div>
    </li>
  );
};

export default AssessmenstCardCompany;
