import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { IconTest } from 'components/IconTest/IconTest';
import Typography from 'ui-kit/Typography';
import { secondsToHms } from 'utils/helpers';
import { formatScaledResult } from 'utils/formatScaledResult';
import { ResultWidget } from 'ui-kit/TestCard/ResultWidget';
import { useMemo } from 'react';
import { stringToHslColor } from 'utils/stringToHslColor';

export const Results = ({
  name,
  category,
  title,
  icon,
  timeLimit,
  result,
  tags,
  description,
  chartSettings,
  sections,
  scaledResult,
  calculationType,
  minValue,
  maxValue,
  onClick
}) => {
  const chartData = useMemo(() => {
    if (!chartSettings || !sections || !tags) {
      return;
    }
    const { includeTestOverall, includeSections, includeTags } = chartSettings;
    const data = [];
    const results = [];

    includeTestOverall?.result &&
      data.push('Test Overall') &&
      results.push(+result?.replace(/[^0-9]/g, ''));
    includeSections &&
      sections.length > 1 &&
      sections.map(({ sectionName, calculatedResult }) => {
        data.push(sectionName);
        results.push(
          typeof calculatedResult === 'string'
            ? +calculatedResult.replace(/[^0-9]/g, '')
            : calculatedResult
        );
      });
    includeTags &&
      tags.map(({ tagName, calculatedResult }) => {
        data.push(tagName);
        results.push(
          typeof calculatedResult === 'string'
            ? +calculatedResult.replace(/[^0-9]/g, '')
            : calculatedResult
        );
      });

    return {
      labels: data,
      datasets: [
        {
          data: results,
          backgroundColor: data.map(value => value && stringToHslColor(value, 45, 55, 0.5)),
          borderWidth: 1
        }
      ]
    };
  }, [sections, tags, chartSettings]);
  return (
    <div
      onClick={onClick}
      className="group flex w-full flex-auto cursor-pointer gap-6 rounded-xl border-[0.5px] border-[#C9CCCF] bg-white p-6 shadow-small transition-all hover:bg-[#FCFCFC]">
      <div className="flex flex-1 flex-col gap-[6px]">
        <div className="flex gap-3">
          <IconTest size={48} url={icon?.url} />
          <div className="flex flex-col">
            <p className="text-base font-semibold text-[#6D7175]">{category?.name || category}</p>
            <div className="flex items-center gap-3">
              <Typography
                className="group-hover:text-[#007D6F]"
                variant="heading-h4"
                title={name}
              />
              <div
                className={`rounded border-[0.5px] border-[#CBD6D6] ${
                  result && 'bg-[#E4E5E7]'
                } px-[6px] py-[1px] font-bold text-[#05443D]`}>
                {formatScaledResult(result)}
              </div>
              <Typography className="text-[#6D7175]" variant="heading-h5" title={title} />
            </div>
          </div>
        </div>
        <HtmlTextReplacer html={description} />
        <p className="text-sm text-[#6D7175]">{`Completed in ${secondsToHms(timeLimit)}`}</p>
      </div>
      <div className="flex flex-1 items-center justify-center">
        <ResultWidget
          chartSettings={chartSettings}
          chartData={chartData}
          sections={sections}
          scaledResult={scaledResult}
          result={result}
          calculationType={calculationType}
          minValue={minValue}
          maxValue={maxValue}
        />
      </div>
    </div>
  );
};
