import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Typography, Icon } from 'ui-kit';

import { HiringStatuses } from 'pages/Employer/CreateAssessment_old/components/HiringStatuses/HiringStatuses';

import {
  setApplicationStatusRequest,
  updateApplication,
  setApplicationOptionalStatusRequest,
  restoreApplicationStatusRequest
} from 'store/candidates/actions';
import { useQueryParams } from 'hooks';

import { SingleApplicationHeader } from './SingleApplicationHeader/SingleApplicationHeader';
import { SectionCandidateInfo } from './SingleApplicatonBody/SectionCandidateInfo';
import { SectionEmployerNote } from './SingleApplicatonBody/SectionEmployerNote';
import { SingleApplicationTestsResults } from './SingleApplicationTests/SingleApplicationTestsResults';
import { SingleApplicationCustomQuestions } from './SingleApplicationTests/SingleApplicationCustomQuestions';
import { Modals } from './Modals/Modals';
import { Tooltips } from './Tooltips/Tooltips';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { SectionAntiCheatMonitor } from './SingleApplicatonBody/SectionAntiCheatMonitor';
import { setUpgradeModalOpen } from 'store/auth/actions';

export const SingleApplication = ({ application, handleCloseApplication, embedded = false }) => {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const [forceModalClose, setForceModalClose] = useState(false);
  const [currentTestId, setCurrentTestId] = useState('');
  const { params } = useQueryParams();

  const upgradeModalHandler = () => {
    dispatch(setUpgradeModalOpen(!application?.visible));
  };

  useEffect(() => {
    upgradeModalHandler();
  }, [application?.visible]);

  useEffect(() => {
    if (params.application) setIsModalOpen(false);
  }, [params.application]);

  const setAppStatus = status => {
    dispatch(setApplicationStatusRequest({ id: application._id, data: { status } }));

    status === 'Not Evaluated' &&
      dispatch(
        updateApplication({
          rating: '',
          ratings: {},
          invitedTakeHomeTest: false,
          completedTakeHomeTest: false,
          referencesChecked: false
        })
      );
  };

  const setAppOptionalStatus = values => {
    dispatch(setApplicationOptionalStatusRequest({ id: application._id, data: values }));
  };

  const restoreApplicationStatus = () => dispatch(restoreApplicationStatusRequest(application._id));

  return (
    <>
      <Tooltips />
      <SingleApplicationHeader
        application={application}
        embedded={embedded}
        handleCloseApplication={handleCloseApplication}
      />

      {application?.assessment?.demo?.isDemo && (
        <div className="my-6 flex gap-6 rounded-md border border-[#D2D5D8] bg-transparent px-[20px] py-[16px]">
          <Icon icon={faCircleQuestion} size={20} />
          <div className="flex flex-col gap-2">
            <Typography variant="regular-default" title="It’s a Demo Application." />
          </div>
        </div>
      )}

      <div className="mt-5 space-y-6">
        <HiringStatuses
          isDemo={application?.assessment?.demo?.isDemo}
          application={application}
          setAppStatus={setAppStatus}
          setIsRateModalOpen={setIsRateModalOpen}
          setAppOptionalStatus={setAppOptionalStatus}
          restoreApplicationStatus={restoreApplicationStatus}
          embedded={embedded}
        />

        {!embedded ? (
          <div className="flex gap-6">
            <div className="flex flex-1 flex-col gap-[24px]">
              <SectionCandidateInfo
                application={application}
                upgradeModalHandler={upgradeModalHandler}
              />
              <SectionEmployerNote application={application} />
            </div>
            <SectionAntiCheatMonitor
              antiCheatMonitor={application?.antiCheatMonitor}
              images={application?.webcamScreenshots}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <SectionCandidateInfo
              embedded={embedded}
              application={application}
              upgradeModalHandler={upgradeModalHandler}
            />
            <SectionAntiCheatMonitor
              embedded={embedded}
              antiCheatMonitor={application?.antiCheatMonitor}
              images={application?.webcamScreenshots}
            />
            <SectionEmployerNote application={application} />
          </div>
        )}

        {application.status !== 'started' && application?.tests?.length > 0 && (
          <SingleApplicationTestsResults
            application={application}
            setCurrentTestId={setCurrentTestId}
            setForceModalClose={setForceModalClose}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        {application?.customQuestions?.sections[0]?.questions?.length > 0 &&
          application?.status !== 'started' && (
            <SingleApplicationCustomQuestions application={application} />
          )}
      </div>

      <Modals
        application={application}
        currentTestId={currentTestId}
        setCurrentTestId={setCurrentTestId}
        isRateModalOpen={isRateModalOpen}
        setIsRateModalOpen={setIsRateModalOpen}
        setUpgradeModalOpen={setUpgradeModalOpen}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        forceModalClose={forceModalClose}
      />
    </>
  );
};
