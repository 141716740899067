import { useNavigate, useParams } from 'react-router-dom';

import Footer from 'components/Footer';
import { Navbar } from 'ui-kit';
import { Logo } from 'ui-kit/Logo';
import * as routes from 'routes/routes';

import { useUserRole } from 'hooks';
import { navConfig } from 'utils/helpers';
import { useEffect } from 'react';
import { ErrorMain } from './ErrorMain';
import { localStorageService } from 'helpers/localStorageService';

export const errorConfig = {
  403: {
    code: 403,
    title: 'Forbidden',
    discription: 'Access to this resource on the server is denided!'
  },
  404: {
    code: 404,
    title: 'Page not Found',
    discription: 'You didn’t break the internet,but we can’t find what are you looking for.'
  },
  422: {
    code: 422,
    title: 'Data not found by this url',
    discription: 'You didn’t break the internet,but we can’t find what are you looking for.'
  },
  500: {
    code: 500,
    title: 'Internal Server Error',
    discription: 'An internal server error has occured.'
  },
  default: {
    code: null,
    title: 'Something went wrong',
    discription: null
  }
};

const ErrorPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { role } = useUserRole();
  const { dashboard } = navConfig(role);

  useEffect(() => {
    if (code === '401') {
      localStorageService.clearStorage();
      localStorageService.setSession(true);
      navigate(routes.AUTH + '/' + routes.AUTH);
    }
  }, [code]);

  return (
    <div className="flex h-screen flex-col justify-between">
      <Navbar>
        <div className="py-6">
          <Logo to={dashboard} />
        </div>
      </Navbar>
      <main className="flex h-full flex-col items-center justify-center">
        <ErrorMain {...errorConfig[code]} dashboard={dashboard} />
      </main>
      <Footer />
    </div>
  );
};

export default ErrorPage;
