import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { useUserRole } from 'hooks';

import Candidates from 'pages/Employer/Candidates';
import ViewCandidate from 'pages/Employer/ViewCandidate';

import { Common } from 'layouts';

import * as routes from './routes';
import PrivateRoute from './PrivateRoute/PrivateRoute';

const AssessmentsRoutes = () => {
  const { isAdmin } = useUserRole();

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Common />
          </PrivateRoute>
        }>
        <Route exact path={routes.CANDIDATES} element={<Candidates isAdmin={isAdmin} />} />
        <Route exact path={routes.VIEW_CANDIDATE} element={<ViewCandidate />} />
      </Route>
    </Routes>
  );
};

export default AssessmentsRoutes;
