import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Button, Modal, Icon } from 'ui-kit';
import Loader from 'components/Loader';
import { ViewTestSummary } from '../ViewTest/ViewTest';
import { CoverTest } from 'components/CoverTest/CoverTest';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

export const ViewTestModal = ({
  isOpenModal,
  setIsOpenModal,
  resource,
  unMapResourceHandler,
  component
}) => {
  const { currentTest, loading } = useSelector(store => store.tests);
  const defaultCloseTop = 68; // 36 (offset inside container) + 32 (margin top of modal)
  const [top, setTop] = useState(defaultCloseTop);

  const onScroll = elem => {
    if (elem.current.scrollTop > 32 && top === 36) return;
    if (elem.current.scrollTop > 32 && top !== 36) setTop(43);
    else if (elem.current.scrollTop <= 32) setTop(defaultCloseTop - elem.current.scrollTop);
  };

  return (
    <Modal
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      onClose={() => setIsOpenModal(false)}
      onScroll={onScroll}
      className="relative flex w-[100%] max-w-[1200px]">
      {loading && <Loader />}
      <Modal.Body className="flex flex-1 flex-col gap-0 rounded-md p-[0px]">
        <div className="sticky z-20 ml-auto flex w-max justify-end" style={{ top: `${top}px` }}>
          <div
            style={{ background: 'rgba(17, 17, 17, 0.6)' }}
            className="mr-[31px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded"
            onClick={() => setIsOpenModal(false)}>
            <Icon icon={faXmark} className="h-[24px] w-[24px] text-white" />
          </div>
        </div>
        <CoverTest size={180} url={currentTest?.cover?.url} className="mt-[-32px] rounded-t-md" />
        <ViewTestSummary currentTest={currentTest} component={component} />
      </Modal.Body>
      {resource && (
        <Modal.Footer className={`flex ${resource ? 'justify-between' : 'justify-end'}`}>
          <Button
            onClick={unMapResourceHandler}
            className="max-w-[400px] truncate"
            variant="primary-outline"
            children={`Unmap from “${
              resource?.title?.length > 30 ? resource?.title?.slice(0, 30) + '...' : resource?.title
            }”`}
          />
        </Modal.Footer>
      )}
    </Modal>
  );
};
