import { useState } from 'react';
import { Button, Modal } from 'ui-kit';
import whatToAdd1 from 'assets/whatToAdd1.png';
import whatToAdd2 from 'assets/whatToAdd2.png';
import whatToAdd3 from 'assets/whatToAdd3.png';

const textData = [
  {
    header: 'Pre-made assessment templates',
    button: '3 components',
    img: whatToAdd1
  },
  {
    header: '3 components for the best assessment',
    button: 'Extend it',
    img: whatToAdd2
  },
  {
    header: 'Extend your assessment with custom questions',
    button: 'Get started',
    img: whatToAdd3
  }
];

const WhatToAddModal = ({ isOpen, setOpen }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <Modal
      isOpen={isOpen}
      onClose={setOpen}
      className="relative m-auto w-full min-w-[650px] max-w-max">
      <div className="relative flex flex-col items-center gap-3 p-6">
        <div className="flex">
          <p className="text-h3 font-medium">{textData[selectedIndex]?.header}</p>
          <div
            className="absolute right-6 top-6"
            onClick={() => {
              setOpen();
              setSelectedIndex(0);
            }}>
            <i className="fa-regular fa-xmark cursor-pointer text-lg text-[#5C5F62]"></i>
          </div>
        </div>
        <img className="h-[400px] w-full max-w-[600px]" src={textData[selectedIndex]?.img} />
        <div className="mt-[12px] flex flex-col items-center gap-6">
          <div className="flex gap-[6px]">
            {[...Array(3)].map((_, index) => (
              <div
                key={index}
                className={`${
                  index === selectedIndex ? 'w-[16px] bg-[#95C9B4]' : 'w-[4px] bg-[#BABEC3]'
                } h-[4px] rounded-full`}
              />
            ))}
          </div>
          <div className="flex gap-3">
            {selectedIndex !== 0 && (
              <Button
                onClick={() => setSelectedIndex(selectedIndex - 1)}
                variant="secondary"
                title="Back"
              />
            )}
            <button
              className="flex items-center gap-2 rounded-lg border border-green-400 bg-[#007D6F] fill-white py-2.5 px-4 text-regular font-semibold leading-normal text-white hover:bg-green-400"
              onClick={() => {
                if (selectedIndex === 2) {
                  setOpen();
                  setSelectedIndex(0);
                } else {
                  setSelectedIndex(selectedIndex + 1);
                }
              }}>
              <p>{textData[selectedIndex]?.button}</p>
              {selectedIndex !== 2 && (
                <div>
                  <i className="fa-regular fa-chevron-right mt-[3px] text-lg text-white"></i>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WhatToAddModal;
