const Logo = ({ to = '/', width, height, color, ...props }) => {
  return (
    <a href={to}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '100'}
        height={height || '21'}
        viewBox="0 0 100 21"
        fill="none"
        {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.3359 2.58984H26.5515V9.05281C26.7732 8.71834 27.0393 8.42846 27.3718 8.16088C27.9254 7.71562 28.7449 7.26966 29.8749 7.26966C32.3122 7.26966 34.0851 9.03051 34.0851 11.9495V18.1895H31.8695V11.9495C31.8695 10.367 30.8718 9.38658 29.4322 9.38658C27.7702 9.38658 26.5515 10.6123 26.5515 12.3954V18.1895H24.3359V2.58984Z"
          fill={color || '#007D6E'}
        />
        <mask id="mask0_3858_12934" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="21">
          <path fillRule="evenodd" clipRule="evenodd" d="M0 20.5H100V0.5H0V20.5Z" fill="white" />
        </mask>
        <g mask="url(#mask0_3858_12934)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.7422 18.189H38.9578V7.49219H36.7422V18.189ZM37.851 2.8125C38.5375 2.8125 39.1805 3.36995 39.1805 4.14899C39.1805 4.92943 38.5375 5.48688 37.851 5.48688C37.1637 5.48688 36.5215 4.92943 36.5215 4.14899C36.5215 3.36995 37.1637 2.8125 37.851 2.8125Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.6172 7.49251H43.8328V8.94049C44.0766 8.65062 44.3427 8.36144 44.6531 8.11616C45.1844 7.6925 45.9819 7.26953 47.0453 7.26953H47.5995V9.60944L47.3113 9.56484C47.134 9.54254 46.9566 9.49795 46.7135 9.49795C45.1844 9.49795 43.8328 10.8351 43.8328 12.7291V18.1893H41.6172V7.49251Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.4628 11.7268C56.1303 10.2788 55.1555 9.27539 53.5822 9.27539C51.8986 9.27539 50.9239 10.2788 50.4805 11.7268H56.4628ZM58.236 16.4062C57.9478 16.7852 57.5709 17.1197 57.1275 17.4089C56.3522 17.9218 55.2666 18.4116 53.6933 18.4116C50.5916 18.4116 48.1543 15.9609 48.1543 12.8406C48.1543 9.72093 50.5916 7.26953 53.5824 7.26953C56.5961 7.26953 58.7895 9.47565 58.7895 12.5068C58.7895 12.8406 58.7452 13.1081 58.723 13.3081L58.6787 13.621H50.4807C50.7246 15.069 52.0098 16.2947 53.8041 16.2947C54.7567 16.2947 55.5327 15.9386 56.064 15.5818C56.3744 15.3812 56.6626 15.1359 56.9065 14.8467L58.236 16.4062Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60.6719 7.49251H62.8875V9.05199C63.1092 8.71751 63.3752 8.42834 63.7077 8.16076C64.2613 7.7148 65.0809 7.26953 66.2108 7.26953C68.6481 7.26953 70.421 9.02969 70.421 11.9493V18.1893H68.2054V11.9493C68.2054 10.3669 67.2078 9.38646 65.7681 9.38646C64.1061 9.38646 62.8875 10.6122 62.8875 12.3946V18.1893H60.6719V7.49251Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M80.6132 11.7268C80.2807 10.2788 79.3059 9.27539 77.7325 9.27539C76.049 9.27539 75.0743 10.2788 74.6309 11.7268H80.6132ZM82.3864 16.4062C82.0982 16.7852 81.7213 17.1197 81.2779 17.4089C80.5026 17.9218 79.417 18.4116 77.8437 18.4116C74.742 18.4116 72.3047 15.9609 72.3047 12.8406C72.3047 9.72093 74.742 7.26953 77.7328 7.26953C80.7465 7.26953 82.9399 9.47565 82.9399 12.5068C82.9399 12.8406 82.8956 13.1081 82.8734 13.3081L82.8291 13.621H74.6311C74.875 15.069 76.1601 16.2947 77.9545 16.2947C78.9071 16.2947 79.683 15.9386 80.2144 15.5818C80.5248 15.3812 80.813 15.1359 81.0569 14.8467L82.3864 16.4062Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85.2665 14.9575C85.4882 15.2474 85.7321 15.4927 86.0196 15.6933C86.5295 16.0501 87.1717 16.4062 88.0363 16.4062C89.2772 16.4062 89.9194 15.7602 89.9194 15.069C89.9194 13.2858 84.2696 14.2001 84.2696 10.3892C84.2696 8.71751 85.7321 7.26953 88.1472 7.26953C89.4102 7.26953 90.3628 7.6479 90.9828 8.00537C91.3597 8.20535 91.6701 8.45063 91.9133 8.71751L90.4736 10.1669C90.2963 10.0101 90.0967 9.85402 89.8529 9.72093C89.4323 9.47565 88.8559 9.27497 88.1472 9.27497C87.0172 9.27497 86.4852 9.83172 86.4852 10.3892C86.4852 12.1716 92.135 11.2811 92.135 15.069C92.135 16.8521 90.6059 18.4116 88.0363 18.4116C86.6182 18.4116 85.5991 17.9218 84.8896 17.4089C84.4691 17.1197 84.1144 16.7852 83.8262 16.4062L85.2665 14.9575Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.0154 9.60831H93.0215V7.49139H94.2401C94.9267 7.49139 95.2371 7.15691 95.2371 6.26569V3.92578H97.231V7.49139H99.6682V9.60831H97.231V14.6226C97.231 15.7368 97.7409 16.2936 98.7821 16.2936C99.114 16.2936 99.4022 16.2267 99.6461 16.1375L100.001 15.9598V18.0767L99.5352 18.2551C99.247 18.3436 98.8487 18.4105 98.3388 18.4105C96.2118 18.4105 95.0154 17.1855 95.0154 14.8456V9.60831Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6843 1.50836L13.6836 1.50766C13.1834 1.15577 12.4947 1.27771 12.1449 1.78081L0 19.2249L1.80891 20.4994L5.74334 14.8482C6.49642 13.7653 8.09056 13.7653 8.84433 14.8482L12.7781 20.4994L14.587 19.2249L10.2618 13.0121C9.73251 12.2519 8.89491 11.7669 7.97556 11.6888L13.9572 3.0539C14.305 2.55149 14.1831 1.85955 13.6843 1.50836Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.58947 8.76121L5.94459 6.81431L2.44177 1.78261C2.09191 1.27951 1.40257 1.15756 0.903056 1.51015C0.403544 1.86135 0.281611 2.55328 0.630784 3.05639L4.58947 8.76121Z"
            fill={color || '#007D6E'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.29433 0.5C7.9816 0.5 8.62382 1.05745 8.62382 1.83649C8.62382 2.61693 7.9816 3.17368 7.29433 3.17368C6.60777 3.17368 5.96484 2.61693 5.96484 1.83649C5.96484 1.05745 6.60777 0.5 7.29433 0.5Z"
            fill={color || '#007D6E'}
          />
        </g>
      </svg>
    </a>
  );
};

export default Logo;
