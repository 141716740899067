import { yupResolver } from '@hookform/resolvers/yup';

import { ErrorMessage, Form, Button, Typography, Modal, Input, Toggle, FieldArray } from 'ui-kit';

import { useDispatch } from 'react-redux';
import { TestCategorySchema } from './validation';
import {
  createTestCategoryRequest,
  editTestCategoryRequest
} from 'store/configuration/actions/subscriptions';
import { NonLinearScale } from '../NonLinearScale/NonLinearScale';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

const CreateTestCategoryForm = ({ isOpenModal, setIsOpenModal, defaultValue, parent }) => {
  const dispatch = useDispatch();

  const onSubmit = (data, control) => {
    !defaultValue?._id && dispatch(createTestCategoryRequest(parent ? { ...data, parent } : data));
    defaultValue?._id && dispatch(editTestCategoryRequest({ id: defaultValue._id, data }));
    control.reset();
    setIsOpenModal(false);
  };

  return (
    <Modal isOpen={isOpenModal} onClose={setIsOpenModal} className="h-max w-[947px] overflow-auto">
      <Form
        mode="all"
        onSubmit={onSubmit}
        resolver={yupResolver(TestCategorySchema)}
        defaultValues={{
          name: defaultValue?.name,
          description: defaultValue?.description,
          visible: defaultValue?.visible,
          nonLinear: defaultValue?.nonLinear,
          scales: defaultValue?.scales
        }}
        className="flex flex-col">
        {({ formState, unregister, watch }) => (
          <>
            <Modal.Header className="!bg-white">
              <Typography
                variant="heading-h4"
                title={
                  parent
                    ? defaultValue?._id
                      ? 'Edit Subcategory'
                      : 'Create Subcategory'
                    : defaultValue?._id
                    ? 'Edit Category'
                    : 'Create Category'
                }
              />
              <Button icon={faXmark} iconSize={20} iconColor="#5C5F62" onClick={setIsOpenModal} />
            </Modal.Header>
            <Modal.Body className="flex flex-col p-6">
              <div className="flex gap-4">
                <div className="flex flex-col">
                  <div>
                    <Typography className="mb-[4px] w-[500px]" title="Category Name" />
                    <Input name="name" />
                    <ErrorMessage name="name" />
                  </div>
                  <div>
                    <Typography className="mb-[4px] w-[500px]" title="Description" />
                    <RichTextEditor name="description" showLength maxLength={3000} />
                    <ErrorMessage name="description" />
                  </div>
                </div>
                <div className="flex flex-col pt-[26px]">
                  <div className="mb-[22px] text-[16px]">
                    <Toggle name="visible" label="Category is visible for Employers and Authors" />
                  </div>
                  {!parent && (
                    <div className="mb-[14px] text-[16px]">
                      <Toggle name="nonLinear" label="Calculate result using a non-linear scale" />
                    </div>
                  )}
                  {!parent && watch('nonLinear') && (
                    <>
                      <div className="flex">
                        <Typography className="mr-[33px] w-[64px]" title="Raw %" />
                        <Typography title="Scaled %" />
                      </div>
                      <FieldArray
                        name="scales"
                        keyExtractor={(_, idx) => idx}
                        element={({ idx, control }) => (
                          <NonLinearScale
                            rawName={`scales[${idx}].raw`}
                            scaleName={`scales[${idx}].scale`}
                            placeholder={false}
                            onClick={() => control.remove(idx)}
                          />
                        )}
                        wrap="div"
                        className="flex flex-col"
                        controller={({ control }) => (
                          <NonLinearScale control={control} unregister={unregister} placeholder />
                        )}
                      />
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-end gap-4">
              <Button variant="primary-outline" onClick={setIsOpenModal} title="Cancel" />
              <Button
                disabled={!formState.isValid}
                variant="primary"
                type="submit"
                title={
                  parent
                    ? defaultValue?._id
                      ? 'Edit Subcategory'
                      : 'Create Subcategory'
                    : defaultValue?._id
                    ? 'Edit Category'
                    : 'Create Category'
                }
              />
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateTestCategoryForm;
