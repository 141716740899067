import { Typography, Button, Section } from 'ui-kit';

import { getModifyData, secondsToHms } from 'utils/helpers';
import countryEmoji from 'country-emoji';

function formatDesktopName(desktopName) {
  // Split the string into words
  const words = desktopName.split(' ');

  // Join the words back together with a space separator
  const formattedDesktop = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  return formattedDesktop + ' ' + words[1];
}

export const SectionCandidateInfo = ({ application, embedded, upgradeModalHandler }) => (
  <Section className="flex flex-1 flex-col gap-8 p-8">
    {!embedded ? (
      <div className="flex gap-[32px]">
        <div className="flex min-w-[290px] flex-col gap-[32px]">
          <div className="flex flex-col gap-[4px]">
            <Typography
              className="text-[#6D7175]"
              variant="regular-default"
              title="Candidate Name"
            />
            <Typography variant="regular-default">
              {!application?.visible && application?.candidate?.[0]?.firstName && (
                <div key={'candidate-hidden-firstName'}>
                  <i
                    key={'candidate-hidden-firstName-icon'}
                    data-tooltip-id="premium-tooltip-top"
                    className="fa-solid fa-gem mr-1 cursor-pointer text-[16px] text-[#2C6ECB]"
                  />
                </div>
              )}
              {application?.candidate?.[0]?.firstName
                ? `${application?.candidate?.[0]?.firstName} ${application?.candidate?.[0]?.lastName}`
                : '-'}
            </Typography>
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Email" />
            <Typography variant="regular-default">
              {!application?.visible && (
                <div key={'candidate-hidden-email'}>
                  <i
                    key={'candidate-hidden-email-icon'}
                    data-tooltip-id="premium-tooltip-top"
                    className="fa-solid fa-gem mr-1 cursor-pointer text-[16px] text-[#2C6ECB]"
                  />
                </div>
              )}
              {application?.candidate?.[0]?.email}
            </Typography>
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Invited On" />
            <Typography
              variant="regular-default"
              title={application?.invitedAt ? getModifyData(application?.invitedAt) : '-'}
            />
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Started On" />
            <Typography
              variant="regular-default"
              title={application?.startedAt ? getModifyData(application?.startedAt) : '-'}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[32px]">
          <div className="flex flex-1 flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="body-regular" title="Time Spent" />
            <Typography
              variant="body-regular"
              title={application?.timeSpent ? secondsToHms(application?.timeSpent) : '-'}
            />
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Updated On" />
            <Typography
              variant="regular-default"
              title={application?.updatedAt ? getModifyData(application?.updatedAt) : '-'}
            />
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Device Used" />
            <div className="flex flex-col gap-[4px]">
              {application?.antiCheatMonitor?.devices?.length > 0 ? (
                application?.antiCheatMonitor?.devices.map(item => (
                  <Typography
                    key={item}
                    variant="regular-default"
                    title={formatDesktopName(item)}
                  />
                ))
              ) : (
                <Typography variant="regular-default" title="-" />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography
              className="text-[#6D7175]"
              variant="regular-default"
              title="Candidate Location"
            />
            <div className="flex flex-col gap-[4px]">
              {application?.antiCheatMonitor?.locations?.length > 0 ? (
                application?.antiCheatMonitor?.locations.map(({ _id, country, city }) => (
                  <div className="flex items-center gap-[6px]" key={_id}>
                    <span role="img" aria-label={country}>
                      {countryEmoji.flag(country)}
                    </span>
                    <Typography variant="regular-default" title={`${city}, ${country}`} />
                  </div>
                ))
              ) : (
                <Typography variant="refular-default" title="-" />
              )}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="flex justify-between">
        <div className="flex flex-col gap-[32px]">
          <div className="flex flex-col gap-[4px]">
            <Typography
              className="text-[#6D7175]"
              variant="regular-default"
              title="Candidate Name"
            />
            <Typography variant="regular-default">
              {!application?.visible && application?.candidate?.[0]?.firstName && (
                <div key={'candidate-hidden-firstName'}>
                  <i
                    key={'candidate-hidden-firstName-icon'}
                    data-tooltip-id="premium-tooltip-top"
                    className="fa-solid fa-gem mr-1 cursor-pointer text-[16px] text-[#2C6ECB]"
                  />
                </div>
              )}
              {application?.candidate?.[0]?.firstName
                ? `${application?.candidate?.[0]?.firstName} ${application?.candidate?.[0]?.lastName}`
                : '-'}
            </Typography>
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Email" />
            <Typography variant="regular-default">
              {!application?.visible && (
                <div key={'candidate-hidden-email'}>
                  <i
                    key={'candidate-hidden-email-icon'}
                    data-tooltip-id="premium-tooltip-top"
                    className="fa-solid fa-gem mr-1 cursor-pointer text-[16px] text-[#2C6ECB]"
                  />
                </div>
              )}
              {application?.candidate?.[0]?.email}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-[32px]">
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="body-regular" title="Time Spent" />
            <Typography
              variant="body-regular"
              title={application?.timeSpent ? secondsToHms(application?.timeSpent) : '-'}
            />
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Updated On" />
            <Typography
              variant="regular-default"
              title={application?.updatedAt ? getModifyData(application?.updatedAt) : '-'}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[32px]">
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Invited On" />
            <Typography
              variant="regular-default"
              title={application?.invitedAt ? getModifyData(application?.invitedAt) : '-'}
            />
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Started On" />
            <Typography
              variant="regular-default"
              title={application?.startedAt ? getModifyData(application?.startedAt) : '-'}
            />
          </div>
        </div>
        <div className="flex flex-col gap-[32px]">
          <div className="flex flex-col gap-[4px]">
            <Typography className="text-[#6D7175]" variant="regular-default" title="Device Used" />
            <div className="flex flex-col gap-[4px]">
              {application?.antiCheatMonitor?.devices?.length > 0 ? (
                application?.antiCheatMonitor?.devices.map(item => (
                  <Typography
                    key={item}
                    variant="regular-default"
                    title={formatDesktopName(item)}
                  />
                ))
              ) : (
                <Typography variant="regular-default" title="-" />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-[4px]">
            <Typography
              className="text-[#6D7175]"
              variant="regular-default"
              title="Candidate Location"
            />
            <div className="flex flex-col gap-[4px]">
              {application?.antiCheatMonitor?.locations?.length > 0 ? (
                application?.antiCheatMonitor?.locations.map(({ _id, country, city }) => (
                  <div className="flex items-center gap-[6px]" key={_id}>
                    <span role="img" aria-label={country}>
                      {countryEmoji.flag(country)}
                    </span>
                    <Typography variant="regular-default" title={`${city}, ${country}`} />
                  </div>
                ))
              ) : (
                <Typography variant="refular-default" title="-" />
              )}
            </div>
          </div>
        </div>
      </div>
    )}
    {!application?.visible && (
      <div className="flex items-center justify-center">
        <Button onClick={upgradeModalHandler} variant="primary">
          <div key={'upgrade-subscription-button'}>
            <i
              key={'upgrade-subscription-icon'}
              className="fa-solid fa-gem mr-1 cursor-pointer text-[16px] text-[#FFFFFF]"
            />
          </div>
          Upgrade Subscription
        </Button>
      </div>
    )}
  </Section>
);
