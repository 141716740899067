import React, { useCallback, useMemo, useState } from 'react';

import { useOnOutsideClick } from 'utils/useOnOutsideClick';
import { DropdownContext, DropdownState } from '../DropdownContext';
import { DropdownOption } from '../DropdownOption';

import cn from 'utils/classNames';

interface DropdownComposition {
  Option: typeof DropdownOption;
}

interface DropdownProps {
  controller: ({ isOpen, setOpen }: DropdownState) => React.ReactNode;
  children: ({ isOpen, setOpen }: DropdownState) => React.ReactNode;
  className?: string;
  menuClassName?: string;
}

export const Dropdown: React.FC<DropdownProps> & DropdownComposition = ({
  controller,
  children,
  className,
  menuClassName
}) => {
  const [isOpen, setOpen] = useState(false);

  const { innerBorderRef } = useOnOutsideClick(() => setOpen(false)) as any;

  const toggle = useCallback(() => setOpen(p => !p), []);

  const value = useMemo(
    () => ({
      isOpen,
      setOpen,
      toggle
    }),
    [isOpen]
  );

  const isFunction = typeof children === 'function';

  return (
    <DropdownContext.Provider value={value}>
      <div className={cn('relative', className)} ref={innerBorderRef}>
        {controller(value)}
        {isOpen && (
          <ul
            className={cn(
              menuClassName ||
                'absolute right-0 top-full z-20 bg-white rounded-xl border border-gray-300 border-solid shadow'
            )}>
            {isFunction ? children(value) : children}
          </ul>
        )}
      </div>
    </DropdownContext.Provider>
  );
};

Dropdown.Option = DropdownOption;
