import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouceCallback } from 'hooks';

import { Form, Input, List, Icon } from 'ui-kit';

import EmptyResourse from 'components/EmptyResourse';
import Loader from 'components/Loader';

import { TestCard } from 'ui-kit/TestCard';
import { getTestByAuthorRequest } from 'store/admin/actions';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { customStyles } from 'ui-kit/CustomSelect/SelectCustom';
import { getTestCategoriesRequest } from 'store/configuration/actions/subscriptions';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

const Tests = () => {
  const dispatch = useDispatch();
  const { _id } = useParams();
  const { authorTests, loading } = useSelector(store => store.admin);
  const { testCategories, loading: load } = useSelector(store => store.subscriptions);

  const onSubmit = useCallback(
    data => {
      dispatch(getTestByAuthorRequest({ id: _id, params: data }));
    },
    [_id]
  );

  const debounceSubmit = useDebouceCallback(onSubmit);

  useEffect(() => {
    dispatch(getTestByAuthorRequest({ id: _id }));
    dispatch(getTestCategoriesRequest());
  }, [_id]);

  return (
    <>
      {(loading || load) && <Loader />}
      <Form className="flex justify-end gap-5">
        {({ handleSubmit }) => (
          <>
            <Select
              onChange={data => {
                const type = data.value;
                handleSubmit(onSubmit({ type }));
              }}
              placeholder="Categories"
              options={[{ value: '', label: 'All Tests' }, ...testCategories]}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null
              }}
            />
            <Input
              leftComponent={() => (
                <Icon icon={faMagnifyingGlass} size={16} className="ml-3" color="#8C9196" />
              )}
              onChangeValue={handleSubmit(debounceSubmit)}
              placeholder="Search Tests..."
              name="search"
            />
          </>
        )}
      </Form>
      {!loading && (
        <List
          data={authorTests}
          element={props => <TestCard.Author data={props} className="flex" cardType="author" />}
          keyExtractor={(_, idx) => idx}
          wrap="div"
          className="grid gap-6 py-5 lg:grid-cols-2 2xl:grid-cols-3"
          empty={<EmptyResourse title="Author dont have tests yet" />}
        />
      )}
    </>
  );
};

export default Tests;
