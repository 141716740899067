import Breadcrumbs from 'components/Breadcrumbs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Section, Typography } from 'ui-kit';
import * as routes from 'routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOccupationsResourcesRequest } from 'store/configuration/actions/subscriptions';
import Loader from 'components/Loader';

export const TestsMapping = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { resources, loading } = useSelector(store => store.subscriptions);

  useEffect(() => {
    dispatch(getAllOccupationsResourcesRequest());
  }, []);

  return (
    <div className="w-full px-6 py-9">
      <Breadcrumbs type="occupation" />
      {loading && <Loader />}
      <Typography title="Tests Mapping" variant="subheader" />
      <Section className="mt-[24px]">
        <Section.Header className="flex">
          <div className="flex-2">
            <Typography variant="regular-emphasis" title="Resources" />
          </div>
          <div className="flex-1 text-end">
            <Typography variant="regular-emphasis" title="Total Values" />
          </div>
          <div className="flex-1 text-end">
            <Typography variant="regular-emphasis" title="Mapped Values" />
          </div>
          <div className="flex-1 text-end">
            <Typography variant="regular-emphasis" title="Unmapped Values" />
          </div>
        </Section.Header>
        <Section.Body className="flex flex-col gap-[24px]">
          {resources?.map(({ title, total, pairedValues, unpairedValues, _id }) => (
            <div key={_id} className="flex">
              <div className="flex-2">
                <Typography
                  onClick={() => navigate(routes.CONFIGURATION_TEST_MAPPING_ROUTE + `/${_id}`)}
                  className="cursor-pointer hover:text-green-400"
                  variant="underline"
                  title={title && title}
                />
              </div>
              <div className="flex-1 text-end">
                <Typography variant="regular-default" title={total && total} />
              </div>
              <div className="flex-1 text-end">
                <Typography variant="regular-default" title={pairedValues && pairedValues} />
              </div>
              <div className="flex-1 text-end">
                <Typography variant="regular-default" title={unpairedValues && unpairedValues} />
              </div>
            </div>
          ))}
        </Section.Body>
      </Section>
    </div>
  );
};
