import { createAction } from 'redux-actions';

export const assessmentConstants = {
  RESET_ERRORS: 'RESET_ERRORS',

  PUBLISH_ASSESSMENT_WITHOUT_CONFIRMATION_REQUEST:
    'PUBLISH_ASSESSMENT_WITHOUT_CONFIRMATION_REQUEST',

  GET_ALL_ASSESSMENTS_REQUEST: 'GET_ALL_ASSESSMENTS_REQUEST',
  GET_ALL_ASSESSMENTS_SUCCESS: 'GET_ALL_ASSESSMENTS_SUCCESS',
  GET_ALL_ASSESSMENTS_FAILURE: 'GET_ALL_ASSESSMENTS_FAILURE',

  GET_PUBLISHED_ASSESSMENTS_REQUEST: 'GET_PUBLISHED_ASSESSMENTS_REQUEST',
  GET_PUBLISHED_ASSESSMENTS_SUCCESS: 'GET_PUBLISHED_ASSESSMENTS_SUCCESS',
  GET_PUBLISHED_ASSESSMENTS_FAILURE: 'GET_PUBLISHED_ASSESSMENTS_FAILURE',

  GET_PUBLISHED_DEMO_ASSESSMENTS_REQUEST: 'GET_PUBLISHED_DEMO_ASSESSMENTS_REQUEST',
  GET_PUBLISHED_DEMO_ASSESSMENTS_SUCCESS: 'GET_PUBLISHED_DEMO_ASSESSMENTS_SUCCESS',
  GET_PUBLISHED_DEMO_ASSESSMENTS_FAILURE: 'GET_PUBLISHED_DEMO_ASSESSMENTS_FAILURE',

  GET_PUBLIC_DEMO_ASSESSMENTS_REQUEST: 'GET_PUBLIC_DEMO_ASSESSMENTS_REQUEST',
  GET_PUBLIC_DEMO_ASSESSMENTS_SUCCESS: 'GET_PUBLIC_DEMO_ASSESSMENTS_SUCCESS',
  GET_PUBLIC_DEMO_ASSESSMENTS_FAILURE: 'GET_PUBLIC_DEMO_ASSESSMENTS_FAILURE',

  GET_PUBLISHED_ASSESSMENTS_NAMES_REQUEST: 'GET_PUBLISHED_ASSESSMENTS_NAMES_REQUEST',
  GET_PUBLISHED_ASSESSMENTS_NAMES_SUCCESS: 'GET_PUBLISHED_ASSESSMENTS_NAMES_SUCCESS',
  GET_PUBLISHED_ASSESSMENTS_NAMES_FAILURE: 'GET_PUBLISHED_ASSESSMENTS_NAMES_FAILURE',

  GET_DRAFT_ASSESSMENTS_REQUEST: 'GET_DRAFT_ASSESSMENTS_REQUEST',
  GET_DRAFT_ASSESSMENTS_SUCCESS: 'GET_DRAFT_ASSESSMENTS_SUCCESS',
  GET_DRAFT_ASSESSMENTS_FAILURE: 'GET_DRAFT_ASSESSMENTS_FAILURE',

  GET_ARCHIVED_ASSESSMENTS_REQUEST: 'GET_ARCHIVED_ASSESSMENTS_REQUEST',
  GET_ARCHIVED_ASSESSMENTS_SUCCESS: 'GET_ARCHIVED_ASSESSMENTS_SUCCESS',
  GET_ARCHIVED_ASSESSMENTS_FAILURE: 'GET_ARCHIVED_ASSESSMENTS_FAILURE',

  GET_ARCHIVED_DEMO_ASSESSMENTS_REQUEST: 'GET_ARCHIVED_DEMO_ASSESSMENTS_REQUEST',
  GET_ARCHIVED_DEMO_ASSESSMENTS_SUCCESS: 'GET_ARCHIVED_DEMO_ASSESSMENTS_SUCCESS',
  GET_ARCHIVED_DEMO_ASSESSMENTS_FAILURE: 'GET_ARCHIVED_DEMO_ASSESSMENTS_FAILURE',

  GET_ASSESSMENT_REQUEST: 'GET_ASSESSMENT_REQUEST',
  GET_ASSESSMENT_SUCCESS: 'GET_ASSESSMENT_SUCCESS',
  GET_ASSESSMENT_FAILURE: 'GET_ASSESSMENT_FAILURE',

  GET_ASSESSMENT_APPLICATIONS_REQUEST: 'GET_ASSESSMENT_APPLICATIONS_REQUEST',
  GET_ASSESSMENT_APPLICATIONS_SUCCESS: 'GET_ASSESSMENT_APPLICATIONS_SUCCESS',
  GET_ASSESSMENT_APPLICATIONS_FAILURE: 'GET_ASSESSMENT_APPLICATIONS_FAILURE',

  GET_ASSESSMENT_GENERIC_TEMPLATES_REQUEST: 'GET_ASSESSMENT_GENERIC_TEMPLATES_REQUEST',
  GET_ASSESSMENT_GENERIC_TEMPLATES_SUCCESS: 'GET_ASSESSMENT_GENERIC_TEMPLATES_SUCCESS',
  GET_ASSESSMENT_GENERIC_TEMPLATES_FAILURE: 'GET_ASSESSMENT_GENERIC_TEMPLATES_FAILURE',

  PUBLISH_ASSESSMENT_REQUEST: 'PUBLISH_ASSESSMENT_REQUEST',
  PUBLISH_ASSESSMENT_SUCCESS: 'PUBLISH_ASSESSMENT_SUCCESS',
  PUBLISH_ASSESSMENT_FAILURE: 'PUBLISH_ASSESSMENT_FAILURE',

  GET_LAST_PUBLISH_ASSESSMENT_REQUEST: 'GET_LAST_PUBLISH_ASSESSMENT_REQUEST',
  GET_LAST_PUBLISH_ASSESSMENT_SUCCESS: 'GET_LAST_PUBLISH_ASSESSMENT_SUCCESS',
  GET_LAST_PUBLISH_ASSESSMENT_FAILURE: 'GET_LAST_PUBLISH_ASSESSMENT_FAILURE',

  PUBLISH_ASSESSMENT_FROM_TEMPLATE_REQUEST: 'PUBLISH_ASSESSMENT_FROM_TEMPLATE_REQUEST',
  PUBLISH_ASSESSMENT_FROM_TEMPLATE_SUCCESS: 'PUBLISH_ASSESSMENT_FROM_TEMPLATE_SUCCESS',
  PUBLISH_ASSESSMENT_FROM_TEMPLATE_FAILURE: 'PUBLISH_ASSESSMENT_FROM_TEMPLATE_FAILURE',

  ARCHIVE_ASSESSMENT_REQUEST: 'ARCHIVE_ASSESSMENT_REQUEST',
  ARCHIVE_ASSESSMENT_SUCCESS: 'ARCHIVE_ASSESSMENT_SUCCESS',
  ARCHIVE_ASSESSMENT_FAILURE: 'ARCHIVE_ASSESSMENT_FAILURE',

  RESTORE_ASSESSMENT_REQUEST: 'RESTORE_ASSESSMENT_REQUEST',
  RESTORE_ASSESSMENT_SUCCESS: 'RESTORE_ASSESSMENT_SUCCESS',
  RESTORE_ASSESSMENT_FAILURE: 'RESTORE_ASSESSMENT_FAILURE',

  DUPLICATE_ASSESSMENT_REQUEST: 'DUPLICATE_ASSESSMENT_REQUEST',
  DUPLICATE_ASSESSMENT_SUCCESS: 'DUPLICATE_ASSESSMENT_SUCCESS',
  DUPLICATE_ASSESSMENT_FAILURE: 'DUPLICATE_ASSESSMENT_FAILURE',

  CREATE_NEW_ASSESSMENT_REQUEST: 'CREATE_NEW_ASSESSMENT_REQUEST',
  CREATE_NEW_ASSESSMENT_SUCCESS: 'CREATE_NEW_ASSESSMENT_SUCCESS',
  CREATE_NEW_ASSESSMENT_FAILURE: 'CREATE_NEW_ASSESSMENT_FAILURE',

  UPDATE_ASSESSMENT_REQUEST: 'UPDATE_ASSESSMENT_REQUEST',
  UPDATE_ASSESSMENT_SUCCESS: 'UPDATE_ASSESSMENT_SUCCESS',
  UPDATE_ASSESSMENT_FAILURE: 'UPDATE_ASSESSMENT_FAILURE',

  INVITE_CANDIDATE_REQUEST: 'INVITE_CANDIDATE_REQUEST',
  INVITE_CANDIDATE_SUCCESS: 'INVITE_CANDIDATE_SUCCESS',
  INVITE_CANDIDATE_FAILURE: 'UINVITE_CANDIDATE_FAILURE',

  GET_RECOMMENDED_TEMPLATES_REQUEST: 'GET_RECOMMENDED_TEMPLATES_REQUEST',
  GET_RECOMMENDED_TEMPLATES_SUCCESS: 'GET_RECOMMENDED_TEMPLATES_SUCCESS',
  GET_RECOMMENDED_TEMPLATES_FAILURE: 'GET_RECOMMENDED_TEMPLATES_FAILURE',

  GET_EVALUATE_QUESTIONS_REQUEST: 'GET_EVALUATE_QUESTIONS_REQUEST',
  GET_EVALUATE_QUESTIONS_SUCCESS: 'GET_EVALUATE_QUESTIONS_SUCCESS',
  GET_EVALUATE_QUESTIONS_FAILURE: 'GET_EVALUATE_QUESTIONS_FAILURE',

  DELETE_ASSESSMENT_REQUEST: 'DELETE_ASSESSMENT_REQUEST',
  DELETE_ASSESSMENT_SUCCESS: 'DELETE_ASSESSMENT_SUCCESS',
  DELETE_ASSESSMENT_FAILURE: 'DELETE_ASSESSMENT_FAILURE',

  //ACTION FOR DEV - NO BACKEND ===================
  ADD_TEST_TO_ASSESSMENT: 'ADD_TEST_TO_ASSESSMENT',
  REMOVE_TEST_FROM_ASSESSMENT: 'REMOVE_TEST_FROM_ASSESSMENT',
  UPDATE_TESTS: 'UPDATE_TESTS',
  CLEAR_ASSESSMENT_STORE: 'CLEAR_ASSESSMENT_STORE',

  CLEAR_ASSESSMENTS_PAGINATION: 'CLEAR_ASSESSMENTS_PAGINATION',
  CLEAR_ASSESSMENTS: 'CLEAR_ASSESSMENTS',
  CLEAR_EVALUATE_QUESTIONS: 'CLEAR_EVALUATE_QUESTIONS',

  EDIT_ASSESSMENT: 'EDIT_ASSESSMENT',
  UPDATE_ASSESSMENT: 'UPDATE_ASSESSMENT',
  RESET_ASSESSMENT_DATA: 'RESET_ASSESSMENT_DATA',
  CHANGE_TEMPLATE_DATA: 'CHANGE_TEMPLATE_DATA',
  SET_CUSTOM_JOB_ROLE: 'SET_CUSTOM_JOB_ROLE',

  ADD_CUSTOM_QUESTION: 'ADD_CUSTOM_QUESTION',
  UPDATE_CUSTOM_QUESTION: 'UPDATE_CUSTOM_QUESTION',
  DELETE_CUSTOM_QUESTION: 'DELETE_CUSTOM_QUESTION',
  DUPLICATE_CUSTOM_QUESTION: 'DUPLICATE_CUSTOM_QUESTION',
  SWAP_CUSTOM_QUESTION: 'SWAP_CUSTOM_QUESTION',

  ADD_ASSESSMENT_QUESTION: 'ADD_ASSESSMENT_QUESTION',
  DUBLICATE_ASSESSMENT_QUESTION: 'DUBLICATE_ASSESSMENT_QUESTION',
  DELETE_ASSESSMENT_QUESTION: 'DELETE_ASSESSMENT_QUESTION',
  MOVE_UP_ASSESSMENT_QUESTION: 'MOVE_UP_ASSESSMENT_QUESTION',
  MOVE_DOWN_ASSESSMENT_QUESTION: 'MOVE_DOWN_ASSESSMENT_QUESTION',
  SET_ASSESSMENT_ANTICHEAT: 'SET_ASSESSMENT_ANTICHEAT'
  //===============================================
};
export const assessmentResetErrors = createAction(assessmentConstants.RESET_ERRORS);

export const getRecommendedTemplatesRequest = createAction(
  assessmentConstants.GET_RECOMMENDED_TEMPLATES_REQUEST,
  action => action
);
export const getRecommendedTemplatesSuccess = createAction(
  assessmentConstants.GET_RECOMMENDED_TEMPLATES_SUCCESS,
  assessmentsData => assessmentsData
);
export const getRecommendedTemplatesFailure = createAction(
  assessmentConstants.GET_RECOMMENDED_TEMPLATES_FAILURE,
  errors => errors
);

export const getAllAssessmetsRequest = createAction(
  assessmentConstants.GET_ALL_ASSESSMENTS_REQUEST,
  action => action
);
export const getAllAssessmetsSuccess = createAction(
  assessmentConstants.GET_ALL_ASSESSMENTS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getAllAssessmetsFailure = createAction(
  assessmentConstants.GET_ALL_ASSESSMENTS_FAILURE,
  errors => errors
);

export const publishAssessmentWithoutConfirmation = createAction(
  assessmentConstants.PUBLISH_ASSESSMENT_WITHOUT_CONFIRMATION_REQUEST,
  action => action
);

export const getPublishedAssessmetsRequest = createAction(
  assessmentConstants.GET_PUBLISHED_ASSESSMENTS_REQUEST,
  action => action
);
export const getPublishedAssessmetsSuccess = createAction(
  assessmentConstants.GET_PUBLISHED_ASSESSMENTS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getPublishedAssessmetsFailure = createAction(
  assessmentConstants.GET_PUBLISHED_ASSESSMENTS_FAILURE,
  errors => errors
);

export const getPublishedDemoAssessmetsRequest = createAction(
  assessmentConstants.GET_PUBLISHED_DEMO_ASSESSMENTS_REQUEST,
  action => action
);
export const getPublishedDemoAssessmetsSuccess = createAction(
  assessmentConstants.GET_PUBLISHED_DEMO_ASSESSMENTS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getPublishedDemoAssessmetsFailure = createAction(
  assessmentConstants.GET_PUBLISHED_DEMO_ASSESSMENTS_FAILURE,
  errors => errors
);

export const getPublicDemoAssessmetsRequest = createAction(
  assessmentConstants.GET_PUBLIC_DEMO_ASSESSMENTS_REQUEST,
  action => action
);
export const getPublicDemoAssessmetsSuccess = createAction(
  assessmentConstants.GET_PUBLIC_DEMO_ASSESSMENTS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getPublicDemoAssessmetsFailure = createAction(
  assessmentConstants.GET_PUBLIC_DEMO_ASSESSMENTS_FAILURE,
  errors => errors
);

export const getPublishedAssessmentsNamesRequest = createAction(
  assessmentConstants.GET_PUBLISHED_ASSESSMENTS_NAMES_REQUEST,
  action => action
);
export const getPublishedAssessmentsNamesSuccess = createAction(
  assessmentConstants.GET_PUBLISHED_ASSESSMENTS_NAMES_SUCCESS,
  assessmentsData => assessmentsData
);
export const getPublishedAssessmentsNamesFailure = createAction(
  assessmentConstants.GET_PUBLISHED_ASSESSMENTS_NAMES_FAILURE,
  errors => errors
);

export const getArchivedAssessmetsRequest = createAction(
  assessmentConstants.GET_ARCHIVED_ASSESSMENTS_REQUEST,
  action => action
);
export const getArchivedAssessmetsSuccess = createAction(
  assessmentConstants.GET_ARCHIVED_ASSESSMENTS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getArchivedAssessmetsFailure = createAction(
  assessmentConstants.GET_ARCHIVED_ASSESSMENTS_FAILURE,
  errors => errors
);

export const getArchivedDemoAssessmetsRequest = createAction(
  assessmentConstants.GET_ARCHIVED_DEMO_ASSESSMENTS_REQUEST,
  action => action
);
export const getArchivedDemoAssessmetsSuccess = createAction(
  assessmentConstants.GET_ARCHIVED_DEMO_ASSESSMENTS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getArchivedDemoAssessmetsFailure = createAction(
  assessmentConstants.GET_ARCHIVED_DEMO_ASSESSMENTS_FAILURE,
  errors => errors
);

export const getDraftAssessmetsRequest = createAction(
  assessmentConstants.GET_DRAFT_ASSESSMENTS_REQUEST,
  action => action
);
export const getDraftAssessmetsSuccess = createAction(
  assessmentConstants.GET_DRAFT_ASSESSMENTS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getDraftAssessmetsFailure = createAction(
  assessmentConstants.GET_DRAFT_ASSESSMENTS_FAILURE,
  errors => errors
);

export const getAssessmetRequest = createAction(
  assessmentConstants.GET_ASSESSMENT_REQUEST,
  action => action
);
export const getAssessmetSuccess = createAction(
  assessmentConstants.GET_ASSESSMENT_SUCCESS,
  assessmentsData => assessmentsData
);
export const getAssessmetFailure = createAction(
  assessmentConstants.GET_ASSESSMENT_FAILURE,
  errors => errors
);

export const getAssessmentApplicationsRequest = createAction(
  assessmentConstants.GET_ASSESSMENT_APPLICATIONS_REQUEST,
  action => action
);
export const getAssessmentApplicationsSuccess = createAction(
  assessmentConstants.GET_ASSESSMENT_APPLICATIONS_SUCCESS,
  assessmentsData => assessmentsData
);
export const getAssessmentApplicationsFailure = createAction(
  assessmentConstants.GET_ASSESSMENT_APPLICATIONS_FAILURE,
  errors => errors
);

export const getAssessmentGenericTemplatesRequest = createAction(
  assessmentConstants.GET_ASSESSMENT_GENERIC_TEMPLATES_REQUEST,
  action => action
);
export const getAssessmentGenericTemplatesSuccess = createAction(
  assessmentConstants.GET_ASSESSMENT_GENERIC_TEMPLATES_SUCCESS,
  assessmentsData => assessmentsData
);
export const getAssessmentGenericTemplatesFailure = createAction(
  assessmentConstants.GET_ASSESSMENT_GENERIC_TEMPLATES_FAILURE,
  errors => errors
);

export const publishAssessmentRequest = createAction(
  assessmentConstants.PUBLISH_ASSESSMENT_REQUEST,
  action => action
);
export const publishAssessmentSuccess = createAction(
  assessmentConstants.PUBLISH_ASSESSMENT_SUCCESS,
  assessmentsData => assessmentsData
);
export const publishAssessmentFailure = createAction(
  assessmentConstants.PUBLISH_ASSESSMENT_FAILURE,
  errors => errors
);

export const getLastPublishAssessmentRequest = createAction(
  assessmentConstants.GET_LAST_PUBLISH_ASSESSMENT_REQUEST,
  action => action
);
export const getLastPublishAssessmentSuccess = createAction(
  assessmentConstants.GET_LAST_PUBLISH_ASSESSMENT_SUCCESS,
  assessmentsData => assessmentsData
);
export const getLastPublishAssessmentFailure = createAction(
  assessmentConstants.GET_LAST_PUBLISH_ASSESSMENT_FAILURE,
  errors => errors
);

export const publishAssessmentFromTemplateRequest = createAction(
  assessmentConstants.PUBLISH_ASSESSMENT_FROM_TEMPLATE_REQUEST,
  action => action
);
export const publishAssessmentFromTemplateSuccess = createAction(
  assessmentConstants.PUBLISH_ASSESSMENT_FROM_TEMPLATE_SUCCESS,
  assessmentsData => assessmentsData
);
export const publishAssessmentFromTemplateFailure = createAction(
  assessmentConstants.PUBLISH_ASSESSMENT_FROM_TEMPLATE_FAILURE,
  errors => errors
);

export const archiveAssessmentRequest = createAction(
  assessmentConstants.ARCHIVE_ASSESSMENT_REQUEST,
  action => action
);
export const archiveAssessmentSuccess = createAction(
  assessmentConstants.ARCHIVE_ASSESSMENT_SUCCESS,
  assessmentsData => assessmentsData
);
export const archiveAssessmentFailure = createAction(
  assessmentConstants.ARCHIVE_ASSESSMENT_FAILURE,
  errors => errors
);

export const restoreAssessmentRequest = createAction(
  assessmentConstants.RESTORE_ASSESSMENT_REQUEST,
  action => action
);
export const restoreAssessmentSuccess = createAction(
  assessmentConstants.RESTORE_ASSESSMENT_SUCCESS,
  assessmentsData => assessmentsData
);
export const restoreAssessmentFailure = createAction(
  assessmentConstants.RESTORE_ASSESSMENT_FAILURE,
  errors => errors
);

export const duplicateAssessmentRequest = createAction(
  assessmentConstants.DUPLICATE_ASSESSMENT_REQUEST,
  action => action
);
export const duplicateAssessmentSuccess = createAction(
  assessmentConstants.DUPLICATE_ASSESSMENT_SUCCESS,
  assessmentsData => assessmentsData
);
export const duplicateAssessmentFailure = createAction(
  assessmentConstants.DUPLICATE_ASSESSMENT_FAILURE,
  errors => errors
);

export const createNewAssessmentRequest = createAction(
  assessmentConstants.CREATE_NEW_ASSESSMENT_REQUEST,
  assessmentsData => assessmentsData
);
export const createNewAssessmentSuccess = createAction(
  assessmentConstants.CREATE_NEW_ASSESSMENT_SUCCESS,
  action => action
);
export const createNewAssessmentFailure = createAction(
  assessmentConstants.CREATE_NEW_ASSESSMENT_FAILURE,
  errors => errors
);

export const deleteAssessmentRequest = createAction(
  assessmentConstants.DELETE_ASSESSMENT_REQUEST,
  assessmentsData => assessmentsData
);
export const deleteAssessmentSuccess = createAction(
  assessmentConstants.DELETE_ASSESSMENT_SUCCESS,
  action => action
);
export const deleteAssessmentFailure = createAction(
  assessmentConstants.DELETE_ASSESSMENT_FAILURE,
  errors => errors
);

export const updateAssessmentRequest = createAction(
  assessmentConstants.UPDATE_ASSESSMENT_REQUEST,
  data => data
);
export const updateAssessmentSuccess = createAction(assessmentConstants.UPDATE_ASSESSMENT_SUCCESS);
export const updateAssessmentFailure = createAction(
  assessmentConstants.UPDATE_ASSESSMENT_FAILURE,
  errors => errors
);

export const inviteCandidateRequest = createAction(
  assessmentConstants.INVITE_CANDIDATE_REQUEST,
  data => data
);
export const inviteCandidateSuccess = createAction(assessmentConstants.INVITE_CANDIDATE_SUCCESS);
export const inviteCandidateFailure = createAction(
  assessmentConstants.INVITE_CANDIDATE_FAILURE,
  errors => errors
);

export const getEvaluateQuestionsRequest = createAction(
  assessmentConstants.GET_EVALUATE_QUESTIONS_REQUEST,
  data => data
);
export const getEvaluateQuestionsSuccess = createAction(
  assessmentConstants.GET_EVALUATE_QUESTIONS_SUCCESS
);
export const getEvaluateQuestionsFailure = createAction(
  assessmentConstants.GET_EVALUATE_QUESTIONS_FAILURE,
  errors => errors
);

export const addCustomQuestion = createAction(
  assessmentConstants.ADD_CUSTOM_QUESTION,
  data => data
);

export const updateCustomQuestion = createAction(
  assessmentConstants.UPDATE_CUSTOM_QUESTION,
  data => data
);

export const deleteCustomQuestion = createAction(
  assessmentConstants.DELETE_CUSTOM_QUESTION,
  data => data
);

export const duplicateCustomQuestion = createAction(
  assessmentConstants.DUPLICATE_CUSTOM_QUESTION,
  data => data
);

export const swapCustomQuestion = createAction(
  assessmentConstants.SWAP_CUSTOM_QUESTION,
  data => data
);

//ACTION FOR DEV - NO BACKEND ===================
export const addTestsToAssessment = createAction(
  assessmentConstants.ADD_TEST_TO_ASSESSMENT,
  test => test
);
export const removeTestsFromAssessment = createAction(
  assessmentConstants.REMOVE_TEST_FROM_ASSESSMENT,
  id => id
);
export const editAssessment = createAction(
  assessmentConstants.EDIT_ASSESSMENT,
  editAssessmetnData => editAssessmetnData
);
export const setCustomJobRole = createAction(
  assessmentConstants.SET_CUSTOM_JOB_ROLE,
  editAssessmetnData => editAssessmetnData
);
export const updateAssessment = createAction(
  assessmentConstants.UPDATE_ASSESSMENT,
  newAssessmentData => newAssessmentData
);
export const resetAssessment = createAction(assessmentConstants.RESET_ASSESSMENT_DATA);

export const changeTemplateData = createAction(assessmentConstants.CHANGE_TEMPLATE_DATA);

export const clearAssessmentsPagination = createAction(
  assessmentConstants.CLEAR_ASSESSMENTS_PAGINATION
);

export const clearAssessments = createAction(assessmentConstants.CLEAR_ASSESSMENTS);

export const clearAssessmentStore = createAction(assessmentConstants.CLEAR_ASSESSMENT_STORE);

export const clearEvaluateQuestions = createAction(assessmentConstants.CLEAR_EVALUATE_QUESTIONS);

export const updateTests = createAction(assessmentConstants.UPDATE_TESTS);

export const addAssessmentQuestion = createAction(
  assessmentConstants.ADD_ASSESSMENT_QUESTION,
  questionData => questionData
);
export const dublicateAssessmentQuestion = createAction(
  assessmentConstants.DUBLICATE_ASSESSMENT_QUESTION,
  questionId => questionId
);
export const deleteAssessmentQuestion = createAction(
  assessmentConstants.DELETE_ASSESSMENT_QUESTION,
  questionId => questionId
);
export const moveUpAssessmentQuestion = createAction(
  assessmentConstants.MOVE_UP_ASSESSMENT_QUESTION,
  questionId => questionId
);
export const moveDownAssessmentQuestion = createAction(
  assessmentConstants.MOVE_DOWN_ASSESSMENT_QUESTION,
  questionId => questionId
);
export const setAssessmentAntiCheat = createAction(
  assessmentConstants.SET_ASSESSMENT_ANTICHEAT,
  data => data
);
//==================================
