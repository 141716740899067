import { Button, Form, Icon, Input, Modal, Typography } from 'ui-kit';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';
import { clearTests, getCurrentTestRequest, getPublishedTestRequest } from 'store/tests/actions';
import { useDebouceCallback } from 'hooks';
import { TestCardSmall } from 'components/TestCardSmall/TestCardSmall';
import { ViewTestSummary } from 'pages/Author/Tests/Test/ViewTest/ViewTest';
import { getAllTestsFamiliesRequest } from 'store/admin/actions';
import { getTestCategoriesRequest } from 'store/configuration/actions/subscriptions';
import SelectTree from 'ui-kit/SelectTree/SelectTree';
import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons';

const TestLibraryModal = ({ isModalOpen, setModalOpen, onClick, tests, mapping, type = '' }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useState({ perPage: -1 });
  const { loading, testCategories } = useSelector(store => store.subscriptions);
  const { currentTest, loading: load } = useSelector(store => store.tests);

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }
    !type && dispatch(getTestCategoriesRequest());
    type &&
      setParams(prev => {
        return { ...prev, type: type.value };
      });
  }, [isModalOpen, type]);

  useEffect(() => {
    if (tests?.length === 0 || !isModalOpen) {
      return;
    }
    dispatch(getCurrentTestRequest(tests?.[0]?._id));
  }, [tests, isModalOpen]);

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }
    if (mapping) {
      dispatch(
        getAllTestsFamiliesRequest({
          sortBy: 'name',
          sortOrder: 1,
          ...params
        })
      );
    } else {
      dispatch(clearTests());
      dispatch(
        getPublishedTestRequest({
          sortBy: 'name',
          sortOrder: 1,
          ...params
        })
      );
    }
  }, [params, isModalOpen, mapping]);

  const debounceSubmit = useDebouceCallback(value =>
    setParams(prev => {
      return { ...prev, ...value, perPage: -1 };
    })
  );

  const previewTestHandler = id => {
    dispatch(getCurrentTestRequest(id));
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        setParams({ perPage: -1 });
        setModalOpen();
      }}
      size="lg">
      {(loading || load) && <Loader />}
      <Modal.Header className="px-6">
        <Typography variant="heading-h2" title="Tests Library" />
        <Button
          icon={faXmark}
          iconSize={20}
          iconColor="#5C5F62"
          onClick={() => {
            setParams({ perPage: -1 });
            setModalOpen();
          }}
        />
      </Modal.Header>
      <Modal.Body className="m-0 flex h-[500px] p-0">
        <div className="flex flex-1 flex-col overflow-auto bg-[#FAFBFB] p-8 pt-0">
          <Form
            className="sticky top-0 w-full bg-[#FAFBFB] pt-8 pb-4"
            defaultValues={{ search: params.search, type: params.type }}>
            {({ handleSubmit }) => (
              <div className="flex w-full flex-col gap-3">
                <Input
                  leftComponent={() => (
                    <Icon className="ml-3" size={16} color="#8C9196" icon={faMagnifyingGlass} />
                  )}
                  name="search"
                  onChangeValue={handleSubmit(debounceSubmit)}
                  placeholder="Search..."
                />
                <SelectTree
                  onChange={data =>
                    handleSubmit(
                      setParams(prev => {
                        return { ...prev, category: data?.value || '', perPage: -1 };
                      })
                    )
                  }
                  name="category"
                  isClearable
                  options={testCategories && testCategories}
                />
              </div>
            )}
          </Form>
          <div className="flex flex-col gap-3">
            {tests?.map(data => (
              <TestCardSmall
                selected={currentTest?._id === data?._id}
                onClick={() => previewTestHandler(data?._id)}
                key={data?._id}
                {...data}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-2 overflow-auto overflow-x-hidden pb-8">
          {currentTest?._id && tests[0] && <ViewTestSummary currentTest={currentTest} />}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end gap-3">
        <Button
          variant="secondary"
          title="Close"
          onClick={() => {
            setParams({ perPage: -1 });
            setModalOpen();
          }}
        />
        {currentTest?._id && tests[0] && (
          <Button
            variant="primary"
            title={`Add ${currentTest?.name}`}
            onClick={() => {
              setParams({ ...params, perPage: -1 });
              onClick();
            }}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default TestLibraryModal;
