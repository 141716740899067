import { put, takeLatest, call } from 'redux-saga/effects';
import { callToast } from 'utils/helpers';
import {
  testConstants,
  getAllTestsSuccess,
  getAllTestsFailure,
  createTestSuccess,
  createTestFailure,
  updateTestSuccess,
  updateTestFailure,
  getCurrentTestSuccess,
  getCurrentTestFailure,
  publishTestFailure,
  publishTestSuccess,
  getPublishedTestFailure,
  getPublishedTestSuccess,
  archiveTestSuccess,
  archiveTestFailure,
  uploadAttachmentSuccess,
  uploadAttachmentFailed,
  getAreasOfUseSuccess,
  getAreasOfUseFailed,
  getSuitableForSuccess,
  getSuitableForFailed,
  getSectionsTagsSuccess,
  getSectionsTagsFailed,
  getDraftTestSuccess,
  getDraftTestFailure,
  getArchivedTestSuccess,
  getArchivedTestFailure,
  createNewVersionTestSuccess,
  createNewVersionTestFailure,
  getTestVerionsSuccess,
  getTestVerionsFailure,
  deleteDraftTestSuccess,
  deleteDraftTestFailure,
  getTestByImportanceSuccess,
  getTestByImportanceFailure,
  getDraftTemplatesSuccess,
  getDraftTemplatesFailure,
  getPublishedTemplatesSuccess,
  getPublishedTemplatesFailure,
  getArchivedTemplatesSuccess,
  getArchivedTemplatesFailure,
  getJobTitlesSuccess,
  getJobTitlesFailure,
  createTemplateFailure,
  createTemplateSuccess,
  getTemplateSuccess,
  getTemplateFailure,
  publishTemplateSuccess,
  publishTemplateFailure,
  archiveTemplateSuccess,
  archiveTemplateFailure,
  editTemplateSuccess,
  editTemplateFailure,
  getJobRolesSuccess,
  getJobRolesFailure,
  draftTemplateSuccess,
  draftTemplateFailure,
  testRequestSuccess,
  testRequestFailure
} from 'store/tests/actions';
import * as routes from 'routes/routes';
import { authorApi, commonApi, employerApi } from 'api/appApi';

function* createTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.createTest(payload.data.test);
    callToast('success', 'Success - test was created');
    yield put(createTestSuccess(data));
    yield call(() => {
      payload.preview && payload.navigate(routes.TESTS + `/${data._id}` + routes.PUBLISH);
      !payload.preview &&
        !payload.confirm &&
        payload.navigate(routes.TESTS + `/${data._id}` + routes.EDIT);
    });
  } catch (error) {
    callToast('error', 'Error - error in creating test');
    yield put(createTestFailure(error.response.status));
    yield call(() => payload.navigate(routes.TESTS));
  }
}

function* testRequestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield employerApi.testRequest(payload);
    yield put(testRequestSuccess(data));
  } catch (error) {
    yield put(testRequestFailure(error.response.status));
  }
}

function* createNewVersionTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.createNewVersionTest(payload.id);
    yield put(createNewVersionTestSuccess(data));
    yield call(() => {
      payload.navigate(routes.TESTS + `/${data._id}` + routes.EDIT);
    });
  } catch (error) {
    callToast('error', 'Error - new version was not created');
    yield put(createNewVersionTestFailure(error.response.status));
  }
}

function* getTestVersionsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getTestsVersionsTest(payload);
    yield put(getTestVerionsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Cannot get current test versions');
    yield put(getTestVerionsFailure(error.response.status));
  }
}

function* updateTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.updateTest(payload);
    yield put(updateTestSuccess(data));
    callToast('success', 'Success - test was updated');
    yield call(
      () => payload.preview && payload.navigate(routes.TESTS + `/${data._id}` + routes.PUBLISH)
    );
  } catch (error) {
    callToast('error', 'Error - test was not updated');
    yield put(updateTestFailure(error.response.status));
  }
}

function* getAllTestsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getPublishedTests(payload);
    yield put(getAllTestsSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting tests');
    yield put(getAllTestsFailure(error.response.status));
  }
}

function* getTestsByImportanceSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getTestsByImportance(payload);
    yield put(getTestByImportanceSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting tests');
    yield put(getTestByImportanceFailure(error.response.status));
  }
}

function* publishTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.updateTest(payload);
    yield put(publishTestSuccess(data));
    callToast('success', 'Success - test was published');
    yield call(() => payload.navigate(routes.TESTS + '/' + data._id));
  } catch (error) {
    callToast('error', 'Error - test was not published');
    yield put(publishTestFailure(error.response.status));
    yield call(() => payload.navigate(routes.TESTS));
  }
}

function* archiveTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.updateTest(payload);
    yield put(archiveTestSuccess(data));
    callToast('success', 'Success - test was archived');
    yield call(() => payload.navigate(routes.TESTS));
  } catch (error) {
    callToast('error', 'Error - test was not archived');
    yield put(archiveTestFailure(error.response.status));
    yield call(() => payload.navigate(routes.TESTS));
  }
}

function* getPublishedTestsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getPublishedTests(payload);

    yield put(getPublishedTestSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting test');
    yield put(getPublishedTestFailure(error.response.status));
  }
}

function* getDraftTestsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getDraftTests(payload);

    yield put(getDraftTestSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting test');
    yield put(getDraftTestFailure(error.response.status));
  }
}

function* deleteDraftTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.deleteDraftTest(payload.id);

    yield put(deleteDraftTestSuccess(data));
    callToast('success', 'Draft discarded successfully');
    payload.navigate(data ? routes.TESTS + `/${data?._id}` : routes.TESTS);
  } catch (error) {
    callToast('error', 'Error in discarding draft');
    yield put(deleteDraftTestFailure(error.response.status));
  }
}

function* getArchivedTestsSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getArchivedTests(payload);

    yield put(getArchivedTestSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting test');
    yield put(getArchivedTestFailure(error.response.status));
  }
}

function* getCurrentTestSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getCurrentTest(payload);
    yield put(getCurrentTestSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting test');
    yield put(getCurrentTestFailure(error.response.status));
  }
}

function* createTemplateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.createTemplate(payload.data);

    yield put(createTemplateSuccess(data));
    callToast('success', 'Template was created') &&
      payload.navigate(routes.ASSESSMENT_TEMPLATES + '/' + data?._id + routes.EDIT);
  } catch (error) {
    callToast('error', 'Error - error in creating template');
    yield put(createTemplateFailure(error.response.status));
  }
}

function* editTemplateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.editTemplate(payload);

    yield put(editTemplateSuccess(data));
    callToast('success', 'Template was saved');
  } catch (error) {
    callToast('error', 'Error - error in editing template');
    yield put(editTemplateFailure(error.response.status));
  }
}

function* publishTemplateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.publishTemplate(payload.id);

    yield put(publishTemplateSuccess(data));
    callToast('success', 'Template was published');
    payload.navigate(routes.ASSESSMENT_TEMPLATES + '/' + data?._id);
  } catch (error) {
    callToast('error', 'Error - error in publishing template');
    yield put(publishTemplateFailure(error.response.status));
  }
}

function* archiveTemplateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.archiveTemplate(payload);

    yield put(archiveTemplateSuccess(data));
    callToast('success', 'Template was archived');
  } catch (error) {
    callToast('error', 'Error - error in archiving template');
    yield put(archiveTemplateFailure(error.response.status));
  }
}

function* draftTemplateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.draftTemplate(payload);

    yield put(draftTemplateSuccess(data));
    callToast('success', 'Template status was changed to draft');
  } catch (error) {
    callToast('error', 'Error - error in changing status of template');
    yield put(draftTemplateFailure(error.response.status));
  }
}

function* getTemplateSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.getSingleTemplate(payload);

    yield put(getTemplateSuccess(data));
  } catch (error) {
    yield put(getTemplateFailure(error.response.status));
  }
}

function* getDraftTemplatesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.getDraftTemplates(payload);

    yield put(getDraftTemplatesSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting templates');
    yield put(getDraftTemplatesFailure(error.response.status));
  }
}

function* getPublishedTemplatesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.getPublishedTemplates(payload);

    yield put(getPublishedTemplatesSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting templates');
    yield put(getPublishedTemplatesFailure(error.response.status));
  }
}

function* getArchivedTemplatesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authorApi.getArchivedTemplates(payload);

    yield put(getArchivedTemplatesSuccess(data));
  } catch (error) {
    callToast('error', 'Error - error in getting test');
    yield put(getArchivedTemplatesFailure(error.response.status));
  }
}

// TODO: useless saga ? (NOT USELESS, just don't render error component on async uploading attachment ???)
function* uploadAttachment(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.uploadAttachment(payload.formData);
    payload.onSuccess(data);
    yield put(uploadAttachmentSuccess(data.attachmentUrl));
  } catch (error) {
    yield put(uploadAttachmentFailed(error.response.status));
    callToast(error, 'failed upload attachment');
  }
}

function* getAreasOfUseSaga() {
  try {
    const { data } = yield commonApi.getAreasOfUse();
    yield put(getAreasOfUseSuccess(data));
  } catch (error) {
    yield put(getAreasOfUseFailed(error.response.status));
  }
}

function* getJobTitlesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getJobTitles(payload);
    yield put(getJobTitlesSuccess(data));
  } catch (error) {
    yield put(getJobTitlesFailure(error.response.status));
  }
}

function* getJobRolesSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.getJobRoles(payload);
    yield put(getJobRolesSuccess(data));
  } catch (error) {
    yield put(getJobRolesFailure(error.response.status));
  }
}

function* getSuitableForSaga() {
  try {
    const { data } = yield commonApi.getSuitableFor();
    yield put(getSuitableForSuccess(data));
  } catch (error) {
    yield put(getSuitableForFailed(error.response.status));
  }
}

function* getSectionsTagsSaga() {
  try {
    const { data } = yield commonApi.getSectionsTags();
    yield put(getSectionsTagsSuccess(data));
  } catch (error) {
    yield put(getSectionsTagsFailed(error.response.status));
  }
}

export default function* testsWatcher() {
  yield takeLatest(testConstants.GET_CURRENT_TEST_REQUEST, getCurrentTestSaga);
  yield takeLatest(testConstants.GET_ALL_TESTS_REQUEST, getAllTestsSaga);
  yield takeLatest(testConstants.CREATE_TEST_REQUEST, createTestSaga);
  yield takeLatest(testConstants.UPDATE_TEST_REQUEST, updateTestSaga);
  yield takeLatest(testConstants.GET_PUBLISHED_TESTS_REQUEST, getPublishedTestsSaga);
  yield takeLatest(testConstants.GET_DRAFT_TESTS_REQUEST, getDraftTestsSaga);
  yield takeLatest(testConstants.GET_ARCHIVED_TESTS_REQUEST, getArchivedTestsSaga);
  yield takeLatest(testConstants.PUBLISH_TEST_REQUEST, publishTestSaga);
  yield takeLatest(testConstants.ARCHIVE_TEST_REQUEST, archiveTestSaga);
  yield takeLatest(testConstants.UPLOAD_ATTACHMENT_REQUEST, uploadAttachment);
  yield takeLatest(testConstants.GET_AREAS_OF_USE_REQUEST, getAreasOfUseSaga);
  yield takeLatest(testConstants.GET_SUITABLE_FOR_REQUEST, getSuitableForSaga);
  yield takeLatest(testConstants.GET_SECTIONS_TAGS_REQUEST, getSectionsTagsSaga);
  yield takeLatest(testConstants.CREATE_NEW_VERSION_TEST_REQUEST, createNewVersionTestSaga);
  yield takeLatest(testConstants.GET_TEST_VERSIONS_REQUEST, getTestVersionsSaga);
  yield takeLatest(testConstants.DELETE_DRAFT_TEST_REQUEST, deleteDraftTestSaga);
  yield takeLatest(testConstants.GET_TESTS_BY_IMPORTANCE_REQUEST, getTestsByImportanceSaga);
  yield takeLatest(testConstants.GET_DRAFT_TEMPLATES_REQUEST, getDraftTemplatesSaga);
  yield takeLatest(testConstants.GET_PUBLISHED_TEMPLATES_REQUEST, getPublishedTemplatesSaga);
  yield takeLatest(testConstants.GET_ARCHIVED_TEMPLATES_REQUEST, getArchivedTemplatesSaga);
  yield takeLatest(testConstants.GET_JOB_TITLES_REQUEST, getJobTitlesSaga);
  yield takeLatest(testConstants.CREATE_TEMPLATE_REQUEST, createTemplateSaga);
  yield takeLatest(testConstants.GET_TEMPLATE_REQUEST, getTemplateSaga);
  yield takeLatest(testConstants.PUBLISH_TEMPLATE_REQUEST, publishTemplateSaga);
  yield takeLatest(testConstants.ARCHIVE_TEMPLATE_REQUEST, archiveTemplateSaga);
  yield takeLatest(testConstants.EDIT_TEMPLATE_REQUEST, editTemplateSaga);
  yield takeLatest(testConstants.GET_JOB_ROLES_REQUEST, getJobRolesSaga);
  yield takeLatest(testConstants.DRAFT_TEMPLATE_REQUEST, draftTemplateSaga);
  yield takeLatest(testConstants.TEST_REQUEST_REQUEST, testRequestSaga);
}
