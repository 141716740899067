import { createAction } from 'redux-actions';

export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT: 'LOGOUT',

  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',

  RESEND_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
  RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
  RESEND_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',

  VERIFY_EMAIL_REQUEST: 'RESEND_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILURE: 'RESEND_EMAIL_FAILURE',

  PASSWORD_RECOVERY_REQUEST: 'PASSWORD_RECOVERY_REQUEST',
  PASSWORD_RECOVERY_SUCCESS: 'PASSWORD_RECOVERY_SUCCESS',
  PASSWORD_RECOVERY_FAILURE: 'PASSWORD_RECOVERY_FAILURE',

  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  PROFILE_COMPLETE_REQUEST: 'PROFILE_COMPLETE_REQUEST',
  PROFILE_COMPLETE_SUCCESS: 'PROFILE_COMPLETE_SUCCESS',
  PROFILE_COMPLETE_FAILURE: 'PROFILE_COMPLETE_FAILURE',

  UPDATE_ADMIN_PROFILE_REQUEST: 'UPDATE_ADMIN_PROFILE_REQUEST',
  UPDATE_ADMIN_PROFILE_SUCCESS: 'UPDATE_ADMIN_PROFILE_SUCCESS',
  UPDATE_ADMIN_PROFILE_FAILURE: 'UPDATE_ADMIN_PROFILE_FAILURE',

  UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',

  UPDATE_PROFILE_IMAGE_REQUEST: 'UPDATE_PROFILE_IMAGE_REQUEST',
  UPDATE_PROFILE_IMAGE_SUCCESS: 'UPDATE_PROFILE_IMAGE_SUCCESS',
  UPDATE_PROFILE_IMAGE_FAILURE: 'UPDATE_PROFILE_IMAGE_FAILURE',

  UPDATE_COMPANY_IMAGE_REQUEST: 'UPDATE_COMPANY_IMAGE_REQUEST',
  UPDATE_COMPANY_IMAGE_SUCCESS: 'UPDATE_COMPANY_IMAGE_SUCCESS',
  UPDATE_COMPANY_IMAGE_FAILURE: 'UPDATE_COMPANY_IMAGE_FAILURE',

  DELETE_PROFILE_IMAGE_REQUEST: 'DELETE_PROFILE_IMAGE_REQUEST',
  DELETE_PROFILE_IMAGE_SUCCESS: 'DELETE_PROFILE_IMAGE_SUCCESS',
  DELETE_PROFILE_IMAGE_FAILURE: 'DELETE_PROFILE_IMAGE_FAILURE',

  DELETE_COMPANY_IMAGE_REQUEST: 'DELETE_COMPANY_IMAGE_REQUEST',
  DELETE_COMPANY_IMAGE_SUCCESS: 'DELETE_COMPANY_IMAGE_SUCCESS',
  DELETE_COMPANY_IMAGE_FAILURE: 'DELETE_COMPANY_IMAGE_FAILURE',

  UPDATE_PROFILE_EMAIL_REQUEST: 'UPDATE_PROFILE_EMAIL_REQUEST',
  UPDATE_PROFILE_EMAIL_SUCCESS: 'UPDATE_PROFILE_EMAIL_SUCCESS',
  UPDATE_PROFILE_EMAIL_FAILURE: 'UPDATE_PROFILE_EMAIL_FAILURE',

  UPDATE_PROFILE_PASSWORD_REQUEST: 'UPDATE_PROFILE_PASSWORD_REQUEST',
  UPDATE_PROFILE_PASSWORD_SUCCESS: 'UPDATE_PROFILE_PASSWORD_SUCCESS',
  UPDATE_PROFILE_PASSWORD_FAILURE: 'UPDATE_PROFILE_PASSWORD_FAILURE',

  NEW_PASSWORD_REQUEST: 'NEW_PASSWORD_REQUEST',
  NEW_PASSWORD_SUCCESS: 'NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_FAILURE: 'NEW_PASSWORD_FAILURE',

  REQUEST_ACCESS_REQUEST: 'REQUEST_ACCESS_REQUEST',
  REQUEST_ACCESS_SUCCESS: 'REQUEST_ACCESS_SUCCESS',
  REQUEST_ACCESS_FAILURE: 'REQUEST_ACCESS_FAILURE',

  GET_COUNTRIES_REQUEST: 'GET_COUNTRIES_REQUEST',
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',

  RESET_AUTH_ERROR: 'RESET_AUTH_ERROR',

  SET_SHOW_ONBOARD: 'SET_SHOW_ONBOARD',

  SET_UPGRADE_MODAL_OPEN: 'SET_UPGRADE_MODAL_OPEN',

  SET_SUCCESS_MODAL_OPEN: 'SET_SUCCESS_MODAL_OPEN',

  SAVE_USER_DATA: 'SAVE_USER_DATA',
  UPDATE_USER_REWARDS: 'UPDATE_USER_REWARDS',

  GET_FAQ_REQUEST: 'GET_FAQ_REQUEST',
  GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
  GET_FAQ_FAILURE: 'GET_FAQ_FAILURE,',

  GET_VALIDATE_HASH_REQUEST: 'GET_VALIDATE_HASH_REQUEST',

  RESET_ERRORS: 'RESET_ERRORS'
};

export const logInRequest = createAction(authConstants.LOGIN_REQUEST, authData => authData);
export const logInSuccess = createAction(authConstants.LOGIN_SUCCESS, userData => userData);
export const logInFailure = createAction(authConstants.LOGIN_FAILURE, errors => errors);

export const countriesRequest = createAction(
  authConstants.GET_COUNTRIES_REQUEST,
  authData => authData
);
export const countriesSuccess = createAction(
  authConstants.GET_COUNTRIES_SUCCESS,
  userData => userData
);
export const countriesFailure = createAction(authConstants.GET_COUNTRIES_FAILURE, errors => errors);

export const logout = createAction(authConstants.LOGOUT, action => action);

export const getProfileRequest = createAction(authConstants.GET_PROFILE_REQUEST);
export const getProfileSuccess = createAction(
  authConstants.GET_PROFILE_SUCCESS,
  profileData => profileData
);
export const getProfileFailure = createAction(authConstants.GET_PROFILE_FAILURE, errors => errors);

export const getCompanyRequest = createAction(authConstants.GET_COMPANY_REQUEST);
export const getCompanySuccess = createAction(
  authConstants.GET_COMPANY_SUCCESS,
  profileData => profileData
);
export const getCompanyFailure = createAction(authConstants.GET_COMPANY_FAILURE, errors => errors);

export const updateProfileRequest = createAction(authConstants.UPDATE_PROFILE_REQUEST);
export const updateProfileSuccess = createAction(
  authConstants.UPDATE_PROFILE_SUCCESS,
  profileData => profileData
);
export const updateProfileFailure = createAction(
  authConstants.UPDATE_PROFILE_FAILURE,
  errors => errors
);

export const profileCompleteRequest = createAction(authConstants.PROFILE_COMPLETE_REQUEST);
export const profileCompleteSuccess = createAction(
  authConstants.PROFILE_COMPLETE_SUCCESS,
  profileData => profileData
);
export const profileCompleteFailure = createAction(
  authConstants.PROFILE_COMPLETE_FAILURE,
  errors => errors
);

export const updateAdminProfileRequest = createAction(authConstants.UPDATE_ADMIN_PROFILE_REQUEST);
export const updateAdminProfileSuccess = createAction(
  authConstants.UPDATE_ADMIN_PROFILE_SUCCESS,
  profileData => profileData
);
export const updateAdminProfileFailure = createAction(
  authConstants.UPDATE_ADMIN_PROFILE_FAILURE,
  errors => errors
);

export const updateCompanyRequest = createAction(authConstants.UPDATE_COMPANY_REQUEST);
export const updateCompanySuccess = createAction(
  authConstants.UPDATE_COMPANY_SUCCESS,
  companyData => companyData
);
export const updateCompanyFailure = createAction(
  authConstants.UPDATE_COMPANY_FAILURE,
  errors => errors
);

export const updateProfileEmailRequest = createAction(authConstants.UPDATE_PROFILE_EMAIL_REQUEST);
export const updateProfileEmailSuccess = createAction(
  authConstants.UPDATE_PROFILE_EMAIL_SUCCESS,
  profileData => profileData
);
export const updateProfileEmailFailure = createAction(
  authConstants.UPDATE_PROFILE_EMAIL_FAILURE,
  errors => errors
);

export const updateProfileImageRequest = createAction(authConstants.UPDATE_PROFILE_IMAGE_REQUEST);
export const updateProfileImageSuccess = createAction(
  authConstants.UPDATE_PROFILE_IMAGE_SUCCESS,
  profileData => profileData
);
export const updateProfileImageFailure = createAction(
  authConstants.UPDATE_PROFILE_IMAGE_FAILURE,
  errors => errors
);

export const updateCompanyImageRequest = createAction(authConstants.UPDATE_COMPANY_IMAGE_REQUEST);
export const updateCompanyImageSuccess = createAction(
  authConstants.UPDATE_COMPANY_IMAGE_SUCCESS,
  profileData => profileData
);
export const updateCompanyImageFailure = createAction(
  authConstants.UPDATE_COMPANY_IMAGE_FAILURE,
  errors => errors
);

export const deleteProfileImageRequest = createAction(authConstants.DELETE_PROFILE_IMAGE_REQUEST);
export const deleteProfileImageSuccess = createAction(
  authConstants.DELETE_PROFILE_IMAGE_SUCCESS,
  profileData => profileData
);
export const deleteProfileImageFailure = createAction(
  authConstants.DELETE_PROFILE_IMAGE_FAILURE,
  errors => errors
);

export const deleteCompanyImageRequest = createAction(authConstants.DELETE_COMPANY_IMAGE_REQUEST);
export const deleteCompanyImageSuccess = createAction(
  authConstants.DELETE_COMPANY_IMAGE_SUCCESS,
  profileData => profileData
);
export const deleteCompanyImageFailure = createAction(
  authConstants.DELETE_COMPANY_IMAGE_FAILURE,
  errors => errors
);

export const updateProfilePasswordRequest = createAction(
  authConstants.UPDATE_PROFILE_PASSWORD_REQUEST
);
export const updateProfilePasswordSuccess = createAction(
  authConstants.UPDATE_PROFILE_PASSWORD_SUCCESS,
  profileData => profileData
);
export const updateProfilePasswordFailure = createAction(
  authConstants.UPDATE_PROFILE_PASSWORD_FAILURE,
  errors => errors
);

export const registerRequest = createAction(authConstants.REGISTER_REQUEST, authData => authData);
export const registerSuccess = createAction(authConstants.REGISTER_SUCCESS, userData => userData);
export const registerFailure = createAction(authConstants.REGISTER_FAILURE, errors => errors);

export const confirmEmailRequest = createAction(authConstants.CONFIRM_EMAIL_REQUEST);
export const confirmEmailSuccess = createAction(authConstants.CONFIRM_EMAIL_SUCCESS);
export const confirmEmailFailure = createAction(authConstants.CONFIRM_EMAIL_FAILURE);

export const verifyEmailRequest = createAction(authConstants.VERIFY_EMAIL_REQUEST);
export const verifyEmailSuccess = createAction(authConstants.VERIFY_EMAIL_SUCCESS);
export const verifyEmailFailure = createAction(authConstants.VERIFY_EMAIL_FAILURE);

export const resendEmailRequest = createAction(authConstants.RESEND_EMAIL_REQUEST, email => email);
export const resendEmailSuccess = createAction(authConstants.RESEND_EMAIL_SUCCESS);
export const resendEmailFailure = createAction(
  authConstants.RESEND_EMAIL_FAILURE,
  errors => errors
);

export const passwordRecoveryRequest = createAction(
  authConstants.PASSWORD_RECOVERY_REQUEST,
  email => email
);
export const passwordRecoverySuccess = createAction(authConstants.PASSWORD_RECOVERY_SUCCESS);
export const passwordRecoveryFailure = createAction(
  authConstants.PASSWORD_RECOVERY_FAILURE,
  errors => errors
);

export const newPasswordRequest = createAction(
  authConstants.NEW_PASSWORD_REQUEST,
  passwordData => passwordData
);
export const newPasswordSuccess = createAction(authConstants.NEW_PASSWORD_SUCCESS);
export const newPasswordFailure = createAction(
  authConstants.NEW_PASSWORD_FAILURE,
  errors => errors
);

export const requestAccessRequest = createAction(
  authConstants.REQUEST_ACCESS_REQUEST,
  passwordData => passwordData
);
export const requestAccessSuccess = createAction(authConstants.REQUEST_ACCESS_SUCCESS);
export const requestAccessFailure = createAction(
  authConstants.REQUEST_ACCESS_FAILURE,
  errors => errors
);

export const resetAuthErrors = createAction(authConstants.RESET_AUTH_ERROR);

export const saveUserData = createAction(authConstants.SAVE_USER_DATA, userData => userData);

export const setUpgradeModalOpen = createAction(authConstants.SET_UPGRADE_MODAL_OPEN, data => data);

export const setSuccessModalOpen = createAction(authConstants.SET_SUCCESS_MODAL_OPEN, data => data);

export const setShowOnboard = createAction(authConstants.SET_SHOW_ONBOARD, userData => userData);

export const updateUserRewards = createAction(
  authConstants.UPDATE_USER_REWARDS,
  userData => userData
);

export const faqRequest = createAction(authConstants.GET_FAQ_REQUEST);
export const faqSuccess = createAction(authConstants.GET_FAQ_SUCCESS, data => data);
export const faqFailure = createAction(authConstants.GET_FAQ_FAILURE, errors => errors);

export const validateHashRequest = createAction(authConstants.GET_VALIDATE_HASH_REQUEST);

export const resetErrors = createAction(authConstants.RESET_ERRORS);
