import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Assessments from 'pages/Employer/Assessments';
import ViewApplication from 'pages/Employer/ViewApplication';

import { Common } from 'layouts';

import * as routes from './routes';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import ViewTestResultExplanation from 'pages/Employer/ViewTestResultExplanation/ViewTestResultExplanation';
import { ViewAssessment } from 'pages/Employer/ViewAssessment';
import { EditAssessment } from 'pages/Employer/EditAssessment/EditAssessment';
import { CreateAssessment } from 'pages/Employer/CreateAssessment';
import { Dashboard } from 'pages/Employer/Dashboard';

const AssessmentsRoutes = () => {
  const { user } = useSelector(store => store.auth);

  const isAdmin = user?.role === 'admin';
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Common />
          </PrivateRoute>
        }>
        <Route exact path={routes.DASHBOARD} element={<Dashboard />} />
        <Route exact path={routes.ASSESSMENTS} element={<Assessments isAdmin={isAdmin} />} />
        <Route exact path={routes.VIEW_APPLICATION} element={<ViewApplication />} />
        <Route exact path={routes.VIEW_TEST_REPORT} element={<ViewTestResultExplanation />} />
        <Route exact path={routes.VIEW_ASSESSMENT} element={<ViewAssessment isAdmin={isAdmin} />} />
        <Route
          exact
          path={routes.CREATE_ASSESSMENT}
          element={<CreateAssessment isAdmin={isAdmin} />}
        />
        <Route exact path={routes.EDIT_ASSESSMENT} element={<EditAssessment />} />
      </Route>
    </Routes>
  );
};

export default AssessmentsRoutes;
