import { Link } from 'react-router-dom';

import { Typography } from 'ui-kit';

import * as routes from 'routes/routes';

import { getFullName, getModifyData } from 'utils/helpers';

const AuthorCard = ({ _id, firstName, lastName, lastActivityAt, tests, email }) => {
  return (
    <Link
      to={routes.AUTHORS + '/' + _id}
      className="flex max-h-[190px] cursor-pointer flex-col justify-between rounded-md border border-gray-400 bg-white p-4 transition-all hover:border-green-400">
      <Typography variant="heading-h5" title={getFullName({ firstName, lastName })} />
      <Typography variant="heading-h6" className="mt-1.5 text-gray-500" title={email} />
      <ul className="my-4 flex">
        <li
          className={`flex w-full flex-col gap-1 py-1.5 ${
            tests?.length === 0 ? 'opacity-50' : 'opacity-100'
          }`}>
          <Typography variant="small-default" title="Tests" />
          <Typography variant="heading-h6" title={tests || '-'} />
        </li>
      </ul>
      <Typography
        variant="small-default"
        className="opacity-50"
        title={'Last Activity on ' + getModifyData(new Date(lastActivityAt))}
      />
    </Link>
  );
};

export default AuthorCard;
