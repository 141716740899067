import { useEffect } from 'react';

import { Section, Typography } from 'ui-kit';
import CandidateInfo from 'components/CandidateInfo';
import { useDispatch, useSelector } from 'react-redux';
import { getCandidateRequest } from 'store/candidates/actions';

import { getModifyData } from 'utils/helpers';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import { ApplicationSectionOld } from 'components/ApplicationSectionOld/ApplicationSectionOld';

const ViewCandidate = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();

  const { loading, currentCandidate } = useSelector(store => store.candidates);

  useEffect(() => {
    dispatch(getCandidateRequest(_id));
  }, []);

  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      <div className="mb-5">
        <Breadcrumbs />
        <Typography
          title={currentCandidate.firstName + currentCandidate.lastName}
          variant="heading-h1"
        />
      </div>
      <div className="space-y-5">
        <Section>
          <Section.Header>
            <Typography title="Candidates" variant="heading-h2" />
          </Section.Header>
          <Section.Body className="flex min-h-[187px] flex-auto flex-wrap">
            <CandidateInfo data={currentCandidate} />
            <div className="flex flex-auto flex-col justify-between space-y-2.5">
              <div>
                <Typography
                  title={`Invitation via ${
                    currentCandidate?.invitation?.typeInvitation === 'email'
                      ? 'email'
                      : 'private link'
                  }`}
                  variant="heading-h4"
                />
                <Typography
                  title={currentCandidate?.invitation?.company}
                  className="mt-1 text-gray-500"
                  variant="small-default"
                />
                <Typography variant="regular" className="flex gap-1">
                  on{' '}
                  <Typography
                    className="text-green-400"
                    title={currentCandidate?.invitation?.name}
                  />
                </Typography>
              </div>
              <Typography
                className="text-gray-500"
                title={
                  currentCandidate.typeInvitation === 'email'
                    ? 'Invited on ' + getModifyData(new Date(currentCandidate.invitedAt))
                    : 'Registered at ' + getModifyData(new Date(currentCandidate.createdAt))
                }
                variant="small-default"
              />
            </div>
          </Section.Body>
        </Section>
        <ApplicationSectionOld applications={currentCandidate.applications} />
      </div>
    </div>
  );
};

export default ViewCandidate;
