import { SubscriptionsCard } from 'components/Cards/SubscriptionsCard';
import { useEffect, useState, useMemo } from 'react';
import { Button, List, Modal, Typography } from 'ui-kit';
import AddSubscriptionForm from 'components/Forms/AddSubscriptionForm';
import Loader from 'components/Loader';
import { statusSubscriptionConfig } from 'config/selectConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  archiveSubscriptionRequest,
  getSubscriptionsRequest,
  publishSubscriptionRequest
} from 'store/configuration/actions/subscriptions';
import Breadcrumbs from 'components/Breadcrumbs';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { confirmStatus } from 'utils/helpers';
import { customStyles } from 'ui-kit/CustomSelect/SelectCustom';
import Select from 'react-select';

export const SubscriptionPlans = () => {
  const [filter, setFilter] = useState('');
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { subscriptions, idOpen, loading } = useSelector(store => store.subscriptions);

  const filteredData = useMemo(() => {
    if (filter) {
      return subscriptions.filter(item => item.status === filter);
    }
    return subscriptions;
  }, [filter, subscriptions]);

  const selectedSubscription = useMemo(() => {
    if (idOpen) {
      return subscriptions.find(item => item._id === idOpen);
    }
  }, [idOpen, subscriptions]);

  useEffect(() => {
    dispatch(getSubscriptionsRequest());
  }, []);

  const onSubmit = () => {
    if (selectedSubscription?.status === 'draft' || selectedSubscription?.status === 'archived') {
      dispatch(publishSubscriptionRequest(selectedSubscription?._id));
    } else {
      dispatch(archiveSubscriptionRequest(selectedSubscription?._id));
    }
    setConfirmModalOpen(false);
  };

  return (
    <div className="w-full px-6 py-9">
      <Breadcrumbs type="billing" />
      {loading && <Loader />}
      <div className="mt-2 flex w-full justify-between">
        <div className="flex gap-3">
          <Typography title="Subscription Plans" variant="heading-h1" />
          <Button onClick={() => setInviteModalOpen(true)} title="Create" variant="primary" />
        </div>
        <div className="max-w-[220px] flex-1">
          <Select
            onChange={({ value }) => setFilter(value)}
            options={statusSubscriptionConfig}
            placeholder="Status"
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null
            }}
          />
        </div>
      </div>
      <Modal isOpen={isInviteModalOpen} className="m-auto w-full max-w-[1070px] overflow-y-auto">
        <AddSubscriptionForm
          setInviteModalOpen={setInviteModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
        />
      </Modal>
      <ConfirmationModal
        isConfirmModalOpen={isConfirmModalOpen}
        setConfirmModalOpen={() => setConfirmModalOpen(false)}
        bodyText={
          <b>
            {confirmStatus(selectedSubscription?.status)} {selectedSubscription?.name} Plan?
          </b>
        }
        headerText={`${confirmStatus(selectedSubscription?.status)} Plan`}
        buttonText={`${confirmStatus(selectedSubscription?.status)} Plan`}
        danger={confirmStatus(selectedSubscription?.status) === 'Archive'}
        buttonAction={onSubmit}
      />
      <Modal isOpen={isViewModalOpen} className="m-auto w-full max-w-[1060px]">
        <AddSubscriptionForm
          setInviteModalOpen={setViewModalOpen}
          setConfirmModalOpen={setConfirmModalOpen}
          setViewModalOpen={setViewModalOpen}
          {...selectedSubscription}
        />
      </Modal>
      {filteredData && (
        <List
          wrap="ul"
          data={filteredData}
          keyExtractor={(_, idx) => idx}
          element={props => <SubscriptionsCard setViewModalOpen={setViewModalOpen} {...props} />}
          className="mt-6 flex flex-col gap-6"
        />
      )}
    </div>
  );
};
