import history from 'helpers/useHistory/useHistory';
import * as React from 'react';

export function useBlocker(blocker, when = true) {
  React.useEffect(() => {
    if (!when) return;

    const unblock = history.block(tx => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        }
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}
