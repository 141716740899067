import { yupResolver } from '@hookform/resolvers/yup';
import { editPasswordSchema } from './validation';

import { ErrorMessage, Form, Button, Typography, Modal, Strength } from 'ui-kit';

import { useDispatch } from 'react-redux';
import { PasswordInput } from 'components/PasswordInput';
import { updateProfilePasswordRequest } from 'store/auth/actions';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

const PasswordEdit = ({ isOpenModal, setIsOpenModal }) => {
  const dispatch = useDispatch();

  const onSubmit = (data, control) => {
    dispatch(updateProfilePasswordRequest(data));
    control.reset();
    setIsOpenModal(false);
  };

  return (
    <Modal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} size="2xl">
      <Form
        mode="all"
        resolver={yupResolver(editPasswordSchema)}
        onSubmit={onSubmit}
        className="flex flex-col">
        {({ watch, formState }) => (
          <>
            <Modal.Header>
              <Typography variant="heading-h4" title="Change password" />
              <Button
                icon={faXmark}
                iconSize={20}
                iconColor="#5C5F62"
                onClick={() => setIsOpenModal(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="p-4">
                <div>
                  <Typography variant="regular-default" title="Current password" />
                  <PasswordInput name="password" />
                  <ErrorMessage name="password" />
                </div>
                <div>
                  <Typography variant="regular-default" title="New password" />
                  <PasswordInput name="newPassword" />
                  <Strength className="mt-2" value={watch('newPassword')} />
                  <ErrorMessage name="newPassword" />
                </div>
                <div>
                  <Typography variant="regular-default" title="Confirm password" />
                  <PasswordInput name="confirmPassword" />
                  <ErrorMessage name="confirmPassword" successMessage="Passwords match" />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-end gap-4">
              <Button
                variant="primary-outline"
                onClick={() => setIsOpenModal(false)}
                title="Cancel"
              />
              <Button
                disabled={!formState.isValid}
                variant="primary"
                type="submit"
                title="Change password"
              />
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default PasswordEdit;
