import { faXmark } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { downgradeFreeRequest } from 'store/configuration/actions/subscriptions';
import { Button, Modal, Typography } from 'ui-kit';

interface DowngradeSubscriprionsProps {
  isDowngradeModalOpen: boolean;
  setDowngradeModalOpen: (isOpen: boolean) => void;
  currentName: string;
  updateName: string;
  daysLeft: any;
}

const DowngradeSubscriprions: React.FC<DowngradeSubscriprionsProps> = ({
  isDowngradeModalOpen,
  setDowngradeModalOpen,
  currentName,
  updateName,
  daysLeft
}) => {
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(downgradeFreeRequest());
  };
  return (
    <Modal isOpen={isDowngradeModalOpen} className="m-auto w-full max-w-[655px]">
      <Modal.Header className="border-none px-6">
        <Typography variant="heading-h2" title={`Downgrade to ${updateName}`} />
        <Button
          icon={faXmark}
          iconSize={20}
          iconColor="#5C5F62"
          onClick={() => setDowngradeModalOpen(false)}
        />
      </Modal.Header>
      <Modal.Body className="border-none p-8">
        Please, confirm that you would like to <b>downgrade to the {updateName} Plan.</b>
        <br />
        <br />
        You will see the following changes upon downgrading:
        <br />
        <br />
        <ul className="ml-5 list-disc">
          <li>5 applications per assessment</li>
          <li>1 simultaneously published assessments</li>
          <li>7 days assessment lifetime</li>
        </ul>
        <br />
        Your {currentName} Plan will stay active for another{' '}
        <b>{moment(daysLeft).diff(moment(), 'days')} days.</b>
      </Modal.Body>
      <Modal.Footer className="flex justify-end gap-4">
        <div className="flex justify-end gap-4">
          <Button
            variant="secondary"
            type="submit"
            title="Cancel"
            onClick={() => {
              setDowngradeModalOpen(false);
            }}
          />
          <Button variant="primary" title={`Downgrade to ${updateName}`} onClick={onSubmit} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DowngradeSubscriprions;
