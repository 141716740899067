import { useEffect, useState } from 'react';

import { List, Section, Typography } from 'ui-kit';
import ApplicationCard from 'components/Cards/ApplicationCard';
import EmptyResourse from 'components/EmptyResourse';

export const ApplicationSectionOld = ({ applications }) => {
  const [apps, setApps] = useState([]);

  useEffect(() => {
    setApps(applications);
  }, [applications]);

  if (!applications?.length) return null;

  return (
    <Section>
      <Section.Header className="flex items-center justify-between gap-4">
        <Typography title="Applications" variant="heading-h2" />
      </Section.Header>
      <Section.Body>
        <List
          empty={<EmptyResourse title="Empty result" />}
          data={apps}
          element={props => <ApplicationCard {...props} />}
          keyExtractor={(_, idx) => idx}
          wrap="div"
          className="grid flex-1 gap-5 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
        />
      </Section.Body>
    </Section>
  );
};
