import { yupResolver } from '@hookform/resolvers/yup';

import { ErrorMessage, Form, Button, Typography, Modal, Input, Textarea } from 'ui-kit';

import { useDispatch } from 'react-redux';
import { AnswerChoisesSchema } from './validation';
import {
  createAnswerChoisesRequest,
  editAnswerChoisesRequest
} from 'store/configuration/actions/subscriptions';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

const separator = '<br />';

const CreateAnswerChoiseForm = ({ isOpenModal, setIsOpenModal, defaultValue }) => {
  const dispatch = useDispatch();

  const onSubmit = ({ name, values }, control) => {
    !defaultValue &&
      dispatch(createAnswerChoisesRequest({ name, values: values.split(separator) }));
    defaultValue &&
      dispatch(
        editAnswerChoisesRequest({
          id: defaultValue._id,
          data: { name, values: values.split(separator) }
        })
      );
    control.reset();
    setIsOpenModal(false);
  };

  return (
    <Modal isOpen={isOpenModal} onClose={setIsOpenModal} className="overflow-y-auto" size="2xl">
      <Form
        mode="onChange"
        onSubmit={onSubmit}
        resolver={yupResolver(AnswerChoisesSchema)}
        defaultValues={{
          name: defaultValue?.name || '',
          values: defaultValue?.values.join(separator) || ''
        }}
        className="flex flex-col">
        {({ formState }) => (
          <>
            <Modal.Header>
              <Typography
                variant="heading-h4"
                title={defaultValue ? 'Edit Template' : 'Create Template'}
              />
              <Button icon={faXmark} iconSize={20} iconColor="#5C5F62" onClick={setIsOpenModal} />
            </Modal.Header>
            <Modal.Body>
              <div className="p-4">
                <div>
                  <Typography variant="small-default" title="Name" />
                  <Input name="name" />
                  <ErrorMessage name="name" />
                </div>
                <div>
                  <Typography variant="small-default" title="Values (one per line)" />
                  <Textarea name="values" className="break-words" maxLength={1000} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-end gap-4">
              <Button variant="primary-outline" onClick={setIsOpenModal} title="Cancel" />
              <Button
                disabled={!formState.isValid}
                variant="primary"
                type="submit"
                title={defaultValue ? 'Save Template' : 'Create Template'}
              />
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CreateAnswerChoiseForm;
