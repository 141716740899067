import { Logo } from 'ui-kit/Logo';
import config from 'config';

const Footer = ({ candidate }) => {
  return (
    <footer className="flex items-center justify-between border-t border-gray-400 px-5 py-8 text-gray-500 ">
      <div className="flex flex-col gap-2">
        {candidate && <p className="text-xs text-[#6D7175] md:text-sm">Powered by</p>}
        <Logo
          to="https://hirenest.com/?utm_source=certificate"
          height={25}
          width={125}
          color="#6D7175"
        />
      </div>
      <ul className="flex flex-col items-center">
        <li className="text-xs md:text-sm">© 2023, Hirenest Ltd.</li>
        <li className="text-xs md:text-sm">Version: {config.appVersion || '1.6'}</li>
      </ul>
      <ul>
        <li>
          <a
            href="mailto:support@hirenest.com?subject=Support%20Request"
            className="text-sm underline">
            Get Support
          </a>
        </li>
        <li>
          <a href="mailto:support@hirenest.com?subject=Bug%20Report" className="text-sm underline">
            Report A Bug
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
