import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import EmptyResourse from 'components/EmptyResourse';

import { Typography, Button, Form, Input, List, Icon } from 'ui-kit';
import { useDebouceCallback, useQueryParams } from 'hooks';

import { InviteAuthorModal } from './InviteAuthorModal';

import AuthorCard from 'components/Cards/AuthorCard';
import { getAllAuthorsRequest } from 'store/admin/actions';
import { faMagnifyingGlass, faPlus } from '@fortawesome/pro-light-svg-icons';
import { ThreeDots } from 'react-loader-spinner';

const Authors = () => {
  const dispatch = useDispatch();

  const { loading, authors } = useSelector(store => store.admin);
  const [isModalOpen, setOpenModal] = useState(false);

  const perPage = 39;

  const { params, set } = useQueryParams({ page: 0, perPage });

  const handleToggleInviteModal = flag => () => setOpenModal(flag);

  const debounceSubmit = useDebouceCallback(set);

  useEffect(() => {
    dispatch(
      getAllAuthorsRequest({
        ...params,
        skip: params.page * perPage,
        perPage
      })
    );
  }, [params]);

  return (
    <div className="py-8 px-7.5">
      <Form defaultValues={{ search: params.search }}>
        {({ handleSubmit, getValues }) => (
          <>
            <ul className="relative mb-6 flex w-full  justify-between">
              <li className="flex items-center space-x-3">
                <Typography title="Authors" variant="heading-h1" />
                <Button
                  onClick={() => setOpenModal(true)}
                  icon={faPlus}
                  title="Invite Author"
                  variant="create"
                />
              </li>
              <li className="flex space-x-3">
                <Input
                  onChangeValue={handleSubmit(debounceSubmit)}
                  leftComponent={() => (
                    <Icon icon={faMagnifyingGlass} size={16} className="ml-3" color="#8C9196" />
                  )}
                  rightComponent={() => (
                    <div className="mr-3 w-[16px]">
                      {loading && (
                        <ThreeDots height="16" width="16" color="grey" ariaLabel="loading" />
                      )}
                    </div>
                  )}
                  className="max-w-[250px] flex-3"
                  name="search"
                  placeholder="Search Authors..."
                />
              </li>
            </ul>
            {authors?.length ? (
              <section className={`flex w-full flex-col justify-between`}>
                {loading ? (
                  <List
                    wrap="ul"
                    data={Array(perPage).fill(0)}
                    keyExtractor={(_, idx) => idx}
                    //TODO: ADD CardLoader 2 sprint
                    // element={props => <CardLoader {...props} type="assessment" />}
                    element={props => <div className="h-[184px] min-w-[345px] bg-gray-300"></div>}
                    className="mb-6 grid min-h-[596px] gap-6 lg:grid-cols-3 2xl:grid-cols-4"
                  />
                ) : (
                  <List
                    wrap="ul"
                    data={authors}
                    keyExtractor={(_, idx) => idx}
                    element={props => <AuthorCard {...props} />}
                    className="mb-6 grid min-h-[596px] gap-6 lg:grid-cols-3 2xl:grid-cols-4"
                  />
                )}
                {/* {totalAssessments > perPage && (
                <Pagination
                  page={params.page}
                  itemsPerPage={perPage}
                  totalItems={totalAssessments}
                  setPage={page => push({ page })}
                />
              )} */}
              </section>
            ) : (
              <EmptyResourse
                className="my-20"
                title={getValues()?.search ? 'No matches found' : "You don't have any authors yet"}
              />
            )}
          </>
        )}
      </Form>

      <InviteAuthorModal isOpen={isModalOpen} closeModal={handleToggleInviteModal(false)} />
    </div>
  );
};

export default Authors;
