import { Route, Routes } from 'react-router-dom';

import Employers from 'pages/Administrator/Employers';
import Authors from 'pages/Administrator/Authors';
import ViewAuthor from 'pages/Administrator/ViewAuthor';
import ViewEmployer from 'pages/Administrator/ViewEmployer';

import { Common } from 'layouts';

import * as routes from './routes';
import ViewCompany from 'pages/Administrator/ViewCompany';
import { Configuration } from 'pages/Administrator/Configuration/Configuration';
import { Accounts } from 'pages/Administrator/Configuration/Accounts/Accounts';
import { Attachments } from 'pages/Administrator/Configuration/Attachments/Attachments';
import { Authors as AuthorConf } from 'pages/Administrator/Configuration/Authors/Authors';
import { Billing } from 'pages/Administrator/Configuration/Billing/Billing';
import { SubscriptionPlans } from 'pages/Administrator/Configuration/SubscriptionPlans/SubscriptionPlans';
import { Employers as EmployersConf } from 'pages/Administrator/Configuration/Employers/Employers';
import { Candidates } from 'pages/Administrator/Configuration/Candidates/Candidates';
import { Companies } from 'pages/Administrator/Configuration/Companies/Companies';
import { Common as CommonConf } from 'pages/Administrator/Configuration/Common/Common';
import { Occupations } from 'pages/Administrator/Configuration/Occupations/Occupations';
import { Notifications } from 'pages/Administrator/Configuration/Notifications/Notifications';
import { General } from 'pages/Administrator/Configuration/General/General';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import { ConfigurationTests } from 'pages/Administrator/Configuration/ConfigurationTests/ConfigurationTests';
import { ConfigurationTestCategories } from 'pages/Administrator/Configuration/ConfigurationTestCategories/ConfigurationTestCategories';
import { ConfigurationAnswerChoises } from 'pages/Administrator/Configuration/ConfigurationAnswerChoises/ConfigurationAnswerChoises';
import { Admins } from 'pages/Administrator/Configuration/Admins/Admins';
import { EditAdmin } from 'pages/Administrator/Configuration/EditAdmin/EditAdmin';
import { TestsMapping } from 'pages/Administrator/Configuration/Occupations/TestsMapping/TestsMapping';
import { TestMappingSingle } from 'pages/Administrator/Configuration/Occupations/TestMappingSingle/TestMappingSingle';
import { OccupationSingle } from 'pages/Administrator/Configuration/Occupations/OccupationSingle/OccupationSingle';
import AdminDashboard from 'pages/Administrator/AdminDashboard';
import { Assessments } from 'pages/Administrator/Configuration/Assessments/Assessments';

const AdministratorRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <Common />
          </PrivateRoute>
        }>
        <Route path={routes.HOME} exact index element={<AdminDashboard />} />
        <Route path={routes.EMPLOYERS} element={<Employers />} />
        <Route path={routes.EMPLOYER_VIEW} element={<ViewEmployer />} />
        <Route path={routes.COMPANY_VIEW} element={<ViewCompany />} />
        <Route path={routes.CONFIGURATION} element={<Configuration />}>
          <Route path={routes.GENERAL} element={<General />} />
          <Route path={routes.ACCOUNTS} element={<Accounts />} />
          <Route path={routes.ADMINS} element={<Admins />} />
          <Route path={routes.EDIT_ADMIN} element={<EditAdmin />} />
          <Route path={routes.ATTACHMENTS} element={<Attachments />} />
          <Route path={routes.ASSESSMENTS_CONF} element={<Assessments />} />
          <Route path={routes.AUTHORCONF} element={<AuthorConf />} />
          <Route path={routes.BILLING} element={<Billing />} />
          <Route
            path={routes.BILLING + '/' + routes.SUBSCRIPTION_PLANS}
            element={<SubscriptionPlans />}
          />
          <Route path={routes.EMPLOYERSCONF} element={<EmployersConf />} />
          <Route path={routes.CANDIDATESCONF} element={<Candidates />} />
          <Route path={routes.COMPANIES} element={<Companies />} />
          <Route path={routes.COMMON} element={<CommonConf />} />
          <Route path={routes.OCCUPATIONS} element={<Occupations />} />
          <Route path={routes.NOTIFICATIONS} element={<Notifications />} />
          <Route path={routes.CONFIGURATION_TESTS} element={<ConfigurationTests />} />
          <Route
            path={routes.CONFIGURATION_TEST_CATEGOIRES_ROUTE}
            element={<ConfigurationTestCategories />}
          />
          <Route
            path={routes.CONFIGURATION_ANSWER_CHOISES_ROUTE}
            element={<ConfigurationAnswerChoises />}
          />
          <Route path={routes.CONFIGURATION_TEST_MAPPING_ROUTE} element={<TestsMapping />} />
          <Route path={routes.CONFIGURATION_TEST_MAPPING_SINGLE} element={<TestMappingSingle />} />
          <Route
            path={routes.CONFIGURATION_OCCUPATION_SINGLE_ROUTE}
            element={<OccupationSingle />}
          />
        </Route>
        <Route path={routes.AUTHORS} element={<Authors />} />
        <Route path={routes.AUTHORS_PROFILE} element={<ViewAuthor />} />
      </Route>
    </Routes>
  );
};

export default AdministratorRoutes;
