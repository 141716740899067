import { Button, Typography } from 'ui-kit';
import { useNavigate } from 'react-router-dom';

export const ErrorMain = ({ code, title, discription, dashboard }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center space-y-3">
      <Typography title={code} variant="heading-h1" className="text-9xl" />
      <Typography title={title} variant="heading-h1" />
      <Typography title={discription} variant="regular-default" />
      {dashboard && (
        <Button
          children="Back to Home Page"
          variant="primary"
          onClick={() => navigate(dashboard || '/auth/singin')}
        />
      )}
    </div>
  );
};
