export const languageConfig = [{ value: 'English', label: 'English' }];

export const typeAssessmentConfig = [
  { value: '', label: 'All Assessments' },
  { value: 'draft', label: 'Draft' },
  { value: 'published', label: 'Published' },
  { value: 'archived', label: 'Archived' }
];

export const statusSubscriptionConfig = [
  { value: '', label: 'Any' },
  { value: 'draft', label: 'Draft' },
  { value: 'active', label: 'Published' }
];

export const candidateStatus = [
  { value: '', label: 'All Applications' },
  { value: 'Evaluated', label: 'Evaluated' },
  { value: 'Not Evaluated', label: 'Not Evaluated' },
  { value: 'Started', label: 'Started' },
  { value: 'Invited', label: 'Invited' },
  { value: 'opened', label: 'Opened' }
];

export const testDifficultyConfig = [
  { value: 'beginner' },
  { value: 'intermediate' },
  { value: 'expert' }
];

export const subscriptionDuration = [{ value: 'year', label: 'Year' }];
export const subscriptionType = [
  { value: 'paid', label: 'Paid' },
  { value: 'free', label: 'Free' }
];
export const recomendedUpgrade = [
  { value: 'none', label: 'None' },
  { value: 'business', label: 'Business' },
  { value: 'enterprise', label: 'Enterprise' }
];

export const timeLimit = [
  { value: 5000, label: '5 seconds' },
  { value: 10000, label: '10 seconds' },
  { value: 15000, label: '15 seconds' },
  { value: 25000, label: '25 seconds' },
  { value: 40000, label: '40 seconds' },
  { value: 60000, label: '1 minute' },
  { value: 120000, label: '2 minutes' },
  { value: 180000, label: '3 minutes' },
  { value: 300000, label: '5 minutes' },
  { value: 420000, label: '7 minutes' },
  { value: 660000, label: '11 minutes' },
  { value: 1080000, label: '18 minutes' },
  { value: 1800000, label: '30 minutes' },
  { value: 3600000, label: '1 hour' },
  { value: 5400000, label: '1 hour 30 minutes' },
  { value: 7200000, label: '2 hours' },
  { value: 10800000, label: '3 hours' },
  { value: 18000000, label: '5 hours' },
  { value: 28800000, label: '8 hours' }
];

export const age = [
  { value: '17 or younger', label: '17 or younger' },
  { value: '18 – 20', label: '18 – 20' },
  { value: '21 – 29', label: '21 – 29' },
  { value: '30 – 39', label: '30 – 39' },
  { value: '40 – 49', label: '40 – 49' },
  { value: '50 – 59', label: '50 – 59' },
  { value: '60 or older', label: '60 or older' }
];

export const gender = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Prefer not to say', label: 'Prefer not to say' },
  { value: 'Other', label: 'Other' }
];

export const industry = [
  { value: 'Academia', label: 'Academia' },
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Chemical', label: 'Chemical' },
  { value: 'Construction', label: 'Construction' },
  { value: 'Defense', label: 'Defense' },
  { value: 'Education', label: 'Education' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Entertainment', label: 'Entertainment' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Food and Agriculture', label: 'Food and Agriculture' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Hospitality', label: 'Hospitality' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Insurance', label: 'Insurance' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Management Consulting', label: 'Management Consulting' },
  { value: 'Non-Profit', label: 'on-Profit' },
  { value: 'Pharmaceutical', label: 'Pharmaceutical' },
  { value: 'Real Estate', label: 'Estate' },
  { value: 'Retail and Consumer Goods', label: 'Retail and Consumer Goods' },
  { value: 'Sport', label: 'Sport' },
  { value: 'Telecommunication', label: 'Telecommunication' },
  { value: 'Transport', label: 'Transport' }
];

export const employment = [
  { value: 'Employed (part-time)', label: 'Employed (part-time)' },
  { value: 'Employed (full-time)', label: 'Employed (full-time)' },
  { value: 'Self-Employed', label: 'Self-Employed' },
  { value: 'Freelancer', label: 'Freelancer' },
  { value: 'Consultant', label: 'Consultant' },
  { value: 'Independent contractor', label: 'Independent contractor' },
  { value: 'Temporary worker', label: 'Temporary worker' },
  { value: 'Not employed (looking for work)', label: 'Not employed (looking for work)' },
  { value: 'Not employed (not looking for work)', label: 'Not employed (not looking for work)' },
  { value: 'Retired', label: 'Retired' },
  { value: 'Disabled (not able to work)', label: 'Disabled (not able to work)' },
  { value: 'Disabled (able to work)', label: 'Disabled (able to work)' }
];

export const years = [
  { value: '1 or less', label: '1 or less' },
  { value: '1 – 3', label: '1 – 3' },
  { value: '4 – 6', label: '4 – 6' },
  { value: '7 – 10', label: '7 – 10' },
  { value: '10 or more', label: '10 or more' }
];

export const educationLevel = [
  { value: 'No schooling completed', label: 'No schooling completed' },
  { value: 'Nursery school', label: 'Nursery school' },
  { value: 'Grades 1 through 11', label: 'Grades 1 through 11' },
  { value: '12th grade—no diploma', label: '12th grade—no diploma' },
  { value: 'Regular high school diploma', label: 'Regular high school diploma' },
  { value: 'GED or alternative credential', label: 'GED or alternative credential' },
  {
    value: 'Some college credit, but less than 1 year of college',
    label: 'Some college credit, but less than 1 year of college'
  },
  {
    value: '1 or more years of college credit, no degree',
    label: '1 or more years of college credit, no degree'
  },
  {
    value: 'Associates degree (for example: AA, AS)',
    label: 'Associates degree (for example: AA, AS)'
  },
  {
    value: 'Bachelor’s degree (for example: BA. BS)',
    label: 'Bachelor’s degree (for example: BA. BS)'
  },
  {
    value: 'Master’s degree (for example: MA, MS, MEng, MEd, MSW, MBA)',
    label: 'Master’s degree (for example: MA, MS, MEng, MEd, MSW, MBA)'
  },
  {
    value: 'Professional degree beyond bachelor’s degree (for example: MD, DDS, DVM, LLB, JD)',
    label: 'Professional degree beyond bachelor’s degree (for example: MD, DDS, DVM, LLB, JD)'
  },
  {
    value: 'Doctorate degree (for example, PhD, EdD)',
    label: 'Doctorate degree (for example, PhD, EdD)'
  }
];

export const occupation = [
  {
    label: 'Management, professional, and related',
    options: [
      { value: 'Management', label: 'Management' },
      { value: 'Business and financial operations', label: 'Business and financial operations' },
      { value: 'Computer and mathematical', label: 'Computer and mathematical' },
      { value: 'Architecture and engineering', label: 'Architecture and engineering' },
      {
        value: 'Life, physical, and social science',
        label: 'Life, physical, and social science'
      },
      { value: 'Community and social service', label: 'Community and social service' },
      { value: 'Legal', label: 'Legal' },
      { value: 'Education, training, and library', label: 'Education, training, and library' },
      {
        value: 'Arts, design, entertainment, sports, and media',
        label: 'Arts, design, entertainment, sports, and media'
      },
      {
        value: 'Healthcare practitioners and technical',
        label: 'Healthcare practitioners and technical'
      }
    ]
  },
  {
    label: 'Service',
    options: [
      { value: 'Healthcare support', label: 'Healthcare support' },
      { value: 'Protective service', label: 'Protective service' },
      {
        value: 'Food preparation and serving related',
        label: 'Food preparation and serving related'
      },
      {
        value: 'Building and grounds cleaning and maintenance',
        label: 'Building and grounds cleaning and maintenance'
      },
      { value: 'Personal care and service', label: 'Personal care and service' }
    ]
  },
  {
    label: 'Sales and office',
    options: [
      { value: 'Sales and related', label: 'Sales and related' },
      { value: 'Office and administrative support', label: 'Office and administrative support' }
    ]
  },
  {
    label: 'Natural resources, construction, and maintenance',
    options: [
      { value: 'Farming, fishing, and forestry', label: 'Farming, fishing, and forestry' },
      { value: 'Construction and extraction', label: 'Construction and extraction' },
      {
        value: 'Installation, maintenance, and repair',
        label: 'Installation, maintenance, and repair'
      }
    ]
  },
  {
    label: 'Production, transportation, and material moving',
    options: [
      { value: 'Production', label: 'Production' },
      { value: 'Transportation and material moving', label: 'Transportation and material moving' }
    ]
  }
];
