import { localStorageService } from 'helpers/localStorageService';
import { useSelector } from 'react-redux';

export const useUserRole = () => {
  const role = useSelector(store => store.auth.user.role) || localStorageService.getRole();

  const isAdmin = role === 'admin';
  const isEmployer = role === 'employer';
  const isAuthor = role === 'author';
  const isCandidate = role === 'candidate';

  return { role, isAdmin, isEmployer, isAuthor, isCandidate };
};
