import Footer from 'components/Footer';
import { Typography } from 'ui-kit';
import { Logo } from 'ui-kit/Logo';

export const SmallScreenSize = () => (
  <div className="flex h-screen flex-col justify-end">
    <div className="m-auto flex flex-col gap-6">
      <div className="flex justify-center">
        <Logo height="36" width="180" />
      </div>
      <div className="flex max-w-[500px] flex-col rounded-md border border-[#C9CCCF] bg-[#FFFFFF] p-[20px]">
        <Typography className="self-center" variant="heading-h3" title="No Mobile Support" />
        <br />
        Unfortunately, Hirenest is only available on desktops at this moment.
        <br />
        <br />
        We understand the importance of mobile support and are working hard to make that available
        soon.
        <br />
        <br />
        Please use Hirenest from your desktop or laptop.
      </div>
    </div>
    <div>
      <Footer />
    </div>
  </div>
);
