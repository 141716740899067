import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Bar, getElementAtEvent } from 'react-chartjs-2';

import { Typography } from 'ui-kit';
import * as routes from 'routes/routes';

import { clearPerformance, getPerformanceRequest } from 'store/candidates/actions';
import { Tooltip } from 'react-tooltip';

export const PerformanceChart = ({ auth }) => {
  const dispatch = useDispatch();
  const chartRef = useRef();
  const navigate = useNavigate();
  const { performance } = useSelector(store => store.candidates);

  useEffect(() => {
    if (auth) {
      return;
    }
    dispatch(getPerformanceRequest());

    return () => dispatch(clearPerformance());
  }, [auth]);

  const labels = ['Low Performance', 'Medium Performance', 'High Performance'];

  const onClick = event => {
    const activeElement = getElementAtEvent(chartRef.current, event);
    if (performance.length !== 0 && activeElement.length !== 0) {
      const label = labels[activeElement[0].index].replace(' Performance', '').toLowerCase();
      navigate(`${routes.CANDIDATES}?score=${label}`);
    }
  };

  const data = useMemo(() => {
    const arr = [];
    const low = performance.find(item => item._id === 'low');
    const medium = performance.find(item => item._id === 'medium');
    const high = performance.find(item => item._id === 'high');

    arr.push(low ? low.count : 0);
    arr.push(medium ? medium.count : 0);
    arr.push(high ? high.count : 0);

    return arr;
  }, [performance]);

  const maxData = Math.max(...data);

  const options = {
    plugins: { legend: null },
    maintainAspectRatio: false,
    scales: {
      x: { grid: { display: false } },
      y: {
        grid: { display: false },
        max: maxData < 10 ? 10 : maxData + Math.round(maxData / 10)
      }
    }
  };

  return (
    <div className="mb-[36px] h-[440px]">
      <Tooltip
        id="performance-description-tooltip"
        className="rounded-s z-50 mt-[3px] ml-[-5px] w-[230px] max-w-[230px] !opacity-100
        after:absolute after:left-[50%] after:bottom-[-5px] after:border-x-[5px]
        after:border-b-0 after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D]"
        noArrow={true}
        style={{
          background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)',
          fontSize: '14px'
        }}>
        Explore the distribution of applications across three groups (low, medium, and high) based
        on their Hirenest scores.
      </Tooltip>

      <Typography variant="heading-h4" className="mb-6">
        Performance
        <i
          className="fa-regular fa-circle-info relative ml-[8px] text-[16px] text-[#8C9196]"
          data-tooltip-id="performance-description-tooltip"
        />
      </Typography>

      <div className="relative h-[398px] rounded-md bg-white p-6 shadow-[0_1px_8px_0_rgba(68,68,68,0.12)]">
        {performance.length === 0 ? (
          <>
            <Typography
              variant="small-default"
              className="absolute top-[35%] left-[50%] mb-6 text-[#8C9196]"
              title="No Completed Applications"
              style={{
                transform: 'translate(-50%, -50%)'
              }}
            />
            <Bar
              data={{
                labels,
                datasets: [{ data: [3, 4, 5], backgroundColor: 'rgba(240, 240, 240)' }]
              }}
              options={{
                ...options,
                scales: {
                  x: { grid: { display: false } },
                  y: { grid: { display: false }, max: 10, ticks: { display: false } }
                },
                plugins: { tooltip: { enabled: false }, legend: null }
              }}
            />
          </>
        ) : (
          <Bar
            redraw={false}
            ref={chartRef}
            onClick={onClick}
            data={{
              labels,
              datasets: [
                {
                  data: data,
                  backgroundColor: [
                    'rgba(254, 164, 160, 1)',
                    'rgba(255, 215, 157, 1)',
                    'rgba(174, 233, 209, 1)'
                  ]
                }
              ]
            }}
            options={options}
          />
        )}
      </div>
    </div>
  );
};
