import { Button, Form, Input, ErrorMessage, Typography, Modal } from 'ui-kit';

import { yupResolver } from '@hookform/resolvers/yup';
import { editMailSchema } from './validation';
import { useDispatch, useSelector } from 'react-redux';
import { PasswordInput } from 'components/PasswordInput';
import { updateProfileEmailRequest } from 'store/auth/actions';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

export const MailEdit = ({ isOpenModal, setIsOpenModal }) => {
  const dispatch = useDispatch();

  const { user } = useSelector(store => store.auth);

  const onSubmit = data => {
    dispatch(updateProfileEmailRequest(data));
    setIsOpenModal(false);
  };

  return (
    <Modal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} size="2xl">
      <Form
        defaultValues={{ email: user?.email }}
        onSubmit={onSubmit}
        resolver={yupResolver(editMailSchema)}
        className="flex flex-col">
        {({ formState }) => (
          <>
            <Modal.Header>
              <Typography variant="heading-h4" title="Change Email" />
              <Button
                icon={faXmark}
                iconSize={20}
                iconColor="#5C5F62"
                onClick={() => setIsOpenModal(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="p-4">
                <div className="mb-5">
                  <Typography title="Current email" variant="regular-default" />
                  <Input name="email" shouldUnregister disabled={true} />
                </div>
                <div>
                  <Typography title="New email" variant="regular-default" />
                  <Input name="newEmail" />
                  <ErrorMessage name="newEmail" />
                </div>
                <div>
                  <Typography title="Current password" variant="regular-default" />
                  <PasswordInput name="password" />
                  <ErrorMessage name="password" />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-end gap-4">
              <Button variant="secondary" onClick={() => setIsOpenModal(false)} title="Cancel" />
              <Button
                disabled={!formState.isDirty}
                type="submit"
                variant="primary"
                title="Change email"
              />
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};
