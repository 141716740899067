import { Section, Typography } from 'ui-kit';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';

export const TestViewWebsite = ({ currentTest }) => (
  <>
    <Section>
      <Section.Body className="flex flex-col gap-6">
        <div>
          <Typography
            title="Meta"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <div className="mt-3 mb-1.5 flex min-h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography
              className="capitalize text-[#8C9196]"
              variant="regular-default"
              title={currentTest?.website?.meta?.title}
            />
          </div>
          <div className="mt-3 mb-[72px] flex min-h-[64px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography className="capitalize text-[#8C9196]" variant="regular-default">
              <HtmlTextReplacer html={currentTest?.website?.meta?.description} />
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            title="Heading 1"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <div className="mt-3 mb-1.5 flex min-h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography
              className="capitalize text-[#8C9196]"
              variant="regular-default"
              title={currentTest?.website?.heading1?.heading}
            />
          </div>
          <div className="mt-3 mb-[72px] flex min-h-[64px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography className="capitalize text-[#8C9196]" variant="regular-default">
              <HtmlTextReplacer html={currentTest?.website?.heading1?.subheading} />
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            title="Heading 2"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <div className="mt-3 mb-1.5 flex min-h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography
              className="capitalize text-[#8C9196]"
              variant="regular-default"
              title={currentTest?.website?.heading2?.headingA}
            />
          </div>
          <div className="mt-3 mb-1.5 flex min-h-[64px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography className="capitalize text-[#8C9196]" variant="regular-default">
              <HtmlTextReplacer html={currentTest?.website?.heading2?.subheadingA} />
            </Typography>
          </div>
        </div>
        <div>
          <div className="mt-3 mb-1.5 flex min-h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography
              className="capitalize text-[#8C9196]"
              variant="regular-default"
              title={currentTest?.website?.heading2?.headingB}
            />
          </div>
          <div className="mt-3 mb-[72px] flex min-h-[64px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography className="capitalize text-[#8C9196]" variant="regular-default">
              <HtmlTextReplacer html={currentTest?.website?.heading2?.subheadingB} />
            </Typography>
          </div>
        </div>
        <div>
          <Typography
            title="Heading 3"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <div className="mt-3 mb-1.5 flex min-h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography
              className="capitalize text-[#8C9196]"
              variant="regular-default"
              title={currentTest?.website?.heading3?.headingA}
            />
          </div>
          <div className="mt-3 mb-1.5 flex min-h-[64px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography className="capitalize text-[#8C9196]" variant="regular-default">
              <HtmlTextReplacer html={currentTest?.website?.heading3?.subheadingA} />
            </Typography>
          </div>
        </div>
        <div>
          <div className="mt-3 mb-1.5 flex min-h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography
              className="capitalize text-[#8C9196]"
              variant="regular-default"
              title={currentTest?.website?.heading3?.headingB}
            />
          </div>
          <div className="mt-3 mb-1.5 flex min-h-[64px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
            <Typography className="capitalize text-[#8C9196]" variant="regular-default">
              <HtmlTextReplacer html={currentTest?.website?.heading3?.subheadingB} />
            </Typography>
          </div>
        </div>
      </Section.Body>
    </Section>
  </>
);
