import { Navigate, Route, Routes } from 'react-router-dom';

import * as routes from './routes';
import SignInForm from 'components/Forms/SignInForm';
import SignUpForm from 'components/Forms/SignUpForm';
import ResetPasswordForm from 'components/Forms/ResetPassowrdForm';
import RedirectNote from 'pages/Common/Auth/components/RedirectNote';
import CreatePasswordForm from 'components/Forms/CreateNewPassordForm';
import { AuthWrapper } from 'pages/Common/AuthWrapper/AuthWrapper';
import { AuthWrapperDefault } from '../pages/Common/AuthWrapperDefault/AuthWrapperDefault';

const UnauthorizedRoutes = () => {
  return (
    <Routes>
      <Route path={routes.AUTH} element={<AuthWrapper />}>
        <Route index element={<Navigate to={routes.SIGNIN} />} />
        <Route path={routes.SIGNIN} element={<SignInForm />} />
        <Route path={routes.SIGNUP} element={<SignUpForm />} />
      </Route>
      <Route path={routes.AUTH} element={<AuthWrapperDefault />}>
        <Route path={routes.RESET_PASSWORD} element={<ResetPasswordForm />} />
        <Route path={routes.EMAIL_VERIFY} element={<RedirectNote />} />
        <Route path={routes.CREATE_NEW_PASSWORD} element={<CreatePasswordForm />} />
      </Route>
    </Routes>
  );
};

export default UnauthorizedRoutes;
