import { Typography } from 'ui-kit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';

export const General = props => {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const form = useForm({
    defaultValues: {
      mode: 'Normal'
    },
    mode: 'onChange'
  });
  const { register, getValues, reset } = form;

  return (
    <div className="px-6 py-9">
      <div className="flex flex-col gap-6">
        <Typography className="mt-[8px]" title="Configuration" variant="heading-h1" />
        <div className="flex flex-col gap-3">
          <Typography title="Operation Mode" variant="regular-emphasis" />
          <div className="flex cursor-pointer items-center gap-[10px]">
            <input
              onClick={() => setConfirmModalOpen(true)}
              type="radio"
              id="normal"
              value="Normal"
              className="h-[14px] w-[14px] ring-green-400 ring-offset-2 checked:bg-none checked:ring-2"
              {...register('mode')}
            />
            <label className="cursor-pointer" htmlFor="normal">
              Normal
            </label>
          </div>
          <div className="flex cursor-pointer items-center gap-[10px]">
            <input
              onClick={() => setConfirmModalOpen(true)}
              id="maintenance"
              type="radio"
              value="Maintenance"
              className="h-[14px] w-[14px] ring-green-400 ring-offset-2 checked:bg-none checked:ring-2"
              {...register('mode')}
            />
            <label className="cursor-pointer" htmlFor="maintenance">
              Maintenance
            </label>
          </div>
        </div>
        <ConfirmationModal
          isConfirmModalOpen={isConfirmModalOpen}
          setConfirmModalOpen={() => {
            reset();
            setConfirmModalOpen(false);
          }}
          headerText="Change Mode"
          buttonText={<p>Change Mode</p>}
          bodyText={`Change Mode to ${getValues('mode')}?`}
        />
      </div>
    </div>
  );
};
