import { ApplicationSection } from 'components/ApplicationSection';
import InviteCandidateForm from 'components/Forms/InviteCandidateForm';
import { QuestionType } from 'components/Question';
import TimeBadget from 'components/TimeBadget';
import ViewTests from 'pages/Employer/CreateAssessment_old/components/ViewTests/ViewTests';
import { Button, List, Section, Typography, Tab, Icon } from 'ui-kit';
import { getModifyData } from 'utils/helpers';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { Tab as TabUI } from '@headlessui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { faHourglassHalf } from '@fortawesome/pro-solid-svg-icons';

export const ViewSummary = ({
  currentAssessment,
  isAdmin,
  currentTest,
  testTime,
  customTime,
  applications,
  verified,
  resendVerificationEmail,
  sniperLink
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { companyLogo } = useSelector(store => store.auth?.user);

  return (
    <div className="space-y-5">
      {currentAssessment.status === 'published' && (
        <div className="relative">
          {!verified && !currentAssessment?.demo?.isDemo && (
            <div className="z-1 absolute inset-0 bg-gray-400 bg-opacity-40 backdrop-blur-[2px]" />
          )}
          <Section
            className={
              !verified &&
              !currentAssessment?.demo?.isDemo &&
              'rounded-lg border-transparent shadow-md'
            }>
            <Section.Header>
              <Typography title="Invitations" variant="heading-h2" />
            </Section.Header>
            <Section.Body>
              <InviteCandidateForm
                isAdmin={isAdmin}
                hash={currentAssessment.hash}
                isDemo={currentAssessment?.demo?.isDemo}
                verified={verified}
              />
            </Section.Body>
          </Section>
          {!verified && !currentAssessment?.demo?.isDemo && (
            <div
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2)'
              }}
              className="z-2 absolute flex flex-col items-center gap-3 rounded bg-white p-6">
              <Typography variant="heading-h4" title="Activate your account" />
              <Typography
                className="mt-[-4px]"
                variant="regular-default"
                title="To invite candidates, you have to confirm your email"
              />
              <div className="flex justify-center gap-3">
                {sniperLink?.url && (
                  <Button
                    onClick={() => window.location.replace(sniperLink?.url)}
                    variant="primary"
                    title={`Open ${sniperLink?.name || 'email'}`}
                  />
                )}
                <Button
                  onClick={resendVerificationEmail}
                  className="font-semibold text-[#007D6F]"
                  title="Resend email"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <ApplicationSection
        applications={applications}
        assessmentId={currentAssessment?._id}
        applicationsCount={currentAssessment?.applications?.length}
        assessmentPublished={currentAssessment?.status === 'published'}
        verified={verified}
      />

      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={index => {
          setSelectedIndex(index);
        }}
        as="div"
        className="w-full rounded-md bg-white shadow-md">
        <Tab.List>
          <TabUI className="group flex flex-1 items-center justify-center gap-[12px] border-b border-[#D2D5D8] py-[30px] !outline-none hover:border-[#12A182] hover:bg-[#F3F8F6] ui-selected:border-[#007D6F]">
            <Typography
              className="text-[#6D7175] group-hover:text-[#12A182] ui-selected:text-[#007D6F]"
              variant="heading-h3"
              title="Tests"
            />
            <TimeBadget
              className="inline-flex w-auto font-semibold"
              time={testTime}
              icon={<Icon icon={faStopwatch} size={16} className="text-[#2F6E63]" />}
            />
          </TabUI>
          <TabUI className="group flex flex-1 items-center justify-center gap-[12px] border-b border-[#D2D5D8] py-[30px] !outline-none hover:border-[#12A182] hover:bg-[#F3F8F6] ui-selected:border-[#007D6F]">
            <Typography
              className="text-[#6D7175] group-hover:text-[#12A182] ui-selected:text-[#007D6F]"
              variant="heading-h3"
              title="Custom Questions"
            />
            <TimeBadget
              className="inline-flex w-auto font-semibold"
              time={customTime}
              icon={<Icon icon={faHourglassHalf} size={16} className="text-[#2F6E63]" />}
            />
          </TabUI>
          <TabUI className="group flex flex-1 items-center justify-center gap-[12px] border-b border-[#D2D5D8] py-[30px] !outline-none hover:border-[#12A182] hover:bg-[#F3F8F6] ui-selected:border-[#007D6F]">
            <Typography
              className="text-[#6D7175] group-hover:text-[#12A182] ui-selected:text-[#007D6F]"
              variant="heading-h3"
              title="Cover"
            />
          </TabUI>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            {currentAssessment.tests?.length !== 0 && (
              <ViewTests tests={currentAssessment?.tests} timeLimit={testTime} showHeader={false} />
            )}
          </Tab.Panel>
          <Tab.Panel className="p-8">
            {currentAssessment?.customQuestions?.sections[0]?.questions?.length !== 0 && (
              <Section>
                <Section.Body>
                  <List
                    data={currentAssessment?.customQuestions?.sections[0]?.questions}
                    element={({ timeLimit, tags, type, index, ...props }, idx) => (
                      <div className="flex flex-col rounded border border-[#C9CCCF]">
                        <div className="flex justify-between rounded-t bg-[#F6F6F7] py-3 pl-6 pr-3">
                          <div className="flex items-center gap-[16px]">
                            <div className="flex gap-1">
                              <p className="text-[#2F6E63]">{props?.index || idx + 1}</p>
                              <p className="text-[#8C9196]">
                                /{' '}
                                {currentAssessment?.customQuestions?.sections[0]?.questions?.length}
                              </p>
                            </div>
                            <TimeBadget time={timeLimit} />
                          </div>
                        </div>
                        <div className="px-[24px] py-[18px]">
                          <QuestionType type={type} {...props} />
                        </div>
                      </div>
                    )}
                    keyExtractor={(_, idx) => idx}
                    wrap="div"
                    className="space-y-3"
                  />
                </Section.Body>
              </Section>
            )}
          </Tab.Panel>
          <Tab.Panel className="p-8">
            <div className="flex w-full gap-8">
              <div className="flex flex-1 flex-col">
                <div className="flex flex-col gap-1">
                  <ul className="flex flex-1 flex-col gap-6">
                    <li className="flex-1 space-y-2">
                      <HtmlTextReplacer
                        className="break-words"
                        style={{ wordBreak: 'break-word' }}
                        html={currentAssessment?.description}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="flex flex-1 justify-center">
                <div className="w-full">
                  {companyLogo && (
                    <section className="flex flex-col gap-[4px]">
                      <Typography variant="medium" title="Company logo" />
                      <div className="dropzone relative flex h-[144px] w-full items-center justify-center rounded-xl bg-[#F6F6F7]">
                        <img
                          src={companyLogo}
                          className="relative h-full w-full rounded-xl object-contain"
                        />
                      </div>
                    </section>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-6 flex">
              <div className="mt-auto flex flex-1 justify-between">
                <Typography variant="small-default" className="mb-2 text-[#6D7175]">
                  Created on: {getModifyData(currentAssessment.createdAt)}
                </Typography>
                <Typography variant="small-default" className="text-[#6D7175]">
                  Modified on: {getModifyData(currentAssessment.updatedAt)}
                </Typography>
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
