import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { Typography, Form, Textarea, Button, Section } from 'ui-kit';
import { useUserRole } from 'hooks';

import { setApplicationNoteRequest } from 'store/candidates/actions';

export const SectionEmployerNote = ({ application }) => {
  const dispatch = useDispatch();
  const { isAdmin } = useUserRole();

  const onSubmit = ({ note }) => {
    if (!application?.assessment?.demo?.isDemo) {
      dispatch(setApplicationNoteRequest({ data: { note }, applicationId: application._id }));
    }
  };

  return (
    <Section className="h-max flex-1 p-8">
      <Form
        onSubmit={onSubmit}
        defaultValues={{
          note: application?.assessment?.demo?.isDemo
            ? 'Question text many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text. Many desktop publishing packages and web page editors now use Lorem Ipsum.'
            : application.note || ''
        }}>
        <Typography title="Employer Note" variant="heading-h4" />
        <Textarea
          data-tooltip-id="note-tooltip"
          data-tooltip-content="It doesn't works in Demo Assessments"
          data-tooltip-place="top"
          disabled={isAdmin}
          className="mt-8"
          name="note"
          placeholder="Some notes about this candidate..."
          maxLength={2000}
        />
        {application?.assessment?.demo?.isDemo && (
          <Tooltip className="bg-black text-sm" id="note-tooltip" />
        )}
        <div className="mt-12 flex items-center justify-between">
          <Typography
            className="text-[#6D7175]"
            variant="regular-default"
            title="This private note would be visible only for your company"
          />
          {!isAdmin && <Button title="Save" type="submit" variant="primary-outline" />}
        </div>
      </Form>
    </Section>
  );
};
