import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import { showHideCategory } from 'store/configuration/actions/subscriptions';

import { TestCardNew } from 'components/TestCardNew/TestCardNew';
import { Button, Form, Icon, Input, Typography } from 'ui-kit';
import recommendedCover from 'assets/libraryRecommended.svg';
import defaultLogo from 'assets/defaultLogoHirenest.svg';
import noTest from 'assets/noFoundTest.svg';
import noTestComplete from 'assets/notFoundTestComplete.svg';
import StickyBox from 'react-sticky-box';
import { CurvedArrow } from 'components/CurvedArrow/CurvedArrow';
import { GenericAssessmentCard } from 'components/GenericAssessmentCard/GenericAssessmentCard';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lottie from 'react-lottie';
import loading from 'assets/lotties/loading.json';
import { ThreeDots } from 'react-loader-spinner';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const recommendedCategory = {
  description:
    'We understand that finding the right candidate for your organization can be a daunting project. Our platform offers a wide range of pre-employment tests to make your hiring process easier.<br /><br />Whether you’re looking for a candidate with a certain skill set or one who can assimilate into your company culture, our tests are designed to help you find the perfect fit. With tests that range from basic aptitude assessments to detailed personality tests, you’re sure to find the right candidate for the job.',
  cover: recommendedCover
};

export const LibraryCategories = ({
  params,
  debounceSubmit,
  categories,
  currentCategory,
  recommendedTestsLength,
  genericTemplatesLength,
  searchLoading,
  tests,
  onScroll,
  hasMore,
  previewTestHandler,
  currentAssessment,
  allTests,
  isNoTestComplete,
  clearSearchHandler,
  categoryClickHandler,
  browseAllTestHandler,
  requestTestHandler,
  loading,
  type,
  testsDivHeight,
  addTestComponent,
  removeTestComponent
}) => {
  const dispatch = useDispatch();

  const isHidden = (parent, parentCategory) => {
    if (parent) return false;
    return categories.find(item => item.parent && item.name === parentCategory).collapsed;
  };

  const showHideCategoryHandler = (id, parent) => {
    if (!parent) return;
    dispatch(showHideCategory({ id }));
  };

  const dataLength = tests?.length > 0 ? tests?.length : allTests?.length;

  return (
    <div className="shadow-medium flex min-h-[750px] rounded-md bg-white">
      <div className="flex min-w-[329px] flex-1 flex-col border-r-[0.5px] border-[#C9CCCF] bg-[#FAFBFB] pb-6">
        <StickyBox offsetTop={type === 'assessment' ? testsDivHeight + 70 : 60} offsetBottom={20}>
          <Form
            className="mb-6 px-6 py-[4px] pt-6"
            defaultValues={{ ...params, search: params.search }}>
            {({ handleSubmit }) => (
              <Input
                leftComponent={() => (
                  <Icon className="ml-3" color="#C9CCCF" size={16} icon={faMagnifyingGlass} />
                )}
                rightComponent={() => (
                  <div className="mr-3 flex items-center gap-3">
                    {searchLoading && (
                      <ThreeDots height="16" width="16" color="grey" ariaLabel="loading" />
                    )}
                    <span
                      onClick={() => clearSearchHandler()}
                      className={`cursor-pointer ${params?.search?.length === 0 && 'hidden'}`}>
                      <i className="fa-solid fa-xmark text-[#C9CCCF]"></i>
                    </span>
                  </div>
                )}
                maxLength={50}
                name="search"
                onChangeValue={handleSubmit(debounceSubmit)}
                placeholder="Search"
              />
            )}
          </Form>
          {categories?.map(
            ({ name, count, _id, parent, collapsed, parentCategory, isCollapsable }) => {
              return params?.search?.length > 0 && name === 'Hirenest Recommended' ? (
                <></>
              ) : (
                <div
                  key={name}
                  onClick={() => {
                    showHideCategoryHandler(_id, parent);
                    if (params?.category === _id) {
                      return;
                    }
                    categoryClickHandler(_id);
                    type === 'assessment' && window.scrollTo(500, 500);
                  }}
                  className={`flex cursor-pointer items-center justify-between py-[15px] pl-[32px] pr-[12px] hover:border-r hover:border-[#007D6F] hover:bg-[#F3F8F6] ${
                    _id === params.category && 'border-r border-[#007D6F] bg-[#F3F8F6]'
                  } ${count === 0 && 'pointer-events-none'} ${
                    isHidden(parent, parentCategory) || (count === 0 && name !== 'All Results')
                      ? 'hidden'
                      : ''
                  }`}>
                  <div className="flex items-center gap-[6px]">
                    {_id === 'recommended' && (
                      <div className="h-[18px] w-[18px] rounded bg-[#F1F8F5] px-[4px] py-[2px]">
                        <img src={defaultLogo} />
                      </div>
                    )}
                    {parent && _id !== 'recommended' && (
                      <div className="w-[18px]">
                        {isCollapsable && (
                          <FontAwesomeIcon
                            icon={collapsed ? faPlus : faMinus}
                            className="h-[16px] w-[16px] text-[16px] text-[#2F6E63] group-hover:text-[#12A182]"
                          />
                        )}
                      </div>
                    )}
                    <Typography
                      className={`${!parent && 'pl-[35px]'} ${count === 0 && 'text-[#8C9196]'}`}
                      variant={parent ? 'heading-h6' : 'regular-default'}
                      title={
                        type !== 'assessment'
                          ? name === 'Hirenest Recommended'
                            ? 'Featured Tests'
                            : name
                          : currentAssessment?.template
                          ? name === 'Hirenest Recommended'
                            ? 'Ready to Go Assessment'
                            : name
                          : name
                      }
                    />
                  </div>
                  <Typography
                    className="text-[#6D7175]"
                    variant="small-default"
                    title={
                      name === 'Hirenest Recommended' && type === 'assessment'
                        ? currentAssessment?.template
                          ? recommendedTestsLength
                          : genericTemplatesLength
                        : count
                    }
                  />
                </div>
              );
            }
          )}
        </StickyBox>
      </div>
      <div id="testsInfo" className="relative flex flex-3 flex-col gap-6 p-[32px]">
        {params?.search?.length === 0 ? (
          <div className="flex gap-4">
            <div className="flex flex-col gap-[6px]">
              {currentCategory?.parentCategory && (
                <Typography
                  className="text-[#6D7175]"
                  variant="heading-h6"
                  title={currentCategory?.parentCategory}
                />
              )}
              <div className="flex items-center gap-[6px]">
                {params?.category === 'recommended' && !currentAssessment?.template && (
                  <div className="h-[18px] w-[18px] rounded bg-[#F1F8F5] px-[4px] py-[2px]">
                    <img src={defaultLogo} />
                  </div>
                )}
                <Typography
                  variant="heading-h3"
                  title={
                    params?.category === 'recommended'
                      ? currentAssessment?.template
                        ? `Ready to Go: ${currentAssessment?.jobRole} Assessment`
                        : type !== 'assessment'
                        ? 'Featured Tests'
                        : 'Recommended Generic Templates'
                      : currentCategory?.name
                  }
                />
              </div>
              {params?.category === 'recommended' && type !== 'assessment' && (
                <div className="relative">
                  <p className="relative z-[1] text-lg text-[#082623]">
                    Welcome to our pre-employment assessment platform!
                  </p>
                  <div
                    style={{ transform: 'skew(-170deg)' }}
                    className="absolute left-[9px] top-[9px] h-[20px] w-[460px] bg-[#FFF5EA]"
                  />
                </div>
              )}
              <HtmlTextReplacer
                className="mt-2"
                html={
                  params?.category === 'recommended'
                    ? type !== 'assessment'
                      ? recommendedCategory?.description
                      : currentAssessment?.template
                      ? ''
                      : `Not sure which tests to select?<br /><br />Our generic templates offer comprehensive assessments suitable for all professions, simplifying your pre-employment screening process.<br /><br />You can select your candidate's job role for a more advanced, role-specific assessment.`
                    : currentCategory?.description
                }
              />
            </div>
            {params?.category === 'recommended' && type !== 'assessment' && (
              <img height={300} width={300} src={recommendedCategory?.cover} />
            )}
          </div>
        ) : (
          <div className="text-xl font-medium">
            Searching <b>'{params?.search}'</b> tests
          </div>
        )}
        {dataLength >= 0 && (
          <InfiniteScroll
            dataLength={dataLength}
            next={onScroll}
            className="flex flex-col gap-3"
            hasMore={params?.category === 'recommended' ? false : hasMore}
            loader={<Lottie options={defaultOptions} height={50} width={50} />}>
            {tests?.length > 0
              ? tests?.map(props =>
                  props?.category ? (
                    <TestCardNew
                      key={props?._id}
                      previewTestHandler={id => previewTestHandler(id, true)}
                      {...props}
                      actionsComponent={
                        type === 'assessment' &&
                        (!currentAssessment?.tests?.find(({ _id }) => props?._id === _id)
                          ? addTestComponent(props)
                          : removeTestComponent(props?._id))
                      }
                    />
                  ) : (
                    <GenericAssessmentCard
                      key={props?._id}
                      previewTestHandler={previewTestHandler}
                      {...props}
                      actionsComponent={
                        type === 'assessment' && addTestComponent(props?.tests, true)
                      }
                    />
                  )
                )
              : params?.search?.length > 0 &&
                !loading && (
                  <div className="flex flex-col items-center gap-6">
                    <img
                      src={isNoTestComplete ? noTestComplete : noTest}
                      width={300}
                      height={243}
                    />
                    {allTests?.length > 0 ? (
                      <div className="w-[580px] text-center text-lg">
                        We were unable to find <b>{params?.search}</b> in{' '}
                        <b>{currentCategory?.name}</b> but we found it in other categories
                      </div>
                    ) : isNoTestComplete ? (
                      <>
                        <Typography
                          className="w-[400px] text-center"
                          variant="heading-h5"
                          title="Thank you! We will add this test soon."
                        />
                        <Button
                          className="z-[2]"
                          onClick={browseAllTestHandler}
                          variant="primary-outline"
                          title="Browse All Tests"
                        />
                        <Typography
                          className="max-w-[400px] text-center text-[#6D7175]"
                          variant="small-default"
                          title="If you still cannot find what you need, contact us and we'll do our best to help."
                        />
                        <CurvedArrow color="#D2D5D8" lineWidth={1} arrowSize={5} />
                      </>
                    ) : (
                      <>
                        <Typography
                          className="w-[400px] text-center"
                          variant="heading-h5"
                          title="Sorry, the test you are looking for is not currently available in our library"
                        />
                        <div className="flex flex-col gap-[4px]">
                          <Typography
                            variant="regular-default"
                            title="Tell us what test you need and we'll add it"
                          />
                          <Form
                            className="flex"
                            defaultValues={{ name: params?.search }}
                            onSubmit={data => requestTestHandler(data)}>
                            <Input
                              name="name"
                              placeholder="test..."
                              className="h-[40px] flex-1 rounded-r-none"
                            />
                            <Button
                              className="min-h-[40px] min-w-[140px] rounded-l-none"
                              variant="primary"
                              type="submit"
                              title="Request Test"
                            />
                          </Form>
                        </div>
                      </>
                    )}
                    {allTests?.map(props => (
                      <TestCardNew
                        key={props?._id}
                        previewTestHandler={id => previewTestHandler(id, true)}
                        {...props}
                        actionsComponent={
                          type === 'assessment' &&
                          (!currentAssessment?.tests?.find(({ _id }) => props?._id === _id)
                            ? addTestComponent(props)
                            : removeTestComponent(props?._id))
                        }
                      />
                    ))}
                  </div>
                )}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
