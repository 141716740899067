import React from 'react';

import cn from 'utils/classNames';

interface ModalHeaderProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        'p-4 flex items-center justify-between border-b border-gray-300 sticky top-0 bg-inherit z-[1050] rounded-t-lg',
        className
      )}>
      {children}
    </div>
  );
};
