import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { Form, Typography, Input } from 'ui-kit';

export const WebsiteTab = ({ defaultValues, onSubmit }) => (
  <Form
    className="space-y-5 p-3"
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    onBlur
    isReset={true}
    mode="onChange">
    {() => (
      <div className="flex-2 space-y-1">
        <div>
          <Typography
            title="Meta"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <Input className="mb-[24px]" name="meta.title" placeholder="Title" />
          <RichTextEditor
            name="meta.description"
            placeholder="Meta Description"
            classNameWrapper="mb-[72px]"
          />
        </div>
        <div>
          <Typography
            title="Heading 1"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <Input className="mb-[24px]" name="heading1.heading" placeholder="Heading" />
          <RichTextEditor
            name="heading1.subheading"
            placeholder="Subheading"
            classNameWrapper="mb-[72px]"
          />
        </div>
        <div>
          <Typography
            title="Heading 2"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <Input className="mb-[24px]" name="heading2.headingA" placeholder="Heading (A)" />
          <RichTextEditor
            name="heading2.subheadingA"
            placeholder="Subheading (A)"
            classNameWrapper="mb-[36px]"
          />
        </div>
        <div>
          <Input className="mb-[24px]" name="heading2.headingB" placeholder="Heading (B)" />
          <RichTextEditor
            name="heading2.subheadingB"
            placeholder="Subheading (B)"
            classNameWrapper="mb-[72px]"
          />
        </div>
        <div>
          <Typography
            title="Heading 3"
            variant="small-default"
            className="mb-[24px] px-0 text-[20px] font-semibold"
          />
          <Input className="mb-[24px]" name="heading3.headingA" placeholder="Heading (A)" />
          <RichTextEditor
            name="heading3.subheadingA"
            placeholder="Subheading (A)"
            classNameWrapper="mb-[36px]"
          />
        </div>
        <div>
          <Input className="mb-[24px]" name="heading3.headingB" placeholder="Heading (B)" />
          <RichTextEditor name="heading3.subheadingB" placeholder="Subheading (B)" />
        </div>
      </div>
    )}
  </Form>
);
