import React from 'react';

import cn from 'utils/classNames';

interface ModalBodyProps {
  children: React.ReactNode;
  className?: string;
}

export const ModalBody: React.FC<ModalBodyProps> = ({ children, className }) => {
  return (
    <div className={cn('p-4 border-b border-gray-300', className)}>{children}</div>
  );
};
