export const variants: {
  [key: string]: {
    titleVariant?: any,
    active: string,
    noActive: string,
    onClick: string,
    className: string,
    deleteIcon: string,
    iconSize: number
  }
} = {
  primary: {
    active: '',
    noActive: '',
    onClick: '',
    className: 'py-2 px-4 bg-gray-300 rounded-lg flex gap-2 items-center relative',
    deleteIcon: 'absolute top-0 right-0 translate-x-1/2 -translate-y-1/2',
    iconSize: 10,
    titleVariant: 'body-bold'
  },
  negative: {
    active: 'bg-orange-400',
    noActive: 'bg-orange-200',
    onClick: 'cursor-pointer bg-orange-400',
    className:
      'flex items-center gap-1 rounded-full py-2 px-4 border border-orange-500 hover:bg-orange-400 transition-all cursor-pointer',
    deleteIcon: 'rounded-full h-5 w-5 flex items-center justify-center',
    iconSize: 14
  },
  positive: {
    active: 'bg-green-400',
    noActive: 'bg-green-200',
    onClick: 'cursor-pointer bg-green-400',
    className:
      'flex items-center gap-1 rounded-full py-2 px-4 border border-green-500 hover:bg-green-400 transition-all cursor-pointer',
    deleteIcon: 'rounded-full h-5 w-5 flex items-center justify-center',
    iconSize: 14
  },
  neutral: {
    active: 'bg-yellow-400',
    noActive: 'bg-yellow-200',
    onClick: 'cursor-pointer bg-yellow-400',
    className:
      'flex items-center gap-1 rounded-full py-2 px-4 border border-yellow-500 hover:bg-yellow-400 transition-all cursor-pointer',
    deleteIcon: 'rounded-full h-5 w-5 flex items-center justify-center',
    iconSize: 14
  },
  published: {
    active: '',
    noActive: '',
    onClick: '',
    className:
      'py-2 px-4 bg-green-400 rounded-lg flex gap-2 items-center relative text-white capitalize w-fit',
    deleteIcon: 'absolute top-0 right-0 translate-x-1/2 -translate-y-1/2',
    iconSize: 10,
    titleVariant: 'small-bold'
  },
  draft: {
    active: '',
    noActive: '',
    onClick: '',
    className:
      'py-2 px-4 bg-gray-300 rounded-lg flex gap-2 items-center relative text-gray-900 capitalize w-fit',
    deleteIcon: 'absolute top-0 right-0 translate-x-1/2 -translate-y-1/2',
    iconSize: 10,
    titleVariant: 'small-bold'
  },
  archived: {
    active: '',
    noActive: '',
    onClick: '',
    className:
      'py-2 px-4 bg-yellow-400 rounded-lg flex gap-2 items-center relative text-gray-900 capitalize w-fit',
    deleteIcon: 'absolute top-0 right-0 translate-x-1/2 -translate-y-1/2',
    iconSize: 10,
    titleVariant: 'small-bold'
  },
  default: {
    active: 'bg-gray-300',
    noActive: 'bg-gray-200',
    onClick: 'cursor-pointer',
    className: 'flex items-center gap-1 rounded py-1 px-2 border border-gray-400',
    deleteIcon: 'rounded-full h-5 w-5 flex items-center justify-center',
    iconSize: 14,
    titleVariant: 'small'
  }
};
