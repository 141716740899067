import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grade } from 'components/Grade/Grade';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { useMemo } from 'react';
import { FieldArray, Form, Input, Typography } from 'ui-kit';
import { FormatOptionLabel } from 'ui-kit/CustomSelect/SelectCustom';
import {
  resultInterpretationCalculate,
  resultInterpretationValidation
} from 'utils/resultInterpretationCalculate';

export const ResultInterpretation = ({
  name,
  _id,
  mode,
  label,
  resultInterpretation,
  blocksRef,
  scrollToBlock,
  index,
  questions,
  method,
  sections,
  chartSettings,
  onSubmit
}) => {
  const resultArr = resultInterpretationCalculate(sections, method, mode, name, _id);
  const allTags = useMemo(() => {
    const tags = questions?.map(({ tags }) => tags)?.flat();

    if (!tags) {
      return;
    }

    const uniqueValues = {};

    for (const item of tags) {
      const value = item.value;
      uniqueValues[value] = item;
    }

    const uniqueData = Object.values(uniqueValues);

    return uniqueData;
  }, [questions]);
  return (
    <Form
      defaultValues={{
        resultInterpretation: {
          ...resultInterpretation
        }
      }}
      mode="all"
      resetDefault={false}
      resolver={yupResolver(resultInterpretationValidation(resultArr))}
      onBlur
      onSubmit={values => {
        onSubmit({
          resultInterpretation: {
            ...values.resultInterpretation,
            min: resultArr[0],
            max: resultArr[1]
          }
        });
      }}>
      {({ formState, unregister, handleSubmit, getValues }) => (
        <div
          ref={el => (blocksRef.current[index] = el)}
          id={label || _id}
          className={`flex flex-col ${
            chartSettings?.interpretationType !== 'simple' && mode !== 'Tag'
              ? 'gap-[36px]'
              : 'gap-3'
          } rounded-lg border border-[#C9CCCF] bg-[#FAFBFB] p-6`}>
          <div className="flex flex-col gap-3">
            <div className="flex justify-between">
              {mode === 'Tag' && <FormatOptionLabel label={label} />}
              {mode !== 'Tag' && <Typography title={name} variant="heading-h5" />}
              {chartSettings?.interpretationType !== 'simple' && mode !== 'Tag' && (
                <div className="flex items-center gap-3">
                  <Typography variant="small-default" title="Color Hue" />
                  <Input
                    name="resultInterpretation.dimensionScales.hue"
                    width="w-[55px]"
                    className="h-[33px] w-[55px]"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-end">
              <ErrorMessage
                errors={formState.errors}
                name="resultInterpretation.dimensionScales.hue"
                render={({ message }) => <Typography variant={'error'} title={message} />}
              />
            </div>
            {mode !== 'Overall' && (
              <RichTextEditor
                placeholder={
                  chartSettings?.interpretationType !== 'simple'
                    ? `${mode !== 'Tag' ? 'Demension' : 'Tag'} Description...`
                    : `${mode} Description...`
                }
                name="resultInterpretation.description"
                classNameWrapper="bg-white"
              />
            )}
          </div>
          {chartSettings?.interpretationType !== 'simple' && mode !== 'Tag' && (
            <div className="flex flex-col gap-3">
              <Typography variant="heading-h6" title="Scale" />
              <div className="flex gap-3">
                <div className="flex flex-1 flex-col gap-3">
                  <Input
                    placeholder="Low Scale Name..."
                    name="resultInterpretation.dimensionScales.low.name"
                  />
                  <RichTextEditor
                    placeholder="Low Scale Description..."
                    name="resultInterpretation.dimensionScales.low.description"
                    classNameWrapper="bg-white"
                  />
                </div>
                <div className="flex flex-1 flex-col gap-3">
                  <Input
                    placeholder="High Scale Name..."
                    name="resultInterpretation.dimensionScales.high.name"
                  />
                  <RichTextEditor
                    placeholder="High Scale Description..."
                    name="resultInterpretation.dimensionScales.high.description"
                    classNameWrapper="bg-white"
                  />
                </div>
              </div>
            </div>
          )}
          {chartSettings?.interpretationType !== 'simple' && mode !== 'Tag' && (
            <Typography className="mb-[-20px]" title="Grades" variant="heading-h6" />
          )}
          <div className="flex gap-4">
            <div className="flex flex-1 flex-col gap-4">
              <div className="ml-[11px] flex max-w-[130px] justify-between">
                <div className="flex h-[30px] w-[40px] items-center justify-center rounded bg-[#DBDDDF] py-[1px] px-[8px]">
                  {resultArr[0]}
                </div>
                -
                <div className="flex h-[30px] w-[40px] items-center justify-center rounded bg-[#DBDDDF] py-[1px] px-[8px]">
                  {resultArr[1]}
                </div>
              </div>
              <FieldArray
                name="resultInterpretation.grades"
                keyExtractor={({ id }) => id}
                element={({ idx, control }) => (
                  <>
                    <Grade
                      minName={`resultInterpretation.grades[${idx}].scale.min`}
                      maxName={`resultInterpretation.grades[${idx}].scale.max`}
                      gradeName={`resultInterpretation.grades[${idx}].title`}
                      descriptionName={`resultInterpretation.grades[${idx}].description`}
                      placeholder={false}
                      onClick={() => {
                        control.remove(idx);
                        const values = getValues();
                        handleSubmit(onSubmit(values));
                      }}
                    />
                    <ErrorMessage
                      errors={formState.errors}
                      name={`resultInterpretation.grades[${idx}].scale.min`}
                      render={({ message }) => <Typography variant={'error'} title={message} />}
                    />
                    <ErrorMessage
                      errors={formState.errors}
                      name={`resultInterpretation.grades[${idx}].scale.max`}
                      render={({ message }) => <Typography variant={'error'} title={message} />}
                    />
                    <ErrorMessage
                      errors={formState.errors}
                      name={`resultInterpretation.grades[${idx}].title`}
                      render={({ message }) => <Typography variant={'error'} title={message} />}
                    />
                    <ErrorMessage
                      errors={formState.errors}
                      name={`resultInterpretation.grades[${idx}].description`}
                      render={({ message }) => <Typography variant={'error'} title={message} />}
                    />
                  </>
                )}
                wrap="div"
                className="flex flex-col gap-3"
                controller={({ control }) => (
                  <Grade control={control} unregister={unregister} placeholder />
                )}
              />
              {mode !== 'Tag' && chartSettings?.interpretationType !== 'simple' && (
                <div className="mt-3 flex flex-col gap-3">
                  <Typography variant="heading-h6" title="Traits" />
                  <div className="flex flex-wrap gap-3">
                    {allTags?.map(({ label, _id }) => (
                      <div
                        onClick={() => scrollToBlock(label)}
                        className="cursor-pointer"
                        key={_id}>
                        <FormatOptionLabel label={label} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Form>
  );
};
