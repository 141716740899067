//common
export const PROFILE = '/me';
export const HOME = '/';
export const DASHBOARD = '/dashboard';
export const COMPANY = '/company';
export const LOG_OUT = '/log-out';
export const PRIVACY_POLICY = '/privacy-policy';
export const EULA = '/eula';
export const HELP = '/help';
export const FEEDBACK = '/feedback';
export const ERROR_PAGE = '/error-page';
export const SUBSCRIPTIONS = '/subscription';
export const FAILED = '/failed';
export const UPGRADE = '/upgrade';
export const RESET = '/reset';

//Auth
export const AUTH = '/auth';
export const SIGNUP = 'signup';
export const SIGNIN = 'signin';
export const RESET_PASSWORD = 'reset-password';
export const EMAIL_VERIFY = 'email-verify';
export const CREATE_NEW_PASSWORD = 'new-password';

//Common actions
export const CREATE = '/create';
export const EDIT = '/edit';
export const PUBLISH = '/publish';

// Candidate application event
export const EMPLOYEE_STEPS = '/start';
export const CANDIDATE_INVITE = '/invite';
export const CANDIDATE_SIGN_UP = '/application-sign-up';
export const CANDIDATE_ROADMAP = '/application-roadmap';
export const CANDIDATE_SECTION_INSTRUCTIONS = '/application-section';
export const CANDIDATE_QUESTION = '/application-question';
export const CANDIDATE_CERTIFICATE_OLD = '/pae-certificate';
export const CANDIDATE_CERTIFICATE = '/pea-certificate';
export const CANDIDATE_ASSESSMENT_ARCHIVED = '/application-archived';
export const CANDIDATE_ASSESSMENT_FORBIDDEN = '/application-forbidden';
export const CANDIDATE_ASSESSMENT_TWICE = '/application-twice';
export const CANDIDATE_APPLICATION_FEEDBACK = '/application-feedback';
export const CANDIDATE_APPLICATION_VERIFY = '/candidate/verify';
export const CANDIDATE_APPLICATION_COMPLETED = '/application-completed';
export const CANDIDATE_TEST_FEEDBACK = '/test-feedback';
export const CANDIDATE_EMAIL_CONFIRM = '/application-email-confirm';

//role - Author
export const TESTS = '/tests';
export const ASSESSMENT_TEMPLATES = '/assessment-templates';
export const ASSESSMENT_TEMPLATES_CREATE = ASSESSMENT_TEMPLATES + CREATE;
export const ASSESSMENT_TEMPLATES_EDIT = ASSESSMENT_TEMPLATES + '/:_id' + EDIT;
export const ASSESSMENT_TEMPLATES_VIEW = ASSESSMENT_TEMPLATES + '/:_id';
export const CREATE_TEST = TESTS + CREATE;
export const VIEW_TEST = TESTS + '/:_id';
export const EDIT_TEST = TESTS + '/:_id' + EDIT;
export const PUBLISH_TEST = TESTS + '/:_id' + PUBLISH;

//role = Employer
export const CANDIDATES = '/candidates';
export const ASSESSMENTS = '/assessments';
export const APPLICATIONS = '/applications';
export const CREATE_ASSESSMENT = ASSESSMENTS + CREATE;
export const EDIT_ASSESSMENT = ASSESSMENTS + '/:_id' + EDIT;
export const VIEW_ASSESSMENT = ASSESSMENTS + '/:_id';
export const VIEW_APPLICATION = ASSESSMENTS + '/:assessmentId' + APPLICATIONS + '/:applicationId';
export const VIEW_TEST_REPORT =
  ASSESSMENTS + '/:assessmentId' + APPLICATIONS + '/:applicationId' + '/test/:testId';
export const VIEW_CANDIDATE = CANDIDATES + '/:_id';

//Administrator
export const AUTHORS = '/authors';
export const AUTHORS_PROFILE = AUTHORS + '/:_id';
export const EMPLOYERS = '/employers';
export const COMPANY_ALL = '/companies';
export const EMPLOYER_VIEW = EMPLOYERS + '/:_id';
export const COMPANY_VIEW = EMPLOYERS + COMPANY_ALL + '/:_id';

export const CONFIGURATION = '/configuration';
export const ACCOUNTS = 'accounts';
export const ADMINS = 'admins';
export const ATTACHMENTS = 'attachments';
export const ASSESSMENTS_CONF = 'assessments';
export const BILLING = 'billing';
export const AUTHORCONF = 'authors';
export const EMPLOYERSCONF = 'employers';
export const CANDIDATESCONF = 'candidates';
export const SUBSCRIPTION_PLANS = 'subscription-plans';
export const COMPANIES = 'companies';
export const COMMON = 'common';
export const OCCUPATIONS = 'occupations';
export const NOTIFICATIONS = 'notifications';
export const GENERAL = 'general';
export const CONFIGURATION_TESTS = 'tests';
export const CONFIGURATION_TEST_CATEGORIES = 'test-categories';
export const CONFIGURATION_TEST_MAPPING = 'test-mapping';
export const CONFIGURATION_ANSWER_CHOISES = 'answer-choices';

export const CONFIGURATION_TEST_CATEGOIRES_ROUTE =
  CONFIGURATION + '/' + CONFIGURATION_TESTS + '/' + CONFIGURATION_TEST_CATEGORIES;

export const EDIT_ADMIN = CONFIGURATION + '/' + ADMINS + '/:_id';

export const CONFIGURATION_TEST_MAPPING_ROUTE =
  CONFIGURATION + '/' + OCCUPATIONS + '/' + CONFIGURATION_TEST_MAPPING;

export const CONFIGURATION_OCCUPATION_SINGLE_ROUTE = CONFIGURATION + '/' + OCCUPATIONS + '/:_id';

export const CONFIGURATION_TEST_MAPPING_SINGLE =
  CONFIGURATION + '/' + OCCUPATIONS + '/' + CONFIGURATION_TEST_MAPPING + '/:_id';

export const CONFIGURATION_ANSWER_CHOISES_ROUTE =
  CONFIGURATION + '/' + CONFIGURATION_TESTS + '/' + CONFIGURATION_ANSWER_CHOISES;
