import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import url from 'utils/url';

export const useQueryParams = (initialParams = {}) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const params = useMemo(
    () =>
      search
        .substring(1)
        .split('&')
        .reduce((acc, param) => {
          const [key] = param.split('=');

          const value = new URLSearchParams(search).get(key);

          if (value) {
            acc[key] = value;
          }

          return acc;
        }, initialParams as { [key: string]: string }),
    [search]
  );

  const push = useCallback(
    (object: object) => {
      navigate(url([pathname], { ...params, ...object }, true));
    },
    [params, pathname]
  );

  const set = useCallback(
    (object: object, options: object = {}) => {
      navigate(url([pathname], object, true), options);
    },
    [params, pathname]
  );

  return { params, push, set };
};
