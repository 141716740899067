import { Button, Icon, Modal, Typography } from 'ui-kit';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { setApplicationStatusRequest, updateApplication } from 'store/candidates/actions';
import { StarsRating } from 'components/StarsRating/StarsRating';
import { faStar, faXmark } from '@fortawesome/pro-light-svg-icons';

const ratings = [
  { label: 'Educational background', value: 'educationalBackground' },
  { label: 'Relevant work experience', value: 'relevantWorkExpirience' },
  { label: 'Leadership skills', value: 'leadershipSkills' },
  { label: 'Communication skills', value: 'communiationSkills' },
  { label: 'Attitude and motivation', value: 'attitudeAndMotivation' }
];

const RateModal = ({ isRateModalOpen, setRateModalOpen, applicationId, rates, isDemo }) => {
  const [rate, setRate] = useState(rates);
  const dispatch = useDispatch();

  useEffect(() => {
    setRate(rates);
  }, [isRateModalOpen]);

  useEffect(() => {
    if (Object.keys(rate.ratings).length < 5) {
      return;
    }
    const allRatingsGreaterThanZero = Object.values(rate.ratings).every(val => val > 0);

    if (allRatingsGreaterThanZero) {
      const sumOfRatings = Object.values(rate.ratings).reduce((acc, val) => acc + val, 0);
      const averageRating = sumOfRatings / Object.values(rate.ratings).length;

      setRate(prevState => ({
        ...prevState,
        rating: averageRating
      }));
    }
  }, [rate.ratings]);
  return (
    <Modal
      isOpen={isRateModalOpen}
      onClose={() => setRateModalOpen(false)}
      className="m-auto w-full max-w-[900px]">
      <Modal.Header className="px-6">
        <Typography variant="heading-h2" title="Rate the Interview" />
        <Button
          icon={faXmark}
          iconSize={20}
          iconColor="#5C5F62"
          onClick={() => setRateModalOpen(false)}
        />
      </Modal.Header>
      <Modal.Body className="flex gap-4 p-6">
        <div className="flex flex-1 flex-col gap-4">
          {ratings?.map(({ value, label }) => (
            <div key={value} className="flex items-center justify-between gap-6">
              <Typography variant="regular-default" title={label} />
              <div className="space-x-1">
                {Array(5)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <button
                        key={index}
                        onClick={() =>
                          setRate(prev => {
                            return {
                              ...prev,
                              ratings: { ...prev.ratings, [value]: index + 1 }
                            };
                          })
                        }>
                        <Icon
                          icon={faStar}
                          size={20}
                          color={rate.ratings[value] > index ? '#FFC96B' : '#E2E2E2'}
                          className={'rounded hover:bg-gray-200'}
                        />
                      </button>
                    );
                  })}
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-1 flex-col items-center justify-center gap-12">
          <div className="flex flex-col items-center gap-2">
            <Typography
              className="font-semibold"
              variant="regular-default"
              title="Overall Interview Score"
            />
            <StarsRating rate={rate.rating} setRate={setRate} />
            {rate.rating > 0 && (
              <Typography
                className="text-[#6D7175]"
                variant="regular-default"
                title={rate?.rating?.toFixed(1)}
              />
            )}
          </div>
          <Typography
            className="text-[#6D7175]"
            variant="small-default"
            title="The Interview Score is calculated as an average"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end gap-4">
        <div className="flex justify-end gap-4">
          <Button
            variant="primary-outline"
            type="submit"
            title="Cancel"
            onClick={() => {
              setRateModalOpen(false);
            }}
          />
          <Button
            disabled={!rate.rating}
            variant="primary"
            title={'Rate the Interview'}
            onClick={() => {
              if (isDemo) {
                dispatch(updateApplication({ ...rate, status: 'Interviewed' }));
              } else {
                dispatch(
                  setApplicationStatusRequest({
                    id: applicationId,
                    data: { status: 'Interviewed', ...rate }
                  })
                );
              }
              setRateModalOpen(false);
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RateModal;
