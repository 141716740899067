import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleOccupationRequest } from 'store/configuration/actions/subscriptions';
import {
  archiveTemplateRequest,
  draftTemplateRequest,
  getTemplateRequest,
  getCurrentTestRequest
} from 'store/tests/actions';
import { Button, Section, Typography } from 'ui-kit';
import { badgetStatus } from 'utils/helpers';
import { SkillCard } from '../AssessmentTemplateCreate/AssessmentTemplateCreate';
import * as routes from 'routes/routes';
import { TestCardNew } from 'components/TestCardNew/TestCardNew';
import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';

export const AssessmentTemplatesView = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, currentTemplate } = useSelector(store => store.tests);
  const { loading: load, occupation } = useSelector(store => store.subscriptions);
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const { jobRoles, totalTests, recommendedTests } = currentTemplate;

  useEffect(() => {
    _id && dispatch(getTemplateRequest(_id));
  }, [_id]);

  const archiveTemplateHandle = () => {
    dispatch(archiveTemplateRequest(_id));
  };

  const editTemplateHandle = () => {
    try {
      dispatch(draftTemplateRequest(_id));
      navigate(routes.ASSESSMENT_TEMPLATES + '/' + _id + routes.EDIT);
    } catch (error) {
      console.log(error);
    }
  };

  const previewTestHandler = id => {
    dispatch(getCurrentTestRequest(id));
    setViewModalOpen(true);
  };

  useEffect(() => {
    currentTemplate?.occupation && dispatch(getSingleOccupationRequest(currentTemplate.occupation));
  }, [currentTemplate?.occupation]);

  return (
    <div className="py-8 px-7.5">
      {(load || loading) && <Loader />}
      <Breadcrumbs type="assessmentTemplates" />
      <div className="flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="flex items-center gap-[12px]">
            <Typography
              className="max-w-[850px]"
              title={currentTemplate?.name}
              variant="heading-h1"
            />
            {_id && (
              <span
                className={`rounded ${badgetStatus(
                  currentTemplate?.status
                )} flex h-[25px] items-center justify-center py-[1px] px-[6px] text-xs font-semibold`}>
                {`${currentTemplate?.status?.toUpperCase()}`}
              </span>
            )}
            {currentTemplate?.type === 'generic' && (
              <span className="flex h-[25px] items-center justify-center rounded bg-[#E0ECF9] px-[6px] py-[1px] text-xs font-bold text-[#337195] shadow-small">
                GENERIC TEMPLATE
              </span>
            )}
          </div>
          <div className="flex min-w-[400px] items-center justify-end gap-3">
            {currentTemplate?.status === 'published' && (
              <Button
                variant="secondary"
                title="Archive Template"
                onClick={() => archiveTemplateHandle()}
              />
            )}
            <Button variant="primary" title="Edit Template" onClick={() => editTemplateHandle()} />
          </div>
        </div>
        {jobRoles?.length > 0 && (
          <Section>
            <Section.Body>
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-3">
                  <Typography variant="heading-h5" title="Job Roles" />
                  <div className="rounded border border-[#C9CCCF] bg-[#E4E5E7] px-[8px] py-[2px]">
                    {jobRoles?.length}
                  </div>
                </div>
                <div className="flex flex-wrap gap-3">
                  {jobRoles?.map(role => (
                    <div
                      className="rounded border border-[#C9CCCF] bg-[#F6F6F7] px-[12px] py-[2px]"
                      key={role}>
                      {role}
                    </div>
                  ))}
                </div>
              </div>
            </Section.Body>
          </Section>
        )}
        {occupation?._id && (
          <Section>
            <Section.Header className="flex items-center justify-between">
              <div className="flex gap-3">
                <Typography variant="heading-h2" title="Occupation" />
                <Typography
                  className="text-[#6D7175]"
                  variant="heading-h2"
                  title={occupation?.title}
                />
              </div>
              <a
                href={`https://www.onetonline.org/link/details/${occupation?.code}`}
                target="_blank"
                rel="noreferrer">
                <Button
                  icon="onet"
                  className="bg-white"
                  variant="primary-outline"
                  title="onetonline.org"
                />
              </a>
            </Section.Header>
            <Section.Body className="grid grid-cols-3 gap-8">
              {occupation?.skills && (
                <SkillCard
                  arr={occupation?.skills}
                  disabled
                  header="Skills"
                  recommendedTests={recommendedTests}
                />
              )}
              {occupation?.technology_skills && (
                <SkillCard
                  arr={occupation?.technology_skills}
                  disabled
                  header="Technology Skills"
                  recommendedTests={recommendedTests}
                />
              )}
              {occupation?.abilities && (
                <SkillCard
                  arr={occupation?.abilities}
                  disabled
                  header="Abilities"
                  recommendedTests={recommendedTests}
                />
              )}
              {occupation?.work_styles && (
                <SkillCard
                  arr={occupation?.work_styles}
                  disabled
                  header="Work Styles"
                  recommendedTests={recommendedTests}
                />
              )}
              {occupation?.knowledge && (
                <SkillCard
                  arr={occupation?.knowledge}
                  disabled
                  header="Knowledge"
                  recommendedTests={recommendedTests}
                />
              )}
              {occupation?.work_context && (
                <SkillCard
                  arr={occupation?.work_context}
                  disabled
                  header="Work Context"
                  recommendedTests={recommendedTests}
                />
              )}
              {occupation?.work_activities && (
                <SkillCard
                  arr={occupation?.work_activities}
                  disabled
                  header="Work Activities"
                  recommendedTests={recommendedTests}
                />
              )}
            </Section.Body>
          </Section>
        )}
        <Section>
          <Section.Header className="flex items-center gap-3">
            <div className="bg-[#F6F6F7] px-[8px] py-[2px]">{totalTests}</div>
            <Typography variant="heading-h2" title="Recommended Tests" />
          </Section.Header>
          <Section.Body className="flex flex-col gap-[72px]">
            <div className="flex flex-wrap gap-6">
              {currentTemplate?.recommendedTests.map(
                ({ name, _id, icon, category, timeLimit, estimatedDuration }) => (
                  <TestCardNew
                    key={_id}
                    previewTestHandler={previewTestHandler}
                    icon={icon}
                    category={category}
                    name={name}
                    _id={_id}
                    timeLimit={timeLimit}
                    estimatedDuration={estimatedDuration}
                    className="w-full"
                    containerClassName="w-full"
                  />
                )
              )}
            </div>
          </Section.Body>
        </Section>
      </div>
      <ViewTestModal isOpenModal={viewModalOpen} setIsOpenModal={setViewModalOpen} />
    </div>
  );
};
