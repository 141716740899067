import { Button, CheckBox, Form, Icon, Section, Typography } from 'ui-kit';
import sandGlass from 'assets/lotties/sandGlass.json';
import { HiringCard } from '../HiringCard/HiringCard';
import { ButtonSelect } from 'ui-kit/ButtonSelect/ButtonSelect';
import moment from 'moment';
import Lottie from 'react-lottie';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { StarsRating } from 'components/StarsRating/StarsRating';
import { useDispatch } from 'react-redux';
import { useUserRole } from 'hooks';
import { updateApplication } from 'store/candidates/actions';
import { formatScaledResult } from 'utils/formatScaledResult';
import defaultLogo from 'assets/defaultLogoHirenest.svg';
import { ArrowStepper } from 'components/ArrowStepper/ArrowStepper';
import { localStorageService } from 'helpers/localStorageService';
import {
  faCircleQuestion,
  faGauge,
  faGaugeMax,
  faGaugeMin
} from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

const measure = ['Invited', 'Opened', 'Started'];
const evaluate = ['Not Evaluated'];
const interview = ['Invited for Interview', 'Interviewed'];
const hire = ['Offer Sent', 'Offer Accepted', 'Offer Declined'];
const rejected = ['Rejected', 'Candidate Unresponsive', 'Candidate Withdrew'];

export const calculateColorHirenestScore = score => {
  if (score === -1 || score === undefined || score === null) {
    return {
      title: 'text-[#8C9196] font-[500]',
      icon: faGauge,
      iconColor: '#8C9196',
      score
    };
  }
  if (score <= 33) {
    return {
      title: 'text-[#B75957]',
      bg: 'bg-[#FFDDDD]',
      border: 'border-[#6A3737]',
      result: `Candidate isn't recommended for the interview`,
      icon: faGaugeMin,
      iconColor: '#B75957',
      score
    };
  }

  if (score > 33 && score < 67) {
    return {
      title: 'text-[#9F6D09]',
      bg: 'bg-[#F8E1B8]',
      border: 'border-[#5F4821]',
      result: `We can't recommend a candidate for the interview, but you can try`,
      icon: faGauge,
      iconColor: '#9F6D09',
      score
    };
  }

  if (score >= 67) {
    return {
      title: 'text-[#378178]',
      bg: 'bg-[#C5EDE9]',
      border: 'border-[#0F645B]',
      result: 'Candidate is good for the interview',
      icon: faGaugeMax,
      iconColor: '#378178',
      score
    };
  }

  return {
    title: 'text-[#B75957]',
    bg: 'bg-[#FFDDDD]',
    border: 'border-[#6A3737]',
    result: `Candidate isn't recommended for the interview`,
    icon: faGaugeMin,
    iconColor: '#B75957',
    score
  };
};

const calculateStep = (status, statusBeforeReject) => {
  const steps = {
    [measure.includes(status)]: { declined: false, step: 0 },
    [evaluate.includes(status)]: { declined: false, step: 1 },
    [interview.includes(status)]: { declined: false, step: 2 },
    ['Offer Sent' === status]: { declined: false, step: 3 },
    [evaluate.includes(statusBeforeReject)]: { declined: true, step: 1 },
    [interview.includes(statusBeforeReject)]: { declined: true, step: 2 },
    ['Offer Declined' === status || hire.includes(statusBeforeReject)]: { declined: true, step: 3 },
    ['Offer Accepted' === status]: { declined: false, step: 3, completed: true }
  };

  return steps[true] || { declined: false, step: -1 };
};

const CheckBoxComponent = ({
  application,
  title,
  value,
  setValue,
  setAppOptionalStatus,
  getValues,
  isDemo,
  dispatch,
  isAdmin = false
}) => (
  <div
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      if (isAdmin) return;

      if (interview.includes(application?.status) || hire.includes(application?.status)) {
        setValue(value, !application?.[value]);
        isDemo
          ? dispatch(updateApplication({ ...getValues() }))
          : setAppOptionalStatus(getValues());
      }
    }}
    className={`flex w-max max-w-[100%] ${
      (interview.includes(application?.status) || hire.includes(application?.status)) &&
      'cursor-pointer'
    } items-center gap-[8px] rounded bg-[#FFF3CD] py-[6px] px-[8px] shadow-small`}>
    <CheckBox
      className="pointer-events-none border-[#8C9196] bg-transparent checked:bg-[#6D7175] checked:hover:bg-[#6D7175]"
      disabled={!interview.includes(application?.status) || !hire.includes(application?.status)}
      name={value}
    />
    <p
      className={`text-xs font-bold uppercase tracking-widest ${
        interview.includes(application?.status) ? 'text-[#332701]' : 'text-[#8C9196]'
      }`}>
      {title}
    </p>
  </div>
);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: sandGlass,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

export const HiringStatuses = ({
  application,
  setAppStatus,
  setIsRateModalOpen,
  setAppOptionalStatus,
  restoreApplicationStatus,
  embedded = false
}) => {
  const dispatch = useDispatch();
  const { isAdmin } = useUserRole();
  const [showHiring, setShowHiring] = useState(localStorageService.getShowHiring() || false);
  const [showFullMeasure, setShowFullMeasure] = useState(!embedded);

  const scores = [];

  if (!isAdmin)
    scores.push(
      calculateColorHirenestScore(application?.hirenestScore ?? application?.scaledScore)
    );
  else {
    if (application?.scaledScore) {
      scores.push(calculateColorHirenestScore(application?.scaledScore));
      scores.push({
        bg: 'bg-[#8C9196]',
        title: 'text-[#8C9196] font-[500] text-[22px] ml-[4px] font-normal',
        border: 'border-[#8C9196]',
        icon: null,
        score: application?.hirenestScore
      });
    } else scores.push(calculateColorHirenestScore(application?.hirenestScore));
  }

  return (
    <Section className="flex-1">
      <Section.Header className="flex items-center justify-between">
        <Typography title="Hiring Workflow" variant="heading-h2" />
        {!measure.includes(application?.status) && (
          <>
            {!rejected.includes(application?.status) ? (
              <ButtonSelect
                className="px-[16px] py-[8px]"
                buttonTitle="Reject Application"
                iconColor="text-[#B31F13]"
                disabled={isAdmin}>
                {rejected?.map(status => (
                  <button
                    key={status}
                    onClick={() =>
                      application?.assessment?.demo?.isDemo
                        ? dispatch(
                            updateApplication({
                              status: status,
                              statusBeforeReject: application?.status
                            })
                          )
                        : setAppStatus(status)
                    }
                    className="flex w-full px-[13px] py-[8px] hover:bg-gray-100"
                    role="menuitem">
                    <p className="flex w-max items-center justify-center rounded bg-[#F8D7DA] px-[6px] py-[4px] text-xs font-bold tracking-wider text-[#2C0B0E] shadow-small">
                      {status === 'Rejected'
                        ? 'Application Rejected'?.toUpperCase()
                        : status?.toUpperCase()}
                    </p>
                  </button>
                ))}
              </ButtonSelect>
            ) : (
              <Button
                onClick={() =>
                  application?.assessment?.demo?.isDemo
                    ? dispatch(
                        updateApplication({
                          status: application?.statusBeforeReject,
                          statusBeforeReject: null
                        })
                      )
                    : restoreApplicationStatus()
                }
                variant="primary-outline"
                title="Restore Application"
                disabled={isAdmin}
              />
            )}
          </>
        )}
      </Section.Header>
      <Section.Body className="flex flex-col gap-6">
        {!showHiring && !embedded && (
          <div className="flex gap-6 rounded-md border border-[#E5EFFD] bg-[#F2F7FE] px-[20px] py-[16px]">
            <Icon icon={faCircleQuestion} size={20} color="#018AC1" />
            <div className="flex flex-col gap-2 text-sm">
              <Typography variant="heading-h6" title="How to use Hiring Workflow?" />
              <ul className="ml-3 list-decimal">
                <li>
                  <b>Measure</b> – Assess the skills and qualifications of job candidates through
                  aptitude tests, personality assessments, and skill-specific evaluations.
                </li>
                <li>
                  <b>Evaluate</b> – Review measurement results and consider other factors, such as
                  experience, education, and references, to narrow down the pool of candidates.
                </li>
                <li>
                  <b>Interview</b> – Get to know the candidate better through in-person, phone, or
                  video interviews and ask more specific questions about their skills and
                  experience.
                </li>
                <li>
                  <b>Hire</b> – Make a decision, negotiate salary and benefits, and bring on the new
                  hire once an agreement has been reached.
                </li>
              </ul>
            </div>
            <Button
              onClick={() => {
                localStorageService.setShowHiring(true);
                setShowHiring(true);
              }}
              className="ml-auto self-start"
              icon={faXmark}
              iconSize={24}
            />
          </div>
        )}
        <div className="flex flex-col gap-3">
          <ArrowStepper
            {...calculateStep(application?.status, application?.statusBeforeReject)}
            embedded={embedded}
            onClick={() => embedded && setShowFullMeasure(!showFullMeasure)}
            showFullMeasure={showFullMeasure}
          />
          <div className="flex gap-3">
            <HiringCard
              cardCompleted={application?.completedAt}
              cardActive={measure.includes(application?.status)}
              embedded={embedded}
              customClass={`${embedded && !showFullMeasure && 'grow-0 w-[94px] !p-3'}`}
              onClickHandler={() => embedded && setShowFullMeasure(!showFullMeasure)}>
              <div className={`flex flex-col gap-3`}>
                {application?.completedAt ? (
                  application?.tests?.map(({ _id, name, result, scaledResult, icon }, idx) => (
                    <div
                      key={idx}
                      className="flex items-center"
                      data-tooltip-id="data-tooltip"
                      data-tooltip-content={name}
                      data-tooltip-place="top">
                      {isAdmin ? (
                        <>
                          {scaledResult ? (
                            <>
                              <Typography
                                className="w-[36px] min-w-[36px] max-w-[36px] font-semibold text-[#6D7175]"
                                variant="small-default"
                                title={formatScaledResult(scaledResult)}
                              />
                              <Typography
                                className="ml-[6px] w-[36px] min-w-[36px] max-w-[36px] font-semibold text-[#8C9196]"
                                variant="small-default"
                                title={
                                  typeof result === 'number' ? `(${result.toFixed(0)})` : result
                                }
                              />
                            </>
                          ) : (
                            <Typography
                              className="w-[36px] min-w-[36px] max-w-[36px] font-semibold text-[#6D7175]"
                              variant="small-default"
                              title={typeof result === 'number' ? `(${result.toFixed(0)})` : result}
                            />
                          )}
                        </>
                      ) : (
                        <Typography
                          className="w-[36px] min-w-[36px] max-w-[36px] font-semibold text-[#6D7175]"
                          variant="small-default"
                          title={
                            (typeof scaledResult === 'number' ? scaledResult.toFixed(0) : null) ??
                            (typeof result === 'number' ? result.toFixed(0) : result)
                          }
                        />
                      )}
                      <img
                        className={`${
                          embedded && !showFullMeasure
                            ? 'mr-[12px] h-[14px] w-[14px]'
                            : 'mx-[6px] h-[24px] w-[24px]'
                        }`}
                        src={icon?.url || defaultLogo}
                      />
                      {showFullMeasure && (
                        <Typography variant="regular-default" className="truncate" title={name} />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="flex flex-col items-center gap-[6px]">
                    <Typography variant="small-default" title="Candidate passing an assessment" />
                    <div className="h-3 w-full overflow-hidden rounded bg-[#E4E5E7]">
                      <div
                        className="h-full bg-[#6D7175]"
                        style={{ width: `${application?.percentComplete}%` }}></div>
                    </div>
                  </div>
                )}
              </div>
            </HiringCard>
            <HiringCard
              cardCanceled={evaluate.includes(application?.statusBeforeReject)}
              cardCompleted={application?.invitedInterviewAt}
              cardActive={evaluate.includes(application?.status)}
              embedded={embedded}>
              <div className="flex flex-col justify-between">
                <div className="flex flex-col gap-[4px]">
                  <Typography
                    className={`text-center ${
                      application?.invitedInterviewAt || evaluate.includes(application?.status)
                        ? 'text-[#082623]'
                        : 'text-[#8C9196]'
                    }`}
                    variant="heading-h6"
                    title="Hirenest Score"
                  />
                  {application?.completedAt ? (
                    <>
                      <div className="flex items-center justify-center">
                        {scores.map((score, idx) => {
                          const scoreText = `${idx !== 0 ? '(' : ''}${score?.score?.toFixed(0)}%${
                            idx !== 0 ? ')' : ''
                          }`;
                          return (
                            <div
                              className="flex h-[32px] items-center justify-center gap-2"
                              key={idx}>
                              {score.icon && (
                                <Icon icon={score.icon} color={score.iconColor} size={24} />
                              )}
                              <Typography
                                className={`font-semibold ${score.title}`}
                                variant={
                                  application?.hirenestScore >= 0 ? 'heading-h2' : 'heading-h4'
                                }
                                title={
                                  application?.hirenestScore || application?.hirenestScore === 0
                                    ? application?.hirenestScore === -1
                                      ? 'Not Available'
                                      : scoreText
                                    : '-'
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                      {application?.hirenestScore >= 0 && (
                        <div className="relative mt-6 flex h-[8px]">
                          <div className="h-full flex-1 rounded-l-md bg-[#F08E8E]" />
                          <div className="h-full flex-1 bg-[#FAC462]" />
                          <div className="h-full flex-1 rounded-r-md bg-[#2D968B]" />
                          {(application?.hirenestScore || application?.hirenestScore === 0) && (
                            <div
                              style={{
                                left:
                                  (application?.scaledScore ?? application?.hirenestScore) -
                                  5 +
                                  '%',
                                boxShadow: '0px 0px 6px rgba(34, 34, 34, 0.24)'
                              }}
                              className={`absolute top-[-100%] flex h-[24px] w-[23px] items-center justify-center rounded-full border pt-[3px] text-xs capitalize ${
                                calculateColorHirenestScore(
                                  application?.scaledScore ?? application?.hirenestScore
                                ).title
                              } ${
                                calculateColorHirenestScore(
                                  application?.scaledScore ?? application?.hirenestScore
                                ).bg
                              } ${
                                calculateColorHirenestScore(
                                  application?.scaledScore ?? application?.hirenestScore
                                ).border
                              } pb-[3px] font-medium`}>
                              {application?.candidate?.[0]?.firstName?.toUpperCase().charAt(0) +
                                application?.candidate?.[0]?.lastName?.toUpperCase().charAt(0)}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Lottie options={defaultOptions} height={48} width={48} />
                      <Typography
                        className="p-[20px] text-center text-[#8C9196]"
                        variant="regular-default"
                        title="Will be calculated when a candidate will complete an application"
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="mt-auto flex flex-col gap-6">
                {!measure.includes(application?.status) && application?.hirenestScore >= 0 && (
                  <div className="flex flex-col gap-[4px]">
                    <Typography
                      className="text-[#6D7175]"
                      variant="heading-h6"
                      title="Hirenest Insight:"
                    />
                    <Typography
                      className="text-[#6D7175]"
                      variant="regular-default"
                      title={
                        calculateColorHirenestScore(
                          application?.scaledScore ?? application?.hirenestScore
                        ).result
                      }
                    />
                  </div>
                )}
                <Button
                  disabled={
                    isAdmin ||
                    !application?.visible ||
                    measure.includes(application?.status) ||
                    rejected.includes(application?.status)
                  }
                  className={`flex justify-center`}
                  onClick={() => {
                    if (
                      interview.includes(application?.status) ||
                      hire.includes(application?.status)
                    ) {
                      application?.assessment?.demo?.isDemo
                        ? dispatch(
                            updateApplication({
                              status: 'Not Evaluated',
                              invitedInterviewAt: null,
                              offerSentAt: null,
                              rating: 0,
                              ratings: {
                                educationalBackground: 0,
                                relevantWorkExpirience: 0,
                                leadershipSkills: 0,
                                communiationSkills: 0,
                                attitudeAndMotivation: 0
                              }
                            })
                          )
                        : setAppStatus('Not Evaluated');
                    } else {
                      application?.assessment?.demo?.isDemo
                        ? dispatch(
                            updateApplication({
                              status: 'Invited for Interview',
                              invitedInterviewAt: moment()
                            })
                          )
                        : setAppStatus('Invited for Interview');
                    }
                  }}
                  variant="secondary"
                  title={
                    application?.invitedInterviewAt ? 'Cancel an Interview' : 'Invite for Interview'
                  }
                />
              </div>
            </HiringCard>
            <HiringCard
              cardCanceled={interview.includes(application?.statusBeforeReject)}
              cardCompleted={application?.offerSentAt}
              cardActive={interview.includes(application?.status)}
              embedded={embedded}>
              <div
                onClick={() => {
                  !isAdmin &&
                    (interview.includes(application?.status) ||
                      hire.includes(application?.status)) &&
                    setIsRateModalOpen(true);
                }}
                className={`flex ${
                  (interview.includes(application?.status) || hire.includes(application?.status)) &&
                  'cursor-pointer'
                } flex-col items-center gap-2`}>
                <Typography
                  className={`text-center ${
                    application?.offerSentAt || interview.includes(application?.status)
                      ? 'text-[#082623]'
                      : 'text-[#8C9196]'
                  }`}
                  variant="heading-h6"
                  title="Rate the Interview"
                />
                <StarsRating rate={application?.rating} />
              </div>
              <div className="flex flex-col gap-[12px]">
                <Typography className="text-[#6D7175]" variant="small-default" title="(Optional)" />
                <Form
                  mode="onChange"
                  defaultValues={{
                    invitedTakeHomeTest: application?.invitedTakeHomeTest,
                    completedTakeHomeTest: application?.completedTakeHomeTest,
                    referencesChecked: application?.referencesChecked
                  }}
                  className="flex flex-col gap-[13px]">
                  {({ setValue, getValues }) => (
                    <>
                      <CheckBoxComponent
                        application={application}
                        title="Invited for Take-Home Test"
                        value="invitedTakeHomeTest"
                        setValue={setValue}
                        isDemo={application?.assessment?.demo?.isDemo}
                        dispatch={dispatch}
                        getValues={getValues}
                        isAdmin={isAdmin}
                        setAppOptionalStatus={setAppOptionalStatus}
                      />
                      <CheckBoxComponent
                        application={application}
                        title="Take-Home Test Completed"
                        value="completedTakeHomeTest"
                        setValue={setValue}
                        isDemo={application?.assessment?.demo?.isDemo}
                        dispatch={dispatch}
                        getValues={getValues}
                        isAdmin={isAdmin}
                        setAppOptionalStatus={setAppOptionalStatus}
                      />
                      <CheckBoxComponent
                        application={application}
                        title="References-Checked"
                        value="referencesChecked"
                        setValue={setValue}
                        isDemo={application?.assessment?.demo?.isDemo}
                        dispatch={dispatch}
                        getValues={getValues}
                        isAdmin={isAdmin}
                        setAppOptionalStatus={setAppOptionalStatus}
                      />
                    </>
                  )}
                </Form>
              </div>
              <Button
                disabled={
                  isAdmin ||
                  (application?.status !== 'Interviewed' && !hire.includes(application?.status))
                }
                className={`mt-auto flex justify-center`}
                onClick={() =>
                  application?.assessment?.demo?.isDemo
                    ? dispatch(
                        updateApplication({
                          status: application?.offerSentAt ? 'Interviewed' : 'Offer Sent',
                          offerSentAt: application?.offerSentAt ? null : moment()
                        })
                      )
                    : setAppStatus(application?.offerSentAt ? 'Interviewed' : 'Offer Sent')
                }
                variant="secondary"
                title={hire.includes(application?.status) ? 'Cancel an Offer' : 'Send an Offer'}
              />
            </HiringCard>
            <HiringCard
              cardActive={application?.status === 'Offer Sent'}
              cardCompleted={application?.status === 'Offer Accepted'}
              cardCanceled={
                application?.status === 'Offer Declined' ||
                hire.includes(application?.statusBeforeReject)
              }
              embedded={embedded}>
              <div className={`mt-auto flex flex-col gap-[12px]`}>
                <Typography
                  className={`text-center font-medium ${
                    application?.status === 'Offer Sent' ? 'text-[#082623]' : 'text-[#8C9196]'
                  }`}
                  variant="heading-h6"
                  title="Candidate Response"
                />
                <Button
                  disabled={
                    isAdmin ||
                    (application?.status === 'Offer Declined' &&
                      application?.status !== 'Offer Sent') ||
                    rejected.includes(application?.status) ||
                    !hire.includes(application?.status)
                  }
                  onClick={() =>
                    application?.assessment?.demo?.isDemo
                      ? dispatch(updateApplication({ status: 'Offer Declined' }))
                      : setAppStatus('Offer Declined')
                  }
                  className={`flex w-full justify-center bg-[#FFFFFF] ${
                    !hire.includes(application?.status) &&
                    application?.status !== 'Offer Sent' &&
                    'cursor-auto border-[#E2E2E2] bg-[#FFFFFF] text-[#8C9196] hover:border-[#E2E2E2] hover:bg-[#FFFFFF]'
                  }`}
                  variant="secondary"
                  title="Offer Declined"
                />
                <Button
                  disabled={
                    isAdmin ||
                    (application?.status === 'Offer Accepted' &&
                      application?.status !== 'Offer Sent') ||
                    rejected.includes(application?.status) ||
                    !hire.includes(application?.status)
                  }
                  onClick={() =>
                    application?.assessment?.demo?.isDemo
                      ? dispatch(updateApplication({ status: 'Offer Accepted' }))
                      : setAppStatus('Offer Accepted')
                  }
                  className={`w-full justify-center border border-[#007D6F] hover:bg-[#F5FEFD] ${
                    !hire.includes(application?.status) &&
                    application?.status !== 'Offer Sent' &&
                    'cursor-auto border-[#E2E2E2] bg-[#FFFFFF] text-[#8C9196] hover:bg-[#FFFFFF] disabled:bg-[#FAFBFB] hover:disabled:bg-[#FAFBFB]'
                  }`}
                  variant="primary"
                  title="Hired"
                />
              </div>
            </HiringCard>
          </div>
        </div>
        <div className="flex gap-3">
          <div
            className={`flex ${!showFullMeasure ? 'max-w-[94px]' : 'w-[calc(25%-1.5rem)]'} flex-1`}>
            {(application?.completedAt || application?.startedAt || application?.invitedAt) && (
              <Typography
                data-tooltip-id="data-tooltip"
                data-tooltip-content={
                  (application?.completedAt &&
                    moment(application?.completedAt).format('MMM DD h:mm A')) ||
                  (application?.startedAt &&
                    moment(application?.startedAt).format('MMM DD h:mm A')) ||
                  (application?.invitedAt && moment(application?.invitedAt).format('MMM DD h:mm A'))
                }
                data-tooltip-place="top"
                className="w-max cursor-pointer text-[#6D7175]"
                variant="small-default"
                title={
                  !showFullMeasure
                    ? 'Completed..'
                    : (application?.completedAt &&
                        `Completed ${moment(application?.completedAt).fromNow()}`) ||
                      (application?.startedAt &&
                        `Started ${moment(application?.startedAt).fromNow()}`) ||
                      (application?.invitedAt &&
                        `Invited ${moment(application?.invitedAt).fromNow()}`)
                }
              />
            )}
          </div>
          <div className="flex w-[calc(25%-1.5rem)] flex-1">
            {application?.invitedInterviewAt && (
              <Typography
                data-tooltip-id="data-tooltip"
                data-tooltip-content={moment(application?.invitedInterviewAt).format(
                  'MMM DD h:mm A'
                )}
                data-tooltip-place="top"
                className="w-max cursor-pointer text-[#6D7175]"
                variant="small-default"
                title={`Invited ${moment(application?.invitedInterviewAt).fromNow()}`}
              />
            )}
          </div>
          <div className="flex w-[calc(25%-1.5rem)] flex-1">
            {application?.offerSentAt && (
              <Typography
                data-tooltip-id="data-tooltip"
                data-tooltip-content={moment(application?.offerSentAt).format('MMM DD h:mm A')}
                data-tooltip-place="top"
                className="w-max cursor-pointer text-[#6D7175]"
                variant="small-default"
                title={`Offer Sent ${moment(application?.offerSentAt).fromNow()}`}
              />
            )}
          </div>
          <div className="flex w-[calc(25%-1.5rem)] flex-1">
            {(application?.acceptedAt || application?.declinedAt) && (
              <Typography
                data-tooltip-id="data-tooltip"
                data-tooltip-content={
                  application?.acceptedAt
                    ? moment(application?.acceptedAt).format('MMM DD h:mm A')
                    : moment(application?.declinedAt).format('MMM DD h:mm A')
                }
                data-tooltip-place="top"
                className="w-max cursor-pointer text-[#6D7175]"
                variant="small-default"
                title={
                  application?.acceptedAt
                    ? `Accepted ${moment(application?.acceptedAt).fromNow()}`
                    : `Declined ${moment(application?.declinedAt).fromNow()}`
                }
              />
            )}
          </div>
        </div>
        <Tooltip id="data-tooltip" />
      </Section.Body>
    </Section>
  );
};
