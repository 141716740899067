import { put, takeLatest, call } from 'redux-saga/effects';
import ReactPixel from 'react-facebook-pixel';
import * as routes from 'routes/routes';

import {
  authConstants,
  registerSuccess,
  registerFailure,
  logInSuccess,
  logInFailure,
  confirmEmailFailure,
  passwordRecoverySuccess,
  passwordRecoveryFailure,
  newPasswordSuccess,
  newPasswordFailure,
  resendEmailSuccess,
  resendEmailFailure,
  getProfileSuccess,
  getProfileFailure,
  updateProfileSuccess,
  updateProfileFailure,
  updateProfileImageSuccess,
  updateProfileImageFailure,
  deleteProfileImageSuccess,
  deleteProfileImageFailure,
  updateProfileEmailSuccess,
  updateProfileEmailFailure,
  updateProfilePasswordSuccess,
  updateProfilePasswordFailure,
  getCompanySuccess,
  getCompanyFailure,
  updateCompanySuccess,
  updateCompanyFailure,
  countriesSuccess,
  countriesFailure,
  updateCompanyImageSuccess,
  updateCompanyImageFailure,
  deleteCompanyImageSuccess,
  deleteCompanyImageFailure,
  saveUserData,
  updateAdminProfileSuccess,
  updateAdminProfileFailure,
  profileCompleteSuccess,
  profileCompleteFailure,
  faqSuccess,
  faqFailure
} from '../actions';
import { callToast } from 'utils/helpers';
import { authApi, commonApi, employerApi } from 'api/appApi';
import { localStorageService } from 'helpers/localStorageService';

export function navigateUser(role, payload) {
  switch (role) {
    case 'admin':
      payload.navigate(routes.HOME);
      break;
    case 'employer':
      payload.navigate(routes.DASHBOARD);
      break;
    case 'author':
      payload.navigate(routes.TESTS);
      break;
    default:
      payload.navigate(routes.AUTH + '/' + routes.SIGNIN);
      break;
  }
}

function* loginSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authApi.signIn(payload.data);
    if (data?.token) {
      if (!data?.verifiedAt) {
        yield call(() => localStorageService.setDomain(data.mx_domain));
        yield call(() => localStorageService.setRecord(data.mx_record));
      }
      yield call(() => localStorageService.setUserId(data._id));
      yield call(() => localStorageService.setEmail(data.email));
      yield call(() => localStorageService.setEmailVerified(!!data?.verifiedAt));
      yield call(() => localStorageService.setToken(data.token));
      yield call(() => localStorageService.setRole(data.role));
      yield call(() => navigateUser(data.role, payload));
      yield put(logInSuccess(data.email));
    }
  } catch (error) {
    callToast('error', 'Error - Failed Sign In');
    yield call(() => localStorageService.clearStorage());
    yield put(logInFailure(error.response));
  }
}

function* getCountries() {
  try {
    const { data } = yield commonApi.getCountries();
    yield put(countriesSuccess(data));
  } catch (error) {
    callToast('error', 'Error - failed to get countries data');
    yield put(countriesFailure(error));
  }
}

function* getProfile() {
  try {
    const { data } = yield commonApi.getProfile();
    yield call(() => localStorageService.setEmail(data.email));
    yield put(getProfileSuccess(data));
  } catch (error) {
    callToast('error', 'Error - Failed to get profile data');
    if (
      error.response.status === 403 ||
      error.response.status === 404 ||
      error.response.status === 422 ||
      error.response.status === 500
    ) {
      yield call(() => (window.location = routes.ERROR_PAGE + '/' + error.response.status));
    }
    if (error.response.status === 401) {
      yield call(() => localStorageService.clearStorage());
      yield call(() => localStorageService.setSession(true));
      yield call(() => (window.location = routes.AUTH));
    }
    yield put(getProfileFailure(error));
  }
}

function* getCompany() {
  try {
    const { data } = yield commonApi.getCompanyData();
    yield call(() => localStorageService.setCompany(data.name));
    yield put(getCompanySuccess(data));
  } catch (error) {
    callToast('error', 'Failed to get company data');
    yield put(getCompanyFailure(error));
  }
}

function* updateProfile(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.editProfile(payload);
    callToast('success', 'Profile was updated!');
    yield put(updateProfileSuccess(data));
  } catch (error) {
    callToast('error', 'Error - profile was not updated');
    yield put(updateProfileFailure(error));
  }
}

function* profileComplete(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.profileComplete(payload);
    callToast('success', 'Profile was completed!');
    yield put(profileCompleteSuccess(data));
  } catch (error) {
    callToast('error', 'Error - profile was not completed');
    yield put(profileCompleteFailure(error));
  }
}

function* updateAdminProfile(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.editAdminProfile(payload);
    callToast('success', 'Profile was updated!');
    yield put(updateAdminProfileSuccess(data));
  } catch (error) {
    callToast('error', 'Error - profile was not updated');
    yield put(updateAdminProfileFailure(error));
  }
}

function* updateCompany(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.editCompany(payload);
    callToast('success', 'Company was updated!');
    yield put(updateCompanySuccess(data));
  } catch (error) {
    callToast('error', 'Error - company was not updated');
    yield put(updateCompanyFailure(error));
  }
}

function* updateProfileImage(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.editProfileImage(payload);
    yield put(updateProfileImageSuccess(data));
  } catch (error) {
    callToast('error', 'Error - profile image was not updated');
    yield put(updateProfileImageFailure(error));
  }
}

function* updateCompanyImage(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.editCompanyImage(payload);
    yield put(updateCompanyImageSuccess(data));
  } catch (error) {
    callToast('error', 'Error - company image was not updated');
    yield put(updateCompanyImageFailure(error));
  }
}

function* deleteProfileImage() {
  try {
    const { data } = yield commonApi.deleteProfileImage();
    yield put(deleteProfileImageSuccess(data));
  } catch (error) {
    callToast('error', 'Error - profile image was not deleted');
    yield put(deleteProfileImageFailure(error));
  }
}

function* deleteCompanyImage() {
  try {
    const { data } = yield commonApi.deleteCompanyImage();
    yield put(deleteCompanyImageSuccess(data));
  } catch (error) {
    callToast('error', 'Error - company image was not deleted');
    yield put(deleteCompanyImageFailure(error));
  }
}

function* updateProfileEmail(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.editProfileEmail(payload);
    if (data?.token) {
      yield call(() => localStorageService.setToken(data.token));
      yield call(() => localStorageService.setEmailVerified(!!data?.verifiedAt));
      yield call(() => localStorageService.setEmail(data.email));
      yield call(() => localStorageService.setRole(data.role));
    }
    yield put(updateProfileEmailSuccess(data));
  } catch (error) {
    callToast('error', 'Error - profile email was not updated');
    yield put(updateProfileEmailFailure(error));
  }
}

function* updateProfilePassword(action) {
  const { payload } = action;
  try {
    const { data } = yield commonApi.editProfilePassword(payload);
    if (data?.token) {
      yield call(() => localStorageService.setToken(data.token));
      yield call(() => localStorageService.setEmailVerified(!!data?.verifiedAt));
      yield call(() => localStorageService.setRole(data.role));
    }
    callToast('success', 'Success - Password was changed');
    yield put(updateProfilePasswordSuccess(data));
  } catch (error) {
    if (error.response.status === 403) {
      callToast('error', 'Wrong current password');
    }
    callToast('error', 'Error - passord was not updated');
    yield put(updateProfilePasswordFailure(error));
  }
}

function* registerSaga(action) {
  const { payload } = action;
  yield put(saveUserData(payload.data));
  try {
    const { data } = yield authApi.signUp(payload.data);
    if (!data?.verifiedAt) {
      yield call(() => localStorageService.setDomain(data.mx_domain));
      yield call(() => localStorageService.setRecord(data.mx_record));
    }
    yield call(() => localStorageService.setUserId(data._id));
    yield call(() => localStorageService.setToken(data.token));
    yield call(() => localStorageService.setEmail(data.email));
    yield call(() => localStorageService.setRole(data.role));
    if (data?.role === 'employer') {
      yield call(() => payload.navigate(routes.DASHBOARD));
    } else {
      yield call(() => navigateUser(data.role, payload));
    }
    yield put(registerSuccess(data));
    yield call(() =>
      window.gtag('event', 'conversion', { send_to: process.env.REACT_APP_SINGUP_GOOGLE_TAG })
    );
    yield call(() => ReactPixel.track('signUp', data));
  } catch (error) {
    yield call(() => localStorageService.clearStorage());
    if (error.response.data[0].msg === 'Email already in use') {
      callToast('error', error.response.data[0].msg);
      yield call(() => action.payload.navigate(routes.AUTH + '/' + routes.SIGNIN));
    }
    yield put(registerFailure(error));
  }
}

function* emailVerifySaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authApi.emailVerify(payload);
    yield call(() => localStorageService.setEmailVerified(!!data?.verifiedAt));
    yield call(() => localStorageService.setUserId(data._id));
    yield call(() => localStorageService.setToken(data.token));
    yield call(() => localStorageService.setEmail(data.email));
    yield call(() => localStorageService.setRole(data.role));
    if (data?.verifiedAt) {
      callToast('success', 'Success - Sign In');
      yield call(() => navigateUser(data.role, payload));
      yield put(logInSuccess(data.email));
    } else {
      callToast('error', 'Error - Failed Email verify');
      yield call(() => payload.navigate('/auth/email-verify'));
      yield put(logInSuccess(data.email));
    }
    callToast('success', 'Success - Email was verified');
  } catch (error) {
    callToast('error', 'Error - Email was not verified');
    yield call(() => localStorageService.clearStorage());
    yield call(() => action.payload.navigate('/auth/singin'));
    yield put(confirmEmailFailure(error));
  }
}

function* resendEmailSaga(action) {
  const { payload } = action;
  try {
    yield authApi.resendEmail(payload.userId);
    callToast('success', 'Success - Email was sent');
    yield put(resendEmailSuccess());
  } catch (error) {
    callToast('error', 'Error - Email was not sent');
    yield put(resendEmailFailure(error));
  }
}

function* resetPasswordSaga(action) {
  const { payload } = action;
  try {
    yield authApi.resetPassword(payload.email);
    callToast('success', 'Success - Reset link was sent');
    yield call(() => action.payload.navigate(routes.AUTH + '/' + routes.EMAIL_VERIFY));
    yield put(passwordRecoverySuccess());
  } catch (error) {
    callToast('error', 'Error - Reset link was not sent');
    yield put(passwordRecoveryFailure(error));
  }
}

function* newPasswordSaga(action) {
  const { payload } = action;
  try {
    const { data } = yield authApi.newPassword(payload.data);
    if (data?.token) {
      yield call(() => localStorageService.setEmailVerified(!!data?.verifiedAt));
      yield call(() => localStorageService.setUserId(data._id));
      yield call(() => localStorageService.setToken(data.token));
      yield call(() => localStorageService.setEmail(data.email));
      yield call(() => localStorageService.setRole(data.role));
      if (data.firstTimeVerified === true) {
        yield call(() => localStorageService.setIsFirstVerified(true));
      }
    }
    callToast('success', 'Success - Password was changed');
    yield put(newPasswordSuccess(data));
    yield call(() => action.payload.navigate('/'));
  } catch (error) {
    callToast('error', 'Error - Password was not changed');
    yield call(() => action.payload.navigate('/auth/signin'));
    yield put(newPasswordFailure(error.response.data.errors));
  }
}

function* getFAQs() {
  try {
    const { data } = yield employerApi.getFAQs();
    yield put(faqSuccess(data));
  } catch (error) {
    callToast('error', 'Error - failed to get FAQs');
    yield put(faqFailure(error));
  }
}

function* validateHash(action) {
  const { payload } = action;
  try {
    const { data } = yield authApi.validateHash(payload.data);
    if (!data) yield call(() => action.payload.navigate('/'));
  } catch (error) {
    callToast('error', 'Error - Password was not changed');
    yield call(() => action.payload.navigate('/auth/signin'));
  }
}

export default function* authWatcher() {
  yield takeLatest(authConstants.REGISTER_REQUEST, registerSaga);
  yield takeLatest(authConstants.CONFIRM_EMAIL_REQUEST, emailVerifySaga);
  yield takeLatest(authConstants.LOGIN_REQUEST, loginSaga);
  yield takeLatest(authConstants.RESEND_EMAIL_REQUEST, resendEmailSaga);
  yield takeLatest(authConstants.PASSWORD_RECOVERY_REQUEST, resetPasswordSaga);
  yield takeLatest(authConstants.NEW_PASSWORD_REQUEST, newPasswordSaga);
  yield takeLatest(authConstants.GET_PROFILE_REQUEST, getProfile);
  yield takeLatest(authConstants.UPDATE_PROFILE_REQUEST, updateProfile);
  yield takeLatest(authConstants.UPDATE_PROFILE_IMAGE_REQUEST, updateProfileImage);
  yield takeLatest(authConstants.DELETE_PROFILE_IMAGE_REQUEST, deleteProfileImage);
  yield takeLatest(authConstants.UPDATE_PROFILE_EMAIL_REQUEST, updateProfileEmail);
  yield takeLatest(authConstants.UPDATE_PROFILE_PASSWORD_REQUEST, updateProfilePassword);
  yield takeLatest(authConstants.GET_COMPANY_REQUEST, getCompany);
  yield takeLatest(authConstants.UPDATE_COMPANY_REQUEST, updateCompany);
  yield takeLatest(authConstants.GET_COUNTRIES_REQUEST, getCountries);
  yield takeLatest(authConstants.GET_FAQ_REQUEST, getFAQs);
  yield takeLatest(authConstants.UPDATE_COMPANY_IMAGE_REQUEST, updateCompanyImage);
  yield takeLatest(authConstants.DELETE_COMPANY_IMAGE_REQUEST, deleteCompanyImage);
  yield takeLatest(authConstants.UPDATE_ADMIN_PROFILE_REQUEST, updateAdminProfile);
  yield takeLatest(authConstants.PROFILE_COMPLETE_REQUEST, profileComplete);
  yield takeLatest(authConstants.GET_VALIDATE_HASH_REQUEST, validateHash);
}
