import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import { Button, ErrorMessage, Form, Input, Typography } from 'ui-kit';

import { ResetPasswordSchema } from 'components/Forms/validation';
import { passwordRecoveryRequest } from 'store/auth/actions';

import * as routes from 'routes/routes';
import { Logo } from 'ui-kit/Logo';

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = data => {
    dispatch(passwordRecoveryRequest({ email: data, navigate }));
  };

  return (
    <div className="flex flex-1 items-center justify-center gap-12">
      <Form
        className="container max-w-screen-sm rounded-xl border border-gray-400 bg-white py-6 px-12"
        mode="all"
        onSubmit={onSubmit}
        resolver={yupResolver(ResetPasswordSchema)}>
        {({ formState }) => (
          <>
            <div className="mb-6 flex justify-center border-b border-gray-400 pb-6 ">
              <Logo width={140} height={28} />
            </div>
            <Typography
              className="mb-[12px] text-center"
              variant="heading-h4"
              title="Reset Password"
            />
            <div className="pb-1">
              <Typography variant="regular-default" title="Email" />
              <Input name="email" />
              <ErrorMessage name="email" />
            </div>
            <div className="flex justify-center gap-4">
              <Button
                onClick={() => navigate(routes.AUTH + '/' + routes.SIGNIN)}
                variant="secondary"
                children="Cancel"
              />
              <Button
                disabled={!formState.isDirty}
                variant="primary"
                type="submit"
                title="Reset Password"
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
