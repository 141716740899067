import ApplicationTable from 'components/ApplicationTable/ApplicationTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAllApplicationRequest,
  getPendingApplicationRequest,
  resetEmbeddedApplication
} from 'store/candidates/actions';
import { getEmbeddedApplicationRequest } from 'store/candidates/actions';
import {
  getDraftAssessmetsRequest,
  getLastPublishAssessmentRequest,
  inviteCandidateRequest
} from 'store/assessment/actions';
import { CandidatesFilters } from './CandidatesFilters';
import * as routes from 'routes/routes';
import { useQueryParams, useUserRole } from 'hooks';
import Loader from 'components/Loader';
import { Tooltip } from 'react-tooltip';
import { Button, Form, Input, Typography } from 'ui-kit';
import { CreateFirstAssessmentButton } from 'components/CreateFirstAssessmentButton/CreateFirstAssessmentButton';
import AssessmenstCard from 'components/Cards/AssessmentCard';
import { SingleApplication } from 'components/SingleApplication/SingleApplication';
import { yupResolver } from '@hookform/resolvers/yup';
import { InviteCandidateSchema } from 'components/Forms/validation';
import Pagination from 'components/Pagination';
import copyToClipboard from 'utils/copyToClipboard';
import { callToast } from 'utils/helpers';
import { localStorageService } from 'helpers/localStorageService';

const Candidates = () => {
  const [searchLoading, setSearchLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserRole();

  const { loading, allApplications, pendingApplications, embeddedApplication, totalCandidates } =
    useSelector(store => store.candidates);
  const { user } = useSelector(store => store.auth);
  const { draftAssessments, lastPublishedAssessment } = useSelector(store => store.assessments);

  useEffect(() => {
    !loading && setSearchLoading(false);
  }, [loading]);

  const invitedLink = useMemo(
    () =>
      `${process.env.REACT_APP_ASSESSMENT_URL}${routes.CANDIDATE_INVITE}#${lastPublishedAssessment?.hash}`,
    [lastPublishedAssessment?.hash]
  );

  const copyLink = useCallback(
    text => () => {
      copyToClipboard(text);
      callToast('success', 'Success - link was copy to clipboard');
    },
    []
  );

  const onSubmit = (data, control) => {
    dispatch(inviteCandidateRequest({ data: { ...data }, id: lastPublishedAssessment?._id }));
    control.reset();
  };

  const { params, set } = useQueryParams({
    assessment: '',
    score: '',
    status: '',
    source: '',
    search: '',
    application: '',

    sortOrder: -1,
    sortBy: 'score',
    perPage: role !== 'admin' ? -1 : 30,
    page: 0
  });

  const handleSort = column => {
    if (params?.sortBy === column) {
      set({ ...params, sortOrder: params?.sortOrder === '1' ? '-1' : '1' });
    } else {
      set({ ...params, sortBy: column, sortOrder: 1 });
    }
  };

  const handleSetPage = page => {
    set({ ...params, page });
  };

  const handleOpenApplication = id => {
    set({ ...params, application: id });
  };
  const handleCloseApplication = () => {
    dispatch(resetEmbeddedApplication());
    set({ ...params, application: '' });
  };

  const handleChangingFilters = (key, value) => {
    set({ ...params, [key]: value });
  };

  useEffect(() => {
    localStorageService.setAssessmentStep(0);
    dispatch(getPendingApplicationRequest());
    if (role !== 'admin') dispatch(getLastPublishAssessmentRequest());
    dispatch(getDraftAssessmetsRequest({ perPage: 1, sortBy: 'updatedAt' }));
  }, []);

  useEffect(() => {
    dispatch(getAllApplicationRequest(params));
  }, [params]);

  useEffect(() => {
    if (params.application) {
      window.scrollTo(0, 0);
      dispatch(getEmbeddedApplicationRequest(params.application));
    }
  }, [params.application]);

  const navigateToAssessment = id => {
    navigate(routes.ASSESSMENTS + '/' + id);
  };

  return (
    <div className="flex flex-col gap-[36px] px-[30px] py-[36px]">
      {loading && !searchLoading && <Loader />}
      <Tooltip
        id="pending-applications-tooltip"
        className="rounded-s z-50 mt-[3px] w-[226px] max-w-[300px] text-sm
        after:absolute after:left-[50%] after:bottom-[-5px] after:translate-x-[-3px]
        after:border-x-[5px] after:border-b-0
        after:border-t-[6px] after:border-x-transparent after:border-t-[#02574D] after:transition"
        noArrow={true}
        style={{
          background: 'linear-gradient(157.4deg, #057466 0%, #004B42 100%)'
        }}
      />
      <CandidatesFilters
        handleChangingFilters={handleChangingFilters}
        setSearchLoading={setSearchLoading}
        searchLoading={searchLoading}
        params={params}
        candidatesLength={allApplications?.length ?? 0}
      />
      <div className="flex">
        <div
          className={`${
            params.application && embeddedApplication ? 'mr-[30px] w-[294px]' : 'w-full'
          }`}>
          <ApplicationTable
            applications={allApplications}
            navigateToAssessment={navigateToAssessment}
            params={params}
            handleSort={handleSort}
            handleOpenApplication={handleOpenApplication}
            miniVersion={params.application && embeddedApplication}
          />
        </div>
        {params.application && embeddedApplication && (
          <div className="flex-1">
            <SingleApplication
              application={embeddedApplication}
              embedded={true}
              handleCloseApplication={handleCloseApplication}
            />
          </div>
        )}
      </div>

      {role === 'admin' && (
        <Pagination
          page={params.page}
          itemsPerPage={params.perPage}
          totalItems={totalCandidates}
          setPage={page => handleSetPage(page)}
        />
      )}
      {pendingApplications > 0 && (
        <div className="flex justify-center gap-[4px]">
          <p className="text-base font-semibold">{pendingApplications} pending applications</p>
          <div className="ml-1 text-[#5C5F62]">
            <i
              className="fa-regular fa-circle-info relative"
              data-tooltip-id="pending-applications-tooltip"
              data-tooltip-content="Applications in Invited and In Progress statuses are not displayed here."
            />
          </div>
        </div>
      )}
      {role !== 'admin' && allApplications?.length === 0 && !lastPublishedAssessment?._id && (
        <div className="flex flex-col gap-[6px]">
          <Typography
            className="text-center"
            variant="heading-h3"
            title="You don’t have any published assessment yet"
          />
          <Typography
            className="text-center"
            variant="regular-default"
            title="Publish your first assessment to start collecting applications."
          />
        </div>
      )}
      {role !== 'admin' && allApplications?.length === 0 && !lastPublishedAssessment?._id && (
        <div className="mt-[22px] flex items-center gap-[24px]">
          <CreateFirstAssessmentButton navigate={navigate} />
          {draftAssessments?.length !== 0 && (
            <>
              <div>or</div>
              <div className="relative flex w-full flex-col">
                <div className="absolute mt-[-22px] pl-5 text-[14px]">
                  Continue editing your draft
                </div>
                <AssessmenstCard data={draftAssessments[0]} customClass="h-[130px]" />
              </div>
            </>
          )}
        </div>
      )}
      {role !== 'admin' && lastPublishedAssessment?._id && user?.verifiedAt && (
        <Form
          resolver={yupResolver(InviteCandidateSchema)}
          defaultValues={{ email: '' }}
          onSubmit={onSubmit}
          className="flex w-[450px] flex-col gap-6 self-center rounded-md bg-white px-6 pt-3 pb-6 shadow-med">
          <div className="flex flex-col">
            <p className="text-center text-h5 font-medium">Invite more candidates</p>
            <p className="break-words text-center text-[#6D7175]">
              to <b>{lastPublishedAssessment?.name}</b> assessment
            </p>
          </div>
          <div className="flex flex-col gap-[6px]">
            <div className="flex gap-[6px]">
              <Input className="h-[40px] w-[295px]" name="email" placeholder="Candidate Email..." />
              <Button
                variant="primary-outline"
                type="submit"
                className="flex max-h-[40px] items-center justify-center gap-[6px] rounded-md border border-[#007D6F] bg-white px-[16px] py-[8px] font-semibold text-[#007D6F]">
                <p>Invite</p>
                <i className="fa-light fa-paper-plane text-[#007D6F]"></i>
              </Button>
            </div>
            <p className="text-center text-base text-[#6D7175]">or</p>
            <Button
              onClick={copyLink(invitedLink)}
              variant="primary-outline"
              className="flex max-h-[40px] w-max cursor-pointer items-center justify-center gap-[6px] self-center rounded-md border border-[#007D6F] bg-white px-[16px] py-[8px] font-semibold text-[#007D6F]">
              <i className="fa-light fa-link"></i>
              <p>Copy Invitation Link</p>
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Candidates;
