import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Typography } from 'ui-kit';
import { useEffect } from 'react';
import { getCompanyRequest } from 'store/auth/actions';
import { getSubscriptionsRequest } from 'store/configuration/actions/subscriptions';
import SubscriptionView from 'components/SubscriptionViewCard/SubscriptionView';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { archiveAssessmentRequest } from 'store/assessment/actions';
import { useUserRole } from 'hooks';

const UpgradeSubscriptionModal = ({
  isModalOpen,
  setModalOpen,
  showArchiveOption = false,
  publishAssessmentWithoutConfirm
}) => {
  const dispatch = useDispatch();
  const { subscriptions } = useSelector(store => store.subscriptions);
  const { lastPublishedAssessment } = useSelector(store => store.assessments);
  const { user, company } = useSelector(store => store.auth);
  const { role } = useUserRole();

  useEffect(() => {
    role === 'employer' && dispatch(getCompanyRequest());
    role === 'employer' && dispatch(getSubscriptionsRequest());
  }, [user]);

  const archiveAndPublishHandler = id => {
    try {
      dispatch(archiveAssessmentRequest({ id }));
      publishAssessmentWithoutConfirm();
      setModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        className="m-auto min-w-[850px] rounded-md">
        <div
          style={{
            background: 'linear-gradient(268deg, #0A8778 0.29%, #005349 99.24%)'
          }}
          className="flex gap-[24px] rounded-md p-3">
          <div className="flex flex-1 flex-col justify-center gap-[18px] px-3 text-white">
            <Typography
              variant="heading-h1"
              className="ml-[-1.5px]"
              title="Unlock your hiring potential"
            />
            <Typography
              variant="heading-h4"
              title="The limit of published assessments has been reached"
            />
            <Typography
              variant="regular-default"
              title="Experience the true power of our platform by subscribing to Hirenest Pro today."
            />
          </div>
          <div className="relative flex w-[300px] flex-1 flex-col gap-3 rounded-md bg-white p-6">
            <Button
              icon={faXmark}
              className="absolute right-0 top-1 cursor-pointer"
              iconSize={20}
              iconColor="#5C5F62"
              onClick={() => setModalOpen(false)}
            />
            <div className="flex items-center justify-center gap-[8px]">
              <SubscriptionView
                avaliableAssesment={company?.availablePublications}
                {...subscriptions[1]}
                modal
                isSettings
              />
            </div>
            {showArchiveOption && (
              <>
                <Typography
                  className="text-[#8C9196]"
                  variant="small-default"
                  title="Alternatively, you can archive your previously published assessment and publish this one."
                />
                <Button
                  size="small"
                  variant="secondary"
                  title="Archive old and publish new"
                  className="max-w-max self-center"
                  onClick={() => archiveAndPublishHandler(lastPublishedAssessment?._id)}
                />
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpgradeSubscriptionModal;
