import { Button, Input, Typography } from 'ui-kit';

export const NonLinearScale = ({ ...props }) => (
  <div className="flex items-center  h-[40px] mb-[12px]">
    <div className="w-64px">
      <Input
        placeholder="0"
        name={props?.rawName || 'raw'}
        onKeyDown={evt => {
          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
        }}
        className="h-[40px] w-[64px] text-center"
        onBlur={e => {
          if (!props?.placeholder) {
            return;
          }
          props?.unregister('raw');
          e.target.value &&
            props?.control.append({
              raw: e.target.value,
              scale: ''
            });
          e.target.value = '';
        }}
        inputClassName="text-center"
        type="number"
      />
    </div>
    <div className="px-[12px]">
      <Typography title="-" />
    </div>
    <div className="w-[64px]">
      <Input
        placeholder="0"
        name={props?.scaleName || 'scale'}
        onKeyDown={evt => {
          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
        }}
        className="h-[40px] w-[64px] text-center"
        onBlur={e => {
          if (!props?.placeholder) {
            return;
          }
          props?.unregister('scale');
          e.target.value &&
            props?.control.append({
              raw: '',
              scale: e.target.value
            });
          e.target.value = '';
        }}
        inputClassName="text-center"
        type="number"
      />
    </div>

    <div className="w-[40px] ml-[12px]">
      <Button
        onClick={props?.onClick}
        className={`h-[40px] w-[40px] items-center ${props.placeholder && 'invisible'}`}
        variant="secondary">
        <i className={`fa-light fa-trash-can`}></i>
      </Button>
    </div>
  </div>
);
