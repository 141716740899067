import { candidateConstants } from '../actions';

const initialState = {
  loading: false,
  allCandidates: [],
  topPerformers: [],
  recentApplications: [],
  moreTopPerformers: false,
  allApplications: [],
  pendingApplications: 0,
  performance: [],
  currentCandidate: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    rating: 0,
    role: 'candidate',
    status: null,
    lastActivityAt: null,
    lastLoginAt: null,
    registeredAt: null,
    appliedAssessments: [],
    results: []
  },
  application: {
    candidate: [{}]
  },
  embeddedApplication: null,
  totalCandidates: null,
  page: 0,
  error: null
};

export function candidates(state = initialState, action) {
  switch (action.type) {
    // RESET ERRORS - NO BACKEND
    case candidateConstants.RESET_ERRORS:
      return {
        ...state,
        error: null
      };
    case candidateConstants.RESET_EMBEDDED_APPLICATION:
      return {
        ...state,
        embeddedApplication: null,
        error: null
      };
    case candidateConstants.RESET_TOP_PERFORMERS:
      return {
        ...state,
        topPerformers: [],
        moreTopPerformers: false,
        error: null
      };
    case candidateConstants.RESET_RECENT_APPLICATIONS:
      return {
        ...state,
        recentApplications: [],
        error: null
      };

    // REQUEST ACTIONS
    case candidateConstants.GET_CANDIDATE_REQUEST:
    case candidateConstants.GET_APPLICATION_REQUEST:
    case candidateConstants.GET_EMBEDDED_APPLICATION_REQUEST:
    case candidateConstants.GET_ALL_APPLICATION_REQUEST:
    case candidateConstants.GET_APPLICATION_INFO_REQUEST:
    case candidateConstants.GET_APPLICATION_TESTS_REQUEST:
    case candidateConstants.GET_APPLICATION_QUESTIONS_REQUEST:
    case candidateConstants.GET_APPLICATION_SUBSCRIBED_REQUEST:
    case candidateConstants.SET_APPLICATION_RATING_REQUEST:
    case candidateConstants.RESTORE_APPLICATION_STATUS_REQUEST:
    case candidateConstants.SET_APPLICATION_OPTIONAL_STATUS_REQUEST:
    case candidateConstants.SET_APPLICATION_NOTE_REQUEST:
    case candidateConstants.GET_ALL_CANDIDATE_REQUEST:
    case candidateConstants.GET_TOP_PERFORMERS_REQUEST:
    case candidateConstants.GET_PERFORMANCE_REQUEST:
    case candidateConstants.GET_RECENT_APPLICATIONS_REQUEST:
    case candidateConstants.GET_PENDING_APPLICATION_REQUEST:
      return {
        ...state,
        loading: true
      };

    // SUCCESS ACTIONS
    case candidateConstants.GET_ALL_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        allCandidates: action.payload.candidates || [],
        totalCandidates: action.payload.total || null,
        error: null
      };
    case candidateConstants.GET_TOP_PERFORMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        topPerformers: [...state.topPerformers, ...action.payload.applications],
        moreTopPerformers: action.payload.applications.length !== 0,
        error: null
      };
    case candidateConstants.GET_PERFORMANCE_SUCCESS:
      return {
        ...state,
        performance: action.payload,
        error: null
      };
    case candidateConstants.CLEAR_PERFORMANCE:
      return {
        ...state,
        error: null,
        performance: []
      };
    case candidateConstants.GET_RECENT_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recentApplications: [...state.recentApplications, ...action.payload.applications],
        error: null
      };
    case candidateConstants.GET_CANDIDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        currentCandidate: action.payload,
        error: null
      };
    case candidateConstants.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        application: action.payload,
        error: null
      };
    case candidateConstants.GET_EMBEDDED_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        embeddedApplication: action.payload,
        error: null
      };
    case candidateConstants.GET_ALL_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        allApplications: action.payload.applications,
        page: action.payload.page,
        totalCandidates: action.payload.total,
        error: null
      };
    case candidateConstants.GET_PENDING_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingApplications: action.payload.count
      };
    case candidateConstants.GET_APPLICATION_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        application: { ...state.application, ...action.payload },
        error: null
      };
    case candidateConstants.GET_APPLICATION_TESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        application: { ...state.application, ...action.payload },
        error: null
      };
    case candidateConstants.GET_APPLICATION_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        application: { ...state.application, ...action.payload },
        error: null
      };
    case candidateConstants.GET_APPLICATION_SUBSCRIBED_SUCCESS:
      return {
        ...state,
        loading: false,
        application: action.payload,
        error: null
      };
    case candidateConstants.UPDATE_APPLICATION:
      return {
        ...state,
        application: { ...state.application, ...action.payload },
        embeddedApplication: state.embeddedApplication
          ? { ...state.embeddedApplication, ...action.payload }
          : null
      };
    case candidateConstants.SET_APPLICATION_RATING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    // TODO: set application to state on request ?
    case candidateConstants.SET_APPLICATION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        application: {
          ...state.application,
          ...action.payload.data
        },
        embeddedApplication: state.embeddedApplication
          ? { ...state.embeddedApplication, ...action.payload.data }
          : null,
        error: null
      };
    case candidateConstants.SET_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        application: { ...state.application, ...action.payload },
        embeddedApplication: state.embeddedApplication
          ? { ...state.embeddedApplication, ...action.payload }
          : null,
        error: null
      };
    case candidateConstants.RESTORE_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        application: { ...state.application, ...action.payload, statusBeforeReject: null },
        embeddedApplication: state.embeddedApplication
          ? { ...state.embeddedApplication, ...action.payload, statusBeforeReject: null }
          : null,
        error: null
      };
    case candidateConstants.SET_APPLICATION_OPTIONAL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        application: {
          ...state.application,
          invitedTakeHomeTest: action.payload.invitedTakeHomeTest,
          completedTakeHomeTest: action.payload.completedTakeHomeTest,
          referencesChecked: action.payload.referencesChecked
        },
        embeddedApplication: state.embeddedApplication
          ? {
              ...state.embeddedApplication,
              invitedTakeHomeTest: action.payload.invitedTakeHomeTest,
              completedTakeHomeTest: action.payload.completedTakeHomeTest,
              referencesChecked: action.payload.referencesChecked
            }
          : null,
        error: null
      };
    case candidateConstants.SET_APPLICATION_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        application: { ...state.application, ...action.payload },
        embeddedApplication: state.embeddedApplication
          ? { ...state.embeddedApplication, ...action.payload }
          : null,
        error: null
      };

    // FAIL ACTIONS
    case candidateConstants.GET_ASSESSMENT_CANDIDATES_FAILURE:
    case candidateConstants.SET_APPLICATION_OPTIONAL_STATUS_FAILURE:
    case candidateConstants.GET_CANDIDATE_FAILURE:
    case candidateConstants.GET_APPLICATION_FAILURE:
    case candidateConstants.GET_EMBEDDED_APPLICATION_FAILURE:
    case candidateConstants.GET_ALL_APPLICATION_FAILURE:
    case candidateConstants.GET_APPLICATION_INFO_FAILURE:
    case candidateConstants.GET_APPLICATION_TESTS_FAILURE:
    case candidateConstants.GET_APPLICATION_QUESTIONS_FAILURE:
    case candidateConstants.GET_APPLICATION_SUBSCRIBED_FAILURE:
    case candidateConstants.SET_APPLICATION_RATING_FAILURE:
    case candidateConstants.SET_APPLICATION_STATUS_FAILURE:
    case candidateConstants.RESTORE_APPLICATION_STATUS_FAILURE:
    case candidateConstants.SET_APPLICATION_NOTE_FAILURE:
    case candidateConstants.GET_ALL_CANDIDATE_FAILURE:
    case candidateConstants.GET_TOP_PERFORMERS_FAILURE:
    case candidateConstants.GET_PERFORMANCE_FAILURE:
    case candidateConstants.GET_RECENT_APPLICATIONS_FAILURE:
    case candidateConstants.GET_PENDING_APPLICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
