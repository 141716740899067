export const HiringCard = ({
  cardCompleted,
  cardActive,
  cardCanceled,
  children,
  onClickHandler,
  embedded,
  customClass = ''
}) => (
  <div
    className={`flex ${
      embedded && onClickHandler ? 'min-h-[352px] cursor-pointer' : 'min-h-[316px]'
    } w-[calc(25%-1.5rem)] flex-1 flex-col gap-6 rounded-md ${
      cardCompleted ? 'bg-[#F3F8F6]' : 'bg-[#FAFBFB]'
    } ${cardActive && 'bg-[#F2F7FE]'} ${
      cardCanceled && 'bg-[#FFF4F4]'
    } p-3 pt-[18px] ${customClass}`}
    onClick={() => onClickHandler && onClickHandler()}>
    {children}
  </div>
);
