import { FC } from 'react';
import { Button, Typography } from 'ui-kit';
import { getModifyData } from 'utils/helpers';
import { HtmlTextReplacer } from 'components/HtmlTextReplacer';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

interface NoteProps {
  text: string;
  createdAt: string;
  deleteNote: (id: string) => void;
  id: string;
}
export const Note: FC<NoteProps> = ({ text, createdAt, deleteNote, id }) => (
  <div className="group min-h-[130px] rounded-lg border border-gray-300 p-6">
    <div className="flex h-full flex-col justify-between">
      <HtmlTextReplacer html={text} />
      <div className="flex w-full justify-between">
        <Typography
          variant="small-default"
          className="flex items-center font-normal text-gray-400"
          title={`Created On ${getModifyData(new Date(createdAt))}`}
        />
        <Button
          className="invisible hover:border-red-400 hover:fill-red-400 group-hover:visible"
  variant="primary-outline"
          icon={faTrash}
          onClick={() => deleteNote(id)}
        />
      </div>
    </div>
  </div>
);
