import { createAction } from 'redux-actions';

export const candidateConstants = {
  RESET_ERRORS: 'RESET_ERRORS',
  RESET_EMBEDDED_APPLICATION: 'RESET_EMBEDDED_APPLICATION',
  RESET_TOP_PERFORMERS: 'RESET_TOP_PERFORMERS',
  RESET_RECENT_APPLICATIONS: 'RESET_RECENT_APPLICATIONS',

  GET_ASSESSMENT_CANDIDATES_REQUEST: 'GET_ASSESSMENT_CANDIDATES_REQUEST',
  GET_ASSESSMENT_CANDIDATES_SUCCESS: 'GET_ASSESSMENT_CANDIDATES_SUCCESS',
  GET_ASSESSMENT_CANDIDATES_FAILURE: 'GET_ASSESSMENT_CANDIDATES_FAILURE',

  GET_ALL_CANDIDATE_REQUEST: 'GET_ALL_CANDIDATE_REQUEST',
  GET_ALL_CANDIDATE_SUCCESS: 'GET_ALL_CANDIDATE_SUCCESS',
  GET_ALL_CANDIDATE_FAILURE: 'GET_ALL_CANDIDATE_FAILURE',

  GET_TOP_PERFORMERS_REQUEST: 'GET_TOP_PERFORMERS_REQUEST',
  GET_TOP_PERFORMERS_SUCCESS: 'GET_TOP_PERFORMERS_SUCCESS',
  GET_TOP_PERFORMERS_FAILURE: 'GET_TOP_PERFORMERS_FAILURE',

  GET_PERFORMANCE_REQUEST: 'GET_PERFORMANCE_REQUEST',
  GET_PERFORMANCE_SUCCESS: 'GET_PERFORMANCE_SUCCESS',
  GET_PERFORMANCE_FAILURE: 'GET_PERFORMANCE_FAILURE',

  GET_RECENT_APPLICATIONS_REQUEST: 'GET_RECENT_APPLICATIONS_REQUEST',
  GET_RECENT_APPLICATIONS_SUCCESS: 'GET_RECENT_APPLICATIONS_SUCCESS',
  GET_RECENT_APPLICATIONS_FAILURE: 'GET_RECENT_APPLICATIONS_FAILURE',

  GET_CANDIDATE_REQUEST: 'GET_CANDIDATE_REQUEST',
  GET_CANDIDATE_SUCCESS: 'GET_CANDIDATE_SUCCESS',
  GET_CANDIDATE_FAILURE: 'GET_CANDIDATE_FAILURE',

  SET_APPLICATION_RATING_REQUEST: 'SET_APPLICATION_RATING_REQUEST',
  SET_APPLICATION_RATING_SUCCESS: 'SET_APPLICATION_RATING_SUCCESS',
  SET_APPLICATION_RATING_FAILURE: 'SET_APPLICATION_RATING_FAILURE',

  SET_APPLICATION_NOTE_REQUEST: 'SET_APPLICATION_NOTE_REQUEST',
  SET_APPLICATION_NOTE_SUCCESS: 'SET_APPLICATION_NOTE_SUCCESS',
  SET_APPLICATION_NOTE_FAILURE: 'SET_APPLICATION_NOTE_FAILURE',

  SET_APPLICATION_STATUS_REQUEST: 'SET_APPLICATION_STATUS_REQUEST',
  SET_APPLICATION_STATUS_SUCCESS: 'SET_APPLICATION_STATUS_SUCCESS',
  SET_APPLICATION_STATUS_FAILURE: 'SET_APPLICATION_STATUS_FAILURE',

  RESTORE_APPLICATION_STATUS_REQUEST: 'RESTORE_APPLICATION_STATUS_REQUEST',
  RESTORE_APPLICATION_STATUS_SUCCESS: 'RESTORE_APPLICATION_STATUS_SUCCESS',
  RESTORE_APPLICATION_STATUS_FAILURE: 'RESTORE_APPLICATION_STATUS_FAILURE',

  SET_APPLICATION_OPTIONAL_STATUS_REQUEST: 'SET_APPLICATION_OPTIONAL_STATUS_REQUEST',
  SET_APPLICATION_OPTIONAL_STATUS_SUCCESS: 'SET_APPLICATION_OPTIONAL_STATUS_SUCCESS',
  SET_APPLICATION_OPTIONAL_STATUS_FAILURE: 'SET_APPLICATION_OPTIONAL_STATUS_FAILURE',

  GET_APPLICATION_REQUEST: 'GET_APPLICATION_REQUEST',
  GET_APPLICATION_SUCCESS: 'GET_APPLICATION_SUCCESS',
  GET_APPLICATION_FAILURE: 'GET_APPLICATION_FAILURE',

  GET_EMBEDDED_APPLICATION_REQUEST: 'GET_EMBEDDED_APPLICATION_REQUEST',
  GET_EMBEDDED_APPLICATION_SUCCESS: 'GET_EMBEDDED_APPLICATION_SUCCESS',
  GET_EMBEDDED_APPLICATION_FAILURE: 'GET_EMBEDDED_APPLICATION_FAILURE',

  GET_PENDING_APPLICATION_REQUEST: 'GET_PENDING_APPLICATION_REQUEST',
  GET_PENDING_APPLICATION_SUCCESS: 'GET_PENDING_APPLICATION_SUCCESS',
  GET_PENDING_APPLICATION_FAILURE: 'GET_PENDING_APPLICATION_FAILURE',

  GET_ALL_APPLICATION_REQUEST: 'GET_ALL_APPLICATION_REQUEST',
  GET_ALL_APPLICATION_SUCCESS: 'GET_ALL_APPLICATION_SUCCESS',
  GET_ALL_APPLICATION_FAILURE: 'GET_ALL_APPLICATION_FAILURE',

  GET_APPLICATION_INFO_REQUEST: 'GET_APPLICATION_INFO_REQUEST',
  GET_APPLICATION_INFO_SUCCESS: 'GET_APPLICATION_INFO_SUCCESS',
  GET_APPLICATION_INFO_FAILURE: 'GET_APPLICATION_INFO_FAILURE',

  GET_APPLICATION_TESTS_REQUEST: 'GET_APPLICATION_TESTS_REQUEST',
  GET_APPLICATION_TESTS_SUCCESS: 'GET_APPLICATION_TESTS_SUCCESS',
  GET_APPLICATION_TESTS_FAILURE: 'GET_APPLICATION_TESTS_FAILURE',

  GET_APPLICATION_QUESTIONS_REQUEST: 'GET_APPLICATION_QUESTIONS_REQUEST',
  GET_APPLICATION_QUESTIONS_SUCCESS: 'GET_APPLICATION_QUESTIONS_SUCCESS',
  GET_APPLICATION_QUESTIONS_FAILURE: 'GET_APPLICATION_QUESTIONS_FAILURE',

  GET_APPLICATION_SUBSCRIBED_REQUEST: 'GET_APPLICATION_SUBSCRIBED_REQUEST',
  GET_APPLICATION_SUBSCRIBED_SUCCESS: 'GET_APPLICATION_SUBSCRIBED_SUCCESS',
  GET_APPLICATION_SUBSCRIBED_FAILURE: 'GET_APPLICATION_SUBSCRIBED_FAILURE',

  UPDATE_APPLICATION: 'UPDATE_APPLICATION',
  CLEAR_PERFORMANCE: 'CLEAR_PERFORMANCE'
};

export const candidatesResetErrors = createAction(candidateConstants.RESET_ERRORS);
export const resetEmbeddedApplication = createAction(candidateConstants.RESET_EMBEDDED_APPLICATION);
export const resetTopPerformers = createAction(candidateConstants.RESET_TOP_PERFORMERS);
export const resetRecentApplications = createAction(candidateConstants.RESET_RECENT_APPLICATIONS);

export const getAssessmetCandidatesRequest = createAction(
  candidateConstants.GET_ASSESSMENT_CANDIDATES_REQUEST
);
export const getAssessmetCandidatesSuccess = createAction(
  candidateConstants.GET_ASSESSMENT_CANDIDATES_SUCCESS,
  data => data
);
export const getAssessmetCandidatesFailure = createAction(
  candidateConstants.GET_ASSESSMENT_CANDIDATES_FAILURE,
  errors => errors
);

export const getCandidateRequest = createAction(
  candidateConstants.GET_CANDIDATE_REQUEST,
  action => action
);
export const getCandidateSuccess = createAction(
  candidateConstants.GET_CANDIDATE_SUCCESS,
  candidateData => candidateData
);
export const getCandidateFailure = createAction(
  candidateConstants.GET_CANDIDATE_FAILURE,
  errors => errors
);

export const getApplicationRequest = createAction(
  candidateConstants.GET_APPLICATION_REQUEST,
  action => action
);
export const getApplicationSuccess = createAction(
  candidateConstants.GET_APPLICATION_SUCCESS,
  applicationData => applicationData
);
export const getApplicationFailure = createAction(
  candidateConstants.GET_APPLICATION_FAILURE,
  errors => errors
);

export const getEmbeddedApplicationRequest = createAction(
  candidateConstants.GET_EMBEDDED_APPLICATION_REQUEST,
  action => action
);
export const getEmbeddedApplicationSuccess = createAction(
  candidateConstants.GET_EMBEDDED_APPLICATION_SUCCESS,
  applicationData => applicationData
);
export const getEmbeddedApplicationFailure = createAction(
  candidateConstants.GET_EMBEDDED_APPLICATION_FAILURE,
  errors => errors
);

export const getPendingApplicationRequest = createAction(
  candidateConstants.GET_PENDING_APPLICATION_REQUEST,
  action => action
);
export const getPendingApplicationSuccess = createAction(
  candidateConstants.GET_PENDING_APPLICATION_SUCCESS,
  applicationData => applicationData
);
export const getPendingApplicationFailure = createAction(
  candidateConstants.GET_PENDING_APPLICATION_FAILURE,
  errors => errors
);

export const getAllApplicationRequest = createAction(
  candidateConstants.GET_ALL_APPLICATION_REQUEST,
  action => action
);
export const getAllApplicationSuccess = createAction(
  candidateConstants.GET_ALL_APPLICATION_SUCCESS,
  applicationData => applicationData
);
export const getAllApplicationFailure = createAction(
  candidateConstants.GET_ALL_APPLICATION_FAILURE,
  errors => errors
);

export const getApplicationInfoRequest = createAction(
  candidateConstants.GET_APPLICATION_INFO_REQUEST,
  action => action
);
export const getApplicationInfoSuccess = createAction(
  candidateConstants.GET_APPLICATION_INFO_SUCCESS,
  applicationData => applicationData
);
export const getApplicationInfoFailure = createAction(
  candidateConstants.GET_APPLICATION_INFO_FAILURE,
  errors => errors
);

export const getApplicationTestsRequest = createAction(
  candidateConstants.GET_APPLICATION_TESTS_REQUEST,
  action => action
);
export const getApplicationTestsSuccess = createAction(
  candidateConstants.GET_APPLICATION_TESTS_SUCCESS,
  applicationData => applicationData
);
export const getApplicationTestsFailure = createAction(
  candidateConstants.GET_APPLICATION_TESTS_FAILURE,
  errors => errors
);

export const getApplicationQuestionsRequest = createAction(
  candidateConstants.GET_APPLICATION_QUESTIONS_REQUEST,
  action => action
);
export const getApplicationQuestionsSuccess = createAction(
  candidateConstants.GET_APPLICATION_QUESTIONS_SUCCESS,
  applicationData => applicationData
);
export const getApplicationQuestionsFailure = createAction(
  candidateConstants.GET_APPLICATION_QUESTIONS_FAILURE,
  errors => errors
);

export const getApplicationSubscribedRequest = createAction(
  candidateConstants.GET_APPLICATION_SUBSCRIBED_REQUEST,
  action => action
);
export const getApplicationSubscribedSuccess = createAction(
  candidateConstants.GET_APPLICATION_SUBSCRIBED_SUCCESS,
  applicationData => applicationData
);
export const getApplicationSubscribedFailure = createAction(
  candidateConstants.GET_APPLICATION_SUBSCRIBED_FAILURE,
  errors => errors
);

export const updateApplication = createAction(
  candidateConstants.UPDATE_APPLICATION,
  action => action
);

export const clearPerformance = createAction(
  candidateConstants.CLEAR_PERFORMANCE,
  action => action
);

export const getAllCandidateRequest = createAction(
  candidateConstants.GET_ALL_CANDIDATE_REQUEST,
  candidateId => candidateId
);
export const getAllCandidateSuccess = createAction(
  candidateConstants.GET_ALL_CANDIDATE_SUCCESS,
  candidateData => candidateData
);
export const getAllCandidateFailure = createAction(
  candidateConstants.GET_ALL_CANDIDATE_FAILURE,
  errors => errors
);

export const getTopPerformersRequest = createAction(
  candidateConstants.GET_TOP_PERFORMERS_REQUEST,
  candidateId => candidateId
);
export const getTopPerformersSuccess = createAction(
  candidateConstants.GET_TOP_PERFORMERS_SUCCESS,
  candidateData => candidateData
);
export const getTopPerformersFailure = createAction(
  candidateConstants.GET_TOP_PERFORMERS_FAILURE,
  errors => errors
);

export const getPerformanceRequest = createAction(
  candidateConstants.GET_PERFORMANCE_REQUEST,
  candidateId => candidateId
);
export const getPerformanceSuccess = createAction(
  candidateConstants.GET_PERFORMANCE_SUCCESS,
  candidateData => candidateData
);
export const getPerformanceFailure = createAction(
  candidateConstants.GET_PERFORMANCE_FAILURE,
  errors => errors
);

export const getRecentApplicationsRequest = createAction(
  candidateConstants.GET_RECENT_APPLICATIONS_REQUEST,
  candidateId => candidateId
);
export const getRecentApplicationsSuccess = createAction(
  candidateConstants.GET_RECENT_APPLICATIONS_SUCCESS,
  candidateData => candidateData
);
export const getRecentApplicationsFailure = createAction(
  candidateConstants.GET_RECENT_APPLICATIONS_FAILURE,
  errors => errors
);

export const setApplicationRatingRequest = createAction(
  candidateConstants.SET_APPLICATION_RATING_REQUEST,
  candidateId => candidateId
);
export const setApplicationRatingSuccess = createAction(
  candidateConstants.SET_APPLICATION_RATING_SUCCESS,
  candidateData => candidateData
);
export const setApplicationRatingFailure = createAction(
  candidateConstants.SET_APPLICATION_RATING_FAILURE,
  errors => errors
);

export const setApplicationStatusRequest = createAction(
  candidateConstants.SET_APPLICATION_STATUS_REQUEST,
  candidateId => candidateId
);
export const setApplicationStatusSuccess = createAction(
  candidateConstants.SET_APPLICATION_STATUS_SUCCESS,
  candidateData => candidateData
);
export const setApplicationStatusFailure = createAction(
  candidateConstants.SET_APPLICATION_STATUS_FAILURE,
  errors => errors
);

export const restoreApplicationStatusRequest = createAction(
  candidateConstants.RESTORE_APPLICATION_STATUS_REQUEST,
  candidateId => candidateId
);
export const restoreApplicationStatusSuccess = createAction(
  candidateConstants.RESTORE_APPLICATION_STATUS_SUCCESS,
  candidateData => candidateData
);
export const restoreApplicationStatusFailure = createAction(
  candidateConstants.RESTORE_APPLICATION_STATUS_FAILURE,
  errors => errors
);

export const setApplicationOptionalStatusRequest = createAction(
  candidateConstants.SET_APPLICATION_OPTIONAL_STATUS_REQUEST,
  candidateId => candidateId
);
export const setApplicationOptionalStatusSuccess = createAction(
  candidateConstants.SET_APPLICATION_OPTIONAL_STATUS_SUCCESS,
  candidateData => candidateData
);
export const setApplicationOptionalStatusFailure = createAction(
  candidateConstants.SET_APPLICATION_OPTIONAL_STATUS_FAILURE,
  errors => errors
);

export const setApplicationNoteRequest = createAction(
  candidateConstants.SET_APPLICATION_NOTE_REQUEST,
  action => action
);
export const setApplicationNoteSuccess = createAction(
  candidateConstants.SET_APPLICATION_NOTE_SUCCESS,
  action => action
);
export const setApplicationNoteFailure = createAction(
  candidateConstants.SET_APPLICATION_NOTE_FAILURE,
  errors => errors
);
