import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import * as routes from 'routes/routes';

import RateModal from 'components/Modals/RateModal/RateModal';
import ResultInterpretationModal from 'components/Modals/ResultInterpretationModal/ResultInterpretationModal';

export const Modals = ({
  application,
  currentTestId,
  setCurrentTestId,
  isRateModalOpen,
  setIsRateModalOpen,
  isModalOpen,
  setIsModalOpen,
  forceModalClose
}) => {
  const { company } = useSelector(store => store.auth);
  const navigate = useNavigate();
  let { applicationId, assessmentId } = useParams();
  if (!applicationId) applicationId = application._id;
  if (!assessmentId) assessmentId = application.assessment.assessmentId;

  const showButtons = useMemo(
    () => (application?.tests?.length > 1 ? true : false),
    [application.tests]
  );

  const currentTest = useMemo(() => {
    return application?.tests?.find(test => test._id === currentTestId) || {};
  }, [currentTestId, application.tests]);

  const handleNextTest = type => {
    const currIndex = application?.tests?.findIndex(({ _id }) => _id === currentTestId);
    let currentIndex = type === 'next' ? currIndex + 1 : currIndex - 1;
    if (currentIndex >= application?.tests?.length) currentIndex = 0;
    if (currentIndex < 0) currentIndex = application?.tests?.length - 1;
    setCurrentTestId(application?.tests[currentIndex]?._id);
  };

  return (
    <>
      <RateModal
        isRateModalOpen={isRateModalOpen}
        setRateModalOpen={setIsRateModalOpen}
        applicationId={application._id}
        isDemo={application?.assessment?.demo?.isDemo}
        rates={{
          rating: application?.rating || 0,
          ratings: application?.ratings || {
            educationalBackground: 0,
            relevantWorkExpirience: 0,
            leadershipSkills: 0,
            communiationSkills: 0,
            attitudeAndMotivation: 0
          }
        }}
      />

      {isModalOpen && (
        <ResultInterpretationModal
          isOpen={isModalOpen}
          setOpen={setIsModalOpen}
          forceModalClose={forceModalClose}
          setCurrentTestId={setCurrentTestId}
          showButtons={showButtons}
          handleNextTest={() => handleNextTest('next')}
          handlePrevTest={() => handleNextTest('prev')}
          handleExplain={() =>
            navigate(
              routes.ASSESSMENTS +
                '/' +
                assessmentId +
                routes.APPLICATIONS +
                '/' +
                applicationId +
                '/test/' +
                currentTestId
            )
          }
          {...currentTest}
        />
      )}
    </>
  );
};
