import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';

import { getApplicationRequest } from 'store/candidates/actions';

import Breadcrumbs from 'components/Breadcrumbs';

import { SingleApplication } from 'components/SingleApplication/SingleApplication';

const ViewApplication = () => {
  const { applicationId } = useParams();
  const dispatch = useDispatch();

  const { loading, application } = useSelector(store => store.candidates);

  useEffect(() => {
    if (!applicationId) return;
    dispatch(getApplicationRequest(applicationId));
  }, [applicationId]);

  if (!application?.assessment?.name) {
    return;
  }

  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      <Breadcrumbs type="application" />
      <SingleApplication application={application} />
    </div>
  );
};

export default ViewApplication;
