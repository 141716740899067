import { useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouceCallback, useUserRole } from 'hooks';

import { Typography, Button, Input, Form, Icon } from 'ui-kit';
import Loader from 'components/Loader';

import * as routes from 'routes/routes';
import { TabLibrary } from 'components/TabLibrary/TabLibrary';
import { TestCard } from 'ui-kit/TestCard';

import {
  clearTest,
  clearTests,
  clearTestsPagination,
  getArchivedTestRequest,
  getDraftTestRequest,
  getPublishedTestRequest
} from 'store/tests/actions';
import { getTestCategoriesRequest } from 'store/configuration/actions/subscriptions';
import SelectTree from 'ui-kit/SelectTree/SelectTree';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { ThreeDots } from 'react-loader-spinner';

const perPage = 30;

const TestsLibrary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [params, setParams] = useState({ page: 0 });

  const { isAuthor } = useUserRole();
  const {
    loading,
    publishedTests,
    publishedTestsTotal,
    draftTests,
    draftTestsTotal,
    archivedTests,
    archivedTestsTotal
  } = useSelector(store => store.tests);
  const { loading: load, testCategories } = useSelector(store => store.subscriptions);

  useEffect(() => {
    setParams(prev => {
      return { ...prev, page: 0 };
    });
    dispatch(clearTestsPagination());
  }, [selectedTab]);

  const tabs = useMemo(() => {
    return [
      { title: 'draft', count: draftTestsTotal, arr: draftTests },
      { title: 'published', count: publishedTestsTotal, arr: publishedTests },
      { title: 'archived', count: archivedTestsTotal, arr: archivedTests }
    ];
  }, [publishedTests, draftTests, archivedTests]);

  useEffect(() => {
    !loading && setSearchLoading(false);
  }, [loading]);

  const hasMore = useMemo(() => {
    if (tabs[selectedTab]?.arr?.length >= tabs[selectedTab]?.count) {
      return false;
    }

    return true;
  }, [tabs, selectedTab]);

  useEffect(() => {
    if (selectedTab === -1 || !hasMore) {
      return;
    }
    if (selectedTab === 0) {
      dispatch(
        getDraftTestRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
    if (selectedTab === 2) {
      dispatch(
        getArchivedTestRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
    if (selectedTab === 1) {
      dispatch(
        getPublishedTestRequest({
          skip: params.page * perPage,
          perPage,
          sortBy: 'updatedAt',
          ...params
        })
      );
    }
  }, [params?.page]);

  const createTestHandler = () => {
    navigate(routes.CREATE_TEST);
  };

  useEffect(() => {
    dispatch(clearTest());
    dispatch(getTestCategoriesRequest());
  }, []);

  const debounceSubmit = useDebouceCallback(value => {
    setSearchLoading(true);
    setParams(prev => {
      return { ...prev, ...value, page: 0 };
    });
  });

  useEffect(() => {
    setParams(prev => {
      return { ...prev, page: 0 };
    });
    dispatch(clearTests());
    dispatch(
      getPublishedTestRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
    dispatch(
      getDraftTestRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
    dispatch(
      getArchivedTestRequest({
        skip: params.page * perPage,
        perPage,
        sortBy: 'updatedAt',
        ...params
      })
    );
  }, [params?.search, params?.category]);

  const onScroll = () => {
    setParams(prev => {
      return { ...prev, page: prev.page + 1 };
    });
  };

  return (
    <div className="py-8 px-7.5">
      {(load || loading) && !searchLoading && <Loader />}
      <Form defaultValues={{ search: params.search, category: params.category }}>
        {({ handleSubmit }) => (
          <>
            <ul className="flex justify-between ">
              <li className="flex items-center space-x-3">
                <Typography
                  title={isAuthor === 'author' ? 'My Tests' : 'Tests'}
                  variant="heading-h1"
                />
                {isAuthor && (
                  <Button
                    onClick={createTestHandler}
                    variant="primary"
                    title="Create test"
                    iconSize={20}
                    iconStart={faPlus}
                  />
                )}
              </li>
              <li className="flex w-[500px] items-center space-x-3">
                <div className="flex-1">
                  <SelectTree
                    onChange={data => {
                      const category = data.value;
                      handleSubmit(
                        setParams(prev => {
                          return { ...prev, category, page: 0 };
                        })
                      );
                    }}
                    defaultValue={''}
                    name="category"
                    options={[{ name: 'All Tests', _id: '', subcategories: [] }, ...testCategories]}
                  />
                </div>
                <Input
                  leftComponent={() => (
                    <Icon className="ml-3" size={16} color="#8C9196" icon={faMagnifyingGlass} />
                  )}
                  rightComponent={() => (
                    <div className="mr-3 w-[16px]">
                      {searchLoading && (
                        <ThreeDots height="16" width="16" color="grey" ariaLabel="loading" />
                      )}
                    </div>
                  )}
                  name="search"
                  onChangeValue={handleSubmit(debounceSubmit)}
                  width="w-[250px]"
                  placeholder="Search Tests..."
                />
              </li>
            </ul>
            <TabLibrary
              tabs={tabs}
              hasMore={hasMore}
              onScroll={onScroll}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              children={TestCard.Author}
            />
          </>
        )}
      </Form>
    </div>
  );
};

export default TestsLibrary;
