import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { swapSection } from 'store/tests/actions';

import { Icon } from 'ui-kit';

interface QuestionSectionHaderProps {
  idx: number;
  className: string;
}

export const QuestionSectionHeader: React.FC<QuestionSectionHaderProps> = ({ idx, className }) => {
  const dispatch = useDispatch();

  const handleUp = () => {
    dispatch(swapSection({ from: idx, to: idx - 1 }));
  };

  const handleDown = () => {
    dispatch(swapSection({ from: idx, to: idx + 1 }));
  };

  return (
    <div className={`mt-8 flex flex-col gap-3 ${className}`}>
      <Icon
        className="cursor-pointer rounded-md border border-transparent p-[8px] hover:border-green-400 hover:bg-transparent"
        onClick={handleUp}
        icon={faChevronUp}
        size={16}
        color="green"
      />
      <Icon
        className="cursor-pointer rounded-md border border-transparent p-[8px] hover:border-green-400 hover:bg-transparent"
        onClick={handleDown}
        icon={faChevronDown}
        size={16}
        color="green"
      />
    </div>
  );
};
