import { Button, Form, Input, Modal, Radio, Textarea, Typography } from 'ui-kit';

import { useDispatch } from 'react-redux';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { callToast } from 'utils/helpers';
import {
  createSubscriptionRequest,
  editSubscriptionRequest
} from 'store/configuration/actions/subscriptions';
import {
  draftSubscriptionPlan,
  publisFreeSubscriptionPlan,
  publishPaidSubscriptionPlan
} from './validation';

const AddSubscriptionForm = ({
  setInviteModalOpen,
  setConfirmModalOpen,
  setViewModalOpen,
  ...props
}) => {
  const dispatch = useDispatch();

  const onSubmit = (data, publish = false) => {
    try {
      publish
        ? data?.pricing === 'free'
          ? publisFreeSubscriptionPlan.validateSync(data)
          : publishPaidSubscriptionPlan.validateSync(data)
        : draftSubscriptionPlan.validateSync(data);
      if (data.pricing === 'free') {
        delete data.priceMonth;
        delete data.priceYear;
      } else if (data.pricing === 'paid') {
        delete data.publicationLimit;
        delete data.publicationLifetime;
        delete data.applicationsLimit;
      }

      if (data.benefits) {
        data.benefits = data.benefits.split('<br />');
      }

      if (props?._id) {
        dispatch(
          editSubscriptionRequest({
            subscriptionId: props?._id,
            data: { ...data }
          })
        );
      } else {
        dispatch(createSubscriptionRequest({ ...data }));
      }

      setInviteModalOpen(false);
      publish && setConfirmModalOpen(true);
    } catch (e) {
      callToast('error', e.errors.at(0));
    }
  };

  console.log(props?.status);

  return (
    <Form
      onSubmit={onSubmit}
      mode="onChange"
      defaultValues={{
        name: props?.name,
        description: props?.description,
        pricing: props?.pricing || 'paid',
        priceMonth: props?.priceMonth || { price: '' },
        priceYear: props?.priceYear || { price: '' },
        benefits: props?.benefits?.join('<br />') || '',
        badge: props?.badge || '',
        icon: props?.icon || '',
        publicationLimit: props?.publicationLimit || 1,
        publicationLifetime: props?.publicationLifetime || 1,
        applicationsLimit: props?.applicationsLimit || 1
      }}>
      {({ formState, watch, setValue, getValues }) => {
        return (
          <>
            <Modal.Header className="px-6">
              <Typography
                variant="heading-h2"
                title={
                  props?.status
                    ? `${
                        props?.status === 'active'
                          ? 'Published'
                          : props?.status.charAt(0).toUpperCase() + props?.status?.slice(1)
                      } Subscription Plan`
                    : 'Create Subscription Plan'
                }
              />
              <Button
                icon={faXmark}
                iconSize={20}
                iconColor="#5C5F62"
                onClick={() => setInviteModalOpen(false)}
              />
            </Modal.Header>
            <Modal.Body className="flex flex-1 gap-[48px] p-8">
              <div className="flex flex-1 flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <Typography title="Name" />
                  <Input name="name" />
                </div>
                <div className="flex flex-col gap-1">
                  <Typography title="Description" />
                  <Textarea height="min-h-[70px]" name="description" />
                </div>
                <div className="flex flex-col gap-1">
                  <Typography title="Benefits" />
                  <Textarea height="min-h-[70px]" name="benefits" />
                </div>
                <div className="flex gap-6">
                  <div className="flex flex-1 flex-col gap-1">
                    <Typography title="Recommendation Badge" />
                    <Input name="badge" />
                  </div>
                  <div className="flex flex-1 flex-col gap-1">
                    <Typography title="Icon Name (Font Awesome)" />
                    <Input name="icon" />
                  </div>
                </div>
              </div>
              <div className="flex flex-1 flex-col gap-6">
                <div className="flex max-w-[193px] flex-col gap-3">
                  <Typography title="Pricing Type" />
                  <div className="flex items-center gap-3">
                    <Radio
                      name="pricing"
                      disabled={props?.status === 'active' || props?.status === 'archived'}
                      value="paid"
                      classNameInput="w-[16px] h-[16px] cursor-pointer"
                      placeholder={
                        <Typography
                          className={`${
                            (props?.status === 'active' || props?.status === 'archived') &&
                            'text-[#8C9196]'
                          }`}
                          variant="regular-default"
                          title="Paid"
                        />
                      }
                    />
                    <Radio
                      name="pricing"
                      disabled={props?.status === 'active' || props?.status === 'archived'}
                      value="free"
                      classNameInput="w-[16px] h-[16px] cursor-pointer"
                      placeholder={
                        <Typography
                          className={`${
                            (props?.status === 'active' || props?.status === 'archived') &&
                            'text-[#8C9196]'
                          }`}
                          variant="regular-default"
                          title="Free"
                        />
                      }
                    />
                  </div>
                </div>
                {watch('pricing') === 'paid' && (
                  <>
                    <Typography title="Pricing" variant="heading-h4" />
                    <div className="flex gap-6">
                      <div className="flex flex-1 flex-col gap-1">
                        <Typography title="Monthly Cost (USD)" />
                        <Input
                          name="priceMonth.price"
                          disabled={props?.status === 'active' || props?.status === 'archived'}
                          onKeyDown={evt =>
                            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                          }
                          placeholder="e.g. 29"
                          type="number"
                        />
                      </div>
                      <div className="flex flex-1 flex-col gap-1">
                        <Typography title="Annual Cost (USD)" />
                        <Input
                          name="priceYear.price"
                          disabled={props?.status === 'active' || props?.status === 'archived'}
                          onKeyDown={evt =>
                            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                          }
                          placeholder="e.g. 19"
                          type="number"
                        />
                      </div>
                    </div>
                  </>
                )}
                {watch('pricing') === 'free' && (
                  <>
                    <Typography title="Limits" variant="heading-h4" />
                    <div className="mt-[-12px] flex flex-col">
                      <div className="flex gap-6">
                        <div className="flex flex-1 flex-col gap-1">
                          <Typography title="Assessment Publications" />
                          <Input
                            name="publicationLimit"
                            placeholder="e.g. 15"
                            type="number"
                            onKeyDown={evt =>
                              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                            }
                          />
                        </div>
                        <div className="flex flex-1 flex-col gap-1">
                          <Typography title="Assessment Lifetime, days" />
                          <Input
                            name="publicationLifetime"
                            placeholder="e.g. 60"
                            type="number"
                            onKeyDown={evt =>
                              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-5 flex gap-6">
                        <div className="flex flex-1 flex-col gap-1">
                          <Typography title="View Applications" />
                          <Input
                            name="applicationsLimit"
                            placeholder="e.g. 15"
                            type="number"
                            disabled={watch('pricing') !== 'free'}
                            onKeyDown={evt =>
                              ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                            }
                          />
                        </div>
                        <div className="flex flex-1 flex-col" />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-between gap-4">
              <Button
                variant="secondary"
                disabled={!formState.isValid}
                title={`${props?.status === 'active' ? 'Archive Plan' : 'Publish Plan'}`}
                onClick={() => {
                  if (props?.status === 'active' || props?.status === 'archived') {
                    setViewModalOpen(false);
                    setConfirmModalOpen(true);
                  } else {
                    const values = getValues();
                    onSubmit(values, true);
                  }
                }}
              />
              <div className="flex gap-4">
                <Button
                  variant="secondary"
                  title="Cancel"
                  onClick={() => setInviteModalOpen(false)}
                />
                <Button
                  disabled={!formState.isValid}
                  variant="primary"
                  title={`${
                    props?.status === 'active' || props?.status === 'archived'
                      ? 'Save Changes'
                      : 'Save Draft'
                  }`}
                  onClick={() => {
                    const values = getValues();
                    onSubmit(values, false);
                  }}
                />
              </div>
            </Modal.Footer>
          </>
        );
      }}
    </Form>
  );
};

export default AddSubscriptionForm;
