import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faHourglassHalf, faStopwatch } from '@fortawesome/pro-solid-svg-icons';

import { Typography, Button, Icon } from 'ui-kit';

import Breadcrumbs from 'components/Breadcrumbs';
import Loader from 'components/Loader';

import { TestEditor } from '../components/TestEditor';
import { CreateNewTest, SaveNewTest } from 'components/Forms/validation';

import { clearSection } from 'helpers/clearSection';
import { callToast } from 'utils/helpers';
import {
  clearTest,
  createTestRequest,
  updateAreasOfUse,
  updateSuitableFor
} from 'store/tests/actions';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt/useCallbackPrompt';
import ConfirmChangesModal from 'components/ConfirmChangesModal/ConfirmChangesModal';
import TimeBadget from 'components/TimeBadget';
import { useEffect } from 'react';

export const CreateTest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentTest, showDialog, loading } = useSelector(store => store.tests);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  useEffect(() => {
    dispatch(clearTest());
  }, []);

  const onSubmit = (preview, confirm = false) => {
    if (!currentTest?.name) {
      callToast('error', 'Test Name is required');
      return;
    }
    let exist;
    const data = {
      test: clearSection({
        ...currentTest
      })
    };

    currentTest.tags.map(tag => {
      const tags = currentTest.sections
        .flatMap(section => section.questions)
        .flatMap(question => question.tags);
      const isExist = tags.some(({ value }) => value === tag.value);
      if (!isExist) {
        callToast('error', `Result for ${tag.label} tag exist without questions`);
        exist = !isExist;
      }
    });

    if (exist) {
      return;
    }

    const newAreas = data.test?.areasOfUse?.filter(item => item?.__isNew__ && item);
    const newSuitable = data.test?.suitableFor?.filter(item => item?.__isNew__ && item);

    try {
      preview ? CreateNewTest.validateSync(data.test) : SaveNewTest.validateSync(data.test);
      dispatch(createTestRequest({ data, navigate, preview, confirm }));
      newAreas && dispatch(updateAreasOfUse(newAreas));
      newSuitable && dispatch(updateSuitableFor(newSuitable));
    } catch (e) {
      callToast('error', e.errors.at(0));
    }
  };

  return (
    <div className="py-8 px-7.5">
      {loading && <Loader />}
      <Breadcrumbs />
      <div className="flex justify-between">
        <div className="flex items-center gap-[12px]">
          <div className="max-w-[900px]">
            <Typography variant="heading-h1" title="Create Test" />
          </div>
          <TimeBadget
            className="font-semibold"
            time={currentTest?.estimatedDuration ?? currentTest?.timeLimit}
            icon={<Icon icon={faStopwatch} size={16} />}
          />
          <TimeBadget
            className="font-semibold"
            time={currentTest?.timeLimit}
            icon={<Icon icon={faHourglassHalf} size={16} />}
          />
        </div>
        <div className="flex gap-3">
          <Button
            variant="secondary"
            title="Save Draft"
            onClick={() => {
              onSubmit(false);
            }}
          />
          <Button
            variant="primary"
            title="Preview & Publish"
            onClick={() => {
              onSubmit(true);
            }}
          />
        </div>
      </div>
      <TestEditor />
      <ConfirmChangesModal
        isConfirmModalOpen={showPrompt}
        closeConfirmModal={cancelNavigation}
        bodyText={currentTest?.name || 'Test'}
        confirmNavigation={confirmNavigation}
        buttonAction={() => onSubmit(false, true)}
      />
    </div>
  );
};
