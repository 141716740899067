import { useNavigate } from 'react-router-dom';

import { InviteEmployerSchema } from 'components/Forms/validation';
import { Button, ErrorMessage, Form, Input, Modal, Typography } from 'ui-kit';

import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch } from 'react-redux';
import { inviteEmployerRequest } from 'store/admin/actions';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

const RequestAccessForm = ({ isInviteModalOpen, setInviteModalOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(inviteEmployerRequest(data));
    setInviteModalOpen(false);
  };

  return (
    <Modal isOpen={isInviteModalOpen} onClose={() => setInviteModalOpen(false)} size="2xl">
      <Form onSubmit={onSubmit} resolver={yupResolver(InviteEmployerSchema)} mode="onBlur">
        {({ formState }) => (
          <>
            <Modal.Header className="px-6">
              <Typography variant="heading-h4" title="Invite Employer" />
              <Button
                icon={faXmark}
                iconSize={20}
                iconColor="#5C5F62"
                onClick={() => setInviteModalOpen(false)}
              />
            </Modal.Header>
            <Modal.Body className="px-6">
              <div className="flex gap-4">
                <div className="flex-1">
                  <Typography title="First Name" variant="small-default" />
                  <Input name="firstName" />
                  <ErrorMessage name="firstName" />
                </div>
                <div className="flex-1">
                  <Typography title="Last Name" variant="small-default" />
                  <Input name="lastName" />
                  <ErrorMessage name="lastName" />
                </div>
              </div>
              <div>
                <Typography title="Company Name" variant="small-default" />
                <Input name="companyName" />
                <ErrorMessage name="companyName" />
              </div>
              <div>
                <Typography title="Email" variant="small-default" />
                <Input name="email" />
                <ErrorMessage name="email" />
              </div>
            </Modal.Body>
            <Modal.Footer className="flex justify-end gap-4">
              <Button
                variant="primary-outline"
                title="Close"
                onClick={() => setInviteModalOpen(false)}
              />
              <Button
                disabled={!formState.isDirty}
                variant="primary"
                title="Send Invitation"
                type="submit"
              />
            </Modal.Footer>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default RequestAccessForm;
