import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { Button, Input, Textarea, Typography } from 'ui-kit';

export const Grade = ({ ...props }) => (
  <div className="flex items-center gap-3">
    <div className="flex items-center gap-2">
      <Input
        placeholder="Min"
        type="number"
        name={props?.minName || 'min'}
        onKeyDown={evt => {
          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
        }}
        className="h-[42px] w-16 text-center"
        onBlur={e => {
          if (!props?.placeholder) {
            return;
          }
          props?.unregister('min');
          e.target.value &&
            props?.control.append({
              description: '',
              title: '',
              scale: {
                min: e.target.value,
                max: null
              }
            });
          e.target.value = '';
        }}
        min={-999}
        max={999}
        inputClassName="text-center"
      />
      <Typography title="-" />
      <Input
        placeholder="Max"
        type="number"
        name={props?.maxName || 'max'}
        onBlur={e => {
          if (!props?.placeholder) {
            return;
          }
          props?.unregister('max');
          e.target.value &&
            props?.control.append({
              description: '',
              title: '',
              scale: {
                min: null,
                max: e.target.value
              }
            });
          e.target.value = '';
        }}
        onKeyDown={evt => {
          ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
        }}
        className="h-[42px] w-16 text-center"
        min={-999}
        max={999}
        inputClassName="text-center"
      />
    </div>
    <Input
      width="flex-1 h-[42px]"
      className="h-[42px] flex-1"
      name={props?.gradeName || 'grade'}
      onBlur={e => {
        if (!props?.placeholder) {
          return;
        }
        props?.unregister('grade');
        e.target.value &&
          props?.control.append({
            description: '',
            title: e.target.value,
            scale: {
              min: null,
              max: null
            }
          });
        e.target.value = '';
      }}
      placeholder="Grade..."
    />
    <Textarea
      className="block flex-3"
      name={props?.descriptionName || 'text'}
      onBlur={e => {
        if (!props?.placeholder) {
          return;
        }
        props?.unregister('text');
        e.target.value &&
          props?.control.append({
            description: e.target.value,
            title: '',
            scale: {
              min: null,
              max: null
            }
          });
        e.target.value = '';
      }}
      placeholder="Description..."
      height="min-h-[42px]"
    />
    <Button
      onClick={props?.onClick}
      className={`h-[42px] items-center ${props.placeholder && 'invisible'}`}
      variant="primary-outline"
      icon={faTrash}
    />
  </div>
);
