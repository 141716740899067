import Breadcrumbs from 'components/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'ui-kit';
import * as routes from 'routes/routes';

export const ConfigurationTests = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full px-6 py-9">
      <Breadcrumbs />
      <Typography title="Tests" variant="subheader" />
      <div
        onClick={() => navigate(routes.CONFIGURATION_TEST_CATEGOIRES_ROUTE)}
        className="group mt-6 flex w-full cursor-pointer flex-col gap-3 rounded-xl border border-[#C9CCCF] bg-[#FCFCFC] py-4 px-6 transition-all hover:border-green-400">
        <Typography
          variant="heading-h5"
          title="Test Categories"
          className="transition-all group-hover:text-green-400"
        />
        <Typography variant="regular-default" title="Saved in Test Meta, defined by an Author." />
      </div>
      <div
        onClick={() => navigate(routes.CONFIGURATION_ANSWER_CHOISES_ROUTE)}
        className="group mt-6 flex w-full cursor-pointer flex-col gap-3 rounded-xl border border-[#C9CCCF] bg-[#FCFCFC] py-4 px-6 transition-all hover:border-green-400">
        <Typography
          variant="heading-h5"
          title="Answer Choices Templates"
          className="transition-all group-hover:text-green-400"
        />
        <Typography
          variant="regular-default"
          title="Pre-defined List of Choices, used by an Author and an Employer when editing questions."
        />
      </div>
    </div>
  );
};
