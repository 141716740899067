import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { optionsBarChart, optionsPolar } from 'config/charConfig';
import { useMemo } from 'react';
import { Bar, PolarArea } from 'react-chartjs-2';
import { Icon, Section, Typography } from 'ui-kit';
import { FormatOptionLabel } from 'ui-kit/CustomSelect/SelectCustom';
import TextAreaView from 'ui-kit/TextAreaView/TextAreaView';
import { stringToHslColor } from 'utils/stringToHslColor';

const GradeView = ({ title, results, tag }) => (
  <div className="flex flex-col gap-6 rounded-md border border-[#C9CCCF] p-6">
    {tag ? <FormatOptionLabel label={title} /> : <Typography variant="heading-h3" title={title} />}
    <div className="flex gap-2">
      <div className="w-[30px] rounded bg-[#E4E5E7] px-[6px] py-[1px] text-center">
        {results?.min}
      </div>
      <span>-</span>
      <div className="w-[30px] rounded bg-[#E4E5E7] px-[6px] py-[1px] text-center">
        {results?.max}
      </div>
    </div>
    <div className="flex flex-col gap-6">
      {results?.grades?.map(({ _id, title, description, scale }) => (
        <div className="flex gap-[18px]" key={_id}>
          <div className="flex gap-2">
            <Typography className="w-[30px] text-center" variant="regular-emphasis" title={scale?.min} />
            <span>-</span>
            <Typography className="w-[30px] text-center" variant="regular-emphasis" title={scale?.max} />
          </div>
          <div className="flex flex-col gap-[6px]">
            <Typography variant="regular-emphasis" title={title} />
            <TextAreaView variant="regular-default" title={description} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export const TestViewResults = ({ currentTest }) => {
  const charData = useMemo(() => {
    const { includeTestOverall, includeSections, includeTags } = currentTest.result.chartSettings;
    let data = [];

    includeTestOverall && data.push('Test Overall');
    includeSections &&
      currentTest?.sections?.length > 1 &&
      currentTest.sections.map(({ name }) => data.push(name));
    includeTags && currentTest.tags.map(({ label }) => data.push(label));

    return {
      labels: data,
      datasets: [
        {
          data: data.map(() => Math.floor(Math.random() * (80 - 15 + 1)) + 15),
          backgroundColor: data.map(value => stringToHslColor(value, 45, 55, 0.5)),
          borderWidth: 1
        }
      ]
    };
  }, [currentTest.result.chartSettings]);

  return (
    <Section>
      <Section.Body className="flex flex-col gap-6">
        <div className="flex">
          <div className="flex flex-1 flex-col gap-[4px]">
            <Typography
              className="ml-[12px]"
              variant="regular-default"
              title="Test Result Calculation Method"
            />
            <div className="flex h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
              <Typography
                className="capitalize text-[#8C9196]"
                variant="regular-default"
                title={currentTest?.result?.calculationMethod}
              />
              <div className="flex h-[20px] w-[20px] items-center">
                <Icon icon={faChevronDown} color="#BABEC3" />
              </div>
            </div>
          </div>
          <div className="flex flex-1 justify-center">
            {currentTest?.result?.chartSettings?.chartStyle !== 'none' && (
              <div className="flex flex-col gap-[4px]">
                <Typography className="ml-[12px]" variant="regular-default" title="Chart Style" />
                <div className="flex h-[44px] w-full items-center justify-between rounded-md border border-[#D2D5D8] bg-[#FAFBFB] px-[16px] py-[10px]">
                  <Typography
                    className="capitalize text-[#8C9196]"
                    variant="regular-default"
                    title={currentTest?.result?.chartSettings?.chartStyle}
                  />
                  <div className="flex h-[20px] w-[20px] items-center">
                    <Icon icon={faChevronDown} color="#BABEC3" />
                  </div>
                </div>
                <div className="mt-[28px] flex flex-col gap-1">
                  <div className="flex items-center gap-[10px]">
                    <input
                      disabled
                      type="checkbox"
                      className="h-4 w-4 rounded-sm border border-[#D2D5D8] checked:bg-[#BDC1CC] checked:hover:bg-[#BDC1CC] focus:ring-0 focus:ring-offset-0"
                      checked={currentTest?.result?.chartSettings?.includeTestOverall}
                    />
                    <Typography
                      className="text-[#8C9196]"
                      title="Include Test Overall"
                      variant="regular-default"
                    />
                  </div>
                  <div className="flex items-center gap-[10px]">
                    <input
                      disabled
                      type="checkbox"
                      className="h-4 w-4 rounded-sm border border-[#D2D5D8] checked:bg-[#BDC1CC] checked:hover:bg-[#BDC1CC] focus:ring-0 focus:ring-offset-0"
                      checked={currentTest?.result?.chartSettings?.includeSections}
                    />
                    <Typography
                      className="text-[#8C9196]"
                      title="Include Sections"
                      variant="regular-default"
                    />
                  </div>
                  <div className="flex items-center gap-[10px]">
                    <input
                      disabled
                      type="checkbox"
                      className="h-4 w-4 rounded-sm border border-[#D2D5D8] checked:bg-[#BDC1CC] checked:hover:bg-[#BDC1CC] focus:ring-0 focus:ring-offset-0"
                      checked={currentTest?.result?.chartSettings?.includeTags}
                    />
                    <Typography
                      className="text-[#8C9196]"
                      title="Include Tags"
                      variant="regular-default"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="max-h-[430px] flex-1">
            {currentTest?.result?.chartSettings?.chartStyle !== 'none' &&
              (currentTest?.result?.chartSettings?.chartStyle === 'polar' ? (
                <PolarArea
                  data={charData}
                  options={
                    currentTest?.calculationType === 'avg'
                      ? optionsPolar(currentTest?.minValue, currentTest?.maxValue)
                      : optionsPolar(0, 100)
                  }
                />
              ) : (
                <div className="mr-20">
                  <Bar
                    data={charData}
                    options={
                      currentTest?.calculationType === 'avg'
                        ? optionsBarChart(currentTest?.minValue, currentTest?.maxValue)
                        : optionsBarChart(0, 100)
                    }
                  />
                </div>
              ))}
          </div>
        </div>
        {currentTest?.result?.resultInterpretation?.grades?.length > 0 && (
          <GradeView title="Test Overall" results={currentTest?.result?.resultInterpretation} />
        )}
        {currentTest?.sections?.map(
          ({ resultInterpretation, name }) =>
            resultInterpretation?.grades.length > 0 && (
              <GradeView title={name} results={resultInterpretation} />
            )
        )}
        {currentTest?.tags?.map(
          ({ resultInterpretation, label }) =>
            resultInterpretation?.grades.length > 0 && (
              <GradeView tag title={label} results={resultInterpretation} />
            )
        )}
      </Section.Body>
    </Section>
  );
};
