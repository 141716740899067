import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { ViewTestModal } from 'pages/Author/Tests/Test/ViewTestModal';
import Button from 'ui-kit/Button';
import Typography from 'ui-kit/Typography';


import classNames from 'utils/classNames';
import { secondsToHms } from 'utils/helpers';

export const Assessment = props => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const dispatch = useDispatch();

  const { type, idArrow } = props || {};
  const { _id, name, questionsCount, timeLimit, type: typeSelect } = props?.data || {};

  const isTestAdded = props.allTests?.some(item => item._id === _id);

  return (
    <>
      <li
        id={idArrow}
        style={{ wordBreak: 'break-word' }}
        className={`relative flex min-w-[345px] flex-col justify-between break-words ${
          type === 'added' && 'max-w-[300px]'
        } rounded-md bg-white  transition-all`}>
        <ul className="space-y-4 rounded-md border border-gray-300 p-4">
          <li className="space-y-1">
            <Typography variant="heading-h3" className="truncate" title={name || 'In draft'} />
            <Typography
              variant="regular-emphasis"
              className="truncate text-gray-400"
              title={typeSelect?.label || typeSelect || 'Not selected'}
            />
          </li>
          <li className="flex flex-wrap justify-between gap-3">
            <div>
              <Typography variant="small-default" title="Questions" />
              <Typography variant="regular-emphasis" className="text-gray-500" title={questionsCount} />
            </div>
            <div>
              <Typography variant="small-default" title="Time Limit" />
              <Typography
                variant="regular-emphasis"
                className="text-gray-500"
                title={secondsToHms(timeLimit) || '-'}
              />
            </div>
          </li>
          <li className="flex justify-between">
            <Button variant="primary-outline" onClick={previewTestHandler} title="Preview" />
            {type === 'added' ? (
              <Button
                onClick={removeTestHandler}
                disabled={isTestAdded}
                className="inline-flex h-10 w-20 items-center justify-center rounded-xl border border-transparent bg-red-400 py-2  px-3 text-base font-semibold text-white shadow-sm transition-all hover:bg-red-500 disabled:bg-gray-400"
                title="Delete"
              />
            ) : (
              <Button
                onClick={() => addTestsToAssessmentHandler(_id)}
                disabled={isTestAdded}
                title={isTestAdded ? 'Added' : 'Add'}
                className={classNames(
                  'transition-all inline-flex justify-center items-center py-2 px-3 h-10 w-20 text-base font-semibold  disabled:bg-gray-400 rounded-xl border border-transparent focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-offset-0 shadow-sm',
                  isTestAdded
                    ? 'bg-white text-gray-500 border-gray-400 focus:ring-0 hover:bg-gray-300'
                    : `text-white bg-green-400 hover:bg-green-500`
                )}
              />
            )}
          </li>
        </ul>
      </li>
      <ViewTestModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
};
