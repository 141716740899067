import { useEffect, useMemo, useState } from 'react';
import { Button, Form, List, Modal, Textarea, Typography } from 'ui-kit';
import { IconTest } from 'components/IconTest/IconTest';
import EvaluateQuestionComponent from './EvaluateQuestionComponent';

export const EvaluateModal = ({ isOpen, setIsOpen, questions, testName, testIcon }) => {
  const [questionIdx, setQuestionIdx] = useState(0);

  useEffect(() => {
    setQuestionIdx(0);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={setIsOpen} className="m-auto w-full min-w-[800px] max-w-max">
      <Modal.Body className="flex min-h-[100px] flex-col !p-0">
        <div className="flex w-full flex-col justify-between">
          <div className="flex items-center gap-3 px-[24px] py-[16px]">
            <IconTest url={testIcon} size={48} />
            <div className="flex flex-1 items-center gap-[5px]">
              <p className="text-h4 text-[#082623]">Evaluating Questions</p>
              <p className="text-h4 text-[#8C9196]">for {testName}</p>
            </div>
            <div className="hover:cursor-pointer" onClick={setIsOpen}>
              <i className="fa-regular fa-xmark text-xl text-[#5C5F62]"></i>
            </div>
          </div>
          <div className="h-[6px] bg-[#F6F6F7]">
            <div className="flex h-full">
              {questions?.map((_, index) => (
                <div
                  key={index}
                  className={`flex-1 ${index <= questionIdx ? 'bg-[#00A47C]' : ''}`}
                />
              ))}
            </div>
          </div>
        </div>
        <EvaluateQuestionComponent
          questionIdx={questionIdx}
          questions={questions}
          prevQuestionHandler={() => setQuestionIdx(questionIdx - 1)}
          nextQuestionHandler={() =>
            questionIdx + 1 === questions.length
              ? setIsOpen(false)
              : setQuestionIdx(questionIdx + 1)
          }
        />
      </Modal.Body>
    </Modal>
  );
};
